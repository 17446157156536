import React, { useState, useEffect, useStateRef, useRef } from 'react';
/* import "primereact/resources/themes/vela-blue/theme.css";
import "primereact/resources/primereact.min.css"; */
/* import "/node_modules/primeflex/primeflex.css";
import "/node_modules/primeflex/primeflex.css"; */
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import "primeflex/primeflex.css";
import { InputText } from 'primereact/inputtext';
import SocietyService from '../../Service/SocietyService';
import './CommunicationSetting.css';
import { blue } from '@material-ui/core/colors';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import axios from "axios";
import Config from "../../Config.json";
import { Toast } from 'primereact/toast';
import authHeader from "../../authHeader";
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import { useForm } from "react-hook-form";
import { InputNumber } from 'primereact/inputnumber';
import { StepContent } from '@material-ui/core';




const CommunicationSetting = () => {
    const societyService = new SocietyService();
    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue, clearErrors } = useForm();

    const [DisableButton, setDisableButton] = useState(true);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    var [societiesOfCity, setSocietiesOfCity] = useState([]);
    const [societyArray, setSocietyArray] = useState([]);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [productDialog, setProductDialog] = useState(false);
    const [societyCommunicationData, setSocietyCommunicationData] = useState([]);;
    var [emailValue, setEmailValue] = useState();
    var [whatsAppValue, setWhatsAppValue] = useState();
    const [selectedSocietyId, setSelectedSocietyId] = useState();
    const [selectedSocietyName, setSelectedSocietyName] = useState(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);


    useEffect(() => {
        societyService.getAllSocietyDetails()
            .then(data => {
                let tempArray = [];
                data.forEach(society => {
                    let item = { 'id': society.societyId, 'name': society.societyName, 'city': society.city };
                    tempArray.push(item);
                })
                tempArray.sort(compare);
                setSocietyArray(tempArray);
            });

        societyService.GetSocietyCommunicationDetails().then(data => {
            // data.forEach(a=>{
            //     a.isWhatsupEnabled=a.isWhatsupEnabled?"true":"false";
            //     a.isEmailEnabled=a.isEmailEnabled?"true":"false";
            // })
            setSocietyCommunicationData(data);
        });

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }

    }, []);


    function compare(a, b) {
        if (a.last_nom < b.last_nom) {
            return -1;
        }
        if (a.last_nom > b.last_nom) {
            return 1;
        }
        return 0;
    }

    const openNew = () => {
        // setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const onSocietyChange = (e) => {
        let sName = e.value;
        let sId = e.value.id;
        setSelectedSocietyName(sName);
        setSelectedSocietyId(sId);
    }

    const onSubmit = (data) => {
        data.societyId = selectedSocietyId;
        if (emailValue) {
            data.email = 1;
        }
        if (whatsAppValue) {
            data.whatsApp = 1;
        }
        return axios.post(`${Config.apiUrl}/SocietyCommunication/SocietyCommunicationDetails`, data,{headers:authHeader()})
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                window.location.reload();
            })

    }

    const emailBodyTemplate = (rowData) => {
        return <Checkbox checked={rowData.isEmailEnabled} 
         />      
    }

    const whatsAppBodyTemplate = (rowData) => {
        return <Checkbox checked={rowData.isWhatsupEnabled} 
         />      
    }

    const codeEditor = (productKey, props, field) => {
        // return Checkbox(productKey, props, 'Group Name');
        if (field === 'Email') {
            // emailValue=props.rowData.isEmailEnabled
            // emailValue ='true'? true :false;
            // props.rowData.isEmailEnabled = 'true' ? true : false;

            // props.rowData.isEmailEnabled=emailValue;
            return <Checkbox checked={props.rowData.isEmailEnabled}
                // onChange={e => setEmailValue(e.checked)}
                // onChange={(e) => setEmailValue(productKey, props.rowData.isEmailEnabled, e.checked)} />
                onChange={(e) => onEditorValueChange(productKey, props, e.checked)} />
        }
        else if(field === 'WhatsApp') {
            // whatsAppValue = props.rowData.isWhatsupEnabled
            //  props.rowData.isWhatsupEnabled = 'true' ? true : false;
            // props.rowData.isWhatsupEnabled = whatsAppValue;

            return <Checkbox checked={props.rowData.isWhatsupEnabled} 
            onChange={e => onEditorValueChange(productKey, props, e.checked)} />
        }

    }
    const dataTableEdit = {
        'data': setSocietyCommunicationData
    };

    const onEditorValueChange = (productKey, props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;     
        dataTableEdit[`${productKey}`](updatedProducts);
        setSocietyCommunicationData(updatedProducts);
    }

    let originalRows = {};
    let editingCellRows = {};

    const onRowEditInit = (event) => {
        originalRows[event.index] = { ...societyCommunicationData[event.index] };
        // societyService.putGroupMaster(originalRows);
    }

    const onRowEditSave = (event) => {
        originalRows[event.index] = { ...societyCommunicationData[event.index] };
        let data = event.data;
        data.email=data.isEmailEnabled ? 1 : 0 ;
        data.whatsApp=data.isWhatsupEnabled ? 1 : 0;
        setSpinnerVisible(true);
        return axios.put(`${Config.apiUrl}/SocietyCommunication/update`, data, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', sticky: true, detail: response.data.msg });
                }
                else {
                    toast.current.show({ severity: 'error', sticky: true, detail: response.data.msg });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
              })
            .finally(() => {
                setSpinnerVisible(false);
            })
    }

    const onRowEditCancel = (event) => {
        let index=event.index;
        let data = [...societyCommunicationData];
        societyCommunicationData[event.index] = originalRows[event.index];
        delete originalRows[event.index];
        setSocietyCommunicationData(data);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    return (

        <div class="card ComuSettings">
            <div>
                <Toast ref={toast} position="center" />
                <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={societyCommunicationData} className="p-datatable-gridlines" editMode="row" onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel} onRowEditSave={onRowEditSave} scrollable scrollHeight='500px'>
                    {/* <Column em="" selectionmode="single" /> */}
                    <Column field='societyName' header="Society Name"  style={{width:'300px'}}/>
                    <Column field='societyId' header="Society Id" />
                    <Column field='isEmailEnabled' header="Email" body={emailBodyTemplate} editor={(props) => codeEditor('data', props, 'Email')} />
                    <Column field='isWhatsupEnabled' header="WhatsApp" body={whatsAppBodyTemplate} editor={(props) => codeEditor('data', props, 'WhatsApp')} />
                    <Column rowEditor header="Action" headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} style={{ width: "50px" }}></Column>
                </DataTable>
            </div>

            <Dialog visible={productDialog} style={{ width: '450px' }} header="Details" modal className='ComuSettings' onHide={hideDialog}>
                <div>
                    <label>Society</label>
                    <Dropdown value={selectedSocietyName} options={societyArray} id="name" optionLabel="name" onChange={onSocietyChange} filter showClear filterBy="name" placeholder="Select a SocietyName" />
                </div>
                <div className="col-12">
                    <Checkbox checked={emailValue} onChange={e => setEmailValue(e.checked)} ></Checkbox>
                    <label htmlFor="cb1" >Email</label>
                </div>
                <div className="col-12">
                    <Checkbox checked={whatsAppValue} onChange={e => setWhatsAppValue(e.checked)} ></Checkbox>
                    <label htmlFor="cb1" className="p-checkbox-label">WhatsApp</label>
                </div>

                <div class="field grid" style={{ marginTop: '10px' }}>
                    <Button label="SUBMIT" onClick={handleSubmit(onSubmit)} />
                </div>


            </Dialog>

        </div>

    );
}
export default CommunicationSetting;