/* eslint-disable no-const-assign */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types';
import  './FlatView.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from '../../Service/SocietyService';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Flat_Master from '../../Flat_Master';
import { useHistory } from 'react-router-dom';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import Flat_Master from '../Masters/Flat_Master';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import moment from 'moment';




const FlatView = ({ setEditData, match }) => {
  let history = useHistory();
  var societyId = parseInt(localStorage.getItem('societyId'));
  const { path } = match;
  const societyService = new SocietyService();
  const [id, setId] = useState([]);
  const [personalDetailsData, SetPersonalDetailsData] = useState([]);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const toast = useRef(null);
 // const [personalDetailsData, setPersonalDetailsData] = useState([]);

  const { errors, register, reset, handleSubmit } = useForm();
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const tableName = sessionStorage.getItem('tblName');


  
    //getting group master data
    useEffect(() => {
     
      societyService.getPersonalDetails(societyId, tableName)
        .then(data => {
          
          const updatedData = data.map(d => {
          d.admitDateFormat= moment(new Date(d.admitDateFormat)).format('DD-MM-YYYY');
            if (d.admitDateFormat === '01-01-0001') {
              d.admitDateFormat = '---';
            }
            //  else {
            //   const dateObject = new Date(d.admitDateFormat);
            //   const day = dateObject.getDate().toString().padStart(2, '0');
            //   const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            //   const year = dateObject.getFullYear();
  
            //   d.admitDateFormat = `${day}-${month}-${year}`;
            // }
            return d;
          });
  
          SetPersonalDetailsData(updatedData);
  
          console.warn(updatedData);
  
          if (accessLevel >= 2) {
            setDisableButton(false);
          } else {
            setDisableButton(true);
          }
        });
    }, []); // Empty dependency array means this effect will run once after the initial render
  
  const onDelete = (event) => {
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" 
        style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={handleSubmit(onYes)} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
            </div>
          </div>
        </div>
      )
    });
  }

  const onYes = (event) => {
    setSpinnerVisible(true);
    toast.current.clear();

    setEditData = selectedRadioData;
    let index = personalDetailsData.findIndex(a => a.id === selectedRadioData.id);
    let id = selectedRadioData.id;

    let data = personalDetailsData.filter(b => b.id != selectedRadioData.id);
      SetPersonalDetailsData(data);
      axios.delete(`${Config.apiUrl}/PersonalDetails/delete?id=${id}`,{headers:authHeader()})
        .then(response => {
          console.log(response)
          if (response.data != null) {
            toast.current.show({ severity: 'success', detail: response.data[0].msg });
            window.location.reload();
          }
          else {
            toast.current.show({ severity: 'error', detail: response.data[0].msg });
          }
        })
        .catch(error => { 
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
          setSpinnerVisible(false);
        })
    
  }

  const onNo = () => {
    toast.current.clear();
    return;
  };

  const onEdit = () => {
    history.push(`/Flat_Master/edit/${selectedRadioData.id}/${selectedRadioData.societyId}`);

    console.warn("edit buuton clicked");
  }



  const header = (
    <div className="tableheader">
      <div>
          <label>Flat Details</label>
      </div>
      <div>
            <Button icon="pi pi-pencil" value="Edit" tooltip="Edit" className="btnEdit" onClick={onEdit} disabled={DisableButton}/>
            <Button icon="pi pi-trash" value="Delete"  className="btn" onClick={onDelete} disabled={DisableButton}/>
      </div>
     
    </div>
  );

  console.warn("selected Data", selectedRadioData);

  return (
    <div className='GridView' data-testid="GridView">
            <Toast ref={toast} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>

      <div className='GridView'>
      <DataTable value={personalDetailsData} className="p-datatable-gridlines" header={header} scrollable scrollHeight='60vh' style={{overflow:'hidden'}} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}  >
        <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
        <Column field="id"  style={{width:'70px'}} header="Flat Id" filter></Column>
        <Column field="wing"  style={{width:'70px'}} header="Wing" filter></Column>
        <Column field="flatNo"  style={{width:'80px'}} header="Flat No" filter></Column>
        <Column field="name"  style={{width:'220px'}} header="Owner Name"  filter></Column>
        <Column field="gender"  style={{width:'100px'}} header="Gender" filter></Column>
        {/* <Column field="birthDateFormat"  style={{width:'100px'}} header="Birth Date" filter></Column>
        <Column field="age"  style={{width:'80px'}} header="Age" filter></Column>
        <Column field="relation"  style={{width:'100px'}} header="Relation" filter></Column> */}
        <Column field="occupiedBy" style={{width:'150px'}} header="Occupied By" filter></Column>
        <Column field="occupation"  style={{width:'150px'}} header="Occupation" filter ></Column>
        <Column field="admitDateFormat"  style={{width:'100px'}} header="Date of Admition" filter></Column>
        <Column field="mobile"  style={{width:'120px'}} header="Mobile" filter ></Column>
        <Column field="address1"  style={{width:'250px'}} header="Address" filter ></Column>
        <Column field="city"  style={{width:'150px'}} header="City" filter ></Column>
        <Column field="pincode"  style={{width:'100px'}} header="Pincode" filter ></Column>
        <Column field="vehicleNo"  style={{width:'100px'}} header="Vehicle No" filter ></Column>
        <Column field="vehicleType"  style={{width:'100px'}} header="Vehicle Type" filter ></Column>
        <Column field="stickerNo"  style={{width:'100px'}} header="Sticker No" filter ></Column>

      </DataTable>  
      </div>
    </div>
  );
}

FlatView.propTypes = {};

FlatView.defaultProps = {};

export default FlatView;
