import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SocietyService from '../../Service/SocietyService';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import authHeader from './../../authHeader';
import { InputText } from 'primereact/inputtext';


function DisplayIndexSetting() {

    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [GridData, setGridData] = useState(null);
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const toast = useRef(null);

    const dataTableEdit = {
        'products': setGridData
      };
    
    
    
      const onEditorValueChange = (productKey, props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        dataTableEdit[`${productKey}`](updatedProducts);
    
      }
    
    
      const inputTextEditor = (productKey, props, field) => {
    
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
      }
    
      const codeEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'Group Name');
      }

    let originalRows = {};
  let editingCellRows = {};

  const onRowEditInit = (event) => {
    originalRows[event.index] = { ...GridData[event.index] };
    // societyService.putGroupMaster(originalRows);
  }
  const onRowEditSave = (event) => {
    originalRows[event.index] = { ...GridData[event.index] };
    let data = event.data;
    let id = event.data.subGroupId;
    return axios.put(`${Config.apiUrl}/SubGroupMaster/updateDisplayOrder?id=${id}&societyId=${societyId}`, data,{headers:authHeader()})
      .then(response => {
        console.log(response)
        if (response.data.success) {
          toast.current.show({ severity: 'success',sticky:true, detail: response.data.msg });
        }
        else {
          toast.current.show({ severity: 'error', sticky:true, detail: response.data.msg });
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onRemove=()=>{
    // setStickyMode(false);
    window.location.reload();
  }

    const onRowEditCancel = (event) => {
        let product = [...GridData];
        GridData[event.index] = originalRows[event.index];
        delete originalRows[event.index];
    
        setGridData(product);
      }

    useEffect(()=> {
        societyService.getSubGroupMasterData(societyId).then(data => 
          {
            setGridData(data)
          });
        console.warn(GridData);
    },[]);

    return(
        <div>
            <div className="dataTable">
            <Toast ref={toast} position="center" onRemove={onRemove}/>
                <DataTable value={GridData} className="p-datatable-gridlines" editMode="row" onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel} onRowEditSave={onRowEditSave} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}>
                  <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                  <Column field="subGroupId" header="Sub-Group Id" filter style={{ width: "100px" }} ></Column>
                  <Column field="subGroupName" header="Sub-Group Name" filter  style={{ width: "350px" }}></Column>
                  <Column field="displayIndex" header="Display Index" editor={(props) => codeEditor('products', props)} filter style={{ width: "200px" }}></Column>
                  <Column rowEditor header="Action" headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} style={{ width: "50px" }}></Column>

                </DataTable>
            </div>
          </div>
    );
}
export default DisplayIndexSetting;