import { Checkbox } from "@material-ui/core";
import { Button } from "primereact/button";
import React from 'react'
import '../Popups/Popup.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SocietyService from '../Service/SocietyService';



function EmailPopup(props) {

    let history = useHistory();
    // const toast = useRef(null);
    const societyService = new SocietyService();
    const societyId = parseInt(localStorage.getItem('societyId'));
    const [wings, setWings] = useState([]);
    const [FlatNo, setFlatNo] = useState([]);
    const [selectedWing,setSelectedWing]=useState();
    const [selectedFlatNo,setSelectedFlatNo]=useState();
    const [ReportType,setReportType]=useState();

    const onChangeReportType=(e)=>{
       let value=e.currentTarget.value;
       setReportType(value);
       if(value==="Custom Report")
       {
        societyService
        .getWing(societyId)
        .then((data) =>{
        data.splice(0,0,{wing:'All'})
            setWings(
                data.map(({ wing }) => ({ label: wing, value: wing })),
        )
        });  
       }
    }

    const onChangeWing=(e)=>{
        let wing=e.currentTarget.value;
        setSelectedWing(wing);
        societyService
        .getFlatNo(wing,societyId)
        .then((data) =>{
        data.splice(0,0,{flatNo:'All'})
            setFlatNo(
                data.map(({ flatNo }) => ({ label: flatNo, value: flatNo })),
        )
        });  
    }
    const onChangeFlatNo=(e)=>{
        let FlatNo=e.currentTarget.value;
        if(FlatNo==='All')
        {
            setSelectedFlatNo(FlatNo);
        }
        else
        {
            FlatNo=parseInt(FlatNo);
            setSelectedFlatNo(FlatNo);
        }
    }

    const onNext=(e)=>{
        if(ReportType==="All Report")
        {
         history.push({
          pathname: '/Report',
          state: { detail:props.data.saveMaintainance,SocietyData:props.data.societyDetailsData,BillFootNote:props.data.billFootNote}
        })
        }
        if(ReportType==="Custom Report"){
            
            if(selectedFlatNo==="All" )
            {
                 props.data.saveMaintainance=props.data.saveMaintainance.filter(a=>a.wing ===selectedWing);
                history.push({
                    pathname: '/Report',
                    state: { detail:props.data.saveMaintainance,SocietyData:props.data.societyDetailsData,BillFootNote:props.data.billFootNote}
                  })
                return;     
            }
            if(selectedWing!=="All"){
            let index=props.data.saveMaintainance.findIndex(a=>a.flatNo===selectedFlatNo && a.wing ===selectedWing);
            history.push({
                pathname: '/Report',
                state: { detail:[props.data.saveMaintainance[index]],SocietyData:props.data.societyDetailsData,BillFootNote:props.data.billFootNote}
              })
            }
            else{
                history.push({
                    pathname: '/Report',
                    state: { detail:props.data.saveMaintainance,SocietyData:props.data.societyDetailsData,BillFootNote:props.data.billFootNote}
                  })
            }
        }
     }


    const Custom=(e)=>{
        let value=e;
     }
    return (props.trigger) ? (
        
        <div className="popup">
            <div className="popup-inner">
            <div className="popup__header">
                {/* <button classname="close-btn">CLOSE</button> */}
               <h3>Bill Report</h3>
                {/* {props.children} */}
            </div>
            <div  className="popup-content">
                <div>
                  
                    <label>Report Type:</label>
                    <select  style={{height:'25px',width:'150px'}} onChange={onChangeReportType} >
                    <option hidden value="">Select Report Type</option>
                        <option>All Report</option>
                        <option>Custom Report</option>
                    
                    </select>
                
                </div>
                {/* <select style={{ width: '175px' }} name="wing" className="height" ref={register} onChange={onChangeWing} >
                        <option hidden value="">Select Wing</option>
                            {wings.map(({ label, value: wings }) => (
                                <option key={wings} value={wings}>
                                {label}
                                </option>
                            ))}
                        </select> */}
               <div style={{ display: ReportType==="Custom Report" ? "block" : "none" }}>
                <label>Wings:</label>
                    <select  style={{height:'25px',width:'200px'}} onChange={onChangeWing}>
                    <option hidden value="">Select Wing</option>
                            {wings.map(({ label, value: wings }) => (
                                <option key={wings} value={wings}>
                                {label}
                                </option>
                            ))}
                    </select>

                    <label>Flat No:</label>
                    <select  style={{height:'25px',width:'200px'}} onChange={onChangeFlatNo}>
                    <option hidden value="">Select FlatNo</option>
                            {FlatNo.map(({ label, value: flatNo }) => (
                                <option key={flatNo} value={flatNo}>
                                {label}
                                </option>
                            ))}
                    </select>
                </div>

            </div>
            
            <div className="popup__footer">
             <Button onClick= {onNext}>Next</Button>
            <Button  onClick={()=>props.setTrigger(false)}>CLOSE</Button>
           </div>
           </div>
        </div>
    ):"";
}

export default EmailPopup

