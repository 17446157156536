import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
// import logo from './logo.svg';
import './PersonalDetails.css';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SocietyService from "../../Service/SocietyService";
// import PropertyDetails from './PropertyDetails';
import Config from '../../Config.json' 
import { Button } from 'primereact/button';
// import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import moment from 'moment';

function PersonalDetails({ setFlatId, id, societyId, setActiveTab }) {

  const validationSchema = Yup.object().shape({
    prefix: Yup.string()
      .required('Prefix is required'),
    // wing: Yup.string()
    //   .required('Wing is required'),
    flatNo: Yup.string()
      .required('Flat number is required'),
    email: Yup.string()
      .email('Email is invalid'),
    name: Yup.string()
      .required('name is required'),

  });

  const isAddMode = !id;
  const toast = useRef(null);
  var [personalDetails, SetPersonalDetails] = useState([]);
  const [addArray, setAddArray] = useState([]);
  const [birthDate, setBirthDate] = useState([]);
  const [admitDate, setAdmitDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [wing, setWings] = useState([]);
  const [isEditWingName, setIsEditWingName] = useState();
  const [saveDisabled, SetSaveDisabled] = useState(true);
  const [addDisabled, setAddDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [maxFlatNo, setMaxFlatNo] = useState();
  var [isEdit, setIsEdit] = useState(false);
  const [selectedWing, setselectedWing] = useState();
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [lastSelectedPersonalId, setLastSelectedPersonalId] = useState();
  const [societyDetails, SetSocietyDetails] = useState([]);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const societyService = new SocietyService();


  const { errors, register, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {

    societyId = parseInt(societyId);
    setIsEditWingName('');
    let flatInId = -1;
    if (id != undefined) {
      flatInId = parseInt(id);
      setNextDisabled(false);

    }

    if (societyId > 0) {
      societyService.getWing(societyId).then((data) => {
        data.sort((a, b) => a.wing.localeCompare(b.wing));
        setWings(

          data.map(({ wing }) => ({
            label: wing,
            value: wing,

          }))

        );

        data.sort((a, b) => a.wing.localeCompare(b.wing));

      })


      if (accessLevel >= 2) {
        setDisableButton(false);
      }
      else {
        setDisableButton(true);

      }

      //fetch personal details
      fetchPersonalDetails();
    }

    

  }, []);

  const fetchPersonalDetails = () => {
    societyService.getPersonalDetailsOnID(id).then(userIn => {
      //set value in fields
      const fields = ['wing', 'personalId', 'wingName', 'flatNo', 'prefix', 'name', 'email', 'gender', 'birthDate', 'age', 'relation', 'occupation', 'dateOfAdmition', 'mobile', 'address1', 'address2', 'city', 'pincode'];
      fields.forEach(field => setValue(field, userIn[0][field]));
      setUser(userIn[0]);
      setValue('wing',userIn[0].wing);
      setLastSelectedPersonalId(userIn[0].personalId);
      userIn[0].rowActive = true;
      userIn.forEach(a => {
        a.state = "unmodified"

        //set empty value here
        if (a.birthDateFormat === '0001-01-01') {
          a.birthDateFormat = "---";
        }
        else {
          setBirthDate(a.birthDateFormat);
        }
        if (a.age === 0) {
          a.age = '---';
        }

        if (a.admitDateFormat === '0001-01-01') {
          a.admitDateFormat = '---';
        }
        else {
          setAdmitDate(a.admitDateFormat);
        }
        if (a.mobile === '') {
          a.mobile = '---';
        }
        if (a.address1 === '') {
          a.address1 = '---';
        }
        if (a.pincode === 0) {
          a.pincode = '---';
        }
      })
      SetPersonalDetails(userIn);
      setIsEdit(true);

    });
    console.warn(personalDetails);
    SetSaveDisabled(true);
    setAddDisabled(false);
  }


  const toInputLowerCase = (e) => {
    e.currentTarget.value = ("", e.currentTarget.value).toLowerCase();
  }

  const onAddOwner = (data, e) => {
    console.warn(data);
    data.birthDateFormat = data.birthDate;
    data.admitDateFormat = data.dateOfAdmition;

    let existingValue = personalDetails.findIndex(x => x.name === data.name);
    if (existingValue < 0) {
      data.state = "New";
      SetPersonalDetails(personalDetails.concat(data));
      onsave(data);
    }
    else {
      toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Owner is already added' });
    }
    SetSaveDisabled(false);
    setNextDisabled(false);

  }

  const onEditOwner = (data, e) => {
    console.warn(data);
    data.birthDateFormat = data.birthDate;
    data.admitDateFormat = data.dateOfAdmition;

    let existingIndex = personalDetails.findIndex(x => x.personalId == data.personalId);
    if (existingIndex >= 0) {
      if (data.state != "New") {
        data.state = "modified";
      }
      personalDetails[existingIndex] = data;
      personalDetails = [...personalDetails];
      SetPersonalDetails(personalDetails);
      onsave(data);           // reset();
    }
    else {
    }
    SetSaveDisabled(false);
    setNextDisabled(false);
  }

  var ownerNames = []
  const onsave = (b) => {
    SetSaveDisabled(true);
    ownerNames.length = 0;
    b.flatId = id;
    if (b.state === "New") {
      ownerNames.push(b.name);
      createUser(b);
    }
    else if (b.state === "modified") {
      ownerNames.push(b.name);
      updateUser(b);
    }
  }


  const createUser = (data, e) => {
    data.personalId = "-1";
    if (data.birthDate === '') {
      data.birthDate = undefined;
    }
    if (data.age === '') {
      data.age = undefined;
    }
    if (data.dateOfAdmition === '') {
      data.dateOfAdmition = undefined;
    }
    if (data.pincode === '') {
      data.pincode = undefined;
    }
    if (data.wing === '') {
      data.wing = "NA";
    }
    setSpinnerVisible(true);
    axios.post(`${Config.apiUrl}/PersonalDetails/id=2`, data, { headers: authHeader() })
      .then(response => {
        setFlatId(response.data[0].flatId)
        console.warn(data);
        console.log(response)
        if (response.data[0].success) {
          toast.current.show({ severity: 'success', sticky: true, detail: response.data[0].msg });
          id = response.data[0].flatId;
          setNextDisabled(false);
          SetSaveDisabled(false);
          onReset();
        }
        else {
          toast.current.show({ severity: 'error', sticky: true, detail: response.data[0].msg });
        }
      }).catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error});
      })
      .finally(()=>{
        setSpinnerVisible(false);
        SetFocusValue(true);
      });
    data.state = "unmodified";
  }


  const updateUser = (data, e) => {
    if (data.birthDate === '') {
      data.birthDate = undefined;
    }
    if (data.age === '' || data.age==='---') {
      data.age = undefined;
    }
    if (data.dateOfAdmition === '') {
      data.dateOfAdmition = undefined;
    }
    if (data.pincode === '') {
      data.pincode = undefined;
    }
    setSpinnerVisible(true);
    axios.put(`${Config.apiUrl}/PersonalDetails/update?id=${data.personalId}`, data, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data[0].success === true) {
          toast.current.show({ severity: 'success', summary: 'Success Message', detail: response.data[0].msg });
          id = id;
          fetchPersonalDetails();
          SetSaveDisabled(false);
        }
        else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
        }
      }).catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error});
      })
      .finally(()=>{
        setSpinnerVisible(false);
        SetFocusValue(true);
      });
    data.state = "unmodified";
  }

  const [user, setUser] = useState({});
  const rowClass = (data) => {
    return {
      'selectedRow': data.rowActive
    }
  }

  const onDoubleClickEdit = (event) => {
    if (lastSelectedPersonalId !== event.data.personalId) {
      let selectedRow = personalDetails.find(a => a.personalId === lastSelectedPersonalId)
      selectedRow.rowActive = false;
    }
    var data = event.data;
    data.rowActive = true;
    rowClass(data);
    setLastSelectedPersonalId(data.personalId);
    const fields = ['wing', 'personalId', 'flatNo', 'prefix', 'name', 'gender', 'birthDate', 'age', 'relation', 'occupation', 'dateOfAdmition', 'mobile', 'address1', 'address2', 'city', 'pincode'];
    fields.forEach(field => setValue(field, data[field]));
    setBirthDate(data.birthDateFormat);
    setAdmitDate(data.admitDateFormat);
    setIsEdit(true);
    setAddDisabled(true);
  }

  const onChange = (e) => {
    setBirthDate(e.currentTarget.value);
    setValue('birthDate', e.currentTarget.value);
    let ageValue = e.currentTarget.value;
    setValue('age', calculate_age(ageValue));
  }
  function calculate_age(ageValue) {
    var age = Math.floor((new Date() - new Date(ageValue).getTime()) / 3.15576e+10);
    return age;
  }

  const onAdmitDateChange = (e) => {
    setAdmitDate(e.currentTarget.value);
    setValue('dateOfAdmition', e.currentTarget.value);
  }
  const onReset = () => {
    const fields = ['prefix', 'name', 'gender', 'birthDate', 'age', 'relation', 'occupation', 'dateOfAdmition', 'mobile'];
    fields.forEach(field => setValue(field, ''));
    setAddDisabled(false);
  }

  const onNext = (e) => {
    let insertIndex = personalDetails.findIndex(a => a.state === "New" || a.state === "modified");
    if (insertIndex >= 0) {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'There are unsaved records in grid. Please save it before moving it to new tab' });
    }
    setActiveTab(1);
  }
  const wingOnChange = (e) => {
    if (e != undefined) {
      let localWing = e.currentTarget.value;
      let idParam = parseInt(societyId);
       setselectedWing(localWing);
      let mflatNo;
      societyService.getMaxFlatNo(idParam, localWing).then((data) => {
        setMaxFlatNo(data);
        mflatNo=data;
        setValue('flatNo', data);
        societyAddress(localWing,mflatNo);
      })
    }

    if (isEditWingName.length != 0) {
      if (isEdit) {
        if (e.currentTarget.value != isEditWingName) {
          setValue('wing', isEditWingName);
          toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'In Edit mode wing name cant be changes' });
        }
      }
    }
  }

  const onFlatNo = (e) => {
    let flatnumber=e.currentTarget.value;
    societyAddress(selectedWing,flatnumber);
  }

  const societyAddress=(Wing,flatNo)=>{
  societyService.getSocietyDetailsOnID(societyId).then(data => {
    SetSocietyDetails(data);
    setValue('address1',`${Wing}-${flatNo} ${data[0].societyName}`);
    setValue('address2', data[0].address);
    setValue('city', data[0].city);
    setValue('pincode', data[0].pincode);
  });
}

  const InputFlatNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('flatNo', onlyNums);
  }

  return (
    <div className="form" autoComplete="off" >
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>


      <div className="PersonalDetails">
        <input type8="text" name="personalId" ref={register} hidden />
        <input type3="text" name="societyId" ref={register} value={societyId} hidden />

        <div className="Form-inline">
          <label > Wing<span style={{ color: 'red' }}>*</span>:</label>
          <select onChange={wingOnChange} className="SelectSmallWidth" name="wing" ref={register} autoFocus={focusValue} >
          <option hidden value="">Select Wing</option>
            {wing.map(({ label, value: wing }) => (
              <option key={wing} value={wing} >
                {label}
              </option>
            ))}
          </select>

          {/* <label className="WingLabel"> Wing Name:</label>
        <input type3="text" name="wingName" ref={register} /> */}

          <label className='FlatNo'> Flat No<span style={{ color: 'red' }}>*</span>:</label>
          <input readOnly={isEdit} type2="number" name="flatNo" autoComplete='off'placeholder='--Enter Flat No--' ref={register({ required: true })} onChange={onFlatNo}/>

          {/* <div className="p-field"> */}
          <label className='SmallLabel'> Email: </label>
          <input type="text" className="EmailTextBox" name="email" autoComplete='off' placeholder='abc@gmail.com' ref={register} onInput={toInputLowerCase} />
          {/* </div> */}
        </div>

        <div className="Form-inline">
          <label > Owner<span style={{ color: 'red' }}>*</span>:</label>
          <select className="SelectSmallWidth" name="prefix" ref={register({ required: true })}>
            <option>Mr.</option>
            <option>Mrs.</option>
          </select>

          <input type4="text" className="text" name="name" autoComplete='off' placeholder='--Enter Name--' ref={register({ required: true })} />
        </div>

        <div className="Form-inline">
          <label > Gender:</label>
          <select className="SelectSmallWidth" name="gender" ref={register}>
            <option>Male</option>
            <option>Female</option>
            <option>Transgender</option>
          </select>

          <label className="WingLabel">Birth Date:</label>
          <input type="Date" className="Date" name="birthDate" ref={register} value={birthDate} onChange={onChange} placeholder='' />

          <label className="SmallLabel"> Age:</label>
          <input readOnly type5="text" name="age" placeholder='Enter Age' ref={register} />

          <label className="label"> Relation:</label>
          <select className="Select2" name="relation" ref={register}>
            <option>Brother</option>
            <option>Daughter</option>
            <option>Father</option>
            <option>Mother</option>
            <option>Son</option>
            <option>Self</option>
          </select>
        </div>

        <div className="Form-inline">
          <label className="label"> Occupation:</label>
          <select className="Selectt2" name="occupation" ref={register} >
            <option>Self Employed</option>
            <option>Bussiness Man</option>
            <option>Farmer</option>
          </select>       


          <label style={{width:'200px'}}>Date of Admition:</label>

          <input type="Date" className="Date" name="dateOfAdmition" ref={register} value={admitDate} onChange={onAdmitDateChange} />

          <label className="label"> Mobile:</label>
          <input type="number" name="mobile" className="number" autoComplete='off' placeholder='Enter Mobile Number' ref={register} />


        </div>

        <div className="Form-inline">
          <label className="label">Address:</label>
          <input type7="text" name="address1" autoComplete='off' placeholder='--Enter Address Here--' ref={register} />

        </div>

        <div className="Form-inline">
          <label className="label">Address:</label>
            <input type7="text" name="address2" autoComplete='off' placeholder='--Enter Address Here--' ref={register} />
          </div>

        <div className="Form-inline">
          <label className="label"> City:</label>
          {/* <select className="Select3" name="city" ref={register}>
            <option>Mumbai</option>
            <option>Pune</option>
            <option>Satara</option>
          </select> */}
          <input readOnly className='City' type="text" name="city" placeholder='-Enter City Name-' ref={register} />


          <label className="SmallLable"> Pincode:</label>
          <input readOnly type="number" name="pincode" className="number" placeholder ="123456" ref={register} />
        </div>

        <div className="invalid-feedback">
          {/* <div>{errors.wing?.message}</div> */}
          <div> {errors.flatNo?.message}</div>
          <div>{errors.email?.message}</div>
          <div> {errors.prefix?.message}</div>
          <div>{errors.name?.message}</div>
        </div>


        <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px'
          }}>
          <div className="p-field">

            <Button label="ADD OWNER" className="btn" disabled={addDisabled} type="submit " onClick={handleSubmit(onAddOwner)} />
          </div>
          <div className="p-field">

            <Button label="EDIT OWNER" className="btn" disabled={!addDisabled} type="submit " onClick={handleSubmit(onEditOwner)} />
          </div>

          <div className="p-field">

            <Button disabled={nextDisabled} label="NEXT" className="btn" onClick={onNext} />
          </div>

          <div className="p-field ">
            <Button label="RESET" type="reset" className="btn" onClick={onReset} disabled={DisableButton} />
          </div>


        </div>

        <Divider />
        <div >
          <DataTable value={personalDetails} header="Personal Details" rowClassName={rowClass} className="p-datatable-sm" onRowDoubleClick={onDoubleClickEdit} >
            <Column field="wing" header="Wing" ></Column>
            <Column field="flatNo" header="Flat No"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="name" header="Owner Name" ></Column>
            <Column field="gender" header="Gender"></Column>
            <Column field="birthDateFormat" header="Birth Date" ></Column>
            <Column field="age" header="Age"></Column>
            <Column field="relation" header="Relation" ></Column>
            <Column field="occupation" header="Occupation" ></Column>
            <Column field="admitDateFormat" header="Date of Admition" ></Column>
            <Column field="mobile" header="Mobile" ></Column>
            {/* <Column field="address1" header="Address" ></Column> */}
            <Column field="city" header="City" ></Column>
            <Column field="pincode" header="Pincode" ></Column>

          </DataTable>

        </div>
        {/* <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>

          <div className="p-field">

            <Button disabled={saveDisabled} label="Save" type="submit " onClick={handleSubmit(onsave)} />
          </div>
          
        </div> */}
      </div>

    </div>
  );
  // eslint-disable-next-line no-unreachable

}

export default PersonalDetails;


