import { Button } from 'primereact/button';
import '../Report/MemberReceiptReport.css';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';       
import { Dropdown } from 'primereact/dropdown';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { List } from '@material-ui/core';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { useParams } from 'react-router-dom';
import converter from 'number-to-words';
import { useLocation } from "react-router-dom";


var BillData = [];
var societyData = [];
var FootNote = [];
var BillDateValue;
var selectedBillDate;

function SelectedBillPrint() { 
  const location = useLocation();

  const societyId = parseInt(localStorage.getItem('societyId'));
  const toast = useRef(null);
  const [BillsDate, setBillsDate] = useState([]);
  const [BillDateValue, setBillDateValue] = useState(null);
  const [BillDate, setBillDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [BillDatas, setBillData] = useState([]);
  const [selectedBill, setSelectedBill] = useState();
  const [billsMetaData, setBillsMetaData] = useState();
  BillData = BillDatas;
  var [spinnerVisible, setSpinnerVisible] = useState(false);
  //var converter = require('number-to-words');
  //const amountInWords = ''; // or let amountInWords = '';

  const societyService = new SocietyService();
  const tableName = sessionStorage.getItem('tblName');


  // Override the toWords function to include spaces after "forty" and "four"
  const originalToWords = converter.toWords;
  converter.toWords = (number) => {
    let words = originalToWords(number);
  
    // Add space after 
    words = words.replace(/-/g, ' ');
  
    // Add space after 
    words = words.replace(/, /g, ' ');
  
    return words;
  };
  
  const amountInWords = (amount) => {
    const integerPart = Math.floor(amount);
    const decimalPart = Math.round((amount - integerPart) * 100); // Assuming you want two digits in the decimal part
  
    let result = '';
  
    // Process integer part
    if (integerPart >= 100000) {
      result += `${converter.toWords(Math.floor(integerPart / 100000))} Lakh `;
    }
  
    if (integerPart % 100000 > 0) {
      result += `${converter.toWords(integerPart % 100000)} `;
    }
  
    // Process decimal part
    if (decimalPart > 0) {
      result += '& ' + converter.toWords(decimalPart) + ' Paise';
    }
  
    return result.trim().replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  };
  
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

    useEffect(() => {
      // setIsFlatChange(false);
      societyService.getBillsDate(societyId, tableName).then(data => {
        setBillsDate(data);
        setBillsMetaData(data);
        console.warn(data[0]);
      });
      viewBills();
  
    }, []);
  
  //   const onBillChange = (e) => {
  //     let bill = e.value;
  //     setSelectedBill(bill);
  
  //   }
  //   const onDateChange = (e) => {
  //     let Date = e.currentTarget.value;
  //     // Date = moment(Date).format("DD-MM-YYYY");
  
  //     setBillDate(Date);
  
  //     let bDate = BillsDate.find(a => a.billDate === moment(Date).format("DD-MM-YYYY"));
  //     if (bDate !== undefined) {
  //       generationId = bDate.generationId;
  //     }
  //   }
    const viewBills = (e) => {
      setSpinnerVisible(true);
     
      /*     if (generationId === undefined && BillDate !== undefined) {
            toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: " Bill is not Available on this Date" });
          } */
  
      // let bDate = moment(BillDate).format("DD-MM-YYYY");
      // const dateString = BillDate
    
  
    // Format the date as needed (e.g., "MM/dd/yyyy")
    let details=location.state.printDetails;
    const formattedBillDate = moment(details.date).format('YYYY-MM-DD');
    let isSelectedBill=true;
   
    societyService.getSavedMaintainance(isSelectedBill,details.flatId,societyId,details.generationId, formattedBillDate, tableName)
      .then(Bdata => {
        Bdata.forEach(d => {
          d.amountInWord = amountInWords(d.maintainanceReportInfos[0]?.totalAmountPayable.toFixed(2));
        })
        setBillData(Bdata);
      })
        .catch(error => {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Network error" });
        })
        .finally(() => {
          setSpinnerVisible(false);
        })
        const amountInWords = (amount) => {
          return converter.toWords(amount).replace(/(\w)(\w*)/g, function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
          });
        };
      //get society details from db
      societyService.getSocietyDetailsOnID(societyId).then(Sdata => {
        societyData = Sdata;
      });
  
      //getting bill footNote
      societyService.getBillFootNote(societyId)
        .then(FootNotedata => {
          FootNote = FootNotedata;
        });
      // setSpinnerVisible(false);
    }

    return (
      <div className='MemberBill'>
        <div class="card">
          <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
          <Toast ref={toast} position="center" />
  
          
          <Button label="PRINT" onClick={handlePrint} />
  
          <SelectedMemberBill ref={componentRef} amountInWords={amountInWords} />
    </div>
   
      </div>
    );
}

const  portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class SelectedMemberBill extends React.Component {
  render() {
    const societyService = new SocietyService();
    const { amountInWords } = this.props;


    return (
      <div class="MemberBills">

        <div>
          <div style={{ height: '100%' }}>
            {BillData.map((data, index) =>
              <div className="PageBreak" >
                <div className='SocietyName'>
                  <div class="font"><text>{societyData[0]?.societyName}</text></div>
                  <div>
                    <label>Registration No:</label><text>{societyData[0].registerNo}</text>
                    <label> Dated:</label><text>{moment(societyData[0].registerDateFormat).format('DD-MM-YYYY')}</text>
                  </div>
                  <div>
                    <text>
                      {societyData[0].address}
                    </text>
                  </div>
                </div>
                <div className="Bill">
                  BILL
                </div>

                <div>
                  <div className="border">
                    <div>
                      <label className="alignleft" style={{ width: '40%' }}>Flat No: {BillData[index].maintainanceReportInfos[0]?.wing} - {BillData[index].maintainanceReportInfos[0]?.flatNo}</label>
                      <label className="aligncenter" >Area in Sqft:{BillData[index].maintainanceReportInfos[0]?.area}</label>
                      <label className="alignright"> Bill No: {BillData[index].maintainanceReportInfos[0]?.billInvNo}</label>
                    </div>
                    <div>
                      <label className="alignleft" style={{ width: '60%' }}>Name: {BillData[index].maintainanceReportInfos[0]?.name}</label>
                      <label className="alignright"> Bill Date:{moment(BillData[index].maintainanceReportInfos[0]?.billDate).format('DD-MM-YYYY')}</label>
                    </div>

                    <div>
                      <label className="alignleft" style={{ width: '40%' }}>Bill For:{BillData[index].maintainanceReportInfos[0]?.billFor}</label>
                      <label className="alignright">Due Date:{moment(BillData[index].maintainanceReportInfos[0]?.dueDate).format('DD-MM-YYYY')}</label>
                    </div>
                  </div>

                  <div class="Table">
                    <table>
                      <thead className='headerColor'>
                        <th style={{ width: '10%' }}>Sr</th>
                        <th style={{ width: '75%' }}>Particulars</th>
                        <th style={{ width: '15%' }}>Amount</th>
                      </thead>
                      <tbody>
                        {
                          BillData[index].maintainanceInfos.map((item) => (
                            <tr key={item}>
                              <td style={{ padding: '4px' }}>{item.serialNo}</td>
                              <td>{item.glTitle}</td>
                              <td style={{ textAlign: 'right' }}>{item.amount.toFixed(2)}</td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>

                    <table style={{ marginLeft: "50%", width: "50%" }} >
                      <tr>
                        <th style={{ width: '70%' }}>Total</th>
                        <td style={{ textAlign: 'right' }}>{BillData[index]?.currentTotalFromGLHeads.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>Add: Interest</th>
                        <td style={{ textAlign: 'right' }}>{BillData[index].maintainanceReportInfos[0]?.currentInterest.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>Add: Dpc </th>
                        <td style={{ textAlign: 'right' }}>{BillData[index].maintainanceReportInfos[0]?.dpc.toFixed(2)}</td>
                      </tr>
                    </table>


                    <table style={{ marginLeft: "50%", width: "50%" }} >
                      <tr>
                        <th style={{ width: '40%' }}>Principal Arrears</th>
                        <td style={{ width: '30%', textAlign: 'right' }}>{BillData[index].maintainanceReportInfos[0]?.principal.toFixed(2)}</td>
                        <td rowspan='3' style={{ textAlign: 'right' }}>{BillData[index].maintainanceReportInfos[0]?.totalArrears.toFixed(2)}</td>
                      </tr>

                      <tr>
                        <th style={{ width: '40%' }}>Interest Arrears </th>
                        <td style={{ width: '30%', textAlign: 'right' }}>{BillData[index].maintainanceReportInfos[0]?.interestArrears.toFixed(2)}</td>
                      </tr>

                      <tr>
                        <th style={{ width: '40%' }}>Dpc Arrears </th>
                        <td style={{ width: '30%', textAlign: 'right' }}>{BillData[index].maintainanceReportInfos[0]?.dpcArrears.toFixed(2)}</td>
                      </tr>

                    </table>
                    <table style={{ width: "100%" }} >
                    <tr className='headerColor'>
                  <th style={{ width: '50%', fontSize: '12px' }}>Rs:{amountInWords(BillData[index].maintainanceReportInfos[0]?.totalAmountPayable)} Only</th>
                  <th style={{ width: '35%' }}>Total Amount Payable </th>
                  <td style={{ textAlign: 'right' }}><b>{BillData[index].maintainanceReportInfos[0]?.totalAmountPayable.toFixed(2)}</b></td>
                </tr>
                    </table>
                  </div>

                  <div className="BillFootNote">
                    <label><b>E.&.O.E</b></label><br />
                    <label>{FootNote[0].footNote1}</label><br />
                    <label>{FootNote[0].footNote2}.</label><br />
                    <label>{FootNote[0].footNote3}</label><br />
                    <label>{FootNote[0].footNote4}</label><br />
                    <label>{FootNote[0].footNote5}</label><br />
                    <label>{FootNote[0].footNote6}</label><br />
                    <label>{FootNote[0].footNote7}</label><br />
                    <label>{FootNote[0].footNote8}</label><br />
                  </div>

                  <div style={{ height: '100px' }}>
                    <label className="alignright">For {societyData[0].societyName}</label><br />
                    <label className="alignright" style={{ marginTop: "40px" }}>Authorised Signature</label>
                  </div>

                  <div className="Receipt">
                    {/* <div style={{ display: BillData[index].memberReceiptInfo[0] ? "block" : "none" }}>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft" style={{ width: '40%' }}>Receipt No:<text>{BillData[index]?.memberReceiptInfo[0]?.rctNo}</text>
                        </label>

                        <label className="MRalignright"> Date:{BillData[index]?.memberReceiptInfo[0]?.dateFormat}</label>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft" >Received with Thanks From: {BillData[index]?.memberReceiptInfo[0]?.name}</label>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft" >Unit No: {BillData[index]?.memberReceiptInfo[0]?.wing}-{BillData[index]?.memberReceiptInfo[0]?.flatNo}</label>
                      </div>

                      <div style={{ height: '100px' }}>
                        <div className="p-formgroup-inline">
                          <label className="MRalignleft">Amount ₹: {BillData[index]?.memberReceiptInfo[0]?.receiptTotal} </label>
                          <label className="MRalignleft">{converter.toWords(BillData[index].memberReceiptInfo[0]?.receiptTotal || 0)}</label>
                        </div>

                        <div className="p-formgroup-inline">
                          <label className="MRalignleft">By Cheque No: {BillData[index]?.memberReceiptInfo[0]?.chqNo}</label>
                        </div>
                        <div className="p-formgroup-inline">
                          <label className="MRalignleft">Drawn on: {BillData[index].memberReceiptInfo[0]?.bankName}</label>
                        </div>
                        <div className="p-formgroup-inline">
                          <label className="MRalignleft">Narration: {BillData[index].memberReceiptInfo[0]?.remarks}</label>
                        </div>

                        <label className="MRalignleft" style={{ marginTop: '1px' }}>This Receipt is Valid Subject to realisation of cheque..</label>
                      </div>
                    </div> */}
                   
                    <div style={{ display: BillData[index].memberReceiptInfo[0] ? "block" : "none" }}>
                    <h3>Receipt</h3>
                      <div className="p-formgroup-inline">
                        <div className="p-formgroup-inline">
                       

                          <label className="MRalignleft" >Receipt No
                          </label>
                          <text>: {BillData[index].memberReceiptInfo[0]?.rctNo}</text>
                        </div>
                        {/* <div className="p-formgroup-inline"> */}


                        <label className="alignRight"> Date: {BillData[index].memberReceiptInfo[0]?.dateFormat}</label>
                        {/* </div> */}
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft" >Received with Thanks From </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.name}</text>

                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft" >Unit No </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.wing}-{BillData[index].memberReceiptInfo[0]?.flatNo}</text>
                      </div>

                      {/* <div style={{ height: '100px',margin:'4px' }}> */}
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft">Amount ₹ </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.receiptTotal} </text>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft">Rupees in words </label>
                        <text>: "{converter.toWords(BillData[index].memberReceiptInfo[0]?.receiptTotal || 0).toUpperCase()} ONLY"</text>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft">Mode </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.mode}</text>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft">By Cheque No </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.chqNo}</text>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft">Drawn on </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.bankName}</text>
                      </div>
                      <div className="p-formgroup-inline">
                        <label className="MRalignleft">Narration </label>
                        <text>: {BillData[index].memberReceiptInfo[0]?.remarks}</text>

                      </div>
                      {/* <div style={{ height: '100px' }}>
                        <text className="alignright"><b>For {societyData[0]?.societyName}</b></text><br />
                        <text className="alignright" style={{ marginTop: "40px" }}><b>Authorised Signature</b></text>
                      </div> */}
                      {/* <text className="MRalignleft" style={{ marginTop: '30px' }}>This Receipt is Valid Subject to realisation of cheque..</text> */}

                    </div>
                    <div style={{ display: !BillData[index].memberReceiptInfo[0] ? "block" : "none" }}>
                      <div style={{ textAlign: 'center' }}>
                        <h1><b>No Receipt</b></h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>


    );
  }
}
export default SelectedBillPrint;
  