/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
//import "primeicons/primeicons.css";

/* import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css"; */

// import logo from "./logo.svg";


// import Register from "./Register";
// import Nav from "./Nav";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
//import '../../components/Transactions/generalReceipt.css'
import React, { useState, useEffect, useContext, useRef } from 'react';
import SocietyService from "../../Service/SocietyService";
import { BrowserRouter, useHistory, useParams } from 'react-router-dom';
import Config from '../../Config.json';
import './GL_Master.css'
// import { SocietyContext } from "../../SocietyContext";
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { usePopup } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import authHeader from '../../authHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";


function GL_Master() {
  const validationSchema = Yup.object().shape({
    // title: Yup.string()
    //     .required('Title is required'),
    glTitle: Yup.string()
      .required('GL-Title is required'),

    groupName: Yup.string()
      .required('Group Name is required'),

    subGroupName: Yup.string()
      .required('Sub-Group Name is required'),
    openingBalance: Yup.string()
      .required('Opening Balance is required'),
    balanceDate: Yup.string()
      .required('Balance Date is required '),

    amountType: Yup.string()
      .required('Mode is required ,'),
  });

  let history = useHistory();
  const toast = useRef(null);

  const selectedValue = "";
  const [products, setProducts] = useState(null);
  const [product, setProduct] = useState([]);
  const [groupName, setgroupName] = useState([]);
  const [subGroupName, setSubGroupName] = useState([]);
  const [subGroupNameValue, setSubGroupNameValue] = useState(null);
  const [glId, setGlId] = useState(null);
  const [glTitle, setGlTitle] = useState();
  const [GlData, setGlData] = useState([]);
  const [GlDataOnId, setGlDataOnId] = useState([]);
  const [IsTax, setIsTaxable] = useState([]);
  const [IsDepriciation, setIsDepriciation] = useState([]);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const [Id, setMainId] = useState(null);
  const [balanceDate, setBalanceDate] = useState([]);
  const [IsBilling, setIsBilling] = useState();
  const [IsIntTrue, setIntFree] = useState();
  const [IsExpLedgerTrue, setExpenseLedger] = useState();
  const isAddMode = !Id;
  const societyService = new SocietyService();
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  var societyId = parseInt(localStorage.getItem('societyId'));
  const [DisableButton, setDisableButton] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [amountType, setAmountType] = useState();
  const tableName = sessionStorage.getItem('tblName');
  const [lowercaseWarning, setLowercaseWarning] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    const enteredValue = event.target.value;
    const titleCaseValue = enteredValue
      .toLowerCase()
      .replace(/\b\w/g, (match) => match.toUpperCase());

    setInputValue(titleCaseValue); // Update the displayed value
    setLowercaseWarning(enteredValue !== titleCaseValue); // Check if conversion was necessary
  };

  const { errors, register, handleSubmit, reset, setValue } =
    useForm(
      { resolver: yupResolver(validationSchema) }
    );

  useEffect(() => {
    //getting group name
    societyService
      .getGroupName(societyId)
      .then((data) =>
        setProduct(
          data.map(({ groupName }) => ({ label: groupName, value: groupName }))
        )
      );

    //getting sub group name
    // societyService
    //   .getSubGroupName(societyId)
    //   .then((data) =>
    //     setSubGroupName(
    //       data.map(({ subGroupName }) => ({
    //         label: subGroupName,
    //         value: subGroupName,
    //       }))
    //     )
    //   );

    //getting Gl master Data
    societyService.getGlMasterData(societyId).then(data => {
      data.forEach(d => {


        if (d.balanceDateFormat === '01-01-0001') {
          d.balanceDate = '---';
        }
      })
      setGlData(data);

    })

    //getting Gl master id

    societyService.getGlMasterId(societyId).then((data) => setGlId(data));

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }
  }, []);

  const onSubmit = (data, e) => {
    // if (window.confirm("Is All Owner Added"))
    // {
    data.TransactionTable = tableName;
    return isAddMode ? createGlMaster(data) : updateGlMaster(data);
  };

  const createGlMaster = (dataGL) => {
    dataGL.societyId = societyId;
    console.warn(dataGL);
    // let isBilling;
    if (dataGL.gst === "" || dataGL.gst === undefined) {
      dataGL.gst = -1;
    }
    if (dataGL.tds === "" || dataGL.tds === undefined) {
      dataGL.tds = -1;
    }

    // if (!dataGL.isBilling) {
    //   dataGL.isBilling = "false";
    // }
    // if (!dataGL.isExpLedger) {
    //   dataGL.isExpLedger = "false";
    // }
    // if (!dataGL.isIntFree) {
    //   dataGL.isIntFree = "false";
    // }
    if (dataGL.isBilling) {
      dataGL.isBilling = "true";
    }
    else {
      dataGL.isBilling = "false";
    }

    if (dataGL.isExpLedger) {
      dataGL.isExpLedger = "true";

    } else {
      dataGL.isExpLedger = "false";
    }

    if (dataGL.isIntFree) {
      dataGL.isIntFree = "true";

    } else {
      dataGL.isIntFree = "false";
    }
    if (dataGL.openingBalance === '') {
      dataGL.openingBalance = 0;
    }
    if (dataGL.isTaxable === '') {
      dataGL.isTaxable = 'No';
    }
    if (dataGL.isDepriciation === '') {
      dataGL.isDepriciation = 'No';
    }


    let deprecVal = -1;
    deprecVal = parseInt(dataGL.depreciation);
    dataGL.depreciation = isNaN(deprecVal) ? 0 : deprecVal;

    let url = `${Config.apiUrl}/GlMaster/Insert`;
    setSpinnerVisible(true);
    axios
      .post(url, dataGL, { headers: authHeader() })
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });
          GlData.push(dataGL);
          setGlData(GlData);
          setGlId(glId + 1);
        } else {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data.msg });
        }

      })
      .catch((error) => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      }).finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);

      })

  };

  const updateGlMaster = (data, e) => {
    setSpinnerVisible(true);
    data.societyId = societyId;
    if (data.gst === "" || data.gst === undefined) {
      data.gst = -1;
    }
    if (data.tds === "" || data.tds === undefined) {
      data.tds = -1;
    }
    if (data.isBilling) {
      data.isBilling = "true";
    }
    else {
      data.isBilling = "false";
    }

    if (data.isExpLedger) {
      data.isExpLedger = "true";

    } else {
      data.isExpLedger = "false";
    }

    if (data.isIntFree) {
      data.isIntFree = "true";

    } else {
      data.isIntFree = "false";
    }

    let deprecVal = -1;
    deprecVal = parseInt(data.depreciation);
    data.depreciation = isNaN(deprecVal) ? 0 : deprecVal;

    axios
      .put(`${Config.apiUrl}/GlMaster/update?id=${Id}`, data, { headers: authHeader() })
      .then((response) => {
        console.warn(data);
        console.log(response);
        if (response.data.success) {
          toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });

        } else {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data.msg });
        }

      })
      .catch((error) => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      }).finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);

      });


  };

  const onEdit = (e) => {
    if (selectedRadioData != null) {

      if (selectedRadioData.length > 1) {
        toast.current.show({ severity: 'warn', summary: 'Alert', detail: "Please select only one" });
        return;
      }
      selectedRadioData.forEach((b) => {
        let id = b.id;
        let editId = b.glId;
        // let data=b;


        if (editId <= 5) {
          toast.current.show({ severity: 'warn', summary: 'Alert', detail: "You cannot edit this" });
          return;
          // window.location.reload();
        }

        else {

          societyService.getGlMasterOnID(id).then((data) => {
            if (data[0].isBilling === 'false') {
              data[0].isBilling = false;
            }
            else {
              data[0].isBilling = true;

            }

            if (data[0].isIntFree === 'false') {
              data[0].isIntFree = false;
            }
            else {
              data[0].isIntFree = true;

            }

            if (data[0].isExpLedger === 'false') {
              data[0].isExpLedger = false;
            }
            else {
              data[0].isExpLedger = true;

            }

            const fields = [
              "glId",
              "glTitle",
              "groupName",
              "subGroupName",
              "openingBalance",
              "amountType",
              "balanceDate",
              "isBilling",
              "isIntFree",
              "isExpLedger",
              "depreciation",
              "isTaxable",
              "gst",
              "tds",
              "isDepriciation",
            ];
            fields.forEach((field) => setValue(field, data[0][field]));
            setMainId(data[0].id);
            setGlId(data[0].glId);
            setGlTitle(data[0].glTitle);
            setBalanceDate(data[0].bDate);
            setgroupName(data[0].groupName);
            setSubGroupValNameFunc(data[0].groupName, data[0].subGroupName);
            setAmountType(data[0].amountType)
            // setIsBilling(data[0].isBilling);
            // setIntFree(data[0].isIntFree);
            // setExpenseLedger(data[0].isExpLedger);
            setIsTaxable(data[0].isTaxable);
            setIsDepriciation(data[0].isDepriciation);
            console.warn(IsExpLedgerTrue);


            // });
          })
        }
      })
    }
    else {
      toast.current.show({ severity: 'warn', summary: 'Alert', detail: "PLEASE SELECT ROW" });

    }
  };

  const onChangeBalanceDate = (e) => {
    setBalanceDate(e.currentTarget.value);
    setValue("balanceDate", e.currentTarget.value);
  };

  const changeExpLedger = (e) => {
    if (e.currentTarget.value === 'on') {
      setExpenseLedger("true");
    } else {
      setExpenseLedger("false");
    }
    // setValue("isExpLedger", e.currentTarget.value);
  };
  const changeIsBilling = (e) => {
    if (e.currentTarget.value === 'on') {
      setIsBilling("true");
    } else {
      setIsBilling("false");
    }
    // setValue("isBilling", IsBilling);
  };

  const ChangeIsIntFree = (e) => {
    if (e.currentTarget.value === 'on') {
      setIntFree("true");
    } else {
      setIntFree("false");
    }
    // setValue("isIntFree", e.currentTarget.value);
  };
  const ChangeGlId = (e) => {
    setMainId(e.currentTarget.value);
    // setValue('isIntFree',e.currentTarget.value);
  };

  const onDelete = (event) => {
    if (selectedRadioData != null) {
      selectedRadioData.forEach((d) => {
        let glid = d.glId;
        if (glid <= 5) {
          toast.current.show({ severity: 'warn', summary: 'Alert', detail: "This Gl is Predifined ,you cannot delete this " });
          return;
        }
        else {
          toast.current.show({
            severity: 'warn', sticky: true, content: (
              <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                <div className="p-text-center">
                  <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                  <h4>Are you sure?</h4>
                  <p>Confirm to Delete</p>
                </div>
                <div className="p-grid p-fluid">
                  <div className="p-col-6" >
                    <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
                  </div>
                  <div className="p-col-6">
                    <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
                  </div>
                </div>
              </div>
            )
          });
        }
      })
    }
    else {
      toast.current.show({ severity: 'warn', summary: 'Alert', detail: "PLEASE SELECT ROW " });
    }
  }

  const onYes = () => {
    toast.current.clear();
    selectedRadioData.forEach((d) => {
      let id = d.id;
      let data = GlData.filter((b) => b.id != d.id);
      setGlData(data);
      setSpinnerVisible(true);
      axios
        .delete(`${Config.apiUrl}/GlMaster/delete?id=${id}`, { headers: authHeader() })
        .then((response) => {
          console.log(response);
          if (response.data != null) {
            toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });

          }
          else {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Success', detail: response.data.msg });
          }
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          setSpinnerVisible(false);
        })
    });
  };

  const onNo = () => {
    toast.current.clear();
    return;
  };

  const onCancel = (glId) => {
    reset();
    window.location.reload();
  };

  const onRemove = () => {
    // setStickyMode(false);
    window.location.reload();
  }

  const header = (
    <div className="table-header">
      <div>
        GL Master
      </div>

      <div>
        {/* <Link to={`/Flat_Master/edit/${selectedRadioData.id}`}> */}
        <Button
          icon="pi pi-pencil"
          value="Edit"
          tooltip="Edit"
          className="btnEdit1" style={{margin:'10px'  ,height:'40px'}} 
          onClick={onEdit}
          disabled={DisableButton}
        />
        {/* </Link>     */}
        <Button
          icon="pi pi-trash"
          value="Delete"
          className="btn"  style={{background:'red',margin:'10px',height:'40px'}}
          onClick={onDelete}
          disabled={DisableButton}
        />
      </div>
    </div>
  );

  const onTaxableValue = (e) => {
    const IsTaxable = e.currentTarget.value;
    setIsTaxable(IsTaxable);
  };

  const onDepriciationValue = (e) => {
    const isDepriciation = e.currentTarget.value;
    setIsDepriciation(isDepriciation);
  };

  const setSubGroupValNameFunc = (groupName, subGroupName) => {
    societyService
      .getSubGroupName(societyId, groupName)
      .then((data) => {

        setSubGroupName(
          data.map(({ subGroupName }) => ({
            label: subGroupName,
            value: subGroupName,
          }))
        );
        setSubGroupNameValue(subGroupName);
        setgroupName(groupName);

      });
  };


  const OnChangeGroupName = (e) => {
    let selectedGroupName = e.currentTarget.value;
    setgroupName(selectedGroupName);
    societyService
      .getSubGroupName(societyId, selectedGroupName)
      .then((data) =>
        setSubGroupName(
          data.map(({ subGroupName }) => ({
            label: subGroupName,
            value: subGroupName,
          }))
        )
      );

  }

  // const Group =(e)=> {
  //   let value=e.currentTarget.value;
  //   subGroupName.filter(a=>a.)
  // }



  return (

    <div className="GL_Master" style={{ height: '100%', padding: '10px', marginTop: "10px", backgroundColor: "Info" }}>
      <Toast ref={toast} position="center" onRemove={onRemove} />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px' }}>GL Id<span style={{ color: 'red' }}>*</span>:</label>
          <div className="p-col">
            <input
              type="text"
              name="glId"
              value={glId || ""}
              ref={register}
              readOnly
              style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              onChange={ChangeGlId}
            />
          </div>
        </div>
        <div>
        <label className="label200" style={{ width: '150px' }}>GL Title<span style={{ color: 'red' }}>*</span>:</label>
      <input
        type="text"
        name="glTitle"
        ref={register({
          required: 'GL Title is required',
         
        })}
        // value={inputValue} // Use the state value for the displayed value
        // onChange={handleInputChange}
        style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }} autoFocus={focusValue} autoComplete="off" 
      />
      <div className="invalid-feedback">{errors.glTitle?.message}</div>
    </div>
      </div>
      <div className="p-formgroup-inline ">
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px', color: 'black' }}>Group Name<span style={{ color: 'red' }}>*</span>:</label>
          <div className="p-col">
            <select name="groupName" className="selectStyle" value={groupName} style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }} ref={register({ required: true })} onChange={OnChangeGroupName}>
              <option hidden value=""> Select Group Name</option>
              {product.map(({ label, value: GroupValue }) => (
                <option key={GroupValue} value={GroupValue}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px' }}>Sub-Group Name<span style={{ color: 'red' }}>*</span>:</label>
          <div className="p-col">
            <select name="subGroupName" className="selectStyle" value={subGroupNameValue} ref={register({ required: true })} style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              onChange={e => setSubGroupNameValue(e.target.value)}>
              <option hidden value=""> Select SubGroup Name</option>
              {subGroupName.map(({ label, value: SubGroupValue }) => (
                <option key={SubGroupValue.option} value={SubGroupValue}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="invalid-feedback">{errors.groupName?.message} {errors.subGroupName?.message}</div>

      </div>


      <div className="p-formgroup-inline ">
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px' }} >Opening Balance:</label>
          <div className="p-col">
            <input
              type="text"
              style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              name="openingBalance"
              ref={register({ required: true })}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px' }} >Type:</label>
          <div className="p-col">
            <select name="amountType" className="selectStyle" style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }} ref={register({ required: true })}>
              <option hidden value=""> Select Amount Type</option>
              <option> Debit</option>
              <option>Credit</option>

            </select>
          </div>
        </div>
        {/* <div className="invalid-feedback">{errors.openingBalance?.message} {errors.amountType?.message}</div> */}

      </div>


      <div className="p-formgroup-inline ">
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '151px' }} >Balance Date:</label>
          <div className="p-col">
            <input
              type="Date"
              style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              name="balanceDate"
              ref={register({ required: true })}
              value={balanceDate}
              onChange={onChangeBalanceDate}
            />
          </div>
        </div>
        <div className="p-field p-grid" style={{ border: "1px solid black", borderRadius: "6px" }} >
          <label className="label200" style={{ width: '80px', padding: '1px' }} >Is Billing:</label>
          <div className="p-col">
            <input
              type="checkbox"

              name="isBilling"
              ref={register}

              onChange={changeIsBilling}

            />
          </div>
        </div>

        <div className="p-field p-grid" style={{ border: "1px solid black", borderRadius: "6px" }}>
          <label className="label200" style={{ width: '130px', padding: '1px' }} >Is Interest Free:</label>
          <div >
            <input
              type="checkbox"
              name="isIntFree"

              ref={register}
              // checked={IsIntTrue}
              onChange={ChangeIsIntFree}
            // value={IsIntTrue}
            />
          </div>
        </div>



        <div className="p-field p-grid" style={{ border: "1px solid black", borderRadius: "6px" }}>
          <label className="label200" style={{ width: '150px', padding: '1px' }} >Is Expenses Ledger:</label>
          <input
            type="checkbox"

            name="isExpLedger"
            ref={register}
            // checked={IsExpLedgerTrue}
            onChange={changeExpLedger}
          // value={IsExpLedgerTrue}
          />
        </div>
        {/* <div className="invalid-feedback">{errors.mode?.message} {errors.balanceDate?.message}</div> */}

      </div>

      <div className="p-formgroup-inline ">
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px' }} >Is Depriciation:</label>
          <div className="p-col">
            <select
              className="selectStyle"
              style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              name="isDepriciation"
              ref={register}
              onChange={onDepriciationValue}
            >
              <option hidden value="">Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div style={{ display: IsDepriciation === "Yes" ? "block" : "none" }}>
          <div className="p-field p-grid">
            <label className="label200" style={{ width: '150px' }}  >Depriciation:</label>
            <div className="p-col">

              <input type="text" name="depreciation" style={{ width: '200px' }} ref={register} autoComplete="off" />
            </div>
          </div>
        </div>

      </div>
      <div className="p-formgroup-inline ">
        <div className="p-field p-grid">
          <label className="label200" style={{ width: '150px' }} >Is Taxable:</label>
          <div className="p-col">
            <select
              className="selectStyle"
              style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              name="isTaxable"
              ref={register}
              onChange={onTaxableValue}
            >
              <option hidden value="">Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>

          </div>
        </div>
        <div style={{ display: IsTax === "Yes" ? "block" : "none" }}>
          <div className="p-field p-grid">
            <label className="label200" style={{ width: '150px' }}>GST%:</label>
            <div className="p-col">

              <input type="text" style={{ width: '200px',borderRadius:'6px',borderColor:'lightgrey',height:'25px'}} name="gst" ref={register} autoComplete="off" />
            </div>
            <div style={{ display: IsTax === "Yes" ? "block" : "none" }}>

              <div style={{ display: IsTax === "Yes" ? "block" : "none" }}>
                <div className="p-field p-grid">
                  <label className=" label200" style={{ width: '130px' }}>TDS%:</label>
                  <div className="p-col">
                    <input type="text" name="tds" style={{ width: "200px",borderRadius:'6px',borderColor:"lightgrey", height:'25px' }} ref={register} autoComplete="off" />
                  </div>
                </div>
              </div>


                {/* <div style={{ display: IsTax === "Yes" ? "block" : "none" }}>
          <div className="p-field p-grid">
            <label className=" label200" style={{ width: '150px' }}>TDS%:</label>
            <div className="p-col">
              <input type="text" name="tds" style={{ width: "200px" }} ref={register} autoComplete="off" />
            </div>  */}

              </div>
            </div>
          </div>

          {/* <div className="p-col">
                <label className=" label200" style={{ width: '70px' }}>TDS%:</label>
                <input type="text" name="tds" style={{ width: "200px" }} ref={register} />
              </div> */}
          {/* <div className="p-col">
              
              </div> */}

          {/* <div className="p-field p-grid" style={{ display: IsTax === "Yes" ? "block" : "none" }}>
            
            </div> */}


        </div>
      

        <div className="p-formgroup-inline">
          <div className="p-field p-grid">
            <div className="p-col">
            <Button
  style={{
    height: '25px',
    marginLeft: '500px',
    padding: '20px',
    lineHeight: '45px', // Adjust this value as needed
  }}
  label="SUBMIT"
  type="submit"
  onClick={handleSubmit(onSubmit)}
  disabled={DisableButton}
/>

&nbsp;
              <Button style={{ margin: '2px', height: '25px',padding:'20px' }} label="RESET" onClick={onCancel} disabled={DisableButton} />
            </div>
          </div>
        </div>

        <div className="Display">
          <div className="dataTable">
            <DataTable
              value={GlData}
              header={header}
              className="p-datatable-sm"
              editMode="row"
              paginator
              rows={5}
              selection={selectedRadioData}
              onSelectionChange={(e) => setSelectedRadioData(e.value)}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
              <Column field="glId" header="GL Id" filter style={{ width: '50px' }}></Column>
              <Column field="glTitle" header="GL Title" filter style={{ width: '350px' }}></Column>
              <Column field="groupName" header="Group Name" filter></Column>
              <Column field="subGroupName" header="Sub-Group Name" filter style={{ width: '300px' }}></Column>
              <Column field="openingBalance" header="Opening Balance " filter ></Column>
              <Column field="balanceDateFormat" header="Balance Date" filter ></Column>
              <Column field="isBilling" header="Is Billing" filter ></Column>
              <Column field="isIntFree" header="Is Interest Free" filter ></Column>
              <Column field="isExpLedger" header="Is Expenses Ledger" filter ></Column>

              {/* <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column body={() => <Button icon="pi pi-trash" className="btn"  />} headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} title="Edit"></Column> */}
            </DataTable>
          </div>
        </div>
      </div>
      );
}
      export default GL_Master;
