import React, { useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import moment from 'moment/moment';
import { useForm } from 'react-hook-form';
import SocietyService from '../../Service/SocietyService';
import { BrowserRouter, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Popup } from 'reactjs-popup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from 'react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './SupplimentaryBillGeneration.css';
import { InputText } from 'primereact/inputtext';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { date } from 'yup/lib/locale';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import Enumerable from 'linq';
import Moment from 'react-moment';

const schema = yup.object().shape({
  billDate: yup.date().required(),
  amount: yup.number().required(),
});

function SupplementaryBillGeneration() {
  const [billDate, setBillDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [billForMonth, setBillForMonth] = useState();
  // const [month, setMonth] = useState([]);
  const [popupVisible, setpopupVisible] = useState(false);
  const toast = useRef(null);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
  const [GlTitle, setGlTitle] = useState("");
  var societyId = parseInt(localStorage.getItem('societyId'));
  const societyService = new SocietyService();
  const [glIdData, setGlIdData] = useState([]);
  const { register, handleSubmit, errors, clearErrors, setValue, reset } = useForm();
  const [DisableButton, setDisableButton] = useState(true);
  const [selectedGlId, setSelectedGlId] = useState();
  const [flats, setFlats] = useState([]);
  const tableName = sessionStorage.getItem('tblNameSupplimentary');
  var [GenerationId, setMaxGenerationId] = useState();
  const [BillNo, setBillNo] = useState();
  const [uiData, setUiData] = useState();
  var [MaxBillNo, setMaxBillNo] = useState();
  const [interestType, setInterestType] = useState();
  const [amountType, setAmountType] = useState('PerFlat');
  const [noInterestValue, setNointerestValue] = useState(false);
  const [billFor, setBillFor] = useState([]);
  const [billGenerated, setBillGenerated] = useState(true);

  const [monthList, setMonthList] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [billingCycle, setBillingCycle] = useState();
  const [fromYear, setFromYear] = useState();
  const [FromMonth, setFromMonth] = useState();
  const [billingTemplate, setBillTemplate] = useState([]);
  const contentStyle = {
    maxWidth: "600px",
    width: "90%",
    height: "40%",
    marginLeft: "250px"
  };
  const GlcontentStyle = {
    maxWidth: "600px",
  };
  useEffect(() => {
    societyService.GetIsBiilingGls(societyId)
      .then(data => {
        //data=data.filter(a=>)
        setGlIdData(data)
      });

    getPlans();
    GetFlatsData();

    /*   societyService.GetSupplimentaryBillSetting(societyId).then(response => {
          if (response != undefined) {
              setInterestType(response.interestType);
              setValue('interestType', response.interestType);
              setValue('fixAmount', response.fixAmount);
              setValue('interestRate', response.rateInterestPA);
          }
      },
          error => {

          }
      ) */

  }, []);


  societyService.getMaxBillNo(societyId, tableName).then((data) => {
    setMaxBillNo(data);
  });

  const GetFlatsData = async () => {
    let flats = await societyService.getFlat(societyId);
    setFlats(flats);
  }
  const onSubmitButton = (data, e) => {
    data.glId = selectedGlId;
    // data.billDate=billDate;
    // data.dueDate=dueDate;
    console.warn(data);
    setUiData(data);
    setpopupVisible(true);

  }

  const onRowSelect = (event) => {
    let fNo = event.data.flatNo;
    let name = event.data.name;
    let wing = event.data.wing;
    setValue('flatNo', fNo);
    setValue('name', name);
    setValue('wing', wing);
  }

  const onRowSelectGlid = (e) => {
    let glid = e.data.glId;
    let glTitle = e.data.glTitle;
    setSelectedGlId(glid);
    setGlTitle(glTitle);
    setValue('glTitle');
  }

  const onChangeAmunt = (e) => {

    setValue('amount', e.currentTarget.value);
  }
  const onChangeRemarks = (e) => {

    setValue('remarks', e.currentTarget.value);
  }
  const onChangeBillDate = (e) => {

    setValue('billDate', e.currentTarget.value);
  }
  const onChangeDueDate = (e) => {

    setValue('dueDate', e.currentTarget.value);
  }
  function authHeader() {
    const user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token };
    }
    else {
      return {};
    }
  }
  function saveData(data) {

    setSpinnerVisible(true);
    let billNo = MaxBillNo;

    let billItem = {};
    billItem.billDate = uiData.billDate;
    billItem.dueDate = uiData.dueDate;
    billItem.societyId = societyId;
    billItem.billPlanId=selectedBillGenerationPlan;
    // billItem.flatId = flat.flatId;
    billItem.amount = uiData.amount;
    billItem.amountType = amountType;
    billItem.GlId = uiData.glId;
    billItem.narration = uiData.remark;
    billItem.BillNo = ++billNo;
    billItem.TransactionTable = tableName;
    billItem.interestType = interestType;
    billItem.billGenerationType = amountType;
    // billArray.push(billItem);
    return axios.post(`${Config.apiUrl}/SupplimentaryBillGeneration/SaveSupplimentaryBill`, billItem, { headers: authHeader() })
      .then(response => {
        // console.warn(data);
        console.log(response)
        if (response.data.success) {
          toast.current.show({ severity: 'success', detail: 'Bills Generated Successfully', sticky: true });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Network error", sticky: true });
      })
      .finally(() => {
        setSpinnerVisible(false);
      })

    // })




  }

  function cancelData(data) {


  }

  function onlyNumberKey(evt) {

    // Only ASCII character in that range allowed
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
    return true;
  }



  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    //dd const value = e.target.value.replace(/\D/g, "");
    // if value is not blank, then test the regex

    const value = e.target.value.replace(/\D/g, "");
    //   this.setState('amount',{value: e.target.value});

  }

  const onNoInterestCheckChanged = (e) => {
    console.log(e.target.checked);
    setNointerestValue(e.target.checked);
  }
  const getQuarter = ((date) => {
    let quarters = ['Jan-Feb-Mar', 'Apr-May-Jun', 'Jul-Aug-Sep', 'Oct-Nov-Dec'];
    return quarters[Math.ceil((date.getMonth() + 1) / 3)] + " " + date.getFullYear();

  })
  function listQuarters(sDate, eDate) {
    sDate = new Date(sDate); eDate = new Date(eDate);

    //Get the quarter of the current month
    var sQuarter = Math.floor((sDate.getMonth()) / 3) + 1;
    var eQuarter = Math.floor((eDate.getMonth()) / 3) + 1;

    var sYear = sDate.getFullYear();
    var eYear = eDate.getFullYear();

    var quarterNames = ['Jan-Feb-Mar', 'Apr-May-Jun', 'Jul-Aug-Sep', 'Oct-Nov-Dec'];
    var quarterList = [];

    while (sQuarter <= eQuarter && sYear <= eYear) {

      quarterList.push({ label: quarterNames[sQuarter - 1] + ' ' + sYear, value: quarterNames[sQuarter - 1] + ' ' + sYear });
      ;
      sQuarter++;

      if (sQuarter > 4) {
        sQuarter = 1;
        sYear++;
      }
    }

    setBillFor(quarterList);

  }
  const listQuartersOld = ((sDateIn, eDateIn) => {

    let sDate = new Date(sDateIn);
    let eDate = new Date(eDateIn);
    // Ensure start is the earlier date;
    if (sDate > eDate) {
      var t = eDate;
      eDate = sDate;
      sDate = t;
    }

    // Copy input start date do don't affect original
    sDate = new Date(sDate);

    // Set to 2nd of month so adding months doesn't roll over
    // and not affected by daylight saving
    sDate.setDate(2);

    // Initialise result array with start quarter
    var startQ = getQuarter(sDate);
    var endQ = getQuarter(eDate);
    var result = [startQ];

    // List quarters from start to end
    while (startQ != endQ) {
      sDate.setMonth(sDate.getMonth() + 3);
      startQ = getQuarter(sDate);
      result.push({ label: startQ, value: startQ });
    }
    setBillFor(result);
    //  return result;
  });


  const [selectedBillGenerationPlan, setSelectedBillGenerationPlan] = useState(0);
  const OnChangeBillGenerationPlan = ((e) => {
    setBillGenerated(true);
    let plan = e.currentTarget.value;
    setSelectedBillGenerationPlan(plan);
    let planObj = supplimentaryBillGenerationPlans.find(a => a.id == plan);
    setValue('interestType', planObj.interestType);
    setValue('interestRate', planObj.interestRate);
    setValue('interestJobRunType', planObj.interestJobRunType);
    setValue('glTitle', planObj.glTitle);
    setValue('id', planObj.id);
    setValue('glId', planObj.glId);
    setValue('amount', planObj.amount);

    setValue('billDate', moment(planObj.billingDate).format('yyyy-MM-DD'));
    setBillingCycle(planObj.billingCycle);
    let billDueDate;


    setValue('dueDate', moment(planObj.dueDate).format('yyyy-MM-DD'));
    //}
    setValue('dueDate', moment(planObj.dueDate).format('yyyy-MM-DD'));

    setValue('amountType', planObj.amountType);
    // setValue('billingTypeEnum', planObj.billingTypeEnum);
    setValue('remarks', planObj.remarks);


    let tempFromDate = moment(planObj.billingDate, 'yyyy-MM-DD');
    let tempDueDate = moment(planObj.dueDate, 'yyyy-MM-DD');

    let monthFrom = tempFromDate.format('M');
    let dayFrom = tempFromDate.format('D');
    let yearFrom = tempFromDate.format('YYYY');

    let toYear = tempDueDate.format('YYYY');
    let toMonth = tempDueDate.format('M');
    setFromYear(yearFrom);
    setFromMonth(monthFrom);

    setYear(toYear);
    setMonth(toMonth);

    billsfor(planObj.billingCycle, tempFromDate, tempDueDate, planObj);

    /*   setInterestType(billingTemplate[0].interestType);
      setLateInterestType(billingTemplate[0].latePaymentType);
      setBillTemplate(billingTemplate); */
  })
  const [supplimentaryBillGenerationPlans, setSupplimentaryBillGenerationPlans] = useState([]);
  const [selectedSupplimentaryBillGenerationPlans, setSelectedSupplimentaryBillGenerationPlans] = useState([]);

  const getPlans = (() => {
    societyService.GetSupplimentaryBillPlans(societyId)
      .then(data => {
        setSupplimentaryBillGenerationPlans(data);
      })
  })

  var date = new Date();
  // var MonthList;
  // var Year;
  // var Month;
  // var FromYear;
  // var FromMonth;

  const billsfor = (billingCycle, fromDateTemp, toDateTemp, planObj) => {
    let startDate = moment(fromDateTemp);
    let endDate = moment(toDateTemp);
    let startMonth = startDate.format('M');
    let startYear = startDate.format('Y');
    let endMonth = endDate.format('M');
    let endYear = endDate.format('Y');

    if (billingCycle === 'Monthly') {
      let Months = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`];
      let options = [];

      var betweenMonths = [];
      if (fromDateTemp < toDateTemp) {
        var date = fromDateTemp.startOf('month');
        while (date < toDateTemp.endOf('month')) {
          betweenMonths.push(Months[date.format('M') - 1] + " " + date.format('YYYY'));
          date.add(1, 'month');
        }
      }
      /* for (var pastYear = fromYear; pastYear < year; pastYear++) {
        Months.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
      } */
      betweenMonths.forEach(q => options.push({ label: q, value: q }));
      setBillFor(options);
    }
    if (billingCycle === 'Quaterly') {
      /*  let quarters = ['Jan-Feb-Mar', 'Apr-May-Jun', 'Jul-Aug-Sep', 'Oct-Nov-Dec'],
         options = [];
       for (var pastYear = fromYear; pastYear <= year; pastYear++) {
         if (pastYear === fromYear) {
           let monthFloor = Math.floor(FromMonth / 4);
           let tempQuarters = Enumerable.from(quarters).where(a => quarters.indexOf(a) >= monthFloor).toArray();
           tempQuarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
         }
         else if (pastYear === year) {
           let monthFloor = Math.floor(month / 4);
           let tempQuarters = Enumerable.from(quarters).where(a => quarters.indexOf(a) <= monthFloor).toArray();
           tempQuarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
         }
         else {
           let monthFloor = FromMonth / 4;
           quarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
         } */
      listQuarters(planObj.billingDate, planObj.dueDate);

    }

    if (billingCycle === 'Half_Yearly') {

      let options = [];
      let startDateTemp = startDate;
      let endDateTemp = endDate;
      while (startDateTemp <= endDate) {
        let nextDateAferInterval = moment(startDateTemp).add(6, 'months').subtract(1, 'days');

        // if (nextDateAferInterval <= endDate) {
        let f = startDateTemp.format('MMM') + '_' + startDateTemp.format('Y');
        let t = nextDateAferInterval.format('MMM') + '_' + nextDateAferInterval.format('Y');
        options.push({ label: f + " To " + t, value: f + " To " + t })
        // }
        startDateTemp = nextDateAferInterval.add(1, 'days');
      }
      /*       var month = moment(startDate); 
            while( month < endDate ) {
                month.add(1, "month");
                dates.push(month.format('YYYY-MM-DD'));
            }
            let HalfYearlyMonths = [`Jan-Feb-Mar-Apr-May-Jun`, `Jul-Aug-Sep-Oct-Nov-Dec`];
            let options = [];
            for (var pastYear = fromYear; pastYear < year; pastYear++) {
              HalfYearlyMonths.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
              
            }
            HalfYearlyMonths.slice(0, parseInt(month / 6 + 1)).forEach(q => options.push({ label: q + ' ' + year, value: q + ' ' + year })); */
      setBillFor(options);
    }

    if (billingCycle === 'Yearly') {
      let options = [];
      let startDateTemp = startDate;
      let endDateTemp = endDate;
      while (startDateTemp <= endDate) {
        let nextDateAferInterval = moment(startDateTemp).add(12, 'months').subtract(1, 'days');

        // if (nextDateAferInterval <= endDate) {
        let f = startDateTemp.format('MMM') + '_' + startDateTemp.format('Y');
        let t = nextDateAferInterval.format('MMM') + '_' + nextDateAferInterval.format('Y');
        options.push({ label: f + " To " + t, value: f + " To" + t })
        // }
        startDateTemp = nextDateAferInterval.add(1, 'days');
        /*  let YearlyMonths = [`Jan-Feb-Mar-Apr-May-Jun-Jul-Aug-Sep-Oct-Nov-Dec`];
         let options = [];
         fromDateTemp=new Date(fromDateTemp);
         let currentMonth= fromDateTemp.getMonth();
   
   
         toDateTemp=new Date(toDateTemp);
         while(fromDateTemp<=toDateTemp){
         let year = fromDateTemp.getFullYear();
         let month 
         options.push({ label: year, value: year })
       fromDateTemp= moment(fromDateTemp).add('years',1); */
      }
      /*       for (var pastYear = fromYear; pastYear < year; pastYear++) {
              YearlyMonths.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
            }
            YearlyMonths.slice(0, parseInt(month / 11 + 1)).forEach(q => options.push({ label: q + ' ' + year, value: q + ' ' + year })); */
      setBillFor(options);
    }
  }

  const onBillingCycleChange = (e) => {
    var Mno;
    let year;
    Mno = parseInt(Mno);
    let selectedYear = e.currentTarget.value.match(/\d/g);
    selectedYear = selectedYear.join("");
    if (billingCycle === 'Quaterly') {
      if (e.currentTarget.value === `Jan-Feb-Mar ${selectedYear}`) {
        Mno = moment().month("Jan").format("M");
      }
      if (e.currentTarget.value === `Apr-May-Jun ${selectedYear}`) {
        Mno = moment().month("April").format("M");
      }
      if (e.currentTarget.value === `Jul-Aug-Sep ${selectedYear}`) {
        Mno = moment().month("July").format("M");
      }
      if (e.currentTarget.value === `Oct-Nov-Dec ${selectedYear}`) {
        Mno = moment().month("Oct").format("M");
      }
    }

    if (billingCycle === 'Half_Yearly') {

      let fromHalfWhole = e.currentTarget.value.split("To")[0];
      let fromMonth = fromHalfWhole.split('_')[0].trim();
      selectedYear = fromHalfWhole.split('_')[1].trim();
      //if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun ${selectedYear}`) {
      Mno = moment().month(fromMonth).format("M");

    }

    if (billingCycle === 'Yearly') {
      let fromHalfWhole = e.currentTarget.value.split("To")[0];
      let fromMonth = fromHalfWhole.split('_')[0].trim();
      selectedYear = fromHalfWhole.split('_')[1].trim();
      //if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun ${selectedYear}`) {
      Mno = moment().month(fromMonth).format("M");

      /*   if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun-Jul-Aug-Sep-Oct-Nov-Dec ${selectedYear}`) {
          Mno = moment().month("Jan").format("M");
        } */
    }

    if (billingCycle === 'Monthly') {
      if (e.currentTarget.value === `Jan ${selectedYear}`) {
        Mno = moment().month("Jan").format("M");
      }
      if (e.currentTarget.value === `Feb ${selectedYear}`) {
        Mno = moment().month("Feb").format("M");
      }
      if (e.currentTarget.value === `Mar ${selectedYear}`) {
        Mno = moment().month("Mar").format("M");
      }
      if (e.currentTarget.value === `Apr ${selectedYear}`) {
        Mno = moment().month("April").format("M");
      }
      if (e.currentTarget.value === `May ${selectedYear}`) {
        Mno = moment().month("May").format("M");
      }
      if (e.currentTarget.value === `Jun ${selectedYear}`) {
        Mno = moment().month("June").format("M");
      }
      if (e.currentTarget.value === `Jul ${selectedYear}`) {
        Mno = moment().month("July").format("M");
      }
      if (e.currentTarget.value === `Aug ${selectedYear}`) {
        Mno = moment().month("Aug").format("M");
      }
      if (e.currentTarget.value === `Sep ${selectedYear}`) {
        Mno = moment().month("Sep").format("M");
      }
      if (e.currentTarget.value === `Oct ${selectedYear}`) {
        Mno = moment().month("Oct").format("M");
      }
      if (e.currentTarget.value === `Nov ${selectedYear}`) {
        Mno = moment().month("Nov").format("M");
      }
      if (e.currentTarget.value === `Dec ${selectedYear}`) {
        Mno = moment().month("Dec").format("M");
      }
    }
    let mtemp = Mno;
    let t = mtemp.toString().padStart(2, "0");
    let firstDayStr = `${selectedYear}-${t}-01`;
    let firstDay = new Date(firstDayStr);

    societyService.IsSupplimentaryBillGenerated(societyId, firstDayStr, tableName, selectedBillGenerationPlan).then((data) => {
      setBillGenerated(data);
    });

    //dueDay.setDate(dueDay.getDate() + billingTemplate[0].dueDate - 1);
    setValue('billDate', firstDayStr);
    if (billingCycle == 'Monthly') {
      let m = moment(firstDayStr, 'YYYY-MM-DD');
      let dDate = m.add(1, 'months').subtract(1, 'days');
      setValue('dueDate', dDate.format('YYYY-MM-DD'));
    }
    if (billingCycle == 'Quaterly') {
      let m = moment(firstDayStr, 'YYYY-MM-DD');
      let dDate = m.add(3, 'months').subtract(1, 'days');
      setValue('dueDate', dDate.format('YYYY-MM-DD'));
    }
    if (billingCycle == 'Half_Yearly') {
      let m = moment(firstDayStr, 'YYYY-MM-DD');
      let dDate = m.add(6, 'months').subtract(1, 'days');
      setValue('dueDate', dDate.format('YYYY-MM-DD'));
    }
    if (billingCycle == 'Yearly') {
      let m = moment(firstDayStr, 'YYYY-MM-DD');
      let dDate = m.add(12, 'months').subtract(1, 'days');
      setValue('dueDate', dDate.format('YYYY-MM-DD'));
    }

    //setBillDate(firstDay);
    //setBillForMonth(e.currentTarget.value);
    //console.warn(billForMonth);
  }

  return (
    <form>
      <Toast ref={toast} position={'center'} />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
      <ConfirmPopup target={document.getElementById('button')} visible={popupVisible} onHide={() => setpopupVisible(false)} message="Are you sure you want to proceed?"
        icon="pi pi-exclamation-triangle" accept={saveData} reject={cancelData} />
      <div className="SupplimentaryBill"  >
        <span style={{ fontFamily: 'verdena', fontWeight: 'bold', padding: '2px', fontSize: '20px', marginBottom: '50px' }}>Supplementary Bill Generation</span>

        <div className="field grid p-formgroup-inline">
          <select name="billGenerationPlan" value={selectedBillGenerationPlan} style={{ width: '400px',marginBottom:'10px', margin: '2px', padding: '5px',borderRadius:'6px',borderColor:'darkgrey' }} ref={register({ required: true })} onChange={OnChangeBillGenerationPlan}>
            <option hidden value=""> Select Bill Generation Plan</option>
            {supplimentaryBillGenerationPlans.map((item) => (
              <option value={item.id}>
                {item.billName}
              </option>
            ))}
          </select>
        </div>

        <div className="field grid p-formgroup-inline">
          <label className='col-fixed' style={{ width: '130px' }}>Bill Date:</label>
          <div className="col ">
            <select name="billFor" style={{width:'190px', height:'28px' }} className="selectBillFor" ref={register}  onChange={onBillingCycleChange} >
              <option>Select </option>
              {billFor.map(({ label, value: Months }) => (
                <option key={Months} value={Months}>
                  {label}
                </option>
              ))}
            </select>
            {errors?.billFor && <small className="text-danger">{errors?.billDate && errors.billDate.message}
            </small>
            }
          </div>
        </div>
        <div className="field grid p-formgroup-inline" style={{ marginTop: '5px' }}>

          <label className='col-fixed' style={{ width: '130px' }}>Bill Date:</label>
          <div className="col ">
            <input type="text" name="billDate" ref={register({ required: { value: true, message: "You must select bill date" } })} />
            {errors?.billDate && <small className="text-danger">{errors?.billDate && errors.billDate.message}
            </small>
            }
          </div>


          {/* <label className='col-fixed' style={{ width: '130px' }}> Bill Date</label>
                    <div className="col ">
                        <Calendar name="billDate" value={billDate} onChange={(e) => setBillDate(e.value)} showIcon dateFormat="dd-mm-yy" required></Calendar>
                        {errors.billDate &&  <p style={{color:'Red'}}>{errors.billDate?.message}</p>   }
                    </div> */}
        </div>
        <div className="field grid p-formgroup-inline">
          <label className='col-fixed' style={{ width: '130px' }}>Due Date:</label>
          <div className="col ">
            <input type="text" name="dueDate" ref={register({ required: { value: true, message: "You must select due date" } })} />
            {errors?.dueDate && <small className="text-danger">{errors?.dueDate && errors.dueDate.message}
            </small>
            }
          </div>

          {/* <label className='col-fixed' style={{ width: '130px' }}> Due Date</label>
                    <div className="col ">
                        <Calendar value={dueDate} onChange={(e) => setDueDate(e.value)} showIcon dateFormat="dd-mm-yy" required></Calendar>
                    </div> */}
        </div>
        <div className="field grid p-formgroup-inline">
          {/* {!noInterestValue && <> */}
          <label className='col-fixed' style={{ width: '130px' }}>Interest Type:</label>
          <div className="col ">
            <input type="text" name="interestType" readOnly ref={register({ required: true })} />
          </div>
          {/* </>
                } */}
          {/* <div className="col ">
                        <input type="checkbox" onChange={onNoInterestCheckChanged} name="noInterest" ref={register({ required: true })} />
                        <label className='col-fixed' style={{ width: '130px' }}>Without Interest:</label>
                    </div> */}
        </div>
        <div className="field grid p-formgroup-inline">
          <label className='col-fixed' style={{ width: '130px' }}>Rate:</label>
          <div className="col ">
            <input type="text" name="interestRate" readOnly ref={register({ required: true })} />
          </div>
        </div>



        <div className="field grid p-formgroup-inline">

          <label className='col-fixed' style={{ width: '130px' }}> GL Title</label>

          <div className='col'>
            {/* <input type="text" name="glTitle" ref={register} value={GlTitle} autoComplete="off" /> */}
            <input type="text" name="glTitle" ref={register({ required: { value: true, message: "You must select GL Titile" } })} />

            {errors?.glTitle && <small className="text-danger">{errors?.glTitle && errors.glTitle.message}
            </small>
            }
          </div>

          {/* <Popup trigger={<a href="#" className='col-fixed' style={{ width: '200px', marginLeft: '50px' }}> Search Gl-Id </a>} position="right top" contentStyle={GlcontentStyle}>
                        <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => setSelectedGlidGltitle(e.value)} dataKey="glId" className="p-datatable-sm" scrollable scrollHeight='280px' style={{ overflow: 'hidden' }} width="250px" >
                            <Column field="glId" header="Gl Id" filter></Column>
                            <Column field="glTitle" header="GL Title" filter></Column>
                        </DataTable>
                    </Popup> */}
        </div>
        <div className="field grid p-formgroup-inline">
          <label className='col-fixed' style={{ width: '130px' }}> AmountType</label>
          <div className='col'>
            <input type="text" name="amountType" ref={register({ required: { value: true, message: "amount Type should be non zero" } })} />
            {/*                         <div className="field-radiobutton" style={{ margin: '2px', padding: '2px' }}>
                            <RadioButton inputId="city1" name="amountType" value="PerFlat" onChange={(e) => setAmountType(e.value)} checked={amountType === 'PerFlat'} />
                            <label htmlFor="city1">PerFlat</label>
                        </div>
                        <div className="field-radiobutton" style={{ margin: '2px', padding: '2px' }}>
                            <RadioButton inputId="city2" name="amountType" value="PerSqft" onChange={(e) => setAmountType(e.value)} checked={amountType === 'PerSqft'} />
                            <label htmlFor="city2">PerSqft</label>
                        </div>
                        <div className="field-radiobutton" style={{ margin: '2px', padding: '2px' }}>
                            <RadioButton inputId="city3" name="amountType" value="FixedAmount" onChange={(e) => setAmountType(e.value)} checked={amountType === 'FixedAmount'} />
                            <label htmlFor="city3">FixedAmount</label> */}
            {/* </div> */}
          </div>
        </div>
        <div className="field grid p-formgroup-inline">


          <label className='col-fixed' style={{ width: '130px' }}>Amount</label>
          <div className='col'>
            <input type="number" name="amount" ref={register({ required: { value: true, message: "You must enter Amount" } })} />
            {errors.amount && <small className="text-danger">{errors?.amount && errors.amount.message}
            </small>
            }
          </div>
        </div>

        <div className="field grid p-formgroup-inline">
          <label className='col-fixed' style={{ width: '130px' }}>Remarks</label>
          <div className='col'>
            <input type="text" name="remarks" ref={register({ required: true })} />
          </div>
        </div>



      </div>
      {/* disabled={DisableButton} */}
      <div className="p-formgroup-inline" >
        <div className="p-field">
          <Button label="GENERATE" disabled={billGenerated} type="submit" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} onClick={handleSubmit(onSubmitButton)} />
          <Button label="RESET" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} />
        </div>
      </div>

    </form >

  );

}

export default SupplementaryBillGeneration;



