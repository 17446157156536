import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import SocietyService from 'Service/SocietyService';

const ViewLogs = () => {
    const [globalFilter, setGlobalFilter] = useState();
    const societyService = new SocietyService();
    const [logs, setLogs] = useState([]);

    useEffect(() =>{
        societyService.GetLogData().then(data =>{
            let arr=[];
            data.forEach(item =>{
                let logMsg = {};
                logMsg.msg = item;
                arr.push(logMsg);
            });
            setLogs(arr);
        }).catch(err =>{
            console.log(err);
        })

        },[])

        return (

            <div className='box'>
                <DataTable className='dataTable' value={logs} globalFilter={globalFilter} >
                        <Column field="msg" header="Message"></Column>
                </DataTable>

            </div>


        );
    }

export default ViewLogs;