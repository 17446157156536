import { Link } from "react-router-dom";
import React from "react";
import styles from "./SocietyDetails.module.css";
import axios from 'axios';
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import SocietyService from '../../Service/SocietyService';
import Config from '../../Config.json';
import authHeader from "../../authHeader";
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import moment from "moment";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

function SocietyMasters({ setSocietyId, id, setActiveTab, setSocietyName }) {
  let history = useHistory();
  const societyService = new SocietyService();
  const toast = useRef(null);
  const isAddMode = !id;
  const [societyDetails, SetSocietyDetails] = useState([]);
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const { errors, register, reset, handleSubmit, setValue, clearErrors } = useForm();

  const onChange = (e) => {
    let Sname = e.currentTarget.value;
    setValue("sName", e.currentTarget.value);
  };

  const onSubmit = (data, e) => {
    return isAddMode
      ? InsertSocietyDetails(data)
      : updateSocietyDetails(data);
  }

  const InsertSocietyDetails = (data, e) => {
    console.warn(data);
    setSocietyName(data.societyName);
    if (data.pincode === "" || data.pincode === undefined) {
      data.pincode = -1;
    }
    if (data.shops === "" || data.shops === undefined) {
      data.shops = -1;
    }
    if (data.landCost === "" || data.landCost === undefined) {
      data.landCost = -1;
    }
    if (data.constructionCost === "" || data.constructionCost === undefined) {
      data.constructionCost = -1;
    }
    if (data.totalFlats === "" || data.totalFlats === undefined) {
      data.totalFlats = -1;
    }
    if (data.garages === "" || data.garages === undefined) {
      data.garages = -1;
    }
    setSpinnerVisible(true);
    return axios.post(`${Config.apiUrl}/SocietyDetails/Insert`, data, { headers: authHeader() })
      .then(response => {
        setSocietyId(response.data[0].societyId)
        console.warn(data);
        console.log(response)
        if (response.data[0].success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });

        }
        else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });

        }
        if (response.data[0].success) {
          setNextDisabled(false);
        }

      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  }

  const updateSocietyDetails = (data, e) => {
    if (data.pincode === "" || data.pincode === undefined) {
      data.pincode = -1;
    }
    if (data.shops === "" || data.shops === undefined) {
      data.shops = -1;
    }
    if (data.landCost === "" || data.landCost === undefined) {
      data.landCost = -1;
    }
    if (data.constructionCost === "" || data.constructionCost === undefined) {
      data.constructionCost = -1;
    }
    if (data.totalFlats === "" || data.totalFlats === undefined) {
      data.totalFlats = -1;
    }
    if (data.garages === "" || data.garages === undefined) {
      data.garages = -1;
    }
    setSpinnerVisible(true);

    return axios.put(`${Config.apiUrl}/SocietyDetails/update?id=${id}`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
        }
        else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  }

  const onReset = () => {
    reset();
    window.location.reload();
  }
  useEffect(() => {
    societyService.getSocietyDetailsOnID(id).then(data => SetSocietyDetails(data));
    setSocietyId(parseInt(id));

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }

  }, []);

  const [user, setUser] = useState({});
  const [registerDate, setRegisterDate] = useState([]);
  const [fyFromDate, setFyFromDate] = useState([]);
  const [toDate, setToDate] = useState();

  useEffect(() => {
    if (!isAddMode) {
      societyService.getSocietyDetailsOnID(id).then(userIn => {
        const fields = ['societyName', 'sName', 'registerNo', 'registerDate', 'societyCode', 'address', 'city', 'pincode', 'telPhoneNo1', 'telPhoneNo2', 'fyFrom', 'to', 'faxNo', 'url', 'panNo', 'tanNo', 'serTaxNo', 'gstNo', 'shops', 'totalPlotArea', 'constuctedArea', 'landCost', 'constructionCost', 'totalFlats', 'garages'];
        fields.forEach(field => setValue(field, userIn[0][field]));
        setUser(userIn[0]);
        setRegisterDate(userIn[0].registerDateFormat);
        setFyFromDate(userIn[0].fyFromDate);
        setToDate(userIn[0].toDate);
      });
      setNextDisabled(false);
    }
  }, []);

  const onRegisterDateChange = (e) => {
    setRegisterDate(e.currentTarget.value);
    setValue('registerDate', e.currentTarget.value);
  }



  const onFyFromDateChange = (e) => {
    setFyFromDate(e.currentTarget.value);
    var date = new Date(e.currentTarget.value);
    var newDate =date.setFullYear(date.getFullYear() + 1);
    newDate = moment(newDate - 86400000).format('YYYY-MM-DD');
    setToDate(newDate);
    setValue('to', newDate);
    setValue('registerDate', e.currentTarget.value);
  }

  // const onFyToDateChange = (e) => {
  //   setToDate(e.currentTarget.value);

  //   setValue('registerDate', e.currentTarget.value);
  // }
  const toInputLowerCase = (e) => {
    e.currentTarget.value = ("", e.currentTarget.value).toLowerCase();
  }

  const InputPincodeNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('pincode', onlyNums);
  }

  const passwordChange = (e) => {
    // const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('password', e.currentTarget.value);
    console.debug(user);
  }

  const InputTel1No = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('telPhoneNo1', onlyNums);
  }

  const InputTel2No = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('telPhoneNo2', onlyNums);
  }

  const InputShopNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('shops', onlyNums);
  }

  const InputAreaNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('totalPlotArea', onlyNums);
  }

  const InputCAreaNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('constuctedArea', onlyNums);
  }

  const InputLandCostNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('landCost', onlyNums);
  }

  const InputCCostNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('constructionCost', onlyNums);
  }

  const InputTotalFlatsNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('totalFlats', onlyNums);
  }

  const InputGaragesNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('garages', onlyNums);
  }

  const onNext = (e) => {
    setActiveTab(1);
  }

  return (
    <div className={styles.SocietyInfo}>
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
      <div className={styles.Form_inline}>
        <label className={styles.Label}> Society Name:</label>
        <input
          type="text"
          name="societyName"
          className={styles.TextBox}
          ref={register}
          onChange={onChange}
          autoFocus={focusValue}
          autoComplete="off"
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Registration No:</label>
        <input
          type="text"
          className={styles.NumberTextBox}
          name="registerNo"
          ref={register({ required: true })}
          autoComplete="off"
        />
        {errors.registerNo ? "Date is required" : null}
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Registration Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="registerDate"
          value={registerDate}
          ref={register({ required: true })}
          onChange={onRegisterDateChange}
        />
        {errors.registerDate && (
          <p className="errorMsg" style={{ color: 'red' }}>Please Select Registration Date.</p>
        )}

        <label className={styles.Label}> Society code:</label>
        <input
          type="text"
          name="societyCode"
          className={styles.SocietyCode}
          readOnly={!isAddMode}
          ref={register({ required: true })}
          autoComplete="off"
        />
        {errors.societyCode && (
          <p className="errorMsg" style={{ color: 'red' }}>Please Enter Society Code.</p>
        )}
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Address:</label>
        <input
          type="text"
          className={styles.TextBox}
          name="address"
          ref={register}
          autoComplete="off"
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> City:</label>
        <input
          type="text"
          name="city"
          className={styles.ShortTextBox}
          ref={register}
          autoComplete="off"
        />

        <label className={styles.Label}> Pincode:</label>
        <input
          type="text"
          className={styles.ShortNumberTextBox}
          name="pincode"
          ref={register}
          onInput={InputPincodeNo}
          autoComplete="off"
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Society Tel No 1:</label>
        <input
          type="text"
          name="telPhoneNo1"
          className={styles.ShortTextBox}
          ref={register}
          onInput={InputTel1No}
          autoComplete="off"
        />

        <div>
          <label className={styles.Label}> Tel No 2:</label>
        </div>

        <input
          type2="text"
          name="telPhoneNo2"
          className={styles.ShortTextBox}
          ref={register}
          onInput={InputTel2No}
          autoComplete="off"
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> F.Y From:</label>
        <input
          type="date"
          name="fyFrom"
          value={fyFromDate}
          className={styles.ShortWidthDate}
          ref={register}
          onChange={onFyFromDateChange}
        />

        <div className={styles.ToDatelabel}>
          <label className={styles.Label}> To:</label>
        </div>

        {/* <input
          type="text"
          name="to"
          value={toDate}
          className={styles.ShortWidthDate}
          ref={register}
          // onChange={onFyToDateChange}
        /> */}

        <input
          type="date"
          name="to"
          value={toDate}
          className={styles.ShortWidthDate}
          ref={register}
          readOnly={true}

        // onChange={onFyToDateChange}
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Fax No:</label>
        <input
          type="text"
          name="faxNo"
          className={styles.ShortNumberTextBox}
          ref={register}
          autoComplete="off"
        />
      </div>

      <div className="Form-inline">
        <label className={styles.Label}> Url:</label>
        <input
          type="text"
          className={styles.TextBox}
          name="url"
          ref={register}
          autoComplete="off"
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Pan No:</label>
        <input
          type="text"
          name="panNo"
          className={styles.ShortTextBox}
          ref={register}
          autoComplete="off"
        />

        <div>
          <label className={styles.Label}> Tan No:</label>
        </div>

        <input
          type="text"
          name="tanNo"
          className={styles.ShortTextBox}
          ref={register}
          autoComplete="off"
        />
      </div>

      <div className="Form-inline">
        <label className={styles.Label}> Ser Tax No:</label>
        <input
          type="text"
          name="serTaxNo"
          className={styles.ShortTextBox}
          ref={register}
          autoComplete="off"
        />

        <div>
          <label className={styles.Label}> Gst No:</label>
        </div>&nbsp;&nbsp;

        <input
          type="text"
          name="gstNo"
          className={styles.ShortTextBox}
          ref={register}
          autoComplete="off"
        />

        <label className={styles.Label}>Shops:</label>&nbsp;
        <input
          type="text"
          name="shops"
          className={styles.ShortTextBox}
          ref={register}
          onInput={InputShopNo}
          autoComplete="off"
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Total Plot Area:</label>
        <input
          type="text"
          name="totalPlotArea"
          className={styles.ShortTextBox}
          ref={register}
          onInput={InputAreaNo}
          autoComplete="off"
        />

        <div className={styles.Form_inline}>
          <label className={styles.Label}> Consrtucted Area:</label>&nbsp;
          <input
            type="text"
            name="constuctedArea"
            className={styles.ShortTextBox}
            ref={register}
            onInput={InputCAreaNo}
            autoComplete="off"
          />

          <label className={styles.Label}> Land Cost:</label>
          <input
            type="text"
            name="landCost"
            className={styles.ShortTextBox}
            ref={register}
            onInput={InputLandCostNo}
            autoComplete="off"
          />
        </div>
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.Label}> Construction Cost:</label>
        <input
          type="text"
          name="constructionCost"
          className={styles.ShortTextBox}
          ref={register}
          onInput={InputCCostNo}
          autoComplete="off"
        />

        <div className={styles.Form_inline}>
          <label className={styles.Label}> Total Flats:</label>&nbsp;
          <input
            type="text"
            name="totalFlats"
            className={styles.ShortTextBox}
            ref={register}
            onInput={InputTotalFlatsNo}
            autoComplete="off"
          />

          <div className={styles.shortLabel}>
            <label className={styles.Label}> Garages:</label>
          </div>
          <input
            type="text"
            name="garages"
            className={styles.ShortNumberTextBox}
            ref={register}
            onInput={InputGaragesNo}
            autoComplete="off"
          />
        </div>
      </div>

      <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '5px' }}>
        <div className="p-field" >
          <Button label="SAVE" type="submit" style={{ transition: 'all .2s',marginTop:'15px',padding:'25px 15px',width:'130px',height:'30px',}} onClick={handleSubmit(onSubmit)} disabled={DisableButton} />
        </div>
        <div className="p-field">
          <Button disabled={nextDisabled} label="NEXT" style={{ transition: 'all .2s',marginTop:'15px',padding:'25px 15px',width:'130px',height:'30px', }} onClick={onNext} />
        </div>
        <div className="p-field ">
          <Button label="RESET"  style={{ transition: 'all .2s' ,marginTop:'15px', padding:'25px 15px',width:'130px',  height:'30px',}}onClick={onReset} disabled={DisableButton} />
        </div>
      </div>
    </div>
  );
}

export default SocietyMasters;
