import { Button } from 'primereact/button';
import '../Report/MemberReceiptReport.css';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { List } from '@material-ui/core';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { Checkbox } from 'primereact/checkbox';



var memberData = [];
var societyData = [];
var setDate;
var converter = require('number-to-words');

function MemberReceiptReport({ props }) {

    const validationSchema = Yup.object().shape({
        fromDate: Yup.string()
            .required('From Date is required'),
        toDate: Yup.string()
            .required('To Date is required '),

        transactionType: Yup.string()
            .required('Transaction type is required '),
    });

    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue } = useForm(
        { resolver: yupResolver(validationSchema) }
    );
    const [data, setMemberReport] = useState([]);
    memberData = data;
    var societyId = parseInt(localStorage.getItem('societyId'));
    const societyService = new SocietyService();
    var [date, setDate] = useState();
    const [transactionType, setTransactionType] = useState();
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [isPrintDisabled, setIsPrintDisabled] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [flatNo,setFlatNo]=useState();
    const [flatId,setFlatId]=useState(0);
    const [isRecent,setIsRecent]=useState(false);



    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }



    const onTransactionTypeChange = (e) => {
        let transactionType = e.currentTarget.value
        if (transactionType === 'Member Receipt') {
            setTransactionType("MR");
        }
        else {
            setTransactionType("GR");
        }
    }
    useEffect(() => {
        societyService.getFlat(societyId)
            .then(data => {
                data.forEach(a => {
                    a.unitNo = `${a.wing}-${a.flatNo}`;
                })
                data.splice(0, 0, { unitNo: 'All' })

                SetPersonalDetailsData(data)

            }
            );
    }, [])

    const onChangeWing = (e) => {
        let selectedFlat = e.value;
        setFlatNo(selectedFlat);
        if(e.value.unitNo==='All')
        {
            setFlatId(0);
        }
        else{
        setFlatId(selectedFlat.id);
        }   
    }

    let fDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
    let tDate = moment(new Date(toDate)).format('YYYY-MM-DD');
    const viewReceipts = (e) => {

        setSpinnerVisible(true);

        let undefinedList = [];

        if (fromDate === undefined) {
            undefinedList.push({ label: "select From date" });
        }
        if (toDate === undefined) {
            undefinedList.push({ label: "select To date" });
        }
        if (transactionType === undefined) {
            undefinedList.push({ label: "select Receipt" });
        }

        let message = "Please";
        undefinedList.forEach(a => {

            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            toast.current.show({ severity: 'warn', sticky: true, detail: message });
        }
        else {
            societyService.getMemberReport(societyId, fDate, tDate, transactionType, tableName,flatId,isRecent).then(data => {
                setSpinnerVisible(false);
                if (data === undefined || data === null || data.length === 0) {
                    toast.current.show({ severity: 'info', sticky: true, detail: "No Data found!" });
                    setMemberReport(data);
                    return;
                }
                setIsPrintDisabled(false);
                data.forEach(item => {
                    if (item.chqNo === 0) {
                        item.chqNo = '---';
                    }
                    if (item.bankName === '') {
                        item.bankName = '---';
                    }
                });
                setMemberReport(data);
                societyService.getSocietyDetailsOnID(societyId).then(data => {
                    SetSocietyDetailsData(data)
                }, error => {
                    toast.current.show({ severity: 'info', sticky: true, detail: "Error in getting society details receipts data!" });
                    setSpinnerVisible(false);
                });
            }, error => {
                toast.current.show({ severity: 'info', sticky: true, detail: "Error in getting receipts data!" });
                setSpinnerVisible(false);
            })
        }

    }


    return (
        <div class="card" >
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div className='p-formgroup-inline'>

                <div style={{ margin: '10px' }}>
                    <label className="selection">From:</label>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="date" name="fromDate" className="textHeight" value={fromDate} ref={register({ required: true })} onChange={onDateChange} />
                </div>

                <div style={{ margin: '10px' }}>
                    <label className="selection">To:</label>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="date" name="toDate" className="textHeight" value={toDate} ref={register({ required: true })} onChange={onToDateChange} />
                </div>

                <div style={{ margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <select className="selection" name="transactionType" ref={register({ required: true })} onChange={onTransactionTypeChange}>
                        <option hidden value="">Select Receipt</option>
                        <option>Member Receipt</option>
                        <option>General Receipt</option>
                    </select>
                </div>
                <div>
                    <Dropdown className='Dropdown' filter filterBy="unitNo" value={flatNo} options={personalDetailsData} onChange={onChangeWing} id="FlatNo" name="unitNo" ref={register} optionLabel="unitNo" placeholder="Select Flat" />
                </div>

                <div className="col-12" hidden={flatId=== 0} style={{margin:'20px'}} >
                    <Checkbox checked={isRecent} onChange={e => setIsRecent(e.checked)} ></Checkbox>
                    <label htmlFor="cb1" >Recent</label>
                </div>

                <div style={{ margin: '10px' }}>
                    <Button label="VIEW RECEIPTS" onClick={viewReceipts} className="btnlabel" />
                </div>

                <div style={{ margin: '10px' }}>
                    <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT" className="btnlabel" />
                </div>
            </div>
            <Report ref={componentRef} />
        </div>
    );
}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class Report extends React.Component {
    render() {

        return (
            <div className='memberReceiptReport' style={{ height: '100%' }}>
                <style>{portrait()}</style>
                {memberData.map((data, index) =>

                    <div className="memberReceiptPageBreak">
                        <div className='SocietyName'>
                            <div class="font"><text>{societyData[0]?.societyName}</text></div>
                            <div>
                                <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                                <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                            </div>
                            <div>
                                <text>
                                    {societyData[0]?.address}
                                </text>
                            </div>
                        </div>
                        <div className="MRBill">
                            RECEIPT
                        </div>


                        <div style={{ margin: '4px' }}>
                            <div className="MRborder">
                                <div className="p-formgroup-inline">
                                    <div className="p-formgroup-inline">

                                        <label className="MRalignleft" >Receipt No
                                        </label>
                                        <text>: {memberData[index].rctNo}</text>
                                    </div>
                                    {/* <div className="p-formgroup-inline"> */}


                                    <label className="alignRight"> Date: {memberData[index].dateFormat}</label>
                                    {/* </div> */}
                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft" >Received with Thanks From </label>
                                    <text>: {memberData[index].name}</text>

                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft" >Unit No </label>
                                    <text>: {memberData[index].wing}-{memberData[index].flatNo}</text>
                                </div>

                                {/* <div style={{ height: '100px',margin:'4px' }}> */}
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft">Amount ₹ </label>
                                    <text>: {memberData[index].receiptTotal.toFixed(2)} </text>
                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft">Rupees in words </label>
                                    <text>: "{converter.toWords(Math.round(memberData[index].receiptTotal).toFixed(2)).toUpperCase()} ONLY"</text>
                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft">Mode </label>
                                    <text>: {memberData[index].mode}</text>
                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft">By Cheque No </label>
                                    <text>: {memberData[index].chqNo}</text>
                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft">Drawn on </label>
                                    <text>: {memberData[index].bankName}</text>
                                </div>
                                <div className="p-formgroup-inline">
                                    <label className="MRalignleft">Narration </label>
                                    <text>: {memberData[index].remarks}</text>

                                </div>
                                <div style={{ height: '100px' }}>
                                    <text className="alignright"><b>For {societyData[0]?.societyName}</b></text><br />
                                    <text className="alignright" style={{ marginTop: "40px" }}><b>Authorised Signature</b></text>
                                </div>
                                <text className="MRalignleft" style={{ marginTop: '30px' }}>This Receipt is Valid Subject to realisation of cheque..</text>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </div>

        );


    }

};

export default MemberReceiptReport;
