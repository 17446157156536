import { Column } from 'primereact/column';
// import Config from '../../Config.json' 
import { Button } from 'primereact/button';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import './BankDepositeSlip.css'
import { useForm } from 'react-hook-form';

function BankDepositeSlip() {
    const { register, handleSubmit, reset, errors,setValue } = useForm();
    const [depositeData,setDepositeData]=useState([]);

    const onAdd = (data) => {
        console.warn(data);

        let _depositeDetails = [...depositeData];
            let _data = { ...data };
            _depositeDetails.push(_data);


            setDepositeData(_depositeDetails);
             onReset()

    }

    const onReset=()=>{
        const fields = ['date', 'flatno','roomNumber','name','amount','cheqno','cheqDate','bankname','narration'];
        fields.forEach(field => setValue(field, ''));
    }
    return (
        <div className="BankDepositeSlip">
            <div>
                <label> Date:</label>
                <input type="date" className="datee" name="date" ref={register}  />

                <label className='fltno'>FlatNo:</label>
                <select className="Select4" name="flatno" ref={register} >
                <option>A</option>
                <option>B</option>
                <option>C</option>
                </select>       
                
                <input type="text" maxLength="4" className="rmno" name="roomNumber" placeholder='--Room No--' ref={register}  />
                <input type="text" className="Name" name="name" placeholder='--Enter Name Here--'  />
            </div>
            <div>
                <label className='amtt'>Amount:</label>
                <input type="number" className='amt' name='amount' ref={register} />

                <label >ChequeNo:</label>
                <input type='text' maxLength="6" className='chqn' name='cheqno' ref={register}  />

                <label >ChequeDate:</label>
                <input type='Date' className='' name='cheqDate' ref={register} />
            </div>

            <div>
                <label className='Mediumlabel' >Bank Name:</label>
                <input type='text' className='largeTxtBox' name='bankname' ref={register}  />
            </div>

            <div>
                <label className='Mediumlabel'>Narration:</label>
                <input type='text' className='largeTxtBox' name='narration' ref={register} />
            </div>
            <div className='p-formgroup-inline' style={{ justifyContent: 'start', marginTop: '15px', marginLeft: '15rem' }}>
                <div className="field">
                    <Button label="Add" className="btnn"  type="submit" onClick={handleSubmit(onAdd)}   />
                </div>
                <div className="field">
                    <Button label="Submit" className="btnn"   />
                </div>
                <div className="field">
                    <Button label="Reset" className="btnn" onClick={onReset} />
                </div>
            </div>

            <div >
                <DataTable value={depositeData} header="Personal Details" className="p-datatable-sm"  >
                    <Column field="date" header="Date" ></Column>
                    <Column field="flatno" header="Flat No"></Column>
                    <Column field="amount" header="Amount"></Column>
                    <Column field="cheqno" header="Cheque Number" ></Column>
                    <Column field="cheqdate" header="Cheque Date"></Column>
                    <Column field="bankname" header="Bank Name" ></Column>
                    <Column field="narration" header="Narration"></Column>


                </DataTable>

            </div>
        </div>
    );
}
export default BankDepositeSlip;