import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import { Button } from 'primereact/button';
import './PropertyDetailsAuditView.css';
import moment from 'moment';



function PropertyDetailsAuditView() {

    const [globalFilter, setGlobalFilter] = useState('');
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [PropertyAuditData, setPropertyAuditInfo] = useState();
    var societyId = parseInt(localStorage.getItem('societyId'));

    const societyService = new SocietyService();

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {
        societyService.getPropertyAuditInfo(fromDate, toDate,societyId).then(data => {
            data.forEach(d=>{
                if(d.areaInSqft===0)
                {
                    d.areaInSqft='---';
                }
                if(d.areaInSqMtr===0)
                {
                    d.areaInSqMtr='---';
                }
                if(d.agreementValue=== 0)
                {
                    d.agreementValue='---';
                }
                if(d.memberNo=== '')
                {
                    d.memberNo='---';
                }
                if(d.memberAmt===0)
                {
                    d.memberAmt='---';
                }
                if(d.certificateNo=== '')
                {
                    d.certificateNo='---';
                }
                if(d.entranceFee=== 0)
                {
                    d.entranceFee='---';
                }
                if(d.otherAmt=== 0)
                {
                    d.otherAmt='---';
                }
                if(d.total=== 0)
                {
                    d.total='---';
                }
            })
            setPropertyAuditInfo(data);
            
        }
        )}

    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>Property Details Audit View</b> */}

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }}  value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className="label">From:</label>
            <input type="date" name="date" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className="label" >To:</label>
            <input type="date" name="date" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />

        </div>
    );

    return (
        <div className="propertyAuditView">
            <span style={{fontFamily:'verdena', fontWeight:'bold'}}>Property Details Audit View</span> 
        <div>
            <DataTable className="p-datatable-gridlines" responsiveLayout="scroll" scrollable scrollHeight='400px' style={{ overflow: 'hidden',height:'600px' }} header={header}
                globalFilter={globalFilter} value={PropertyAuditData} emptyMessage="No result found.">
                <Column field="type" style={{ width: '120px' }} header="Flat Shop Type" filter></Column>
                <Column field="usedBy" style={{ width: '120px' }} header="Used By" filter></Column>
                <Column field="areaInSqft" style={{ width: '100px' }} header="Area Sqft" filter></Column>
                <Column field="areaInSqMtr" style={{ width: '100px' }} header="Area Mtr" filter></Column>
                <Column field="agreementValue" style={{ width: '100px' }} header="Agreement Value" filter></Column>
                <Column field="memberNo" style={{ width: '100px' }} header="Member No" filter></Column>
                <Column field="memberAmt" style={{ width: '100px' }} header="Membership Amount" filter ></Column>
                <Column field="certificateNo" style={{ width: '100px' }} header="Certificate No" filter></Column>
                <Column field="entranceFee" style={{ width: '100px' }} header="Entrance Fee" filter ></Column>
                <Column field="sharesFrom" style={{ width: '100px' }} header="Shares From" filter ></Column>
                <Column field="sharesTo" style={{ width: '100px' }} header="Shares To" filter ></Column>
                <Column field="otherAmt" style={{ width: '100px' }} header="Other Amount" filter ></Column>
                <Column field="total" style={{ width: '100px' }} header="Total" filter></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '120px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>

            </DataTable>
            </div>
        </div>

    );
}
export default PropertyDetailsAuditView;