import React, { useState, useEffect, useContext, useRef } from 'react';
import './CommitteeMember.css';
import SocietyService from '../../Service/SocietyService';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Toast } from 'primereact/toast';
// import Config from './Config.json';
import Config from '../../Config.json';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from './progressSpinBarControl';

import 'primeflex/primeflex.css';


function CommitteeMember(setEditData) {

  const [OName, setOName] = useState([]);
  const societyService = new SocietyService();
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const toast = useRef(null);
  var societyId = parseInt(localStorage.getItem('societyId'));
  const [committeeMemberDetails, setCommitteeMemberDetails] = useState([]);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const [selectedName, setSelectedOwnerName] = useState(null);
  const [selectedMemberType, setSelectedMemberType] = useState(null);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [logData,setLogData] = useState([]);


  const onMemberTypeChange = (e) => {
    setSelectedMemberType(e.value);
  }

  const onDelete = (event) => {
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={handleSubmit(onYes)} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
            </div>
          </div>
        </div>
      )
    });
  }

  const onYes = (event) => {
    toast.current.clear();
    

    if(selectedRadioData === undefined || selectedRadioData === null){
      toast.current.show({ severity: 'error', detail: "No rows selected" });
      return;
    }
    setSpinnerVisible(true);
    setEditData = selectedRadioData;
    let index = committeeMemberDetails.findIndex(a => a.id === selectedRadioData.id);
    let id = selectedRadioData.id;

    let data = committeeMemberDetails.filter(b => b.id != selectedRadioData.id);
    setCommitteeMemberDetails(data);
    axios.delete(`${Config.apiUrl}/PersonalDetails/DeleteCommitteeMember?id=${id}`, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: "Data Deleted Successfully" });
          window.location.reload();
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
      })

  }

  const onNo = () => {
    toast.current.clear();
    return;
  };

  useEffect(() => {
    societyService
      .getOName(societyId).then((data) => {
        //set owner name
        setOName(data);
      });

    societyService.getCommitteeMemberDetails(societyId).then(data => setCommitteeMemberDetails(data));
    console.warn(committeeMemberDetails);

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }
  }, []);

  const header = (
    <div className="table-header">
       Committee Members
      <Button
        icon="pi pi-trash"
        value="Delete"
  
        className="btn"
        onClick={handleSubmit(onDelete)}
        disabled={DisableButton}
      />
    </div>
  );

  const onAdd = (data) => {
    if(selectedName == undefined){
      toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Please select member' });
      return;
    }
    if(selectedMemberType == undefined){
      toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Please select member Type' });
      return;
    }
    data.memberType = selectedMemberType.name;
    data.ownerName = selectedName.name;
    data.ownerPropertyId = selectedName.ownerPropertyId;
    data.wing = selectedName.wing;
    data.flatNo = selectedName.flatNo;
    data.societyId = societyId;

    let existingValue = committeeMemberDetails.findIndex(x => x.ownerPropertyId === data.ownerPropertyId && x.memberType === data.memberType);
    if (existingValue < 0) {
      data.state = "New";
      setCommitteeMemberDetails(committeeMemberDetails.concat(data));
    }
    else {
      // alert("Owner is already added");
      toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Member is already added' });
    }
    console.warn(committeeMemberDetails);

  }

  const memberType = [
    { name: 'Committee' },
    { name: 'Secretary' },
    { name: 'Treasurer' },
  ];

  const OnSelectMember = (e) => {
    let selectedOwnerName = e.value;
    setSelectedOwnerName(selectedOwnerName);
  }


  const onSave = (data, e) => {
    data = committeeMemberDetails;
   

    let index = committeeMemberDetails.findIndex(x => x.state=== 'New');
    if(index <0){
      toast.current.show({ severity: 'warn', summary: 'Info Message', detail:'No new items to save'}); 
      return;
    }
    setSpinnerVisible(true);
    return axios.post(`${Config.apiUrl}/PersonalDetails/InsertCommitteeMember`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data.msg });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data.msg });
        }
        window.location.reload();

      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
      })
  }


  return (
    <form className="Bcolor" style={{ border: "1px solid black", width: "auto", marginTop: "20px" }}>
      <h3>Committee Member</h3>
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />

      {/* <div className="p-formgroup-inline"> */}
      <div className="p-field p-grid CommitteeMember">
        <label className="p-col-2" style={{marginLeft:'15px',width:'130px', padding:'2px', fontFamily:'verdena'}}>Owner Name:</label>
        <div  >
          <Dropdown style={{marginLeft:'5px', fontFamily:'verdena'}} className='Dropdown' value={selectedName} options={OName} id="ownerName" optionLabel="name" ref={register} onChange={OnSelectMember} filter showClear placeholder="Select Owner Name" />
        </div>
      </div>
      {/* </div> */}

      <div className="p-field p-grid CommitteeMember">
        <label className="p-col-2" style={{marginLeft:'15px',width:'130px', padding:'2px', fontFamily:'verdena'}}>Member Type:</label>
        <div>
          <Dropdown className='Dropdown' style={{marginLeft:'5px', fontFamily:'verdena'}} value={selectedMemberType} options={memberType} onChange={onMemberTypeChange} id="memberType" name="MemberType" ref={register} optionLabel="name" placeholder="Select Member Type" />


        </div>
      </div>



      <div className="p-formgroup-inline" style={{ marginLeft: '300px', marginTop: '30px' }}>
        <div className="p-field">

          <Button label="ADD" type="submit" onClick={handleSubmit(onAdd)} disabled={DisableButton} />
        </div>
      </div>

      <div className="CommitteeMember">
        <DataTable value={committeeMemberDetails} header={header} className="p-datatable-sm" width="500px"  scrollable scrollHeight='500px' style={{ overflow: 'hidden' }} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}>
          <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
          <Column field="ownerPropertyId" header="Id"></Column>
          <Column field="ownerName" header="Owner Name"></Column>
          <Column field="memberType" header="Member Type"></Column>
          <Column field="wing" header="Wing"></Column>
          <Column field="flatNo" header="Flat No"></Column>
        </DataTable>
      </div>

      <div className="p-formgroup-inline" style={{ backgroundColor: "Info", justifyContent: 'center', width: '100%' }}>
        <div className="p-field">

          <Button label="SAVE" style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} type="submit " onClick={handleSubmit(onSave)} disabled={DisableButton} />
          {/* <Button label="CLOSE" style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} type="submit " disabled={DisableButton} /> */}
        </div>
      </div>

    </form>
  );
}
export default CommitteeMember;