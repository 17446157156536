import axios from 'axios';
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import styles from './../SocietyMaster/SocietyDetails.module.css';
import authHeader from './../../authHeader';
import SocietyService from '../../Service/SocietyService';

function EmailSettings() {
  
    // const dispatch =useDispatch();
    const toast = useRef(null);


    var societyId = parseInt(localStorage.getItem('societyId'));
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const societyService = new SocietyService();
    const [email, setEmail] = useState();
  
    const { errors, register, reset, handleSubmit, setValue, clearErrors } = useForm();
    const toInputLowerCase = (e) => {
        e.currentTarget.value = ("", e.currentTarget.value).toLowerCase();
      }
      const passwordChange = (e) => {
        // const onlyNums = e.target.value.replace(/[^0-9]/g, '');
         setValue('password', e.currentTarget.value);
        
       }

       useEffect(()=>{
         societyService.getEmail(societyId).then(data=>{
           setEmail(data);
           if(data.length > 0)
           {
             if(data[0].emailPassword.length>0){
              toast.current.show({ severity: 'info', summary: 'success',detail: 'Password is already set for this email id' });     
             }
             
           }
         })
       },[])

    const onSubmit = (data, e) => {
     
    
        if(data.emailPassword != data.confirmPassword ){
            toast.current.show({ severity: 'error', summary: 'Failure',detail: "Password and confirm Password does not match" });
            return;
          }
          let formData = new FormData()
          let societyId=parseInt(localStorage.getItem("societyId"));
    formData.append("email", data.emailId);
    formData.append("password", data.emailPassword);
    formData.append("societyId", societyId);
data.email=data.emailId;data.password=data.emailPassword;data.societyId= societyId;
          
          return axios.post(`${Config.apiUrl}/SocietyDetails/UpdateEmailSetting`,data, { headers: authHeader() })
          .then(response => {
        //  setSocietyId(response.data[0].societyId)
           // console.warn(data);
           // console.log(response)
            if (response.data != null) {
              toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
    
            }
            else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
    
            }
            if (response.data[0].success) {
              setNextDisabled(false);
            }
    
          })
          .catch(error => { console.log(error) })
      }


    return (
        <div className={styles.SocietyInfo}>
            <Toast ref={toast} position="center" />

            <div className={styles.Form_inline}>

                <label className={styles.Label}> Email Id:</label>
                <input
                    type="text"
                    name="emailId"
                    // style={{width:"250px"}}
                    onInput={toInputLowerCase}
                    autoComplete="nope"
                    className={styles.EmailId}
                    ref={register}
                />
            </div>
            <div className={styles.Form_inline}>
                <label className={styles.Label}> Password:</label>
                <input
                    type="password"
                    name="emailPassword"
                    autoComplete="new-password"
                    // style={{width:"250px"}}
                    // onInput={toInputLowerCase}
                    // className={styles.EmailId}
                    onInput={passwordChange}
                    className={styles.ShortTextBox}
                    ref={register}
                />
                <label className={styles.Label}>Confirm Password:</label>
                <input
                    type="password"
                    name="confirmPassword"
                    // style={{width:"250px"}}
                    // onInput={toInputLowerCase}
                    className={styles.ShortTextBox}
                    ref={register}
                />



            </div>

            <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '5px' }}>
                <div className="p-field">

                    <Button label="Save" type="submit " onClick={handleSubmit(onSubmit)} />
                </div>

            </div>
        </div>
 

    );
}


export default EmailSettings;