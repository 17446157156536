import React, { useState, useEffect, useStateRef, useRef } from 'react';
import { Toast } from 'primereact/toast';
import './VehicleDetails.css';
import { useForm } from 'react-hook-form';
import Config from '../../Config.json'
import { Button } from 'primereact/button';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import authHeader from '../../authHeader';
import SocietyService from "../../Service/SocietyService";
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';


function VehicleDetails({ flatId, id, setActiveTab, setEditData }) {

  const toast = useRef(null);
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  var [vehicleGridData, setVehicleGridData] = useState([]);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const societyService = new SocietyService();
  var societyId = parseInt(localStorage.getItem('societyId'));
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const isAddMode = !id;
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedParkingType, setSelectedParkingType] = useState(null);

  const [selectedVehicleNo, setSelectedVehicleNo] = useState(null);
  const [selectedStickerNo, setSelectedStickerNo] = useState(null);
  const [currentflatId, setSelectedFlatId] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [saveDisabled, SetSaveDisabled] = useState(true)
  const [addDisabled, setAddDisabled] = useState(false);
  const [lastSelectedVehicleId, setLastSelectedVehicleId] = useState();
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);





  useEffect(() => {
    if (!isAddMode) {

      societyService.GetVehicleDetailsOnId(id).then((vehicleDetailsOnId) => {
        setNextDisabled(false);
        const fields = ["vehicleType", "vehicleNo", "stickerNo", "parkingType", "parkingNo"];
        fields.forEach(field => setValue(field, vehicleDetailsOnId[0]?.[field]));
        setSelectedVehicleType(vehicleDetailsOnId[0]?.vehicleType);
        setSelectedVehicleNo(vehicleDetailsOnId[0]?.vehicleNo);
        setSelectedStickerNo(vehicleDetailsOnId[0]?.stickerNo);
        setSelectedParkingType(vehicleDetailsOnId[0]?.parkingType);
        setLastSelectedVehicleId(vehicleDetailsOnId[0].id);
        vehicleDetailsOnId[0].rowActive = true;
        setVehicleGridData(vehicleDetailsOnId);





      })

    }
    if (accessLevel >= 2) {

    }
    else {
      setAddDisabled(true);
      SetSaveDisabled(true);

    }

  }, []);

  const onVehicleTypeChange = (e) => {
    setSelectedVehicleType(e.currentTarget.value);
  }
  const onParkingTypeChange = (e) => {
    setSelectedParkingType(e.currentTarget.value);
  }



  const onVehicleNoChange = (e) => {
    setSelectedVehicleNo(e.currentTarget.value);
  }

  const onStickerNoChange = (e) => {
    setSelectedStickerNo(e.currentTarget.value);
  }

  const onFlatIdChange = (e) => {
    setSelectedFlatId(e.currentTarget.value);
  }

  const onAddVehicle = (data) => {

    // data.vehicleType = selectedVehicleType;
    // data.vehicleNo = selectedVehicleNo;
    // data.stickerNo = selectedStickerNo;
    data.flatId = id;
    data.id = id;
    id = currentflatId;
    console.warn(data);
    let existingValue = vehicleGridData.findIndex(a => a.vehicleType === selectedVehicleType && a.vehicleNo === selectedVehicleNo);
    if (existingValue < 0) {
      data.state = "New";
      setVehicleGridData(vehicleGridData.concat(data));
      onSave(data);
    }
    else {
      toast.current.show({ severity: 'warn', sticky: true, detail: "Vehicle is already Added" });
    }
    SetSaveDisabled(false);
    setNextDisabled(false);
  }

  const onEditVehicle = (data, e) => {
    data.flatId = id;
    data.id = id;
    id = currentflatId;

    let existingIndex = vehicleGridData.findIndex(a => a.vehicleType === selectedVehicleType && a.vehicleNo === selectedVehicleNo);
    if (existingIndex >= 0) {
      if (data.state != "New") {

        data.state = "modified";
      }
      vehicleGridData[existingIndex] = data;
      vehicleGridData = [...vehicleGridData];
      setVehicleGridData(vehicleGridData);
      onSave(data);
    }
    else {
      toast.current.show({ severity: 'error', detail: "Nominee is already added" });
    }
    SetSaveDisabled(false);
    setNextDisabled(true);

  }

  const onSave = (data, e) => {
    //  data.flatId = id;
    // data.id = id;

    if (data.state === "New") {
      createVehicle(data);

    }
    else if (data.state === "modified") {
      updateVehicle(data);
    }


  };

  const createVehicle = (data) => {
    setSpinnerVisible(true);
    axios.post(`${Config.apiUrl}/Vehicle/InsertVehicleDetails`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data.success) {
          toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });
          setNextDisabled(false);
        }
        else {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data.msg });
        }

      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
        onReset();
      })
  }

  const updateVehicle = (data) => {
    setSpinnerVisible(false);
    axios.put(`${Config.apiUrl}/Vehicle/UpdateVehicleDetails`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data.success) {
          toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });
          setNextDisabled(false);
        }
        else {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data.msg });
        }

      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  }

  const onDoubleClickEdit = (event) => {
    if (lastSelectedVehicleId !== event.data.id) {
      let selectedRow = vehicleGridData.find(a => a.id === lastSelectedVehicleId)
      selectedRow.rowActive = false;
    }

    var data = event.data;
    data.rowActive = true;
    rowClass(data);
    setLastSelectedVehicleId(event.data.id);

    const fields = ["vehicleType", "vehicleNo", "stickerNo", "vehicleType", "parkingNo"];
    fields.forEach(field => setValue(field, data[field]));
    setSelectedVehicleType(data?.vehicleType);
    setSelectedVehicleNo(data?.vehicleNo);
    setSelectedStickerNo(data?.stickerNo);
    setSelectedParkingType(data?.parkingType);
    setAddDisabled(true);
  }

  const rowClass = (data) => {

    return {
      'selectedRow': data.rowActive

    }

  }











  const onDelete = (event) => {
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
            </div>
          </div>
        </div>
      )
    });
  }

  const onYes = (event) => {
    toast.current.clear();
    setSpinnerVisible(true);

    setEditData = selectedRadioData;
    let index = vehicleGridData.findIndex(a => a.id === selectedRadioData.id);
    let id = selectedRadioData.id;

    let data = vehicleGridData.filter(b => b.id != selectedRadioData.id);
    setVehicleGridData(data);
    axios.delete(`${Config.apiUrl}/Vehicle/DeleteVehicleDetails?id=${id}`, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: "Data Deleted Successfully" });
          // window.location.reload();
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
      })

  }

  const onNo = () => {
    toast.current.clear();
    return;
  };

  const onNext = (e) => {

    setActiveTab(6);
  }

  const onReset = () => {


    const fields = ["vehicleType", "vehicleNo", "stickerNo", "vehicleType", "parkingNo"];
    fields.forEach(field => setValue(field, ''));


  }

  const header = (
    <div className="table-header">
      Vehicle Details

      <Button
        icon="pi pi-trash"
        value="Delete"
        tooltip="Delete"
        className="btn"
        onClick={onDelete}
      />
    </div>
  );

  return (
    <div className="Vehicleform">
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>

      <input type8="text" value={currentflatId} name="id" ref={register} hidden onChange={onFlatIdChange} />
      <div className="Form-inline">
        <label className="VehicleLabel"> Parking Type:</label>
        <select name="parkingType" className='VehicleSelect' ref={register} value={selectedParkingType} onChange={onParkingTypeChange} autoFocus={focusValue}>
          <option hidden value="">Select Parking Type</option>
          <option>OPEN PARKING</option>
          <option>STILT OR COVERED PARKING</option>
          <option>GARAGE PARKING</option>

        </select>
      </div>

      <div className="Form-inline">

        <label className="VehicleLabel"> Vehicle Type<span style={{ color: 'red' }}>*</span>:</label>

        <select name="vehicleType" className='VehicleSelect' ref={register({ required: true })} value={selectedVehicleType} onChange={onVehicleTypeChange}>
          <option hidden value="">Select Vehicle Type</option>
          <option>2 Wheeler</option>
          <option>3 Wheeler</option>
          <option>4 Wheeler</option>
        </select>

      </div>

      <div className="Form-inline">
        <label className="VehicleLabel"> Vehicle No<span style={{ color: 'red' }}>*</span>:</label>
        <input type="text" className='VehicleSelect' name="vehicleNo" ref={register({ required: true })} value={selectedVehicleNo} onChange={onVehicleNoChange} autoComplete="off" />

        {errors.vehicleNo && (
          <p className="errorMsg" style={{ color: 'red' }}>Please Enter Vehicle No</p>
        )
        }
      </div>

      <div className="Form-inline">
        <label className="VehicleLabel"> Sticker No:</label>
        <input type="text" className='VehicleSelect' name="stickerNo" ref={register} value={selectedStickerNo} onChange={onStickerNoChange} autoComplete="off" />
      </div>

      <div className="Form-inline">
        <label className="VehicleLabel"> Parking Allotment No :</label>
        <input type="text" className='VehicleSelect' name="parkingNo" ref={register} autoComplete="off" />
      </div>

      <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
        <div className="p-field">

          <Button label="Add Vehicle" type="submit" onClick={handleSubmit(onAddVehicle)} disabled={addDisabled} />
        </div>
        <div className="p-field">

          <Button label="Edit Vehicle" disabled={!addDisabled} type="submit " onClick={handleSubmit(onEditVehicle)} />
        </div>
        <div className="p-field">

          <Button label="Next" disabled={nextDisabled} onClick={onNext} />
        </div>

        <div className="p-field">

          <Button label="Reset" onClick={onReset}  />
        </div>


        <div>
          <DataTable header={header} value={vehicleGridData}
            selection={selectedRadioData} rowClassName={rowClass} onSelectionChange={e => setSelectedRadioData(e.value)} className="p-datatable-sm" onRowDoubleClick={onDoubleClickEdit}  >
            <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
            <Column field="flatId" header="Id" ></Column>
            <Column field="parkingType" header="Parking Type" ></Column>
            <Column field="vehicleType" header="Vehicle Type" ></Column>
            <Column field="vehicleNo" header="Vehicle No"></Column>
            <Column field="stickerNo" header="Sticker No" ></Column>
            <Column field="parkingNo" header="Parking No" ></Column>

          </DataTable>
        </div>

      </div>
    </div>
  );
}
export default VehicleDetails;