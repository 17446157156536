import { Button } from 'primereact/button';
// import '../Report/Register.css';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import SocietyService from '../../../Service/SocietyService';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import '../Register/Register.css';







var societyData = [];
var IRegisterData = [];

function IRegister({ props }) {

  
  var societyId = parseInt(localStorage.getItem('societyId'));
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const societyService = new SocietyService();
  const [IRegisterReportData, SetIRegisterData] = useState([]);
  IRegisterData = IRegisterReportData;
  

  
  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

      societyService.getIRegisterData(societyId).then(a => {
          var srNo=1;
          a.forEach(d=> {
              d.serialNo=srNo++;
          })
          SetIRegisterData(a);
        });

  }, []);


  


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (

    <div class="card" style={{ backgroundColor: 'black', height: '45px' }}>
      <div className='p-formgroup-inline'>
        <Button onClick={handlePrint} label="PRINT" style={{ marginLeft: '10px' }} />
      </div>
      <Report ref={componentRef} />


    </div>

  );
}

const  portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;


class Report extends React.Component {
  render() {



    return (

      <div >
        <style>{portrait()}</style>
        {IRegisterData.map((data,index)=> 
        <div className='IRegister'>
          <div className='SocietyName'>
            <div className="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>
                {societyData[0]?.address}
              </text>
            </div>
            <div className="font">
              <label>FORM "I"</label>
            </div>
            <div className="font">
              <label>Register of Members</label>
            </div>
          </div>

          <div className='labelFont'>
              <label>1. Serial No </label>
              <label style={{marginLeft: '250px'}}>: {IRegisterData[index]?.serialNo}</label>
          </div>
          <div className='labelFont'>
              <label>2. Date Of Admission </label>
              <label style={{marginLeft: '184px'}}>: {IRegisterData[index]?.dateFormat}</label>
          </div>
          <div className='labelFont'>
              <label>3. Date Of Payment of Entrance Fee:</label>
          </div>
          <div className='labelFont'>
              <label>4. Flat No</label>
              <label style={{marginLeft: '270px'}}>: {IRegisterData[index]?.wing}-{IRegisterData[index]?.flatNo}</label>
          </div>
          <div className='labelFont'>
              <label>5. Full Name</label>
              <label style={{marginLeft: '250px'}}>: {IRegisterData[index]?.name}</label>
          </div>
          <div className='labelFont'>
              <label>6. Address</label>
              <label style={{marginLeft: '265px'}}>: {IRegisterData[index]?.address}</label>
          </div>
          <div className='labelFont'>
              <label>7. Occpuation</label>
              <label style={{marginLeft: '243px'}}>: {IRegisterData[index]?.occupation}</label>
          </div>
          <div className='labelFont'>
              <label>8. Age on the Date of Admission:</label>
          </div>
          <div className='labelFont'>
              <label>9. Date Of Cessation of Membership:</label>
          </div>
          <div className='labelFont'>
              <label>10. Reason for Cessation:</label>
          </div>

           
          <div className='hr'>
          <label className='tableHeader'>Nominee Details</label>
            <div class="NomineeTable">  
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%', textAlign: 'center' }}>Sr</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Nomination Date</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Name Of Nominee</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Nominee Address</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Committee MeetingDate</th> 
                    <th style={{ width: '15%', textAlign: 'center' }}>Subject Revocation Date</th>                 
                    <th style={{ width: '5%', textAlign: 'center' }}>Share Percent</th> 
                    <th style={{ width: '15%', textAlign: 'center' }}>Remarks</th>                 
                    <th style={{ width: '15%', textAlign: 'center' }}>Status</th>                 
                
                
                  </tr>
                </thead>
                 <tbody>
                  
                    {/* IRegisterData.map((item) => ( */}
                      <tr>
                        <td>{IRegisterData[index].serialNo}</td>
                        <td>{IRegisterData[index].admitionDateOfNominee}</td>
                        <td>{IRegisterData[index].nomineeName}</td>
                        <td>{IRegisterData[index].nomineeAddress}</td>
                        <td>{IRegisterData[index].meetingDateFormat}</td>
                        
                      </tr>
                    {/* )) */}
                  

                </tbody>
                
              </table>

            </div>

            <label className='tableHeader'>Particulars of Shares Held</label>
            <div class="NomineeTable">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '10%', textAlign: 'center' }}>Date</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Cash Book Folio</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Application</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Allotment</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Amount Received on 1st & 2nd call</th> 
                    <th style={{ width: '15%', textAlign: 'center' }}>Total Amount Received</th>                 
                    <th style={{ width: '10%', textAlign: 'center' }}>No. of Shares Held</th> 
                    <th style={{ width: '15%', textAlign: 'center' }}>Sr No. OF Share Certificate</th>                 
               
                  </tr>
                </thead>
                {/* <tbody>
                  {
                    JRegisterData.map((item) => (
                      <tr key={item}>
                        <td>{item.serialNo}</td>
                        <td>{item.wing}-{item.flatNo}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                        
                      </tr>
                    ))
                  }

                </tbody> */}
              </table>

            </div>

            <label className='tableHeader'>Particulars of SharesTransferred or Surrendered</label>
            <div class="NomineeTable">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%', textAlign: 'center' }}>Date</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Cash Book Folio</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Date</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Cash Book Folio or Shares transfer register No.</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>No. of shares transferred</th> 
                    <th style={{ width: '15%', textAlign: 'center' }}>No. of share transferred or refunded</th>                 
                    <th style={{ width: '5%', textAlign: 'center' }}>Serial No. of share certificates</th>                 
                    <th style={{ width: '15%', textAlign: 'center' }}>Balance Amount</th>                 
                
                
                  </tr>
                </thead>
                {/* <tbody>
                  {
                    JRegisterData.map((item) => (
                      <tr key={item}>
                        <td>{item.serialNo}</td>
                        <td>{item.wing}-{item.flatNo}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                        
                      </tr>
                    ))
                  }

                </tbody> */}
              </table>

            </div>

          </div>
        </div>
    )}
      </div>


    );
  }

};

export default IRegister;



