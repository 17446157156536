import 'primeflex/primeflex.css';
import './CreditDebitNote.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import SocietyService from '../../Service/SocietyService';
import Config from '../../Config.json';
import { SocietyContext } from "../../SocietyContext";
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { Checkbox } from 'primereact/checkbox';
import { reset } from 'react-tabs/lib/helpers/uuid';
import { useDispatch } from 'react-redux';

function CreditDebitNote({ props }) {
    var societyId = parseInt(localStorage.getItem('societyId'));
    var [addDebit, setAddDebit] = useState([]);
    const [addCredit, setAddCredit] = useState([]);
    // const [gridValue, setValue] = useState([]);
    const { id, jeNo } = useParams();
    const [date, setDate] = useState([]);
    const isAddMode = !id;
    const societyService = new SocietyService();
    const [scrollNo, setScrollNo] = useState();
    const [wings, setWings] = useState([]);
    const [wing, setWing] = useState();
    const [Name, setName] = useState([]);
    const [flatNo, setFlatNo] = useState();
    const [creditOwnerName, setCreditOwnerName] = useState([]);
    const [creditWing, setCreditWing] = useState([]);
    const [selectedGlId, setSelectedGlId] = useState();
    const [selectedCreditGlId, setSelectedCreditGlId] = useState();
    const [GlTitle, setGlTitle] = useState();
    const [CreditGlTitle, setCreditGlTitle] = useState();
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [glIdData, setGlIdData] = useState([]);
    const [selectedFlatNoName, setSelectedFlatNoName] = useState();
    const [selectedFlatNoNameCredit, setSelectedFlatNoNameCredit] = useState();
    const [creditFlatNo, setCreditFlatNo] = useState();
    const [dDate, setDDate] = useState();
    const [cDate, setCDate] = useState();
    const [debitAmount, setDebitAmount] = useState();
    const [creditAmount, setCreditAmount] = useState();
    const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
    const [selectedCreditGlidGltitle, setSelectedCreditGlidGltitle] = useState();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [flatId, setFlatId] = useState(0);
    const [creditFlatId, setCreditFlatId] = useState(0);
    const [dAmount, setDAmount] = useState();
    const [dRemarks, setDRemarks] = useState();
    const [cRemarks, setCreditRemarks] = useState();
    const [buttonLabel, setbuttonLabel] = useState('SUBMIT');
    const toast = useRef(null);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [debitAddButton, setDebitAddButton] = useState(false);
    const [creditAddButton, setCreditAddButton] = useState(false);
    const [debitEditButton, setDebitEditButton] = useState(true);
    const [creditEditButton, setCreditEditButton] = useState(true);
    // const [CreditresetButton, setResetButton] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [DisableButton, setDisableButton] = useState(true);
    const [checked, setChecked] = useState(false);
    const [creditFlatChecked, setCreditFlatChecked] = useState(false);
    const [lastSelectedCreditDebitId, setLastSelectedCreditDebitId] = useState(0);
    const [selectedRowId, setSelectedRowId] = useState(0);
    const tableName = sessionStorage.getItem('tblName');

    const [maxDate, setMaxDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

    const contentStyle = {
        maxWidth: "600px",
        width: "90%",
        height: "20%",
        marginTop: "-100px"
    };

    const glContentStyle = {
        maxWidth: "600px",
        width: "90%",
        height: "20%",

    };
    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
    const {
        register: register1,
        errors: errors1,
        handleSubmit: handleSubmit1,
        setValue: setDebitValue,
    } = useForm();


    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
        setValue: setCreditValue,
    } = useForm();

    const onAddDebit = (data, e) => {
        data.transactionType = "DE";
        data.creditAmount = 0;
        data.debitAmount = parseFloat(data.debitAmount);
        // data.date = dDate;
        data.flatId = flatId;
        data.state = "New";
        if (data.amount === '0') {
            toast.current.show({ severity: 'error', detail: 'Enter Correct Amount' });
            return;
        }
        setSaveButton(false);
        let duplicate = addDebit.find(a => parseInt(a.glId) === parseInt(data.glId) && a.transactionType === data.transactionType);
        if (!duplicate) {
            setAddDebit(addDebit.concat(data));
            onDebitReset();
        }
        else {
            toast.current.show({ severity: 'warn', detail: 'please choose another glId. This gl Already Added in Table' });
        }
    }

    const onAddCredit = (data, e) => {
        if(dDate=== cDate)
        {
        data.transactionType = "CE";
        data.debitAmount = 0;
        data.creditAmount = parseFloat(data.creditAmount);
        data.flatId = creditFlatId;
        data.state = "New";
        if (data.amount === '0') {
            toast.current.show({ severity: 'error', detail: 'Enter Correct Amount' });
            return;
        }
        setSaveButton(false);
        console.warn(data);
        let duplicate = addDebit.find(a => parseInt(a.glId) === parseInt(data.glId) && a.transactionType === data.transactionType);
        if (!duplicate) {
            setAddDebit(addDebit.concat(data));
            onCreditReset();
        }
        else {
            toast.current.show({ severity: 'warn', detail: 'please choose another glId.This gl Already Added in Table' });
        }
    }
    else{
        toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Credit Details Date and Debit Details Date Mismatch" });
    }
    }

    const onSave = (data, e) => {
        let debitAmountSum = addDebit.reduce((a, v) => a = a + v.debitAmount, 0);
        let creditAmountSum = addDebit.reduce((a, v) => a = a + v.creditAmount, 0);
        if (debitAmountSum === creditAmountSum) {
            // setSaveButton(false);
            addDebit.forEach(b => {
                b.societyId = societyId;
                b.TransactionTable=tableName;
                // b.flatId = flatId;
                if (b.state === "New") {
                    createCreditDebitNote(data, b);
                }
                else if (b.state === "modified") {
                    updateCreditDebitNote(data, b);
                }
                // return isAddMode
                //     ? createCreditDebitNote(data, b)
                //     : updateCreditDebitNote(data, b);
            })
        }
        else {
            // setSaveButton(true);
            if(cDate !== dDate)
            {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Credit Details Date and Debit Details Date Mismatch" });
            }
            if(debitAmountSum !== creditAmountSum)
            {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Debit Amount and credit Amount are MisMatch" });
            }   
        }
    }

    const createCreditDebitNote = (data, b) => {
        setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/Transaction/InsertCreditDebitNote`, b, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data[0].msg });
                }
                else {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data[0].msg });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                window.location.reload();
            })
    }

    const updateCreditDebitNote = (data, b) => {
        setSpinnerVisible(true);
        return axios.put(`${Config.apiUrl}/Transaction/UpdateCreditDebitNote`, b, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })
    }

    // let type = 'DE';
    useEffect(() => {
        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);
            setCreditAddButton(true);
            setCreditEditButton(true);
            setDebitAddButton(true);
            setCreditEditButton(true);
            setSaveButton(true);

        }

        let d = moment(moment(new Date())).format('YYYY-MM-DD');

        if (isAddMode) {
            setCreditValue('date', d);
            setDebitValue('date', d);
            setCDate(d);
            setDDate(d);

            societyService.getJeNo(societyId,tableName).then(data => {
                // setScrollNo(data)
                setCreditValue('jeNo', data);
                setDebitValue('jeNo', data);

            });

            societyService.getScrollNo(d, societyId, 'DE',tableName).then(data => {
                // setScrollNo(data)
                setCreditValue('scrollNo', data);
                setDebitValue('scrollNo', data);

            });
        }

   

        societyService.getPersonalDetails(societyId,tableName)
            .then(data => {
                SetPersonalDetailsData(data)
            });
        console.warn(personalDetailsData);

        //get gLid and glTitle
        societyService.getGlTitle(societyId)
            .then(data => {
                setGlIdData(data)
            });

        societyService
            .getWing(societyId)
            .then((data) =>
                setWings(
                    data.map(({ wing }) => ({ label: wing, value: wing })),

                ),
            );

        if (!isAddMode) {
            // get user and set form fields
            setbuttonLabel('Update');
            setDebitEditButton(false);
            setCreditEditButton(false);
            // setAddButton(true);
            societyService.getCreditDebitNoteOnID(societyId, parseInt(jeNo),tableName).then((data) => 
            {
                setAddDebit(data);
            });
        }
    }, []);

    const onDateChangeDE = (e) => {
        setDDate(e.currentTarget.value);
        societyService.getScrollNo(e.currentTarget.value, societyId, 'DE',tableName).then(data => {
            setDebitValue('scrollNo', data);
            setScrollNo(scrollNo);
        }
        );
    }

    const onDateChangeCR = (e) => {
        setCDate(e.currentTarget.value);
        setCreditValue('date', e.currentTarget.value)
        societyService.getScrollNo(e.currentTarget.value, societyId, 'CE',tableName).then(data => {
            setCreditValue('scrollNo', data);
            setScrollNo(scrollNo);
        }
        );
    }

    const onChangeWing = (e) => {
        let wing = e.currentTarget.value;
        setWing(wing);
        setDebitValue('wing',wing);
    }

    const onRowSelect = (e) => {
        let fNo = e.data.flatNo;
        let name = e.data.name;
        let wing = e.data.wing;
        // setFlatNo(fNo);
        // setName(name);
        // setWing(wing);
        setDebitValue('flatNo',fNo);
        setDebitValue('name',name);
        setDebitValue('wing',wing);
        setFlatId(e.data.id);

    }

    const onRowSelectGlid = (e) => {
        let glid = e.data.glId;
        let glTitle = e.data.glTitle;
        setSelectedGlId(glid);
        setGlTitle(glTitle);
        setDebitValue('glId',glid);
        setDebitValue('glTitle',glTitle);

    }

    const onRowSelectCreditGlid = (e) => {
        let glid = e.data.glId;
        let glTitle = e.data.glTitle;
        setSelectedCreditGlId(glid);
        setCreditGlTitle(glTitle);
    }

    const onRowSelectCredit = (e) => {
        let fNo = e.data.flatNo;
        let creditOwnerName = e.data.name;
        let creditWing = e.data.wing;
        setCreditValue('flatNo',fNo);
        setCreditValue('name',creditOwnerName);
        setCreditValue('wing',creditWing);
        setCreditFlatId(e.data.id);
    }

    const onChangeFlat = (e) => {
        let flatNo = e.currentTarget.value;
        if (flatNo !== '') {
            flatNo = parseInt(flatNo);
            setDebitValue('flatNo',flatNo);
            setFlatNo(flatNo);
        }
        societyService
            .getName(flatNo, wing, societyId)
            .then((data) => {
                if (data.length > 0) {
                    // setName(data[0].name);
                    setDebitValue('name',data[0].name);
                    setFlatId(data[0].id);
                }
                else {
                    setDebitValue('name','');
                }
            }
            );
    }

    const onChangeWingCredit = (e) => {
        let wing = e.currentTarget.value;
        setCreditWing(wing);
        setCreditValue('wing',wing);

    }

    const onChangeFlatCredit = (e) => {
        let flatNo = e.currentTarget.value;
        if (flatNo !== '') {
            flatNo = parseInt(flatNo);
            setCreditValue('flatNo',flatNo);
            setCreditFlatNo(flatNo);
        }
        societyService
            .getName(flatNo, creditWing, societyId)
            .then((data) => {
                if (data.length > 0) {
                    setCreditValue('name',data[0].name);
                    setCreditFlatId(data[0].id);
                }
                else {
                    setCreditValue('name','');
                    // toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
                }
            }
            );
    }

    const onChangeDebitGlId = (e) => {
        let glId = e.currentTarget.value;
        societyService
            .getGlTitleOnglId(glId, societyId)
            .then((data) => {
                if (data != null) {
                    setDebitValue('glTitle',data);
                }
                else {
                    setDebitValue('glTitle','');
                    toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
                }
            }
            );
    }

    const onChangeCreditGlId = (e) => {
        let glId = e.currentTarget.value;
        societyService
            .getGlTitleOnglId(glId, societyId)
            .then((data) => {
                if (data != null) {
                    setCreditValue('glTitle',data);
                }
                else {
                    setCreditValue('glTitle','');
                    toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
                }
            }
            );
    }

    const onDebitAmountChange = (e) => {
        let amount = e.currentTarget.value
        amount = parseFloat(amount);
        setDebitAmount(amount);
    }

    const InputDebitGlId = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setDebitValue('glId', onlyNums);
    }

    const InputFlatNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setDebitValue('flatNo', onlyNums);
    }

    const InputDebitAmount = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setDebitValue('amount', onlyNums);
    }

    const InputCreditGlId = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setCreditValue('glId', onlyNums);
    }

    const InputCreditFlatNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setCreditValue('flatNo', onlyNums);
    }

    const InputCreditAmount = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setCreditValue('amount', onlyNums);
    }

    const rowClass = (data) => {
        return {
            'selectedRow': data.rowActive
        }
    }

    const onDoubleClickEdit = (event) => {
        if (event.data.transactionType === "DE") {
            setDebitAddButton(true);
            onCreditReset();
            setCreditAddButton(false);
        }
        else if (event.data.transactionType === "CE") {
            setCreditAddButton(true);
            onDebitReset();
            setDebitAddButton(false);

        }
        if (lastSelectedCreditDebitId !== event.data.id) {
            let selectedRow = addDebit.find(a => a.id === lastSelectedCreditDebitId)
            if (selectedRow !== undefined) {
                selectedRow.rowActive = false;
            }
            else {

            }
        }

        var data = event.data;
        data.rowActive = true;
        rowClass(data);
        setLastSelectedCreditDebitId(data.id);
        setSelectedRowId(data.id);
        if (data.transactionType === 'DE') {
            if(data.flatId !== 0){
                setChecked(true);
            }
            // setDDate(data.dateFormat);
            setDebitValue('date', data.dateFormat);
            // setSelectedGlId(data.glId);
            setDebitValue('jeNo', data.jeNo);
            setDebitValue('glId', data.glId);
            setDebitValue('glTitle', data.glTitle);
            setDebitValue('wing', data.wing);
            setDebitValue('flatNo', data.flatNo);
            setDebitValue('name', data.name);
            setDebitValue('debitAmount', data.debitAmount);
            setDebitValue('scrollNo', data.scrollNo);
            setDebitValue('narration', data.narration);
            setFlatId(data.flatId);
        }
        else {
            if(data.flatId !== 0){
                setCreditFlatChecked(true);
            }
            setCreditValue('date', data.dateFormat || data.date);
            setCreditValue('jeNo', data.jeNo);
            setCreditValue('glId', data.glId)
            setCreditValue('glTitle', data.glTitle)
            setCreditValue('wing', data.wing);
            setCreditValue('flatNo', data.flatNo);
            setCreditValue('name', data.name);
            setCreditValue('creditAmount', data.creditAmount);
            setCreditValue('scrollNo', data.scrollNo);
            setCreditValue('narration', data.narration);
            setCreditFlatId(data.flatId);
        }
    }

    const onEdit = (data, e) => {

        console.warn(data);
        data.id = selectedRowId;
        data.debitAmount = parseFloat(data.debitAmount);
        if(checked)
        {
            data.flatId=flatId;
        }
        data.creditAmount = 0;
        data.transactionType = "DE";
        let existingIndex = addDebit.findIndex(x => x.id === data.id);
        if (existingIndex >= 0) {
            if (data.state != "New") {
                data.state = "modified";
            }
            addDebit[existingIndex] = data;
            addDebit = [...addDebit];
            setAddDebit(addDebit);
        }

    }

    const onCreditEdit = (data, e) => {
        data.id = selectedRowId;
        if(creditFlatChecked)
        {
            data.flatId=creditFlatId;
        }
        data.creditAmount = parseFloat(data.creditAmount);
        data.debitAmount = 0;
        data.transactionType = "CE";
        let existingIndex = addDebit.findIndex(x => x.id === data.id);
        if (existingIndex >= 0) {
            if (data.state != "New") {
                data.state = "modified";
            }
            addDebit[existingIndex] = data;
            addDebit = [...addDebit];
            setAddDebit(addDebit);
        }
    }

    const onDebitReset = () => {
        const fields = ['glId', 'glTitle', 'wing', 'flatNo', 'name', 'debitAmount', 'narration'];
        fields.forEach(field => setDebitValue(field, ''));
        // setAddButton(false);
    }


    const onCreditReset = () => {
        const fields = ['glId', 'glTitle', 'wing', 'flatNo', 'name', 'creditAmount', 'narration'];
        fields.forEach(field => setCreditValue(field, ''));
        // setAddButton(false);
    }
    const onReset = () => {
        reset();
        window.location.reload();
      }
    return (
        <div className='creditDebitNote'>
            <div className="container">
                <Toast ref={toast} position={'center'} />
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                <div class="debit container__half">
                    <form key={1} style={{ border: "1px solid grey", borderRadius: '2%', backgroundColor: "Info" }}>
                        <Card className="Debit">
                            <h2 style={{ textAlign: 'center', color: 'red' }}>Debit Details</h2>
                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Date:</label>
                                    <div className="p-col">
                                        <input type="Date" name="date" autoFocus={focusValue}   className="inputTextBox" style={{ width: '175px' }} onChange={onDateChangeDE} ref={register1({ required: true })} max={maxDate} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="p-field p-grid">
                                    <label>Je Number:</label>
                                    <div className="p-col">
                                        <input type="text" name="jeNo"   className="inputTextBox" style={{ width: '175px' }} ref={register1} readOnly />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors1.date && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please select Date</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>GL ID:</label>
                                    <div className="p-col">
                                        <input type="text" autoComplete="off" name="glId"   className="inputTextBox" style={{ width: '100px' }} onChange={onChangeDebitGlId} ref={register1({ required: true })} onInput={InputDebitGlId}  />
                                    </div>
                                    <div>
                                        <input type="text" name="glTitle"   className="inputTextBox" style={{ width: '378px' }} ref={register1({ required: true })} autoComplete="off"  />
                                        <Popup trigger={<a href="#" style={{ marginLeft: '10px' }}>Search Gl-Id</a>} position="right top" contentStyle={glContentStyle}>
                                            <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => setSelectedGlidGltitle(e.value)} dataKey="glId" className="p-datatable-sm" scrollable scrollHeight='300px' style={{ overflow: 'hidden' }} width="250px" >
                                                <Column field="glId" header="Gl Id" filter></Column>
                                                <Column field="glTitle" header="GL Title" filter></Column>
                                            </DataTable>
                                        </Popup>
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors1.glId && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please enter GL ID</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline" style={{ justifyContent: 'space-around' }}>
                                <div>
                                    <label className="label200" >Enable Flat Details:</label>
                                    <Checkbox inputId="binary" style={{ margin: '2px' }} checked={checked} onChange={e => setChecked(e.checked)} />
                                </div>
                                <div>
                                    <Popup disabled={!checked} trigger={<a href="#" style={{ marginRight: '30px' }} >Search Flat No</a>} position="right top" contentStyle={contentStyle}>
                                        <DataTable value={personalDetailsData} selectionMode="single" selection={selectedFlatNoName} onRowSelect={onRowSelect} onSelectionChange={e => setSelectedFlatNoName(e.value)} dataKey="id" className="p-datatable-sm" scrollable scrollHeight='350px' style={{ overflow: 'hidden' }} width="250px" >
                                            <Column field="wing" header="Wing" filter style={{ width: '150px' }}></Column>
                                            <Column field="flatNo" header="Flat No" filter style={{ width: '150px' }}></Column>
                                            <Column field="name" header="Name" filter></Column>
                                        </DataTable>
                                    </Popup>
                                </div>
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Wing:</label>
                                    <div className=" p-col" >
                                        <select style={{ width: '175px' }} disabled={!checked} name="wing"   className="inputTextBox" ref={register1} onChange={onChangeWing} >
                                            <option hidden value="">Select Wing</option>
                                            {wings.map(({ label, value: wings }) => (
                                                <option key={wings} value={wings}>
                                                    {label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="p-field p-grid">
                                    <label>Flat No:</label>
                                    <div className="p-col" >
                                        <input type="text" disabled={!checked} name="flatNo"   className="inputTextBox" ref={register1} onChange={onChangeFlat}  onInput={InputFlatNo} autoComplete="off" />
                                    </div>
                                </div>

                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Name:</label>
                                    <div className="p-col-12 p-md-2 ">
                                        <input type="text" disabled={!checked} name="name"   className="inputTextBox" style={{ width: '485px' }} ref={register1}  autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors1.name && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>Please select Name</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Amount:</label>
                                    <div className="p-col">
                                        <input type="text" name="debitAmount"   className="inputTextBox" ref={register1({ required: true })} onChange={onDebitAmountChange} onInput={InputDebitAmount} autoComplete="off" />
                                    </div>
                                </div>

                                <div className="p-field p-grid">
                                    <label>Scroll No:</label>
                                    <div className="p-col" >
                                        <input type="text" name="scrollNo" readOnly   className="inputTextBox" ref={register1({ required: true })} value={scrollNo} autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors1.amount && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please enter amount</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Narration:</label>
                                    <div className="p-col">
                                        <input type="textbox" name="narration" className= 'largeTextBox' style={{ width: '485px', height: '80px' }} ref={register1({ required: true })} autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors1.narration && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>There should be narration</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline" style={{ marginLeft: '200px', marginTop: '30px' }}>
                                <div className="p-field">
                                    <Button label="ADD" type="submit" onClick={handleSubmit1(onAddDebit)} disabled={debitAddButton} />
                                </div>

                                <div className="p-field">
                                    <Button label="EDIT" type="submit" onClick={handleSubmit1(onEdit)} disabled={debitEditButton} />
                                </div>
                                <div className="p-field">
                                    <Button label="RESET" type="reset" onClick={handleSubmit(onDebitReset)} disabled={DisableButton} />
                                </div>
                            </div>
                        </Card>
                    </form>
                </div>


                <div className="credit container__half">
                    <form key={2} style={{ border: "1px solid black", borderRadius: '2%', backgroundColor: "Info" }} autoComplete="off" >
                        <Card className="CreditDebitNote Credit">
                            <h2 style={{ textAlign: 'center', color: 'red' }}>Credit Details</h2>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid ">
                                    <label>Date:</label>
                                    <div className="p-col">
                                        <input type="Date" name="date"   className="inputTextBox" style={{ width: '175px' }} onChange={onDateChangeCR} ref={register2({ required: true })} value={cDate}  max={maxDate} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="p-field p-grid">
                                    <label>Je Number:</label>
                                    <div className="p-col">
                                        <input type="text" name="jeNo"   className="inputTextBox" style={{ width: '175px' }} ref={register2} readOnly />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors2.date && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please select Date</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>GL ID:</label>
                                    <div className="p-col">
                                        <input type="text" name="glId"   className="inputTextBox" style={{ width: '100px' }} ref={register2({ required: true })} onChange={onChangeCreditGlId} onInput={InputCreditGlId} value={selectedCreditGlId} autoComplete="off" />
                                    </div>
                                    <div>
                                        <input type="text" name="glTitle"   className="inputTextBox" style={{ width: '378px' }} ref={register2({ required: true })} value={CreditGlTitle} />
                                        <Popup trigger={<a href="#" style={{ marginLeft: '10px' }}>Search Gl-Id</a>} position="left top" contentStyle={glContentStyle}>
                                            <DataTable value={glIdData} selectionMode="single" selection={selectedCreditGlidGltitle} onRowSelect={onRowSelectCreditGlid} onSelectionChange={e => setSelectedCreditGlidGltitle(e.value)} dataKey="glId" className="p-datatable-sm" scrollable scrollHeight='300px' style={{ overflow: 'hidden' }} width="250px" autoComplete="off" >
                                                <Column field="glId" header="Gl Id" filter></Column>
                                                <Column field="glTitle" header="GL Title" filter></Column>
                                            </DataTable>
                                        </Popup>
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors2.glId && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please enter GL ID</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline" style={{ justifyContent: 'space-around' }}>
                                <div>
                                    <label className="label200" >Enable Flat Details:</label>
                                    <Checkbox inputId="binary" style={{ margin: '2px' }} checked={creditFlatChecked} onChange={e => setCreditFlatChecked(e.checked)} />
                                </div>
                                <div>
                                    <Popup disabled={!creditFlatChecked} trigger={<a href="#" style={{ marginRight: '30px' }}>Search Flat No</a>} position="left top" contentStyle={contentStyle}>
                                        <DataTable value={personalDetailsData} selectionMode="single" selection={selectedFlatNoNameCredit} onRowSelect={onRowSelectCredit} onSelectionChange={e => setSelectedFlatNoNameCredit(e.value)} dataKey="id" className="p-datatable-sm" scrollable scrollHeight='350px' style={{ overflow: 'hidden' }} width="250px" >
                                            <Column field="wing" header="Wing" filter style={{ width: '150px' }}></Column>
                                            <Column field="flatNo" header="Flat No" filter style={{ width: '150px' }}></Column>
                                            <Column field="name" header="Name" filter></Column>
                                        </DataTable>
                                    </Popup>
                                </div>
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Wing:</label>
                                    <div className=" p-col" >
                                        <select style={{ width: '175px' }} disabled={!creditFlatChecked} name="wing"   className="inputTextBox" ref={register2} onChange={onChangeWingCredit} >
                                            <option hidden value="">Select Wing</option>
                                            {wings.map(({ label, value: wings }) => (
                                                <option key={wings} value={wings}>
                                                    {label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="p-field p-grid">
                                    <label>Flat No:</label>
                                    <div className="p-col">
                                        <input type="text" disabled={!creditFlatChecked} name="flatNo"   className="inputTextBox" ref={register2} onChange={onChangeFlatCredit} onInput={InputCreditFlatNo} autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Name:</label>
                                    <div className="p-col-12 p-md-2 ">
                                        <input type="text" disabled={!creditFlatChecked} name="name"   className="inputTextBox" style={{ width: '485px' }} ref={register2} autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors2.name && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>Please select Name</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Amount:</label>
                                    <div className="p-col">
                                        <input type="text" name="creditAmount"   className="inputTextBox" ref={register2({ required: true })} onInput={InputCreditAmount} autoComplete="off" />
                                    </div>
                                </div>

                                <div className="p-field p-grid">
                                    <label>Scroll No:</label>
                                    <div className="p-col" >
                                        <input type="text" name="scrollNo" value={scrollNo}   className="inputTextBox" ref={register2} />

                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors2.amount && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please enter amount</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label>Narration:</label>
                                    <div className="p-col">
                                        <input type="textbox" name="narration" className= 'largeTextBox' style={{ width: '485px', height: '80px' }} ref={register2({ required: true })} autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                {errors2.narration && (
                                    <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>There should be narration</p>
                                )
                                }
                            </div>

                            <div className="p-formgroup-inline" style={{ marginLeft: '250px', marginTop: '30px' }}>
                                <div className="p-field">

                                    <Button label="ADD" type="submit" onClick={handleSubmit2(onAddCredit)} disabled={creditAddButton} />
                                </div>
                                <div className="p-field">
                                    <Button label="EDIT" type="submit" onClick={handleSubmit2(onCreditEdit)} disabled={creditEditButton} />
                                </div>
                                <div className="p-field">
                                    <Button label="RESET" type="button" onClick={handleSubmit(onCreditReset)} disabled={DisableButton} />
                                </div>
                            </div>
                        </Card>
                    </form>
                </div>

            </div>
            <div class="datatable">
                <div className="p-field">
                    <DataTable value={addDebit} className="p-datatable-sm" width="500px" rowClassName={rowClass} onRowDoubleClick={onDoubleClickEdit} >
                        <Column field="date" header="Date" ></Column>
                        <Column field="jeNo" header="Je No" ></Column>
                        <Column field="glId" header="Gl Id"></Column>
                        <Column field="glTitle" header="GL Title" ></Column>
                        <Column field="wing" header="Wing"></Column>
                        <Column field="flatNo" header="Flat No "></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="debitAmount" header="Debit Amount"></Column>
                        <Column field="creditAmount" header="Credit Amount"></Column>
                        <Column field="scrollNo" header="Scroll No"></Column>
                        <Column field="narration" header="Narration"></Column>
                        <Column field="transactionType" header="Transaction Type"></Column>
                    </DataTable>
                </div>

                <div className="p-formgroup-inline" style={{ backgroundColor: 'black', justifyContent: 'center', width: '100%' }}>
                    <div className="p-field">

                        <Button label={buttonLabel} style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} type="submit " onClick={handleSubmit(onSave)} disabled={saveButton} />
                        <Button label="RESET" style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} onClick={onReset} disabled={DisableButton} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CreditDebitNote;