/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
// import './App.css';
import { Link } from "react-router-dom";
import React from "react";
// import {Tabs, Tab} from 'react-bootstrap-tabs';
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import GroupMasterUi from "../../GroupMasterUi";
// import SubGroupMasterUi from "../../SubGroupMasterUi";
// import GL_Master from "../../GL_Master";
import styles from "./SocietyDetails.module.css";
import { Toast } from 'primereact/toast';
import { useState, useEffect,useRef } from "react";
import { useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axios from 'axios';
import SocietyService from '../../Service/SocietyService';
// import { array } from "yup/lib/locale";
import Config from '../../Config.json';
import authHeader from "../../authHeader";
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";



function SocietyMasters({ societyId, id, setActiveTab }) {
  const toast = useRef(null);
  // const Wing = useRef();

  const societyService = new SocietyService();
  const isAddMode = !id;
  let history = useHistory();
  let indexNoFromWing;
  let indexNoToWing;

  const [fromWing, setWing] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']);
  const Add = fromWing.map((Add) => Add);
  const [isRange, setIsRange] = useState([]);
  const [isManual, setIsManual] = useState([]);
  var [wings, setWings] = useState([]);
  const [wingData, setWingData] = useState([]);
  const [wingName, SetWingName] = useState();
  const [focusValue, SetFocusValue] = useState(true);
  const [selectedCheckboxData, setSelectedCheckxoxData] = useState([]);
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);



  

  const { errors, register, reset, handleSubmit, setValue } = useForm();

  // {resolver: yupResolver(validationSchema)}


  // const onSubmit = (data,e) => 
  //   // if (window.confirm("Is All Owner Added"))
  //   // {
  //   return isAddMode
  //   ? InsertWing(data)
  //   : UpdateWing(data);

  // }
  const onSubmit = (data, e) => {
    let WingData = [];
    wings.forEach(w => {
      if(w.wing.trim().toUpperCase()=="N/A"){
      }
      else if (isAddMode) {
        let tempWing = { 'SocietyId': societyId, 'wing': w.wing };
        WingData.push(tempWing);
      }
      else {
        let tempWing = { 'SocietyId': societyId, 'wing': w.wing };
        WingData.push(tempWing);
      }
    })
    setWingData(WingData);

    WingData.forEach(b => {
      if (b.SocietyId === '0') {
        alert("please fill Society details first before adding wing");
        return;
      }
      setSpinnerVisible(true);
      return axios.post(`${Config.apiUrl}/WingMaster/Insert`, b, { headers: authHeader() })
        .then(response => {
          console.warn(data);
          console.log(response)
          if(response.data.length > 0){
          if (response.data != null ) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
          }
          else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
          }
          if (response.data[0].success) {
            setNextDisabled(false);
          }
        }
        })
        .catch(error => { 
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
          setSpinnerVisible(false);
        })   
       })
  }

  const OnClick = (e) => {
    const IsRange = e.currentTarget.value === 'true' ? true : false;
    setIsRange(IsRange);
  }

  const OnClickManually = (e) => {
    const IsManual = e.currentTarget.value === 'true' ? true : false;
    setIsManual(IsManual);
  }

  const onChangeFromWing = (e) => {
    let NoFromWing = e.currentTarget.value;
    indexNoFromWing = NoFromWing;
  };

  const onChangeToWing = (e) => {
    let NoToWing = parseInt(e.currentTarget.value) + 1;
    let resultedWings = [];
    let selectedWings = fromWing.slice(indexNoFromWing, NoToWing);
    selectedWings.forEach(w => {
      let tempWing = { 'wing': w };
      resultedWings.push(tempWing);
    })
    setWings(resultedWings);
  };

  const onAddWing = (data, e) => {
    data.societyId=societyId;
    if (data.wing === '') {
      toast.current.show({ severity: 'warn', summary: 'alert', detail: "Please Enter Wing Name"});
      return;
    }

    let existingValue = wings.findIndex(x => x.wing === data.wing);
    if (existingValue < 0) {
      setWings(wings.concat(data));
      SetWingName("");
      SetFocusValue(true);
    }
    else {
      toast.current.show({ severity: 'warn', summary: 'alert', detail: "wing is already added"});
    }
    reset();
  }

  const onResetGrid = (e) => {
    let resertWings = "";
    setWings(resertWings);
    window.location.reload();
  }

  useEffect(() => {
    let array = [];
    societyService.getWingDetailsOnID(societyId)
      .then(data => {
        // data.data.forEach(d => {
        //   // array.push({ 'wing': d.wing, 'id': d.id });
        //   // array.sort((a, b) => a.wing.localeCompare(b.wing));
        // });
        data.sort((a, b) => a.wing.localeCompare(b.wing));

        setWings(data);
        setNextDisabled(false);
      })

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);
    }
  }, []);

  const onNext = (e) => {
    setActiveTab(2);
  }

  const onDelete = (event) => {
    if(selectedCheckboxData != null)
    {
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
            </div>
          </div>
        </div>
      )
    });
   }
   else{
    toast.current.show({ severity: 'warn',sticky:true, detail: "PLEASE SELECT ROW"});
    return;
   }
  }

  const onYes = () => {
    toast.current.clear();
    setSpinnerVisible(true);
    selectedCheckboxData.forEach(d => {
      if (selectedCheckboxData[0].SocietyId === '0') {
        let data = wings.filter(b => b.wing != d.wing);
        setWings(data);
      }
      else {
        let data = wings.filter(b => b.wing != d.wing);
        let Id = d.id;
        wings=data;
        axios.delete(`${Config.apiUrl}/WingMaster/delete?id=${Id}`, { headers: authHeader() })
          .then(response => {
            console.log(response)
            if (response.data != null) {
              toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg});
            }
            else {
              toast.current.show({ severity: 'warn', summary: 'alert', detail: response.data.msg});
            }
          })
          .catch(error => { 
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
        })
        .finally(() => {
            setSpinnerVisible(false);
            wings=data;

            // setWings(data);
          })
      }
    })
  }

  const onNo = () => {
    toast.current.clear();
    return;
  };

  

  const toInputUpperCase = (e) => {
    e.currentTarget.value = ("", e.currentTarget.value).toUpperCase();
  }

  const header = (
    <div className={styles.tableheader}>
      <label>Wing</label>
      <Button icon="pi pi-trash" value="Delete" tooltip="Delete" className="btn" onClick={handleSubmit(onDelete)} disabled={DisableButton} />
    </div>
  );

  return (
      <div>
          <Toast ref={toast} position="center" />
          <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
          <div className={styles.WingMaster}>
          <div className="p-formgroup-inline" style={{ marginTop: '20px', marginLeft: '20px' }}>
            <div className="p-field">
              <label> Wing:</label>
              <input  type="text" name="wing" autoFocus={focusValue} onInput={toInputUpperCase} className={styles.SelectMediumWidth}  ref={register} value={wingName} onChange={e => SetWingName(e.target.value)} autoComplete="off" />
            </div>

            <div>
              <Button label="Add" className="p-button-sm" style={{ transition: 'all .2s',marginTop:'15px',padding:'25px 15px',width:'130px',height:'30px',}}onClick={handleSubmit(onAddWing)} disabled={DisableButton} />
            </div> 
          </div>

          <input type="text" value={societyId} name="SocietyId" ref={register} hidden />
          <div className="DataTable"  >
            <DataTable value={wings} className="p-datatable-gridlines" style={{ width: '200px' }} header={header} selection={selectedCheckboxData} onSelectionChange={e => setSelectedCheckxoxData(e.value)} scrollable scrollHeight="200px" >
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
              <Column field="wing" header="Wing Name" style={{ width: "100px" }}></Column>
            </DataTable>
          </div>

          <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
            <div className="p-field">
              <Button label="SAVE" type="submit " style={{ transition: 'all .2s',marginTop:'15px',padding:'25px 15px',width:'130px',height:'30px',}}onClick={handleSubmit(onSubmit)} disabled={DisableButton} />
            </div>
            <div className="p-field">
              <Button disabled={nextDisabled} label="NEXT" style={{ transition: 'all .2s',marginTop:'15px',padding:'25px 15px',width:'130px',height:'30px',}}onClick={onNext} />
            </div>
            <div className="p-field ">
              <Button label="RESET" style={{ transition: 'all .2s',marginTop:'15px',padding:'25px 15px',width:'130px',height:'30px',}}onClick={onResetGrid} disabled={DisableButton} />
            </div>
          </div>
        </div>
      </div>
  );
}
export default SocietyMasters;
