import "primeflex/primeflex.css";
import "./BillGeneration.css";
import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card, Hidden } from "@material-ui/core";
import { Dropdown } from 'primereact/dropdown';
import SocietyService from "../../Service/SocietyService";
import { useContext } from "react";
import { SocietyContext } from "../../SocietyContext";
import axios from "axios";
import Config from "../../Config.json";
import { Toolbar } from 'primereact/toolbar';
import { useHistory } from 'react-router-dom';
import { date } from "yup/lib/locale";
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import authHeader from "../../authHeader";
import '../loader.css';
import Enumerable from "linq";
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

function EditFlatBill({ props }) {
    let history = useHistory();
    const societyId = parseInt(localStorage.getItem('societyId'));
    const societyName = localStorage.getItem('societyName');
    const tableName = sessionStorage.getItem('tblName');
    const societyService = new SocietyService();
    var converter = require('number-to-words');
    var [billedGLs, setBilledGLs] = useState([]);
    const [wings, setWings] = useState([]);
    const [cMaintainance, setCurrentMaintainance] = useState();
    const [CurrInterest, setCurrInterest] = useState();
    const [oSPrincipal, setOutStandingPrincipal] = useState();
    const [oSInterest, setOutstandingInterest] = useState();
    const [oSTotal, setOutStandingTotal] = useState();
    const [dpc, setDpc] = useState();
    const [CurrBillTotal, setCurrBillTotal] = useState();
    const [tPayable, setTotalPayable] = useState();
    const [flatNo, setFlatNo] = useState();
    const [billDate, setBillDate] = useState();
    const [billFor, setBillFor] = useState([]);
    const [dueDate, setDueDate] = useState();
    const [SrNo, setSrNo] = useState('');
    const billNoParam = useParams("billNo");
    const [BillNo, setBillNo] = useState();
    var [MaxBillNo, setMaxBillNo] = useState();
    const [ownerName, setOwnerName] = useState();
    const [billForMonth, setBillForMonth] = useState();
    const [area, setArea] = useState();
    const toast = useRef(null);
    const interval = useRef(null);
    const [Generateloading, setGenerateloading] = useState(undefined);
    const [Saveloading, setSaveloading] = useState(undefined);
    const [billContainer, setBillContainer] = useState(undefined);
    const [billContainerOriginal, setBillContainerOriginal] = useState(undefined);
    const [products2, setProducts2] = useState(null);
    const [glName, setGlTitle] = useState();
    const [glValue, setGlValue] = useState();
    const [glDialog, setGlDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [glAmount, setAmountValue] = useState(0.00);
    const [glTitleValue, setGlTitleValue] = useState();
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    var MonthList;
    var Year;
    var Month;
    var FromYear;
    var FromMonth;

    useEffect(() => {
        societyService.getMaxBillNo(societyId,tableName).then((data) => {
            setMaxBillNo(data);
        });

        let bill = parseInt(billNoParam.billNo);
        societyService.getBillDetailsForBillNo(bill, societyId, -1,tableName).then((billContainer => {
            const fields = ['name', 'srNo', 'billFor', 'billDate', 'dueDate', 'billNo', 'society', 'area', 'currentTotalFromGLHeads', 'currentInterest', 'dpc', 'CurrBillTotal', 'oustandingsArrears', 'interestArrears', 'dpcArrears', 'totalArrears', 'totalAmountPayable'];
            fields.forEach(field => setValue(field, billContainer[field]));
            setValue('flatNo', `${billContainer.wing}-${billContainer.flatNo}`);
            setValue('billDate', billContainer.billDateFormat);
            setValue('dueDate', billContainer.dueDateFormat);
            billContainer.maintainanceInfos.forEach(a => {
                a.state = 'Updated';
            })
            let currentTotalFromGLHeads = (billContainer.maintainanceInfos && billContainer.maintainanceInfos.reduce((a, v) => a + v.amount, 0));
            billContainer.currentTotalFromGLHeads = currentTotalFromGLHeads;
            setValue('currentTotalFromGLHeads', currentTotalFromGLHeads);
            setValue('CurrBillTotal', currentTotalFromGLHeads + billContainer.currentInterest + billContainer.dpc);
            billContainer.CurrBillTotal = currentTotalFromGLHeads + billContainer.currentInterest + billContainer.dpc;
            billContainer.totalAmountPayable=billContainer.CurrBillTotal + billContainer.totalArrears;
            setValue('totalAmountPayable', billContainer.totalAmountPayable);
            console.log(billContainer);
            setBillContainer(billContainer);
            setBillContainerOriginal(billContainer);
        })).catch(error=>{
            toast.current.show({ severity: 'error', detail: 'Error in loading bill details' });
        })

        societyService
            .getWing(societyId)
            .then((data) =>
                setWings(
                    data.map(({ wing }) => ({ label: wing, value: wing })),
                ),
            );

        societyService.getGlMasterIsBilling(societyId).then(data => {
            let tempArray = [];
            data.forEach(d => {
                tempArray = [...tempArray, d];
            })
            setBilledGLs(tempArray);
            console.log(billedGLs);
        });

        
    }, []);


    const onSave = () => {
        setSaveloading(true);
        setTimeout(() => {
            try {
                let id = billContainer.flatId;
                billContainer.amountInWord = converter.toWords(billContainer.totalAmountPayable);
                billContainer.narration = `updated bill for the Month ${billContainer.billFor}`;
                return axios.put(`${Config.apiUrl}/BillGeneration/EditMaintenance?id=${id}&societyId=${societyId}&societyName=${societyName}&billingDate=${billContainer.billDateFormat}&transactionTableName=${tableName} `, billContainer, { headers: authHeader() })
                    .then(response => {
                        console.log(response)
                        if (response.data.success) {
                            setSaveloading(false);
                            toast.current.show({ severity: 'success', sticky: 'true', detail: response.data.msg });
                            // window.location.reload();
                        }
                        else {
                            toast.current.show({ severity: 'error', detail: response.data.msg });
                        }
                    })
                    .catch(error => {
                        toast.current.show({ severity: 'error', detail: error });
                    })
            }
            catch (error) {
                toast.current.show({ severity: 'error', detail: error });
            }
        }, 1000);
    }

    const GlHeadTotal = (data) => {
        let currentTotalFromGLHeads = (data && data.reduce((a, v) => a + v.amount, 0));
        billContainer.currentTotalFromGLHeads = currentTotalFromGLHeads;
        setValue('currentTotalFromGLHeads', currentTotalFromGLHeads);
        billContainer.CurrBillTotal = currentTotalFromGLHeads + billContainer.currentInterest + billContainer.dpc;
        setValue('CurrBillTotal', billContainer.CurrBillTotal);
        billContainer.totalAmountPayable = billContainer.CurrBillTotal + billContainer.totalArrears;
        setValue('totalAmountPayable', billContainer.totalAmountPayable);
    }

    const billCalculation = () => {
        billContainer.CurrBillTotal = billContainer?.currentTotalFromGLHeads + billContainer?.currentInterest + billContainer?.dpc;
        setValue('CurrBillTotal', billContainer?.CurrBillTotal);
        billContainer.totalArrears = billContainer?.oustandingsArrears + billContainer?.interestArrears + billContainer?.dpcArrears;
        setValue('totalArrears', billContainer.totalArrears);
        billContainer.totalAmountPayable = billContainer.CurrBillTotal + billContainer.totalArrears;
        setValue('totalAmountPayable', billContainer.totalAmountPayable);
    }

    const statusEditor = () => {
        return (
            <Dropdown value={glValue} options={glName} optionLabel="glId" optionValue="glId"
                onChange={onGlTitleValueChange} placeholder="Select a glTitle"
            />
        );
    }

    const onRemove = () => {
        window.location.reload();
    }

    function compare(a, b) {
        if (a.glId < b.glId) {
            return -1;
        }
        if (a.glId > b.glId) {
            return 1;
        }
        return 0;
    }

    const currentInterest = (e) => {
        let value = e.currentTarget.value;
        if (value === '') {
            value = 0;
        }
        billContainer.currentInterest = parseFloat(value);
        billCalculation();
    }
    const currentDpc = (e) => {
        let value = e.currentTarget.value;
        if (value === '') {
            value = 0;
        }
        billContainer.dpc = parseFloat(value);
        billCalculation();
    }

    const outStandingPrincipal = (e) => {
        let value = e.currentTarget.value;
        if (value === '') {
            value = 0;
        }
        billContainer.oustandingsArrears = parseFloat(value);
        billCalculation();
    }
    const outStandingInterest = (e) => {
        let value = e.currentTarget.value;
        if (value === '') {
            value = 0;
        }
        billContainer.interestArrears = parseFloat(value);
        billCalculation();
    }
    const outStandingDpc = (e) => {
        let value = e.currentTarget.value;
        if (value === '') {
            value = 0;
        }
        billContainer.dpcArrears = parseFloat(value);
        billCalculation();
    }
    const priceEditor = (productKey, props) => {
        return <InputNumber value={props.rowData['amount']} onValueChange={(e) => onAmountValueChange(productKey, props, e.value)} mode="currency" currency="INR" />

    }

    const onGlTitleValueChange = (value) => {
        setGlValue(value);
        let selectedGlTitle = billedGLs.find(a => a.glId === value);
        setGlTitleValue(selectedGlTitle.glTitle);
    }

    const onAmountValueChange = (productKey, props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        GlHeadTotal(updatedProducts);
    }

    const onRowEditComplete1 = (e) => {
        let _products2 = [...products2];
        let { newData, index } = e;
        _products2[index] = newData;
        setProducts2(_products2);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment><br></br><br></br>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={handleSubmit(openNew)} />
            </React.Fragment>
        )
    } 

    const openNew = () => {
        setSubmitted(false);
        setGlDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setGlDialog(false);
        GlHeadTotal(billContainer.maintainanceInfos);
    }

    const onAddDataTable = () => {
        let addGl = [];
        addGl = { glId: glValue, glTitle: glTitleValue, amount: parseFloat(glAmount), state: 'Added' };
        if (glValue === undefined || glValue === '') {
            toast.current.show({ severity: 'warn', detail: 'please select glid' });
            return;
        }
        if (addGl.glId !== '' || addGl.glId !== undefined) {
            let duplicate = billContainer.maintainanceInfos.find(a => a.glId === glValue);
            if (duplicate === undefined) {
                billContainer.maintainanceInfos.push(addGl);
                setBillContainer(billContainer);
                setGlValue('');
                setGlTitleValue('');
                setAmountValue('');
            }
            else {
                toast.current.show({ severity: 'warn', detail: 'This gl Alredy added in maintainance details' });
            }
        }
        else {
            toast.current.show({ severity: 'warn', detail: 'please fill data' });
        }
    }

    return (
        <form onsubmit="return false;" className="BillGeneration">
            <Toast ref={toast} position={'center'} onRemove={onRemove} />
            {Saveloading ? (
                <div className="spinner">
                    <span>Saving...</span>
                    <div className="half-spinner"></div>
                </div>
            ) : (

                <div style={{
                    border: "1px solid black",
                    width: 'auto',
                    backgroundColor: "Info",
                    height: "100%",
                    margin: '10px',
                }}>
                    <div className="billForm" >
                        <div style={{ border: "1px solid black", padding: '3px', height: "100px" }}>
                            <div className="Form_inline">
                                <label>
                                    Flat No:
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    className="TextBoxSize150"
                                    name="flatNo"
                                    ref={register}
                                />
                                
                                <label>
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    className="TextBoxSize150"
                                    name="name" style={{ border: "1px solid black",width:"20%"}} 
                                    ref={register}
                                    value={ownerName}
                                />

                                {/* <label className="Label">
                                    Sr:
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    name="sr"
                                    className="TextBoxSize100"
                                    ref={register}
                                    value={SrNo}
                                /> */}
                            </div>

                            <div className="Form_inline">
                                <label >
                                    Bill For:
                                </label>
                                <input
                                    type="text"
                                    className="TextBoxSize150"
                                    readOnly
                                    name="billFor"
                                    ref={register}
                                    value={billForMonth}
                                />

                                <label >
                                    Bill Date:
                                </label>
                                <input
                                    type="Date"
                                    readOnly
                                    className="DateBox"
                                    name="billDate"
                                    ref={register}
                                    value={billDate}
                                />

                                <label >
                                    Due Date:
                                </label>
                                <input
                                    type="Date"
                                    readOnly
                                    name="dueDate"
                                    className="DateBox"
                                    ref={register}
                                    value={dueDate}
                                />
                            </div>
                            <br></br>

                            <div className="Form_inline">
                                <label>
                                    Bill No:
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    className="TextBoxSize150"
                                    name="billNo"
                                    ref={register}
                                    value={BillNo}
                                />

                                <label>
                                    Society:
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    className="TextBoxSize150"
                                    name="society" style ={{width:"25%"}}
                                    ref={register}
                                    value={societyName}
                                />

                                <label className="Label">
                                    Area:
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    className="TextBoxSize150"
                                    name="area" style={{width:"5%"}}
                                    ref={register}
                                    value={area}
                                />
                            </div><br></br>

                        </div><br></br>
                        <div className="billTable" style={{ minHeight: "20%", width: "850px" }}>
                            <Toolbar className="mb-4" right={rightToolbarTemplate}> </Toolbar>
                            <DataTable
                                value={billContainer?.maintainanceInfos}
                                className="p-datatable-sm"
                                scrollable scrollHeight='200px'
                                showGridlines
                                editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete1}
                            >
                                <Column field="glTitle" header="Particulars" style={{ width: '170px' }}></Column>
                                <Column field="amount" header="Amount" editor={(props) => priceEditor('amount', props)} style={{ width: "80px" }}></Column>
                                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </div>

                        <div style={{ marginLeft: '450px' }}  >
                            <div className="Form_inline">
                                <label className="ValueLabel"> TOTAL: </label>
                                <input type="text" readOnly name="currentTotalFromGLHeads" style={{ height: '25px', width: '145px' }} ref={register} value={cMaintainance} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> CURR.INTEREST: </label>
                                <input type="text" name="currentInterest" style={{ height: '25px', width: '145px' }} onChange={currentInterest} ref={register} value={CurrInterest} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> DPC: </label>
                                <input type="text" name="dpc" style={{ height: '25px', width: '145px' }} onChange={currentDpc} ref={register} value={dpc} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> CURR.BILL TOTAL: </label>
                                <input type="text" readOnly name="CurrBillTotal" style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} ref={register} value={CurrBillTotal} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> O/S PRINCIPAL: </label>
                                <input type="text" name="oustandingsArrears" style={{ height: '25px', width: '145px' }} onChange={outStandingPrincipal} ref={register} value={oSPrincipal} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> O/S INTEREST: </label>
                                <input type="text" name="interestArrears" style={{ height: '25px', width: '145px' }} onChange={outStandingInterest} ref={register} value={oSInterest} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> O/S DPC: </label>
                                <input type="text" name="dpcArrears" style={{ height: '25px', width: '145px' }} onChange={outStandingDpc} ref={register} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> O/S TOTAL: </label>
                                <input type="text" readOnly name="totalArrears" style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} ref={register} value={oSTotal} />
                            </div>

                            <div className="Form_inline">
                                <label className="ValueLabel"> TOTAL AMOUNT PAYABLE: </label>
                                <input type="text" readOnly name="totalAmountPayable" ref={register} value={tPayable} style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} />
                            </div>

                        </div>
                        <div className="p-field" style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button label="SAVE" type="submit" style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} onClick={handleSubmit(onSave)} />
                        </div>

                        <Dialog visible={glDialog} style={{ width: '550px', height: '400px' }} header="Maintainance Gl Details" modal className="p-fluid" onHide={hideDialog}>
                            <div className="Form_inline" >
                                <Dropdown value={glValue} options={billedGLs} style={{ width: '300px', height: '35px' }} optionLabel="glTitle" optionValue="glId"
                                    onChange={(e) => onGlTitleValueChange(e.value)} placeholder="Select a glTitle" />
                                <InputNumber id="glAmount" value={glAmount} style={{ width: '200px', height: '35px' }} onChange={(e) => setAmountValue(e.value)} mode="currency" currency="INR" />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button label="Add" type="submit" style={{ width: "140px", height: '30px', margin: '10px' }} onClick={onAddDataTable} />
                            </div>

                            <div >
                                <DataTable
                                    value={billContainer?.maintainanceInfos}
                                    className="p-datatable-sm"
                                    scrollable scrollHeight='100px'
                                    showGridlines
                                    editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete1}
                                    style={{ width: '500px', padding: '10px' }}
                                >
                                    <Column field="glTitle" header="Particulars" style={{ width: "300px" }}></Column>
                                    <Column field="amount" header="Amount" ></Column>
                                </DataTable>
                            </div>
                        </Dialog>
                    </div>
                </div>
            )}
        </form>
    )
}
export default EditFlatBill;

