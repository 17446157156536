/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import './NomineeDetails.css';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import SocietyService from "../../Service/SocietyService";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Config from '../../Config.json' 
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import authHeader from '../../authHeader';
import { Checkbox } from 'primereact/checkbox';
import { BlockUI } from 'primereact/blockui';
import { Panel } from "primereact/panel";
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';









function NomineeDetils({ flatId, id, usedBy, setActiveTab }) {
    const validationSchema = Yup.object().shape({
        prefix: Yup.string()
            .required('Prefix is required'),
        nominee: Yup.string()
            .required('Nominee Name is required'),

    });
    // const isAddMode = !id;
    const toast = useRef(null);

    console.warn("Nominee Details", flatId)
    var [nomineeDetails, setNomineeDetails] = useState([]);
    const [saveDisabled, SetSaveDisabled] = useState(true);
    const [birthDate, setBirthDate] = useState([]);
    const [admitDate, setAdmitDate] = useState([]);
    const [meetDate, setMeetDate] = useState([]);

    const [nomineeId, setNomineeId] = useState([]);
    const [nextDisabled, setNextDisabled] = useState(true)
    const [addDisabled, setAddDisabled] = useState(false);
    const [nomineeArray, setNomineeArray] = useState([]);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [lastSelectedNomineeId, setLastSelectedNomineeId] = useState();
    const [dataLoading, setDataLoading] = useState(true);
    const [checked, setChecked] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);






    const societyService = new SocietyService();

    const { errors, register, reset, handleSubmit, setValue } = useForm(
        { resolver: yupResolver(validationSchema) }
    );

    useEffect(() => {

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }

        // get user and set form fields
        fetchNomineeData();



    }, []);

    const fetchNomineeData = () => {
        societyService.getNomineeDetailsOnID(id).then(retriveNomineeDataIn => {
            setNextDisabled(false);

            if (retriveNomineeDataIn.length > 0) {
                setChecked(true);
                const fields = ['prefix', 'nomineeId', 'nominee', 'gender', 'birthDate', 'age', 'relation', 'occupation', 'dateOfAdmition', 'mobile', 'address1', 'address2', 'city', 'pincode', 'meetingDate'];
                fields.forEach(field => setValue(field, retriveNomineeDataIn[0][field]));
                retriveNomineeDataIn[0].rowActive = true;
                setRetriveNomineeData(retriveNomineeDataIn[0]);
                setLastSelectedNomineeId(retriveNomineeDataIn[0].nomineeId);
                setNextDisabled(false);

                // rowClass(retriveNomineeDataIn[0]);

                retriveNomineeDataIn.forEach(a => {


                    if (a.birthDateFormat === '0001-01-01') {
                        setBirthDate("");
                        setValue('age', '');
                        a.birthDateFormat = '---';


                    }
                    else {
                        setValue('birthDate', a.birthDateFormat);
                    }

                    if (a.admitDateFormat === '0001-01-01') {
                        setAdmitDate("");
                        a.admitDateFormat = '---';

                    }
                    else {
                        setValue('dateOfAdmition', a.admitDateFormat);

                    }

                    if (a.meetingDateFormat === '0001-01-01') {
                        setMeetDate("");
                        a.meetingDateFormat = '---';
                    }
                    else {
                        setValue('meetingDate', a.meetingDateFormat);

                    }
                    if (a.pincode === 0) {
                        setValue('pincode', '');
                        a.pincode = '---';
                    }
                    if (a.mobile === '') {
                        a.mobile = '---';
                    }
                    if (a.address1 === '') {
                        a.address1 = '---';
                    }
                    if (a.address2 === '') {
                        a.address2 = '---';
                    }
                });
                console.warn(retriveNomineeDataIn);
                setNomineeDetails(retriveNomineeDataIn);
                setDataLoading(false);
                SetSaveDisabled(true);

                setAddDisabled(true);

            }
            else {
                setAddDisabled(false);
            }

        });
        SetSaveDisabled(true);



        console.warn(nomineeDetails);

    }


    const onAddNominee = (data, e) => {
        console.warn(data);
        data.birthDateFormat = data.birthDate;
        data.admitDateFormat = data.dateOfAdmition;
        data.meetingDateFormat = data.meetingDate;

        data.flatId = id;
        let existingValue = nomineeDetails.findIndex(x => x.nominee === data.nominee);
        if (existingValue < 0) {
            data.state = "New";
            setNomineeDetails(nomineeDetails.concat(data));
            onsave(data);
            // reset();
        }
        else {
            toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Nominee is already added' });

        }
        SetSaveDisabled(false);
        setNextDisabled(false);


    }

    const onEditNominee = (data, e) => {
        console.warn(data);
        data.birthDateFormat = data.birthDate;
        data.admitDateFormat = data.dateOfAdmition;
        data.meetingDateFormat = data.meetingDate;

        let existingIndex = nomineeDetails.findIndex(x => x.nominee === data.nominee);
        if (existingIndex >= 0) {
            if (data.state != "New") {

                data.state = "modified";
            }
            nomineeDetails[existingIndex] = data;
            nomineeDetails = [...nomineeDetails];
            setNomineeDetails(nomineeDetails);
            onsave(data);
        }
        else {
            toast.current.show({ severity: 'error', detail: "Nominee is already added" });
        }
        SetSaveDisabled(false);
        setNextDisabled(true);

    }


    const onsave = (data) => {
        // nomineeDetails.forEach(b => {
        setAddDisabled(true);
        data.flatId = id;
        if (data.state === "New") {
            createUser(data);
        }
        else if (data.state === "modified") {
            updateUser(data);
        }

        // });
    }

    const createUser = (data, e) => {
        data.nomineeId = "-1";
        if (data.birthDate === '') {
            data.birthDate = undefined;
        }
        if (data.age === '') {
            data.age = undefined;
        }
        if (data.dateOfAdmition === '') {
            data.dateOfAdmition = undefined;
        }
        if (data.pincode === '') {
            data.pincode = undefined;
        }
        if (data.meetingDate === '') {
            data.meetingDate = undefined;
        }
        setSpinnerVisible(true);
        axios.post(`${Config.apiUrl}/NomineeDetails/id=2`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                    data.state = "Unmodified"
                    id = id;
                    setNextDisabled(false);
                    setAddDisabled(false);
                    fetchNomineeData();

                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }

            })
            .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
              })
    }


    const updateUser = (data, e) => {
        if (data.birthDate === '') {
            data.birthDate = undefined;
        }
        if (data.age === '') {
            data.age = undefined;
        }
        if (data.dateOfAdmition === '') {
            data.dateOfAdmition = undefined;
        }
        if (data.pincode === '') {
            data.pincode = undefined;
        }
        if (data.meetingDate === '') {
            data.meetingDate = undefined;
        }
        setSpinnerVisible(true);
        axios.put(`${Config.apiUrl}/NomineeDetails/update?id=${data.nomineeId}`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                    data.state = "Unmodified";
                    id = id;
                    setNextDisabled(false);
                    setAddDisabled(false);
                    fetchNomineeData();


                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
              })
    }

    const [retriveNomineeData, setRetriveNomineeData] = useState({});

    const rowClass = (data) => {

        return {
            'selectedRow': data.rowActive

        }

    }

    const onDoubleClickEdit = (event) => {
        if (lastSelectedNomineeId !== event.data.nomineeId) {
            let selectedRow = nomineeDetails.find(a => a.nomineeId === lastSelectedNomineeId)
            selectedRow.rowActive = false;
        }

        var data = event.data;
        data.rowActive = true;
        rowClass(data);
        setLastSelectedNomineeId(event.data.nomineeId);

        const fields = ['nomineeId', 'prefix', 'nominee', 'gender', 'birthDate', 'age', 'relation', 'occupation', 'dateOfAdmition', 'mobile', 'address1', 'address2', 'city', 'pincode', 'meetingDate'];
        fields.forEach(field => setValue(field, data[field]));
        setValue('birthDate', data.birthDateFormat);
        setValue('dateOfAdmition', data.admitDateFormat);
        setValue('meetingDate', data.meetingDateFormat);

        // setBirthDate(data.birthDateFormat);
        // setAdmitDate(data.admitDateFormat);
        // setMeetDate(data.meetingDateFormat);
        setAddDisabled(true);
    }

    const getFormattedDate = (dateInput) => {
        const [year, month, day] = dateInput.split('-');
        let dateFinal = month + "/" + day + "/" + year;
        return dateFinal;

    }
    const onReset = (data) => {
        reset();
        const fields = ['prefix', 'nominee', 'gender', 'birthDate', 'age', 'relation', 'occupation', 'dateOfAdmition', 'mobile', 'address1', 'address2', 'city', 'pincode', 'meetingDate'];
        fields.forEach(field => setValue(field, ''));
        setAddDisabled(false);

    }

    const onChange = (e) => {
        // setBirthDate(e.currentTarget.value);
        setValue('birthDate', e.currentTarget.value);
        let ageValue = e.currentTarget.value
        setValue('age', calculate_age(ageValue));
        console.warn(calculate_age());
    }
    function calculate_age(ageValue) {
        var age = Math.floor((new Date() - new Date(ageValue).getTime()) / 3.15576e+10);
        return age;

    }

    const onAdmitDateChange = (e) => {
        // setAdmitDate(e.currentTarget.value);
        setValue('dateOfAdmition', e.currentTarget.value);
    }

    const onMeetDateChange = (e) => {
        // setMeetDate(e.currentTarget.value);
        setValue('meetingDate', e.currentTarget.value);
    }

    const onNext = (e) => {
        let insertIndex = nomineeDetails.findIndex(a => a.state === "New");
        if (insertIndex >= 0) {
            toast.current.show({ severity: 'error', detail: "There are unsaved records in grid. Please save it before moving it to new tab" });
        }
        if (usedBy === 'Tenant') {
            setActiveTab(4);
        }
        else {
            setActiveTab(5);

        }
    }



    return (
        <form className="NomineeForm"  autoComplete="off" >
            <div className="field-checkbox">
                <Checkbox inputId="binary" style={{margin:'5px'}} checked={checked} onChange={e => setChecked(e.checked)} />
                <label htmlFor="binary" style={{margin:'5px',width:'auto'}}>Enter Nominee Details</label>
            </div>

                
                    <Toast ref={toast} position="center" />
                    <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>

                    <div className='NomineeForm'>
                    <BlockUI blocked={!checked} template={<i className="pi pi-lock" style={{ 'fontSize': '3rem','opacity':'0.5' }} />}>

                    {/* <Panel > */}

                    <div className="Form-inline">
                        {/* <input type8="text" value={flatId} name="FlatId" ref={register} hidden /> */}
                        <input type8="text" value={nomineeDetails.nomineeId} name="nomineeId" ref={register} hidden />

                        <label className="label"> Nominee<span style={{ color: 'red' }}>*</span>:</label>
                        <select className="SelectSmallWidth" name="prefix" ref={register({ required: true })} autoFocus={focusValue}>
                            <option>Mr</option>
                            <option>Mrs</option>
                        </select>

                        <input type4="text" className="text" name="nominee" ref={register({ required: true })} autoComplete="off" />
                    </div>

                    <div className="Form-inline">
                        <label className="label"> Gender :</label>
                        <select className="SelectSmallWidth" name="gender" ref={register}>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Transgender</option>
                        </select>

                        <label className="BirthDateLabel">Birth Date :</label>
                        <input type="Date" className="Date" name="birthDate" ref={register} onChange={onChange} />

                        <label className="label"> Age :</label>
                        <input readOnly type5="text" name="age" ref={register} />

                        <label className="label"> Relation :</label>
                        <select className="Select2" name="relation" ref={register}>
                            <option>Brother</option>
                            <option>Daughter</option>
                            <option>Father</option>
                            <option>Mother</option>
                            <option>Self</option>
                            <option>Son</option>
                            <option>Spouse</option>
                            <option>Father in Law</option>
                            <option>Sister in Law</option>
                            <option>Mother in Law</option>
                            <option>Brother in Law</option>

                        </select>
                    </div>

                    <div className="Form-inline">
                        <label className="label"> Occupation:</label>
                        <select className="Select2" name="occupation" ref={register}>
                            <option>Self Employed</option>
                            <option>Bussiness man</option>
                            <option>Farmer</option>
                        </select>


                        <label className="labelAdmition">Date of Admition:</label>

                        <input type="Date" className="Date" name="dateOfAdmition" ref={register} onChange={onAdmitDateChange} />

                        <label className="label"> Mobile :</label>
                        <input type="number" name="mobile" className="number" ref={register} autoComplete="off" />


                    </div>

                    <div className="Form-inline">
                        <label className="label">Address:</label>
                        <input type7="text" name="address1" ref={register} autoComplete="off" />
                        <div className="SecondAddress">
                            <input type7="text" name="address2" ref={register} autoComplete="off" />
                        </div>
                    </div>

                    <div className="Form-inline">
                        <label className="label"> City :</label>
                        <input type="text" className="SelectCity" name="city" ref={register} autoComplete="off" />

                        {/* <select className="SelectCity" name="city" ref={register}>
                            <option>Mumbai</option>
                            <option>Pune</option>
                            <option>Satara</option>
                        </select> */}

                        <label className="label"> Pincode :</label>
                        <input type="number" name="pincode" className="number" ref={register} autoComplete="off" />

                        <label className="MeetingDateLabel">Meeting Date :</label>
                        <input type="Date" className="Date" name="meetingDate" ref={register} onChange={onMeetDateChange} />
                    </div>

                    <div className="invalid-feedback">
                        <div>{errors.prefix?.message}</div>
                        <div> {errors.nominee?.message}</div>
                    </div>

                    <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
                        <div className="p-field">

                            <Button disabled={addDisabled} label="Add Nominee" type="submit " onClick={handleSubmit(onAddNominee)} />
                        </div>
                        <div className="p-field">

                            <Button label="Edit Nominee" disabled={!addDisabled} type="submit " onClick={handleSubmit(onEditNominee)} />
                        </div>


                        <div className="p-field">

                            <Button label="Next" disabled={nextDisabled} onClick={onNext} />
                        </div>

                        <div className="p-field ">
                            <Button label="Reset" type="reset" onClick={onReset} disabled={DisableButton} />
                        </div>


                    </div>

                    <div >
                        <DataTable value={nomineeDetails} header="Personal Details" rowClassName={rowClass} className="p-datatable-sm" onRowDoubleClick={onDoubleClickEdit} >
                            {/* <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column> */}
                            <Column field="nominee" header="Nominee Name" style={{ width: "250px" }}></Column>
                            <Column field="gender" header="Gender"></Column>
                            <Column field="birthDateFormat" header="Birth Date" ></Column>
                            <Column field="age" header="Age"></Column>
                            <Column field="relation" header="Relation" ></Column>
                            <Column field="occupation" header="Occupation" ></Column>
                            <Column field="admitDateFormat" header="Date of Admition" ></Column>
                            <Column field="mobile" header="Mobile" ></Column>
                            <Column field="address1" header="Address 1" ></Column>
                            <Column field="address2" header="Address 2" ></Column>
                            <Column field="city" header="City" ></Column>
                            <Column field="pincode" header="Pincode" ></Column>
                            <Column field="meetingDateFormat" header="Meeting Date" ></Column>
                        </DataTable>
                    </div>
                    </BlockUI>


                {/* </Panel> */}
                </div>



        </form>
    );
}

export default NomineeDetils;
