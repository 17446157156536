/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
// import logo from './logo.svg';
import { Toast } from 'primereact/toast';
import './GroupMasterUi.css';
// import Register from '../../Register';
// import Nav from '../../Nav';
// import Login from '../../Login';
// import GroupMasters from '../../GroupMasters';
// import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SocietyService from '../../Service/SocietyService';
import { useForm } from 'react-hook-form';
import { resetIdCounter } from 'react-tabs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import Config from '../../Config.json';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";







function GroupMasterUi() {
  const validationSchema = Yup.object().shape({
    // title: Yup.string()
    //     .required('Title is required'),
    GroupName: Yup.string()
        .required('Group Name is required'),
});

  // const selectedValue = "";
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
   var societyId=parseInt(localStorage.getItem('societyId'));

  const [GmData, setGmData] = useState([]);
  const [id, setId] = useState(null);
  const toast = useRef(null);
  // const [selectedProduct3, setSelectedProduct3] = useState(null);
  const [selectedProducts3, setSelectedProducts3] = useState(null);
  const [Ddata, Deletedata] = useState(null);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const [DisableButton, setDisableButton] = useState(true);
  const [stickyMode, setStickyMode] = useState(true);
  localStorage.setItem('uiName', 'Group Master');
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [lowercaseWarning, setLowercaseWarning] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    const enteredValue = event.target.value;
    const uppercaseValue = enteredValue.toUpperCase();

    setInputValue(uppercaseValue); // Update the displayed value
    setLowercaseWarning(enteredValue !== uppercaseValue); // Check if conversion was necessary
  };


  // const [societyId,setSocietyId]=useState();
  // const [accessLevel,setAccessLevel]=useState(0);


  // const [onRowclickData,setOnRowClickData]=useState([]);


  const societyService = new SocietyService();


  useEffect(() => {

  //  let accLevel = parseInt(localStorage.getItem('accessLevel'));
  //  let socId=parseInt(localStorage.getItem('societyId'));
  //  console.warn("AccessLevel "+accLevel+", SocietyId:"+socId);
  // setSocietyId(socId);setAccessLevel(accLevel);
    //getting group master data
    societyService.getGroupMasterData(societyId).then
    (
      data => {
      setGmData(data)
      },
      e=>{
        console.log(e);
      }
      );
    console.warn(GmData);

    //gettig group master id
    societyService.getGroupId(societyId).then(data => setId(data));
    console.warn(id);

    if(accessLevel >= 2)
    {
      setDisableButton(false);
      setBlockedPanel(false);
    }
    else{
      setDisableButton(true);
      setBlockedPanel(true);
    }
  }, []);


  const { errors, register, reset, handleSubmit } = useForm(
    {resolver: yupResolver(validationSchema)}
  );


  const onSubmit = (data, e) => {
    // console.log(data);
    data.societyId=societyId;
    console.warn(data);
    setSpinnerVisible(true);
   
    axios.post(`${Config.apiUrl}/GroupMaster`,data,{headers:authHeader()})
      .then(response => {
        console.log(response)
        if (response.data[0].success) {
         
          toast.current.show({ severity: 'success',sticky:true,detail: response.data[0].msg });
          var addData = { "groupId": data.GroupId, "groupName": data.GroupName }
          GmData.push(addData);
          // setId(id + 1);
          
          

        }
        else {
          toast.current.show({ severity: 'error',sticky:true, detail: response.data[0].msg });
         
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error',sticky:true, detail: "ERROR IN SAVING DATA" });
      }).finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);

      })
    
    reset();

  }

  const onCancel = (data) => {
    // setId(selectedValue)
    reset();
    window.location.reload();
    

  }

  const dataTableEdit = {
    'products': setGmData
  };



  const onEditorValueChange = (productKey, props, value) => {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    dataTableEdit[`${productKey}`](updatedProducts);

  }


  const inputTextEditor = (productKey, props, field) => {

    return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} width="10px" />;
  }

  const groupNameEditor = (productKey, props) => {
    return inputTextEditor(productKey, props, 'Group Name');
  }
  let originalRows = {};
  let editingCellRows = {};

  const onRowEditInit = (event) => {
    originalRows[event.index] = { ...GmData[event.index] };
    // societyService.putGroupMaster(originalRows);
  }

  const onRowEditSave = (event) => {
    setSpinnerVisible(true);
    originalRows[event.index] = { ...GmData[event.index] };
    let data = event.data;
    let id = event.data.groupId;
    data.societyId=societyId;
    return axios.put(`${Config.apiUrl}/GroupMaster/update?id=${id}&societyId=${societyId}`, data,{headers:authHeader()})
      .then(response => {
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data[0].msg });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(()=>{
        setSpinnerVisible(false);
      });
  }



  const onRowEditCancel = (event) => {
    let product = [...GmData];
    GmData[event.index] = originalRows[event.index];
    delete originalRows[event.index];

    setGmData(product);
  }


  const onDelete = (event) => {
    if(selectedRadioData != null)
    {
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
            </div>
          </div>
        </div>
      )
    });
   }
   else{
    toast.current.show({ severity: 'warn',sticky:true, detail: "PLEASE SELECT ROW"});
    return;
   }
  }

  const onYes = () => {
    toast.current.clear();
    selectedRadioData.forEach(d => {
      let id = d.groupId;
      let data = GmData.filter(b => b.groupId != d.groupId);
      setGmData(data);
      setSpinnerVisible(true);
      axios.delete(`${Config.apiUrl}/GroupMaster/delete?id=${id}&societyId=${societyId}`,{headers:authHeader()})
        .then(response => {
          console.log(response)
          if (response.data != null) {
            toast.current.show({ severity: 'success',sticky:true, detail: response.data[0].msg });
            setId(id - 1);

          }
          else {
            toast.current.show({ severity: 'error',sticky:true, detail: response.data[0].msg });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=>{
          setSpinnerVisible(false);
        })

    })
      // window.location.reload();
  }

  const onNo = () => {
    toast.current.clear();
    return;
  };
  const onRemove=()=>{
    // setStickyMode(false);
    window.location.reload();
  }

  const header = (
    <div className="table-header">
      GROUP MASTER
      <Button icon="pi pi-trash" value="Delete" tooltip="Delete" className="btn" onClick={onDelete} disabled={DisableButton} />
    </div>
  );



  return (
    <div className="GroupMasterUi">
      <Card className="BackgroundColor" >
      <Toast ref={toast} position="center" onRemove={onRemove}/>
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
    <div class="container">

      <div class="container__half">
        <div className="GroupMaster" >
          <div>
            <label style={{fontFamily:'verdana'}} >Group Id<span style={{ color: 'red' }}>*</span>:</label>
            <input type="text" name="GroupId" value={id || ""} ref={register({ required: true })} readOnly />
          </div>
          <div>
      <label>
        Group Name<span style={{ color: 'red' }}>*</span>:
      </label>
      <input
        type="text"
        name="GroupName"
        ref={register({
          required: 'Group Name is required',
          validate: (value) => value === value.toUpperCase() ,
        })}
        value={inputValue} // Use the state value for the displayed value
        onChange={handleInputChange}
        autoFocus={focusValue}
        autoComplete="off"
      />
    
    </div>
  
            </div>
          


        <div className="button" style={{marginBottom:'20px'}}>
          <Button label="SAVE" type="submit " onClick={handleSubmit(onSubmit)} disabled={DisableButton}/>
          <Button label="RESET" type="submit " onClick={onCancel} disabled={DisableButton}/>
        </div>
      </div>


      <div class="container__half">
        <div className="DataTable">
        <BlockUI blocked={blockedPanel}>
          <DataTable value={GmData} style={{width:'1000px'}} scrollHeight='500px' scrollable className="p-datatable-gridlines" header={header} selection={selectedRadioData} paginator rows={5} editMode="row" onRowEditCancel={onRowEditCancel} onRowEditSave={onRowEditSave} onSelectionChange={e => setSelectedRadioData(e.value)}    >
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            <Column field="groupId" header="Group Id" style={{ width: "100px" }} filter ></Column>
            <Column field="groupName" header="Group Name" style={{ width: "400px" }} filter editor={(props) => groupNameEditor('products', props)}></Column>
            <Column rowEditor style={{ width: "100px"}} header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
          </DataTable>
          </BlockUI>
        </div>
      </div>
    </div>
    </Card>
    </div>


  )
}
export default GroupMasterUi;
