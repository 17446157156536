
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';


var societyData = [];
var societyName = localStorage.getItem('societyName');
var societyId = parseInt(localStorage.getItem('societyId'));
var selectedDate;
var DuesAdvance = [];
var view;
var totalAmountSum;
var principleSum;
var interestSum;
var dpcSum;
var advanceSum;

// class Example extends React.Component {
function DuesAdvanceBill({ props }) {

  const toast = useRef(null);
  const societyService = new SocietyService();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  selectedDate = date;
  // const [DateWithTime, setDateWithTime] = useState();
  const [regularDues, setRegularDues] = useState([]);
  DuesAdvance = regularDues;

  const [wings, setWings] = useState([]);
  const [Wing, setWing] = useState();
  const [type, setType] = useState();
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [displayView, setDisplayView] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  view = displayView;
  const tableName = sessionStorage.getItem('tblName');


  // selectedWing = Wing;

  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

    societyService
      .getWing(societyId)
      .then((data) => {
        data.splice(0, 0, { wing: 'All' })
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),

        )
      }, []);
      
  }, []);

  const onDateChange = (e) => {
    let Date = e.currentTarget.value
    setDate(Date);
    // Date = moment(Date).format('YYYY-MM-DDThh:mm:ss');
    // setDateWithTime(Date);

  }
  const onChangeType = (e) => {
    let type = e.currentTarget.value;
    setType(type);
  }

  const onChangeWing = (e) => {
    let wing = e.currentTarget.value;
    setWing(wing);
  }
  const viewBills = (e) => {
    setSpinnerVisible(true);
    let undefinedList = [];
    if (date === undefined) {
      undefinedList.push({ label: "select date" });
    }

    if (type === undefined) {
      undefinedList.push({ label: "select dues type" });

    }
    if (Wing === undefined) {
      undefinedList.push({ label: "select wing" });
    }

    let message = "Please";
    undefinedList.forEach(a => {

      message = message + "\n " + a.label + ",";
    })
    if (undefinedList.length > 0) {

      toast.current.show({ severity: 'warn', sticky: true, detail: message });
    }
    else {

      setIsPrintDisabled(false);
      societyService.getRegularDues(societyId, date, Wing,tableName).then(data => {
        if (data.length === 0) {

          toast.current.show({ severity: 'info', sticky: true, detail: "No Data..!" });
          return;
        }
        setDisplayView(true);

        if (type === 'Dues_Advance') {
          data = data.filter(d => d.totalAmount < 0);
          // setRegularDues(data);
        }
        if (type === 'Dues_Regular') {
          data = data.filter(d => d.totalAmount >= 0);
          // setRegularDues(data);
        }
        // totalAmountSum = data && data.reduce((a, v) => a + Math.abs(v.totalAmount), 0).toFixed(2);
        totalAmountSum = data.filter(item => item.totalAmount > 0).reduce((a, v) => a + v.totalAmount, 0).toFixed(2);
        principleSum = data.filter(item => item.principle > 0).reduce((a, v) => a + v.principle, 0).toFixed(2);
        interestSum = data.filter(item => item.interest > 0).reduce((a, v) => a + v.interest, 0).toFixed(2);
        dpcSum = data.filter(item => item.dpc > 0).reduce((a, v) => a + v.dpc, 0).toFixed(2);

        // var Advance = 0;
        data.forEach(d => {
          d.principle = d.principle.toFixed(2);
          d.interest = d.interest.toFixed(2);
          d.dpc = d.dpc.toFixed(2);
          d.totalAmount = d.totalAmount.toFixed(2);

          if (d.totalAmount < 0) {
            let advanceAmount=Math.abs(d.totalAmount).toFixed(2);
            d.advance = parseFloat(advanceAmount);
            d.principle = '---';
            d.interest = '---';
            d.dpc = '---';
            d.totalAmount = '---';
            // advanceSum = Advance + parseFloat(d.advance);
            // Advance = advanceSum
          }
          else {
            d.advance = '---';
          }

          setSerialNo(data);

        },

        )
        advanceSum = data.filter(item => item.advance > 0).reduce((a, v) => a + v.advance, 0).toFixed(2);
        setRegularDues(data)

      }).catch(error=>{
        toast.current.show({ severity: 'warn', sticky: true, detail: "Error in generating report"});
      }).finally(()=>{
        setSpinnerVisible(false);
      });
    }

  }

  const setSerialNo = (data) => {
    let srNo = 1;
    data.forEach(d => {
      d.serialNo = srNo;
      srNo++;
    });
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div class="card" style={{ backgroundColor: 'black', height: '70px' }}>
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />


      <div className='p-formgroup-inline'>
        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <input type="Date" style={{ height: '35px' }} name="Date" value={date} onChange={onDateChange} />
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>

          <select style={{ width: '150px', height: '35px', marginLeft: '10px' }} onChange={onChangeType} >
            <option hidden value="">Select dues type</option>
            <option>All</option>
            <option>Dues_Advance</option>
            <option>Dues_Regular</option>

          </select>
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <select style={{ width: '150px', height: '35px', marginLeft: '10px' }} name="wing" onChange={onChangeWing} >
            <option hidden value="">Select Wing</option>
            {wings.map(({ label, value: wings }) => (
              <option key={wings} value={wings}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div style={{ margin: '10px' }}>
          <Button label="VIEW" onClick={viewBills} style={{ marginLeft: '10px' }} />
        </div>

        <div style={{ margin: '10px' }}>
          <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT" style={{ marginLeft: '10px' }} />
        </div>
      </div>
      <Report ref={componentRef} />

    </div>
  );

}


class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstPage: true,
      firstPageTotal: this.calculateOverallTotal(),
    };
  }

  calculateOverallTotal = () => {
    // Calculate your overall total here
    // For example, if your data is stored in DuesAdvance array
    // const overallTotal = DuesAdvance.reduce((acc, item) => acc + item.totalAmount, 0);
    // return overallTotal;
    const overallTotal = DuesAdvance.reduce((acc, item) => {
      const totalAmount = parseFloat(item.totalAmount);
      if (!isNaN(totalAmount)) {
        return acc + totalAmount;
      }
      return acc;
    }, 0);
  };

  render() {
    const portrait = () => `
      footer {
        font-size: 9px;
        color: #f00;
        text-align: center;
      }

      @page {
        size: portrait;
        margin: 7mm 15mm 5mm 15mm;
      }

      @media print {
        footer {
          position: fixed;
          bottom: 0;
        }

        .content-block, p {
          page-break-inside: avoid;
        }

        html, body {
          width: 210mm;
          height: 295mm;
        }

        .DuesAdvanceTable {
          width: 100%;
          border-collapse: collapse;
        }

        .DuesAdvanceTable th, .DuesAdvanceTable td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .headerColor {
          background-color: #f2f2f2;
        }

        .DuesAdvanceTable tfoot {
          font-weight: bold;
        }

        .totalRow {
          display: none;
        }

        .firstPage .overallTotal {
          /* Styles for the overall total on the first page */
          display: table-row;
        }

        .otherPages .totalRow {
          /* Styles for subsequent pages */
          display: table-row;
        }
      }
    `;

    return (
      <div className={this.state.isFirstPage ? 'firstPage' : 'otherPages'} style={{ height: '100%', display: !view ? 'none' : 'block' }}>
               <style>{portrait()}</style>
        <div className="SocietyName">
          <div class="font"><text>{societyData[0]?.societyName}</text></div>
          <div>
            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
          </div>
          <div>
            <text>
              {societyData[0]?.address}
            </text>
          </div>
          <div class="font">
            <label>Advance Dues From Members</label>
          </div>
          <div className="dateFont">
            <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label>
          </div>
        </div>
        <div>
          <div className="DuesAdvanceTable">
            <table>
              <thead>
                <tr className='headerColor'>
                  <th style={{ width: '5%', textAlign: 'center' }}>Sr</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Flat No</th>
                  <th style={{ width: '30%', textAlign: 'center' }}>Member Name</th>
                  <th style={{ width: '36%', textAlign: 'center' }} colspan="3">Dues</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Total Amount</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Advance</th>
                </tr>
                <tr className='headerColor'>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Principal</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Interest</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>DPC</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tfoot>
                {this.state.isFirstPage && (
                  <tr className='overallTotal'>
                    <th>Overall Total</th>
                    <td colSpan="6"></td>
                    <th style={{ textAlign: 'right', padding: '5px' }}>{this.state.firstPageTotal}</th>
                    <td></td>
                  </tr>
                )}
                <tr className='totalRow'>
                  <th>Total</th>
                  <td></td>
                  <td></td>
                  <th style={{ textAlign: 'right', padding: '5px' }}>{principleSum}</th>
                  <th style={{ textAlign: 'right', padding: '5px' }}>{interestSum}</th>
                  <th style={{ textAlign: 'right', padding: '5px' }}>{dpcSum}</th>
                  <th style={{ textAlign: 'right', padding: '5px' }}>{totalAmountSum}</th>
                  <th style={{ textAlign: 'right', padding: '5px' }}>{advanceSum}</th>
                </tr>
              </tfoot>
              <tbody>
                {DuesAdvance.map((item) => (
                  <tr key={item}>
                    <td style={{ textAlign: 'left', padding: '5px' }}>{item.serialNo}</td>
                    <td style={{ textAlign: 'left', padding: '5px' }}>{item.wing}-{item.flatNo}</td>
                    <td style={{ textAlign: 'left', padding: '5px' }}>{item.name}</td>
                    <td style={{ textAlign: 'right', padding: '5px' }}>{item.principle}</td>
                    <td style={{ textAlign: 'right', padding: '5px' }}>{item.interest}</td>
                    <td style={{ textAlign: 'right', padding: '5px' }}>{item.dpc}</td>
                    <td style={{ textAlign: 'right', padding: '5px' }}>{item.totalAmount}</td>
                    <td style={{ textAlign: 'right', padding: '5px' }}>{item.advance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default DuesAdvanceBill;
