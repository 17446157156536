/* eslint-disable no-unused-vars */
import logo from './logo.svg';
import ReactDOM from 'react-dom';
import './App.css';
import Nav from './Nav';
// import Login from './Login';
import Login from './components/Login/Login';
import GroupMasters from './GroupMasters';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Flat_Master from './components/Masters/Flat_Master';
import GL_Master from './components/Masters/GL_Master';

import WingMaster from './components/SocietyMaster/WingMaster';
import SocietyMasters from './components/SocietyMaster/SocietyMasters';
import GridView from './components/FlatView/FlatView';
import MemberReceipt from './components/Transactions/memberReceipt';
import GeneralReceipt from './components/Transactions/generalReceipt';
import SocietyView from './components/SocietyMaster/SocietyView';
import MRReturnEntry from './components/Transactions/MRReturnEntry';
import GRReturnEntry from './components/Transactions/GRReturnEntry';
import VoucherEntry from './components/Transactions/voucherEntry';
import BillGeneration from './components/Transactions/BillGeneration';
import CreditDebitNote from './components/Transactions/CreditDebitNote';
import TransactionView from './components/Transactions/TransactionView';
import RegisterUser from './components/Login/RegisterUser';
import UsersView from './UsersView';
import React, { createContext, useState } from "react";
import { withRouter } from "react-router-dom";
// import { SocietyContext, SocietyContextProvider } from './SocietyContext';
import { PrintTemplate } from 'react-print';
import Report from './components/Report/Report';
import authtication from "./actions/authtication";
import Flat from "./components/Transactions/Flat";
import Permission from './components/Misc/Permission';
import Profile from './components/Login/Profile';
import LoginPopup from './components/Login/LoginPopup';
import GroupMasterUi from './components/Masters/GroupMasterUi';
import SubGroupMasterUi from './components/Masters/SubGroupMasterUi';
import ProfilePopup from './components/Login/ProfilePopup';
import GL_MasterAuditView from './AuditViews/GL_MasterAuditView';
import GroupMasterAuditView from './AuditViews/GroupMasterAuditView';
import Sub_GroupMasterAuditView from './AuditViews/Sub_GroupMasterAuditView';
import SocietyMasterAuditView from './AuditViews/SocietyMasterAuditView';
import PersonalAuditView from './AuditViews/PersonalAuditView';
import OpeningBalanceAuditView from './AuditViews/OpeningBalanceAuditView';
import PropertyDetailsAuditView from './AuditViews/PropertyDetailsAuditView';
import NomineeAuditView from './AuditViews/NomineeAuditView';
import ForgotPassword from './actions/ForgotPassword';
import HomePage from './HomePage';
import CommitteeMember from './components/Misc/CommitteeMember';
import DuesRegularBill from './components/Report/DuesRegularBill';
import DuesAdvanceBill from './components/Report/DuesAdvanceBill';
import DuesReminder from './components/Report/DuesReminder';
import SupplementaryDues from './components/Report/SupplementaryDues';
import MemberReceiptRegister from './components/Report/MemberReceiptRegister';
import SummaryDues from './components/Report/SummaryDues';
import BillRegister from './components/Report/BillRegister';
import MemberReceiptReport from './components/Report/MemberReceiptReport';
import MemberBills from './components/Report/MemberBills';
import BankReport from './components/Report/BankReport';
import CashReport from './components/Report/CashReport';
import PaymentRegister from './components/Report/PaymentRegister';
import TrialBalance from './components/Report/TrialBalance';
import GLReport from './components/Report/GLReport';

import NominationRegister from './components/Report/Register/NominationRegister';
import JRegister from './components/Report/Register/JRegister';
import IRegister from './components/Report/Register/IRegister';
import ShareRegister from './components/Report/Register/ShareRegister';
import EmailSettings from './components/Settings/EmailSettings';
import DisplayIndexSetting from './components/Settings/DisplayIndexSetting';
import EditFlatBill from './components/Transactions/EditFlatBill'
import MemberOpeningBalance from './components/Transactions/MemberOpeningBalanceUi';
import CommunicationSetting from './components/Misc/CommunicationSetting';
import SocietyState from './SocietyState';
import BankFD from './components/Transactions/BankFD';
import TransactionAuditView from './AuditViews/TransactionAuditView';
import SupplementaryBillGeneration from './components/Transactions/SupplimentaryBillGeneration';
import SupplimentaryMemberReceipt from './components/Transactions/SupplimentaryMemberReceipt';
import SupplimentaryMemberOpeningBalance from './components/Transactions/SupplimentaryMemberOpeningBalanceUi'
import SupplimentaryTransactionView from './components/Transactions/SupplimentaryTransactionView';
import TransactionSchemaReview from 'components/Misc/TransactionSchemaReview';
import CreateTransactionSchemaForFinYear from 'components/Misc/CreateTransactionSchemaForFinYear';
import SupplementaryBillGenerationPlan from 'components/Transactions/SupplimentaryBillGenerationPlan';
import BillGenerationForIndividualFlat from 'components/Transactions/BillGenerationForIndividualFlat';
import UpdateMaintainanceUi from 'components/Masters/UpdateMaintainanceUi';
import UpdateCommunicationDetail from 'components/Masters/UpdateCommunicationDetail';

import PersonalDetails from 'components/Masters/PersonalDetails';
import { SocietyDetails } from 'actions';
import SocietyContext from 'SocietyContext';
import BillingDetails from 'components/SocietyMaster/BiilingDetails';
import FlatView from './components/FlatView/FlatView';
import BankDepositeSlip from './components/BankDepositeSlip';

import ViewLogs from 'components/Misc/ViewLogs';
import MaintenanceLedger from 'components/Masters/MaintenanceLedger';
import TenantDetails from 'components/Masters/TenantDetails';
import UpdateTenantDetails from 'components/Masters/UpdateTenantDetails';
import PropertyDetails from 'components/Masters/PropertyDetails';
import TenantView from 'components/TenantView';
import TenantInfo from 'components/TenantInfo';
import GeneratedBillsView from 'components/Transactions/GeneratedBillsView';
import UserRole from 'components/UserRoles';
import UserPrivileges from 'components/UserPrivileges';
import AddRole from 'components/AddRole';
import AddPrivileges from 'components/AddPrivileges';
import SelectedMemberReceipt from 'components/Report/SelectedMemberReceipt';
import SelectedMemberBill from 'components/Transactions/SelectedMemberBill';
import Addnew_Gl from './components/Masters/Addnew_Gl';
import MemberLedger from 'components/MemberLedger';
function App({ props }) {
  // const { path } = match;

  // "apiUrl": "https://localhost:44345/api"
  //"apiUrl": "https://www.mysocietyworld.com/api"

  return (
    <SocietyState>
    <Router>
      <div className="App">

        <Switch>
          <Route path='/' exact component={Login} />

         
          <Route path='/login' component={Login} />
          <Route path='/signUp' component={RegisterUser} />
          <Route path='/ProfilePopup' component={ProfilePopup} />
          <Route path='/actions/ForgotPassword' exact component={ForgotPassword}/>


          <div>
            <Nav />
            <Route path='/HomePage' exact component={HomePage}/>
            <Route path='/GroupMasterUi' exact component={GroupMasterUi} />
            <Route path='/SubGroupMasterUi' exact component={SubGroupMasterUi} />
            <Route path='/GlMaster' exact component={GL_Master} />

            {/* <Route path='/GL_Master/edit/:id?' exact component={GL_Master}/> */}
            <Route path='/Flat_Master' exact component={Flat_Master} />
            <Route path='/MaintenanceLedger' exact component={MaintenanceLedger} />
            
            <Route path='/EditFlatBill/:billNo' exact component={EditFlatBill} />
            <Route path='/SocietyMasters' exact component={SocietyMasters} />
            <Route path='/SocietyView' exact component={SocietyView} />
            <Route path='/GridView' exact component={GridView} />
            <Route path='/Flat_Master/edit/:id/:societyId' exact component={Flat_Master} />
            <Route path='/Transactions/MemberReceipt' exact component={MemberReceipt} />
            <Route path='/Transactions/GeneralReceipt' exact component={GeneralReceipt} />
            <Route path='/SocietyMaster/edit/:id?' exact component={SocietyMasters} />
            <Route path='/Transactions/MemberReceiptReturn' exact component={MRReturnEntry} />
            <Route path='/Transactions/GeneralReceiptReturn' exact component={GRReturnEntry} />
            <Route path='/Transactions/SupplementaryBillGeneration' exact component={SupplementaryBillGeneration} />
            <Route path='/Transactions/SupplementaryBillGenerationPlan' exact component={SupplementaryBillGenerationPlan} />
            <Route path='/Transactions/SupplimentaryMemberReceipt' exact component={SupplimentaryMemberReceipt} />
            <Route path='/Transactions/SupplimentaryMemberOpeningBalance' exact component={SupplimentaryMemberOpeningBalance} />
            <Route path='/Transactions/VoucherEntry' exact component={VoucherEntry} />
            <Route path='/Transactions/BillGeneration' exact component={BillGeneration} />
            <Route path='/Transactions/GeneratedBillsView' exact component={GeneratedBillsView} />
            <Route path='/Transactions/BillGenerationForIndividualFlat' exact component={BillGenerationForIndividualFlat} />
              <Route path='/Transactions/CreditDebitNote' exact component={CreditDebitNote} />
            <Route path='/Transactions/MemberOpeningBalance' exact component={MemberOpeningBalance} />
            <Route path='/TransactionView' exact component={TransactionView} />
            <Route path='/SupplimentaryTransactionView' exact component={SupplimentaryTransactionView} />

            <Route path='/GeneralReceipt/edit/:id?' exact component={GeneralReceipt} />
            <Route path='/MemberReceipt/edit/:editRctNo?' exact component={MemberReceipt} />
            <Route path='/MRReturnEntry/edit/:id?' exact component={MRReturnEntry} />
            <Route path='/GRReturnEntry/edit/:id?' exact component={GRReturnEntry} />
            <Route path='/VoucherEntry/edit/:id?' exact component={VoucherEntry} />
            <Route path='/CreditDebitNote/edit/:id/:jeNo' exact component={CreditDebitNote} />
            <Route path='/Flat_MasterSocietyLink/:societyId?' exact component={Flat_Master} />
            <Route path='/WingMaster' exact component={WingMaster} />
            <Route path='/Report' exact component={Report} />
            {/* <Route path='/Report/BillReport' exact component={MemberReceiptReport} /> */}
            <Route path='/Transactions/Flat' exact component={Flat} />
            <Route path='/Permission' exact component={Permission} />
            <Route path='/UsersView' exact component={UsersView} />
            <Route path='/Profile' exact component={Profile} />
            <Route path='/AuditViews/GL_MasterAuditView' exact component={GL_MasterAuditView}/>
            <Route path='/AuditViews/GroupMasterAuditView' exact component={GroupMasterAuditView}/>
            <Route path='/AuditViews/Sub_GroupMasterAuditView' exact component={Sub_GroupMasterAuditView}/>
            <Route path='/AuditViews/SocietyMasterAuditView' exact component={SocietyMasterAuditView}/>
            <Route path='/AuditViews/TransactionAuditView' exact component={TransactionAuditView}/>
            <Route path='/AuditViews/PersonalAuditView' exact component={PersonalAuditView}/>
            <Route path='/AuditViews/OpeningBalanceAuditView' exact component={OpeningBalanceAuditView}/>
            <Route path='/AuditViews/PropertyDetailsAuditView' exact component={PropertyDetailsAuditView}/>
            <Route path='/AuditViews/NomineeAuditView' exact component={NomineeAuditView}/>
            <Route path='/CommitteeMember' exact component={CommitteeMember}/>
            <Route path='/EmailSettings' exact component={EmailSettings}/>
            <Route path='/CommunicationSetting' exact component={CommunicationSetting}/>
            <Route path='/TransactionFinYearSchemaReview' exact component={CreateTransactionSchemaForFinYear}/>
            <Route path='/BankFD' exact component={BankFD}/>
            <Route path='/DisplayIndexSetting' exact component={DisplayIndexSetting}/>
            <Route path='/Reports/MemberBills' exact component={MemberBills}/>
            <Route path='/Reports/DuesRegularBills' exact component={DuesRegularBill}/>
            <Route path='/Reports/DuesAdvanceBills' exact component={DuesAdvanceBill}/>
            <Route path='/Reports/SupplementaryDues' exact component={SupplementaryDues}/>
            <Route path='/Reports/DuesReminder' exact component={DuesReminder}/>
            <Route path='/Reports/SummaryDues' exact component={SummaryDues}/>
            <Route path='/Reports/MemberReceiptRegister' exact component={MemberReceiptRegister}/>
            <Route path='/Reports/BillRegister' exact component={BillRegister}/>
            <Route path='/Reports/MemberReceiptReport' exact component={MemberReceiptReport}/>
            <Route path='/Reports/BankReport' exact component={BankReport}/>
            <Route path='/Reports/CashReport' exact component={CashReport}/>
            <Route path='/Reports/PaymentRegisterReport' exact component={PaymentRegister}/>
            <Route path='/Reports/TrailBalance' exact component={TrialBalance}/>
            <Route path='/Reports/GlReport' exact component={GLReport}/>
            <Route path='/PrintMemberReceipt' exact component={SelectedMemberReceipt} />
            <Route path='/SelectedMemberBill' exact component={SelectedMemberBill} />


                      {/* <Route path='/' exact component={DuesAdvanceBill}/> */}
            <Route path='/Reports/NominationRegister' exact component={NominationRegister}/>
            <Route path='/Reports/JRegister' exact component={JRegister}/>
            <Route path='/Reports/IRegister' exact component={IRegister}/>
            <Route path='/Reports/ShareRegister' exact component={ShareRegister}/>
            <Route path='/ViewLogs' exact component={ViewLogs}/>
            <Route path='/UserRole' exact component={UserRole}/>
            <Route path='/UserPrivileges' exact component={UserPrivileges}/>
            <Route path='/AddRole' exact component={AddRole}/>
            <Route path='/Addprivileges' exact component={AddPrivileges}/>
            <Route path='/Addnew_Gl' exact component={Addnew_Gl}/>
            <Route path='/MemberLedger' exact component={MemberLedger}/>




          </div>

        </Switch>
      </div>
    </Router>
    </SocietyState>

  );
}
export default App;




