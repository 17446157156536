/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Divider } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import styles from "./PropertyDetails.css";
import { useForm } from "react-hook-form";
import SocietyService from "../../Service/SocietyService";
import axios from "axios";
import Config from '../../Config.json';
import { Button } from "primereact/button"; //import { UserContext } from './UserContext';
import { Toast } from "primereact/toast";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";


function  PropertyDetails({ id, setUsedBy, setActiveTab }) {
  const validationSchema = Yup.object().shape({
    // title: Yup.string()
    //     .required('Title is required'),
    type: Yup.string()
      .required(' Flat/shop Type is required'),
    usedBy: Yup.string()
      .required('Used By is required'),
  })

  // const flatId = useContext(UserContext);
  // const isAddMode = !id;
  const toast = useRef(null);

  // const [usedBy,setUsedBy]= useState([]);
  const [memberAmt, setMemberAmt] = useState([]);
  const [entranceFee, setEntranceFee] = useState([]);
  const [saveDisabled, SetSaveDisabled] = useState(true);
  const [maxShares, setMaxShares] = useState([]);
  const [ToShares, setToShares] = useState();
  const [isAddMode, setIsAddMode] = useState(true);
  const [otherAmount, setOtherTotal] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(true);
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  var societyId = parseInt(localStorage.getItem('societyId'));
  const [DisableButton, setDisableButton] = useState(true);
  const [ResetDisable, setResetDisable] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [compositionType ,setcompositiontype] = useState();
  const [floorType ,setfloorType] = useState();


  // console.warn("property Details", flatId);
  const societyService = new SocietyService();

  const { errors, register, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data, e) => {
    SetSaveDisabled(true);
    if (isAddMode) {
      createUser(data);
    } else {
      updateUser(data);
    }
  };

  const createUser = (data, e) => {
    data.flatId = id;
    if (data.areaInSqft === '') {
      data.areaInSqft = undefined;
    }
    if (data.areaInSqMtr === '') {
      data.areaInSqMtr = undefined;
    }
    if (data.agreementValue === '') {
      data.agreementValue = undefined;
    }
    if (data.twoWheeler === '') {
      data.twoWheeler = undefined;
    }
    if (data.threeWheeler === '') {
      data.threeWheeler = undefined;
    }
    if (data.fourWheeler === '') {
      data.fourWheeler = undefined;
    }
    if (data.memberAmt === '') {
      data.memberAmt = undefined;
    }
    if (data.entranceFee === '') {
      data.entranceFee = undefined;
    }
    if (data.otherAmt === '') {
      data.otherAmt = undefined;
    }
    if (data.total === '') {
      data.total = undefined;
    }
    if(data.compositionType===''){
      data.compositionType = undefined;
    }
    if(data.floorType===''){
      data.floorType = undefined;
    }
    setSpinnerVisible(true);
    axios
      .post(`${Config.apiUrl}/PropertyDetails/id=2`, data, { headers: authHeader() })
      .then((response) => {
        console.warn(data);
        console.log(response);
        if (response.data[0].success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            sticky: true,
            detail: response.data[0].msg,
          });
          setNextDisabled(false);
          SetSaveDisabled(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data[0].msg,
          });
        }
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  };

  const updateUser = (data, e) => {
    if (data.areaInSqft === '') {
      data.areaInSqft = undefined;
    }
    if (data.areaInSqMtr === '') {
      data.areaInSqMtr = undefined;
    }
    if (data.agreementValue === '') {
      data.agreementValue = undefined;
    }
    if (data.twoWheeler === '') {
      data.twoWheeler = undefined;
    }
    if (data.threeWheeler === '') {
      data.threeWheeler = undefined;
    }
    if (data.fourWheeler === '') {
      data.fourWheeler = undefined;
    }
    if (data.memberAmt === '') {
      data.memberAmt = undefined;
    }
    if (data.entranceFee === '') {
      data.entranceFee = undefined;
    }
    if (data.otherAmt === '') {
      data.otherAmt = undefined;
    }
    if (data.total === '') {
      data.total = undefined;
    }
    if(data.compositionType===''){
      data.compositionType = undefined;
    }
    if(data.floorType===''){
      data.floorType = undefined;
    }

    setSpinnerVisible(true);
    axios
      .put(`${Config.apiUrl}/PropertyDetails/update?id=${id}`, data, { headers: authHeader() })
      .then((response) => {
        console.warn(data);
        console.log(response);
        if (response.data[0].success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            sticky: true,
            detail: response.data[0].msg,
            life: "6000",
          });
          SetSaveDisabled(false);
        } else {
          // alert(response.data[0].msg);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data[0].msg,
          });
        }
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);

      })
  };

  const [propertyDetails, setPropertyDetails] = useState({});

  useEffect((data) => {
    if (isAddMode) {
      societyService.getMaxShares(societyId).then((data) => {
        setMaxShares(data);
        console.warn(maxShares);
        let toShares = data + 4;
        setToShares(toShares);
        console.warn(toShares);
      });
    }

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);
      setResetDisable(true);
      SetSaveDisabled(true);

    }

    // if (!isAddMode) {
    // get user and set form fields
    societyService.getPropertyDetailsOnID(id).then((propertyDetails) => {

      if (propertyDetails.length > 0) {
        setNextDisabled(false);
        setResetDisable(false);
        const fields = [
          "type",
          "usedBy",
          "areaInSqft",
          "areaInSqMtr",
          "agreementValue",
          "twoWheeler",
          "threeWheeler",
          "fourWheeler",
          "memberNo",
          "memberAmt",
          "certificateNo",
          "entranceFee",
          "sharesFrom",
          "otherAmt",
          "sharesTo",
          "total",
          "compositionType",
          "floorType"

        ];
        fields.forEach((field) => setValue(field, propertyDetails[0][field]));
        setMaxShares(propertyDetails[0].sharesFrom);
        setToShares(propertyDetails[0].sharesTo);
        setUsedBy(propertyDetails[0].usedBy);
        setPropertyDetails(propertyDetails);
      

        let total =
          propertyDetails[0].otherAmt +
          propertyDetails[0].memberAmt +
          propertyDetails[0].entranceFee;
        setValue("total", total);
        console.warn("Personal Details", propertyDetails);
        setNextDisabled(false);
        setIsAddMode(false);
      }
      else {
        // createUser(data);
        setIsAddMode(true);
        let obj = { memberAmt: 0, entranceFee: 0, otherAmt: 0 };
        let array = [];
        array.push(obj);
        setPropertyDetails(array);
      }
    });
    // }
  }, []);

  const onChangeUse = (e) => {
    let useBy = e.currentTarget.value;
    setUsedBy(useBy);
  };

  const onChange = (e) => {
    let SqrFoot = e.currentTarget.value;
    setValue("areaInSqMtr", calculate_SqrMeter(SqrFoot));
    console.warn(calculate_SqrMeter());
  };
  function calculate_SqrMeter(SqrFoot) {
    var sM = SqrFoot * 0.09290304;
    sM = sM.toFixed(2);
    return sM;
  }

  const MemberAmt = (e) => {
    let amt = e.currentTarget.value;
    let Amt = parseFloat(amt);
    propertyDetails[0].memberAmt = Amt;
    setPropertyDetails(propertyDetails);
    calculate_Total();
  };
  const EntranceFee = (e) => {
    let fee = e.currentTarget.value;
    let Fee = parseFloat(fee);
    setEntranceFee(Fee);

    propertyDetails[0].entranceFee = Fee;
    setPropertyDetails(propertyDetails);
    calculate_Total();
  };
  const OtherAmt = (e) => {
    let otherAmnt = e.currentTarget.value;
    let lOtherAmt = parseFloat(otherAmnt);
    setOtherTotal(lOtherAmt);

    propertyDetails[0].otherAmt = lOtherAmt;
    setPropertyDetails(propertyDetails);

    calculate_Total();
  };
  function calculate_Total() {
    let total =
      propertyDetails[0].memberAmt +
      propertyDetails[0].entranceFee +
      propertyDetails[0].otherAmt;
    setValue("total", total);
    // return Total;
  }
  const onNext = (e) => {
    // let insertIndex = personalDetails.findIndex(a => a.state === "New" || a.state === "modified");
    // if (insertIndex >= 0) {
    //   // alert("There are unsaved records in grid. Please save it before moving it to new tab")
    //   toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'There are unsaved records in grid. Please save it before moving it to new tab' });

    // }
    setActiveTab(2);
  }
  const onReset = () => {
    reset();
    // setIsEdit(false);


  }

  return (
    <div className="Propertyform" >
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
      <div className="Form-inline">

        <label className="PropertyLabel"> Flat/Shop Type:<span style={{ color: 'red', marginLeft:'-20px' }}></span>:</label>
        <select className="PropertySelect" name="type" ref={register({ required: true })} autoFocus={focusValue}>
          <option>Residental</option>
          <option>Commercial</option>
          <option>Semi Commercial</option>
        </select>

        <label className="PropertyLabel"> Used By<span style={{ color: 'red' }}>*</span>:</label>
        <select
          className="PropertySelect"
          name="usedBy"
          ref={register({ required: true })}
          onChange={onChangeUse}
        >
          <option hidden value="">Select Used By</option>
          <option>Owner</option>
          <option>Tenant</option>
        </select>
      </div>

      <div className="Form-inline">
        <label className="PropertyLabel"> Area in Sqft:</label>
        <input
          type="number"
          name="areaInSqft"
          className=""
          ref={register}
          onChange={onChange}
          autoComplete="off" 
        />

        <label className="PropertyLabel"> Area in Sq Mtr:</label>
        <input readOnly type8="text" name="areaInSqMtr"  autoComplete="off" ref={register} />
      </div>

      <div className="Form-inline">
        <label className="PropertyLabel"> Agreement Value: </label>
        <input type="number" name="agreementValue"  autoComplete="off" ref={register} />

    
        <label className="PropertyLabel"> Composition Type:<span style={{ color: 'red', marginLeft:'-20px' }}></span>:</label>
        <select className="PropertySelect" name="compositionType" ref={register}>
          <option hidden value="">select </option>
          <option>1RK</option>
          <option>1BHK</option>
          <option>1.5BHK</option>
          <option>2BHK</option>
          <option>2.5BHK</option>
          <option>3BHK</option> 
          <option>3.5BHK</option>
          <option>4BHK</option>
          <option>4.5BHK</option>
          <option>5BHK</option>
        </select>
      
     
        <label className="Floorlabel">Floor Type:</label>
        <input  type="text" className="floor" name="floorType" maxLength={20}  placeholder= " Enter Room No in Words " autoFocus={focusValue} ref={register}  />
      </div>

      <div className="Header">
        <h3 style={{marginLeft:"10px"}}>Membership Details</h3>
      </div>

      <div className="Form-inline">
        <label className="PropertyLabel">Member No:</label>
        <input type8="text" name="memberNo" autoComplete="off" ref={register} />

        <label className="PropertyLabel"> Membership Amt:</label>
        <input
          type="number"
          name="memberAmt"
          ref={register}
          onChange={MemberAmt}
          autoComplete="off" 
        />
      </div>

      <div className="Form-inline">
        <label className="PropertyLabel"> Certificate No:</label>
        <input type8="text" name="certificateNo" autoComplete="off" ref={register} />

        <label className="PropertyLabel"> Entrance Fee:</label>
        <input
          type="number"
          name="entranceFee"
          ref={register}
          onChange={EntranceFee}
          autoComplete="off" 
        />
      </div>

      <div className="Form-inline">
        <label className="PropertyLabel"> Shares From:</label>
        <input
          type8="text"
          name="sharesFrom"
          ref={register}
          value={maxShares}
          autoComplete="off" 
        />

        <label className="PropertyLabel"> Other Amt:</label>
        <input
          type="number"
          name="otherAmt"
          ref={register}
          onChange={OtherAmt}
          autoComplete="off" 
        />
      </div>

      <div className="Form-inline">
        <label className="PropertyLabel"> Shares To:</label>
        <input type8="text" name="sharesTo" ref={register} value={ToShares} autoComplete="off"  />

        <label className="PropertyLabel"> Total:</label>
        <input readOnly type="number" name="total" ref={register} autoComplete="off"  />
      </div>

      <div className="invalid-feedback">
        <div>{errors.type?.message}</div>
        <div> {errors.usedBy?.message}</div>
      </div>
      <div className="p-formgroup-inline" style={{ justifyContent: "center", marginRight: '13%' }}>
        <div className="p-field">
          <Button
            label="Save"
            type="submit "
            onClick={handleSubmit(onSubmit)}
            disabled={DisableButton}
          />
        </div>
        <div className="p-field">

          <Button disabled={nextDisabled} label="Next" onClick={onNext} />
        </div>

        <div className="p-field ">
          <Button label="Reset" type="reset" onClick={onReset} disabled={ResetDisable} />
        </div>
      </div>
    </div>
  );
}
export default PropertyDetails;
