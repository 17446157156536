import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useContext, useRef } from 'react';
import './TransactionView.css';
import SocietyService from '../../Service/SocietyService';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import moment, { invalid } from 'moment';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { Dropdown } from 'primereact/dropdown';


function TransactionView({ setEditData }) {
  let history = useHistory();
  const societyService = new SocietyService();
  const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
  var [transactionData, SetTransactionDetailsData] = useState([]);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  var societyId = parseInt(localStorage.getItem('societyId'));
  const [Generateloading, setGenerateloading] = useState(undefined);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
  const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const dt = useRef(null);
  const [societyGls, setSocietyGls] = useState([]);
  var [GlId, setGlId] = useState([]);
  const [selectedGlTitle, setSelectedGlTitle] = useState(null);
  var [intialData, setIntialData] = useState([]);

  var [data, setData] = useState();
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [maxDate, setMaxDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const tableName = sessionStorage.getItem('tblName');



  useEffect(() => {
    let d = moment(moment(new Date())).format('YYYY-MM-DD');
    setGenerateloading(false);
    setValue('date', d);
    console.warn(d);
    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }

    try {
      societyService.getGlTitle(societyId)
        .then((data) => {
          data.sort(compareGlTitils);
          data.splice(0, 0, { glTitle: 'All' })
          setSocietyGls(data)
          // data.map(({ glTitle, glId }) => ({
          //   label: glTitle, glId,
          //   value: glTitle, glId
          // }))
          //)
          console.warn(data);
        })
        .catch(error => {
          toast.current.show({ severity: 'error', detail: error });
        })
    }
    catch (error) {
      console.log(error);
      toast.current.show({ severity: 'error', detail: error });
    }
  }, []);

  const onDelete = (event) => {
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={handleSubmit(onYes)} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
            </div>
          </div>
        </div>
      )
    });
  }

  const onYes = (event) => {
    try {
      toast.current.clear();
      setEditData = selectedRadioData;
      let index = transactionData.findIndex(a => a.id === selectedRadioData.id);
      let id = selectedRadioData.id;
      let data = transactionData.filter(b => b.id != selectedRadioData.id);
      SetTransactionDetailsData(data);
      setSpinnerVisible(true);
      axios.delete(`${Config.apiUrl}/Transaction/delete?id=${id}&transactionTableName=${tableName}`, { headers: authHeader() })
        .then(response => {
          if (response.data != null) {
            transactionData = [...data];
            toast.current.show({ severity: 'success', detail: "Data Deleted Successfully" });
          }
          else {
            toast.current.show({ severity: 'error', detail: response.data[0].msg });
          }
        })
        .catch(error => {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
        })
        .finally(() => {
          setSpinnerVisible(false);
        })
    }
    catch (error) {
      console.log(error);
      toast.current.show({ severity: 'error', detail: error });
    }
  }

  const onNo = () => {
    toast.current.clear();
    return;
  };

  const onGlTitleChange = (e) => {
    let glTitle = e.value.glTitle
    setSelectedGlTitle(e.value);
    if (glTitle != 'All') {
      societyService.getGlTitle(societyId).then((data) => {
        let glId = data.find(a => a.glTitle === glTitle);
        setGlId(glId.glId);
      },
      );
    }
    else {
      setGlId(0);
    }
    transactionData = intialData;
  }

  const onFilter = (e) => {
    setSpinnerVisible(true);

    societyService.getTransactionDetailsRecordsCount(fromDate, toDate, GlId, societyId, tableName)
      .then(data => {
        if (data.success) {

          societyService.getTransactionDetails(fromDate, toDate, GlId, societyId, tableName)
            .then(data => {
              const filteredData = data.filter(item => item.creditPrinciple !== 0 || item.debitPrinciple !== 0);

              filteredData.forEach(item => {
                if (item.debitPrinciple === 0) {
                  item.debitPrinciple = '---';
                }
                if (item.creditPrinciple === 0) {
                  item.creditPrinciple = '---';
                }
                if (item.debitInterest === 0) {
                  item.debitInterest = '---';
                }
                if (item.creditInterest === 0) {
                  item.creditInterest = '---';
                }
                if (item.creditDpc === 0) {
                  item.creditDpc = '---';
                }
                if (item.debitDpc === 0) {
                  item.debitDpc = '---';
                }
                if (item.creditDpc === 0) {
                  item.creditDpc = '---';
                }
                if (item.flatNo === 0) {
                  item.flatNo = '---';
                }
                if (item.rctNo === 0) {
                  item.rctNo = '---';
                }
                if (item.scrollNo === 0) {
                  item.scrollNo = '---';
                }
                if (item.wing === "") {
                  item.wing = '---';
                }
              });
              SetTransactionDetailsData(filteredData);
            }).catch(error => {
              SetTransactionDetailsData([]);
              toast.current.show({ severity: 'error', detail: error });

            })
            .finally(() => {
              setSpinnerVisible(false);
            });
        } 
        //else {
         // toast.current.show({ severity: 'error', detail: data.response.msg });

        //}
        else {
          console.error('Error:', data);
          let errorMessage = 'An error occurred';
          if (data && data.response && data.response.msg) {
            errorMessage = data.response.msg;
          } else if (data && data.message) {
            errorMessage = data.message;
          }
          toast.current.show({ severity: 'error', detail: errorMessage });
        }
        
      }).catch(error => {
        toast.current.show({ severity: 'error', detail: error });
      })
    console.warn(transactionData);
  }

  const onDateChange = (e) => {
    let FromDate = e.currentTarget.value;
    setFromDate(FromDate);
  }

  const onToDateChange = (e) => {
    let ToDate = e.currentTarget.value;
    setToDate(ToDate);
  }

  const onEdit = () => {
    if (selectedRadioData.transactionType === 'GR') {
      history.push(`/GeneralReceipt/edit/${selectedRadioData.id}`);
    }
    if (selectedRadioData.transactionType === 'MR') {
      history.push(`/MemberReceipt/edit/${selectedRadioData.id}`);
    }
    if (selectedRadioData.transactionType === 'MRR') {
      history.push(`/MRReturnEntry/edit/${selectedRadioData.id}`);
    }
    if (selectedRadioData.transactionType === 'GRR') {
      history.push(`/GRReturnEntry/edit/${selectedRadioData.id}`);
    }
    if (selectedRadioData.transactionType === 'VE') {
      history.push(`/VoucherEntry/edit/${selectedRadioData.id}`);
    }
    if (selectedRadioData.transactionType === 'DE') {
      history.push(`/CreditDebitNote/edit/${selectedRadioData.id}/${selectedRadioData.jeNo}`);
    }
    if (selectedRadioData.transactionType === 'CE') {
      history.push(`/CreditDebitNote/edit/${selectedRadioData.id}/${selectedRadioData.jeNo}`);
    }
    console.warn("edit buuton clicked");
  }
  function compareGlTitils(a, b) {

    // converting to uppercase to have case-insensitive comparison
    const name1 = a.glTitle.toUpperCase();
    const name2 = b.glTitle.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }

  const header = (
    <div className="table-header">
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" style={{
            width: '180px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, margin: '2px', paddingRight: '1rem'
          }} value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
        </span>

        <label style={{ width: '150', padding: 2, margin: 2, marginLeft: '20px' }}>From:</label>
        <input type="date" max={maxDate} style={{
          height: '37px', padding: '6 2 6 2', margin: 2, marginRight: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
          borderRadius: 5,
        }} name="date" value={fromDate} onChange={onDateChange} />


        <label className="Date">To:</label>
        <input type="date" name="date" max={maxDate} style={{
          height: '37px', padding: '6 2 6 2', margin: 2, marginRight: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
          borderRadius: 5,
        }} value={toDate} onChange={onToDateChange} />

        <label>Gl Title:</label>
        {/* <select onChange={onGlTitleChange} style={{
          height: '30px', width: '150', padding: 2, margin: 2, marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
          borderRadius: 5
        }}>
          <option hidden value="">Select Gl Title</option>
          {GlTitle.map(({ label }) => (
            <option>
              {label}
            </option>
          ))}
        </select> */}
        <Dropdown style={{
          borderRadius: '5px', borderColor: 'green', width: '200px', borderStyle: 'solid', borderWidth: 1
        }} value={selectedGlTitle} options={societyGls} onChange={onGlTitleChange} id="glTitle" optionLabel="glTitle" filter showClear filterBy="glTitle" placeholder="Select a Gl" />
        <Button label="FETCH" style={{
          width: '100px', padding: '6 2 6 2', margin: 2, marginLeft: '10px', borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
          borderRadius: 5
        }} onClick={onFilter} />
      </div>

      <div>
        <Button icon="pi pi-pencil" value="Edit" tooltip="Edit" className="btnEdit" onClick={onEdit} disabled={DisableButton} />
        <Button icon="pi pi-trash" value="Delete" tooltip="Delete" className="btn" onClick={onDelete} disabled={DisableButton} />
      </div>
    </div>
  );

  return (
    <div className="TransactionView" >
      <span style={{ fontFamily: 'verdena', fontWeight: 'bold' }}>Transactions View</span>
      <div>
        <Toast ref={toast} position={'center'} />
        <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
        <DataTable ref={dt} value={transactionData} className="p-datatable-gridlines"  scrollable scrollHeight='45vh' style={{ overflow: 'hidden' }} header={header} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}
          globalFilter={globalFilter} emptyMessage="No result found.">
          <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
          <Column field="dateFormat" style={{ width: '115px' }} header="Date" filter></Column>
          <Column field="glId" header="GL ID" style={{ width: '70px' }} filter></Column>
          <Column field="glTitle" header="GL Title" style={{ width: '120px' }} filter></Column>
          <Column field="wing" header="Wing" style={{ width: '70px' }} filter></Column>
          <Column field="flatNo" header="Flat No" style={{ width: '80px' }} filter></Column>
          <Column field="name" header="Name" style={{ width: '200px' }} filter></Column>
          <Column field="billNo" header="Bill No" style={{ width: '70px' }} filter></Column>
          <Column field="rctNo" header="Rct No" style={{ width: '70px' }} filter></Column>
          <Column field="scrollNo" header="Scroll No" style={{ width: '70px' }} filter></Column>
          <Column field="debitPrinciple" header="Debit Principle" style={{ width: '90px' }} filter></Column>
          <Column field="debitInterest" header="Debit Interest" style={{ width: '80px' }} filter></Column>
          <Column field="debitDpc" header="Debit Dpc" style={{ width: '70px' }} filter></Column>
          <Column field="creditPrinciple" header="Credit Principle" style={{ width: '90px' }} filter></Column>
          <Column field="creditInterest" header="Credit Interest" style={{ width: '80px' }} filter></Column>
          <Column field="creditDpc" header="Credit Dpc" style={{ width: '80px' }} filter></Column>
          <Column field="totalAmount" header="Total Amount" style={{ width: '90px' }} filter></Column>
          <Column field="balance" header="Balance" style={{ width: '90px' }} filter></Column>
          <Column field="cheqNo" header="Cheq No" style={{ width: '100px' }} filter></Column>
          <Column field="bankName" header="Bank Name" style={{ width: '100px' }} filter></Column>
          <Column field="narration" header="Narration" style={{ width: '200px' }} filter></Column>
          <Column field="jeNo" header="Je No" style={{ width: '80px' }} filter></Column>
          <Column field="transactionType" header="Transaction Type" style={{ width: '120px' }} filter></Column>
          <Column field="createdAt" header="Created Timestamp" style={{ width: '110px' }} filter></Column>
        </DataTable>
      </div>
    </div>
  );
}
export default TransactionView;
