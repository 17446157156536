import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import UpdateMaintainanceUi from './UpdateMaintainanceUi';
import UpdateCommunicationDetail from './UpdateCommunicationDetail';
import TenantInfo from 'components/TenantInfo';


function MaintenanceLedger() {
    return (
        <TabView >

            <TabPanel header="Maintenance">

                <UpdateMaintainanceUi />

            </TabPanel>
            <TabPanel header="Communication Details" >
                <UpdateCommunicationDetail />
            </TabPanel>
            <TabPanel header="Tenant Info">
                <TenantInfo />
            </TabPanel>



        </TabView>
    )
}

export default MaintenanceLedger