import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import './GroupMasterAuditView.css';
import { Button } from 'primereact/button';
import moment from 'moment';

function GroupMasterAuditView() {

    const [globalFilter, setGlobalFilter] = useState('');
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [GroupMasterAuditData, setGroupMasterAuditInfo] = useState();
    const societyService = new SocietyService();
    var societyId=parseInt(localStorage.getItem('societyId'));

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {
        societyService.getGroupMasterAuditInfo(fromDate, toDate,societyId).then(data => setGroupMasterAuditInfo(data));
        console.warn(GroupMasterAuditData)
    }

    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>Group Master Audit View</b> */}

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }}  value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className='label' >From:</label>
            <input type="date" name="date" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className='label'>To:</label>
            <input type="date" name="date" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />
        </div>
    );

    return (


        <div className="GroupMasterAuditView">
             <span style={{fontFamily:'verdena', fontWeight:'bold'}}>Group Master Audit View</span> 
            <div>

            <DataTable className="p-datatable-gridlines" scrollable scrollHeight='500px' style={{ overflow: 'hidden' }} header={header}
                globalFilter={globalFilter} value={GroupMasterAuditData} emptyMessage="No result found.">
                <Column field="groupId" header="Group ID" style={{ width: '70px' }} filter></Column>
                <Column field="groupName" header="Group Name" style={{ width: '120px' }} filter></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '80px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>

            </DataTable>
            </div>
        </div>

    );
}
export default GroupMasterAuditView;