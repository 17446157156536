import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from 'Service/SocietyService';
import { Dropdown } from 'primereact/dropdown';
import './UserRoles.css';
import axios from "axios";
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import authHeader from '../authHeader';

var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
var societyId = parseInt(localStorage.getItem('societyId'));


function UserRoles() {
    const [userDetails, setUserDetails] = useState([]);
    const [DisableButton, setDisableButton] = useState(true);
    const [userName, setUserName] = useState('');
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [selectedRole, setSelectedRole] = useState([]);
    const [addDisabled, setAddDisabled] = useState(false);
    const [destinationData, setDestinationData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [rolesDetails, setRolesDetails] = useState([]);
    const [rolesidDetails, setRolesidDetails] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState([]);
    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
    const [SubGmData, setSubGmData] = useState(null);
    const toast = useRef(null);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const societyService = new SocietyService();

    useEffect(() => {
        societyService.getRegisterUserDetails(societyId)
            .then(data => {
                data.forEach(a => {
                    a.userInfo = `${a.userName}-${a.firstName}-${a.lastName}`;
                })

                setUserDetails(data);
            });

        societyService.getRolesDetails()
            .then(data => {
                setRolesDetails(data);
            });

        if (accessLevel >= 2) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }

    }, []);

    const onChangeUser = (event) => {
        setUserName(event.target.value);
        let selectedUSer = event.target.value.userId;

        societyService.getRolesidDetails(selectedUSer)
            .then(data => {
                setDestinationData(data);
            });
    };

    const handleMoveSelectedRows = () => {
      if (selectedRows.length === 0) {
          toast.current.show({
              severity: 'warn',
              detail: 'Please select at least one row.',
          });
          return;
      }

      // Check for duplicates in the destinationData based on the name property
      const duplicateNames = selectedRows.filter(row => destinationData.some(destRow => destRow.roleName === row.roleName));

      if (duplicateNames.length > 0) {
          toast.current.show({
              severity: 'warn',
              detail: 'Duplicate names detected. Please select only unique names.',
          });
          return;
      }

      // Update destinationData with the selectedRows
      setDestinationData(prevData => [...prevData, ...selectedRows]);

      setIsSaveButtonDisabled(destinationData.length === 0);
  };


    const onDelete = () => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="p-text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <Button type="button" label="Yes" value={SubGmData} className="p-button-success" onClick={handleSubmit(onYes)} />
                        </div>
                        <div className="p-col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
                        </div>
                    </div>
                </div>
            )
        });
    }
    const onYes = () => {
      if (!selectedRows || selectedRows.length === 0 || !userName) {
        toast.current.show({
          severity: 'warn',
          sticky: true,
          detail: 'Please Select Row and User First',
        });
        return;
      }
    
      toast.current.clear();
      setSpinnerVisible(true);
    
      // Create an array to store promises for each delete operation
      const deletePromises = selectedRows.map((row) => {
        let id = row.id;
    
        // Return the axios delete promise
        return axios.delete(`${Config.apiUrl}/UserRoles/delete?id=${id}`, {
          headers: authHeader(),
        });
      });
    
      // Use Promise.all to wait for all delete operations to complete
      Promise.all(deletePromises)
        .then((responses) => {
          responses.forEach((response, index) => {
            console.log(response);
            if (response.data.success) {
              toast.current.show({
                severity: 'success',
                sticky: true,
                detail: response.data.msg,
              });
    
              // Remove the successfully deleted row from selectedRows
              setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter(
                  (row) => row.id !== selectedRows[index].id
                )
              );
            } else {
              toast.current.show({
                severity: 'error',
                sticky: true,
                detail: response.data.msg,
              });
            }
          });
    
          // Reload the page after successful delete
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSpinnerVisible(false);
        });
    };
    
    const onNo = () => {
        toast.current.clear();
        return;
    };

    const onSave = () => {
        if (!userName || selectedRows.length === 0) {
          toast.current.show({ severity: 'Warn', sticky: true, detail: "Please Select User and Role First" });
          return;
        }
      
        setSpinnerVisible(true);
      
        // Create an array to store promises for each save operation
        const savePromises = selectedRows.map((row) => {
          let details = rolesDetails.filter(a => a.roleName === row.accessLevel);
          let newData = { UserId: userName.userId, RoleId: row.id };
      
          // Return the axios post promise
          return axios.post(`${Config.apiUrl}/UserRoles/UserInfo`, newData, { headers: authHeader() });
        });
      
        // Use Promise.all to wait for all save operations to complete
        Promise.all(savePromises)
          .then((responses) => {
            responses.forEach((response, index) => {
              console.log(response);
              if (response.data.success) {
                toast.current.show({ severity: 'success', detail: response.data.msg });
      
                // Remove the successfully saved row from selectedRows
                setSelectedRows(prevSelectedRows => prevSelectedRows.filter((row, i) => i !== index));
              } else {
                toast.current.show({ severity: 'error', detail: response.data.msg });
              }
            });
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            setSpinnerVisible(false);
          });
      };
      
    return (
        <div className='UsersView' style={{ marginTop: "10px" }} >
            <div>
                <label style={{ fontSize: "18px", width: "80px", marginLeft: "30px" }}> Select User </label>
                <Dropdown options={userDetails} optionLabel="userName" placeholder="Select user" value={userName} onChange={onChangeUser} />
            </div>

            <div style={{ display: 'flex', marginTop: "25px", borderRadius: "6px",color:"black" }}>
            <DataTable
        value={rolesDetails}
        className="p-datatable-gridlines p-datatable-sm custom-datatable"  // Add custom class for styling
        style={{ background: 'linear-gradient(to bottom, #fcfff4 0%,#e9e9ce 100%);', border: '1px solid #ddd' }}  // Add border style
        scrollable
        scrollHeight="500px"
        style={{ overflow: 'hidden'}}
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
    >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
        <Column field="roleName" header="RoleName"></Column>
    </DataTable>

                <i className="pi pi-angle-double-right" style={{ fontSize: '2rem', color: "grey", marginTop: "60px" }} onClick={handleMoveSelectedRows}></i>
       
                <DataTable value={destinationData} className="p-datatable-gridlines p-datatable-sm" style={{ background: 'linear-gradient(to bottom, #fcfff4 0%,#e9e9ce 100%);' }} scrollable scrollHeight='500px' style={{ overflow: 'hidden' }} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="roleName" header="UserRole" > </Column>
                </DataTable>
            </div>
            <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
            <div className="p-field">
             <Button icon="pi pi-save"label="SAVE" type="text" value={selectedRows} onClick={onSave} />
                     </div>

<div className="p-field">
                    <Toast ref={toast} />
                    <Button
                    icon="pi pi-trash"
                    value="Delete"
                    tooltip="Delete"
                    label="DELETE"
                    className="btn" 
                    onClick={onDelete}  />  </div>
            </div>
        </div>
    );
}

export default UserRoles;
