import { React, useState } from "react";
import SocietyContext from './SocietyContext';

const SocietyState = (props) => {
    const data = {
        "societyId": '-1'
    }

    const [societyData, setSocietyData] = useState(data);
    const updateSocietyData = (id) => {
        setSocietyData({ "societyId": id });
    }
    return (

        <SocietyContext.Provider value={{societyData , updateSocietyData}}>
            {props.children}
        </SocietyContext.Provider>
    )

}

export default SocietyState;