
import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';


var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var Dues = [];
var selectedDate;
var NoticeNumber;
var converter = require('number-to-words');

// class Example extends React.Component {
function DuesReminder({ props }) {

    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const societyService = new SocietyService();
    const [selectedType, setSelectionType] = useState();
    const [Stype, setType] = useState("Tariff Wise");
    const [regularDues, setRegularDues] = useState([]);
    Dues = regularDues;
    const [amount, setAmount] = useState();
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [DateWithTime, setDateWithTime] = useState();
    const [Operator, setOperator] = useState();
    selectedDate = date;
    const [NoticeNo, setNoticeNo] = useState(1);
    const [tariffno, setTariffNo] = useState(1);
    const [InstallmentData, setInstallmentsdata] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');

    NoticeNumber = NoticeNo;

    const onSelectionTypeChange = (e) => {
        setSelectionType(e.value);
        setType(e.value.name);
        if (e.value.name === 'Tariff Wise') {
            societyService.getInstallment(societyId).then(data => {
                setInstallmentsdata(data);
            })
        }
    }

    useEffect(() => {
        societyService.getSocietyDetailsOnID(societyId).then(data => {
            SetSocietyDetailsData(data)
        });
    })

    const selectionType = [
        { name: 'Tariff Wise' },
        { name: 'Amount Wise' },
    ];

    const onAmountChange = (e) => {
        let Amount = e.currentTarget.value
        setAmount(Amount);
    }

    const onDateChange = (e) => {
        let Date = e.currentTarget.value
        setDate(Date);
        Date = moment(Date).format('YYYY-MM-DDThh:mm:ss');
        setDateWithTime(Date);

    }

    const onOperatorChange = (e) => {
        let operator = e.currentTarget.value
        setOperator(operator);
    }

    const onTariffNo = (e)=>{
        let Tariffno=parseInt(e.currentTarget.value);
        setTariffNo(Tariffno);
    }

    const onSelectNoticeNo = (e) => {
        let NoticeNo = e.currentTarget.value;
        setNoticeNo(NoticeNo);
    }

    const viewBills = (e) => {
        setSpinnerVisible(true);
        let undefinedList = [];
        if (date === undefined) {
            undefinedList.push({ label: "select date" });
        }

        if (selectedType === undefined) {
            undefinedList.push({ label: "select amount type" });

        }
        if (Operator === undefined) {
            undefinedList.push({ label: "select operator" });

        }
        // if (selectedType?.name === "Tariff Wise") {
        if (NoticeNo === undefined) {
            undefinedList.push({ label: "select Notice number" });
        }
        // }
        if (selectedType?.name === "Amount Wise") {
            if (amount === undefined) {
                undefinedList.push({ label: "enter amount" });

            }
        }
        let message = "Please";
        undefinedList.forEach(a => {

            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            toast.current.show({ severity: 'warn', sticky: true, detail: message });
            setSpinnerVisible(false);
        }
        else {
            societyService.getDuesReminderData(societyId, date, amount,tableName).then(data => {
                setRegularDues(data);


                if (Stype === 'Tariff Wise') {
                    data.forEach(d => {
                        let fId = d.flatId;
                        let item = InstallmentData.find(i => i.flatId = fId);
                        d.installmentAmount = item.installmentAmount;
                    })

                    if (Operator === '>=') {
                        let totalDues = data.filter(a => a.totalAmount >= a.installmentAmount * tariffno);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '>') {
                        let totalDues = data.filter(a => a.totalAmount > a.installmentAmount * tariffno);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '<=') {
                        let totalDues = data.filter(a => a.totalAmount <= a.installmentAmount * tariffno);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '<') {
                        let totalDues = data.filter(a => a.totalAmount < a.installmentAmount * tariffno);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '=') {
                        let totalDues = data.filter(a => a.totalAmount == a.installmentAmount * tariffno);
                        setRegularDues(totalDues);
                    }
                }
                else {

                    if (Operator === '>=') {
                        let totalDues = data.filter(a => a.totalAmount >= amount);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '>') {
                        let totalDues = data.filter(a => a.totalAmount > amount);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '<=') {
                        let totalDues = data.filter(a => a.totalAmount <= amount);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '<') {
                        let totalDues = data.filter(a => a.totalAmount < amount);
                        setRegularDues(totalDues);
                    }
                    if (Operator === '=') {
                        let totalDues = data.filter(a => a.totalAmount == amount);
                        setRegularDues(totalDues);
                    }
                }

            }
            ).catch(error => {
                toast.current.show({ severity: 'warn', sticky: true, detail: "Error in generating report" });
            }).finally(() => {
                setSpinnerVisible(false);
            })
        }

    }
     // Override the toWords function to include spaces after "forty" and "four"
  const originalToWords = converter.toWords;
  converter.toWords = (number) => {
    let words = originalToWords(number);
  
    // Add space after 
    words = words.replace(/-/g, ' ');
  
    // Add space after 
    words = words.replace(/, /g, ' ');
  
    return words;
  };
  const amountInWords = (amount, operator) => {
    const absoluteAmount = Math.abs(amount);
    const million = 1000000;
    const integerPart = Math.floor(absoluteAmount);
    const decimalPart = Math.round((absoluteAmount - integerPart) * 100);

    let result = '';

    // Process millions part
    if (integerPart >= million) {
        result += `${converter.toWords(Math.floor(integerPart / million))} Million `;
    }

    // Process remaining part
    const remainingPart = integerPart % million;
    if (remainingPart > 0) {
        if (remainingPart >= 100000) {
            result += `${converter.toWords(Math.floor(remainingPart / 100000))} Lakh `;
        }

        if (remainingPart % 100000 > 0) {
            result += `${converter.toWords(remainingPart % 100000)} `;
        }
    }

    // Process decimal part
    if (decimalPart > 0) {
        if (decimalPart < 10) {
            result += `& ${converter.toWords(decimalPart * 10)} Paise Only`;
        } else {
            result += `& ${converter.toWords(decimalPart)} Paise Only`;
        }
    }

    // Include result in the output string based on the operator
    if (operator === '<') {
        result = `${amount < 0 ? 'Minus ' : ''}${result}`;
    } else if (operator === '>') {
        result = `> ${result}`;
    }

    return result.trim().replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
    });
};

// Example usage:
console.log(amountInWords(-69718016, '<'));
console.log(amountInWords(123456789, '>'));
const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (

        <div class="card" style={{ backgroundColor: 'black', height: '70px' }}>
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />

            <div className='p-formgroup-inline' >
                <div style={{ margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="Date" style={{ height: '35px' }} value={date} onChange={onDateChange} />
                </div>
                <div style={{ margin: '15px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <Dropdown style={{ height: '35px' }} value={selectedType} options={selectionType} onChange={onSelectionTypeChange} id="selectionType" name="SelectionType" ref={register} optionLabel="name" placeholder="Select Amount Type" />
                </div>

                <div style={{ margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <select style={{ height: '35px', width: '120px' }} onChange={onOperatorChange} value={Operator}>
                        <option hidden value="">Select Operator</option>
                        <option>&gt;</option>
                        <option>&gt;=</option>
                        <option>&lt;</option>
                        <option>&lt;=</option>
                        <option>=</option>

                    </select>
                </div>
                <div style={{ display: Stype === 'Tariff Wise' ? 'none' : 'block', margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="text" style={{ height: '35px', marginLeft: '10px' }} value={amount} onChange={onAmountChange} placeholder="Enter Amount" />
                </div>

                <div style={{ display: Stype === 'Amount Wise' ? 'none' : 'block', margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="text" style={{ height: '35px', marginLeft: '10px' }} value={amount} onChange={onTariffNo} placeholder="Enter Tariff No" />
                </div>
                
                <div style={{ margin: '10px', marginTop:'10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="number" style={{ height: '35px', marginLeft: '10px' }} value={NoticeNumber} onChange={onSelectNoticeNo} placeholder="Enter Notice No" />
                   {/*  <select style={{ height: '35px', width: '130px' }} onChange={onSelectNoticeNo} value={NoticeNumber}>
                        <option hidden value="">Select Notice No:</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                    </select> */}
                </div>

                <div style={{ margin: '10px',marginTop:'10px'  }}>
                    <Button label="VIEW" onClick={viewBills} style={{ marginLeft: '10px' }} />
                </div>
                <div style={{ margin: '10px' }}>
                    <Button onClick={handlePrint} label="PRINT" style={{ marginLeft: '10px' }} />

                </div>
            </div>
            <Report ref={componentRef}  amountInWords={amountInWords} />

        </div>
    );

}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 297mm;

    }
  }
`;


class Report extends React.Component {
    render() {
        const { amountInWords } = this.props;

        return (
            <div>
                <style>{portrait()}</style>

                <div className="DuesReminder" >
                    {Dues.map((data, index) =>

                        <div className="Border" >
                            <div style={{ padding: '40px 80px 40px 80px' }}>
                                <div className='SocietyName' >
                                    <div className="font"><text>{societyData[0]?.societyName}</text></div>
                                    <div>
                                        <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                                        <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                                    </div>
                                    <div>
                                        <text>
                                            {societyData[0]?.address}
                                        </text>
                                    </div>
                                    <div class="font">
                                        <p>REMINDER NOTICE NO:{NoticeNumber}</p>
                                    </div>
                                </div>
                                <div className="AlignContentEnd">
                                    <label> Date:{moment(selectedDate).format("DD/MM/YYYY")}</label>
                                </div>
                                <div>
                                    <label className="DRLabel" style={{ width: '40%' }}>To,
                                    </label>


                                </div>
                                <div>
                                    <label className="user" >{Dues[index]?.name}</label>
                                </div>
                                <div>
                                    <label className="user" >{Dues[index]?.wing}-{Dues[index]?.flatNo}</label>
                                </div>
                                <div >
                                    <label className="user" >{societyData[0]?.societyName}</label>
                                </div>
                                <div className="fontsize">
                                    <div className="AlignContentCenter">
                                        <p><u><b>Sub : Out standing of Maintenance Charges Rs.{Dues[index]?.totalAmount.toFixed(2)}/- </b></u></p>
                                    </div>
                                    <p>
                                        <label>Dear Sir/Madam</label>
                                    </p>
                                    <div>
                                        <p>             We observe from the Accounts Records that your Society charges -<u> Rs.{Dues[index]?.totalAmount.toFixed(2)}/
                                            (RS.{amountInWords(Dues[index]?.totalAmount)})</u> is outstanding and are not paid
                                            up to date.</p>
                                    </div>
                                    <div>
                                        <p>             The Society has to make payments of day to day expenditure to run its management.
                                            If members are not paying in time to time then it is not possible for us to spend
                                            money for various necessary services of Water, Electricity, Security, Sweeper Salary
                                            etc.
                                        </p>
                                    </div>
                                    <div>
                                        <p>             Thus it affects services to be given to all Members. Members non payment affects
                                            Society’s Financial Position. Required expenditures are stopped for want fund.
                                            Hence you are hereby advised to make payment of overdue at the earliest & make a
                                            habit to pay bills monthly and in time. If every member does not pay their bills in
                                            time then Society’s Management cannot be done efficiently. Your Co-Operation is
                                            needed from time to time.
                                        </p>
                                    </div>

                                    <p>
                                        We hope you will understand the situation and arrange to make payment immediately.
                                    </p>

                                    <div >
                                        <label>
                                            Thanking you,
                                        </label>
                                    </div>
                                    <div >
                                        <label>
                                            For {societyData[0]?.societyName}
                                        </label>
                                    </div>

                                    <div>
                                        <p>
                                            (Secretary)
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </div>

        )
    }

};

export default DuesReminder;



