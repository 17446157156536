import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import MemberReceipt from '../Transactions/memberReceipt';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyData = [];
var societyName = localStorage.getItem('societyName');
var societyId = parseInt(localStorage.getItem('societyId'));
var selectedDate;
var summaryDues = [];
var view;
var totalAmountSum;

// class Example extends React.Component {
function SummaryDues({ props }) {
    const toast = useRef(null);
    const societyService = new SocietyService();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    selectedDate = date;
    const [DateWithTime, setDateWithTime] = useState();
    const [summaryDuesData, setSummaryDues] = useState([]);
    summaryDues = summaryDuesData;

    const [wings, setWings] = useState([]);
    const [Wing, setWing] = useState();
    const [type, setType] = useState();
    const [totalSum, setTotalAmountSum] = useState();
    totalAmountSum = totalSum;
    const [isPrintDisabled, setIsPrintDisabled] = useState(true);
    const [displayView, setDisplayView] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');

    view = displayView;

    useEffect(() => {
        societyService.getSocietyDetailsOnID(societyId).then(data => {
            SetSocietyDetailsData(data);
        });

        societyService
            .getWing(societyId)
            .then((data) => {
                data.splice(0, 0, { wing: 'All' })
                setWings(
                    data.map(({ wing }) => ({ label: wing, value: wing })),

                )
            }, []);

    }, []);

    const onDateChange = (e) => {
        let Date = e.currentTarget.value
        setDate(Date);
        // Date = moment(Date).format('YYYY-MM-DDThh:mm:ss');
        // setDateWithTime(Date);

    }


    const onChangeWing = (e) => {
        let wing = e.currentTarget.value;
        setWing(wing);
    }
    const viewBills = (e) => {
        setSpinnerVisible(true);
        let undefinedList = [];
        if (date === undefined) {
            undefinedList.push({ label: "select date" });
        }

        if (Wing === undefined) {
            undefinedList.push({ label: "select Wing" });

        }
        let message = "Please";
        undefinedList.forEach(a => {

            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            toast.current.show({ severity: 'warn', sticky: true, detail: message });
        }
        else {
            setDisplayView(true);
            setIsPrintDisabled(false);
            societyService.getRegularDues(societyId, date, Wing,tableName).then(data => {
                setSerialNo(data);
                setSummaryDues(data);

                setTotalAmountSum(data && data.reduce((a, v) => a + v.totalAmount, 0))

            }).catch(error=>{
                toast.current.show({ severity: 'warn', sticky: true, detail: "Error in generating Report" });
            }).finally(()=>{
                setSpinnerVisible(false);
            })
        }
    }
    
    const setSerialNo = (data) => {
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        });
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div class="card" style={{ backgroundColor: 'black', height: '70px' }}>
            <div className='p-formgroup-inline'>

                <Toast ref={toast} position="center" />
                <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
                <div style={{ margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="date" style={{ height: '35px' }} value={date} onChange={onDateChange} />
                </div>

                <div style={{ margin: '10px' }}>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <select style={{ width: '150px', height: '35px', marginLeft: '10px' }} name="wing" onChange={onChangeWing} >
                        <option hidden value="">Select Wing</option>
                        {wings.map(({ label, value: wings }) => (
                            <option key={wings} value={wings}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{ margin: '10px' }}>
                    <Button label="VIEW" onClick={viewBills} style={{ marginLeft: '10px' }} />
                </div>

                <div style={{ margin: '10px' }}>
                    <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT" style={{ marginLeft: '10px' }} />
                </div>



            </div>
            <Report ref={componentRef} />

        </div>
    );

}

const  portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class Report extends React.Component {
    render() {



        return (

            <div style={{ height: '100%', display: !view ? 'none' : 'block' }}>
                <div>
                <style>{portrait()}</style>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        <div class="font">
                            <label>Summary Dues From Members on Date</label>
                        </div>
                        <div className="dateFont">
                            <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label>
                        </div>

                    </div>

                    <div>
                        <div class="SummaryDues">
                            <table>
                                <thead className='headerColor'>
                                    <th style={{ width: '5%', textAlign: 'center' }}>Sr </th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Flat No</th>
                                    <th style={{ width: '65%', textAlign: 'center' }}>Member Name</th>
                                    <th style={{ width: '20%', textAlign: 'center' }}>Amount</th>

                                </thead>

                                {
                                    summaryDues.map((item) => (
                                        <tr key={item}>
                                            <td style={{ textAlign: 'center', padding: '5px' }}>{item.serialNo}</td>
                                            <td style={{ textAlign: 'center', padding: '5px' }}>{item.wing}-{item.flatNo}</td>
                                            <td style={{ textAlign: 'left', padding: '5px' }}>{item.name}</td>
                                            <td style={{ textAlign: 'right', padding: '5px' }}>{item.totalAmount?.toFixed(2)}</td>

                                        </tr>
                                    ))
                                }
                                <tr className='headerColor'>
                                    <th style={{ textAlign: 'center', padding: '5px' }}>Total</th>
                                    <td style={{ textAlign: 'center', padding: '5px' }}></td>
                                    <td style={{ textAlign: 'left', padding: '5px' }}></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{totalAmountSum?.toFixed(2)}</th>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>

            </div>


        )
    }

};

export default SummaryDues;



