import React from "react";
import styles from "./SocietyDetails.module.css";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "@material-ui/core";
import axios from "axios";
import SocietyService from "../../Service/SocietyService";
import Config from "../../Config.json";
import authHeader from "../../authHeader";
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import '../SocietyMaster/BiilingDetails.css';

function BillingDetails({ societyId, id, setActiveTab }) {
  let history = useHistory();
  const toast = useRef(null);

  const societyService = new SocietyService();
  //const isAddMode = !id;
  const [isAddMode, setAddMode] = useState(!id);
  const [billingDetails, SetBillingDetails] = useState([]);
  const [isCalcType, setIsCalcType] = useState("Simple Interest");
  const [isLatePaymentType, setIsLatePaymentType] = useState("Simple Interest");
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [isDayBase, setIsDayBase] = useState(true);


  const { errors, register, reset, handleSubmit, setValue } = useForm();

  const onSubmit = (data, e) => {
    return isAddMode ? InsertBillingDetails(data) : updateBillingDetails(data);
  };
  const InsertBillingDetails = (data, e) => {
    console.warn(data);

    if (isCalcType === "Simple Interest" && "Compound Interest") {
      data.fixAmount = -1;
    }
    if (isLatePaymentType === "Fixed Amount" && "Fixed Amount Multiplied") {
      data.rateInterestPA = -1;
    }

    if (data.rateInterestPA === "" || data.rateInterestPA === undefined) {
      data.rateInterestPA = -1;
    }
    if (data.lateInterestPA === "" || data.lateInterestPA === undefined) {
      data.lateInterestPA = -1;
    }
    if (data.fixAmount === "" || data.fixAmount === undefined) {
      data.fixAmount = -1;
    }
    if (data.lateFixAmount === "" || data.lateFixAmount === undefined) {
      data.lateFixAmount = -1;
    }
    if (data.surveyDate === "" || data.surveyDate === undefined) {
      data.surveyDate = "0001-01-01";
    }
    if (data.leaseDeedDate === "" || data.leaseDeedDate === undefined) {
      data.leaseDeedDate = "0001-01-01";
    }
    if (data.conveyanceDeedDate === "" || data.conveyanceDeedDate === undefined) {
      data.conveyanceDeedDate = "0001-01-01";
    }
    if (data.developDate === "" || data.developDate === undefined) {
      data.developDate = "0001-01-01";
    }
    if (data.triPartyDate === "" || data.triPartyDate === undefined) {
      data.triPartyDate = "0001-01-01";
    }
    if (data.bccOcDate === "" || data.bccOcDate === undefined) {
      data.bccOcDate = "0001-01-01";
    }
    if (!data.building) {
      data.building = "false";
    }
    if (!data.wing) {
      data.wing = "false";
    }
    if (!data.unitNo) {
      data.unitNo = "false";
    }
    if (!data.composition) {
      data.composition = "false";
    }
    if (!data.unitType) {
      data.unitType = "false";
    }
    if (!data.totalArea) {
      data.totalArea = "false";
    }
    if (!data.perUnitArea) {
      data.perUnitArea = "false";
    }
    if (!data.perPerson) {
      data.perPerson = "false";
    }

  
      data.isDayBased = isDayBase;
    

    setSpinnerVisible(true);
    return axios
      .post(`${Config.apiUrl}/BillingDetails/Insert`, data, { headers: authHeader() })
      .then((response) => {
        console.warn(data);
        console.log(response);
        if (response.data != null) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });

        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });

        }
        if (response.data[0].success) {
          setNextDisabled(false);
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })


  };

  const updateBillingDetails = (data, e) => {

    if (isCalcType === "Simple Interest" && "Compound Interest") {
      data.fixAmount = -1;
    }
    if (isLatePaymentType === "Fixed Amount" && "Fixed Amount Multiplied") {
      data.rateInterestPA = -1;
    }
    if (data.rateInterestPA === "" || data.rateInterestPA === undefined) {
      data.rateInterestPA = -1;
    }
    if (data.lateInterestPA === "" || data.lateInterestPA === undefined) {
      data.lateInterestPA = -1;
    }
    if (data.fixAmount === "" || data.fixAmount === undefined) {
      data.fixAmount = -1;
    }
    if (data.lateFixAmount === "" || data.lateFixAmount === undefined) {
      data.lateFixAmount = -1;
    }
    if (!data.building) {
      data.building = "false";
    }
    if (data.wing === false) {
      data.wing = "false";
    }
    if (data.unitNo === false) {
      data.unitNo = "false";
    }
    if (data.composition === false) {
      data.composition = "false";
    }
    if (data.unitType === false) {
      data.unitType = "false";
    }
    if (data.totalArea === false) {
      data.totalArea = "false";
    }
    if (data.perUnitArea === false) {
      data.perUnitArea = "false";
    }
    if (data.perPerson === false) {
      data.perPerson = "false";
    }
    data.isDayBased = isDayBase;
    setSpinnerVisible(true);
    return axios
      .put(`${Config.apiUrl}/BillingDetails/update?id=${id}`, data, { headers: authHeader() })
      .then((response) => {
        console.warn(data);
        console.log(response);
        if (response.data != null) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });

        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });

        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  };

  const [user, setUser] = useState({});
  const [surveyDate, setSurveyDate] = useState({});
  const [leaseDeedDate, setLeaseDeedDate] = useState({});
  const [conveyanceDeedDate, setConveyanceDeedDate] = useState({});
  const [developDate, setDevelopDate] = useState({});
  const [triPartyDate, setTriPartyDate] = useState({});
  const [bccOcDate, setBccOcDate] = useState({});
  const [isBuilding, setIsBuilding] = useState();
  const [isWing, setIsWing] = useState();
  const [isUnitNo, setIsUnitNo] = useState();
  const [isComposition, setIsComposition] = useState();
  const [isUnitType, setIsUnitType] = useState();
  const [isTotalArea, setIsTotalArea] = useState();
  const [isPerUnitArea, setIsPerUnitArea] = useState();
  const [isPerPerson, setIsPerPerson] = useState();

  useEffect(() => {
    if (!isAddMode) {
      societyService.getBillingDetailsOfSociety(id).then((userIn) => {
        const fields = [
          "billingType",
          "billingCycle",
          "billingDate",
          "dueDate",
          "rateInterestPA",
          "interestType",
          "fixAmount",
          "lateInterestPA",
          "latePaymentType",
          "lateFixAmount",
          "delayCalcMethod",
          "building",
          "wing",
          "unitNo",
          "composition",
          "unitType",
          "totalArea",
          "perUnitArea",
          "perPerson",
          "surveyNo",
          "surveyDate",
          "leaseDeedNo",
          "leaseDeedDate",
          "conveyanceDeedNo",
          "conveyanceDeedDate",
          "developAgreement",
          "developDate",
          "triPartyAgreement",
          "triPartyDate",
          "bccOc",
          "bccOcDate",
          "dueDateMonth",
          "monthStartHalfEnd"
        ];
        if(userIn[0].isDayBased != undefined && userIn[0].isDayBased != null){
          setIsDayBase(userIn[0].isDayBased );
            if(userIn[0].isDayBased){
              setValue("BillBasedType", 'Day_Base')
            } else{
              setValue("BillBasedType", 'Month_Base')
            }
          }
        fields.forEach((field) => setValue(field, userIn[0][field]));
        setUser(userIn[0]);

      

        if (userIn[0].sDate === '0001-01-01') {
          setSurveyDate("");
        }
        else {
          setSurveyDate(userIn[0].sDate);
        }

        if (userIn[0].ldDate === '0001-01-01') {
          setLeaseDeedDate("");
        }
        else {
          setLeaseDeedDate(userIn[0].ldDate);
        }

        if (userIn[0].cdDate === '0001-01-01') {
          setConveyanceDeedDate("");
        }
        else {
          setConveyanceDeedDate(userIn[0].cdDate);
        }

        if (userIn[0].cdDate === '0001-01-01') {
          setDevelopDate("");
        }
        else {
          setDevelopDate(userIn[0].dDate);
        }

        if (userIn[0].tpDate === '0001-01-01') {
          setTriPartyDate("");
        }
        else {
          setTriPartyDate(userIn[0].tpDate);
        }
        if (userIn[0].boDate === '0001-01-01') {
          setBccOcDate("");
        }
        else {
          setBccOcDate(userIn[0].boDate);
        }
        setIsCalcType(userIn[0].interestType);
        setIsLatePaymentType(userIn[0].latePaymentType);
        setIsBuilding(userIn[0].building === "true");
        setIsWing(userIn[0].wing === "true");
        setIsUnitNo(userIn[0].unitNo === "true");
        setIsComposition(userIn[0].composition === "true");
        setIsUnitType(userIn[0].unitType === "true");
        setIsTotalArea(userIn[0].totalArea === "true");
        setIsPerUnitArea(userIn[0].perUnitArea === "true");
        setIsPerPerson(userIn[0].perPerson === "true");
      });
      setNextDisabled(false);
    }

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }
  }, []);

  const onChangeSurveyDate = (e) => {
    setSurveyDate(e.currentTarget.value);
    setValue("surveyDate", e.currentTarget.value);
  };

  const onChangeLeaseDeedDate = (e) => {
    setLeaseDeedDate(e.currentTarget.value);
    setValue("leaseDeedDate", e.currentTarget.value);
  };

  const onChangeConveyanceDeedDate = (e) => {
    setConveyanceDeedDate(e.currentTarget.value);
    setValue("conveyanceDeedDate", e.currentTarget.value);
  };

  const onChangeDevelopDate = (e) => {
    setDevelopDate(e.currentTarget.value);
    setValue("developDate", e.currentTarget.value);
  };

  const onChangeTriPartyDate = (e) => {
    setTriPartyDate(e.currentTarget.value);
    setValue("triPartyDate", e.currentTarget.value);
  };

  const onChangeBccOcDate = (e) => {
    setBccOcDate(e.currentTarget.value);
    setValue("bccOcDate", e.currentTarget.value);
  };

  const onCalculationType = (e) => {
    const IsCalcType = e.currentTarget.value;
    setIsCalcType(IsCalcType);
  };

  const onLatePaymentType = (e) => {
    const IsLatePaymentType = e.currentTarget.value;
    setIsLatePaymentType(IsLatePaymentType);
  };

  const onChangeBuilding = (e) => {
    if (e.currentTarget.value === "") {
      setIsBuilding("true");
    } else {
      setIsBuilding("false");
    }
    setValue("building", e.currentTarget.value);
  };

  const onChangeWing = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsWing("true");
    } else {
      setIsWing("false");
    }
    setValue("wing", e.currentTarget.value);
  };

  const onChangeUnitNo = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsUnitNo("true");
    } else {
      setIsUnitNo("false");
    }
    setValue("unitNo", e.currentTarget.value || e.currentTarget.value === 'false');
  };

  const onChangeComposition = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsComposition("true");
    } else {
      setIsComposition("false");
    }
    setValue("composition", e.currentTarget.value);
  };

  const onChangeUnitType = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsUnitType("true");
    } else {
      setIsUnitType("false");
    }
    setValue("unitType", e.currentTarget.value);
  };

  const onChangeTotalArea = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsTotalArea("true");
    } else {
      setIsTotalArea("false");
    }
    setValue("totalArea", e.currentTarget.value);
  };

  const onChangePerUnitArea = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsPerUnitArea("true");
    } else {
      setIsPerUnitArea("false");
    }
    setValue("perUnitArea", e.currentTarget.value);
  };

  const onChangePerPerson = (e) => {
    if (e.currentTarget.value === "" || e.currentTarget.value === 'false') {
      setIsPerPerson("true");
    } else {
      setIsPerPerson("false");
    }
    setValue("perPerson", e.currentTarget.value);
  };

  const InputBillingDate = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('billingDate', onlyNums);
  }

  const InputDueDate = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('dueDate', onlyNums);
  }

  const InputAmount = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('rateInterestPA', onlyNums);
  }

  const InputFixAmount = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('fixAmount', onlyNums);
  }

  const InputPA = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('lateInterestPA', onlyNums);
  }

  const InputFixA = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('lateFixAmount', onlyNums);
  }

  const onNext = (e) => {
    setActiveTab(3);
  }

  const onReset = () => {
    reset();
    window.location.reload();
  }
  const onDayBaseValue = (e) => {
    const Value = e.currentTarget.value;
    if (Value.toUpperCase() === 'DAY_BASE') {
      setIsDayBase(true);
    } else {
      setIsDayBase(false);
    }

  }
  return (
    <div className={styles.SocietyInfo}>
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
      <div className={styles.Form_inline}>
        <input
          type8="text"
          value={societyId}
          name="SocietyId"
          ref={register}
          hidden
        />

        <label className={styles.LabelWidth200}> Billing Type:</label>
        <select
          className="SelectMediumWidth"
          name="billingType"
          ref={register}
          autoFocus={focusValue}
        >
          <option>Advance</option>
          <option>Regular</option>
        </select>

        <label className={styles.LabelWidth200}> Billing Cycle:</label>
        <select
          className="ShortTextBox"
          name="billingCycle"
          ref={register}
        >
          <option>Monthly</option>
          <option>Quarterly</option>
          <option>Half-Yearly</option>
          <option>Yearly</option>
        </select>
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}> Billing Date(Day):</label>
        <input
          type="text"
          className="ShortTextBox"
          name="billingDate"
          ref={register}
          onInput={InputBillingDate}
          autoComplete="off"
        />

        <label className={styles.LabelWidth200}  >Due Date(Method):</label>

        
        <select
          className="selectStyle"
          style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
          onChange={onDayBaseValue}  ref={register} name="BillBasedType"
        >

          <option hidden value="">Select</option>
          <option>Day_Base</option>
          <option>Month_Base</option>
          {/* <option>Month End </option>
          <option>2nd Month Half</option>
          <option>2nd Month End</option>
          <option>3rd Month Half</option>
          <option>3rd Month End</option> */}

        </select>
        {!isDayBase &&
          <div style={{display:'flex'}}>
               <label className={styles.LabelWidth200} style={{width:'80px'}}>Month:</label>
             <select
              className="selectStyle"
              style={{ width: '50px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              name="dueDateMonth"  ref={register}
            >

              <option hidden value="">Select Month</option>
              <option>1</option>
              <option>2 </option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>

            </select>
            {/* <label className="LabelWidth50" style={{width:'30px', marginBottom:'1px', textAlign:'top'}}>th</label> */}
            {/* <label className="LabelWidth50" style={{width:'50px', textAlign:'right'}}>AND</label> */}
            <select
              className="selectStyle"
              style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
              name="monthStartHalfEnd"  ref={register}
            >

              <option hidden value="">Select Type</option>
              <option>Month_Start</option>
              <option>Month_Half</option>
              <option>Month_End </option>
           

            </select>
           
          </div>
        }

        {isDayBase &&
          <div style={{ display: isDayBase ? "block" : "none" }}>
            <input type="text"
              name="dueDate"
              className="ShortTextBox" style={{ marginLeft: "5px" }}
              ref={register}
              onInput={InputDueDate}
              autoComplete="off"
            />
          </div>
        }

      </div>


      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}>Interest Calc.Type:</label>
        <select
          className="SelectMediumWidth"
          name="interestType"
          ref={register}
          onChange={onCalculationType}
        >
          <option>Simple Interest</option>
          <option> Compound Interest</option>
          <option>Fixed Amount </option>
          <option>Fixed Amount Multiplied</option>
        </select>

        <div
          style={{
            display:
              isCalcType !== "Fixed Amount" &&
                isCalcType !== "Fixed Amount Multiplied"
                ? "block"
                : "none",
          }}
        >
          <label className={styles.LabelWidth200}> Rate Interest P.A:</label>&nbsp;&nbsp;
          <input
            type="text"
            className="ShortTextBox"
            name="rateInterestPA"
            ref={register}
            onInput={InputAmount}
            autoComplete="off"
          />
        </div>

        <div className={styles.Form_inline}
          style={{
            display:
              isCalcType !== "Simple Interest" &&
                isCalcType !== "Compound Interest"
                ? "block"
                : "none",
          }}
        >
          <label className={styles.LabelWidth200}> Fix Amount:</label>

          <input
            type="text"
            className="ShortNumberTextBox"
            name="fixAmount"
            ref={register}
            onInput={InputFixAmount}
          />
        </div>
      </div>
      <Divider />

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}>
          Late Payment Calc.Type:
        </label>
        <select
          className="SelectMediumWidth"
          name="latePaymentType"
          ref={register}
          onChange={onLatePaymentType}
        >
          <option>Simple Interest</option>
          <option> Compound Interest</option>
          <option>Fixed Amount </option>
          <option>Fixed Amount Multiplied</option>
        </select>

        <div className={styles.Form_inline}
          style={{
            display:
              isLatePaymentType !== "Fixed Amount" &&
                isLatePaymentType !== "Fixed Amount Multiplied"
                ? "block"
                : "none",
          }}
        >
          <label className={styles.LabelWidth200}> Rate Interest P.A:</label>&nbsp;&nbsp;
          <input
            type="text"
            className="ShortTextBox"
            name="lateInterestPA"
            ref={register}
            onInput={InputPA}
            autoComplete="off"
          />
        </div>

        <div className={styles.Form_inline}
          style={{
            display:
              isLatePaymentType !== "Simple Interest" &&
                isLatePaymentType !== "Compound Interest"
                ? "block"
                : "none",
          }}
        >
          <label className={styles.LabelWidth200}> Fix Amount:</label>

          <input visible="false"
            type="text"
            className={styles.ShortNumberTextBox}
            name="lateFixAmount"
            ref={register}
            onInput={InputFixA}
          />
        </div>
        <label className={styles.LabelWidth200}>Delay Calc. Method:</label>
        <select
          className="SelectMediumWidth"
          name="delayCalcMethod"
          ref={register}
        >
          <option>None</option>
          <option>DelayDays</option>
          <option>DelayMonths</option>
        </select>
      </div>
      <Divider />

      <div >
        <h5 style={{ marginLeft: "9px", fontSize: "18px", color: "grey" }}>Billing Tariff Base</h5>
      </div>
      <div className={styles.Form_inline}>
        <label className="checkboxLabel" style={{ marginLeft: "9px" }}>Building
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="building"
            ref={register}
            checked={isBuilding}
            onChange={onChangeBuilding}
            value={isBuilding}
          />
        </label>

        <label className="checkboxLabel">Wing
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="wing"
            ref={register}
            checked={isWing}
            onChange={onChangeWing}
            value={isWing}
          />
        </label>

        <label className="checkboxLabel">Unit No
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="unitNo"
            ref={register}
            checked={isUnitNo}
            onChange={onChangeUnitNo}
            value={isUnitNo}
          />
        </label>

        <label className="chkboxlabel">Composition
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="composition"
            ref={register}
            checked={isComposition}
            onChange={onChangeComposition}
            value={isComposition}
          />
        </label>

        <label className="chkboxlabel" >Unit Type
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="unitType"
            ref={register}
            checked={isUnitType}
            onChange={onChangeUnitType}
            value={isUnitType}
          />
        </label>

        <label className="chkboxlabel">Total Area
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="totalArea"
            ref={register}
            checked={isTotalArea}
            onChange={onChangeTotalArea}
            value={isTotalArea}
          />
        </label>

        <label className="chkboxlabel" >Per Unit Area
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="perUnitArea"
            ref={register}
            checked={isPerUnitArea}
            onChange={onChangePerUnitArea}
            value={isPerUnitArea}
          />
        </label>


        <label className="chkboxlabel">Per Person
          <input
            type="checkbox"
            className={styles.Checkbox}
            name="perPerson"
            ref={register}
            checked={isPerPerson}
            onChange={onChangePerPerson}
            value={isPerPerson}
          />
        </label>
      </div>
      <Divider />

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}> Survey No:</label>
        <input
          type="text"
          className="SelectMediumWidth"
          name="surveyNo"
          ref={register}
          autoComplete="off"
        />

        <label style={{ fontSize: '13px' }}> Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="surveyDate"
          ref={register}
          onChange={onChangeSurveyDate}
          value={surveyDate}
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}> Lease Deed No:</label>
        <input
          type="text"
          className="SelectMediumWidth"
          name="leaseDeedNo"
          ref={register}
          autoComplete="off"
        />

        <label style={{ fontSize: '13px' }}> Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="leaseDeedDate"
          value={leaseDeedDate}
          ref={register}
          onChange={onChangeLeaseDeedDate}
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}>Conveyance Deed No:</label>
        <input
          type="text"
          className="SelectMediumWidth"
          name="conveyanceDeedNo"
          ref={register}
          autoComplete="off"
        />

        <label style={{ fontSize: '13px' }}> Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="conveyanceDeedDate"
          ref={register}
          value={conveyanceDeedDate}
          onChange={onChangeConveyanceDeedDate}
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}>Development Agreement:</label>
        <input
          type="text"
          className="SelectMediumWidth"
          name="developAgreement"
          ref={register}
          autoComplete="off"
        />

        <label style={{ fontSize: '13px' }}> Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="developDate"
          ref={register}
          value={developDate}
          onChange={onChangeDevelopDate}
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}>Tri Party Agreement:</label>
        <input
          type="text"
          className="SelectMediumWidth"
          name="triPartyAgreement"
          ref={register}
          autoComplete="off"
        />

        <label style={{ fontSize: '13px' }}> Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="triPartyDate"
          ref={register}
          value={triPartyDate}
          onChange={onChangeTriPartyDate}
        />
      </div>

      <div className={styles.Form_inline}>
        <label className={styles.LabelWidth200}> BCC/OC:</label>
        <input
          type="text"
          className="SelectMediumWidth"
          name="bccOc"
          ref={register}
          autoComplete="off"
        />

        <label style={{ fontSize: '13px' }}> Date:</label>
        <input
          type="date"
          className={styles.ShortWidthDate}
          name="bccOcDate"
          ref={register}
          value={bccOcDate}
          onChange={onChangeBccOcDate}
        />
      </div>

      <div
        className="p-formgroup-inline"
        style={{ justifyContent: "center", marginTop: "5px" }}
      >
        <div className="p-field">
          <Button
            label="SAVE"
            type="submit "
            onClick={handleSubmit(onSubmit)}
            disabled={DisableButton}
          />
        </div>
        <div className="p-field">

          <Button disabled={nextDisabled} label="Next" onClick={onNext} />
        </div>
        <div className="p-field ">
          <Button label="RESET" disabled={DisableButton} onClick={onReset} />
        </div>
      </div>
    </div>
  )
}

export default BillingDetails;
