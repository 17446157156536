/* eslint-disable no-unused-vars */
import logo from './logo.svg';
import './App.css';
import './Nav.css';

import React, { useRef, useContext, useState, useEffect } from 'react';
import { SlideMenu } from 'primereact/slidemenu';
import { Button } from 'primereact/button';
import { SocietyContext } from "./SocietyContext";
import { Avatar, withWidth } from '@material-ui/core';
import { Menubar } from 'primereact/menubar';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import SocietyService from 'Service/SocietyService';





function Nav() {
  //function getbackupDatabase(societyId) {

    //societyService.getbackupDatabase(societyId)
    //.then(data => {
     //   SetbackupDatabase(data);
 
      // });
    // }
 
   
  // Assuming this code is part of a React component
  
  const societyService = new SocietyService();
  //const [BackupDatabase, getBackupDatabase] = useState();
const[BackupDatabase, setBackupDatabase]= useState();

  const societyName = localStorage.getItem('societyName');
  const societyId = localStorage.getItem('societyId');
  var Name = localStorage.getItem('name').toUpperCase();
  const [accessColor, setAccessColor] = useState('orange');
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  var financialYear = sessionStorage.getItem('finYear');
  useEffect(() => {
   // societyService.getSocietyDetails(societyId).then(data => {
    //  SetSocietyDetailsData(data);
   // });
   

  //getting group name
    if (accessLevel == 1) {
      setAccessColor('green');
    }
    else if (accessLevel == 2) {
      setAccessColor('yellow');
    }
    else if (accessLevel == 3) {
      setAccessColor('red');
    }

  });
  const handleBackupClick = (societyId) => {
    getDatabaseData(societyId);
  };
  const getDatabaseData=(societyId)=> {
    societyService.getBackupDatabase(societyId)
        .then(data => {
            setBackupDatabase(data);
        })
}


  const stringAvatar = (Name) => {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${Name.split(' ')[0][0]}${Name.split(' ')[1][0]}`,
    };
  }




  let history = useHistory();
  const menu = useRef(null);
  const items = [
    {
      label: 'Home', icon: 'pi pi-home',
      command: () => { window.location = "/HomePage" }
    },
    {
      label: 'Masters',

      items: [
        {
          label: 'Group Master',
          command: () => { window.location = "/GroupMasterUi" },
        },
        {
          label: 'Sub-Group Master',
          command: () => { window.location = "/SubGroupMasterUi" }
        },
        {
          label: 'GL-Master',
          command: () => { window.location = "/GlMaster" }
        },
        {
          label: 'Society Master',
          command: () => { window.location = "/SocietyMasters" }
        },
        {
          label: 'Flat Master',
          command: () => { window.location = "/Flat_Master" }
        },
        {
          label: 'QuickView',
          command: () => { window.location = "/MaintenanceLedger" }
        },

      ]
    },
    {
      label: 'Transactions',

      items: [
        {
          label: 'Flat',
          command: () => { window.location = "/Transactions/Flat" }
        },


        {
          label: 'MemberReceipt',
          command: () => { window.location = "/Transactions/MemberReceipt" },

        },

        {
          label: 'General Receipt',
          command: () => { window.location = "/Transactions/GeneralReceipt" }
        },
        {
          label: 'Member Receipt Return',
          command: () => { window.location = "/Transactions/MemberReceiptReturn" }
        },
        {
          label: 'General Receipt Return',
          command: () => { window.location = "/Transactions/GeneralReceiptReturn" }
        },
        {
          label: 'Voucher Entry',
          command: () => { window.location = "/Transactions/VoucherEntry" }
        },

        {
          label: 'Credit Debit Note',
          command: () => { window.location = "/Transactions/CreditDebitNote" }
        },
        {
          label: 'Member Opening Balance',
          command: () => { window.location = "/Transactions/MemberOpeningBalance" }
        },

        {
          label: 'Supplementary',
          items: [
            {
              label: 'Supplementary Member Opening Balance',
              command: () => { window.location = "/Transactions/SupplimentaryMemberOpeningBalance" }
            },
            {
              label: 'Generate Supplementary Bill',
              command: () => { window.location = "/Transactions/SupplementaryBillGeneration" }
            },
            {
              label: 'Create Supplementary Bill Generation Plan',
              command: () => { window.location = "/Transactions/SupplementaryBillGenerationPlan" }
            },
            {
              label: 'Supplementary Member Receipt',
              command: () => { window.location = "/Transactions/SupplimentaryMemberReceipt" }
            }
          ]
    },
    {
      label: 'Bank FD',
      command: () => { window.location = "/BankFD" }
    },
  ]

},
{
  label: 'Reports',
    items: [
      {
        label: 'Member Bill',
        command: () => { window.location = "/Reports/MemberBills" }
      },
      {
        label: 'Dues Regular Bills',
        command: () => { window.location = "/Reports/DuesRegularBills" }
      },
      {
        label: 'Dues Advance Bills',
        command: () => { window.location = "/Reports/DuesAdvanceBills" }
      },
      {
        label: 'Supplementary Dues',
        command: () => { window.location = "/Reports/SupplementaryDues" }
      },
      {
        label: 'Dues Reminder',
        command: () => { window.location = "/Reports/DuesReminder" }
      },
      {
        label: 'Dues Summary',
        command: () => { window.location = "/Reports/SummaryDues" }
      },
      {
        label: 'Register Receipt',
        command: () => { window.location = "/Reports/MemberReceiptRegister" }
      },
      {
        label: 'Bill Register',
        command: () => { window.location = "/Reports/BillRegister" }
      },
      {
        label: 'Receipt',
        command: () => { window.location = "/Reports/MemberReceiptReport" }
      },
      {
        label: 'Bank Report',
        command: () => { window.location = "/Reports/BankReport" }
      },
      {
        label: 'Cash Report',
        command: () => { window.location = "/Reports/CashReport" }
      },
      {
        label: 'Payment Register',
        command: () => { window.location = "/Reports/PaymentRegisterReport" }
      },
      {
        label: 'Trial Balance',
        command: () => { window.location = "/Reports/TrailBalance" }
      },
      {
        label: 'GL Report',
        command: () => { window.location = "/Reports/GLReport" }
      },
      {
        label: 'Change Statutory Reports',
        items: [
          {
            label: 'Nomination Register',
            command: () => { window.location = "/Reports/NominationRegister" }
          },
          {
            label: 'J Register',
            command: () => { window.location = "/Reports/JRegister" }
          },
          {
            label: 'I Register',
            command: () => { window.location = "/Reports/IRegister" }
          },
          {
            label: 'Share Register',
            command: () => { window.location = "/Reports/ShareRegister" }
          }
        ]
      }


    ]
},
{
  label: 'Utility',

    items: [
      {
        label: 'Bill Generation', 
        command: () => { window.location = "/Transactions/BillGeneration" }
      },
      {
        label: 'Bill Generation For Individual Flat',
        command: () => { window.location = "/Transactions/BillGenerationForIndividualFlat" }

      },
      {
        label: 'Generated Bills View',
        command: () => { window.location = "/Transactions/GeneratedBillsView" }

      },
      {
        label: 'Committee Member',
        command: () => { window.location = "/CommitteeMember" }
      },
      {
        label: 'EmailSettings',
        command: () => { window.location = "/EmailSettings" }
      },
      {
        label: 'CommunicationSetting',
        command: () => { window.location = "/CommunicationSetting" }
      },
      /* {
        label: 'Transaction Schema Review For Current Fin Year',
        command: () => { window.location = "/TransactionSchemaReview" }
      }, */
      {
        label: 'Transaction Schema Review For Fin Year',
        command: () => { window.location = "/TransactionFinYearSchemaReview" }
      }

    ]
},
{
  label: 'Views',

    items: [
      {
        label: 'Society Views',
        command: () => { window.location = "/SocietyView" },
      },
      {
        label: 'Flat Views',
        command: () => { window.location = "/GridView" },
      },
      {
        label: 'Transaction Views',
        command: () => { window.location = "/TransactionView" }
      },
      {
        label: 'Supplimentary Transaction Views',
        command: () => { window.location = "/SupplimentaryTransactionView" }
      },
      {
        label: 'Users View',
        command: () => { window.location = "/UsersView" }
      },
      {
        label: 'Audit View',
        items: [
          {
            label: 'Flat Audit',

            items: [
              {
                label: 'Personal Audit View',
                command: () => { window.location = "/AuditViews/PersonalAuditView" },
              },
              {
                label: 'Opening Balance Audit View',
                command: () => { window.location = "/AuditViews/OpeningBalanceAuditView" },
              },
              {
                label: 'Property Details Audit View',
                command: () => { window.location = "/AuditViews/PropertyDetailsAuditView" },
              },
              {
                label: 'Nominee Audit View',
                command: () => { window.location = "/AuditViews/NomineeAuditView" },
              }
            ]
          },
          {
            label: 'Group Master Audit',
            command: () => { window.location = "/AuditViews/GroupMasterAuditView" },
          },
          {
            label: 'Sub-Group Master Audit',
            command: () => { window.location = "/AuditViews/Sub_GroupMasterAuditView" },
          },
          {
            label: 'Gl-Master Audit',
            command: () => { window.location = "/AuditViews/GL_MasterAuditView" },
          },
          {
            label: 'Society Audit',
            command: () => { window.location = "/AuditViews/SocietyMasterAuditView" },
          },
          {
            label: 'Transaction Audit',
            command: () => { window.location = "/AuditViews/TransactionAuditView" },
          },
        ]
      },
      {
        label: 'Display Settings View',
        command: () => { window.location = "/DisplayIndexSetting" }
      },
      {
        label: 'View Logs',
        command: () => { window.location = "/ViewLogs" }
      },

    ]
},
{
  label: 'Access Management',

    items: [
{
  label: 'Permission',
    command: () => { window.location = "/Permission" }
},
{
  label: 'Add Role',
    command: () => { window.location = "/AddRole" }
},
{
  label: 'Add Privileges',
    command: () => { window.location = "/Addprivileges" }
},
{
  label: 'User Role',
    command: () => { window.location = "/UserRole" }
},
{
  label: 'Role Privileges',
    command: () => { window.location = "/UserPrivileges" }
},
 ]
    
  },
  {
    label: 'add New Gl',
    command: () => { window.location = "/Addnew_Gl" }
},
{
  label: 'Member Ledger',
  command: () => { window.location = "/MemberLedger" }
},
  ];

const profile = [
  {
    label: 'View Profile', icon: 'pi pi-user',
    command: () => { window.location = "/Profile" }
  },
  {
    label: 'LogOut', icon: 'pi pi-sign-out',
    command: () => { window.location = "/login" },
    
  },
  {
  label: 'Backup',
  icon: 'pi pi-sync',
  command:() => {handleBackupClick(societyId)} 
  }
   
  
]




return (
  <div>
    <div className="Nav">
      <div className="societyName" style={{ marginLeft: '20px' }}>
        {societyName.toUpperCase()} -
        {societyId}
      </div>
      <div style={{ width: '400px', fontFamily: 'monospace', font: 'icon' }}>
        Financial Year-{financialYear}
      </div>
      {/* <div className="UiName">
          {uiName}
        </div> */}
      <SlideMenu ref={menu} model={profile} popup viewportHeight={200} menuWidth={175}></SlideMenu>
      <div className="Menu" >
        <Avatar icon="user" onClick={(event) => menu.current.toggle(event)} style={{ background: accessColor, marginRight: '10px' }}{...stringAvatar(Name)} ></Avatar>

      </div>
    </div>

    <div className="card" style={{ marginBottom: '0px' }}>

      {/* <Menubar model={items}  style={{ backgroundColor: "rgb(175, 152, 162) ", }} /> */}
      <Menubar model={items} style={{ backgroundColor: "rgb(232 217 245 / 35%)", }} />
    </div>
  </div>



);
}

export default Nav;
