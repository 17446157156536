import { Button } from 'primereact/button';
// import '../Report/Register.css';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import SocietyService from '../../../Service/SocietyService';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import '../Register/Register.css';






var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var ShareRegisterData = [];

// class Example extends React.Component {
function ShareRegister({ props }) {

  
 
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const societyService = new SocietyService();
  const [ShareRegisterReportData, SetShareRegisterData] = useState([]);
  ShareRegisterData = ShareRegisterReportData;


  
  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

    societyService.getShareRegisterData(societyId).then(data => {
      var srNo=1;
      data.forEach(d=> {
          d.serialNo=srNo++;
      })
      SetShareRegisterData(data);
    });

  }, []);


  


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (

    <div class="card" style={{ backgroundColor: 'black', height: '45px' }}>
      <div className='p-formgroup-inline'>
        <Button onClick={handlePrint} label="PRINT" style={{ marginLeft: '10px' }} />
      </div>
      <Report ref={componentRef} />


    </div>

  );
}


class Report extends React.Component {
  render() {



    return (

      <div style={{ height: '100%' }}>
        {/* {DuesRegular.map((data, index)=> */}
        <div>
          <div className='SocietyName'>
            <div class="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>
                {societyData[0]?.address}
              </text>
            </div>
            <div class="font">
              <label>Share Register</label>
            </div>
          </div>

          <div>
            <div class="NominationRegisterTable">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%', textAlign: 'center' }}>Sr</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Date of Allotment of share</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Cash Book Folio No</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Share Certificate No</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>No of Shares</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Value of Share Rs.</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Name of Member to whom shares alloted</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Date of transfer</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Cash Book Folio No</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>No Of Share transferred</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Share Certificate No transferred</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Values of Shares transferred</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Name of transferee</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Authority for transfer or refund</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Remarks</th>



                  </tr>
                </thead>
                <tbody>
                  {
                    ShareRegisterData.map((item) => (
                      <tr key={item}>
                        <td>{item.serialNo}</td>
                        <td>{item.dateFormat}</td>
                        <td></td>
                        <td>{item.certificateNo}</td>
                        <td>{item.shareNo}</td>
                        <td>{item.amount}</td>
                        <td>{item.name}</td>
                      </tr>
                    ))
                  }

                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>


    )
  }

};

export default ShareRegister;



