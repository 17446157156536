/* eslint-disable no-const-assign */
import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import './TrialBalance.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../loader.css';
import { Toast } from 'primereact/toast';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
<script src="https://cdn.jsdelivr.net/npm/lodash@4.17.5/lodash.min.js"></script>
var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];

var GridDatas = [];
var fDateOnReport;
var tDateOnReport;
var LData = [];
var AData = [];

var PLData = [];
var iData = [];
var eData = [];
var OpenBalanceData = [];
var LastFinancialYearDate;
var totalCreditSum;
var openingCreditSum;
var totalDebitSum;
var openingDebitSum;
var totalIncomeDebitSum;
var openingIncomeDebitSum;
var totalExpDebitSum;
var openingExpDebitSum;
var openBalDebitSum;
var openBalCreditSum;
var DebitSum;
var CreditSum;
var ClosingBalDebitSum;
var ClosingBalCreditSum;
var rctPayCreditSum;
var rctPayDebitSum;

// class Example extends React.Component {
function TrialBalance({ props }) {


    const toast = useRef(null);
    const location = useLocation();
    const { errors, register, reset, handleSubmit, setValue } = useForm();

    const societyService = new SocietyService();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [trialOpeningBalance, setOpeningBalance] = useState([]);
    var [nFormData, setNFormData] = useState([]);
    const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
    const [GridData, setGridData] = useState([]);
    const [liablityData, setLiablityData] = useState([]);
    LData = liablityData;
    const [assetsData, setAssetsData] = useState([]);
    AData = assetsData;

    const [expenditureData, setExpenditureData] = useState([]);
    eData = expenditureData;


    const [incomeData, setIncomeData] = useState([]);
    iData = incomeData;


    GridDatas = GridData;
    const [rctDebitSum, setRctPayDebitSum] = useState();
    rctPayDebitSum=rctDebitSum;

    const [rctCreditSum, setRctPayCreditSum] = useState();
    rctPayCreditSum=rctCreditSum;

    const [openBalDebit, setOpenBalDebitSum] = useState();
    openBalDebitSum=openBalDebit;

    const [openBalCredit, setOpenBalCreditSum] = useState();
    openBalCreditSum=openBalCredit;

    const [creditPrincipleSum, setCreditSum] = useState();
    CreditSum=creditPrincipleSum;

    const [DebitPrincipleSum, setDebitSum] = useState();
    DebitSum=DebitPrincipleSum;

    const [closeBalCredit, setClosingBalCreditSum] = useState();
    ClosingBalCreditSum=closeBalCredit;

    const [closeBalDebit, setClosingBalDebitSum] = useState();
    ClosingBalDebitSum=closeBalDebit;





    const [Generateloading, setGenerateloading] = useState(undefined);
    const [onView, setOnView] = useState(undefined);
    const [isPrintDisabled, setIsPrintDisabled] = useState(true);


    fDateOnReport = moment(new Date(fromDate)).format('DD-MM-YYYY');
    tDateOnReport = moment(new Date(toDate)).format('DD-MM-YYYY');

    LastFinancialYearDate = moment(fDateOnReport).subtract(1, "days").format("DD-MM-YYYY");
    console.warn(LastFinancialYearDate);
    // LastFinancialYearDate = fDate
    // LastFinancialYearDate = LastFinancialYearDate.format("DD-MM-YYYY");


    useEffect(() => {
        setGenerateloading(false);
        setOnView(false);
        societyService.getSocietyDetails(societyId).then(data => {
            SetSocietyDetailsData(data);

        });

    }, []);

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
        setIsGenerateDisabled(false);
        GridData.splice(0,GridData.length);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
        setIsGenerateDisabled(false);
        GridData.splice(0,GridData.length);


    }
    let fDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
    let tDate = moment(new Date(toDate)).format('YYYY-MM-DD');
    const onGenerate = (e) => {
        setSpinnerVisible(true);
        let undefinedList = [];

        if (fromDate === undefined) {
            undefinedList.push({ label: "select From date" });

        }
        if (toDate === undefined) {
            undefinedList.push({ label: "select To date" });

        }
        let message = "Please";
        undefinedList.forEach(a => {

            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            toast.current.show({ severity: 'warn', sticky: true, detail: message });
        }
        else {
        setGenerateloading(true);
        setIsPrintDisabled(false);

        OpeningBal();

        //get Liabilities data
        societyService.GetLiablitiesData(societyId, fDate, tDate,tableName).then(data => {

            setLiablityData(data);
            openingCreditSum = data && data.reduce((a, v) => a + v.openCredit, 0);
            totalCreditSum = data && data.reduce((a, v) => a + v.currentCredit, 0);
        })

        //getassetsData
        societyService.GetAssetsData(societyId, fDate, tDate,tableName).then(data => {

            setAssetsData(data)
            openingDebitSum = data && data.reduce((a, v) => a + v.openDebit, 0);
            totalDebitSum = data && data.reduce((a, v) => a + v.currentDebit, 0);

        })

        //get Expanditure Data
        societyService.GetExpenditureData(societyId, fDate, tDate,tableName).then(data => {

            setExpenditureData(data)
            openingExpDebitSum = data && data.reduce((a, v) => a + v.openDebit, 0);
            totalExpDebitSum = data && data.reduce((a, v) => a + v.currentDebit, 0);


        })
        //get Income Data
        societyService.GetIncomeData(societyId, fDate, tDate,tableName).then(data => {

            setIncomeData(data)
            openingIncomeDebitSum = data && data.reduce((a, v) => a + v.openCredit, 0);
            totalIncomeDebitSum = data && data.reduce((a, v) => a + v.currentCredit, 0);


        })

        setGenerateloading(false);
        setOnView(true);
        setIsGenerateDisabled(true);
        setSpinnerVisible(false);
    }

    }

    const OpeningBal = () => {
        societyService.getTrialOpeningBalance(societyId, fromDate, toDate,tableName).then(data => {

            setOpeningBalance(data)
            OpenBalanceData = data;
            NFormTrialBalance();

        })
    }

    const NFormTrialBalance = () => {
        setSpinnerVisible(true);
        societyService.getTrialBalanceNFormData(societyId, fromDate, toDate,tableName).then(data => {
            var srNo = 1;
            data.forEach(d => {

                d.serialNo = srNo++;

                let index = OpenBalanceData.findIndex(a => a.glId === d.glId);
                if (index >= 0) {
                    let item = { 'serialNo': d.serialNo, 'glId': d.glId, 'glTitle': d.glTitle, 'debit': d.debit, 'credit': d.credit, 'openBal': OpenBalanceData[index]?.openBal, 'closeBal': OpenBalanceData[index]?.openBal + d.credit - d.debit }
                    GridData.push(item);
                }
                else {
                    let item = { 'serialNo': d.serialNo, 'glId': d.glId, 'glTitle': d.glTitle, 'debit': d.debit, 'credit': d.credit, 'openBal': 0, 'closeBal': 0 + d.credit - d.debit }
                    GridData.push(item);
                }
            })
            setNFormData(data)
            setRctPayDebitSum(data && data.reduce((a, v) => a + v.debit, 0));
            setRctPayCreditSum(data && data.reduce((a, v) => a + v.credit, 0));
            // setOpenBalDebitSum(data.filter(({openBal}) => openBal < 0).reduce((a, v) => a + v.openBal,0));
            setOpenBalDebitSum(GridData.filter(item => item.openBal < 0).reduce((a, v) => a + v.openBal, 0));
            setOpenBalCreditSum (GridData.filter(item => item.openBal > 0).reduce((a, v) => a + v.openBal, 0));
            setDebitSum ( GridData && GridData.reduce((a, v) => a + v.debit, 0));
            setCreditSum ( GridData && GridData.reduce((a, v) => a + v.credit, 0));
            setClosingBalDebitSum (GridData.filter(item => item.closeBal < 0).reduce((a, v) => a + v.closeBal, 0));
            // setClosingBalCreditSum (data.filter(({closeBal}) => closeBal > 0).reduce((a, v) => a + v.closeBal,0));
            setClosingBalCreditSum(GridData.filter(item => item.closeBal > 0).reduce((a, v) => a + v.closeBal, 0));

        })
        setSpinnerVisible(false);
    }


    const TrialBalanceNForm = () => {
        setSpinnerVisible(true);
        handleNFormPrint();
        setSpinnerVisible(false);
    }

    const TrialBalanceRctPay = () => {
        setSpinnerVisible(true);
        handleRctPayPrint();
        setSpinnerVisible(false);
    }

    const BalanceSheet = () => {
        setSpinnerVisible(true);
        handleBalanceSheetPrint();
        setSpinnerVisible(false);
    }

    const ProfitAndLoss = () => {
        setSpinnerVisible(true);
        handleProfitLossPrint();
        setSpinnerVisible(false);
    }

    const NForm = useRef();
    const RctPay = useRef();
    const BalSheet = useRef();
    const ProfitLossAccount = useRef();

    const handleNFormPrint = useReactToPrint({
        content: () => NForm.current,
    });

    const handleRctPayPrint = useReactToPrint({
        content: () => RctPay.current,
    });

    const handleBalanceSheetPrint = useReactToPrint({
        content: () => BalSheet.current,
    });
    const handleProfitLossPrint = useReactToPrint({
        content: () => ProfitLossAccount.current,
    });
    return (
        <div className="TrialBalance">
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <div class="TrialBalanceCard" >
                <div class="ButtonCard" >

                    <label>From:</label>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="date" name="date" className='Date' value={fromDate} onChange={onDateChange} />

                    <label className="label">To:</label>
                    <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                    <input type="date" name="date" className='Date' value={toDate} onChange={onToDateChange} />
                    <Button label="GENERATE" icon="pi pi-arrow-circle-down" disabled={isGenerateDisabled} onClick={onGenerate} style={{ marginLeft: '10px', marginTop: '10px', backgroundColor: 'green' }} />

                    <Button label="TRIAL BALANCE N FORM" disabled={isPrintDisabled} onClick={TrialBalanceNForm} style={{ marginLeft: '10px' }} />
                    {/* <Button onClick={handlePrint} label="Trial Balance Close Balance Only" style={{ marginLeft: '10px' }} /> */}
                    <Button label="TRIAL BALANCE RCT/PAY ONLY" disabled={isPrintDisabled} onClick={TrialBalanceRctPay} style={{ marginLeft: '10px' }} />
                    <Button label="BALANCE SHEET" disabled={isPrintDisabled} onClick={BalanceSheet} style={{ marginLeft: '10px' }} />
                    <Button label="PROFIT AND LOSS ACCOUNT" disabled={isPrintDisabled} onClick={ProfitAndLoss} style={{ marginLeft: '10px' }} />
                    {/* <Button onClick={handlePrint} label="Trial Balance" style={{ marginLeft: '10px' }} /> */}
                    <Button label="GL CLOSE" disabled style={{ marginLeft: '10px', backgroundColor: 'red' }} />
                    {/* <Button onClick={handlePrint} label="Print" icon="pi pi-print" style={{ marginLeft: '10px', backgroundColor: 'green' }} /> */}
                    <div>
                        <div style={{ color: 'red', padding: '10px' }}>{errors.fromDate?.message}  {errors.toDate?.message}</div>

                    </div>
                </div>

                {
                    Generateloading ? (
                        <div className="spinner">
                            <span>Loading...</span>
                            <div className="half-spinner"></div>
                        </div>
                    )
                        :
                        (
                            <>
                                {onView ? (
                                    <>
                                        <div className="TrialBalanceData">
                                            <DataTable className="p-datatable-gridlines" responsiveLayout="scroll" scrollable scrollHeight="60vh" 
                                                value={GridData} emptyMessage="No result found.">
                                                <Column field="glId" header="GL-Id" style={{width:'50px'}} ></Column>
                                                <Column field="glTitle" header="GL Title" style={{width:'100px'}}></Column>
                                                <Column field="openBal" header="Opening Bal" style={{width:'100px'}}></Column>
                                                <Column field="debit" header="Debit" style={{width:'100px'}} ></Column>
                                                <Column field="credit" header="Credit" style={{width:'100px'}}></Column>
                                                <Column field="closeBal" header="Closing Bal" style={{width:'100px'}}   ></Column>

                                            </DataTable>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </>
                        )}
                <div hidden >

                    <Report ref={NForm} />
                    <RctPayTrialBalance ref={RctPay} />
                    <BalanceSheetReport ref={BalSheet} />
                    <ProfitAndLossReport ref={ProfitLossAccount} />

                </div>

            </div>
        </div>
    );

}

const landscape = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: landscape;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 297mm;
      height: 210mm;

    }
  }
`;

const  portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 297mm;

    }
  }
`;






class Report extends React.Component {
    render() {



        return (

            <div>
                <style>{landscape()}</style>

                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        {/* <div class="font">
                            <label>Cash Book Report</label>
                        </div> */}
                        {/* <div class="font">
                            <label>Cash Book For : <text>{BankReportDatas[1]?.bankName}</text></label>
                        </div> */}
                        <div className="dateFont">
                            {/* <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label> */}
                            <label>TRIAL BALANCE From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>


                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Sr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>GlId</th>
                                        <th style={{ width: '350px', textAlign: 'center' }}>GL-Title</th>
                                        <th style={{ width: '200px', textAlign: 'center' }} colSpan="2">Opening Bal</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Dr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Cr</th>
                                        <th style={{ width: '200px', textAlign: 'center' }} colSpan="2">Closing Bal</th>

                                    </tr>
                                    <tr className='headerColor'>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Dr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Cr</th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Dr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Cr</th>
                                    </tr>


                                </thead>

                                {GridDatas.map((Bdata, index) =>

                                    <tbody style={{}}>

                                        {


                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{GridDatas[index]?.serialNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.glId}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{GridDatas[index]?.glTitle}</td>


                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.openBal < 0 ? GridDatas[index]?.openBal?.toFixed(2) : '---'}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.openBal > 0 ? GridDatas[index]?.openBal?.toFixed(2) : '---'}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.debit?.toFixed(2)}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.credit?.toFixed(2)}</td>


                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.closeBal < 0 ? GridDatas[index]?.closeBal?.toFixed(2) : '---'}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.closeBal > 0 ? GridDatas[index]?.closeBal?.toFixed(2) : '---'}</td>



                                                {/* <th></th> */}
                                            </tr>









                                            // ))
                                        }

                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>Total</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}></td>


                                                <th style={{ textAlign: 'right', padding: '5px' }}>{openBalDebitSum?.toFixed(2)}</th>
                                                <th style={{ textAlign: 'right', padding: '5px' }}>{openBalCreditSum?.toFixed(2)}</th>
                                                <th style={{ textAlign: 'right', padding: '5px' }}>{DebitSum?.toFixed(2)}</th>
                                                <th style={{ textAlign: 'right', padding: '5px' }}>{CreditSum?.toFixed(2)}</th>


                                                <th style={{ textAlign: 'right', padding: '5px' }}>{ClosingBalDebitSum?.toFixed(2)}</th>
                                                <th style={{ textAlign: 'right', padding: '5px' }}>{ClosingBalCreditSum?.toFixed(2)}</th>



                                                {/* <th></th> */}
                                            </tr>
                            </table>

                        </div>

                    </div>

                </div>

            </div>



        )
    }

};

class RctPayTrialBalance extends React.Component {
    render() {



        return (

            <div>


                <div>
                <style>{portrait()}</style>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        {/* <div class="font">
                            <label>Cash Book Report</label>
                        </div> */}
                        {/* <div class="font">
                            <label>Cash Book For : <text>{BankReportDatas[1]?.bankName}</text></label>
                        </div> */}
                        <div className="dateFont">
                            {/* <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label> */}
                            <label>TRIAL BALANCE From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>


                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Sr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>GlId</th>
                                        <th style={{ width: '350px', textAlign: 'center' }}>GL-Title</th>

                                        <th style={{ width: '100px', textAlign: 'center' }}>Dr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Cr</th>


                                    </tr>

                                </thead>
                                {GridDatas.map((Bdata, index) =>

                                    <tbody style={{}}>

                                        {


                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{GridDatas[index]?.serialNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.glId}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{GridDatas[index]?.glTitle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.debit.toFixed(2)}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{GridDatas[index]?.credit.toFixed(2)}</td>

                                            </tr>









                                            // ))
                                        }

                                    </tbody>
                                )}
                                    <tr className='headerColor'>
                                        <th style={{ textAlign: 'left', padding: '5px' }}>Total</th>
                                        <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                        <td style={{ textAlign: 'left', padding: '5px' }}></td>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{rctPayDebitSum?.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{rctPayCreditSum?.toFixed(2)}</th>

                                    </tr>
                            </table>

                        </div>

                    </div>

                </div>

            </div>



        )
    }

};

class BalanceSheetReport extends React.Component {
    render() {



        return (

            <div>
                <style>{landscape()}</style>
                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>

                        <div className="dateFont">
                            {/* <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label> */}
                            <label>TRIAL BALANCE From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>


                    <div>
                        <div class="DuesRegularTable">
                            <div className='inline'>
                                <table style={{ width: '50%', float: 'left'}}>
                                    <thead style={{ border: "2px solid", }}>
                                        <tr className='headerColor'>
                                            <th style={{ width: '100px', textAlign: 'center' }}>{LastFinancialYearDate}</th>
                                            <th style={{ width: '350px', textAlign: 'center' }}>Liabilities</th>
                                            <th style={{ width: '100px', textAlign: 'center' }}>Sub-Total</th>
                                            <th style={{ width: '100px', textAlign: 'center' }} >Total</th>
                                        </tr>


                                    </thead>
                                    {LData.map((d, index) =>


                                        <tbody>


                                            <tr >
                                                <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                                <th style={{ textAlign: 'Center', padding: '5px' }}> {LData[index]?.name}</th>
                                                <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{LData[index]?.currentCredit}</td>




                                            </tr>

                                            {LData[index].creditDebitInfoGlwise.map((data, i) =>
                                                <tr >

                                                    <td style={{ textAlign: 'right', padding: '5px' }}>{LData[index]?.creditDebitInfoGlwise[i]?.openCredit}</td>
                                                    <td style={{ textAlign: 'left', padding: '5px' }}>{LData[index]?.creditDebitInfoGlwise[i]?.name}</td>
                                                    <td style={{ textAlign: 'right', padding: '5px' }}>{LData[index]?.creditDebitInfoGlwise[i]?.currentCredit}</td>
                                                    <td style={{ textAlign: 'right', padding: '5px' }}> </td>
                                                </tr>
                                            )}




                                        </tbody>
                                    )}
                                    <tr className='headerColor'>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{openingCreditSum}</th>
                                        <th style={{ textAlign:'center'}}> -------Total ------ </th>
                                        <td></td>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{totalCreditSum}</th>

                                    </tr>
                                   
                                </table>
                               

                                <table style={{ width: '50%' }}>
                                    <thead style={{ border: "2px solid" }}>
                                        <tr className='headerColor'>
                                            <th style={{ width: '100px', textAlign: 'center' }}>{LastFinancialYearDate}</th>
                                            <th style={{ width: '350px', textAlign: 'center' }}>Assets</th>
                                            <th style={{ width: '100px', textAlign: 'center' }}>Sub-Total</th>
                                            <th style={{ width: '100px', textAlign: 'center' }} >Total</th>

                                        </tr>


                                    </thead>
                                    {AData.map((Bdata, index) =>


                                        <tbody style={{}}>

                                            <tr>
                                                <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                                <th style={{ textAlign: 'Center', padding: '5px' }}> {AData[index]?.name}</th>
                                                <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}> {AData[index]?.currentDebit}</td>




                                            </tr>

                                            {AData[index].creditDebitInfoGlwise.map((data, i) =>
                                                <tr>

                                                    <td style={{ textAlign: 'right', padding: '5px' }}>{AData[index]?.creditDebitInfoGlwise[i]?.openDebit}</td>
                                                    <td style={{ textAlign: 'left', padding: '5px' }}>{AData[index]?.creditDebitInfoGlwise[i]?.name}</td>
                                                    <td style={{ textAlign: 'right', padding: '5px' }}>{AData[index]?.creditDebitInfoGlwise[i]?.currentDebit}</td>
                                                    <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                                </tr>
                                            )}

                                        </tbody>
                                    )}
                                    <tr className='headerColor'>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{openingDebitSum}</th>
                                        <th style={{ textAlign:'center'}}> -------Total ------ </th>
                                        <td></td>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{totalDebitSum}</th>

                                    </tr>
                                </table>

                                
                            </div>

                        </div>

                    </div>

                </div>

            </div>



        )
    }

};

class ProfitAndLossReport extends React.Component {
    render() {



        return (

            <div>
                <style>{landscape()}</style>

                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>

                        <div className="dateFont">
                            <label>TRIAL BALANCE From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>


                    <div>
                        {/* <div class="DuesRegularTable"> */}
                        <div className='inline'>

                            <table style={{ width: '50%', float: 'left' }}>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '100px', textAlign: 'center' }}>{LastFinancialYearDate}</th>
                                        <th style={{ width: '350px', textAlign: 'center' }}>Expenditure </th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Sub-Total</th>
                                        <th style={{ width: '100px', textAlign: 'center' }} >Total</th>
                                    </tr>


                                </thead>
                                {eData.map((d, index) =>


                                    <tbody>


                                        <tr>
                                            <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                            <th style={{ textAlign: 'Center', padding: '5px' }}> {eData[index]?.name}</th>
                                            <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                            <td style={{ textAlign: 'right', padding: '5px' }}>{eData[index]?.currentDebit}</td>




                                        </tr>

                                        {eData[index].creditDebitInfoGlwise.map((data, i) =>
                                            <tr>

                                                <td style={{ textAlign: 'right', padding: '5px' }}>{eData[index]?.creditDebitInfoGlwise[i]?.openDebit}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{eData[index]?.creditDebitInfoGlwise[i]?.name}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{eData[index]?.creditDebitInfoGlwise[i]?.currentDebit}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}> </td>
                                            </tr>
                                        )}













                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{openingExpDebitSum}</th>
                                        <th style={{ textAlign:'center'}}> -------Total ------ </th>
                                        <td></td>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{totalExpDebitSum}</th>

                                    </tr>
                            </table>

                            <table style={{ width: '50%' }}>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '100px', textAlign: 'center' }}>{LastFinancialYearDate}</th>
                                        <th style={{ width: '350px', textAlign: 'center' }}>Income</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Sub-Total</th>
                                        <th style={{ width: '100px', textAlign: 'center' }} >Total</th>

                                    </tr>


                                </thead>
                                {iData?.map((Bdata, index) =>


                                    <tbody style={{}}>

                                        <tr>
                                            <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                            <th style={{ textAlign: 'Center', padding: '5px' }}> {iData[index]?.name}</th>
                                            <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                            <td style={{ textAlign: 'right', padding: '5px' }}> {iData[index]?.currentCredit}</td>




                                        </tr>

                                        {iData[index].creditDebitInfoGlwise.map((data, i) =>
                                            <tr>

                                                <td style={{ textAlign: 'right', padding: '5px' }}>{iData[index]?.creditDebitInfoGlwise[i]?.openCredit}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{iData[index]?.creditDebitInfoGlwise[i]?.name}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{iData[index]?.creditDebitInfoGlwise[i]?.currentCredit}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                            </tr>
                                        )}

                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{openingIncomeDebitSum}</th>
                                        <th style={{ textAlign:'center'}}> -------Total ------ </th>
                                        <td></td>
                                        <th style={{ textAlign: 'right', padding: '5px' }}>{totalIncomeDebitSum}</th>

                                    </tr>
                            </table>

                        </div>
                        {/* </div> */}

                    </div>

                </div>

            </div>



        )
    }

};

export default TrialBalance;





