import React from "react";

import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "@material-ui/core";
import axios from "axios";
import SocietyService from "../../Service/SocietyService";
import Config from "../../Config.json";
import authHeader from "../../authHeader";
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import styles from "./SupplimentaryBillSettings.css";

function SupplimentaryBillSettings({ societyId, id, setActiveTab }) {
    let history = useHistory();
    const toast = useRef(null);

    const societyService = new SocietyService();
    //const isAddMode = !id;
    const [isAddMode, setAddMode] = useState(!id);
    const [billingDetails, SetBillingDetails] = useState([]);
    const [isCalcType, setIsCalcType] = useState("Simple Interest");
    const [isLatePaymentType, setIsLatePaymentType] = useState("Simple_Interest");
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [selectedInterestType, setSelectedInterestType] = useState("");
    const [interestRate, setInterestRate] = useState();
    const [fixedAmount, setFixedAmount] = useState();
    const { errors, register, reset, handleSubmit, setValue } = useForm();

    useEffect(() => {

        societyService.GetSupplimentaryBillSetting(societyId).then(response => {

            if (response != undefined) {
                setSelectedInterestType(response.interestType);
                setValue('fixAmount',response.fixAmount);
                setValue('rateInterestPA',response.rateInterestPA);
                 setValue("interestType",response.interestType);
                 setIsCalcType(response.interestType);
            }
        },
            error => {

            }
        )
    },[])
    const onSubmit = (data, e) => {
        return updateBillingDetails(data);
    };
    const InputAmount = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('rateInterestPA', onlyNums);
    }

    const InputFixAmount = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('fixAmount', onlyNums);
    }

    const onCalculationType = (e) => {
        const IsCalcType = e.currentTarget.value;
        setIsCalcType(IsCalcType);
         setValue("interestType",IsCalcType);
        setSelectedInterestType(IsCalcType);
    };

    const updateBillingDetails = (data, e) => {

        if (isCalcType === "Simple_Interest" && "Compound_Interest") {
            data.fixAmount = -1;
        }
        if (isLatePaymentType === "Fixed_Amount") {
            data.rateInterestPA = -1;
        }
        if (data.rateInterestPA === "" || data.rateInterestPA === undefined) {
            data.rateInterestPA = -1;
        }

        if (data.fixAmount === "" || data.fixAmount === undefined) {
            data.fixAmount = -1;
        }
        if (data.lateFixAmount === "" || data.lateFixAmount === undefined) {
            //  data.lateFixAmount = -1;
        }
        data.societyId = societyId;
        if (data.societyId === undefined || societyId <= 0 || isNaN(data.societyId)) {
            toast.current.show({ severity: 'error', sticky: true, content: 'Society is not set' });
            return;
        }
        if (((isCalcType === "Simple_Interest") || (isCalcType === "Compound_Interest")) && data.rateInterestPA === -1) {
            toast.current.show({ severity: 'error', sticky: true, content: 'Interest Rate Value is not set' });
            return;
        }
        if ((isCalcType === "Fixed_Amount" && data.fixAmount === -1)) {
            toast.current.show({ severity: 'error', sticky: true, content: 'Fixed Amount is not set' });
            return;
        }

        setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/SupplimentaryBillGeneration/UpdateSupBillSettings?societyId=${data.societyId}`, data, { headers: authHeader() })
            .then((response) => {
                console.warn(data);
                console.log(response);
                if (response.data != null) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });

                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    };

    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div className='SupplimentaryBill'>
                <div className="p-formgroup-inline">
                    <label className="LabelWidth200">Interest Calc.Type:</label>
                    <select
                       value={selectedInterestType}
                        className="SelectMediumWidth"
                        name="interestType"
                        ref={register}
                        onChange={onCalculationType}
                    >
                        <option>None</option>
                        <option>Simple_Interest</option>
                        <option> Compound_Interest</option>
                        {/* <option>Fixed_Amount</option> */}
                        {/* <option>Fixed Amount Multiplied</option> */}
                    </select>
                </div>
                <div className="p-formgroup-inline" style={{ display: (isCalcType === "Compound_Interest" || isCalcType === "Simple_Interest" )? "block" : "none" }}>
                    <label className="LabelWidth200"> Rate Interest P.A:</label>
                    <input
                        type="text"
                        className="ShortTextBox"
                        name="rateInterestPA"
                        ref={register}
                        onInput={InputAmount}
                        autoComplete="off"
                    />
                </div>
                {/* <div className="p-formgroup-inline" style={{ display:  isCalcType === "Fixed_ Amount" ? "block" : "none" }}                >
                    <label className="LabelWidth200"> Fix Amount:</label>
                    <input
                        type="text"
                        className="ShortNumberTextBox"
                        name="fixAmount"
                        ref={register}
                        onInput={InputFixAmount}
                    />
                </div> */}

                <div
                    className="p-formgroup-inline"
                    style={{ marginTop: "5px", marginLeft: "300px" }}
                >
                    <div className="p-field">
                        <Button
                            label="SAVE"
                            type="submit "
                        // onClick={handleSubmit(onSubmit)}
                        // disabled={DisableButton}
                        />

                    </div>
                </div>
            </div>

        </form>
    );
};

export default SupplimentaryBillSettings;