// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-dupe-class-members */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Config from '../Config.json';
import authHeader from '../authHeader';


class SocietyService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            FlatId: null
        };

    }

    getFinancialYearsData(societyId){
        return axios.get(`${Config.apiUrl}/Authentication/GetFinancialYears?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    GetSocietyFinYearTransObjects(societyId,societyCode,yearValue,shortYearValue){
        return axios.get(`${Config.apiUrl}/Schema/GetSocietyFinYearTransObjects?societyCode=${societyCode}&societyId=${societyId}&yearValue=${yearValue}&shortYearValue=${shortYearValue}`, { headers: authHeader() }).then(res => res.data);
    }

    getTransactionTables(schema,tablePrefix){
        return axios.get(`${Config.apiUrl}/Schema/GetTableNames?schemaName=${schema}&prefix=${tablePrefix}`, { headers: authHeader() }).then(res => res.data);
    }

    getTransactionTableTriggers(schema,tableName){
        return axios.get(`${Config.apiUrl}/Schema/GetTriggerNames?schemaName=${schema}&tableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getOtp(username) {
        return axios.get(`${Config.apiUrl}/Authentication/GetOtp?userName=${username}`).then(res => res.data);
    }

    //getting group master grid data
    getGroupMasterData(societyId) {
        return axios.get(`${Config.apiUrl}/GroupMaster/id=2?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    //gettig group name
    getGroupName(societyId) {
        return axios.get(`${Config.apiUrl}/GroupMaster/id=3?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getGroupId(societyId) {
        return axios.get(`${Config.apiUrl}/GroupMaster/id=1?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    //post group master data


    //post subGroupMasterData

    postSubGroupMaster(data) {
        axios.post(`${Config.apiUrl}/SubGroupMaster`, { headers: authHeader() }, data)
            .then(response => {
                console.log(response)
                if (response.data != null) {
                    alert(response.data[0].msg);
                }
                else {
                    alert(response.data[0].msg);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    //get sub groupId
    getSubGroupId(societyId) {
        return axios.get(`${Config.apiUrl}/SubGroupMaster/id=2?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getSubGroupMasterData(societyId) {
        return axios.get(`${Config.apiUrl}/SubGroupMaster/id=1?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }


    //getting sub group name
    getSubGroupName(societyId, selectedGroupName) {
        return axios.get(`${Config.apiUrl}/SubGroupMaster/id=3?societyId=${societyId}&GroupName=${selectedGroupName}`, { headers: authHeader() }).then(res => res.data);
    }

    //getiing Gl master id
    getGlMasterId(societyId) {
        return axios.get(`${Config.apiUrl}/GlMaster/id=1?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getGlMasterIsBilling(societyId) {
        return axios.get(`${Config.apiUrl}/Maintainance/Get?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getMaintainanceDetailsOnID(id, societyId) {
        return axios.get(`${Config.apiUrl}/Maintainance/GetOnId?id=${id}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getGlMasterOnID(id) {
        return axios.get(`${Config.apiUrl}/GlMaster/GetOnId?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }

    //getting gl master data

    getGlMasterData(societyId) {
        return axios.get(`${Config.apiUrl}/GlMaster/GetGlMasterData?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getBankFds(societyId) {
        return axios.get(`${Config.apiUrl}/BankFD/Get?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getBankFdGls(societyId,subGroupName) {
        return axios.get(`${Config.apiUrl}/BankFD/GetFDGlMasterTitle?societyId=${societyId}&bankFDSubGroup=${subGroupName}`, { headers: authHeader() }).then(res => res.data);
    }

    getPersonalDetailsOnID(id) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/get?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }

    getMaxFlatNo(id, wing) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetMaxFlatNo?id=${id}&wing=${wing}`, { headers: authHeader() }).then(res => res.data);
    }
    getPersonalDetails(societyId,tableName) {
        return axios.get(`${Config.apiUrl}/PersonalDetails?societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    //new add050224
    geNewGlHeadDetails(societyId) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/NewGlHeadDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getWing(id) {
        return axios.get(`${Config.apiUrl}/WingMaster/GetData?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }
    getMaxShares(societyId) {
        return axios.get(`${Config.apiUrl}/PropertyDetails/GetMaxShares?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getPropertyDetailsOnID(id) {
        return axios.get(`${Config.apiUrl}/PropertyDetails/get?id=${id}`, { headers: authHeader() }).then(res => res.data);

    }
    getOpeningBalanceOnID(id) {
        return axios.get(`${Config.apiUrl}/OpeningBalance/get?id=${id}`, { headers: authHeader() }).then(res => res.data);

    }
    getNomineeDetailsOnID(id) {
        return axios.get(`${Config.apiUrl}/NomineeDetails/get?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }
    getTenantDetailsOnID(id) {
        return axios.get(`${Config.apiUrl}/TenantDetails/get?id=${id}`, { headers: authHeader() }).then(res => res.data);

    }
    getTenantDetails(societyId) {
        return axios.get(`${Config.apiUrl}/TenantDetails/GetTenantDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }
    getAllSocietyDetails() {
        return axios.get(`${Config.apiUrl}/SocietyDetails/GetAllSociety`, { headers: authHeader() }).then(res => res.data);
    }

    GetSocietyCommunicationDetails() {
        return axios.get(`${Config.apiUrl}/SocietyCommunication/Get`, { headers: authHeader() }).then(res => res.data);
    }

    getSocietyDetails(societyId) {
        return axios.get(`${Config.apiUrl}/SocietyDetails/Get?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getSocietyDetailsOnID(id) {
        return axios.get(`${Config.apiUrl}/SocietyDetails/GetData?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }

    getBillingDetailsOfSociety(id) {
        return axios.get(`${Config.apiUrl}/BillingDetails/GetData?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }
    getGeneratedBillsHistory(societyId) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getGeneratedBillHistory?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getWingDetailsOnID(id) {
        return axios.get(`${Config.apiUrl}/WingMaster/GetData?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }

    getsocietyName() {
        return axios.get(`${Config.apiUrl}/Authentication/GetsocietyName`, { headers: authHeader() }).then(res => res.data);

    }

    getTransactionDetails(fromDate, toDate, glId, societyId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetTransactionDetails?fromDate=${fromDate}&toDate=${toDate}&glId=${glId}&societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }
    //new member
    getMemberLedgerDetails(fromDate, toDate, societyId,tableName,flatId) {
        return axios.get(`${Config.apiUrl}/Transaction/GetMemberLedger?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}&transactionTableName=${tableName}&flatId=${flatId}`, { headers: authHeader() }).then(res => res.data);

    }

    getTransactionDetailsRecordsCount(fromDate, toDate, glId, societyId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetTransactionsRecordCount?fromDate=${fromDate}&toDate=${toDate}&glId=${glId}&societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }


    getTransactionDetailsOnID(id,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetGeneralReceiptDataOnID?id=${id}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getBillDetailsForBillNo(billNo, societyId, flatId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetBillDetails?societyId=${societyId}&billNo=${billNo}&flatId=${flatId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getMemberReceiptDetailsOnID(id,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetMemberReceiptDataOnID?id=${id}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    GetMemberReceiptOnRctNo(societyId, rctNo,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetMemberReceiptOnRctNo?societyId=${societyId}&rctNo=${rctNo}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getMRReturnEntryDetailsOnID(id,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetMRReturnEntryDataOnID?id=${id}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getGRReturnEntryDetailsOnID(id,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetGRReturnEntryDataOnID?id=${id}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getVoucherEntryDetailsOnID(id,tableName,societyId) {
        return axios.get(`${Config.apiUrl}/Transaction/GetVoucherEntryDataOnID?id=${id}&transactionTableName=${tableName}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getCreditDebitNoteOnID(societyId, jeNo,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetCreditDebitNoteDataOnID?societyId=${societyId}&jeNo=${jeNo}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getRegisterUserDetails(societyId) {
        return axios.get(`${Config.apiUrl}/Authentication/GetRegisterUserDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }
    //User Role
    getRolesDetails(id) {
        return axios.get(`${Config.apiUrl}/UserRoles/rolesDetails`, { headers: authHeader() }).then(res => res.data);

    }
    getRolesiDetails(Id) {
        return axios.get(`${Config.apiUrl}/UserRoles/rolesDetails1?Id=${Id}`, { headers: authHeader() }).then(res => res.data);

    }
    getRolesidDetails(userId) {
        return axios.get(`${Config.apiUrl}/UserRoles/userDetails?userId=${userId}`, { headers: authHeader() }).then(res => res.data);

    }
    //backup
    getBackupDatabase(societyId){
        return axios.get(`${Config.apiUrl}/Backup/backup_database?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    //userprivileges
    getprivilegesDetails() {
        return axios.get(`${Config.apiUrl}/UserPrivileges/PrivilegesDetails`, { headers: authHeader() }).then(res => res.data);

    }
    getPrivilegesidDetails(roleId) {
        return axios.get(`${Config.apiUrl}/UserPrivileges/UserPrivilegesDetails?roleId=${roleId}`, { headers: authHeader() }).then(res => res.data);

    }

    getBillData(societyId, billDate,tableName) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getMaintainanceBill?societyId=${societyId}&billingDate=${billDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getBillDataForFlat(societyId,flatId, billDate,tableName) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getMaintainanceBillForFlat?societyId=${societyId}&flatId=${flatId}&billingDate=${billDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    IsBillGenerated(societyId, billDate,tableName) {
        return axios.get(`${Config.apiUrl}/BillingDetails/IsBillGenerated?societyId=${societyId}&billingDate=${billDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getMissingFlatBillGenerated(societyId, billDate,tableName) {
        return axios.get(`${Config.apiUrl}/BillGenerationForIndividualFlat/getMissingFlatBillGenerated?societyId=${societyId}&billingDate=${billDate},transactionTableName=${tableName}`)
    }
    // getWing(societyId) {
    //     return axios.get(`${Config.apiUrl}/BillGeneration/getWing?societyId=${societyId}`,{headers:authHeader()}).then(res => res.data);
    // }
    getFlatNo(Wing, societyId) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getFlatNo?Wing=${Wing}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getName(flatNo, wing, societyId) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getName?flatNo=${flatNo}&wing=${wing}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getFlat(societyId) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getFlat?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getGlTitleOnglId(glId, societyId) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getGlTitle?glId=${glId}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getOwnerName(wing, flatNo) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetOwnerName?flatNo=${flatNo}&wing=${wing}`, { headers: authHeader() }).then(res => res.data);

    }
    getMaxBillNo(societyId,tableName) {
        return axios.get(`${Config.apiUrl}/BillGeneration/GetMaxBillNo?societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }
    getMaxGenerationId(societyId) {
        return axios.get(`${Config.apiUrl}/BillGeneration/GetMaxBillGenerationId?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }
    getWingAndFlatNoData(flatId) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetWingAndFlatNo?flatId=${flatId}`, { headers: authHeader() }).then(res => res.data);
    }

    getScrollNo(date, societyId, type,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetScrollNo?date=${date}&societyId=${societyId}&type=${type}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getJeNo(societyId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetMaxJeNo?societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getScrollNoinfo() {
        return axios.get(`${Config.apiUrl}/ScrollNo/GetScrollNoinfo`, { headers: authHeader() }).then(res => res.data);
    }


    getVoucherEntryDetails(date, societyId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetVoucherEntryDetails?date=${date}&societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getRctNo(societyId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetRctNo?societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getFlatOutstandings(societyId, flatId, date,tableName) {
        return axios.get(`${Config.apiUrl}/BillGeneration/getTransactionValue?societyId=${societyId}&flatId=${flatId}&date=${date}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getMREntry(societyId, date,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetMREntry?societyId=${societyId}&date=${date}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetSocietyBank(societyId, mode) {
        return axios.get(`${Config.apiUrl}/GlMaster/GetSocietyBankName?societyId=${societyId}&subGroupName=${mode}`, { headers: authHeader() }).then(res => res.data);
    }
    getBillFootNote(societyId) {
        return axios.get(`${Config.apiUrl}/BillFootnote/getBillFootNoteOnId?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }
    getGLEntry(societyId, date,tblName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetGLEntry?societyId=${societyId}&date=${date}&transactionTableName=${tblName}`, { headers: authHeader() }).then(res => res.data);
    }

    getGlTitle(societyId) {
        return axios.get(`${Config.apiUrl}/GlMaster/GetGlTitle?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetIsBiilingGls(societyId) {
        return axios.get(`${Config.apiUrl}/GlMaster/GetIsBiilingGls?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getFlatData(societyId, selectedWing, flatNo,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetFlatData?societyId=${societyId}&selectedWing=${selectedWing}&flatNo=${flatNo}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getFlatBalanceHistory(societyId, selectedWing, flatId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetFlatBalanceHistory?societyId=${societyId}&selectedWing=${selectedWing}&flatId=${flatId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }


    getPermissionDetails(societyId) {
        return axios.get(`${Config.apiUrl}/Permission/GetPermissionDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    getUserName(userId) {
        return axios.get(`${Config.apiUrl}/Profile/GetUserName?userId=${userId}`, { headers: authHeader() }).then(res => res.data);
    }

    getGroupMasterAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/GroupMaster/GetGroupMasterAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getSubGroupMasterAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/SubGroupMaster/GetSubGroupMasterAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getSocietyMasterAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/SocietyDetails/GetSocietyMasterAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getGL_MasterAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/GlMaster/GetGL_MasterAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getTransactionAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/Transaction/GetTransactionAudit?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    // getFlatMasterAuditInfo(fromDate,toDate) {
    //     return axios.get(`${Config.apiUrl}/Transaction/GetFlatMasterAuditInfo?fromDate=${fromDate}&toDate=${toDate}`).then(res => res.data);

    // }

    getPersonalAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetPersonalAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getOpeningBalanceAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/OpeningBalance/GetOpeningBalanceAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getPropertyAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/PropertyDetails/GetPropertyAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getNomineeAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/NomineeDetails/GetNomineeAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getMaintainanceAuditInfo(fromDate, toDate, societyId) {
        return axios.get(`${Config.apiUrl}/Maintainance/GetMaintainanceAuditInfo?fromDate=${fromDate}&toDate=${toDate}&societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getMemberDetails(societyId) {
        return axios.get(`${Config.apiUrl}/Permission/GetMemberDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getOName(societyId) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetOName?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }

    getCommitteeMemberDetails(societyId) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetCommitteeMemberDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }
    getMemberReport(societyId, fromDate, toDate, transactionType,tableName,flatId,isRecent) {
        return axios.get(`${Config.apiUrl}/Report/GetMRReport?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionType=${transactionType}&transactionTableName=${tableName}&flatId=${flatId}&isRecent=${isRecent}`, { headers: authHeader() }).then(res => res.data);

    }
    getBillsDate(societyId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getBillsOnBillDate?societyId=${societyId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getSavedMaintainance(isSelectedBill,flatId,societyId, generationId, BillDate,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getSavedMaintainance?isSelectedBill=${isSelectedBill}&&flatId=${flatId}&&societyId=${societyId}&&GenerationId=${generationId}&&BillDate=${BillDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }

    getRegularDues(societyId, date, wing,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getRegularDues?societyId=${societyId}&wing=${wing}&date=${date}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getDuesReminderData(societyId, date, amount,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getDuesReminderData?societyId=${societyId}&date=${date}&amount=${amount}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }
    // getMemberReceiptReport(societyId,date){
    //     return axios.get(`${Config.apiUrl}/Report/getMemberReceiptReport?societyId=${societyId}&date=${date}`).then(res => res.data); 
    // }
    getInstallment(societyId) {
        return axios.get(`${Config.apiUrl}/Report/getInstallmentsData?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);

    }
    getMemberReceiptReport(societyId, fromDate, toDate, wing, transactionType, mode,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getMemberReceiptReport?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&wing=${wing}&transactionType=${transactionType}&mode=${mode}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getBillRegisterReport(societyId, generationId, BillDate,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getBillRegisterReport?societyId=${societyId}&&generationId=${generationId}&&BillDate=${BillDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    // getSavedBill(SocietyId)
    getOpeningBalance(societyId, fromDate, selectedBankGlId, mode,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetOpeningBalance?societyId=${societyId}&fromDate=${fromDate}&glId=${selectedBankGlId}&mode=${mode}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }
    getBankReport(societyId, fromDate, toDate, selectedBankGlId, orderBy,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetBankReportData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&glId=${selectedBankGlId}&orderBy=${orderBy}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }
    getCashReport(societyId, fromDate, toDate, selectedBankGlId,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetCashReportData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&bankGlId=${selectedBankGlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getPaymentRegisterData(societyId, fromDate, toDate, mode,tableName) {
        return axios.get(`${Config.apiUrl}/Transaction/GetPaymentRegisterData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&mode=${mode}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getTrialBalanceNFormData(societyId, fromDate, toDate,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getTialBalanceNFormData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getTrialOpeningBalance(societyId, fromDate, toDate,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getTrialOpeningBalance?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetExpenditureData(societyId, fromDate, toDate,tableName) {

        let hd = authHeader();
        hd['Content-Type'] = 'application/json'
        return axios.get(`${Config.apiUrl}/Report/getExpenditureData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionTableName=${tableName}`, { headers: hd }).then(res => res.data);
    }
    GetIncomeData(societyId, fromDate, toDate,tableName) {

        let hd = authHeader();
        hd['Content-Type'] = 'application/json'
        return axios.get(`${Config.apiUrl}/Report/getIncomeData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionTableName=${tableName}`, { headers: hd }).then(res => res.data);
    }
    GetProfitAndLossData(societyId, fromDate, toDate) {
        return axios.get(`${Config.apiUrl}/Report/getProfitAndLossData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}`, { headers: authHeader() }).then(res => res.data);
    }
    GetLiablitiesData(societyId, fromDate, toDate,tableName) {
        let hd = authHeader();
        hd['Content-Type'] = 'application/json'
        return axios.get(`${Config.apiUrl}/Report/getLiablitiesData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionTableName=${tableName}`, { headers: hd }).then(res => res.data);
    }
    GetAssetsData(societyId, fromDate, toDate,tableName) {

        let hd = authHeader();
        hd['Content-Type'] = 'application/json'
        return axios.get(`${Config.apiUrl}/Report/getAssetsData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&transactionTableName=${tableName}`, { headers: hd }).then(res => res.data);
    }
    getGLReportData(societyId, fromDate, toDate, GlId) {
        return axios.get(`${Config.apiUrl}/Report/getBalanceSheetData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&GlId=${GlId}`, { headers: authHeader() }).then(res => res.data);
    }
    getNominationRegisterData(societyId) {
        return axios.get(`${Config.apiUrl}/Report/getNominationRegisterReport?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getJRegisterData(societyId) {
        return axios.get(`${Config.apiUrl}/Report/getJRegisterReportData?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getIRegisterData(societyId) {
        return axios.get(`${Config.apiUrl}/Report/getIRegisterReportData?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getShareRegisterData(societyId) {
        return axios.get(`${Config.apiUrl}/Report/getShareRegisterReportData?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }

    sendMail(subject, toAddress, body) {
        return axios.get(`${Config.apiUrl}/Email?subject=${subject}&toAddress=${toAddress}&body=${body}`, { headers: authHeader() }).then(res => res.data);
    }

    sendMailTest(subject, toAddress, body) {
        return axios.get(`${Config.apiUrl}/Email?subject=test&toAddress=ranawarenitin1@gmail.com,uday.ghadage@gmail.com,nikeshghadage312@gmail.com&body=This is Test mail from our Society API`, { headers: authHeader() }).then(res => res.data);
    }
    getEmail(societyId) {
        return axios.get(`${Config.apiUrl}/SocietyDetails/GetEmailId?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetMemberOpeningBalance(societyId, date,tableName) {
        return axios.get(`${Config.apiUrl}/PersonalDetails/GetMemberOpeningBalance?societyId=${societyId}&Date=${date}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetSupplimentaryBillPlans(societyId) {
        return axios.get(`${Config.apiUrl}/SupplimentaryBillGeneration/GetSupplimentaryBillPlans?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetSupplimentaryBillSetting(societyId) {
        return axios.get(`${Config.apiUrl}/SupplimentaryBillGeneration/GetSupBillSettings?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetSupplimentaryMemberReceipt(societyId) {
        return axios.get(`${Config.apiUrl}/SupplimentaryMemberReceipt/GetSupplimentaryMemberReceipt?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetSupplimentaryMemberOpeningBalance(societyId,tableName,billPlanId) {
        return axios.get(`${Config.apiUrl}/SupplimentaryBillGeneration/GetSupplimentaryOpeningBalance?societyId=${societyId}&transactionTable=${tableName}&billPanId=${billPlanId}`, { headers: authHeader() }).then(res => res.data);
    }

    getSupplimentaryFlatOutstandings(societyId, flatId, date,tableName,billPlanId) {
        return axios.get(`${Config.apiUrl}/SupplimentaryBillGeneration/getTransactionValue?societyId=${societyId}&flatId=${flatId}&date=${date}&transactionTableName=${tableName}&billPlanId=${billPlanId}`, { headers: authHeader() }).then(res => res.data);
    }

    IsSupplimentaryBillGenerated(societyId, billDate,tableName,billPlanId) {
        return axios.get(`${Config.apiUrl}/SupplimentaryBillGeneration/IsBillGenerated?societyId=${societyId}&billingDate=${billDate}&transactionTableName=${tableName}&billPlanId=${billPlanId}`, { headers: authHeader() }).then(res => res.data);
    }
    getGlGridData(societyId, fromDate, toDate, GlId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getGlGridData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&glId=${GlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    getGlOpeningBalance(societyId, fromDate, GlId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/GetGlOpeningBalance?societyId=${societyId}&fromDate=${fromDate}&glId=${GlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);

    }
    GetDetailData(societyId, fromDate, toDate, GlId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getDetailData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&glId=${GlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetDailyData(societyId, fromDate, toDate, GlId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getDailyData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&glId=${GlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetMonthlyData(societyId, fromDate, toDate, GlId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getMonthlyData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&glId=${GlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetVehicleGridData(societyId, flatId) {
        return axios.get(`${Config.apiUrl}/Vehicle/getVehicleGridData?societyId=${societyId}&flatId=${flatId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetVehicleDetailsOnId(id) {
        return axios.get(`${Config.apiUrl}/Vehicle/getVehicleDetailsOnId?id=${id}`, { headers: authHeader() }).then(res => res.data);
    }
    GetQuartelyData(societyId, fromDate, toDate, GlId,tableName) {
        return axios.get(`${Config.apiUrl}/Report/getQuartelyData?societyId=${societyId}&fromDate=${fromDate}&toDate=${toDate}&glId=${GlId}&transactionTableName=${tableName}`, { headers: authHeader() }).then(res => res.data);
    }
    GetLogData(){
        return axios.get(`${Config.apiUrl}/Log/getLogFileData`, { headers: authHeader() }).then(res => res.data);
    }
    getMissingListOfFlatsForBillDate(societyId,firstDay,tableName){
        return axios.get(`${Config.apiUrl}/BillGenerationForIndividualFlat/GetMissingListOfFlatsForBillDate?societyId=${societyId}&billingDate=${firstDay}&transactionTableName=${tableName}`, {headers: authHeader()}).then(res => res.data);
    }
    getDetailsOfMaintenance(societyId){
        return axios.get(`${Config.apiUrl}/Maintainance/GetMaintainanceDetailsForUpdate?societyId=${societyId}`, {headers: authHeader()}).then(res => res.data);
    }
    
    // getMissingListOfMonth(billDate){
    //     return axios.get(`${Config.apiUrl}/BillGeneration/GetMissingBillForMonths?billingDate=${billDate}`, {headers: authHeader()}).then(res => res.data);
    // }

}
export default SocietyService;
