import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var selectedDate;
var BillData = [];
var generationId;
var isBillingData;

function BillRegister({ props }) {
  const location = useLocation();
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const societyService = new SocietyService();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const [BillDateValue, setBillDateValue] = useState(null);
  const [selectedBillDate, setSelectedBillDate] = useState([]);
  const [BillDate, setBillDate] = useState();
  const [BillDatas, setBillData] = useState([]);
  const [IsBillingTrueData, setIsBillingData] = useState([]);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  isBillingData = IsBillingTrueData;
  BillData = BillDatas;
  selectedDate = BillDateValue?.billDate;
  const tableName = sessionStorage.getItem('tblName');


  useEffect(() => {
    societyService.getSocietyDetails(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

    societyService.getGlMasterIsBilling(societyId).then(data => {
      setIsBillingData(data);
    });

    societyService.getBillsDate(societyId,tableName).then(data => {
      setSelectedBillDate(data);
      data.forEach(d => {
        d.t_date = moment(d.t_date).format("YYYY-DD-MM");
        // d.billDate = moment(d.billDate).format("DD-MM-YYYY");
      });
    });
  }, []);

  const onBillDateChange = (e) => {
    setBillDateValue(e.value);
    setBillDate(e.value.t_date);
    generationId = e.value.generationId
  }

  const setSerialNo = (data) => {
    let srNo = 1;
    data.forEach(d => {
      d.serialNo = srNo;
      srNo++;
    });
  }

  const totalGlAmount = (data) => {
    data.forEach(a => {
      a.totalGlAmount = 0;
      for (var i = 0; i < a.billRegisterReportInfos.length; i++) {
        a.totalGlAmount += a.billRegisterReportInfos[i].amount;
        a.name=a.billRegisterReportInfos[0].name;   
        a.flatNo=`${a.billRegisterReportInfos[0].wing}-${a.billRegisterReportInfos[0].flatNo}`   }
    })
  }

  const total = (data) => {
    data.forEach(a => {
      for (var i = 0; i < a.billRegisterPreviousOustanding.length; i++) {
        a.total = a.totalGlAmount + a.billRegisterPreviousOustanding[0]?.previousOustanding;
      }
    })
  }

  const viewBills = (e) => {
    setSpinnerVisible(true);
    societyService.getBillRegisterReport(societyId, generationId, BillDate,tableName).then(Bdata => {
      setSerialNo(Bdata);
      totalGlAmount(Bdata);
      total(Bdata);
      Bdata.forEach(b => {
          isBillingData.forEach(billingdata=>{
            let itemReterived=b.billRegisterReportInfos.find(a=>a.glId===billingdata.glId);
            if(itemReterived != undefined)
            {
              return;
            }
            else{
              b.billRegisterReportInfos.push({glId:billingdata.glId,glTitle:billingdata.glTitle,amount:0});
            }
          })
        b.billRegisterReportInfos.sort((a, b) => (a.glId > b.glId) ? 1 : -1)
        
        let value=b.billRegisterReportInfos.find(a=>a.glId===2)
        if(value != undefined)
          {
            b.billRegisterReportInfos.shift();
            b.billRegisterReportInfos.push(value);

          }

          value=b.billRegisterReportInfos.find(a=>a.glId===3)
          if(value != undefined)
            {
              b.billRegisterReportInfos.shift();
              b.billRegisterReportInfos.push(value);
  
            }

        b.billRegisterReportInfos.forEach(m => {
          if (m.amount === 0 || '') {
            m.amount = '---';
          }
        })
      })
      setBillData(Bdata);
      setSpinnerVisible(false);
    })
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div class="card" style={{ backgroundColor: 'black', height: '45px',}}>
    <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
      <Dropdown value={BillDateValue} options={selectedBillDate} optionLabel="billDate" placeholder="Select a Date" style={{marginLeft:'30px' }} onChange={onBillDateChange} />
      <Button label="VIEW" onClick={viewBills} style={{ marginLeft: '10px' }} />
      <Button onClick={handlePrint} label="PRINT" style={{ marginLeft: '10px' }} />
      <Report ref={componentRef} />
    </div>
  );

}
const pageStyle =()=> `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }
  
  @page {
    size: landscape;
    margin: 7mm 15mm 0mm 15mm;
  }
  
  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }
  
    .content-block, p {
      page-break-inside: avoid;
    }
  
    html, body {
      width: 310mm;
      height: 297mm;
      
    }
  }
`;


class Report extends React.Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <div>
          <style>{pageStyle()}</style>
          <div className='SocietyName'>
            <div class="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>
                {societyData[0]?.address}
              </text>
            </div>
            <div class="font">
              <label>Regular Bill Register</label>
            </div>
            <div className="dateFont">
              <label>As On Date {selectedDate}</label>
            </div>
          </div>

          <div>
            <div class="DuesRegularTable">
              <table>
                <thead className='headerColor'>
                  <th style={{ width: '3%', textAlign: 'center' }}>Sr No</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Flat No</th>
                  <th style={{ width: '15%', textAlign: 'center' }}>Member Name</th>
                  {
                    isBillingData.map((item) => (
                      <th>{item.glTitle}</th>

                    ))
                  }
                  <th style={{ width: '5%', textAlign: 'center' }}>Interest On Arrears</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Delayed Payment Charges</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Total</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Previous O/S</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Total</th>
                </thead>
                {BillData.map((Bdata, index) =>
                  <tbody>
                    {
                      <tr>
                        <td>{BillData[index].serialNo}</td>
                        <td>{BillData[index].flatNo}</td>
                        <td>{BillData[index].name}</td>
                        {
                          BillData[index].billRegisterReportInfos?.map((item) =>
                          (
                            <th style={{ textAlign: 'right' }}>{item.amount}</th>
                          ))
                        }
                        <th style={{ textAlign: 'right' }}>{BillData[index].totalGlAmount}</th>
                        {
                          BillData[index].billRegisterPreviousOustanding?.map((item) =>
                          (
                            <th style={{ textAlign: 'right' }}>{item.previousOustanding.toFixed(2)}</th>
                          ))
                        }
                        <th style={{textAlign:'right'}}>{BillData[index].total.toFixed(2)}</th>
                      </tr>
                    }
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
};
export default BillRegister;



