import { suppressDeprecationWarnings } from 'moment';
import 'primeflex/primeflex.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from "react-hook-form";
import SocietyService from '../../Service/SocietyService';
import { SocietyContext } from "../../SocietyContext";
import './Flat.css';
import { Button } from "primereact/button";
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import authHeader from '../../authHeader';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { align } from '@progress/kendo-drawing';
import 'primeicons/primeicons.css';
import axios from "axios";
import Config from "../../Config.json";
import { useReactToPrint } from 'react-to-print';
import SelectedMemberReceipt from 'components/Report/SelectedMemberReceipt';



function Flat({ props }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const toast = useRef(null);
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const [wings, setWings] = useState([]);
  const [selectedWing, setSelectedWing] = useState();
  const societyService = new SocietyService();
  var societyId = parseInt(localStorage.getItem('societyId'));
  const tableName = sessionStorage.getItem('tblName');
  const [Name, setName] = useState('');
  const [Address1, setAddress1] = useState('');
  const [Address2, setAddress2] = useState('');
  const [City, setCity] = useState('');
  const [Pincode, setPincode] = useState('');
  const [Mobile, setMobile] = useState('');
  var [Sr, setSr] = useState('');
  const [Email, setEmail] = useState('');
  const [FlatType, setFlatType] = useState('');
  const [UsedBy, setUsedBy] = useState('');
  const [AreaSqft, setAreaSqft] = useState('');
  const [AreaSqMtr, setAreaSqMtr] = useState('');
  const [MemberNo, setMemberNo] = useState('');
  const [CertificateNo, setCertificateNo] = useState('');
  const [ShFrom, setShFrom] = useState('');
  const [ShTo, setShTo] = useState('');
  const [addFlatData, setFlatData] = useState();
  const [Relation, setRelation] = useState('');
  const [Nominee, setNominee] = useState('');
  const [MeetingDate, setMeetingDate] = useState('');
  const [agreementDate, setAgreementDate] = useState('');
  const [toDate, setToDate] = useState([]);
  const [FloorType, setFloorType] = useState('');
  const [CompositionType, setCompositionType] = useState('');


  const cm = useRef(null);
  const [flatNo, setFlatNo] = useState('');
  const [backButton, setBackDisabled] = useState(false);
  const [nextButton, setNextDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [nominneData, setNomineeData] = useState([]);
  const [tenantDetails, setTenantDetails] = useState('');
  const [flatId, setFlatId] = useState();
  var [flatIndex, setFlatIndex] = useState(0);
  const [selectedRadioData, setSelectedRadioData] = useState(null);

  let history = useHistory();
  const menuModel = [
    { label: 'Edit Bill', icon: 'pi pi-fw pi-search', command: () => editBill(selectedProduct) }
  ];
  const [value] = useDebounce(flatNo, 3000);
  useEffect(() => {
    societyService
      .getWing(societyId)
      .then((data) =>
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),
        ),
      );
  }, []);

  const editBill = (product) => {
    history.push(`/EditFlatBill/${selectedProduct.billNo}`);
  }

  const onChangeWing = (e) => {
    let wing = e.currentTarget.value
    setSelectedWing(wing);
  }

  const InputFlatNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setFlatNo(onlyNums);
  }


  const onChangeFlat = (e) => {
    let flatNo = e.currentTarget.value;


    societyService
      .getFlat(societyId)
      .then((data) => {
        setData(data);
        if (data.length > 0) {
          let fNo = data.find(a => a.flatNo === parseInt(flatNo));
          if (fNo === undefined) {
            Reset();
            return;
          }
          else {
            let i = data.findIndex(a => a.flatNo === parseInt(flatNo) && a.wing === selectedWing);
            setFlatIndex(i);
            if (i === 0) {
              setBackDisabled(true);
              setNextDisabled(false);
            }
            if (i === data.length - 1) {
              setNextDisabled(true);
              setBackDisabled(false);
            }
            setSr(data[i].serialNo);
            setName(data[i].name);
            setSelectedWing(data[i].wing);
            setAddress1(data[i].address1);
            setAddress2(data[i].address2);
            setCity(data[i].city);
            setPincode(data[i].pincode);
            setMobile(data[i].mobile);
            setEmail(data[i].email);
            setFlatType(data[i].type);
            setUsedBy(data[i].usedBy);
            setAreaSqft(data[i].areaInSqft);
            setAreaSqMtr(data[i].areaInSqMtr);
            setMemberNo(data[i].memberNo);
            setCertificateNo(data[i].certificateNo);
            setShFrom(data[i].sharesFrom);
            setShTo(data[i].sharesTo);
            setRelation(data[i].relation);
            setNominee(data[i].nominee);
            setFlatId(data[i].id);
            getNomineeData(data[i].id);
            getTenantData(data[i].id);
            setCompositionType(data[i].composition);
            setFloorType(data[i].floor);

            getGridData(societyId, selectedWing, data[i].id);
          }
        }
      }
      );
  }

  const getNomineeData = (id) => {
    societyService.getNomineeDetailsOnID(id).then(data => {
      setNomineeData(data);
      if (data[0]?.meetingDateFormat === '0001-01-01') {
        data[0].meetingDateFormat = '';
        setMeetingDate(data[0]?.meetingDateFormat);
      }
      else {
        setMeetingDate(data[0]?.meetingDateFormat);
      }
    })
  }

  const getTenantData = (id) => {
    societyService.getTenantDetailsOnID(id).then(data => {
      setTenantDetails(data)
      if (data[0]?.agreementFromDateFormat === '0001-01-01') {
        data[0].agreementFromDateFormat = '';
        setAgreementDate(data[0]?.agreementFromDateFormat);
      }
      else {
        setAgreementDate(data[0]?.agreementFromDateFormat);
      }

      if (data[0]?.agreementToDateFormat === '0001-01-01') {
        data[0].agreementToDateFormat = '';
        setToDate(data[0]?.agreementToDateFormat);
      }
      else {
        setToDate(data[0]?.agreementToDateFormat);
      }
    }
    )
  }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const getGridData = (societyId, selectedWing, flatId) => {
    societyService.getFlatBalanceHistory(societyId, selectedWing, flatId, tableName).then(data => {
      data.forEach(a => {
        if (a.recordType.toUpperCase() === 'OPENING_BALANCE') {
          a.balance = a.totalAmount;
          a.narration = "Opening balance";
        }
        a.debitPrinciple = a.debitPrinciple.toFixed(2);
        a.debitInterest = a.debitInterest.toFixed(2);
        a.debitDpc = a.debitDpc.toFixed(2);
        a.creditPrinciple = a.creditPrinciple.toFixed(2);
        a.creditInterest = a.creditInterest.toFixed(2);
        a.creditDpc = a.creditDpc.toFixed(2);
        a.totalAmount = a.totalAmount.toFixed(2);
        a.balance = a.balance.toFixed(2);
        a.flatId=flatId;
      });
      setFlatData(data);
    })
    console.warn(addFlatData);
  }

  const onNext = (e) => {
    flatIndex = flatIndex + 1;
    if (flatIndex === data.length - 1) {
      setNextDisabled(true);
    }
    setBackDisabled(false);
    setSr(data[flatIndex].serialNo);
    setSelectedWing(data[flatIndex].wing);
    setFlatNo(data[flatIndex].flatNo);
    setName(data[flatIndex].name);
    setAddress1(data[flatIndex].address1);
    setAddress2(data[flatIndex].address2);
    setCity(data[flatIndex].city);
    setPincode(data[flatIndex].pincode);
    setMobile(data[flatIndex].mobile);
    setEmail(data[flatIndex].email);
    setFlatType(data[flatIndex].type);
    setUsedBy(data[flatIndex].usedBy);
    setAreaSqft(data[flatIndex].areaInSqft);
    setAreaSqMtr(data[flatIndex].areaInSqMtr);
    setMemberNo(data[flatIndex].memberNo);
    setCertificateNo(data[flatIndex].certificateNo);
    setShFrom(data[flatIndex].sharesFrom);
    setShTo(data[flatIndex].sharesTo);
    setRelation(data[flatIndex].relation);
    setNominee(data[flatIndex].nominee);
    setMeetingDate(data[flatIndex].meetingDateFormat);
    setFlatIndex(flatIndex);
    setFlatId(data[flatIndex].id);
    getNomineeData(data[flatIndex].id);
    getTenantData(data[flatIndex].id);
    setCompositionType(data[flatIndex].compositionType);
    setFloorType(data[flatIndex].floorType);
    getGridData(societyId, selectedWing, data[flatIndex].id);
  }

  const onBack = (e) => {
    flatIndex = flatIndex - 1;
    if (flatIndex === 0) {
      setBackDisabled(true);
    }
    setNextDisabled(false);
    setSr(data[flatIndex].serialNo);
    setSelectedWing(data[flatIndex].wing);
    setFlatNo(data[flatIndex].flatNo);
    setName(data[flatIndex].name);
    setAddress1(data[flatIndex].address1);
    setAddress2(data[flatIndex].address2);
    setCity(data[flatIndex].city);
    setPincode(data[flatIndex].pincode);
    setMobile(data[flatIndex].mobile);
    setEmail(data[flatIndex].email);
    setFlatType(data[flatIndex].type);
    setUsedBy(data[flatIndex].usedBy);
    setAreaSqft(data[flatIndex].areaInSqft);
    setAreaSqMtr(data[flatIndex].areaInSqMtr);
    setMemberNo(data[flatIndex].memberNo);
    setCertificateNo(data[flatIndex].certificateNo);
    setShFrom(data[flatIndex].sharesFrom);
    setShTo(data[flatIndex].sharesTo);
    setRelation(data[flatIndex].relation);
    setNominee(data[flatIndex].nominee);
    setMeetingDate(data[flatIndex].meetingDateFormat);
    setFlatIndex(flatIndex);
    setFlatId(data[flatIndex].id);
    getNomineeData(data[flatIndex].id);
    getTenantData(data[flatIndex].id);
    setCompositionType(data[flatIndex].compositionType);
    setFloorType(data[flatIndex].floorType);
    getGridData(societyId, selectedWing, data[flatIndex].id);
  }

  const onFirstFlat = (e) => {
    flatIndex = 0;
    setBackDisabled(true);
    setNextDisabled(false);
    setSr(data[flatIndex].serialNo);
    setSelectedWing(data[flatIndex].wing);
    setFlatNo(data[flatIndex].flatNo);
    setName(data[flatIndex].name);
    setAddress1(data[flatIndex].address1);
    setAddress2(data[flatIndex].address2);
    setCity(data[flatIndex].city);
    setPincode(data[flatIndex].pincode);
    setMobile(data[flatIndex].mobile);
    setEmail(data[flatIndex].email);
    setFlatType(data[flatIndex].type);
    setUsedBy(data[flatIndex].usedBy);
    setAreaSqft(data[flatIndex].areaInSqft);
    setAreaSqMtr(data[flatIndex].areaInSqMtr);
    setMemberNo(data[flatIndex].memberNo);
    setCertificateNo(data[flatIndex].certificateNo);
    setShFrom(data[flatIndex].sharesFrom);
    setShTo(data[flatIndex].sharesTo);
    setRelation(data[flatIndex].relation);
    setNominee(data[flatIndex].nominee);
    setMeetingDate(data[flatIndex].meetingDateFormat);
    setFlatIndex(flatIndex);
    setFlatId(data[flatIndex].id);
    getNomineeData(data[flatIndex].id);
    getTenantData(data[flatIndex].id);
    setCompositionType(data[flatIndex].compositionType);
    setFloorType(data[flatIndex].floorType);
    getGridData(societyId, selectedWing, data[flatIndex].id);
  }

  const onLastFlat = (e) => {
    flatIndex = data.length - 1;
    setNextDisabled(true);
    setBackDisabled(false);
    setSr(data[flatIndex].serialNo);
    setSelectedWing(data[flatIndex].wing);
    setFlatNo(data[flatIndex].flatNo);
    setName(data[flatIndex].name);
    setAddress1(data[flatIndex].address1);
    setAddress2(data[flatIndex].address2);
    setCity(data[flatIndex].city);
    setPincode(data[flatIndex].pincode);
    setMobile(data[flatIndex].mobile);
    setEmail(data[flatIndex].email);
    setFlatType(data[flatIndex].type);
    setUsedBy(data[flatIndex].usedBy);
    setAreaSqft(data[flatIndex].areaInSqft);
    setAreaSqMtr(data[flatIndex].areaInSqMtr);
    setMemberNo(data[flatIndex].memberNo);
    setCertificateNo(data[flatIndex].certificateNo);
    setShFrom(data[flatIndex].sharesFrom);
    setShTo(data[flatIndex].sharesTo);
    setRelation(data[flatIndex].relation);
    setNominee(data[flatIndex].nominee);
    setMeetingDate(data[flatIndex].meetingDateFormat);
    setFlatIndex(flatIndex);
    setFlatId(data[flatIndex].id);
    getNomineeData(data[flatIndex].id);
    getTenantData(data[flatIndex].id);
    setCompositionType(data[flatIndex].compositionType);
    setFloorType(data[flatIndex].floorType);
    getGridData(societyId, selectedWing, data[flatIndex].id);
  }

  const onChangeNominee = (e) => {
    let nomineeName = e.currentTarget.value;
    let index = nominneData.findIndex(a => a.nominee.toUpperCase() === nomineeName.toUpperCase());
    let data = nominneData[index];
    if (data?.meetingDateFormat === '0001-01-01') {
      data.meetingDateFormat = '';
      setMeetingDate(data?.meetingDateFormat);
    }
    else {
      setMeetingDate(data?.meetingDateFormat);
    }
  }

  const Reset = () => {
    setSr('');
    setName('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setPincode('');
    setMobile('');
    setEmail('');
    setFlatType('');
    setUsedBy('');
    setAreaSqft('');
    setAreaSqMtr('');
    setMemberNo('');
    setCertificateNo('');
    setShFrom('');
    setShTo('');
    setRelation('');
    setNominee('');
    setMeetingDate('');
    setCompositionType('');
    setFloorType('');
  }

  const onEdit = (rowData) => {
    if (rowData.recordType.toUpperCase() === 'OPENING_BALANCE') {
      toast.current.show({ severity: 'warn', detail: "This is not for Edit" });
      return;
    }
    if (rowData.recordType.toUpperCase() === 'BILLS') {
      history.push(`/EditFlatBill/${rowData.billNo}`);
    }
    if (rowData.recordType.toUpperCase() === 'RECEIPT') {
      history.push(`/MemberReceipt/edit/${rowData.rctNo}`);
    }
    // if (selectedRadioData.transactionType === 'MRR') {
    //   history.push(`/MRReturnEntry/edit/${selectedRadioData.id}`);
    // }
    // if (selectedRadioData.transactionType === 'GRR') {
    //   history.push(`/GRReturnEntry/edit/${selectedRadioData.id}`);
    // }
    // if (selectedRadioData.transactionType === 'VE') {
    //   history.push(`/VoucherEntry/edit/${selectedRadioData.id}`);
    // }
    // if (selectedRadioData.transactionType === 'DE') {
    //   history.push(`/CreditDebitNote/edit/${selectedRadioData.id}`);
    // }
    // if (selectedRadioData.transactionType === 'CE') {
    //   history.push(`/CreditDebitNote/edit/${selectedRadioData.id}`);
    // }
    // if (selectedRadioData.transactionType === 'MDP') {
    //   history.push(`/EditFlatBill/${selectedRadioData.billNo}`);
    // }
  }

  const header = (
    <div className="tableheader">
      <div>

      </div>

      <div>
        {/* <FontAwesomeIcon icon="fa-regular fa-pen" /> */}
        {/* <Button icon="pi pi-pencil" value="Edit" tooltip="Edit" onClick={handleSubmit(onEdit)} /> */}

        {/* <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" />  */}
      </div>
    </div>
  );

  const rowClass = (data) => {
    return {
      'MDI': data.recordType === "Opening_Balance",
      'MR': data.recordType === "Receipt",
      'MDP': data.recordType === "bills",
    }
  }

  const leftContents = (
    <React.Fragment>
      <Button icon="pi pi-arrow-up" className="p-button-danger p-mr-2" tooltip="First Flat" onClick={handleSubmit(onFirstFlat)} />
      <Button icon="pi pi-arrow-left" className="p-button-danger p-mr-2" disabled={backButton} onClick={handleSubmit(onBack)} />
      <Button icon="pi pi-arrow-right" className="p-button-danger p-mr-2" disabled={nextButton} onClick={handleSubmit(onNext)} />
      <Button icon="pi pi-arrow-down" className="p-button-danger p-mr-2" tooltip="Last Flat" onClick={handleSubmit(onLastFlat)} />
      <Button icon="pi pi-print" className="p-button-danger p-mr-2" tooltip="Print Flat" disabled />
    </React.Fragment>
  );

  const editbutton = (rowData) => {
    return <div>
      {rowData.recordType.toUpperCase() !== 'OPENING_BALANCE' &&
        <Button icon="pi pi-pencil" className='edit' value="Edit" tooltip="Edit" onClick={() => onEdit(rowData)} />
      }
    </div>;
  }

  const print = (rowData) => {
    // if (rowData.recordType.toUpperCase() === 'MR') {
    //   history.push(`/ PrintMemberReceipt`);
    // }
    if (rowData.recordType.toUpperCase() === "RECEIPT") {
      history.push({
        pathname: '/PrintMemberReceipt',
        state: { printDetails: rowData }
      })
      // <SelectedMemberReceipt/>
    }
    else {
      return;
    }
  };
  
  

  const emailButton = (rowData) => {
    return (
      <div className="Form_inline">
        {rowData.billNo > 0 &&

          <Button icon="pi pi-envelope" value="print" className='btn' tooltip="Send Email" onClick={() => sendEmail(rowData)} />

        }
        {/* <div style={{ marginRight: '30px' }}> */}
        {/* <Button icon="pi pi-print" className='btn' tooltip="Print" onClick={print}  /> */}
        <Button icon="pi pi-print" value="print" tooltip="Edit" className="btn" onClick={() => print(rowData)} />

      </div>

    );

  }

  const sendEmail = (rowData) => {
    console.log();
    if (Email === undefined || Email.length === 0) {
      toast.current.show({ severity: 'error', detail: "No Email defined for flat owner", sticky: true });
      return;
    }
    return axios.post(`${Config.apiUrl}/BillGeneration/SendFlatBill?societyId=${societyId}&billingDate=${rowData.dateFormat}&transactionTableName=${tableName}&flatId=${flatId}`)
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data[0].success) {
          toast.current.show({ severity: 'success', detail: "Data Saved Successfully", sticky: true });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg, sticky: true });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Network Error', sticky: true });
      })
      .finally(() => {

      })
  }

  return (
    <form className="Flat backgroundColor" style={{ border: "1px solid black", width: "auto", backgroundColor: "Info" }}>
      <Toast ref={toast} position={'center'} />
      <div>
        <Toolbar left={leftContents} />
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2" >Sr:</label>
        <input type="text" name="sr" style={{ height: '25px', width: '110px', marginLeft: '59px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Sr} readOnly />
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2" >Wing:</label>
        <select style={{ height: '25px', width: '110px', marginLeft: '40px', borderRadius: '6px', border: '1px solid lightgrey' }} name="wing" onChange={onChangeWing} value={selectedWing}  >
          <option hidden value="">Select Wing</option>
          {wings.map(({ label, value: wings }) => (
            <option key={wings} value={wings}>
              {label}
            </option>
          ))}
        </select>

        <label className="p-mr-2 p-mb-2" >Flat No:</label>
        <input type="text" style={{ height: '25px', width: '110px', borderRadius: '6px', border: '1px solid lightgrey' }} name="flatNo" value={flatNo} onChange={onChangeFlat} onInput={InputFlatNo} />

        <label className="p-mr-2 p-mb-2" >Flat Type:</label>
        <input type="text" style={{ height: '25px', width: '119px', borderRadius: '6px', border: '1px solid lightgrey' }} name="flatType" value={FlatType} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '7px' }} >Used By:</label>
        <input type="text" style={{ height: '25px', width: '111px', marginLeft: '25px', borderRadius: '6px', border: '1px solid lightgrey' }} name="usedBy" value={UsedBy} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Area(sqft):</label>
        <input type="text" style={{ height: '25px', width: '110px', marginLeft: '28px', borderRadius: '6px', border: '1px solid lightgrey' }} name="areaSqft" value={AreaSqft} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Area(sqmtr):</label>
        <input type="text" name="areaSqmtr" style={{ height: '25px', width: '110px', borderRadius: '6px', border: '1px solid lightgrey' }} value={AreaSqMtr} />
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2" >Name:</label>
        <input type="text" name="name" style={{ height: '25px', width: '498px', marginLeft: '35px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Name} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Member No:</label>
        <input type="text" name="memberNo" style={{ height: '25px', width: '110px', marginLeft: '3px', borderRadius: '6px', border: '1px solid lightgrey' }} value={MemberNo} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Certificate No:</label>
        <input type="text" name="certificateNo" style={{ height: '25px', width: '111px', marginLeft: '5px', borderRadius: '6px', border: '1px solid lightgrey' }} value={CertificateNo} />
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2">Address:</label>
        <input type="text" name="address1" style={{ height: '25px', width: '498px', marginLeft: '20px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Address1} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Sh From:</label>
        <input type="text" name="shFrom" style={{ height: '25px', width: '110px', marginLeft: '24px', borderRadius: '6px', border: '1px solid lightgrey' }} value={ShFrom} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Sh To:</label>
        <input type="text" name="shTo" style={{ height: '25px', width: '110px', marginLeft: '58px', borderRadius: '6px', border: '1px solid lightgrey' }} value={ShTo} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Relation:</label>
        <input type="text" name="relation" style={{ height: '25px', width: '113px', marginLeft: '25px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Relation} />
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2"></label>
        <input type="text" name="address2" style={{ height: '25px', width: '497px', marginLeft: '78px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Address2} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>Nominee:</label>
        <select style={{ height: '25px', width: '565px', marginLeft: '22px', borderRadius: '6px', border: '1px solid lightgrey' }} name="societyBank" className="height" onChange={onChangeNominee}  >
          {nominneData.map((nominneData) => (
            <option key={nominneData.nominee} value={nominneData.nominee}>
              {nominneData.nominee}
            </option>
          ))}
        </select>
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2" >City:</label>
        <input type="text" name="city" style={{ height: '25px', width: '110px', marginLeft: '47px', borderRadius: '6px', border: '1px solid lightgrey' }} value={City} />

        <label className="p-mr-2 p-mb-2">Pincode:</label>
        <input type="text" name="pincode" style={{ height: '25px', width: '108px', marginLeft: '40px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Pincode} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '163px', color: 'red' }}>Agreement:</label>
        <input type="text" name="agreement" style={{ height: '25px', width: '110px', marginLeft: '15px', borderRadius: '6px', border: '1px solid lightgrey' }} value={agreementDate} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '40px', color: 'red' }}>To:</label>
        <input type="text" name="to" style={{ height: '25px', width: '110px', marginLeft: '50px', borderRadius: '6px', border: '1px solid lightgrey' }} value={toDate} />

        <label className="compt" style={{ marginLeft: '3px' }}>CompositionType:</label>
        <input type="text" className="PropertySelct" name="compositionType" value={CompositionType} />

      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <label className="p-mr-2 p-mb-2" >Mobile No:</label>
        <input type="text" name="mobile" style={{ height: '25px', width: '110px', marginLeft: '9px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Mobile} />

        <label className="p-mr-2 p-mb-2">Meeting Date:</label>
        <input type="text" name="meetingDate" style={{ height: '25px', width: '111px', borderRadius: '6px', border: '1px solid lightgrey' }} value={MeetingDate} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '176px' }}>Email:</label>
        <input type="text" name="email" style={{ height: '25px', width: '345px', marginLeft: '37px', borderRadius: '6px', border: '1px solid lightgrey' }} value={Email} />

        <label className="p-mr-2 p-mb-2" style={{ marginLeft: '10px' }}>FloorType:</label>
        <input type="text" name="floorType" style={{ height: '25px', width: '106px', marginLeft: '25px', borderRadius: '6px', border: '1px solid lightgrey' }} value={FloorType} />

      </div>



      {/* <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedProduct(null)} /> */}
      <div className="p-formgroup-inline">
        <DataTable className="p-datatable-gridlines" value={addFlatData} rowClassName={rowClass} scrollable scrollHeight='45vh' style={{ overflow: 'hidden' }}

          responsiveLayout="scroll" header={header}  >
          <Column body={editbutton} headerStyle={{ width: '3em' }}></Column>
          <Column body={emailButton} headerStyle={{ width: '7em' }}></Column>
          <Column field="dateFormat" header="Date" style={{ width: '100px' }}></Column>
          <Column field="billNo" header="Bill No" style={{ width: '100px' }}></Column>
          <Column field="rctNo" header="Rct No" style={{ width: '100px' }}></Column>
          <Column field="debitPrinciple" header="Debit Principle"></Column>
          <Column field="debitInterest" header="Debit Interest" ></Column>
          <Column field="debitDpc" header="Debit Dpc" ></Column>
          <Column field="creditPrinciple" header="Credit Principle" ></Column>
          <Column field="creditInterest" header="Credit Interest" ></Column>
          <Column field="creditDpc" header="Credit Dpc" ></Column>
          <Column field="totalAmount" header="Total"></Column>
          <Column field="balance" header="Balance"></Column>
          {/* <Column field="narration" header="Narration"></Column> */}
        </DataTable>
      </div>
    </form>
  );
}
export default Flat;