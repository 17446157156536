
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';






var societyId = parseInt(localStorage.getItem('societyId'));
var societyData=[];


// class Example extends React.Component {
function SupplementaryDues({ props }) {

  const location = useLocation();
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const societyService = new SocietyService();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;

  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });
 

  },[]);


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <button onClick={handlePrint}>Print this out!</button>
      <Report ref={componentRef} />

    </div>
  );

}


class Report extends React.Component {
  render() {



    return (
    
      <div style={{ height: '100%' }}>
          <div>
            <div className='SocietyName'>
              <div class="font"><text>{societyData[0]?.societyName}</text></div>
              <div>
                <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
              </div>
              <div>
                <text>
                  {societyData[0]?.address}
                </text>
              </div>
              <div class="font">
                 <label>Supplementary Dues From Members</label>
              </div>
              <div className="dateFont">
                  <label>As On Date</label>
              </div>              
            </div>

            <div>         
              <div class="Table">
                <table>
                  <thead>
                  <tr>
                    <th style={{ width: '5%', textAlign: 'center' }}>Sr</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Flat No</th>
                    <th style={{ width: '30%', textAlign: 'center' }}>Member Name</th>
                    <th style={{ width: '40%', textAlign: 'center' }} colspan="3">Dues</th>
                    <th style={{ width: '25%', textAlign: 'center' }}>Total Amount</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>DPC</th>
                    <th></th>
                  </tr>
                  </thead>                           
                </table>

              </div>

            </div>
          </div>
    
      </div>


    )
  }

};

export default SupplementaryDues;



