import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import { Button } from 'primereact/button';
import './OpeningBalanceAuditView.css';
import moment from 'moment';



function OpeningBalanceAuditView() {

    const [globalFilter, setGlobalFilter] = useState('');
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [OpeningBalanceAuditData, setOpeningBalanceAuditInfo] = useState();
    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {
        societyService.getOpeningBalanceAuditInfo(fromDate, toDate,societyId).then(data => setOpeningBalanceAuditInfo(data));
        console.warn(OpeningBalanceAuditData)
    }

    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>Opening Balance Details Audit View</b> */}

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }}  value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className="label">From:</label>
            <input type="date" name="date" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className="label">To:</label>
            <input type="date" name="date" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />
        </div>
    );

    return (
        <div className="OpeningBalanceAuditView">
            <span style={{fontFamily:'verdena', fontWeight:'bold'}}>Opening Balance Details Audit View</span> 
            <div>
            <DataTable className="p-datatable-gridlines" responsiveLayout="scroll" scrollable scrollHeight='400px' style={{ overflow: 'hidden' }} header={header}
                globalFilter={globalFilter} value={OpeningBalanceAuditData} emptyMessage="No result found.">
                <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                <Column field="openBalDateFormat" style={{ width: '120px' }} header="Open Bal Date" filter></Column>
                <Column field="openBalDues" style={{ width: '100px' }} header="Open Bal Dues" filter></Column>
                <Column field="openBalInterest" style={{ width: '100px' }} header="Open Bal Interest" filter></Column>
                <Column field="balType" style={{ width: '80px' }} header="Bal Type" filter></Column>
                <Column field="bank" style={{ width: '100px' }} header="Bank" filter></Column>
                <Column field="branch" style={{ width: '150px' }} header="Branch" filter ></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '120px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>
            </DataTable>
            </div>
        </div>

    );
}
export default OpeningBalanceAuditView;