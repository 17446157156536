
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { Dropdown } from "primereact/dropdown";
import axios from 'axios';
import { Toast } from 'primereact/toast';
//import Config from '../Config.json';
import Config from '../../Config.json';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import authHeader from '../../authHeader';
import ProgressSpinBarControl from './progressSpinBarControl';
import SocietyService from 'Service/SocietyService';
import { Checkbox } from 'primereact/checkbox';

function CreateTransactionSchemaForFinYear() {

    const societyService = new SocietyService();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [checked, setChecked] = useState(false);
    const [intialized, setIntialized] = useState(false);
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    const [isTransMainTableCreated, setIsTransMainTableCreated] = useState(false);
    const [isTransMainAuditTableCreated, setIsTransMainAuditTableCreated] = useState(false);
    const [isTransMainTableTriggersCreated, setIsTransMainTableTriggersCreated] = useState(false);
    const [isTransSupplTableCreated, setIsTransSupplTableCreated] = useState(false);
    const [isTransSupplAuditTableCreated, setIsTransSupplAuditTableCreated] = useState(false);
    const [isTransSupplTableTriggerCreated, setIsTransSupplTableTriggerCreated] = useState(false);
    const [transSchemaStatistics, setTransSchemaStatistics] = useState({});
    const toast = useRef(null);
    const [selectedFinYears, setselectedFinYear] = useState();
    const [selectedCheckBoxChecked, setCheckBoxChecked] = useState();
    const [currentFinYearShort, setCurrentFinYearShort] = useState(sessionStorage.getItem('finYearShort'));
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [financialYearsData, setFinancialYearsData] = useState();
    const [finYear, setFinYear] = useState(sessionStorage.getItem("finYear"));
    const [finYearShort, setFinYearShort] = useState(sessionStorage.getItem("finYearShort"));
    const [societyCode, setSocietyCode] = useState(sessionStorage.getItem('societyCode'));
    useEffect(() => {

        let finYearShort = sessionStorage.getItem('finYearShort');

        const FinancialData = getFinancialYear(societyId);
        setFinancialYearsData(FinancialData);


    }, [])

    const getFinancialYear = async (societyId) => {
        const FinancialData = await societyService.getFinancialYearsData(societyId)
        setFinancialYearsData(FinancialData);
    }

    const [selectedTransTable, setSelectedTransTable] = useState("");
    const [selectedTransAuditTable, setSelectedTransAuditTable] = useState("");

    const resetUI = () => {
        setIsTransMainTableCreated(true);
        setIsTransMainAuditTableCreated(false);
        setIsTransMainTableTriggersCreated(false);
        setIsTransSupplTableCreated(false);
        setIsTransSupplTableCreated(false);
        setIsTransSupplTableTriggerCreated(false);
    }
    const changeFinancialYear = async (e) => {
        resetUI();
        setselectedFinYear(e.value);
        setIntialized(true);
        let societyCode = sessionStorage.getItem('societyCode').toLowerCase();

        let tblName = `tr_${societyCode}_${e.value.societyId}_${e.value.yearShort}`
        setSelectedTransTable(tblName);
        let tblSuppName = `tbl_transsupplimentary_${e.value.societyId}`;
        setSelectedTransAuditTable(tblSuppName);
        let tblTranTablePrefix = `tr_${societyCode}_${e.value.societyId}`;
        let transSchemaData = await societyService.GetSocietyFinYearTransObjects(societyId, societyCode, currentFinYearShort, currentFinYearShort);
        setTransSchemaStatistics(transSchemaData);
        if (transSchemaData.transactionTableCreated) {
            setIsTransMainTableCreated(true);
        }
        else {
            setIsTransMainTableCreated(false);
        }
        if (transSchemaData.transactionAuditTableCreated) {
            setIsTransMainAuditTableCreated(true);
        }
        else {
            setIsTransMainAuditTableCreated(false);
        }
        if (transSchemaData.transTableTriggersCreated) {
            setIsTransMainTableTriggersCreated(true);
        }
        else {
            setIsTransMainTableTriggersCreated(false);
        }
        if (transSchemaData.transactionSuppTableCreated) {
            setIsTransSupplTableCreated(true);
        }
        else {
            setIsTransSupplTableCreated(false);
        }
        if (transSchemaData.transactionSuppAuditTableCreated) {
            setIsTransSupplAuditTableCreated(true);
        }
        else {
            setIsTransSupplAuditTableCreated(false);
        }
        if (transSchemaData.transactionSuppTriggersCreated) {
            setIsTransSupplTableTriggerCreated(true);
        }
        else {
            setIsTransSupplTableTriggerCreated(false);
        }
    }

    const onSave = () => {

    }


    const createTransactionDbObject = (objStr) => {

        let createTransMainTableTrigger = false;
        let createTransSupTableTrigger = false;
        let createTransTable = false; let createTransAudit = false; let createTransSuppTable = false; let createTransSuppAudit = false;
        if (objStr === "TT") {
            createTransTable = true;
        }
        else if (objStr === "TAT") {
            createTransAudit = true;

        }
        else if (objStr === "TATR") {
            createTransMainTableTrigger = true;
        }
        else if (objStr === "ST") {
            createTransSuppTable = true;
        }
        else if (objStr === "SAT") {
            createTransSuppAudit = true;
        }
        else if (objStr === "SATR") {
            createTransSupTableTrigger = true;
        }

        let url = "";
        if (createTransTable || createTransAudit || createTransSuppTable || createTransSuppAudit) {
            url = `${Config.apiUrl}/Schema/CreateSelectedTransactionDbObjects?societyCode=${societyCode}&societyId=${societyId}&yearValue=${finYear}&shortYearValue=${finYearShort}&shouldCreateTranTable=${createTransTable}&shouldCreateTransAuditTable=${createTransAudit}&shouldCreateTransSuppTable=${createTransSuppTable}&shouldCreateTransSuppAuditTable=${createTransSuppAudit}`;
            axios.post(url, { headers: authHeader() })
                .then(response => {
                    console.log(response)
                    if (response.data.success) {
                        //       setSaveloading(false);
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
                        //     setSaveDisabled(true);
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
                    }
                }).catch(error => {
                    console.log(error)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: "Error in saving data!" });
                    //setSaveloading(false);

                })
        }
        if (createTransMainTableTrigger || createTransSupTableTrigger) {
            let tableName = createTransMainTableTrigger ? selectedTransTable : selectedTransAuditTable;
            url = `${Config.apiUrl}/Schema/CreateTrigger?tableName=${tableName}&action=ALL&isSupplumentary=${createTransSupTableTrigger}`;
            axios.post(url, { headers: authHeader() })
                .then(response => {
                    console.log(response)
                    if (response.data.success) {
                        //       setSaveloading(false);
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
                        //     setSaveDisabled(true);
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
                    }
                }).catch(error => {
                    console.log(error)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: "Error in saving data!" });
                    //setSaveloading(false);

                })

        }

    }

    return (
        <form className="Bcolor" style={{ border: "1px solid black", width: "auto", marginTop: "20px" }}>
            <h3 style={{ marginLeft: "30px" }}>Fin Years Transaction Schema Review</h3>
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />

            <div className="p-formgroup-inline" style={{ marginLeft: "10px" }}>
                <div className="p-field p-grid">
                    <label className="card flex" style={{ marginLeft: "20px" }}>Financial Year</label>
                    <div className=" p-col" >
                        <Dropdown className="Dropdown" value={selectedFinYears} options={financialYearsData} optionLabel="yearValue" onChange={changeFinancialYear} placeholder="Select Financial Year" />
                    </div>
                </div>
            </div>


            <div className="card flex" style={{ marginLeft: "20px" }}>
                <Checkbox onChange={e => setIsTransMainTableCreated(e.checked)} checked={isTransMainTableCreated}></Checkbox>
                <label className="p-col-fixed" style={{ width: '130px' }}>Transaction Table Created</label>
                {!isTransMainTableCreated && intialized &&
                    <Button onClick={() => createTransactionDbObject('TT')} style={{ marginLeft: "225px" }}>Create</Button>
                }
            </div>
            <div className="card flex" style={{ marginLeft: "20px" }}>
                <Checkbox onChange={e => setIsTransMainAuditTableCreated(e.checked)} checked={isTransMainAuditTableCreated}></Checkbox>
                <label className="p-col-fixed" style={{ width: '130px' }}>Transaction Audit Table Created</label>
                {!isTransMainAuditTableCreated && intialized &&
                    <Button style={{ marginLeft: "180px" }} onClick={() => createTransactionDbObject('TAT')}>Create</Button>
                }
            </div>
            <div className="card flex" style={{ marginLeft: "20px" }}>
                <Checkbox onChange={e => setIsTransMainTableTriggersCreated(e.checked)} checked={isTransMainTableTriggersCreated}></Checkbox>
                <label className="p-col-fixed" style={{ width: '130px' }}>Transaction Trigger Created</label>
                {!isTransMainTableTriggersCreated && intialized &&
                    <Button style={{ marginLeft: "215px" }} onClick={() => createTransactionDbObject('TATR')}>Create</Button>
                }
            </div>
            <div className="card flex" style={{ marginLeft: "20px" }}>
                <Checkbox onChange={e => setIsTransSupplTableCreated(e.checked)} checked={isTransSupplTableCreated}></Checkbox>
                <label className="p-col-fixed" style={{ width: '130px' }}>Transaction Supplementary Created</label>
                {!isTransSupplTableCreated && intialized &&
                    <Button style={{ marginLeft: "148px" }} onClick={() => createTransactionDbObject('ST')}>Create</Button>
                }
            </div>
            <div className="card flex" style={{ marginLeft: "20px" }}>
                <Checkbox onChange={e => setIsTransSupplAuditTableCreated(e.checked)} checked={isTransSupplAuditTableCreated}></Checkbox>
                <label className="p-col-fixed" style={{ width: '130px' }}>Transaction Supplementary Audit Table Created</label>
                {!isTransSupplAuditTableCreated && intialized &&
                    <Button style={{ marginLeft: "55px" }} onClick={() => createTransactionDbObject('SAT')}>Create</Button>
                }
            </div>
            <div className="card flex" style={{ marginLeft: "20px" }}>
                <Checkbox onChange={e => setIsTransSupplTableTriggerCreated(e.checked)} checked={isTransSupplTableTriggerCreated}></Checkbox>
                <label className="p-col-fixed" style={{ width: '130px' }}>Transaction Supplementary Trigger Created</label>
                {!isTransSupplTableTriggerCreated && intialized &&
                    <Button style={{ marginLeft: "87px" }} onClick={() => createTransactionDbObject('SATR')}>Create</Button>
                }
            </div>
            {/*             <div className="p-formgroup-inline" style={{ backgroundColor: "Info", justifyContent: 'left', width: '100%' }}>
                <div className="p-field">
                    <Button label="SAVE" style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} type="submit " onClick={handleSubmit(onSave)} />
                </div>
            </div> */}

        </form>
    );
}

export default CreateTransactionSchemaForFinYear;