import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card, Hidden } from "@material-ui/core";
import { Dropdown } from 'primereact/dropdown';
import SocietyService from "../../Service/SocietyService";
import { useContext } from "react";
import { SocietyContext } from "../../SocietyContext";
import axios from "axios";
import Config from "../../Config.json";
import { Toolbar } from 'primereact/toolbar';
import { useHistory } from 'react-router-dom';
import { date } from "yup/lib/locale";
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { usePopup } from "react";
import Popup from "reactjs-popup";
import ReactToPrint from "react-to-print";
import Report from '../Report/Report';
import ReportPopup from "../../Popups/ReportPopup";
import { Toast } from 'primereact/toast';
import authHeader from "../../authHeader";
import '../loader.css';
import { setLocale } from "yup";
import EmailPopup from "../../Popups/EmailPopup";
import Enumerable from 'linq';
import './BillGenerationForIndividualFlat.css';

function BillGenerationForIndividualFlat({ props }) {
    let history = useHistory();
    const societyId = parseInt(localStorage.getItem('societyId'));
    const societyName = localStorage.getItem('societyName');
    const tableName = sessionStorage.getItem('tblName');
    const societyService = new SocietyService();
    var converter = require('number-to-words');
    var [billedGLs, setBilledGLs] = useState([]);
    const [trueResponseData, setTrueResponse] = useState([]);
    const [isFlatChange, setIsFlatChange] = useState(false);
    const [billData, setBillData] = useState([]);
    const [saveMaintainance, setSaveMaintainance] = useState([]);
    const [wings, setWings] = useState([]);
    const [wingName, setWingName] = useState("");
    const [selectedWing, setSelectedWing] = useState();
    const [selectedFlatNo, setSelectedFlatNo] = useState();
    const [arrayData, setArrayData] = useState([]);
    const [cMaintainance, setCurrentMaintainance] = useState();
    const [CurrInterest, setCurrInterest] = useState();
    const [oSPrincipal, setOutStandingPrincipal] = useState();
    const [oSInterest, setOutstandingInterest] = useState();
    const [oSTotal, setOutStandingTotal] = useState();
    const [dpc, setDpc] = useState();
    const [oSDpc, setOsDpc] = useState(0);
    const [CurrBillTotal, setCurrBillTotal] = useState();
    const [tPayable, setTotalPayable] = useState();
    const [generatedbillsData, setGeneratedbillsData] = useState();

    const [flatNo, setFlatNo] = useState();
    var [index, setIndex] = useState(0);
    const [backButton, setBackDisabled] = useState(false);
    const [nextButton, setNextDisabled] = useState(false);
    const [initialForm, setInitialForm] = useState(true);
    const [SaveButton, setSaveDisabled] = useState(false);
    const [BillGenerates, setBillGeneratesDisabled] = useState(false);
    const [billDate, setBillDate] = useState();
    const [billFor, setBillFor] = useState([]);
    const [dueDate, setDueDate] = useState();
    const [billingCycle, setBillingCycle] = useState();
    const [interestType, setInterestType] = useState();
    const [lateInterestType, setLateInterestType] = useState();
    const [NoMaintainance, setNoMaintainance] = useState([]);
    const [SrNo, setSrNo] = useState();
    const [BillNo, setBillNo] = useState();
    var [MaxBillNo, setMaxBillNo] = useState();
    var [GenerationId, setMaxGenerationId] = useState();
    const [ownerName, setOwnerName] = useState();
    const [billForMonth, setBillForMonth] = useState();
    const [area, setArea] = useState();
    const [month, setMonth] = useState([]);
    const [billingTemplate, setBillTemplate] = useState([]);
    const [societyDetailsData, SetSocietyDetailsData] = useState([]);
    const [billFootNote, setBillFootNote] = useState([]);
    const [ButtonPopup, setButtonPopup] = useState(false);
    const [value1, setValue1] = useState(0);
    const [MailPopup, setMailPopup] = useState(false);
    const [billGenerated, setBillGenerated] = useState(false);
    const [progressspinner, setProgressSpinner] = useState(false);
    const toast = useRef(null);
    const interval = useRef(null);
    const [missingFlatsData, setMissingFlatsData] = useState([]);
    const [Generateloading, setGenerateloading] = useState(undefined);
    const [Saveloading, setSaveloading] = useState(undefined);
    const [completed, setcompleted] = useState(undefined);
    const [loadingLabelText, setLoadingLabelText] = useState('Loading..');
    const { errors, register, reset, handleSubmit, setValue } = useForm(
    );
    const [missingListOfFlatsForBillDate, setMissingListOfFlatsForBillDate] = useState([]);

    var MonthList;
    var Year;
    var Month;
    var FromYear;
    var FromMonth;

    useEffect(() => {
        setGenerateloading(false);
        setcompleted(true);
        setIsFlatChange(false);
        getSocietyData();
        BillingDetails();

        // societyService
        //     .getWing(societyId)
        //     .then((data) =>
        //         setWings(
        //             data.map(({ wing }) => ({ label: wing, value: wing })),

        //         ),
        //     );

        // societyService.getMissingListOfFlatsForBillDate(societyId,billDate,tableName).then((data) => {
        //     setMissingListOfFlatsForBillDate(data);
        // })

        societyService.getGlMasterIsBilling(societyId).then(data => {
            let tempArray = [];
            data.forEach(d => {
                tempArray = [...tempArray, d];
            })
            setBilledGLs(tempArray);
            console.log(billedGLs);
        });

        societyService.getMaxBillNo(societyId, tableName).then((data) => {
            setMaxBillNo(data);
        });

        societyService
        .getGeneratedBillsHistory(societyId)
        .then((data) =>
          setGeneratedbillsData(data)
        );
        //getting bill footNote
        societyService.getBillFootNote(societyId)
            .then(data => setBillFootNote(data));
    }, []);

    var date = new Date();
    const billsfor = (billingCycle) => {
        if (billingCycle === 'Monthly') {
            let Months = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`];
            let options = [];
            for (var pastYear = FromYear; pastYear < Year; pastYear++) {
                Months.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
            }
            Months.slice(0, parseInt(Month + 1)).
                forEach(q => options.push({ label: q + ' ' + Year, value: q + ' ' + Year }));
            setBillFor(options);
        }
        if (billingCycle === 'Quarterly') {
            let quarters = ['Jan-Feb-Mar', 'Apr-May-Jun', 'Jul-Aug-Sep', 'Oct-Nov-Dec'],
                options = [];
            for (var pastYear = FromYear; pastYear <= Year; pastYear++) {
                if (pastYear === FromYear) {
                    let monthFloor = Math.floor(FromMonth / 4);
                    let tempQuarters = Enumerable.from(quarters).where(a => quarters.indexOf(a) >= monthFloor).toArray();
                    tempQuarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
                }
                else if (pastYear === Year) {
                    let monthFloor = Math.floor(Month / 4);
                    let tempQuarters = Enumerable.from(quarters).where(a => quarters.indexOf(a) <= monthFloor).toArray();
                    tempQuarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
                }
                else {
                    let monthFloor = FromMonth / 4;
                    quarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
                }
                setBillFor(options);
            }

            if (billingCycle === 'Half-Yearly') {
                let HalfYearlyMonths = [`Jan-Feb-Mar-Apr-May-Jun`, `Jul-Aug-Sep-Oct-Nov-Dec`];
                let options = [];
                for (var pastYear = FromYear; pastYear < Year; pastYear++) {
                    HalfYearlyMonths.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
                }
                HalfYearlyMonths.slice(0, parseInt(Month / 6 + 1)).forEach(q => options.push({ label: q + ' ' + Year, value: q + ' ' + Year }));
                setBillFor(options);
            }

            if (billingCycle === 'Yearly') {
                let YearlyMonths = [`Jan-Feb-Mar-Apr-May-Jun-Jul-Aug-Sep-Oct-Nov-Dec`];
                let options = [];
                for (var pastYear = FromYear; pastYear < Year; pastYear++) {
                    YearlyMonths.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
                }
                YearlyMonths.slice(0, parseInt(Month / 11 + 1)).forEach(q => options.push({ label: q + ' ' + Year, value: q + ' ' + Year }));
                setBillFor(options);
            }
        }
    }
    const onBillForchange = (e) => {
        let Mno;
        //  Mno = parseInt(Mno);
        var selectedYear = e.currentTarget.value.match(/\d/g);
        selectedYear = selectedYear.join("");
        if (billingCycle === 'Quarterly') {
            if (e.currentTarget.value === `Jan-Feb-Mar ${selectedYear}`) {
                Mno = moment().month("Jan").format("M");
            }
            if (e.currentTarget.value === `Apr-May-Jun ${selectedYear}`) {
                Mno = moment().month("April").format("M");
            }
            if (e.currentTarget.value === `Jul-Aug-Sep ${selectedYear}`) {
                Mno = moment().month("July").format("M");
            }
            if (e.currentTarget.value === `Oct-Nov-Dec ${selectedYear}`) {
                Mno = moment().month("Oct").format("M");
            }
        }

        if (billingCycle === 'Half-Yearly') {
            if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun ${selectedYear}`) {
                Mno = moment().month("Jan").format("M");
            }
            if (e.currentTarget.value === `Jul-Aug-Sep-Oct-Nov-Dec ${selectedYear}`) {
                Mno = moment().month("July").format("M");
            }
        }

        if (billingCycle === 'Yearly') {
            if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun-Jul-Aug-Sep-Oct-Nov-Dec ${selectedYear}`) {
                Mno = moment().month("Jan").format("M");
            }
        }

        if (billingCycle === 'Monthly') {
            if (e.currentTarget.value === `Jan ${selectedYear}`) {
                Mno = moment().month("Jan").format("M");
            }
            if (e.currentTarget.value === `Feb ${selectedYear}`) {
                Mno = moment().month("Feb").format("M");
            }
            if (e.currentTarget.value === `Mar ${selectedYear}`) {
                Mno = moment().month("Mar").format("M");
            }
            if (e.currentTarget.value === `Apr ${selectedYear}`) {
                Mno = moment().month("April").format("M");
            }
            if (e.currentTarget.value === `May ${selectedYear}`) {
                Mno = moment().month("May").format("M");
            }
            if (e.currentTarget.value === `Jun ${selectedYear}`) {
                Mno = moment().month("June").format("M");
            }
            if (e.currentTarget.value === `Jul ${selectedYear}`) {
                Mno = moment().month("July").format("M");
            }
            if (e.currentTarget.value === `Aug ${selectedYear}`) {
                Mno = moment().month("Aug").format("M");
            }
            if (e.currentTarget.value === `Sep ${selectedYear}`) {
                Mno = moment().month("Sep").format("M");
            }
            if (e.currentTarget.value === `Oct ${selectedYear}`) {
                Mno = moment().month("Oct").format("M");
            }
            if (e.currentTarget.value === `Nov ${selectedYear}`) {
                Mno = moment().month("Nov").format("M");
            }
            if (e.currentTarget.value === `Dec ${selectedYear}`) {
                Mno = moment().month("Dec").format("M");
            }
        }
        let firstDay = new Date(selectedYear, Mno - 1, billingTemplate[0].billingDate + 1).toISOString().substr(0, 10);

        societyService.IsBillGenerated(societyId, firstDay, tableName).then((data) => {
            setBillGenerated(data.item1);
            if (data.item1) {
                societyService.getMissingListOfFlatsForBillDate(societyId, firstDay, tableName).then((data) => {
                    if (data === undefined || data.length <= 0) {
                        toast.current.show({ severity: 'info', detail: "No missing flats for this Bill Date", sticky: true });
                        //return;     
                    }
                    setMissingListOfFlatsForBillDate(data);
                    setWings(data);
                }).catch(err => {
                    console.log(err);
                    toast.current.show({ severity: 'failure', detail: "Error while finding missing flats", sticky: true });
                })

               // if (data.item1) {
                    let billItem = generatedbillsData.find(a => a.generationId == data.item2);
                    let formattedDate = moment(billItem.dueDate).format('DD-MM-YYYY');
                    let tempDate = new Date(billItem.dueDate);
                    let t =moment(billItem.dueDate).format('YYYY-MM-DD');
                    setDueDate( t);
                    //setValuetempDate('dueDate', billItem.dueDate);
                 //   let tmpBillDate= new Date(billItem.billDate);
                   let tBilldate =moment(billItem.billDate).format('YYYY-MM-DD');
            
                  // let tempDate2 = new Date(billItem.billDate);
                   setBillDate(tBilldate);
                //}
            }
            else {
                toast.current.show({
                    severity: 'warn', detail: "There is no Regular bill generated for this month. So" +
                        " no missing flat for this month", sticky: true
                });
            }

        }).catch(err => {
            console.log(err);
            toast.current.show({ severity: 'failure', details: "Error while checking missing flats bills generated for this month", sticky: true });
        });

        let dueDay = new Date(firstDay);
        dueDay.setDate(dueDay.getDate() + billingTemplate[0].dueDate - 1);

      //  setDueDate(dueDay.toISOString().substr(0, 10));
       // setBillDate(firstDay);
        setBillForMonth(e.currentTarget.value);
        console.warn(billForMonth);


    }

    function compare(a, b) {
        if (a.glId < b.glId) {
            return -1;
        }
        if (a.glId > b.glId) {
            return 1;
        }
        return 0;
    }

    const getSocietyData = () => {
        societyService.getSocietyDetailsOnID(societyId).then(data => {
            SetSocietyDetailsData(data);
            Year = moment(data[0].to).year();
            FromYear = moment(data[0].fyFrom).year();
            Month = moment(data[0].to).month() + 1;
            FromMonth = moment(data[0].fyFrom).month() + 1;
        })
    }

    const BillingDetails = () => {
        societyService.getBillingDetailsOfSociety(societyId).then((billingTemplate) => {
            let date = new Date();
            billsfor(billingTemplate[0].billingCycle);
            setBillingCycle(billingTemplate[0].billingCycle);
            setInterestType(billingTemplate[0].interestType);
            setLateInterestType(billingTemplate[0].latePaymentType);
            setBillTemplate(billingTemplate);
        });
    }

    const onGenerate = (data, e) => {
        if (missingListOfFlatsForBillDate === undefined || missingListOfFlatsForBillDate === null || missingListOfFlatsForBillDate.length <= 0) {
            toast.current.show({ severity: 'warn', detail: 'No missing flats for this bill date!' });
        
            return;
        }

        setGenerateloading(true);
        setLoadingLabelText('Generating Bills...');
        // setTimeout(() => {
        let billSrNo = 1;
        let indx = 0;
        if (data.billFor === '') {
            toast.current.show("error", "Bill cycle is not set !")
            return;
        }

        societyService.getBillData(societyId, data.billDate, tableName).then((data) => {
            setSaveMaintainance(data);
            setGenerateloading(false);
            toast.current.show({ severity: 'success', detail: "Bills Generated Succesfully..!" });
            data.forEach(item => {
                if (item.success) {
                    item.billSrNo = billSrNo;
                    item.billNo = MaxBillNo;
                    item.billDate = billDate;
                    item.dueDate = dueDate;
                    item.societyId = societyId;
                    item.societyName = societyName;
                    item.billFor = billForMonth;
                    item.narration = `Bill For the month ${billForMonth}`;
                    let srNo = 1;
                    item.currentTotalFromGLHeads = Math.round(item.currentTotalFromGLHeads);
                    item.currentInterest = Math.round(item.currentInterest);
                    item.dpc = Math.round(item.dpc);
                    let currBillTotal = item?.currentTotalFromGLHeads + item?.currentInterest + item?.dpc;
                    item.oustandingsArrears = Math.round(item.oustandingsArrears);
                    item.interestArrears = Math.round(item.interestArrears);
                    item.dpcArrears = Math.round(item.dpcArrears);
                    item.totalArrears = item?.oustandingsArrears + item?.interestArrears + item?.dpcArrears;
                    // item.totalArrears = Math.round(item.totalOutstanding.toFixed(2));
                    // let totalPayable = currBillTotal + oustandingTotal;
                    trueResponseData.totalAmountPayable = Math.round(item.totalOutstandings);
                    item.totalOutstandings = Math.round(item.totalOutstandings.toFixed(2));
                    item.maintainanceInfos.forEach(a => {
                        a.serialNo = srNo++;
                    })

                    billedGLs.forEach(billedItem => {
                        let itemRetrieved = item.maintainanceInfos.find(a => a.glId === billedItem.glId);
                        if (itemRetrieved != undefined) {
                            item.glTitle = billedItem.glTitle;
                        }
                        else {
                            item.maintainanceInfos.push({ glId: billedItem.glId, flatId: item.flatId, flatNo: item.flatNo, area: 0, amount: 0, glTitle: billedItem.glTitle, serialNo: srNo++ });
                        }
                    })

                    trueResponseData.push(item);
                    onChangeFlat(indx);
                    setArrayData(trueResponseData[index].maintainanceInfos);
                    setSrNo(trueResponseData[index].billSrNo);
                    setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
                    setOwnerName(trueResponseData[index].name);
                    BillNumber(index);
                    setArea(trueResponseData[index].area);
                    setTrueResponse(trueResponseData);
                    var str = "" + item.billNo;
                    var pad = "00000";
                    var ans = pad.substring(0, pad.length - str.length) + str;
                    item.billInvNo = `${"INV"}${ans}`;
                    billSrNo++;
                    MaxBillNo++;
                    indx++;
                }
                else {
                    NoMaintainance.push({ FlatNo: item.flatNo, ErrorMsg: item.errormsg })
                }
            });
            if (data.length === 1) {
                setNextDisabled(true);
                setBackDisabled(true);
            }
            setBillData(data);

            setInitialForm(false);
            onChangeFlat(index);
        }
        ).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'failure', detail: "Error in Generating Bill !" });
        });
        // }, 1000);
    };

    const BillNumber = (index) => {
        var str = "" + trueResponseData[index].billNo;
        var pad = "00000";
        var ans = pad.substring(0, pad.length - str.length) + str;
        setBillNo(`${"INV"}${ans}`);
    }

    const onNext = (e) => {
        index = index + 1;
        if (trueResponseData.length - 1 === index) {
            setNextDisabled(true);
        }
        else {
            setBackDisabled(false);
        }
        setSrNo(trueResponseData[index].billSrNo);
        setOwnerName(trueResponseData[index].name);
        setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
        BillNumber(index);
        setArrayData(trueResponseData[index].maintainanceInfos);
        onChangeFlat(index);
        setArea(trueResponseData[index].area);
        setIndex(index);
    }

    const onBack = (e) => {
        index = index - 1;
        if (index === 0) {
            setBackDisabled(true);
        }
        else {
            setNextDisabled(false);
        }
        setSrNo(trueResponseData[index].billSrNo);
        setOwnerName(trueResponseData[index].name);
        setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
        BillNumber(index);
        setArrayData(trueResponseData[index].maintainanceInfos);
        onChangeFlat(index);
        setArea(trueResponseData[index].area);
        setIndex(index);
    }

    const onFirstBill = (e) => {
        index = 0;
        setBackDisabled(true);
        setNextDisabled(false);
        setOwnerName(trueResponseData[index].name);
        setSrNo(trueResponseData[index].billSrNo);
        setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
        BillNumber(index);
        setArrayData(trueResponseData[index].maintainanceInfos);
        onChangeFlat(index);
        setArea(trueResponseData[index].area);
        setIndex(index);
    }

    const onLastBill = (e) => {
        index = trueResponseData.length - 1;
        setNextDisabled(true);
        setBackDisabled(false);
        setOwnerName(trueResponseData[index].name);
        setSrNo(trueResponseData[index].billSrNo);
        setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
        BillNumber(index);
        setArrayData(trueResponseData[index].maintainanceInfos);
        onChangeFlat(index);
        setArea(trueResponseData[index].area);
        setIndex(index);
    }


    const onChangeFlat = (index) => {
        setCurrentMaintainance(trueResponseData[index]?.currentTotalFromGLHeads);
        setCurrInterest(trueResponseData[index]?.currentInterest);
        setDpc(Math.round(trueResponseData[index]?.dpc));
        let currBillTotal = trueResponseData[index]?.currentTotalFromGLHeads + trueResponseData[index]?.currentInterest + Math.round(trueResponseData[index]?.dpc);
        setCurrBillTotal(Math.round(currBillTotal.toFixed(2)));
        setOutStandingPrincipal(Math.round(trueResponseData[index]?.oustandingsArrears));
        setOutstandingInterest(Math.round(trueResponseData[index]?.interestArrears.toFixed(2)));
        setOsDpc(Math.round(trueResponseData[index]?.dpcArrears.toFixed(2)));
        let oustandingTotal = trueResponseData[index]?.oustandingsArrears + trueResponseData[index]?.interestArrears + trueResponseData[index]?.dpcArrears;
        setOutStandingTotal(Math.round(oustandingTotal.toFixed(2)));
        trueResponseData[index].totalArrears = Math.round(oustandingTotal.toFixed(2));
        let totalPayable = currBillTotal + oustandingTotal;
        setTotalPayable(Math.round(totalPayable.toFixed(2)));
        trueResponseData[index].totalAmountPayable = Math.round(totalPayable.toFixed(2));
        trueResponseData[index].amountInWord = converter.toWords(Math.round(totalPayable.toFixed(2)))
    }

    const onSave = (e) => {
        setSaveloading(true);
        setLoadingLabelText('Saving Bills...');
        // setTimeout(() => {
        axios.post(`${Config.apiUrl}/BillGerationForIndividualFlat/SaveMaintenance?societyId=${societyId}&societyName=${societyName}&billingDate=${billDate}&transactionTableName=${tableName}`, trueResponseData, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    setSaveloading(false);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
                    setSaveDisabled(true);
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
                }
            }).catch(error => {
                console.log(error)
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Error in saving data!" });
                setSaveloading(false);

            })
        // }, 2000);
    }

    const onChangeWing = (e) => {
        let wing = e.value;
        setSelectedWing(wing);
        let remainingFlatNo = missingListOfFlatsForBillDate.filter(b => b.wing === wing)
        setFlatNo(remainingFlatNo);

    }

    /*  const onChangeFlat = (e) => {
         let flat = e.currentTarget.value
         setSelectedFlat(flat);
     } */


    const InputFlatNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setFlatNo(onlyNums);
    }



    const onPrintBill = () => {
        setButtonPopup(true);
    }

    const onSendMail = () => {
        setMailPopup(true);
    }

    const leftContents = (
        <React.Fragment>
            <Button icon="pi pi-plus-circle" disabled className="p-button-danger p-mr-2" />
            <Button icon="pi pi-arrow-up" className="p-button-danger p-mr-2" tooltip="First Bill" onClick={handleSubmit(onFirstBill)} />
            <Button icon="pi pi-arrow-left" className="p-button-danger p-mr-2" disabled={backButton} onClick={handleSubmit(onBack)} />
            <Button icon="pi pi-arrow-right" className="p-button-danger p-mr-2" disabled={nextButton} onClick={handleSubmit(onNext)} />
            <Button icon="pi pi-arrow-down" className="p-button-danger p-mr-2" tooltip="Last Bill" onClick={handleSubmit(onLastBill)} />
            <Button icon="pi pi-print" className="p-button-danger p-mr-2" tooltip="Print Bill" onClick={handleSubmit(onPrintBill)} />
            <Button icon="pi pi-save" className="p-button-danger p-mr-2" tooltip="Save Bill" disabled={SaveButton} onClick={onSave} />
            <Button icon="pi pi-trash" className="p-button-danger p-mr-2" disabled tooltip="Delete Bill" />
            <Button icon="pi pi-info-circle" disabled className="p-button-danger p-mr-2" />
            <Button icon="pi pi-envelope" className="p-button-danger p-mr-2" tooltip="Send Mail" onClick={handleSubmit(onSendMail)} />
        </React.Fragment>
    );

    return (
        <form onsubmit="return false;" className="BillGenerationForIndividualFlat">
            <Toast ref={toast} position={"center"} />
            <div className="Form_inline">
                <div>
                    {Generateloading ? (
                        <div className="spinner">
                            <span>{loadingLabelText}</span>
                            <div className="half-spinner"></div>
                        </div>
                    ) : (
                        <>
                            <div style={{ border: "1px solid black" }}>
                                <div classname="initialInfo" style={{ display: initialForm ? "block" : "none" }}>
                                    <div className="Form_inline">
                                        <div className="p-field p-grid ">
                                            <label className="p-col-fixed labelWidth">
                                                Bill For:
                                            </label>
                                            <div className="p-col">
                                                <select name="billFor" ref={register} className="TextBoxSize150" onChange={onBillForchange} >
                                                    <option>Select Month</option>
                                                    {billFor.map(({ label, value: Months }) => (
                                                        <option key={Months} value={Months}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Form_inline">
                                        <div className="p-field p-grid">
                                            <label className="p-col-fixed labelWidth">
                                                Wing :
                                            </label>
                                            {/* <div className="p-col">
                                            <select style={{ height: '25px', width: '110px' }} name="wing" onChange={onChangeWing} value={selectedWing}  >
                                                <option hidden value="">Select Wing</option>
                                                {wings.map(({ label, value: wings }) => (
                                                    <option key={wings} value={wings}>
                                                        {label}
                                                    </option>
                                                ))}
                                            </select>
                                            </div> */}
                                            <Dropdown value={selectedWing} onChange={onChangeWing} options={wings} optionLabel="wing"
                                                placeholder="Select a Wing" className="w-full md:w-14rem" />

                                        </div>
                                    </div>
                                    <div className="Form_inline">
                                        <div className="p-field p-grid">
                                            <label className="p-col-fixed labelWidth" >Flat No:</label>
                                            {/* <div className="p-col">
                                            <input type="text" style={{ height: '25px', width: '110px' }} name="flatNo" value={flatNo} onChange={onChangeFlat} onInput={InputFlatNo} />
                                            </div> */}
                                            <Dropdown value={selectedFlatNo} onChange={(e) => setSelectedFlatNo(e.value)} options={wings} optionLabel="flatNo"
                                                placeholder="Select a Flat No" className="w-full md:w-14rem" />
                                        </div>
                                    </div>




                                    <div className="Form_inline">
                                        <div className="p-field p-grid ">
                                            <label className="p-col-fixed labelWidth">
                                                Bill Date:
                                            </label>
                                            <div className="p-col">
                                                <input
                                                    type="Date"
                                                    name="billDate"
                                                    className="DateBox"
                                                    style={{ height: "30px" }}
                                                    ref={register}
                                                    value={billDate}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="Form_inline">
                                        <div className="p-field p-grid ">
                                            <label className="p-col-fixed labelWidth">
                                                Due Date:
                                            </label>
                                            <div className="p-col">
                                                <input
                                                    type="Date"
                                                    name="dueDate"
                                                    className="DateBox"
                                                    style={{ height: "30px" }}
                                                    ref={register}
                                                    value={dueDate}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-formgroup-inline" >
                                        <div className="p-field p-grid ">
                                            <label className="p-col-fixed labelWidth" >
                                                Billing Cycle:
                                            </label>
                                            <div className="p-col">
                                                <input
                                                    type="text"
                                                    name="billingCycle"
                                                    className="TextBoxSize150"
                                                    style={{ height: "30px" }}
                                                    ref={register}
                                                    value={billingCycle}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-formgroup-inline">
                                        <div className="p-field p-grid ">
                                            <label className="p-col-fixed labelWidth">
                                                Interest Type:
                                            </label>
                                            <div className="p-col">
                                                <input
                                                    type="text"
                                                    name="interestType"
                                                    className="TextBoxSize150"
                                                    style={{ height: "30px" }}
                                                    ref={register}
                                                    value={interestType}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-formgroup-inline">
                                        <div className="p-field p-grid ">
                                            <label className="p-col-fixed labelWidth">
                                                Late Interest Type:
                                            </label>
                                            <div className="p-col">
                                                <input
                                                    type="text"
                                                    name="latePaymentType"
                                                    className="TextBoxSize150"
                                                    ref={register}
                                                    value={lateInterestType}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-formgroup-inline" style={{ margin: "20", justifyContent: 'right' }}>
                                        <div className="p-field">
                                            <Button
                                                label="GENERATE"
                                                type="submit "
                                                disabled={!billGenerated || missingFlatsData===undefined|| missingFlatsData.length===0}
                                                onClick={handleSubmit(onGenerate)}
                                            />
                                        </div>
                                        {/*                                     <div className="p-field ">
                                            <Button label="Cancel" />
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {Saveloading ? (
                        <div className="spinner">
                            <span>{loadingLabelText}</span>
                            <div className="half-spinner"></div>
                        </div>
                    ) : (

                        <>

                            <div style={{
                                border: "1px solid black",
                                width: 'auto',
                                backgroundColor: "Info",
                                height: "100%",
                                margin: '10px',
                            }}>
                                <div className="billForm" style={{ display: !initialForm ? "block" : "none" }}>
                                    <div>
                                        <Toolbar left={leftContents} />
                                    </div>
                                    <div style={{ border: "1px solid black", padding: '3px', height: "100px" }}>
                                        <div className="Form_inline">
                                            <label>
                                                Flat No:
                                            </label>
                                            <input
                                                type="text"
                                                className="TextBoxSize150"
                                                name="flatNo"
                                                ref={register}
                                                value={flatNo}
                                            />

                                            <label>
                                                Name:
                                            </label>
                                            <input
                                                type="text"
                                                className="MediumTextBox"
                                                name="name"
                                                ref={register}
                                                value={ownerName}
                                            />

                                            <label className="Label" >
                                                Sr:
                                            </label>
                                            <input
                                                type="text"
                                                name="sr"
                                                className="TextBoxSize100"
                                                ref={register}
                                                value={SrNo}
                                            />
                                        </div>


                                        <div className="Form_inline">
                                            <label >
                                                Bill For:
                                            </label>
                                            <input
                                                type="text"
                                                className="TextBoxSize150"
                                                name="billFor"
                                                ref={register}
                                                value={billForMonth}
                                            />

                                            <label>
                                                Bill Date:
                                            </label>
                                            <input
                                                type="Date"
                                                className="DateBox"
                                                name="billDate"
                                                ref={register}
                                                value={billDate}
                                            />

                                            <label>
                                                Due Date:
                                            </label>
                                            <input
                                                type="Date"
                                                name="dueDate"
                                                className="DateBox"
                                                ref={register}
                                                value={dueDate}
                                            />

                                        </div>

                                        <div className="Form_inline">
                                            <label >
                                                Bill No:
                                            </label>
                                            <input
                                                type="text"
                                                className="TextBoxSize150"
                                                name="billNo"
                                                ref={register}
                                                value={BillNo}
                                            />

                                            <label >
                                                Society:
                                            </label>
                                            <input
                                                type="text"
                                                className="MediumTextBox"
                                                name="society"
                                                ref={register}
                                                value={societyName}
                                            />

                                            <label className="Label" >
                                                Area:
                                            </label>
                                            <input
                                                type="text"
                                                className="TextBoxSize100"
                                                name="area"
                                                ref={register}
                                                value={area}
                                            />
                                        </div>

                                    </div>
                                    <div className="billTable" style={{ minHeight: "20%", width: "850px" }}>
                                        <DataTable value={arrayData} className="p-datatable-sm" scrollable scrollHeight='200px' showGridlines>
                                            <Column field="serialNo" header="Sr" style={{ width: "80px" }}></Column>
                                            <Column field="glTitle" header="Particulars"></Column>
                                            <Column field="amount" header="Amount" style={{ width: "170px" }}></Column>
                                        </DataTable>
                                    </div>

                                    <div style={{ marginLeft: '450px' }}  >
                                        <div className="Form_inline">
                                            <label className="ValueLabel"> TOTAL: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={cMaintainance} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> CURR.INTEREST: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={CurrInterest} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> DPC: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={dpc} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> CURR.BILL TOTAL: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} ref={register} value={CurrBillTotal} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> O/S PRINCIPAL: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={oSPrincipal} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> O/S INTEREST: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={oSInterest} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> O/S DPC: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={oSDpc} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> O/S TOTAL: </label>
                                            <input type="text" readOnly style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} ref={register} value={oSTotal} />
                                        </div>

                                        <div className="Form_inline">
                                            <label className="ValueLabel"> TOTAL AMOUNT PAYABLE: </label>
                                            <input type="text" readOnly ref={register} value={tPayable} style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={{ display: !initialForm ? "block" : "none" }}>
                                <div style={{
                                    height: "auto",
                                    margin: "10px"
                                }}>

                                    <div className="NoMaintainance" style={{ minHeight: "20%", width: "800px" }}>
                                        <DataTable value={NoMaintainance} className="p-datatable-sm" scrollable scrollHeight='200px' showGridlines>
                                            <Column field="FlatNo" header="Flat No" style={{ width: "60px" }}></Column>
                                            <Column field="ErrorMsg" header="Remark" style={{ width: "390px", color: "red" }}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <ReportPopup trigger={ButtonPopup} setTrigger={setButtonPopup} data={{ saveMaintainance, societyDetailsData, billFootNote }}>
                </ReportPopup>
                <EmailPopup trigger={MailPopup} setTrigger={setMailPopup} data={{ saveMaintainance, societyDetailsData, billFootNote }}>
                </EmailPopup>
            </div>

        </form >
    );
}
export default BillGenerationForIndividualFlat; 