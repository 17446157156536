import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
// import logo from './logo.svg';
// import Register from './Register';
// import Nav from './Nav';
// import Login from './Login';
// import { AutoComplete } from 'primereact/autocomplete';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import './SubGroupMasterUi.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useRef } from 'react';
import SocietyService from '../../Service/SocietyService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../../authHeader';
import { useHistory } from 'react-router-dom';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";




function SubGroupMasterUi() {
  const selectedValue = '';
  let history = useHistory();
  const toast = useRef(null);
  const [SubGmData, setSubGmData] = useState(null);
  const [product, setProduct] = useState([]);
  const [id, setId] = useState(null);
  const [selectedProducts3, setSelectedProducts3] = useState(null);
  // const [deleteId,setDeleteId]=useState(-1);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  localStorage.setItem('uiName', 'Sub-Group Master');
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  var societyId = parseInt(localStorage.getItem('societyId'));
  const [DisableButton, setDisableButton] = useState(true);
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [inputValue, setInputValue] = useState('');




  const societyService = new SocietyService();

  const validationSchema = Yup.object().shape({

    
    // GroupId: Yup.string()
    //     .required('GroupId is required,please Genarate Id'),
    SubGroupName: Yup.string()
      .required(' Sub-Group is required')
  });


  useEffect(() => {

    societyService.getSubGroupMasterData(societyId).then(data => setSubGmData(data));
    console.warn(SubGmData);

    societyService.getGroupName(societyId).then(data => setProduct(data.
      map(({ groupName }) => ({ label: groupName, value: groupName }))
    ));
    // console.warn(product);
    societyService.getSubGroupId(societyId).then(data => setId(data));
    console.warn(id);

    if (accessLevel >= 2) {
      setDisableButton(false);
      setBlockedPanel(false);
    }
    else {
      setDisableButton(true);
      setBlockedPanel(true);
    }


  }, []);
  function capitalizeEachWord(inputString) {
    return inputString
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  
  // Example usage
  const originalString = 'Kirtee kirtee';
  const formattedString = capitalizeEachWord(originalString);
  console.log(formattedString);  // Output: 'Kirtee Kirtee'
  
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    const formattedValue = capitalizeEachWord(newValue);
    setInputValue(formattedValue);
  };
  

  const { register, handleSubmit, reset, errors } = useForm(
    { resolver: yupResolver(validationSchema) }
  );

  const onSubmit = (data) => {
    data.societyId=societyId;
     // Check for duplicate subgroup name
  const isDuplicate  = SubGmData.some(item => item.subGroupName === data.SubGroupName && item.GroupName === data.GroupName);

  if (isDuplicate ) {
    toast.current.show({ severity: 'error', sticky: true, detail: 'Sub-Group with the same name already exists' });
    return; // Do not proceed with the submission
  }

  setSpinnerVisible(true);
axios.post(`${Config.apiUrl}/SubGroupMaster`, data,{headers:authHeader()})
      .then(response => {
        console.log(response)
        if (response.data.success) {
          toast.current.show({ severity: 'success',sticky:true, detail: response.data.msg });
        }
        else {
          toast.current.show({ severity: 'error',sticky:true, detail: response.data.msg });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
    var addData = { "subGroupId": data.SubGroupId, "groupName": data.GroupName, "subGroupName": data.SubGroupName }
    SubGmData.push(addData);
    setId(id + 1);
    reset();

  }


  const onCancel = (data) => {
    reset();
    
    window.location.reload();
  }

  const onRemove=()=>{
    // setStickyMode(false);
    window.location.reload();
  }
  const dataTableEdit = {
    'products': setSubGmData
  };



  const onEditorValueChange = (productKey, props, value) => {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    dataTableEdit[`${productKey}`](updatedProducts);

  }


  const inputTextEditor = (productKey, props, field) => {

    return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
  }

  const codeEditor = (productKey, props) => {
    return inputTextEditor(productKey, props, 'Group Name');
  }
  let originalRows = {};
  let editingCellRows = {};

  const onRowEditInit = (event) => {
    originalRows[event.index] = { ...SubGmData[event.index] };
    // societyService.putGroupMaster(originalRows);
  }
  const onRowEditSave = (event) => {
    originalRows[event.index] = { ...SubGmData[event.index] };
    let data = event.data;
    let id = event.data.subGroupId;
    setSpinnerVisible(true);
    return axios.put(`${Config.apiUrl}/SubGroupMaster/update?id=${id}&societyId=${societyId}`, data,{headers:authHeader()})
      .then(response => {
        console.log(response)
        if (response.data.success) {
          toast.current.show({ severity: 'success',sticky:true, detail: response.data.msg });
        }
        else {
          toast.current.show({ severity: 'error', sticky:true, detail: response.data.msg });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
      })
  }


  const onRowEditCancel = (event) => {
    let product = [...SubGmData];
    SubGmData[event.index] = originalRows[event.index];
    delete originalRows[event.index];

    setSubGmData(product);
  }

  const onDisplayIndex = (e) => {
      history.push("/DisplayIndexSetting");
  }


  const onDelete = (event) => {
    if(selectedRadioData != null){

   
    toast.current.show({
      severity: 'warn', sticky: true, content: (
        <div className="p-flex p-flex-column" style={{ flex: '1' }}>
          <div className="p-text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>Are you sure?</h4>
            <p>Confirm to Delete</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
            </div>
            <div className="p-col-6">
              <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
            </div>
          </div>
        </div>
      )
    });
  }
  else{
    toast.current.show({ severity: 'warn', detail: "SELECT ROW FIRST" });
  }
  }
  

  const onYes = () => {
    toast.current.clear();
    setSpinnerVisible(true);
    //personalDetailsData.splice(index,1);
    selectedRadioData.forEach(d => {
      let id = d.subGroupId;
      let data = SubGmData.filter(b => b.subGroupId != d.subGroupId);
      setSubGmData(data);
      axios.delete(`${Config.apiUrl}/SubGroupMaster/delete?id=${id}&societyId=${societyId}`,{headers:authHeader()})
        .then(response => {
          console.log(response)
          if (response.data.success) {
            toast.current.show({ severity: 'success',sticky:true, detail: response.data.msg });
          }
          else {
            toast.current.show({ severity: 'error',sticky:true, detail: response.data.msg });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=>{
          setSpinnerVisible(false);
        });

    })
  }

  const onNo = () => {
    toast.current.clear();
    return;
  };
  const header = (
    <div className="table-header">
      SUB-GROUP MASTER
      <Button label="View Display Index" className="displayBtn" style={{width:'24%',height:'35px',alignItems: 'unset'
}} onClick={onDisplayIndex} disabled={DisableButton} />
      <Button icon="pi pi-trash"  value="Delete" tooltip="Delete" className="btn"style={{background:'red',margin:'20px',width:'49px',height:'40px'}} onClick={onDelete} disabled={DisableButton} />

    </div>
  );


  return (
    <div className="SubGroupMasterUi" >
  <Card className="BackgroundColor" >
    <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
    <Toast ref={toast} position="center" onRemove={onRemove} />

    <div className="SubGroupMaster">
      <div>
        <label>Sub-Group Id<span style={{ color: 'red' }}>*</span>:</label>
        <input type="text" name="SubGroupId" ref={register} value={id} readOnly />
      </div>
      <div>
        <label>Sub-Group Name<span style={{ color: 'red' }}>*</span>:</label>
        <input
          type="text"
          name="SubGroupName"
          value={inputValue}
          onChange={handleInputChange}
          autoFocus={focusValue}
          autoComplete="off"
        />
        <div className="invalid-feedback">{/* Add your validation error message here */}</div>
      </div>
      <div>
        <label>Group Name<span style={{ color: 'red' }}>*</span>:</label>
        <select className="Select" name="GroupName" ref={register} autoComplete="off">
          {product.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>

      
    </div>
    <div className="button">
        <Button label="SAVE" type="submit" onClick={handleSubmit(onSubmit)} disabled={DisableButton} />
        <Button label="RESET" type="submit" onClick={onCancel} disabled={DisableButton} />
      </div>

    <div className="dataTable" style={{marginTop:'20px',width:'60%'}}>
      <BlockUI blocked={blockedPanel}>
        <DataTable value={SubGmData} scrollHeight='350px' scrollable className="p-datatable-gridlines" style={{width:'1000px'}} header={header} 
          editMode="row"onRowEditInit={onRowEditInit}onRowEditCancel={onRowEditCancel}onRowEditSave={onRowEditSave}selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}>
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
          <Column field="subGroupId" header="Sub-Group Id" filter style={{ width: "100px" }}></Column>
          <Column field="subGroupName" header="Sub- Group Name" editor={(props) => codeEditor('products', props)} filter style={{ width: "300px" }}></Column>
          <Column field="groupName" header="Group Name" filter style={{ width: "150px" }}></Column>
          <Column rowEditor header="Action" headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} style={{ width: "50px" }}></Column>
        </DataTable>
      </BlockUI>
    </div>
  </Card>
</div>

  )
}
export default SubGroupMasterUi;
