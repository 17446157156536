import { SocietyContext } from "./SocietyContext";
import React, { useRef, useContext, useState, useEffect } from 'react';
import logo from "./images/app logo.png";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SocietyService from './Service/SocietyService';
import authHeader from './Service/SocietyService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import './Homepage.css';

function HomePage(){

  const societyName = localStorage.getItem('societyName');
  const [MemberDetails, setMemberDetails] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const societyService = new SocietyService();
  var societyId = parseInt(localStorage.getItem('societyId'));
  const tableName = sessionStorage.getItem('tblName');

  const header = (
    <div className="table-header">
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
        </span>
    </div>
);
const statuses = [
  'dues', 'advance', 'nill'
];

const statusTemplate = (rowData) => {
  return <span className={`product-badge status-${(rowData.status ? rowData.status.toLowerCase() : '')}`}>{rowData.status}</span>;
}

// const statusFilterTemplate = (options) => {
//   return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
// }

// const statusItemTemplate = (option) => {
//   return <span className={`customer-badge status-${option}`}>{option}</span>;
// }

    useEffect(() => {
      societyService.getPersonalDetails(societyId,tableName).then(data => {
        var srNo=1;
        data.forEach(d=>{
          
          d.serialNo=srNo;
          
          srNo++;
          d.unitNo=`${d.wing}-${d.flatNo}`
          if(d.email===null)
          {
            d.email='---';
          }
          if(d.mobile==='')
          {
            d.mobile='---';
          }
          if(d.dues > 0)
          {
            d.status="DUES"
          }
          if(d.dues < 0)
          {
            d.status="ADVANCE"
          }
          if(d.dues === 0)
          {
            d.status="NILL"
          }
        })
        setMemberDetails(data)
      }
        );
     //   console.warn(MemberDetails);
    },[]);

    return(
        <form className="homepage">
        <div className="p-formgroup-inline">
        <div>
        <img src={logo} alt="logo" height={100} width={300} />
        </div>
          <div style={{marginLeft:'200px'}}>
           <h3>
            Welcome To {societyName.toUpperCase()}
            </h3>
          </div>
        </div>

            <Card className="BackgroundColor" >
            <DataTable  value={MemberDetails} width="1100px" className="p-datatable-sm"  paginator rows={8} header={header} globalFilter={globalFilter}  >
              <Column field="serialNo" header="Sr No" style={{width:'100px'}}></Column>
              <Column field="unitNo" header="Unit No" style={{width:'100px'}} ></Column>
              {/* <Column field="flatNo" header="Flat No" style={{width:'100px'}}></Column> */}
              <Column field="name" header="Member Name" style={{width:'300px'}}></Column>
              <Column field="mobile" header="Mobile Number" style={{width:'100px'}}></Column>
              <Column field="dues" header="Dues" sortable  style={{width:'100px'}}></Column>
              <Column field="status" header="Status"  body={statusTemplate} style={{width:'100px'}} sortable ></Column>

              


              <Column field="email" header="Email Id" style={{width:'300px'}}></Column>
            </DataTable>
          </Card>
        </form>
    );
}
export default HomePage;