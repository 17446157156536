import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import './BankReport.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../loader.css';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var selectedDate;
var BillData = [];
var BankReportDatas = [];
var fDateOnReport;
var tDateOnReport;
var openBal;
var creditSum;
var debitSum;
var creditDebitSum;
var generationId;
var selectedDate;
var isBillingData;


function BankReport({ props }) {

    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    const societyService = new SocietyService();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [BillDateValue, setBillDateValue] = useState(null);
    const [selectedBillDate, setSelectedBillDate] = useState([]);
    const [BillDate, setBillDate] = useState();
    const [BillDatas, setBillData] = useState([]);
    const [IsBillingTrueData, setIsBillingData] = useState([]);
    isBillingData = IsBillingTrueData;
    const [isPrintTrue, setIsPrintTrue] = useState(false);
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [societyBank, setSocietyBank] = useState([]);
    const [BankReportData, setBankReportData] = useState([]);
    const [BankReportGridData, setBankReportGridData] = useState([]);
    const [selBank, setSelectedBank] = useState();
    const [selectedBankGlId, setSelectedBankGlId] = useState([]);
    const [orderBy, setOrderBy] = useState('scroll_No');
    const [openingBalance, setOpeningBalance] = useState();
    const [Generateloading, setGenerateloading] = useState(undefined);
    const [onView, setOnView] = useState(undefined);
    const [isPrintDisabled, setIsPrintDisabled] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const mode = "bank balance";
    const tableName = sessionStorage.getItem('tblName');
    BillData = BillDatas;
    selectedDate = BillDateValue;
    BankReportDatas = BankReportGridData;
    fDateOnReport = moment(new Date(fromDate)).format('DD-MM-YYYY');
    tDateOnReport = moment(new Date(toDate)).format('DD-MM-YYYY');
    openBal = openingBalance;

    useEffect(() => {
        setGenerateloading(false);
        setOnView(false);
        societyService.getSocietyDetails(societyId).then(data => {
            SetSocietyDetailsData(data);
        });

        societyService.GetSocietyBank(societyId, mode.toUpperCase()).then((data) => {
            setSocietyBank(data);

        })
    }, []);

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onChangeSelectedBank = (e) => {
        let selectedBank = e.currentTarget.value;
        console.warn(selectedBank);
        setSelectedBank(selectedBank);
        setValue('societyBank', selectedBank);
        let selectedGlId = societyBank.find(a => a.glTitle === selectedBank);
        setSelectedBankGlId(selectedGlId.glId)
    }

    let fDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
    let tDate = moment(new Date(toDate)).format('YYYY-MM-DD');
    const viewBills = (e) => {
        let undefinedList = [];
        if (selBank === undefined) {
            undefinedList.push({ label: "select Bank " });

        }
        if (fromDate === undefined) {
            undefinedList.push({ label: "select From date " });
        }
        if (toDate === undefined) {
            undefinedList.push({ label: "select To date " });
        }

        let message = "Please";
        undefinedList.forEach(a => {
            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            toast.current.show({ severity: 'warn', sticky: true, detail: message });
        }
        else {
            setSpinnerVisible(true);
            setIsPrintDisabled(false);
            societyService.getOpeningBalance(societyId, fDate, selectedBankGlId, mode,tableName).then(data => {
                setOpeningBalance(data);
                openBal = data;

                societyService.getBankReport(societyId, fDate, tDate, selectedBankGlId, orderBy,tableName).then(bankData => {
                    let oBal = openBal;
                    let date = new Date(fromDate);
                    date.setDate(date.getDate() - 1);

                    date = moment(date).format("DD-MM-YYYY");
                    let data = { dateFormat: date, glTitle: "Opening Balance".toUpperCase(), creditPrinciple: 0, debitPrinciple: oBal, balance: 0 };
                    bankData.splice(0, 0, data);

                    if (bankData.length > 1) {
                        bankData.filter(a => a.mode === "Bank");
                        bankData.filter(a => a.mode === "Cash" && a.transactionType === "VE");
                    }
                    creditSum =(bankData && bankData.reduce((a, v) => a + v.creditPrinciple, 0)).toFixed(2);
                    debitSum = (bankData && bankData.reduce((a, v) => a + v.debitPrinciple, 0)).toFixed(2);
                    openBal=0;
                    bankData.forEach(d => {
                        d.balance = openBal + d.debitPrinciple - d.creditPrinciple;
                        openBal = d.balance;
                        if (d.rctNo) {
                            d.no = d.rctNo;
                        }
                        else {
                            d.no = d.scrollNo;
                        }
                        d.debitPrinciple =d.debitPrinciple.toFixed(2);
                        d.creditPrinciple =d.creditPrinciple.toFixed(2);
                        d.balance=d.balance.toFixed(2);
                        
                    })
                    creditDebitSum = (bankData[bankData.length - 1].balance);
                    setBankReportGridData(bankData);
                });
            });
            setSpinnerVisible(false);          
            setOnView(true);
        }
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const rowStyle = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width:'250px'
      };

    return (
        <div className="BankReport">
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <Toast ref={toast} position="center" />
            <div class="card" >
                <div className='p-formgroup-inline'>
                    <div style={{ margin: '10px' }}>
                        <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                        <select style={{ width: '485px',borderRadius:'6px',borderColor:'lightgrey' }} name="societyBank" className="bankName" ref={register} onChange={onChangeSelectedBank}  >
                            <option hidden value="">Select Bank Name</option>
                            {societyBank.map((societyBankName) => (
                                <option key={societyBank.glTitle} value={societyBank.glTitle}>
                                    {societyBankName.glTitle}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ margin: '10px',borderRadius:'6px',borderColor:'lightgrey' }}>
                        <label>From:</label>
                        <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                        <input type="date" name="date" className='Date' value={fromDate} onChange={onDateChange} />
                    </div>

                    <div style={{ margin: '10px',borderRadius:'6px',borderColor:'lightgrey' }}>
                    
                        <label className="label">To:</label>
                        <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                        <input type="date" name="date" className='Date' value={toDate} onChange={onToDateChange} />
                    </div>

                    <div style={{ margin: '10px' }}>
                        <Button label="VIEW" onClick={viewBills} style={{ marginLeft: '10px' }} />
                    </div>

                    <div style={{ margin: '10px' }}>
                        <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT" style={{ marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
            <div>

            </div>
            {Generateloading ? (
                <div className="spinner">
                    <span>Loading...</span>
                    <div className="half-spinner"></div>
                </div>
            ) : (
                <>
                    {onView ? (
                        <>
                            {/* <div style={{ marginTop: "20px", marginBottom: '20px' }}>
                                <label ><b>Opening Balance:</b></label>
                                <input type="text" style={{ height: '25px', width: '150px' }} value={openingBalance} />
                            </div> */}

                            <div className="BankReportData">
                                <DataTable className="p-datatable-gridlines" scrollable scrollHeight='60vh' style={{ overflow: 'hidden' }}
                                    value={BankReportGridData}  emptyMessage="No result found.">
                                    {/* <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column> */}
                                    <Column field="dateFormat" header="Date" style={{ width: '100px' }} ></Column>
                                    <Column field="glId" header="GL-Id" style={{ width: '50px' }}  ></Column>
                                    <Column field="rctNo" header="Rct No" style={{ width: '50px' }}  ></Column>
                                    <Column field="scrollNo" header="Scroll No" style={{ width: '70px' }}  ></Column>
                                    <Column field="glTitle" header="GL Title" style={{ width: '200px' }} ></Column>
                                    <Column field="name" header="Name" style={rowStyle} ></Column>
                                    <Column field="remarks" header="Narration" style={{ width: '200px' }} ></Column>
                                    <Column field="mode" header="Mode" style={{ width: '70px' }}   ></Column>
                                    <Column field="chqNo" header="Cheque No" style={{ width: '100px' }}  ></Column>
                                    <Column field="debitPrinciple" header="Debit" style={{ width: '120px' }} ></Column>
                                    <Column field="creditPrinciple" header="Credit" style={{ width: '120px' }} ></Column>
                                    <Column field="balance" header="Balance" style={{ width: '120px' }} ></Column>
                                </DataTable>
                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </>
            )}

            <div hidden >
                <Report ref={componentRef} />
            </div>
        </div>
    );
}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;


class Report extends React.Component {
    render() {
        return (
            <div style={{ height: '100%' }}>
                <style>{portrait()}</style>
                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        <div class="font">
                            <label>Bank Book Report</label>
                        </div>
                        <div class="font">
                            <label>Bank Book For : <text>{BankReportDatas[1]?.bankName}</text></label>
                        </div>
                        <div className="dateFont">
                            <label>From:{fDateOnReport} To:{tDateOnReport}</label>
                        </div>
                    </div>

                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '200px', textAlign: 'center' }} colSpan="3">Receipt/Voucher</th>
                                        <th style={{ width: '250px', textAlign: 'center' }}>Particulars</th>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Cheq.No.</th>
                                        <th style={{ width: '90px', textAlign: 'center' }}>Debit</th>
                                        <th style={{ width: '90px', textAlign: 'center' }}>Credit</th>
                                        <th style={{ width: '90px', textAlign: 'center' }}>Balance</th>
                                    </tr>
                                    <tr className='headerColor'>
                                        <th style={{ width: '80px', textAlign: 'center' }}>Date</th>
                                        <th style={{ width: '60px', textAlign: 'center' }}>Trn.Type</th>
                                        <th style={{ width: '60px', textAlign: 'center' }}>RVNo</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {BankReportDatas.map((Bdata, index) =>
                                    <tbody>
                                        {
                                            <tr>
                                                <td style={{ textAlign: 'center', padding: '2px' }}>{BankReportDatas[index]?.dateFormat}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{BankReportDatas[index]?.transactionType}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{BankReportDatas[index]?.no}</td>
                                                <td style={{ textAlign: 'left', fontSize: '14px', padding: '5px' }}>
                                                    <tr>{BankReportDatas[index]?.glTitle}</tr>
                                                    <tr>{BankReportDatas[index]?.name}</tr>
                                                    <tr>{BankReportDatas[index]?.remarks}</tr>
                                                </td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{BankReportDatas[index]?.chqNo}</td>
                                                <th style={{ textAlign: 'right', padding: '5px' }}>{BankReportDatas[index]?.debitPrinciple}</th>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{BankReportDatas[index]?.creditPrinciple}</td>
                                                <th style={{ textAlign: 'right', padding: '5px' }}>{BankReportDatas[index]?.balance}</th>
                                            </tr>
                                        }
                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                    <th style={{ textAlign: 'center', padding: '2px' }}>Total</th>
                                    <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                    <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                    <td style={{ textAlign: 'left', fontSize: '14px', padding: '5px' }}>
                                        <tr></tr>
                                        <tr></tr>
                                        <tr></tr>
                                    </td>
                                    <td style={{ textAlign: 'right', padding: '5px' }}></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{debitSum}</th>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{creditSum}</th>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{creditDebitSum}</th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
export default BankReport;



