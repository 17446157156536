import 'primeflex/primeflex.css';
import './MRReturnEntry.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useParams } from 'react-router';
import SocietyService from '../../Service/SocietyService';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

function MRReturnEntry(props) {
    const { register, handleSubmit,reset, errors, clearErrors, setValue } = useForm();
    var societyId = parseInt(localStorage.getItem('societyId'));
    const tableName = sessionStorage.getItem('tblName');
    const { id } = useParams();
    const toast = useRef(null);
    const societyService = new SocietyService();
    const [date, setDate] = useState([]);
    const isAddMode = !id;
    const [interest, setInterest] = useState([]);
    const [amount, setAmount] = useState([]);
    const [wings, setWings] = useState([]);
    const [wing, setWing] = useState();
    const [Name, setName] = useState([]);
    const [Mode, setMode] = useState([]);
    const [currentDate, setCurrentDate] = useState();
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [selectedFlatNoName, setSelectedFlatNoName] = useState();
    const [RctNo, setRctNo] = useState([]);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [flatId, setFlatId] = useState(0);
    const [flatNo, setFlatNo] = useState();


    const contentStyle = {
        maxWidth: "600px",
        width: "90%",
        height: "40%"
    };

    const onSave = (data, e) => {
        data.TransactionTable=tableName;
        return isAddMode
            ? createMRReturnEntry(data)
            : updateMRReturnEntry(data);
    }

    const createMRReturnEntry = (data, e) => {
        data.transactionType = "MRR";
        data.societyId = societyId;
        data.flatId = flatId;

        if (data.amount === '0') {
            toast.current.show({ severity: 'error', detail: 'Enter Correct Amount' });
            return;
        }

        if (data.chqNo === '') {
            data.chqNo = 0;
        }
        setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/Transaction/InsertMRReturnEntry`, data, { headers: authHeader() })
            .then(response => {
                if (response.data != null) {
                    toast.current.show({ severity: 'success', detail: response.data[0].msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                window.location.reload();
                SetFocusValue(true);
            })
    }

    const updateMRReturnEntry = (data, e) => {
        setSpinnerVisible(true);
        if (data.chqNo === '') {
            data.chqNo = 0;
        }
        return axios.put(`${Config.apiUrl}/Transaction/UpdateMRReturnEntry?id=${id}`, data, { headers: authHeader() })
            .then(response => {
                if (response.data != null) {
                    toast.current.show({ severity: 'success', detail: response.data[0].msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);

            })
    }

    useEffect(() => {
        let d = moment(moment(new Date())).format('YYYY-MM-DD');
        setValue('date', d);
        console.warn(d);

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }

        societyService.getPersonalDetails(societyId,tableName)
            .then(data => 
                SetPersonalDetailsData(data)
                );
        console.warn(personalDetailsData);

        societyService
            .getWing(societyId)
            .then((data) =>
                setWings(
                    data.map(({ wing }) => ({ label: wing, value: wing })),
                ),
            );

        if (!isAddMode) {
            // get user and set form fields
            societyService.getMRReturnEntryDetailsOnID(id,tableName).then(userIn => {
                const fields = ['date', 'flatNo', 'wing', 'rctNo', 'chqNo', 'name', 'amount', 'bankName', 'remarks', 'interest', 'dues', 'mode', 'lateChrgs'];
                fields.forEach(field => setValue(field, userIn[0][field]));
                setValue('date', userIn[0].dateFormat);
                setValue('glId', userIn[0].glId);
                setValue('glTitle', userIn[0].glTitle);
                setValue('wing', userIn[0].wing);
                setValue('flatNo', userIn[0].flatNo);
                setValue('name', userIn[0].name);
                setValue('rctNo', userIn[0].rctNo);
                setValue('amount', userIn[0].amount);
                setValue('mode', userIn[0].mode);
                setValue('chqNo', userIn[0].chqNo);
                setValue('bankName', userIn[0].bankName);
                setValue('narration', userIn[0].narration);
                setFlatId(userIn[0].flatId);
            });
        }

        societyService.getRctNo(societyId,tableName).then(data => {
            setValue('rctNo', data);
        }
        );
    }, []);

    const onReset = () => {
        reset();
        window.location.reload();
      }

    const onDateChange = (e) => {
        setDate(e.currentTarget.value);
        setValue('date', e.currentTarget.value)
    }

    const Interest = (e) => {
        let interest = e.currentTarget.value;
        let Interest = parseFloat(interest);
        setInterest(Interest);
    }

    const Amount = (e) => {
        let amount = e.currentTarget.value;
        let Amount = parseFloat(amount);
        setAmount(Amount);
    }

    const LateChrgs = (e) => {
        let lateChrgs = e.currentTarget.value;
        let LateChrgs = parseFloat(lateChrgs);
        setValue('total', calculate_Total(LateChrgs));
    }

    function calculate_Total(LateChrgs) {
        var Total = amount + interest + LateChrgs;
        return Total;
    }

    const onChangeWing = (e) => {
        let wing = e.currentTarget.value;
        setValue('wing', wing);
        setWing(wing);
    }

    const onChangeFlat = (e) => {
        let flatNo = e.currentTarget.value;
        if (flatNo !== '') {
            flatNo = parseInt(flatNo);
            setFlatNo(flatNo);
        }
        societyService
            .getName(flatNo, wing, societyId)
            .then((data) => {
                if (data.length > 0) {
                    setValue('name', data[0].name);
                    setFlatId(data[0].dues);
                    setValue('dues', data[0].dues);

                }
                else {
                    setValue('name', '');
                    setValue('dues', '');

                }
            }
            );
    }

    const onModeChange = (e) => {
        let mode = e.currentTarget.value
        if (mode === 'Cash') {
            setValue('chqNo', '');
            setValue('bank', '');
            clearErrors('chqNo');
            clearErrors('bank');
        }
        setMode(mode);
    }

    const onRowSelect = (event) => {
        let fNo = event.data.flatNo;
        let name = event.data.name;
        let wing = event.data.wing;
        setValue('flatNo', fNo);
        setValue('name', name);
        setValue('wing', wing);
    }

    const InputFlatNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('flatNo', onlyNums);
    }

    const InputAmount = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setValue('amount', onlyNums);
    }

    const InputLateCharge = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setValue('lateChrgs', onlyNums);
    }

    const InputInterest = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setValue('interest', onlyNums);
    }

    const InputChequeNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('chqNo', onlyNums);
    }

    return (
        <div className="bColor MRReturnEntry" style={{ border: "1px solid black", width: "auto", backgroundColor: "Info"}}>
            <h3 style={{ marginLeft: '20px',display:'flex',justifyContent:'center',color:'red'}} >Member Receipt Return</h3>
            <Toast ref={toast} position={'center'} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />

            <div className="p-formgroup-inline ">
                <div className="p-field p-grid" >
                    <label className="p-col-fixed" style={{ width: '108px' }}>Wing:</label>
                    <div className=" p-col" >
                        <select style={{ width: '175px' }} name="wing" autoFocus={focusValue} className="smallTextBox" ref={register} onChange={onChangeWing}  >
                            <option hidden value="">Select Wing</option>
                            {wings.map(({ label, value: wings }) => (
                                <option key={wings} value={wings}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="p-field p-grid" style={{ marginTop: "5px" }}>
                    <label className="p-col-fixed" style={{ width: '108px' }}>Flat No:</label>
                    <div className="p-col">
                        <input type="text" name="flatNo" className="smallTextBox" ref={register({ required: true })} onChange={onChangeFlat} onInput={InputFlatNo} autoComplete="off" />
                    </div>
                </div>

                <Popup trigger={<a href="#">Search Flat No</a>} position="right top" contentStyle={contentStyle}>
                    <DataTable value={personalDetailsData} selectionMode="single" selection={selectedFlatNoName} onRowSelect={onRowSelect} onSelectionChange={e => setSelectedFlatNoName(e.value)} dataKey="id" className="p-datatable-sm" scrollable scrollHeight='350px' style={{ overflow: 'hidden' }} width="250px" >
                        <Column field="wing" header="Wing" filter style={{ width: '150px' }}></Column>
                        <Column field="flatNo" header="Flat No" filter style={{ width: '150px' }}></Column>
                        <Column field="name" header="Name" filter></Column>
                    </DataTable>

                </Popup>
            </div>

            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '115px' }}>Name:</label>
                    <div className="p-col-12 p-md-2 ">
                        <input type="text" readOnly name="name" className="height" style={{ width: '495px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} />
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.name && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>Please select Name</p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Date:</label>
                    <div className=" p-col" >
                        <input type="Date" name="date" className="smallTextBox" style={{ width: '175px' }} onChange={onDateChange} ref={register({ required: true })} />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Dues:</label>
                    <div className="p-col">
                        <input type="text" readOnly name="dues" className="smallTextBox" ref={register} />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Interest:</label>
                    <div className="p-col">
                        <input type="text" name="interest" className="smallTextBox" ref={register} onChange={Interest} onInput={InputInterest} autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Rct No:</label>
                    <div className="p-col" >
                        <input type="text" name="rctNo" readOnly className="smallTextBox" ref={register} />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Amount:</label>
                    <div className="p-col">
                        <input type="text" name="amount" className="smallTextBox" ref={register({ required: true })} onChange={Amount} onInput={InputAmount} autoComplete="off" />
                        {errors.Amount && (
                            <p className="errorMsg" style={{ color: 'red' }}>Please select Amount.</p>
                        )}
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Mode:</label>
                    <div className="p-col">
                        <select style={{ width: '175px' }} name="mode" className="smallTextBox" ref={register} onChange={onModeChange}>
                            <option hidden value=''>Select Mode</option>
                            <option>Cash</option>
                            <option>Online</option>
                            <option>Cheque</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.amount && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-10px' }}>Please enter amount</p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Late Chrgs:</label>
                    <div className="p-col">
                        <input type="text" name="lateChrgs" className="smallTextBox" ref={register} onChange={LateChrgs} onInput={InputLateCharge} autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Chq No:</label>
                    <div className="p-col" >
                        <input type="text" name="chqNo" disabled={Mode === 'Cash'} className="smallTextBox" ref={register({ required: true })} onInput={InputChequeNo} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Total:</label>
                    <div className="p-col" >
                        <input type="text" readOnly name="total" className="smallTextBox" ref={register} />
                    </div>
                </div>
            </div>

            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '115px' }}>Bank Name:</label>
                    <div className="p-col-12 p-md-2 ">
                        <input type="text" name="bankName" disabled={Mode === 'Cash'} className="height" style={{ width: '492px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="p-col-fixed" style={{ width: '108px' }}>Remarks:</label>
                    <div className="p-col">
                        <input type="text" name="remarks" className="height" style={{ width: '491px', height: '68px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.remarks && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>There should be Remarks</p>
                )
                }
            </div>

            <div className="p-formgroup-inline" style={{ marginLeft: '200px', justifyContent: 'left' }}>
                <div className="p-field">

                    <Button label="SAVE" type="submit" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} onClick={handleSubmit(onSave)} disabled={DisableButton} />
                    <Button label="RESET" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} disabled={DisableButton} onClick={onReset} />
                </div>
            </div>
        </div>
    );
}
export default MRReturnEntry;