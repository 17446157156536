import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Dropdown } from 'primereact/dropdown';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from "react";
//import "primeflex/primeflex.css";
import "./BankFD.css";
import * as Yup from 'yup';
import axios from 'axios';
import { Button } from 'primereact/button';
import "primeflex/primeflex.css";
import SocietyService from "../../Service/SocietyService";
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { usePopup } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import authHeader from '../../authHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import Config from '../../Config.json';

//import { Card } from 'primereact/card';class Example extends React.Component {

export default function BankFD() {
    const validationSchema = Yup.object().shape({
        // title: Yup.string()
        //     .required('Title is required'),
        glTitle: Yup.string()
            .required('Gl Title is required'),
        depositeValue: Yup.string()
            .required('Deposite Value is required'),
        depositeDate: Yup.string()
            .required('DepositeDate is required'),
        fD_Type: Yup.string()
            .required('FD Type is required'),
        interestType: Yup.string()
            .required('InterestType is required'),
        interestRate: Yup.number()
            .required('InterestRate is required '),
        certificateNo: Yup.string().required("Certificate No is Required"),
        tenure: Yup.number()
            .required('InterestRate is required '),
        fDMode: Yup.string()
            .required('FD Mode is required '),
        maturityValue: Yup.number()
            .required('MaturityValue is required ,'),
        bank: Yup.string()
            .required('Bank is required '),
        customerId: Yup.string()
            .required('CustomerId is required ')


    });
    const toast = useRef(null);
    const [fdTypes, setFdTypes] = useState([]);
    const [interestTypes, setinterestTypes] = useState([]);
    const [bankFds, setBankFds] = useState([]);
    const [selectedFdType, setSelectedFdType] = useState('');
    const [depositDate, setDpositeDate] = useState('');
    const [maturityDate, setMaturityDate] = useState('');
    const [asOnDate, setAsOnDate] = useState('');
    const [selectedBankFD, setSelectedBankFD] = useState(null);
    const [selectedInterestType, setSelectedInterestType] = useState('');
    const { errors, register, handleSubmit, reset, setValue } = useForm({ resolver: yupResolver(validationSchema) });
    const [Id, setId] = useState(null);
    const isAddMode = !Id;
    const societyService = new SocietyService();
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [DisableButton, setDisableButton] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    var [societyBank, setSocietyBankData] = useState([]);
    const [selectedBank, setSelectedBank] = useState();
    var [bankFdGls, setBankFdGls] = useState([]);
    const [selectedGl, setselectedGl] = useState();
    const [selectedGlTitle, setSelectedGlTitle] = useState();
    const [selectedRenueValue, setSelectedRenueValue] = useState();




    useEffect(() => {
        const fetchData = async () => {

            if (accessLevel >= 2) {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);

            }

            const banks = await societyService.GetSocietyBank(societyId, 'Bank Balance');
            setSocietyBankData(banks);
            societyBank = banks;

            societyService.getBankFdGls(societyId, 'INVESTMENT IN BANK').then(data => {
                setBankFdGls(data);
            })

            // let fdTypeArray = [{type:'Sinking Fund'}, {type:'Cash'}, {type:'GL Title'}];
            // setFdTypes(fdTypeArray);

            // let interestTypes = ['Interest on BANK account', 'Querterly compound in FD', 'Interest on Maturity'];
            // setinterestTypes(interestTypes);
            societyService.getBankFds(societyId).then(data => {
                data.forEach(a=>{
                    a.isAutoRenew=a.isAutoRenew ? 'True':'False';

                })
                setBankFds(data);
            })
        }
        fetchData()

    }, []);

    const onSubmit = (data) => {
        return isAddMode ? createBankFD(data) : editBankFD(data);
    };

    const createBankFD = (data) => {

        data.societyId = societyId;
        data.glId = parseInt(selectedGl===undefined ? selectedBankFD.glId :selectedGl);
        //data.depositeValue = parseFloat(data.depositeValue);
        //  data.depositeDate = moment(depositDate).format('YYYY-MM-DD');
        data.asOnDate = moment(asOnDate).format('YYYY-MM-DD');
        //data.tenure = 0;
        // data.maturityValue = 0;
        // data.id = 0;
        data.isAutoRenew = 'Yes' ? true : false;
        // data.interestRate = parseFloat(data.interestRate);

        setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/BankFD/Insert`, data, { headers: authHeader() })
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });

                } else {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data.msg });
                }

            })
            .catch((error) => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in bank FD insertion' });
            }).finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);

            })

    };

    // function validateFields(dataGL) {
    //     if (dataGL.fd_type === "" || dataGL.fd_type === undefined) {
    //         dataGL.gst = -1;
    //     }
    // }
    const editBankFD = (data) => {
        data.societyId = societyId;
        data.glId = parseInt(selectedGl===undefined ? selectedBankFD.glId :selectedGl);
        data.isAutoRenew = data.isAutoRenew ==='Yes' ? true : false;
        // data.asOnDate = moment(asOnDate).format('YYYY-MM-DD');


        let url = `${Config.apiUrl}/BankFD/Update?Id=${Id}&societyId=${societyId}`;
        setSpinnerVisible(true);
        axios
            .post(url, data, { headers: authHeader() })
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });

                } else {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: response.data.msg });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in bank FD Updation' });
            }).finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);

            })
    };

    const onNo = () => {
        toast.current.clear();
        return;
    };


    const onCancel = (glId) => {
        reset();
        window.location.reload();
    };

    const onChangeSelectedBank = (e) => {
        let selectedBank = e.currentTarget.value;
        setSelectedBank(selectedBank);
    }   

    const onChangeGl = (e) => {
        let gl = e.currentTarget.value;
        let data=bankFdGls.find(a=>a.glTitle === gl);
        setselectedGl(data.glId);
        setSelectedGlTitle(data.glTitle);
    }

    const onAutoRenue = (e) => {
        let value = e.currentTarget.value;
        setSelectedRenueValue(value);
    }


    const onRemove = () => {
        // setStickyMode(false);
        window.location.reload();
    }

    const onEdit = (e) => {
        if (selectedBankFD != null) {

            if (selectedBankFD.length > 1) {
                toast.current.show({ severity: 'warn', summary: 'Alert', detail: "Please select only one" });
                return;

            }
            else{
                const fields =[
                    "glTitle",
                    "customerId",
                    "depositeValue",
                    "tenure",
                    "maturityValue",
                    "bank",
                    "fD_Type",
                    "certificateNo",
                    "interestType",
                    "interestRate",
                    "chequeNo",
                    "branchName",
                    "remarks",
                  ];
                  fields.forEach((field) => setValue(field, selectedBankFD[field]));
                  setValue("isAutoRenew",selectedBankFD.isAutoRenew ? 'Yes' : 'No' );
                //   setValue("depositeDate", selectedBankFD.depositeDateFormat);
                //   setValue("asOnDate", selectedBankFD.asOnDateFormat);
                  setDpositeDate(selectedBankFD.depositeDateFormat);
                  setAsOnDate(selectedBankFD.asOnDateFormat);   
                  setId(selectedBankFD.id);
                //   setselectedGl(selectedBankFD.glTitle);
            }

        }
        else {
            toast.current.show({ severity: 'warn', summary: 'Alert', detail: "PLEASE SELECT ROW" });

        }
    }

    const onDelete = (e) => {
        if (selectedBankFD != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                  <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="p-text-center">
                      <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                      <h4>Are you sure?</h4>
                      <p>Confirm to Delete</p>
                    </div>
                    <div className="p-grid p-fluid">
                      <div className="p-col-6">
                        <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
                      </div>
                      <div className="p-col-6">
                        <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
                      </div>
                    </div>
                  </div>
                )
              });
        }
        else {
            toast.current.show({ severity: 'warn', summary: 'Alert', detail: "PLEASE SELECT ROW " });
          }
    }

    const onYes = () => {
        toast.current.clear();
        // selectedRadioData.forEach((d) => {
        //   let id = d.id;
        //   let data = GlData.filter((b) => b.id != d.id);
        //   setGlData(data);
          setSpinnerVisible(true);
          axios
            .delete(`${Config.apiUrl}/BankFD/Delete?societyId=${societyId}&id=${selectedBankFD.id}`, { headers: authHeader() })
            .then((response) => {
              console.log(response);
              if (response.data != null) {
                toast.current.show({ severity: 'success', sticky: true, summary: 'Success', detail: response.data.msg });
    
              }
              else {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Success', detail: response.data.msg });
              }
            })
            .catch((error) => {
              console.log(error);
            }).finally(()=>{
              setSpinnerVisible(false);
            })
        // });
      };

      //const onReset = (data) => {
       // reset();
       // const fields = ["glTitle",
       // "customerId",
      //  "depositeValue",
       // "tenure",
       // "maturityValue",
       // "bank",
       // "fD_Type",
        //"certificateNo",
        //"interestType",
       // "interestRate",
       // "chequeNo",
       // "branchName",
       // "remarks",];
       // fields.forEach(field => setValue(field, ''));
        // setAddDisabled(false);

    //}
    const onReset = (data) => {
        // setId(selectedValue)
        reset();
        window.location.reload();
        
    
      }

    const header = (
        <div className="table-header">
            <div>
                GL Master
            </div>

            <div>
                {/* <Link to={`/Flat_Master/edit/${selectedRadioData.id}`}> */}
                <Button
                    icon="pi pi-pencil"
                    value="Edit"
                    tooltip="Edit"
                    className="btnEdit1"
                    onClick={onEdit}
                    disabled={DisableButton}
                />
                {/* </Link>     */}
                <Button
                    icon="pi pi-trash"
                    value="Delete"
                    className="btn"
                    onClick={onDelete}
                    disabled={DisableButton}
                />
            </div>
        </div>
    );
    return (
        <div >
            <Toast ref={toast} position="center" onRemove={onRemove} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div style={{ display: "row" }}>
                <div style={{ display: "flex" }}>
                    <div className="BankFD">
                        <div className='field'>
                            <label for="GlTitle"  >GL TITLE</label>
                            {/* <input id="GlTitle" name='glTitle' type="text" ref={register({ required: true })} /> */}
                            <select style={{ width: '176px', height: '25px' }} name="glTitle" className='TextBox1' ref={register({ required: true })} onChange={onChangeGl} value={selectedGlTitle} >
                                <option hidden value="">Select Gl Title</option>
                                {bankFdGls.map((bankGls) => (
                                    <option key={bankGls.glTitle} value={bankGls.glTitle}>
                                        {bankGls.glTitle}
                                    </option>
                                ))}



                            </select>
                            {errors.glTitle && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.glTitle.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="CustId"  >CUST ID</label>
                            <input id="CustId" name="customerId" type="text" ref={register} className='TextBox1'  />

                            {errors.customerId && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.customerId.message}</p>
                            )}
                        </div>

                        <div class="field">
                            <label for="Deptdate">DEP DATE</label>
                            {/* <Calendar name="depositeDate" value={depositDate} ref={register} onChange={(e) => setDpositeDate(e.value)}></Calendar> */}
                            <input id="depositeDate" value={depositDate} onChange={(e) => setDpositeDate(e.value)} style={{ width: '176px', height: '25px' }} name="depositeDate" type="date" ref={register}  className='TextBox1' />
                            {errors.depositeDate && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.depositeDate.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="depositeValue" >DEP VALUE</label>
                            <InputText name="depositeValue" ref={register({ required: true })} className='TextBox1' error={!!errors.depositeValue} />
                            {errors.depositeValue && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.depositeValue.message}</p>
                            )}
                            {/* <input id="Depvalue" type="text" name="depositValue" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" /> */}
                        </div>
                        <div class="field">
                            <label for="tenure" >TENURE</label>
                            <input id="tenure" name="tenure" ref={register} type="text" className='TextBox1' />
                            {errors.tenure && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.tenure.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="maturityValue" >MATURITY VALUE</label>
                            <input id="maturityValue" name="maturityValue" ref={register} type="text" className='TextBox1'  />
                                {/* <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.maturityValue.message}</p> */}
                           
                        </div>
                       
                      


                    </div>
                    <div className="BankFD">
                        <div className='field'>
                            <label for="FDtype"  >FD TYPE</label>
                            <select name="fD_Type" style={{ width: '176px', height: '25px' }} className='TextBox1' ref={register({ required: true })}>
                                <option hidden value="">Select Fd Type</option>
                                <option>Reserve Fund</option>
                                <option>Sinking Fund</option>
                                <option>Share Money</option>
                                <option>General</option>


                                {/* {fdTypes.map(({ type }) => (
                                    <option key={type} value={type} >
                                        {type}
                                    </option>
                                ))} */}

                            </select>
                            {errors.fD_Type && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.fD_Type.message}</p>
                            )}
                            {/* <Dropdown value={selectedFdType} options={fdTypes} onChange={(e) => setSelectedFdType(e.value)} placeholder="Select FD Type" /> */}
                            {/* <input id="FDtype" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" placeholder="FD TYPE" /> */}
                        </div>
                        <div class="field">
                            <label for="certNo">CERT NO</label>
                            <input id="certNo" type="text" name="certificateNo" ref={register} className='TextBox1' />
                            {errors.certificateNo && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.certificateNo.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="onDate">AS ON DATE</label>
                            {/* <Calendar name="asOnDate" value={asOnDate} onChange={(e) => setAsOnDate(e.value)}></Calendar> */}
                            <input style={{ width: '176px', height: '25px' }} id="onDate" type="date" value={asOnDate} ref={register} onChange={(e) => setAsOnDate(e.value)} name="asOnDate" className='TextBox1' placeholder="AS ON DATE" />
                        </div>

                        <div class="field">
                            <label for="InterestType">Interest Types</label>
                            {/* <Dropdown value={selectedInterestType} options={interestTypes} onChange={(e) => setSelectedInterestType(e.value)}  /> */}
                            <select name="interestType" style={{ width: '176px', height: '25px' }} className='TextBox1' ref={register({ required: true })}>
                                <option hidden value="">Select Interest Type</option>
                                <option>Interest on BANK account</option>
                                <option>Querterly compound in FD</option>
                                <option>Interest on Maturity</option>


                                {/* {interestTypes.map(({ interestT }) => (
                                    <option key={interestT} value={interestT} >
                                        {interestT}
                                    </option>
                                ))} */}

                            </select>
                            {errors.interestType && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.interestType.message}</p>
                            )}
                            {/* <input id="INCTTYPE" name="interestType" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" placeholder="INCT TYPE" /> */}
                        </div>
                        <div class="field">
                            <label for="intRate">Interest Rate</label>
                            <input id="intRate" name="interestRate" ref={register} type="text" className='TextBox1'  />
                            {errors.interestRate && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.interestRate.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="autoRenew">AUTO RENUE</label>
                            {/* <input id="autoRenew" name="isAutoRenew" type="text" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" /> */}
                            <select name="isAutoRenew" style={{ width: '176px', height: '25px' }} className='TextBox1' ref={register} onChange={onAutoRenue} value={selectedRenueValue} >
                                <option hidden value="">Select Auto Renue</option>
                                <option>Yes</option>
                                <option>No</option>

                            </select>
                        </div>
                        
                    </div>
                        
                    <div className="BankFD">
                    <div class="field">
                            <label for="mode">MODE</label>
                            <input id="mode" name="fDMode" className='TextBox1' ref={register({ required: true })} value="Bank" readOnly type="text" />
                            {errors.fDMode && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.fDMode.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="Branch name">Branch name</label>
                            <input id="Branch name" type="text" name="branchName" ref={register} className='TextBox1'  />
                           
                        </div>
                        <div class="field">
                            <label for="bank">Bank</label>
                            {/* <input id="bank" name="bank" ref={register({ required: true })} type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" /> */}
                            <select style={{ width: '176px', height: '25px' }} name="bank" className='TextBox1' ref={register({ required: true })} onChange={onChangeSelectedBank} value={selectedBank} >
                                <option hidden value="">Select Bank Name</option>
                                {societyBank.map((societyBankName) => (
                                    <option key={societyBank.glTitle} value={societyBank.glTitle}>
                                        {societyBankName.glTitle}
                                    </option>
                                ))}
                            </select>
                            {errors.bank && (
                                <p className="errorMsg" style={{ color: 'red', marginTop: '1px', marginLeft: '15px' }}>{errors.bank.message}</p>
                            )}
                        </div>
                        <div class="field">
                            <label for="Chequeno">CHEQUE NO</label>
                            <input id="Chequeno" ref={register} name="chequeNo" type="text" className='TextBox1'  />
                        </div>
                        <div>
                        <label for="Remarks" style={{ width: "130px", marginLeft: "15px" }}>Remarks</label>
                            <input id="Remarks" type="text" name="remarks" ref={register} className='TextBox1'  />
                            </div>
                    </div>


                </div>
                <div>
                    <Button
                        style={{ margin: '10px', height: '30px', marginLeft:'670px' }}
                        label="SUBMIT"
                        type="submit "
                        onClick={handleSubmit(onSubmit)}
                    />
                    <Button
                        style={{ margin: '10px', height: '30px' }}
                        label="RESET"
                        type="reset"    
                        onClick={onReset}

                    />


                </div>
                <div className="dataTable">
                    <DataTable
                        value={bankFds}
                        header={header}
                        className="p-datatable-sm"
                        editMode="row"
                        paginator
                        rows={5}
                        selection={selectedBankFD}
                        onSelectionChange={(e) => setSelectedBankFD(e.value)}>
                        <Column
                            selectionMode="single"
                            headerStyle={{ width: "3em" }}
                        ></Column>
                        <Column field="glId" header="GL Id" filter style={{ width: '50px' }}></Column>
                        <Column field="glTitle" header="GL Title" filter style={{ width: '100px' }}></Column>
                        <Column field="fD_Type" header="FD TYPE" filter style={{ width: '50px' }}></Column>
                        <Column field="customerId" header="CUST ID" filter style={{ width: '50px' }}></Column>
                        <Column field="certificateNo" header="CERT NO" filter style={{ width: '50px' }}></Column>
                        <Column field="depositeDateFormat" header="DEP DATE" filter style={{ width: '50px' }}></Column>
                        <Column field="asOnDateFormat" header="AS ON DATE" filter style={{ width: '50px' }}></Column>
                        <Column field="depositeValue" header="DEP VALUE" filter style={{ width: '50px' }}></Column>
                        <Column field="interestType" header="INT TYPE" filter style={{ width: '50px' }}></Column>
                        <Column field="tenure" header="TENURE" filter style={{ width: '50px' }}></Column>
                        <Column field="interestRate" header="INT RATE" filter style={{ width: '50px' }}></Column>
                        <Column field="maturityValue" header="MATURITY VALUE" filter style={{ width: '50px' }}></Column>
                        <Column field="isAutoRenew" header="AUTO RENUE" filter style={{ width: '50px' }}></Column>
                        <Column field="fdMode" header="MODE" filter style={{ width: '50px' }}></Column>
                        <Column field="chequeNo" header="CHEQUE NO" filter style={{ width: '50px' }}></Column>
                        <Column field="bank" header="BANK" filter style={{ width: '50px' }}></Column>
                        <Column field="branchName" header="BRANCH NAME" filter style={{ width: '50px' }}></Column>
                        <Column field="remarks" header="REMARKS" filter style={{ width: "50px" }}></Column>








                    </DataTable>
                </div>
            </div>
        </div>

    );
}