import logo from '../../images/app logo.png'
import { Button } from "primereact/button";
import { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// import { Password } from "primereact/password";
// import { InputText } from "primereact/inputtext";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import { useGoogleAuth } from "./actions/authActions";
import { Dropdown } from "primereact/dropdown";
import SocietyService from "../../Service/SocietyService";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import FacebookLogin from 'react-facebook-login';
import "./login.css";
import Config from '../../Config.json';
import axios from "axios";
import Popup from "reactjs-popup";
import LoginPopup from "./LoginPopup";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import SocietyContext from '../../SocietyContext';


const clientId =
  "55236503589-p9rl4c6jeo87okg69d6eitbgcafq5s8p.apps.googleusercontent.com";

function Login({ userId }) {
  const societyService = new SocietyService();
  let history = useHistory();
  
  const toast = useRef(null);
  const [wrong, setWrong] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isFinanceYear, setIsFinanceYear] = useState(false);
  const [societyArray, setSocietyArray] = useState([]);
  const [UserId, setUserId] = useState();
  const [societyId, setSocietyId] = useState();
  const [financialYearsData, setFinancialYearsData] = useState();
  const [selectedFinYears, setselectedFinYear] = useState();

  // const [societyName] = useState();
  const [selectedSocietyName, setSocietyName] = useState(null);
  const [societyDetailsData, SetSocietyDetailsData] = useState([]);



  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  // const { societyData, updateSocietyData } = useContext(SocietyContext);
  const [ButtonPopup, setButtonPopup] = useState(false);
  // localStorage.setItem('societyId', societyId);
  // localStorage.setItem('societyName', societyName);

  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState('');

  const { errors, register, reset, handleSubmit, setValue, clearErrors } =
    useForm();
  const handleLoginSuccess = (response, e) => {
    console.log(response);
    const googleresponse = {
      Name: response.profileObj.name,
      Email: response.profileObj.email,
      Token: response.googleId,
      Id: 1,
      // Image: response.profileObj.imageUrl,
      ProviderName: "Google",
    };
    setSpinnerVisible(true);
    let url = `${Config.apiUrl}/Authentication/Authenticate?token=${response.tokenId}`;
    console.log(googleresponse);
    console.log(url);
    axios
      .get(url)
      .then((response) => {
        processResponse(response);
        console.log("Bearer Token: " + response.data);
        console.warn(response.data);
      })
      .catch((error) => {
        toast.current.show({ severity: "error", sticky: true, detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
      })

  };

  const handleLoginFailure = (error) => {
    console.log("Login Failure ", error);
  };

  const responseFacebook = (response) => {
    console.log(response);

    setSpinnerVisible(true);
    let apiurl = `${Config.apiUrl}/Authentication/AuthenticateFacebook?facebookToken=${response.accessToken}`;
    console.log(response);
    console.log(apiurl);
    axios
      .get(apiurl)
      .then((response) => {
        processResponse(response);
        // console.log("Bearer Token: " + response.data);
        console.warn(response.accessToken);
      })
      .catch((error) => {
        toast.current.show({ severity: "error", sticky: true, detail: error });
        // console.log("Error from api " + error);
      })
      .finally(() => {
        setSpinnerVisible(false);
      })
    setData(response);
    // setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }

  // useEffect(() => {

  //   //getting group master data
  // }, []);

  const processResponse = (response) => {
    console.log(response);
    if (response.data != null) {

      if (response.data.userSocietyContextInfo.length > 0) {
        if (response.data.token) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
          // localStorage.setItem("societyId", JSON.stringify(response.data));

        }
        setUserId(response.data.userSocietyContextInfo[0].userId);
        localStorage.setItem("userId", response.data.userSocietyContextInfo[0].userId);


        if (!response.data.validUser) {
          toast.current.show({ severity: "error", detail: "Invalid Crdentials" });
          return;
        }
        console.log("Checkpoint 1");
        let approvedSociety = response.data.userSocietyContextInfo.find(a => a.societyId > 0 && a.action > 0);

        if (approvedSociety === undefined) {
          let pendingSociety = response.data.userSocietyContextInfo.find(a => a.societyId > 0 && a.action === 0);
          if (pendingSociety) {
            toast.current.show({ severity: "error", sticky: true, detail: `Your Request is pending for ${pendingSociety.societyName}. Please Contact to Admin` });
            return;
          }
          else {
            setButtonPopup(true);
          }
        }
        else {
          let approvedSocities = response.data.userSocietyContextInfo.filter(a => a.societyId > 0 && a.action === 1);
          if (approvedSocities) {
            if (approvedSocities.length === 1) {
              setSocietyId(approvedSocities[0].societyId);
              localStorage.setItem("societyName", (approvedSocities[0].societyName));
              localStorage.setItem('name', (approvedSocities[0].name));
              sessionStorage.setItem("accessLevel", JSON.stringify(approvedSocities[0].accessLevel));
              localStorage.setItem("societyId", (approvedSocities[0].societyId));

              getFinancialYear(approvedSocities[0].societyId)

              societyService.getSocietyDetails(approvedSocities[0].societyId)
                .then(data => {
                  localStorage.setItem("fyFromDate", (data[0].fyFromDate));
                  localStorage.setItem("fyToDate", (data[0].fyToDate));
                  sessionStorage.setItem("societyCode", (data[0].societyCode));

                  SetSocietyDetailsData(data);
                });

              //Show home page
              // history.push('/HomePage');

            }
            else {
              setLoggedIn(true);

              let tempArray = [];
              response.data.userSocietyContextInfo.forEach((a) => {
                if (a.action > 0) {
                  let existingIndex = tempArray.findIndex(t => t.societyId === a.societyId);
                  if (existingIndex < 0) {
                    tempArray.push(a);
                  }
                }
              });
              tempArray.sort(compare);

              setSocietyArray(tempArray);
              // setButtonPopup(true);
            }
          }
        }
      } else {
        toast.current.show({
          severity: "error",
          detail: response.data.errorMessage,
        });
      }
      // toast.current.show({severity:'success', detail:response.data[0].msg});
    }
  }

  function compare(a, b) {
    if (a.last_nom < b.last_nom) {
      return -1;
    }
    if (a.last_nom > b.last_nom) {
      return 1;
    }
    return 0;
  }

  const onSubmit = (data) => {
    // setUserName(data.loggedInId);
    setSpinnerVisible(true);
    return axios
      .post(`${Config.apiUrl}/Authentication/SignIn`, data)
      .then((response) => {
        processResponse(response);
      })
      .catch((error) => {
        toast.current.show({ severity: "error", detail: `Network issue` });

      })
      .finally(() => {
        setSpinnerVisible(false);
      })

  }

  const onSignUp = () => {
    history.push("/signUp");
  };

  const changeSociety = async (e) => {

    // setUserId(e.value.userId);
    localStorage.setItem("userId", e.value.userId);

    localStorage.setItem("societyId", (e.value.societyId));
    localStorage.setItem("societyName", (e.value.societyName));
    sessionStorage.setItem("accessLevel", (e.value.accessLevel));
    localStorage.setItem("name", (e.value.name));
    //   let selectedSocietyValues=[];
    // selectedSocietyValues.push(e.value);
    setSocietyName(e.target.value);
    // updateSocietyData(e.value.societyId);
    // console.log(societyData);

    const data = await societyService.getSocietyDetails(e.value.societyId);
    localStorage.setItem("fyFromDate", (data[0].fyFromDate));
    sessionStorage.setItem("fyFromDate", (data[0].fyFromDate));
    sessionStorage.setItem("fyToDate", (data[0].fyToDate));

    sessionStorage.setItem("societyCode", (data[0].societyCode));
    getFinancialYear(e.value.societyId);

    SetSocietyDetailsData(data);

    // .then(data => {
    //   sessionStorage.setItem("fyFromDate", (data[0].fyFromDate));
    //   sessionStorage.setItem("societyCode", (data[0].societyCode));
    //   SetSocietyDetailsData(data);
    // });


  };
  const onCheckGoogleSignIn = (e) => {
    setIsGoogleSignIn(!isGoogleSignIn);
  };

  const getFinancialYear = async (societyId) => {
    const FinancialData = await societyService.getFinancialYearsData(societyId)
    setFinancialYearsData(FinancialData);
    if (FinancialData.length > 1) {
      setIsFinanceYear(true);

    }
    else {
      setIsFinanceYear(false);
      var societyCode = sessionStorage.getItem('societyCode').toLowerCase();
      sessionStorage.setItem('finYear', FinancialData[0].yearValue);
      sessionStorage.setItem('finYearShort', FinancialData[0].yearShort);
      // localStorage.setItem("societyId", FinancialData[0].societyId);
      let tblName = `tr_${societyCode}_${societyId}_${FinancialData[0].yearShort}`;
      let tblTranTablePrefix = `tr_${societyCode}_${societyId}`;
      let tblNameSupplimentary = `tbl_transsupplimentary_${societyId}`
      sessionStorage.setItem("tblName", tblName);
      sessionStorage.setItem("tblTransPrefix", tblTranTablePrefix);
      sessionStorage.setItem("tblNameSupplimentary", tblNameSupplimentary);
      history.push("/HomePage");

    }
    // .then(data => {
    //   setFinancialYearsData(data);

    // });
  }

  const changeFinancialYear = (e) => {
    setselectedFinYear(e.value.yearValue);
    let societyCode = sessionStorage.getItem('societyCode').toLowerCase();
    let tblName = `tr_${societyCode}_${e.value.societyId}_${e.value.yearShort}`
    let tblTranTablePrefix = `tr_${societyCode}_${e.value.societyId}`;
    sessionStorage.setItem("tblName", tblName);
    // localStorage.setItem("societyId", e.value.societyId);
    sessionStorage.setItem('finYear', e.value.yearValue);
    let tblNameSupplimentary = `tbl_transsupplimentary_${e.value.societyId}`
    sessionStorage.setItem("tblTransPrefix", tblTranTablePrefix);
    sessionStorage.setItem("tblNameSupplimentary", tblNameSupplimentary);
    sessionStorage.setItem('finYear', e.value.yearValue);
    sessionStorage.setItem('finYearShort', e.value.yearShort);
    console.warn(tblName);
    history.push("/HomePage");

  }

  return (
    <div className="login">
      <Toast ref={toast} />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
      <img src={logo} alt="logo" height={100} width={300} />
      <h1>Login</h1>

      <form>
        {!loggedIn && !isFinanceYear && (
          <div>
            <div className="p-field" >
              {/* <label style={{ lineHeight: "3", color: "white", width:'120px'}}>Username: </label> */}
              <input
                type="text"
                name="loggedInId"
                placeholder="Enter Username "
                ref={register}

              />
            </div>

            <div className="p-field" >
              {/* <label style={{ lineHeight: "3", color: "white",width:'120px' }}>Password : </label> */}
              <input
                type="password"
                name="password"
                placeholder="Enter Your Password"
                ref={register}
              />
            </div>

            <div style={{ color: "red" }}>{wrong}</div>

            <div className="p-formgroup-inline" style={{ display: 'flex', justifyContent: "center", marginTop: "30px", marginLeft: "10px" }}>
              <div className="p-field">
                <Button
                  label="Log in"
                  type="submit "
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
              <div className="p-field ">
                <Button
                  label="sign up"
                  type="submit "
                  onClick={handleSubmit(onSignUp)}
                />
              </div>
            </div>
            <div>
              <Link style={{ color: 'skyblue' }} to={'/actions/ForgotPassword'}>Forgot Password?</Link>
            </div>
            <div style={{ marginTop: "20px", justifyContent: "center" }}>
              <GoogleLogin
                clientId={clientId}
                onSuccess={handleLoginSuccess}
                onFailure={handleLoginFailure}
                isSignedIn={false}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <FacebookLogin
                appId="2385537008263872"
                autoLoad={false}
                fields="name,email"
                scope="public_profile,user_friends"
                callback={responseFacebook}
                icon="fa-facebook" />
            </div>

            <LoginPopup
              trigger={ButtonPopup}
              setTrigger={setButtonPopup}
              userId={UserId}
            ></LoginPopup>

          </div>
        )}
        {loggedIn && (
          <div

            className="p-formgroup-inline"
            style={{ justifyContent: "center", marginTop: "30px" }}>

            <Dropdown className="Dropdown" filter value={selectedSocietyName} id="societyName" options={societyArray} optionLabel="societyName" onChange={changeSociety} placeholder="Select a Society" />

          </div>

        )}

        {isFinanceYear && (

          <div>
            <Dropdown className="Dropdown" filter value={selectedFinYears} options={financialYearsData} optionLabel="yearValue" onChange={changeFinancialYear} placeholder="Select Financial Year" />

          </div>



        )}

      </form>

    </div>
  );
}

export default Login;
