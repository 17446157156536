import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from '../../Service/SocietyService';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import Popup from "reactjs-popup";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './GL_Master.css'

import axios from 'axios';
import Config from '../../Config.json';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
var societyId = parseInt(localStorage.getItem('societyId'));

function Addnew_Gl(id) {
    const toast = useRef(null);
    var [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const societyService = new SocietyService();
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const tableName = sessionStorage.getItem('tblName');
    const [calculationType, setCalculationType] = useState('');
    const [rateAmount, setRateAmount] = useState('');
    const [ratePerSqft, setRatePerSqft] = useState('');
    const [areaSqft, setAreaSqft] = useState('');
    const [amount, setAmount] = useState('');
    const [glId, setGlId] = useState();

    const [glTitle, setGLTitle] = useState('');
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
    const [selectedGlId, setSelectedGlId] = useState();
    const [glIdData, setGlIdData] = useState([]);
    const [glDatas, setGlData] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [gstPercent, setGstPercent] = useState(0);
    const [tdsPercent, setTdsPercent] = useState(0);
    const [billingCycle, setBillingCycle] = useState('Monthly'); // Define billingCycle state with default value

    const GlcontentStyle = {
        maxWidth: "600px",
        marginLeft: "200px"
    };
    const { errors, reset, setValue, handleSubmit } =
        useForm();

    useEffect(() => {
        const fetchData = async () => {
            const glData = await societyService.getGlTitle(societyId);
            setGlIdData(glData);
        }
        fetchData();
    }, []);

    const handleCalculationTypeChange = (event) => {
        setCalculationType(event.target.value);
    };

    const updateAreaSqft = () => {
        if (calculationType === 'Areawise(sqft)') {
            const updatedAreaSqft = parseFloat(ratePerSqft);
            setAreaSqft(isNaN(updatedAreaSqft) ? '' : updatedAreaSqft);
        }
    };

    const updateAmount = () => {
        if (calculationType === 'Fixed') {
            const updatedAmount = parseFloat(amount);
            setRateAmount(isNaN(updatedAmount) ? '' : updatedAmount);
        }
    }
    //new code

    const handleAddButtonClick = () => {
        let newPersonalDetailsData = personalDetailsData.map((item) => {
            let updatedAmount = item.amount;
            let updatedAreaSqft = parseFloat(item.area);
            if (calculationType === 'Areawise(sqft)') {
                const rate = parseFloat(ratePerSqft);
                if (!isNaN(rate)) {
                    updatedAmount = rate * updatedAreaSqft;
                }
            }

            if (calculationType === 'Fixed') {
                const fixedAmount = parseFloat(amount);
                if (!isNaN(fixedAmount)) {
                    updatedAmount = Math.round(fixedAmount);
                }
            }
            const billingCycleFactor = getBillingCycleFactor(); // Function to get the factor based on billing cycle
            updatedAmount *= billingCycleFactor;

            return {
                ...item,
                glId: selectedGlId,
                glTitle: glTitle,
                area: isNaN(updatedAreaSqft) ? '' : updatedAreaSqft.toFixed(2),
                amount: isNaN(updatedAmount) ? '' : updatedAmount.toFixed(2)
            };
        });
        SetPersonalDetailsData(newPersonalDetailsData);
        alert('Data added successfully!');

    };
    const getBillingCycleFactor = () => {
      // Logic to determine factor based on billing cycle
      switch (billingCycle) {
          case 'Monthly':
              return 1;
          case 'Quarterly':
              return 3;
          case 'Half-Yearly':
              return 6;
          case 'Yearly':
              return 12;
          default:
              return 1;
      }
  };
    useEffect(() => {
        societyService.geNewGlHeadDetails(societyId)
            .then(data => {
                const updatedData = data.map(d => {
                    d.admitDateFormat = moment(new Date(d.admitDateFormat)).format('DD-MM-YYYY');
                    if (d.admitDateFormat === '01-01-0001') {
                        d.admitDateFormat = '---';
                    }
                    return d;
                });

                SetPersonalDetailsData(updatedData);

                if (accessLevel >= 2) {
                    setDisableButton(false);
                } else {
                    setDisableButton(true);
                }
            });
    }, []);

    const onSubmit = (e) => {
        setDisableButton(true);
        try {
            createMaintainance(personalDetailsData);
        }
        catch (error) {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Error in submit" });
        }
    }

    const createMaintainance = (d) => {
        setSpinnerVisible(true);
        let url = `${Config.apiUrl}/Maintainance/InsertGlhead`;
        axios.post(url, d, { headers: authHeader() })
            .then(response => {
                console.log(response);
                if (response.data.success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
                    window.location.reload();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })
    }

    const onCancel = (glId) => {
        reset();
        window.location.reload();
    };

    const InputGlId = (e) => {
        try {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
            setValue('glId', onlyNums);
        }
        catch (e) {
            console.log(e);
        }
    }

    const onRowSelectGlid = (e) => {
        let glid = e.data.glId;
        let glTitle = e.data.glTitle;
        setSelectedGlId(glid);
        setGLTitle(glTitle);
    }

    const onChangeGlId = (e) => {
        let glId = e.currentTarget.value;
        setGlId(parseInt(glId));
        societyService
            .getGlTitleOnglId(glId, societyId)
            .then((data) => {
                if (data != null) {
                    setGLTitle(data);
                    let glInfo = glDatas.find(a => a.glId === parseInt(glId));
                    if (glInfo?.isTaxable === 'No') {
                    }
                    else {
                        setGstPercent(glInfo.gst);
                        setTdsPercent(glInfo.tds);
                    }
                }
                else {
                    setGLTitle('');
                    toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
                }
            });
    }

    return (
        <div className="GL_Master" style={{ height: '100%', padding: '10px', marginTop: "10px", backgroundColor: "Info" }}>
            <Card className="BackgroundColor">
                <Toast ref={toast} position="center" />
                <div className="container">
                    <div className="container__half">
                        <div className="GroupMaster">
                            <h2 style={{ textAlign: 'center' }}>Add New GL Head For Billing</h2>
                            <Popup trigger={<a href="#" style={{ marginLeft: '15%', }}> Search Gl-Id </a>} position="right top" contentStyle={GlcontentStyle}>
                                <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => { setSelectedGlidGltitle(e.value); setSelectedGlId(e.value.glId) }} dataKey="glId" className="p-datatable-sm"
                                    scrollable scrollHeight='200px' style={{ overflow: 'hidden' }} width="250px" >
                                    <Column field="glId" header="Gl Id" filter></Column>
                                    <Column field="glTitle" header="GL Title" filter></Column>
                                </DataTable>
                            </Popup>
                            <div className="p-formgroup-inline">
                                <div className="p-field p-grid">
                                    <label className="label100" style={{ width: '100px', marginLeft: '80px' }}>GL ID:</label>
                                    <div className="p-col">
                                        <input type="text" name="glId" style={{ width: '200px', marginTop: '-30px', borderRadius: '6px', marginLeft: '-5px', borderColor: 'lightgrey', height: '25px' }}
                                            className='Gl_id' autoComplete="off" value={selectedGlId} onChange={onChangeGlId} onInput={InputGlId} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '-13px' }} >
                                    <label className="label100" style={{ width: '150px' }}>GL Title:</label>
                                    <input type="text" name="glTitle" className='Gl_Title' style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px', marginLeft: '40px' }} value={glTitle} autoComplete="off" onChange={(e) => setGLTitle(e.target.value)} />
                                </div>
                            </div>
                            <div className="p-formgroup-inline ">
                                <div className="p-field p-grid">
                                    <label className="label100" style={{ width: '150px', color: 'black', marginLeft: '80px', }}>Select Units:</label>
                                    <div className="p-col" style={{ marginLeft: '-50px' }}>
                                        <select name="amountType" className="selectStyle" style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}>
                                            <option hidden value=""> Select Units</option>
                                            <option> All</option>
                                            <option>Residential</option>
                                            <option>Commercial</option>
                                            <option>Semi Commercial</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="p-field p-grid">

                                <label className="label100" style={{ width: '150px', color: 'black', marginLeft: '15px', }}> Billing Cycle:</label>
                                <div className="p-col" style={{ marginLeft: '-60px',marginTop:'-10px' }}>

                                    <select
                                        className="ShortTextBox"
                                        name="billingCycle"
                                        value={billingCycle}
                                        onChange={(e) => setBillingCycle(e.target.value)}
                                    >
                                        <option value="Monthly">Monthly</option>
                                        <option value="Quarterly">Quarterly</option>
                                        <option value="Half-Yearly">Half-Yearly</option>
                                        <option value="Yearly">Yearly</option>
                                    </select></div></div>
                                    </div>
                            <div className="p-formgroup-inline ">

                                <div className="p-field p-grid">
                                    <label className="label100" style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px', marginLeft: '50px' }}>Calculation Type:</label>
                                    <div className="p-col" style={{ marginLeft: '-60px' }}>
                                        <select
                                            name="amountType"
                                            className="selectStyle"
                                            style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}
                                            onChange={(e) => {
                                                handleCalculationTypeChange(e);
                                                updateAreaSqft();
                                            }}
                                            value={calculationType}
                                        >
                                            <option hidden value=""> Select Amount Type</option>
                                            <option>Fixed</option>
                                            <option>Areawise(sqft)</option>
                                        </select>                                             </div>
                      </div>

                                        {calculationType === 'Fixed' && (
                                            <div className="p-field p-grid" style={{marginLeft:'8%'}}>

                                                <label className="label100" style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px', marginLeft: '-35%' }}>Amount:</label>
                                                <div className="p-col" style={{ marginLeft: '-100px' }}>

                                                <input
                                                    type="text"
                                                    name="amount"
                                                    className='RateAmount'
                                                    autoComplete="off"
                                                    value={amount} style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px', marginLeft: '-10px',marginTop:'-20px' }}
                                                    onChange={(e) => {
                                                        setAmount(e.target.value);
                                                        updateAmount();
                                                    }}
                                                />
                                            </div></div>
                                        )}
                                        {calculationType === 'Areawise(sqft)' && (
                                            <div className="p-field p-grid" style={{marginLeft:'8%'}}>
                                                <label className="label100" style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px', marginLeft: '-35%' }}>Rate Per Sqft:</label>
                                                <div className="p-col" style={{ marginLeft: '-100px' }}>

                                                <input
                                                    type="text"
                                                    name="ratePerSqft"
                                                    className='RatePerSqft'
                                                    autoComplete="off"
                                                    value={ratePerSqft} style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px',marginTop:'-3px' ,marginLeft: '18px' }}
                                                    onChange={(e) => {
                                                        setRatePerSqft(e.target.value);
                                                        updateAreaSqft();
                                                    }}
                                                /></div>
                                            </div>
                                        )}
                                    
                            </div>
                            <div className="button" style={{ display: 'flex' }}>
                                <Button icon="pi pi-save" label="ADD" type="text" onClick={handleAddButtonClick} />
                                <Button icon="pi pi-refresh" label="RESET" value="Delete" tooltip="RESET" className="btn" onClick={onCancel} />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <div className='GridView'>
                <DataTable value={personalDetailsData} className="p-datatable-gridlines" scrollable scrollHeight='60vh' style={{ overflow: 'hidden' }} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}  >
                    <Column field="flatID" style={{ width: '70px' }} header="Flat Id" filter></Column>
                    <Column field="wing" style={{ width: '70px' }} header="Wing" filter></Column>
                    <Column field="flatNo" style={{ width: '80px' }} header="Flat No" filter></Column>
                    <Column field="name" style={{ width: '220px' }} header="Member Name" filter></Column>
                    <Column field="glId" style={{ width: '80px' }} header="Gl Id" filter></Column>
                    <Column field="glTitle" style={{ width: '150px' }} header="Gl Title" filter></Column>
                    <Column field="area" style={{ width: '220px' }} header="Area Sqft" filter></Column>
                    <Column field="amount" style={{ width: '220px' }} header="Amount" filter ></Column>
                </DataTable>
            </div>
            <div className="table-footer">
                <Button label="Submit" onClick={handleSubmit(onSubmit)} style={{ margin: "5px" }} />
            </div>
        </div>
    );
}

export default Addnew_Gl;
