import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useRef } from 'react';
import SocietyService from './Service/SocietyService';
import axios from 'axios';
import Config from "./Config.json";
import { Toast } from 'primereact/toast';
import authHeader from './authHeader';
import './UserView.css';

function UsersView({ props }) {

    const { errors, handleSubmit, register, reset, setValue } = useForm();
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [registerUserData, setRegisterUserDetailsData] = useState([]);
    const societyService = new SocietyService();
    const toast = useRef(null);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [DisableButton, setDisableButton] = useState(true);

    const onDelete = (event) => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="p-text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <Button type="button" label="Yes" className="p-button-success" onClick={handleSubmit(onYes)} />
                        </div>
                        <div className="p-col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
                        </div>
                    </div>
                </div>
            )
        });
    }

    const onYes = (event) => {
        toast.current.clear();

        let id = selectedRadioData.userId;


        //personalDetailsData.splice(index,1);
        let data = registerUserData.filter(b => b.id != selectedRadioData.id);
        setRegisterUserDetailsData(data);
        axios.delete(`${Config.apiUrl}/Authentication/delete?id=${id}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data != null) {
                    window.location.reload();

                    toast.current.show({ severity: 'success', detail: response.data[0].msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    useEffect(() => {
        societyService.getRegisterUserDetails(societyId)
            .then(data => {
                setRegisterUserDetailsData(data);
            });
        console.warn(registerUserData);

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }
    }, []);

    const header = (
        <div className="table-header" style={{ justifyContent: 'space-between', display: 'flex' }}>
            Users Details

            <Button icon="pi pi-trash" value="Delete" tooltip="Delete" className="btn" onClick={onDelete} disabled={DisableButton} />

        </div>

    );

    return (
        <div className="RegisterUserView" data-testid=" RegisterUserView">
            <Toast ref={toast} />

            <div className='Users'>
                <DataTable value={registerUserData} className="p-datatable-gridlines" style={{background:'linear-gradient(to bottom, #fcfff4 0%,#e9e9ce 100%);'}} scrollable scrollHeight='500px' style={{ overflow: 'hidden' }} header={header} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}  >
                    <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>

                    <Column field="userName" header="User Name" style={{ width: '250px' }} filter ></Column>
                    <Column field="firstName" header="First Name" style={{ width: '150px' }} filter ></Column>
                    <Column field="lastName" header="Last Name" style={{ width: '150px' }} filter></Column>
                    <Column field="wing" header="Wing" style={{ width: '70px' }} filter></Column>
                    <Column field="flatNo" header="Flat No" style={{ width: '80px' }} filter></Column>
                    {/* <Column field="societyName" header="Society Name" filter></Column> */}
                    <Column field="accessLevel" style={{ width: '80px' }} header="AccessLevel" filter></Column>

                </DataTable>


            </div>
        </div>

    );
}
export default UsersView;