import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from 'Service/SocietyService';
import { Dropdown } from 'primereact/dropdown';
import './Memberledger.css';
import axios from "axios";
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import authHeader from '../authHeader';
import { InputText } from 'primereact/inputtext';
import { SocietyContext } from "../SocietyContext";

import moment, { invalid } from 'moment';
import { Card } from 'primereact/card';
import { useDebounce } from 'use-debounce';
import { SocietyDetails } from '../actions/index';
import { useLocation } from "react-router-dom";

var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

var memberdata = [];
var societyData = [];
function MemberLedger() {
  const location = useLocation();
  const societyId = parseInt(localStorage.getItem('societyId'));
  const [selectedFlatNo, setSelectedFlatNo] = useState(''); // Define selectedFlatNo state

  const toast = useRef(null);
  const [memberLedgerData, setMemberLedgerData] = useState([]);
   const [unit, setUnit] = useState('All');
   const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const dt = useRef(null);
  var [GlId, setGlId] = useState([]);
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [personalDetailsData, SetPersonalDetailsData] = useState([]);

  const [wings, setWings] = useState([]);
  const [selectedWing, setSelectedWing] = useState();
  const tableName = sessionStorage.getItem('tblName');
  const { register, errors, clearErrors, setValue } = useForm();
  const [Name, setName] = useState('');
  const cm = useRef(null);
  const [flatNo, setFlatNo] = useState('');
  const [backButton, setBackDisabled] = useState(false);
  const [nextButton, setNextDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [BillDatas, setBillData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [maxDate, setMaxDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  const [flatId, setFlatId] = useState();
  var [flatIndex, setFlatIndex] = useState(0);
  const [addFlatData, setFlatData] = useState([]);
  const [addFlatData1, setFlatData1] = useState([]);

  memberdata = memberLedgerData;

  const societyService = new SocietyService();

  const [value] = useDebounce(flatNo, 3000);
  
  //const getGridData = (fromDate, toDate, societyId, tableName, flatId) => {
  //  setSpinnerVisible(true);
    //societyService.getMemberLedgerDetails(fromDate, toDate, societyId, tableName, flatId)
      //.then(data => {
       // console.log('Received data:', data); // Log received data

       // let filteredData = data.filter(item => {
         //console.log('Filtering item:', item); // Log each item for debugging

          //if (unit === 'All') {
           // return true;
         // } else {
           // return item.unit === unit && item.flatNo === selectedFlatNo;
         // }
        //});

       // console.log('Filtered data:', filteredData); // Log filtered data
        //setMemberLedgerData(filteredData);
      //})
      //.finally(() => {
       // setSpinnerVisible(false);
    //  });
  //};


  //console.warn(memberLedgerData);


useEffect(() => {
  societyService.getSocietyDetailsOnID(societyId).then(Sdata => {
    societyData = Sdata;
  });
}, [societyId]);
useEffect(() => {
  societyService.getFlat(societyId)
      .then(data => {
          data.forEach(a => {
              a.unitNo = `${a.wing}-${a.flatNo}`;
          })
          data.splice(0, 0, { unitNo: 'All' })

          SetPersonalDetailsData(data)

      }
      );
}, [])

const onChangeWing1 = (e) => {
  let selectedFlat = e.value;
  setFlatNo(selectedFlat);
  if(e.value.unitNo==='All')
  {
      setFlatId(0);
  }
  else{
  setFlatId(selectedFlat.id);
  }   
}

  useEffect(() => {
    societyService
      .getWing(societyId)
      .then((data) =>
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),
        ),
      );
  }, []);

  const onChangeWing = (e) => {
    let wing = e.currentTarget.value
    setSelectedWing(wing);
  }
    
  const InputFlatNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setFlatNo(onlyNums);
  }

  const onChangeFlat = (e) => {
    let flatNo = e.currentTarget.value;

    societyService
      .getFlat(societyId)
      .then((data) => {
        setData(data);
        if (data.length > 0) {
          let fNo = data.find(a => a.flatNo === parseInt(flatNo));
          if (fNo === undefined) {
            Reset();
            return;
          }
          else {
            let i = data.findIndex(a => a.flatNo === parseInt(flatNo) && a.wing === selectedWing);
            setFlatIndex(i);
            if (i === 0) {
              setBackDisabled(true);
              setNextDisabled(false);
            }
            if (i === data.length - 1) {
              setNextDisabled(true);
              setBackDisabled(false);
            }
            setName(data[i].name);
            setSelectedWing(data[i].wing);
            setFlatId(data[i].id);

            // Fetch and display data only if submit button clicked
            if (submitClicked) {
              getGridData();
            }
          }
        }
      });
  }

  const getGridData = (fromDate, toDate, societyId, tableName, flatId) => {
    setSpinnerVisible(true);
  
    if (data.success) {
      // Fetch data for all flat numbers
      societyService.getMemberLedgerDetails(fromDate, toDate, societyId, tableName,flatId)
        .then(data => {
          const filteredData = data.filter(item => item.creditPrinciple !== 0 || item.debitPrinciple !== 0);
          if (!data || data.length === 0) {
            toast.current.show({ severity: 'info', sticky: true, detail: "No Data found!" });
            setMemberLedgerData(data);
            return;
          }
          setIsPrintDisabled(false);
          data.forEach(item => {
            if (item.chqNo === 0) {
              item.chqNo = '---';
            }
            if (item.bankName === '') {
              item.bankName = '---';
            }
          });
          setMemberLedgerData(data);
        })
        .catch(error => {
          toast.current.show({ severity: 'info', sticky: true, detail: "Error in getting data!" });
          setSpinnerVisible(false);
        });
    }
     else {
      societyService.getMemberLedgerDetails(fromDate, toDate, societyId, tableName, flatId)
        .then(data => {
          setSpinnerVisible(false);
          if (!data || data.length === 0) {
            toast.current.show({ severity: 'info', sticky: true, detail: "No Data found!" });
            setMemberLedgerData(data);
            return;
          }
          setIsPrintDisabled(false);
          data.forEach(item => {
            if (item.chqNo === 0) {
              item.chqNo = '---';
            }
            if (item.bankName === '') {
              item.bankName = '---';
            }
          });
          setMemberLedgerData(data);
        })
        .catch(error => {
          toast.current.show({ severity: 'info', sticky: true, detail: "Error in getting data!" });
          setSpinnerVisible(false);
        });
    }
  }
  
  const handleChange = (e) => {
    setUnit(e.target.value);
    var flatId;
    if(e.target.value==="All")
    {
      flatId=0;
    }
    
    getGridData(fromDate,toDate,societyId,tableName, flatId);
  };
  const handleSubmit = () => {
    // Update state to indicate submit button clicked
    setSubmitClicked(true);
    // Fetch and display data when submit button is clicked
    getGridData(fromDate,toDate,societyId,tableName,flatId);
    setFlatData1(data);
  }
  const onDateChange = (e) => {
    let FromDate = e.currentTarget.value;
    setFromDate(FromDate);
  }
  useEffect(() => {
    // Set toDate to current date when component mounts
    const currentDate = new Date().toISOString().split('T')[0];
    setToDate(currentDate);
  }, []);
  const onToDateChange = (e) => {
    let ToDate = e.currentTarget.value;
    setToDate(ToDate);
  }
  
  const Reset = () => {
    setName('');
  }

  const portrait = () => `
  footer {
      font-size: 9px;
      color: #f00;
      text-align: center;
    }
  
    @page {
      size: portrait;
      margin: 7mm 15mm 5mm 15mm;
    }
  
    @media print {
      footer {
        position: fixed;
        bottom: 0;
      }
  
      .content-block, p {
        page-break-inside: avoid;
      }
  
      html, body {
        width: 210mm;
        height: 295mm;
  
      }
    }
  `;
    
  return (
    <div className="GL_Master" style={{ height: '100%', padding: '10px', marginTop: "10px", backgroundColor: "Info" }}>
      <Card className="BackgroundColor">
        <Toast ref={toast} position="center" />
        <div className="container">
          <div className="container__half">
            <div className="GroupMaster">
              <h2 style={{ textAlign: 'center' }}>Member Ledger</h2>
              
              <div className="p-formgroup-inline ">
                <div className="p-field p-grid">
                  <label className="label100" style={{ width: '150px', color: 'black', marginLeft: '80px', }}>Ledger Type:</label>
                  <div className="p-col" style={{ marginLeft: '-50px' }}>
                    <select name="amountType" className="selectStyle" style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px' }}>
                      <option hidden value=""> Select Units</option>
                      <option> Regular</option>
                      <option>Supplementary</option>
                    </select>
                  </div>
                </div>
                <div className="p-field p-grid">
                  <label className="label100" style={{ width: '150px', color: 'black', marginLeft: '15px' }}> Unit:</label>
                  <div className="p-col" style={{ marginLeft: '-60px', marginTop: '-10px' }}>
                  <select
    className="ShortTextBox"
    name="unit"
    value={unit}
    onChange={handleChange}>
    <option value="All">All</option>
    <option value="Flatwise">Flatwise</option>
  </select>
                  </div>
                  
                </div>
              </div>
              <div>
                    <Dropdown className='Dropdown' filter filterBy="unitNo" value={flatNo} options={personalDetailsData} onChange={onChangeWing1} id="FlatNo" name="unitNo" ref={register} optionLabel="unitNo" placeholder="Select Flat" />
                </div>
              {unit === 'Flatwise' && (
                <div className="p-formgroup-inline">
                  <div className="p-field p-grid">
                    <label className="label100" style={{ width: '100px', marginLeft: '80px' }}>Wing:</label>
                    <select style={{ height: '25px', width: '110px', marginLeft: '40px', borderRadius: '6px', border: '1px solid lightgrey' }} name="wing" onChange={onChangeWing} value={selectedWing}  >
                      <option hidden value="">Select Wing</option>
                      {wings.map(({ label, value: wings }) => (
                        <option key={wings} value={wings}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ marginTop: '-13px' }} >
                    <label className="label100" style={{ width: '150px' }}>Flat No:</label>
                    <input type="text" style={{ height: '25px', width: '110px', borderRadius: '6px', border: '1px solid lightgrey' }} name="flatNo" value={flatNo} onChange={onChangeFlat} onInput={InputFlatNo} />
</div>
                  <div style={{ marginTop: '-13px' }} >
                    <label className="label100" style={{ width: '150px' }}>Name:</label>
                    <input type="text" name="name" className='Gl_Title' value={Name} style={{ width: '200px', borderRadius: '6px', borderColor: 'lightgrey', height: '25px', marginLeft: '40px' }} autoComplete="off" />
                  </div>
                </div>
              )}

              <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                <label style={{ width: '150', padding: 2, margin: 2 , marginLeft:'20px'}}>From:</label>
        <input type="date" max={maxDate} style={{
          height:'35px', padding: '6 2 6 2', margin: 2, marginRight: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
          borderRadius: 5,
        }} name="date" value={fromDate} onChange={onDateChange} />

                </div>
                <div style={{ marginTop: '-13px' }} >
                <input
        type="date"
        name="date"
        max={maxDate}
        style={{
          height: '35px',
          padding: 2,
          margin: 2,
          marginRight: 10,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'green',
          borderRadius: 5,
        }}
        value={toDate}
        onChange={onToDateChange}
      />
</div>
              </div>
                           
              <div className="button">
                <Button label="Submit" onClick={handleSubmit} style={{ margin: "5px" }} />
              </div>
            </div>
          </div>
        </div>

        {/* Society Details */}
        {submitClicked && (
          <div className='SocietyName'>
            <div class="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>{societyData[0]?.address}</text>
            </div>
          </div>
        )}

        <div className="p-formgroup-inline">

<table>
  <thead>
  <th style={{ width: '12%', textAlign: 'center' }}>Date</th>
                 
                  <th style={{ width: '5%', textAlign: 'center' }}>Flat No</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Rct No</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Bill No</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Mode</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>cheqNo</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Debit</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Int</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Total</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Credit</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Balance</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Narration</th>
                  </thead>
            <tbody>
                  {
                    memberLedgerData.map((item) => (
                      <tr key={item}>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{item.dateFormat}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.flatNo}</td>

                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.rctNo}</td>
                        <td style={{ padding: '5px', textAlign: 'left' }}>{item.billNo}</td>
                        <td style={{ padding: '5px', textAlign: 'left' }}>{item.mode}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.cheqNo}</td>
                      
                        <td style={{ padding: '5px', textAlign: 'center' }}>{item.debitPrinciple}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.debitInterest}</td>
                        <td style={{ padding: '5px', textAlign: 'left' }}>{item.totalAmount}</td>
                        <td style={{ padding: '5px', textAlign: 'left' }}>{item.creditPrinciple}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.balance}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.narration}</td>
                      
                       </tr>
                    ))
                  }
                  
           </tbody>
</table>
 
        </div>
      </Card>
    </div>
  );
}

export default MemberLedger;
