import 'primeflex/primeflex.css';
import './GRReturnEntry.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router';
import SocietyService from '../../Service/SocietyService';
import { useEffect, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import { SocietyContext } from "../../SocietyContext";
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { usePopup } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';


function GRReturnEntry({ props }) {

  const { register, handleSubmit,reset, errors, clearErrors, setValue } = useForm();
  const { id } = useParams();
  const toast = useRef(null);
  var societyId = parseInt(localStorage.getItem('societyId'));
  const tableName = sessionStorage.getItem('tblName');
  const societyService = new SocietyService();
  const [date, setDate] = useState([]);
  const [wings, setWings] = useState([]);
  const [wing, setWing] = useState();
  const [Name, setName] = useState([]);
  const [GlTitle, setGlTitle] = useState();
  const [Mode, setMode] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [personalDetailsData, SetPersonalDetailsData] = useState([]);
  const [selectedFlatNoName, setSelectedFlatNoName] = useState();
  const [RctNo, setRctNo] = useState([]);
  const [glIdData, setGlIdData] = useState([]);
  const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
  const [selectedGlId, setSelectedGlId] = useState();
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [flatId, setFlatId] = useState(0);
  const [flatNo, setFlatNo] = useState();

  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const isAddMode = !id;

  const contentStyle = {
    maxWidth: "600px",
    width: "90%",
    height: "40%",
    marginLeft: "250px"
  };
  const GlcontentStyle = {
    maxWidth: "600px",
  };

  const onSave = (data, e) => {
    data.TransactionTable=tableName;
    return isAddMode
      ? createGRReturnEntry(data)
      : updateGRReturnEntry(data);
  }

  const createGRReturnEntry = (data, e) => {
    setSpinnerVisible(true);
    data.transactionType = "GRR";
    data.societyId = societyId;
    data.flatId = flatId;
    if (data.amount === '0') {
      toast.current.show({ severity: 'warn', detail: 'Enter Correct Amount' });
      return;
    }
    return axios.post(`${Config.apiUrl}/Transaction/InsertGRReturnEntry`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data[0].msg });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
        window.location.reload();
        SetFocusValue(true);
      })
  }

  const updateGRReturnEntry = (data, e) => {
    setSpinnerVisible(true);
    return axios.put(`${Config.apiUrl}/Transaction/UpdateGRReturnEntry?id=${id}`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data[0].msg });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
        window.location.reload();
        SetFocusValue(true);
      })
  }

  useEffect(() => {
    let d = moment(moment(new Date())).format('YYYY-MM-DD');
    setValue('date', d);
    console.warn(d);

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }

    societyService.getPersonalDetails(societyId,tableName)
      .then(data => SetPersonalDetailsData(data));
    console.warn(personalDetailsData);

    //get gLid and glTitle
    societyService.getGlTitle(societyId)
      .then(data => {
        setGlIdData(data)
      });

    societyService
      .getWing(societyId)
      .then((data) =>
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),
        ),
      );

    if (!isAddMode) {
      // get user and set form fields
      societyService.getGRReturnEntryDetailsOnID(id,tableName).then(userIn => {
        const fields = ['date', 'glid', 'gltitle', 'flatNo', 'wing', 'rctNo', 'chqNo', 'name', 'amount', 'bank', 'narration'];
        fields.forEach(field => setValue(field, userIn[0][field]));
        setValue('date', userIn[0].dateFormat);
        setValue('glId', userIn[0].glId);
        setValue('glTitle', userIn[0].glTitle);
        setValue('wing', userIn[0].wing);
        setValue('flatNo', userIn[0].flatNo);
        setValue('name', userIn[0].name);
        setValue('rctNo', userIn[0].rctNo);
        setValue('amount', userIn[0].amount);
        setValue('mode', userIn[0].mode);
        setValue('chqNo', userIn[0].chqNo);
        setValue('bankName', userIn[0].bankName);
        setValue('narration', userIn[0].narration);
        setFlatId(userIn[0].flatId);
      });
    }

    societyService.getRctNo(societyId,tableName).then(data => {
      setValue('rctNo', data);
    }
    );
  }, []);

  const onClose = (e) => {
    setValue('glId', '');
    setValue('glTitle', '');
    setValue('wing', '');
    setValue('flatNo', '');
    setValue('name', '');
    setValue('dues', '');
    setValue('amount', '');
    setValue('rctNo', '');
    setValue('mode', '');
    setValue('chqNo', '');
    setValue('bankName', '');
    setValue('remarks', '');
  }

  const onDateChange = (e) => {
    setDate(e.currentTarget.value);
    setValue('date', e.currentTarget.value)
  }

  const onChangeWing = (e) => {
    let wing = e.currentTarget.value;
    setValue('wing', wing);
    setWing(wing);
  }

  const onChangeFlat = (e) => {
    let flatNo = e.currentTarget.value;
    if (flatNo !== '') {
      flatNo = parseInt(flatNo);
      setFlatNo(flatNo);
  }

    societyService
      .getName(flatNo, wing, societyId)
      .then((data) => {
        if (data.length > 0) {
          setValue('name', data[0].name);
          setFlatId(data[0].id);
        }
        else {
          setValue('name', '');
        }
      }
      );
  }

  const onChangeGlId = (e) => {
    let glId = e.currentTarget.value;
    societyService
      .getGlTitleOnglId(glId, societyId)
      .then((data) => {
        if (data != null) {
          setGlTitle(data);
          console.warn(GlTitle);
        }
        else {
          setGlTitle('');
          toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
        }
      }
      );
  }

  const onModeChange = (e) => {
    let mode = e.currentTarget.value
    setMode(mode);
  }

  const onRowSelect = (event) => {
    let fNo = event.data.flatNo;
    let name = event.data.name;
    let wing = event.data.wing;
    setValue('flatNo', fNo);
    setValue('name', name);
    setValue('wing', wing);
  }

  const onRowSelectGlid = (e) => {
    let glid = e.data.glId;
    let glTitle = e.data.glTitle;
    setSelectedGlId(glid);
    setGlTitle(glTitle);
  }

  const InputGlId = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('glId', onlyNums);
  }

  const InputFlatNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('flatNo', onlyNums);
  }

  const InputAmount = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
    setValue('amount', onlyNums);
  }

  const InputChequeNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('chqNo', onlyNums);
  }
  const onReset = () => {
    reset();
    window.location.reload();
  }
  return (

    <div className="GRReturnEntry backGroundColor" style={{ border: "1px solid black", width: "auto", backgroundColor: "Info", padding: '10px' }}>
      <h3  style={{marginLeft: '20px',display:'flex',justifyContent:'center',color:'red'}}>General Receipt Return </h3>
      <Toast ref={toast} position={'center'} />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
      <div className="p-formgroup-inline">
        <div className="p-field p-grid " style={{ marginTop: "5px" }}>
          <label className="p-col-fixed" style={{ width: '100px' }}>Date:</label>
          <div className="p-col">
            <input type="Date" name="date" className="smallTextBox" autoFocus={focusValue} style={{ width: '175px' }} onChange={onDateChange} ref={register({ required: true })} />
          </div>
        </div>

        <Popup trigger={<a href="#" style={{ marginLeft: '100px' }}>Search Flat No!</a>} position="right top" contentStyle={contentStyle}>
          <DataTable value={personalDetailsData} selectionMode="single" selection={selectedFlatNoName} onRowSelect={onRowSelect} onSelectionChange={e => setSelectedFlatNoName(e.value)} dataKey="id" className="p-datatable-sm" scrollable scrollHeight='350px' style={{ overflow: 'hidden' }} width="250px" >
            <Column field="wing" header="Wing" filter style={{ width: '150px' }}></Column>
            <Column field="flatNo" header="Flat No" filter style={{ width: '150px' }}></Column>
            <Column field="name" header="Name" filter></Column>
          </DataTable>
        </Popup>
      </div>

      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>GL ID:</label>
          <div className="p-col">
            <input type="text" name="glId" style={{ width: '100px',borderRadius:'6px', borderColor:'lightgrey' }} className="height" onChange={onChangeGlId} ref={register({ required: true })} onInput={InputGlId} value={selectedGlId} autoComplete="off" />
          </div>
          <div>
            <input type="text" name="glTitle" style={{ width: '374px',borderRadius:'6px', borderColor:'lightgrey' }} className="height" ref={register} value={GlTitle} autoComplete="off" />
            <Popup trigger={<a href="#" style={{ marginLeft: '50px' }}> Search Gl-Id </a>} position="right top" contentStyle={GlcontentStyle}>
              <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => setSelectedGlidGltitle(e.value)} dataKey="glId" className="p-datatable-sm" scrollable scrollHeight='280px' style={{ overflow: 'hidden' }} width="250px" >
                <Column field="glId" header="Gl Id" filter></Column>
                <Column field="glTitle" header="GL Title" filter></Column>
              </DataTable>
            </Popup>
          </div>
        </div>
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        {errors.glId && (
          <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-15px' }}>Please enter GL ID</p>
        )
        }
      </div>

      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>Wing:</label>
          <div className=" p-col" >
            <select style={{ width: '175px' }} name="wing" className="smallTextBox" ref={register} onChange={onChangeWing} >
              <option hidden value="">Select Wing</option>
              {wings.map(({ label, value: wings }) => (
                <option key={wings} value={wings}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>Flat No:</label>
          <div className="p-col">
            <input type="text" name="flatNo" className="smallTextBox" ref={register} onChange={onChangeFlat} onInput={InputFlatNo} autoComplete="off" />
          </div>
        </div>
      </div>

      <div className="p-fluid">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '108px' }}>Name:</label>
          <div className="p-col-12 p-md-2 ">
            <input type="text" readOnly name="name" style={{ width: '484px',borderRadius:'6px', borderColor:'lightgrey' }} className="height" ref={register({ required: true })} autoComplete="off" />
          </div>
        </div>
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        {errors.name && (
          <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>Please select Name</p>
        )
        }
      </div>

      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>Amount:</label>
          <div className="p-col">
            <input type="text" min="1" name="amount" className="smallTextBox" ref={register({ required: true })} onInput={InputAmount} autoComplete="off" />
          </div>
        </div>

        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '102px' }}>Rct No:</label>
          <div className="p-col" >
            <input type="text" name="rctNo" readOnly className="smallTextBox" ref={register({ required: true })} autoComplete="off"  />
          </div>
        </div>
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        {errors.amount && (
          <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-10px' }}>Please enter amount</p>
        )
        }
      </div>

      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '99px' }}>Mode:</label>
          <div className="p-col">
            <select style={{ width: '175px' }} onChange={onModeChange} name="mode" className="smallTextBox" ref={register}>
              <option hidden value=''>Select Mode</option>
              <option>Cash</option>
              <option>Online</option>
              <option>Cheque</option>
            </select>
          </div>
        </div>

        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>Chq No:</label>
          <div className="p-col">
            <input type="text" name="chqNo" disabled={Mode === 'Cash'} className="smallTextBox" ref={register} onInput={InputChequeNo} autoComplete="off" />
          </div>
        </div>
      </div>

      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>Bank:</label>
          <div className="p-col" >
            <input type="text" name="bank" disabled={Mode === 'Cash'} style={{ width: '483px',borderRadius:'6px', borderColor:'lightgrey' }} className="height" ref={register({ required: true })} autoComplete="off" />
          </div>
        </div>
      </div>

      <div className="p-formgroup-inline">
        <div className="p-field p-grid">
          <label className="p-col-fixed" style={{ width: '100px' }}>Narration:</label>
          <div className="p-col">
            <input type="textbox" name="narration" style={{ width: '483px', height: '80px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} autoComplete="off" />
          </div>
        </div>
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        {errors.narration && (
          <p className="errorMsg" style={{ color: 'red', marginLeft: '250px', marginTop: '-15px' }}>There should be Narration</p>
        )
        }
      </div>

      <div className="p-formgroup-inline" style={{ justifyContent: 'left', marginLeft: '90px' }}>
        <div className="p-field">
          <Button label="SAVE" type="submit" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} onClick={handleSubmit(onSave)} disabled={DisableButton} />
          <Button label="RESET" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} disabled={DisableButton}  onClick={onReset} />
        </div>
      </div>
    </div>
  );
}
export default GRReturnEntry;