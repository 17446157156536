import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import './GL_MasterAuditView.css';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { SocietyContext } from "../SocietyContext";

function GL_MasterAuditView() {

    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();

    const [globalFilter, setGlobalFilter] = useState('');
    const [GL_MasterAuditData, setGL_MasterAuditInfo] = useState();
    const [fromDate, setFromDate] = useState( localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState( moment(new Date()).format('YYYY-MM-DD'));
    const societyService = new SocietyService();
    var societyId=parseInt(localStorage.getItem('societyId'));


    useEffect(() => {
        // let d = moment(moment(new Date())).format('DD-MM-YYYY 00:01');
        // setValue('fromDate', d);
        // setFromDate(d);
        // setFromDate(d);
        // console.warn(d);

        // let toD = moment(moment(new Date())).format('DD-MM-YYYY 23:59');
        // setValue('toDate', toD);
        // setToDate(toD);
        // console.warn(toD);        
    }, []);

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {

        societyService.getGL_MasterAuditInfo(fromDate, toDate,societyId).then(data => setGL_MasterAuditInfo(data));
        console.warn(GL_MasterAuditData);
    }


    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>GL Master Audit View</b> */}

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }}  value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className="label" >From:</label>
            <input type="date" name="fromDate" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className="label" >To:</label>
            <input type="date" name="toDate" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />

        </div>
    );

    return (
        
        <div className="GL_MasterAuditView">
            <span style={{fontFamily:'verdena', fontWeight:'bold'}}>GL Master Audit View</span> 
            <div>
            <DataTable className="p-datatable-sm" responsiveLayout="scroll" scrollable scrollHeight='410px' style={{ overflow: 'hidden' }} header={header}
                globalFilter={globalFilter} value={GL_MasterAuditData} emptyMessage="No result found.">
                <Column field="glId" header="GL ID" style={{ width: '70px' }} filter></Column>
                <Column field="glTitle" header="GL Title" style={{ width: '120px' }} filter></Column>
                <Column field="groupName" header="Group Name" style={{ width: '120px' }} filter></Column>
                <Column field="subGroupName" header="Sub-Group Name" style={{ width: '120px' }} filter></Column>
                <Column field="balanceDateFormat" header="Balance Date" style={{ width: '115px' }} filter></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '100px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>

            </DataTable>
                            
            </div>
        </div>
    );
}
export default GL_MasterAuditView;