import 'primeflex/primeflex.css';
import './voucherEntry.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useParams } from 'react-router';
import SocietyService from '../../Service/SocietyService';
import { useEffect, useContext, useRef } from 'react';
import { useState } from "react";
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import moment, { invalid } from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import Popup from "reactjs-popup";
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { useHistory } from 'react-router-dom';

function VoucherEntry({ props }) {
  let history = useHistory();

  const { register, handleSubmit, reset,errors, clearErrors, setValue } = useForm();
  const { id } = useParams();
  const toast = useRef(null);
  const societyService = new SocietyService();
  const [date, setDate] = useState([]);
  const [isAddMode, setIsAddMode] = useState(id<=0|| id==undefined);
  const [Mode, setMode] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [scrollNo, setScrollNo] = useState();
  const [d, setd] = useState([]);
  const [amount, setAmount] = useState([]);
  const [GlTitle, setGlTitle] = useState();
  const [glId, setGlId] = useState();
  const [addVoucherEntry, setVoucherEntry] = useState([]);
  var societyId = parseInt(localStorage.getItem('societyId'));
  const societyName = localStorage.getItem('societyName');
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(false);
  const [SaveDisable, setSaveDisable] = useState(false);
  var [societyBank, setSocietyBankData] = useState([]);
  const [societyBankNames, setSocietyBankName] = useState([]);
  const [selectedBankGlId, setSelectedBankGlId] = useState([]);
  const [selectedBank, setSelectedBank] = useState();
  var [voucherReceipt, setVoucherReceipt] = useState([]);
  const [glIdData, setGlIdData] = useState([]);
  const [selectedGlId, setSelectedGlId] = useState();
  const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
  var [glDatas, setGlData] = useState([]);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [gstPercent, setGstPercent] = useState(0);
  const [tdsPercent, setTdsPercent] = useState(0);
  const [saveButtonLabel, setSaveButtonLabel] = useState('SAVE');
  const tableName = sessionStorage.getItem('tblName');


  const GlcontentStyle = {
    maxWidth: "600px",
    marginLeft: "200px"
  };

  const onSave = (data, e) => {
    data.TransactionTable=tableName;
    setSaveDisable(true);
    return isAddMode
      ? createVoucherEntry(data)
      : updateVoucherEntry(data);
  }

  const createVoucherEntry = (data, e) => {
    try {
      data.transactionType = "VE";
      data.societyId = societyId;
      data.societyName = societyName;
      data.bankGlId = selectedBankGlId;

      if (data.amount === '0') {
        toast.current.show({ severity: 'error', detail: 'Enter Correct Amount' });
        return;
      }
      if (data.gltitle === '') {
        toast.current.show({ severity: 'error', detail: 'Enter Correct Gl Id' });
        return;
      }
      if (data.mode === 'Select Mode') {
        toast.current.show({ severity: 'error', detail: 'Please select Proper Mode' });
        return;
      }
      if (data.chqNo === '') {
        data.chqNo = '0';
      }

      setSpinnerVisible(true);
      return axios.post(`${Config.apiUrl}/Transaction/InsertVoucherEntry`, data, { headers: authHeader() })
        .then(response => {
          if (response.data.success) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Data Saved Successfully',sticky:true});
            setSaveDisable(false);
          }
          else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg,sticky:true});
            setSaveDisable(false);
          }
        })
        .catch(error => {
          setSaveDisable(false);
          toast.current.show({ severity: 'error', detail: error,sticky:true});
        })
        .finally(() => {
          setSpinnerVisible(false);
          SetFocusValue(true);
        })
    }
    catch (error) {
      toast.current.show({ severity: 'error', detail: error });
      setSaveDisable(false);
    }
  }

    const updateVoucherEntry = (data, e) => {
    try {
      data.societyId = societyId;
      data.societyName = societyName;
      data.bankGlId = selectedBankGlId;
      data.scrollNo = parseInt(data.scrollNo);
      data.glId = parseInt(data.glId);
      data.amount = parseFloat(data.amount);
      setSpinnerVisible(true);
      if (data.chqNo === '') {
        data.chqNo = '0';
      }
      return axios.put(`${Config.apiUrl}/Transaction/UpdateVoucherEntry?id=${id}`, data, { headers: authHeader() })
        .then(response => {
          if (response.data.success) {
            toast.current.show({ severity: 'success', detail: 'Data Updated Successfully',sticky:true});
            setSaveDisable(false);
          }
          else {
            toast.current.show({ severity: 'error', detail: response.data.msg ,sticky:true});
            setSaveDisable(false);
          }
        }).catch(error => {
          setSaveDisable(false);
          toast.current.show({ severity: 'error', detail: error,sticky:true });
        })
        .finally(() => {
          setSpinnerVisible(false);
          SetFocusValue(true);
        })
    }
    catch (error) {
      setSaveDisable(false);
      toast.current.show({ severity: 'error', detail: error ,sticky:true});
    }
  }

  let type = 'VE';
  useEffect(() => {
    let d = moment(moment(new Date())).format('YYYY-MM-DD');
    setDate(d);
    setValue('date', d);
    const fetchData = async () => {
      const scrollNoData = await societyService.getScrollNo(d, societyId, type,tableName);
      setScrollNo(scrollNoData);

      const glData = await societyService.getGlTitle(societyId);
      setGlIdData(glData);

      const getGlMasterData = await societyService.getGlMasterData(societyId);
      setGlData(getGlMasterData);
      glDatas = getGlMasterData;

      const voucherEntryDetails = await societyService.getVoucherEntryDetails(d, societyId,tableName);
      setVoucherEntry(voucherEntryDetails);

      if (!isAddMode) {
        setSaveButtonLabel("Update");
        // get user and set form fields
        societyService.getVoucherEntryDetailsOnID(id,tableName,societyId).then(voucherEntryData => {
          const fields = ['glId', 'glTitle', 'chqNo', 'name', 'amount', 'bankName', 'narration', 'mode'];
          fields.forEach(field => setValue(field, voucherEntryData[0][field]));
          voucherReceipt = voucherEntryData;
          onModeChangeEditAction(voucherEntryData[0].mode);
          OnNetPayEditAction(voucherEntryData[0])
          setValue('date', voucherEntryData[0].dateFormat);
          setScrollNo(voucherEntryData[0].scrollNo);
          setGlId(voucherEntryData[0].glId);
        });
      }
      else {

      }

      if (accessLevel >= 2) {
        setSaveDisable(false);
        setDisableButton(false);
      }
      else {
        setDisableButton(true);
        setSaveDisable(true);
      }
    }
    fetchData();
  }, []);

  const onRemove=()=>{
    window.location.reload();
}
  const onDateChange = (e) => {
    let date = e.target.value;
    setDate(date);
    setValue('date', date);

    societyService.getScrollNo(date, societyId,type,tableName).then(data => {
      setValue('scrollNo', data);
      setScrollNo(data);
    });

    societyService.getVoucherEntryDetails(date, societyId,tableName).then(data => setVoucherEntry(data));
    console.warn(addVoucherEntry);
  }

  const onModeChangeEditAction = async (e) => {
    let mode = e;
    setMode(mode);
    if (mode.toUpperCase() === "CASH") {
      mode = "CASH BALANCE";
    }
    else {
      mode = "BANK BALANCE";
    }
    const banks = await societyService.GetSocietyBank(societyId, mode);
    setSocietyBankData(banks);
    societyBank = banks;
    setBankDetails(voucherReceipt[0].societyBank);
    var bankNames = [];
    banks.forEach(item => {
      bankNames.push({ 'societyBank': item.societyBank });
    })

    setSocietyBankName(
      bankNames.map(({ societyBank }) => ({ label: societyBank, value: societyBank, })),
    )
    setSelectedBank(voucherReceipt[0].societyBank);
  }

  const onModeChange = async (e) => {
    let mode = e.currentTarget.value
    setMode(mode);
    if (mode.toUpperCase() === "CASH") {
      mode = "CASH BALANCE";
    }
    else {
      mode = "BANK BALANCE";
    }
    const data = await societyService.GetSocietyBank(societyId, mode);
    setSocietyBankData(data);
    var bankNames = [];
    data.forEach(item => {
      bankNames.push({ 'societyBank': item.societyBank });
    })
    setSocietyBankName(
      bankNames.map(({ societyBank }) => ({ label: societyBank, value: societyBank, })),
    )
    setSelectedBank(null);
  }


  const onChangeGlId = (e) => {
    let glId = e.currentTarget.value;
    setGlId(parseInt(glId));
    societyService
      .getGlTitleOnglId(glId, societyId)
      .then((data) => {
        if (data != null) {
          setGlTitle(data);
          console.warn(GlTitle);
          let glInfo = glDatas.find(a => a.glId === parseInt(glId));
          if (glInfo?.isTaxable === 'No') {

          }
          else {
            setGstPercent(glInfo.gst);
            setTdsPercent(glInfo.tds);
          }
        }
        else {
          setGlTitle('');
          toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
        }
      }
      );
  }

  const OnNetPayEditAction = (data) => {
    let netPay = data.netPay;
    let glInfo = glDatas.find(a => a.glId === data.glId);
    if (glInfo?.isTaxable === 'No') {
      let basicAmount = data?.netPay + data?.tds - data?.gst;
      setValue('amount', basicAmount);
      setValue('gst', data?.gst);
      let total = data?.netPay + data?.tds;
      setValue('total', total);
      setValue('tds', data?.tds);
      setValue('netPay', data?.netPay);
    }
    else {
      let basicAmount = data?.netPay + data.tds - data?.gst;
      setValue('amount', basicAmount);
      setValue('gst', data.gst);
      let total = data?.netPay + data?.tds;
      setValue('total', total);
      setValue('tds', data?.tds);
      setValue('netPay', data?.netPay);
    }
  }

  const OnChangeBasicAmount = (e) => {
    let basicAmount = e.currentTarget.value;
    if(basicAmount === ''){
      setValue('amount','');
      setValue('gst', '');
      setValue('total', '');
      setValue('tds', '');
      setValue('netPay', '');
      return; 
    }

    basicAmount = parseFloat(basicAmount);
    let glInfo = glDatas.find(a => a.glId === parseInt(glId));
    if (glInfo?.isTaxable === 'No') {
      let Gst = basicAmount * 0 / 100;
      Gst = parseFloat(Gst);
      setValue('gst', Gst);

      let Total = basicAmount + Gst;
      Total = parseFloat(Total);
      setValue('total', Total);

      let Tds = basicAmount * 0 / 100;
      Tds = parseFloat(Tds);
      setValue('tds', Tds);

      let NetPay = Total - Tds;
      NetPay = parseFloat(NetPay);
      setValue('netPay', NetPay);
    }
    else {
      let Gst = basicAmount * glInfo?.gst / 100;
      Gst = parseFloat(Gst);
      setValue('gst', Gst);

      let Total = basicAmount + Gst;
      Total = parseFloat(Total);
      setValue('total', Total);

      let Tds = basicAmount * glInfo?.tds / 100;
      Tds = parseFloat(Tds);
      setValue('tds', Tds);

      let NetPay = Total - Tds;
      NetPay = parseFloat(NetPay);
      setValue('netPay', NetPay);
    }
  }

  const onRowSelectGlid = (e) => {
    let glid = e.data.glId;
    let glTitle = e.data.glTitle;
    setSelectedGlId(glid);
    setGlTitle(glTitle);
  }

  const onChangeSelectedBank = (e) => {
    let selectBankLocal;
    if (e.currentTarget.value !== undefined) {
      selectBankLocal = e.currentTarget.value;
    }
    else {
      selectBankLocal = e;
    }

    console.warn(selectBankLocal);
    setBankDetails(selectBankLocal);
  }

  function setBankDetails(selectBankLocal) {
    let selectedGlId = societyBank.find(a => a.glTitle === selectBankLocal);
    setSelectedBankGlId(selectedGlId.glId)
    setSelectedBank(selectBankLocal);
  }

  //const onReset = (e) => {
  //  setValue('glId', '');
   // setGlTitle('');
    //setValue('name', '');
   // setValue('amount', '');
   // setValue('gst', '');
    //setValue('total', '');
   // setValue('tds', '');
    //setValue('netPay', '');
   // setValue('mode', '');
    //setValue('chqNo', '');
   // setValue('bank', '');
    //setValue('narration', '');
   // setSaveButtonLabel("Save");
   // setIsAddMode(true);

     //societyService.getScrollNo(d, societyId, type,tableName).then(data=>{
     // setValue('scrollNo', data);
     // setScrollNo(data);
    // });
    
  //}
  const onReset = () => {
    reset();
    window.location.reload();
  }
  const InputGlId = (e) => {
    try {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '');
      setValue('glId', onlyNums);
    }
    catch (e) {
      console.log(e);
    }
  }

  const InputAmount = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
    setValue('amount', onlyNums);
  }

  const InputChequeNo = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('chqNo', onlyNums);
  }
  const InputGst = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('gst', onlyNums);
  }
  const onEdit = (rowData) => {
    if (rowData.transactionType === 'GR') {
      history.push(`/GeneralReceipt/edit/${rowData.id}`);
    }
    if (rowData.transactionType === 'MR') {
      history.push(`/MemberReceipt/edit/${rowData.id}`);
    }
    if (rowData.transactionType === 'MRR') {
      history.push(`/MRReturnEntry/edit/${rowData.id}`);
    }
    if (rowData.transactionType === 'GRR') {
      history.push(`/GRReturnEntry/edit/${rowData.id}`);
    }
    if (rowData.transactionType === 'VE') {
      history.push(`/VoucherEntry/edit/${rowData.id}`);
    }
    if (rowData.transactionType === 'DE') {
      history.push(`/CreditDebitNote/edit/${rowData.id}/${rowData.jeNo}`);
    }
    if (rowData.transactionType === 'CE') {
      history.push(`/CreditDebitNote/edit/${rowData.id}/${rowData.jeNo}`);
    }
    console.warn("edit buuton clicked");
  }
  function compareGlTitils(a, b) {

    // converting to uppercase to have case-insensitive comparison
    const name1 = a.glTitle.toUpperCase();
    const name2 = b.glTitle.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }

  const editbutton = (rowData) => {
    return <div>
     <Button icon="pi pi-pencil" className='edit' value="Edit" tooltip="Edit" onClick={() => onEdit(rowData)} />
      
    </div>;
  }
  return (
    <div className="VoucherEntry" style={{ border: "1px solid black", width: "auto", backgroundColor: "Info" }} >
      <h3 style={{ marginLeft: '100px',display:'flex',justifyContent:'center',color:'red' }}>Voucher Entry</h3>
      <Toast ref={toast} position={'center'} onRemove={onRemove}/>
      <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
      <div >
        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>Date:</label>
          <input type="Date" name="date" className="smallTextBox" autoFocus style={{ width: '175px' }} onChange={onDateChange} ref={register({ required: true })} />
          <Popup trigger={<a href="#" style={{ marginLeft: '50px' }}> Search Gl-Id </a>} position="right top" contentStyle={GlcontentStyle}>
            <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => {setSelectedGlidGltitle(e.value);setGlId(e.value.glId)}} dataKey="glId" className="p-datatable-sm" 
            scrollable scrollHeight='280px' style={{ overflow: 'hidden' }} width="250px" >
              <Column field="glId" header="Gl Id" filter></Column>
              <Column field="glTitle" header="GL Title" filter focusValue={true}></Column>
            </DataTable>
          </Popup>
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.date && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '120px', marginTop: '-1px' }}>Please select Date</p>
          )
          }
        </div>


        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>GL ID:</label>
          <input type="text" name="glId" className="height" style={{ width: '100px',borderRadius:'6px', borderColor:'lightgrey' }} onChange={onChangeGlId} ref={register({ required: true })} onInput={InputGlId} value={selectedGlId} autoComplete="off" />
          <input type="text" name="glTitle" className="height" style={{ width: '380px', marginLeft: '10px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} value={GlTitle} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.glId && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '200px', marginTop: '-1px' }}>Please enter GL ID</p>
          )
          }
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label className="p-mr-2 p-mb-2" >Name:</label>
          <input type="text" name="name" className="height" style={{ width: '495px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.name && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '200px', marginTop: '-1px' }}>Please select Name</p>
          )
          }
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>Basic:</label>
          <input type="text" min="1" name="amount" className="smallTextBox" ref={register({ required: true })} onInput={InputAmount} onChange={OnChangeBasicAmount} autoComplete="off" />

          <label className='label100'>Scroll No:</label>
          <input type="text" name="scrollNo" readOnly className="smallTextBox" style={{ width: '198px' }} ref={register} value={scrollNo} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.amount && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '200px', marginTop: '-10px' }}>Please enter amount</p>
          )
          }
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">

          <label>GST {gstPercent}%:</label>
          <input type="text" name="gst" className="smallTextBox" style={{ width: '175px' }} ref={register} onInput={InputGst} autoComplete="off" />

          <label className='label100'>Mode:</label>
          <select name="mode" className="smallTextBox" style={{ width: '195px' }} ref={register({ required: true })} onChange={onModeChange}>
            <option hidden value=''>Select Mode</option>
            <option>Cash</option>
            <option>Bank</option>
          </select>
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.mode && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '200px', marginTop: '-1px' }}>Please select Mode</p>
          )
          }
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>Total:</label>
          <input type="text" name="total" className="smallTextBox" style={{ width: '175px' }} ref={register} autoComplete="off" />

          <label className='label100'>Cheque No:</label>
          <input type="text" disabled={Mode === 'Cash'} name="chqNo" className="smallTextBox" style={{ width: '195px' }} ref={register} onInput={InputChequeNo} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>Tds {tdsPercent}%:</label>
          <input type="text" name="tds" className="smallTextBox" style={{ width: '175px' }} ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>Net Pay:</label>
          <input type="text" name="netPay" className="smallTextBox" style={{ width: '175px' }} ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label > Society Bank Name:</label>
          <select style={{ width: '495px',borderRadius:'6px', borderColor:'lightgrey' }} name="societyBank" className="height" ref={register({ required: true })} onChange={onChangeSelectedBank} value={selectedBank}  >
            <option hidden value="">Select Bank Name</option>
            {societyBank.map((societyBankName) => (
              <option key={societyBank.glTitle} value={societyBank.glTitle}>
                {societyBankName.glTitle}
              </option>
            ))}
          </select>
        </div>
        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.societyBank && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '200px', marginTop: '-1px' }}>Please select society Bank</p>
          )
          }
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          <label>Narration:</label>
          <input type="text" name="narration" className="height" style={{ width: '495px', height: '80px', paddingTop: '0', justifyContent: 'flex-start',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row">
          {errors.narration && (
            <p className="errorMsg" style={{ color: 'red', marginLeft: '200px', marginTop: '-1px' }}>There should be narration</p>
          )
          }
        </div>

        <div className="p-formgroup-inline" style={{ justifyContent: 'Left' }}>
          <div className="p-field">
            
            <Button label={saveButtonLabel} type="submit" style={{ width: "100px", marginLeft: '180px', marginTop: '10px', height: '30px' }} onClick={handleSubmit(onSave)} disabled={SaveDisable} />
            <Button label="RESET" style={{ width: "100px", marginLeft: '25px', marginTop: '10px', height: '30px' }} onClick={onReset} disabled={DisableButton} />
          </div>
        </div>

        <div className="p-formgroup-inline">
          <div className="p-field">
            <DataTable value={addVoucherEntry} className="p-datatable-sm" width="500px" >
            <Column body={editbutton} headerStyle={{ width: '5em' }}></Column>

              <Column field="dateFormat" header="Date"  ></Column>
              <Column field="glId" header="Gl Id"></Column>
              <Column field="glTitle" header="GL Title" ></Column>
              <Column field="name" header="Name"></Column>
              <Column field="amount" header="Amount"></Column>
              <Column field="scrollNo" header="Scroll No"></Column>
              <Column field="mode" header="Mode"></Column>
              <Column field="chqNo" header="Chq No"></Column>
              <Column field="bank" header="Bank"></Column>
              <Column field="narration" header="Narration"></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VoucherEntry;