import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import MemberReceipt from '../Transactions/memberReceipt';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var selectedDate;
var MRReceipt = [];
var selectedWing;
var view;
var totalAmountSum;

// class Example extends React.Component {
function MemberReceiptRegister({ props }) {

  const toast = useRef(null);
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const societyService = new SocietyService();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const [date, setDate] = useState();
  const [DateWithTime, setDateWithTime] = useState();
  selectedDate = date;
  const [memberReceipt, setMemberReceipt] = useState([]);
  MRReceipt = memberReceipt;
  const [wings, setWings] = useState([]);
  const [wing, setWing] = useState();
  const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
  const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [transactionType, setTransactionType] = useState();
  const [mode, setSelectedMode] = useState();
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [displayView, setDisplayView] = useState(false);
  view = displayView;
  const [totalSum, setTotalAmountSum] = useState();
  totalAmountSum = totalSum;
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const tableName = sessionStorage.getItem('tblName');


  selectedWing = wing;


  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

    societyService
      .getWing(societyId)
      .then((data) => {
        data.splice(0, 0, { wing: 'All' })
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),

        )
      }, []);

  }, []);

  // const onDateChange = (e) => {
  //   let Date = e.currentTarget.value
  //   setDate(Date);
  //   Date = moment(Date).format('YYYY-MM-DDThh:mm:ss');
  //   setDateWithTime(Date);

  // }
  const onDateChange = (e) => {
    let FromDate = e.currentTarget.value;
    setFromDate(FromDate);
  }

  const onToDateChange = (e) => {
    let ToDate = e.currentTarget.value;
    setToDate(ToDate);
  }

  const onTransactionTypeChange = (e) => {
    let transactionType = e.currentTarget.value
    if (transactionType === 'Member Receipt') {
      setTransactionType("MR");
    }
    else {
      setTransactionType("GR");
    }
    if (transactionType === 'Both') {
      setTransactionType(transactionType);
    }
  }

  const onChangeMode = (e) => {
    let selectedMode = e.currentTarget.value
    setSelectedMode(selectedMode);
  }

  const onChangeWing = (e) => {
    let wing = e.currentTarget.value;
    setWing(wing);
  }

  let fDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
  let tDate = moment(new Date(toDate)).format('YYYY-MM-DD');

  const viewReceipt = (e) => {
    setSpinnerVisible(true);
    let undefinedList = [];
    if (fromDate === undefined) {
      undefinedList.push({ label: "select from date" });
    }

    if (toDate === undefined) {
      undefinedList.push({ label: "select to date" });

    }
    if (wing === undefined) {
      undefinedList.push({ label: "select wing" });
    }
    if (transactionType === undefined) {
      undefinedList.push({ label: "select transaction type" });
    }
    if (mode === undefined) {
      undefinedList.push({ label: "select mode" });

    }
    let message = "Please";
    undefinedList.forEach(a => {

      message = message + "\n " + a.label + ",";
    })
    if (undefinedList.length > 0) {

      toast.current.show({ severity: 'warn', sticky: true, detail: message });
    }
    else {
      societyService.getMemberReceiptReport(societyId, fDate, tDate, wing, transactionType, mode,tableName).then(data => {
        setTotalAmountSum(data && data.reduce((a, v) => a + v.amount, 0));

        if (data.length === 0) {
          toast.current.show({ severity: 'info', detail: "No Data !" });
          return;
        }
        else {
          setDisplayView(true);
          setIsPrintDisabled(false);
          data.forEach(item => {
            if (item.bankName === '') {
              item.bankName = '---';
            }
            if(item.chqNo === 0)
            {
              item.chqNo = '---';
            }
            if(item.amount === 0)
            {
              item.amount = '---';
            }
            else{
              item.amount=item.amount?.toFixed(2);
            }


          });

          setMemberReceipt(data);
        }
      })
    }
    setSpinnerVisible(false);
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="card" >
      <div className='p-formgroup-inline'>

        <Toast ref={toast} position={"center"} />
        <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
        {/* <input type="Date" style={{ height: '35px',padding:'5px' }} value={date} onChange={onDateChange} /> */}
        <div style={{ margin: '10px' }}>
          <label className='Label100'>From:</label>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <input type="Date" name="date" className='Date' value={fromDate} onChange={onDateChange} />
        </div>

        <div style={{ margin: '10px' }}>
          <label className='Label100'>To:</label>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <input type="Date" name="date" className='Date' value={toDate} onChange={onToDateChange} />
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <select className='selection' name="wing" ref={register} onChange={onChangeWing} >
            <option hidden value="">Select Wing</option>
            {wings.map(({ label, value: wings }) => (
              <option key={wings} value={wings}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <select className="selection" name="transactionType" ref={register} onChange={onTransactionTypeChange}>
            <option hidden value="">Select Receipt</option>
            <option>Member Receipt</option>
            <option>General Receipt</option>
            <option>Both</option>

          </select>
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <select className="selection" name="Mode" ref={register} onChange={onChangeMode}>
            <option hidden value="">Select Mode</option>
            <option>Cash</option>
            <option>Bank</option>
            <option>Both</option>

          </select>
        </div>

        <div style={{ margin: '10px' }}>
          <Button label="VIEW RECEIPT" onClick={viewReceipt} style={{ marginLeft: '10px' }} />
        </div>
        <div style={{ margin: '10px' }}>
          <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT" style={{ marginLeft: '10px' }} />
        </div>
      </div>
      <Report ref={componentRef} />

    </div>
  );

}

const landscape = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: landscape;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 297mm;
      height: 210mm;

    }
  }
`;



class Report extends React.Component {
  render() {
    return (

      <div style={{ height: '100%',display: !view ? 'none' : 'block'  }}>
        <style>{landscape()}</style>
        <div className='mRRPageBreak' style={{ margin: '10px' }}>
          <div className='SocietyName'>
            <div class="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>
                {societyData[0]?.address}
              </text>
            </div>
            <div class="font">
              <label>Member Receipt Register</label>
            </div>
            <div className="dateFont">
              <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label>
            </div>
            <div class="dateFont">
              <label>Wing {selectedWing}</label>
            </div>
          </div>

          <div >
            <div class="DuesRegularTable">
              <table>
                <thead className='headerColor'>
                  <th style={{ width: '12%', textAlign: 'center' }}>Date</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Receipt No</th>
                  <th style={{ width: '5%', textAlign: 'center' }}>Flat No</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>Member Name</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>Bank Name</th>
                  <th style={{ width: '7%', textAlign: 'center' }}>Cheq.No</th>
                  {/* <th style={{ width: '10%', textAlign: 'center' }}>Bill No</th> */}
                  <th style={{ width: '10%', textAlign: 'center' }}>Amount</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>Narration</th>


                </thead>
                <tbody>
                  {
                    MRReceipt.map((item) => (
                      <tr key={item}>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{item.dateFormat}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.rctNo}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.wing}-{item.flatNo}</td>
                        <td style={{ padding: '5px', textAlign: 'left' }}>{item.name}</td>
                        <td style={{ padding: '5px', textAlign: 'left' }}>{item.bankName}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.chqNo}</td>
                        {/* <td style={{ padding: '5px', textAlign: 'right' }}>{item.billNo}</td> */}
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.amount}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{item.narration}</td>

                      </tr>
                    ))
                  }

                  <tr className='headerColor'>
                                    <th style={{ textAlign: 'center', padding: '5px' }}>Total</th>
                                    <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                    <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                    <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{totalAmountSum?.toFixed(2)}</th>
                                    <td style={{ padding: '5px', textAlign: 'right' }}></td>

                  </tr>

                </tbody>
              </table>

            </div>

          </div>
        </div>

      </div>


    )
  }

};

export default MemberReceiptRegister;



