import { Divider } from '@material-ui/core';
import React, { useState, useEffect, useStateRef, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SocietyService from "../../Service/SocietyService";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import axios from 'axios';
import Config from '../../Config.json'
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as Yup from 'yup';
// import '../Masters/UpdateTenantDetails.css';
import '../Masters/TenantDetails.css';

function UpdateTenantDetails({ id }) {


    const validationSchema = Yup.object().shape({
        prefix: Yup.string()
            .required('Prefix is required'),
        tenantName: Yup.string()
            .required('Tenant Name is required'),
    });

    const isAddMode = !id;
    const toast = useRef(null);

    const [fromDate, setFromDate] = useState([]);
    const [toDate, setToDate] = useState([]);
    var [tenantDetails, setTenantDetails] = useState([]);
    const [addArray, setAddArray] = useState([]);
    const [saveDisabled, SetSaveDisabled] = useState(true);
    const [addDisabled, setAddDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(true)
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [lastSelectedTenantId, setLastSelectedTenantId] = useState();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [isPoliceVerified, setPoliceVerification] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [ownerName, setSelectedOwnerName] = useState()
    const [flatNo, setFlatNo] = useState();
    const [flatId, setFlatId] = useState();
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const[occupiedOwnerBtnDisabled,setOccupiedOwnerBtnDisabled] = useState(true);
    var societyId = parseInt(localStorage.getItem('societyId'));



    const societyService = new SocietyService();

    const { errors, register, reset, handleSubmit, setValue } = useForm(
        { resolver: yupResolver(validationSchema) }
    );
    useEffect(() => {

        //fetchTenantDetails();

        societyService.getFlat(societyId)
            .then(data => {
                data.forEach(a => {
                    a.unitNo = `${a.wing}-${a.flatNo}`;
                })

                SetPersonalDetailsData(data)

            }
            );

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }
    }, []);

    const onAddTenant = (data, e) => {
        let existingValue = tenantDetails.findIndex(x => x.tenantName === data.tenantName);
        if (existingValue < 0) {
            data.state = "New";
            setTenantDetails(tenantDetails.concat(data));
            data.isPoliceVerified = isPoliceVerified;
            data.isPoliceVerifiedFormatted = data.isPoliceVerified?"Approved":"Pending"
            data.isActive = isActive;
            data.isActiveFormatted = data.isActive?"Current Tenant":"Old Tenant"
            onsave(data);
            setNextDisabled(false);
        }
        else {
            // alert("Tenant is already added");
            toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Tenant is already added' });
        }
        SetSaveDisabled(false);
    }

    const onResetUi = (data, e) => {
        console.warn(data);
        data.flatId = flatId;
    }

    const onEditTenant = (data, e) => {
        console.warn(data);
        data.flatId = flatId;
        data.isPoliceVerified = isPoliceVerified;
        data.isPoliceVerifiedFormatted = data.isPoliceVerified?"Approved":"Pending"
        data.isActive = isActive;
        data.isActiveFormatted = data.isActive?"Current Tenant":"Old Tenant"
        let existingIndex = tenantDetails.findIndex(x => x.tenantName === data.tenantName);
        if (existingIndex >= 0) {
            if (data.state != "New") {

                data.state = "modified";
            }
            tenantDetails[existingIndex] = data;
            tenantDetails = [...tenantDetails];
            setTenantDetails(tenantDetails);
            data.isPoliceVerified = isPoliceVerified;
            onsave(data);    // reset();
            //  setNextDisabled(false);
        }
        else {
        }
        SetSaveDisabled(false);
    }
    /* const onDeletetTenant(data, e) => {

    } */
    const onsave = (data) => {
        setAddDisabled(true);
        data.flatId = flatId;
        if (data.state === "New") {
            createUser(data);
        }
        else if (data.state === "modified") {
            updateUser(data);
        }
    }

    const createUser = (data, e) => {
        data.tenantId = "-1";
        data.flatId = flatId;
        if (data.pincode === '') {
            data.pincode = undefined;
        }
        if (data.agreementFrom === '') {
            data.agreementFrom = undefined;
        }
        if (data.agreementMonths === '') {
            data.agreementMonths = undefined;
        }
        if (data.agreementTo === '') {
            data.agreementTo = undefined;
        }
        setSpinnerVisible(true);
        axios.post(`${Config.apiUrl}/TenantDetails/Insert`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                    id = id;
                    setNextDisabled(false);
                    setAddDisabled(false);
                    setDisableButton(false);
                    fetchTenantDetails();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    }
    const updateUser = (data, e) => {
        if (data.pincode === '') {
            data.pincode = undefined;
        }
        if (data.agreementFrom === '') {
            data.agreementFrom = undefined;
        }
        if (data.agreementMonths === '') {
            data.agreementMonths = undefined;
        }
        if (data.agreementTo === '') {
            data.agreementTo = undefined;
        }
        setSpinnerVisible(true);
        axios.put(`${Config.apiUrl}/TenantDetails/update?id=${data.tenantId}`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg, life: 6000 });
                    id = id;
                    fetchTenantDetails();
                    setAddDisabled(true);
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    }

    const fetchTenantDetails = (flatId) => {

        // get user and set form fields
        societyService.getTenantDetailsOnID(flatId).then(retriveTenantDetailsIn => {
            //setNextDisabled(false);
            if (retriveTenantDetailsIn.length > 0) {
                const fields = ['prefix', 'tenantId', 'tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo',
                    'isActive', 'isPoliceVerified', 'agreementType'];
                fields.forEach(field => setValue(field, retriveTenantDetailsIn[0][field]));
                retriveTenantDetailsIn.forEach(item=>{
                    item.isActiveFormatted= item.isActive?"Current Tenant":"Old Tenant"
                })
                retriveTenantDetailsIn.forEach(item=>{
                    item.isPoliceVerifiedFormatted = item.isPoliceVerified?"Approved":"Pending"
                })
                setTenantDetails(retriveTenantDetailsIn);

                retriveTenantDetailsIn[0].rowActive = true;
                setLastSelectedTenantId(retriveTenantDetailsIn[0].tenantId)
                setPoliceVerification(retriveTenantDetailsIn[0].isPoliceVerified);
                setIsActive(retriveTenantDetailsIn[0].isActive);
                if (retriveTenantDetailsIn[0].agreementToDateFormat === '0001-01-01') {
                    setFromDate("");
                    setValue('agreementMonths', '');

                }
                else {
                    setValue('agreementTo',retriveTenantDetailsIn[0].agreementToDateFormat)
                     setFromDate(retriveTenantDetailsIn[0].agreementToDateFormat);
                }

                if (retriveTenantDetailsIn[0].agreementFromDateFormat === '0001-01-01') {
                    setToDate("");
                }
                else {
                    setValue('agreementFrom',retriveTenantDetailsIn[0].agreementFromDateFormat);
        
                    setToDate(retriveTenantDetailsIn[0].agreementFromDateFormat);
                }

                if (retriveTenantDetailsIn[0].pincode === 0) {
                    setValue('pincode', '');
                }


                // setFromDate(retriveTenantDetailsIn[0].agreementToDateFormat);
                // setToDate(retriveTenantDetailsIn[0].agreementFromDateFormat);
                setAddDisabled(true);
            } else {

                setTenantDetails([...[]]);
                onReset();
            }
            SetSaveDisabled(true);
        });
    }

    const updateFlatAsOccupiedByowner = (data, e) => {
        data.ownerOccupied = true;
        data.flatId = flatId;
        setSpinnerVisible(true);
        axios.post(`${Config.apiUrl}/TenantDetails/MarkFlatAsOwnerOccupied?flatid=${flatId}`, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg, life: 6000 });
                    id = id;
                    //  fetchTenantDetails();
                    //setAddDisabled(false);
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    }

    const onChangeFlat = (e) => {
        let selectedFlat = e.value;
        setFlatNo(selectedFlat);
        setSelectedOwnerName(selectedFlat.name);
        id = selectedFlat.id;
        setFlatId(id);
        fetchTenantDetails(id);
        setOccupiedOwnerBtnDisabled(false);

    }
    const [retriveTenantDetails, setRetriveTenantDetais] = useState({})
    const rowClass = (data) => {
        return {
            'selectedRow': data.rowActive
        }
    }
    const onDoubleClickEdit = (event) => {
        if (lastSelectedTenantId !== event.data.tenantId) {
            let selectedRow = tenantDetails.find(a => a.tenantId === lastSelectedTenantId)
            selectedRow.rowActive = false;
        }

        var data = event.data;
        data.rowActive = true;
        rowClass(data);
        setLastSelectedTenantId(event.data.tenantId);
        const fields = ['prefix', 'tenantId', 'tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo',
            'isActive', 'isPoliceVerified', 'agreementType'];
        fields.forEach(field => setValue(field, data[field]));
        setValue('agreementFrom', data.agreementFromDateFormat)
        setValue('agreementTo', data.agreementToDateFormat)
        setPoliceVerification(data.isPoliceVerified);
        setIsActive(data.isActive);
        // setToDate(data.agreementFromDateFormat);
        setAddDisabled(true);
    }
    const onChange = (e) => {
        setFromDate(e.currentTarget.value);
        setValue('agreementFrom', e.currentTarget.value);
    }
    const onToDateChange = (e) => {
        setToDate(e.currentTarget.value);
        setValue('agreementTo', e.currentTarget.value);
    }
    const onMonthChange = (e) => {
        let month = e.currentTarget.value;
        let Month = parseInt(month);
        setValue('agreementTo', addMonths(Month))
    }
    function addMonths(Month) {
        var newDate = new Date(fromDate);
        var month = newDate.getMonth();
        var toDate = newDate.setMonth(month + Month);
        var FinalToDate = new Date(toDate).toISOString().slice(0, 10);
        return FinalToDate;
    }
    const onReset = (data) => {
        const fields = ['prefix', 'tenantId', 'tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo',
            'isActive', 'isPoliceVerified', 'agreementType'];
        fields.forEach(field => setValue(field, ''));
        setAddDisabled(false);
    }

    // const onDeleteTenant = () => {
    //     if (lastSelectedTenantId === undefined || lastSelectedTenantId === null) {
    //         toast.current.show({ severity: 'warning', summary: 'Success', detail: 'No record selected for deletion!' });
    //         return;
    //     }
    //     setSpinnerVisible(true);
    //     axios.post(`${Config.apiUrl}/TenantDetails/DeleteTenant?id=${lastSelectedTenantId}`, { headers: authHeader() })
    //         .then(response => {
    //             console.log(response)
    //             if (response.data.success) {
    //                 toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
    //                 id = id;
    //                 fetchTenantDetails();
    //             }
    //             else {
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
    //             }
    //         })
    //         .catch(error => {
    //             toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    //         })
    //         .finally(() => {
    //             setSpinnerVisible(false);
    //             SetFocusValue(true);
    //         })

    // }
    const onDeleteTenant = (event) => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="p-text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <Button type="button" label="Yes" className="p-button-success" onClick={(onYes)} />
                        </div>
                        <div className="p-col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={(onNo)} />
                        </div>
                    </div>
                </div>
            )
        });
    }
    
    const onYes = (event) => {
        if (lastSelectedTenantId === undefined || lastSelectedTenantId === null) {
            toast.current.show({ severity: 'warning', summary: 'Success', detail: 'No record selected for deletion!' });
            return;
        }
        // setSpinnerVisible(true);
        axios.post(`${Config.apiUrl}/TenantDetails/DeleteTenant?id=${lastSelectedTenantId}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
                    id = id;
                    fetchTenantDetails(flatId);
                    let details=tenantDetails.filter(a=>a.tenantId !== lastSelectedTenantId);
                    setTenantDetails(details)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }
    
    const onNo = () => {
        toast.current.clear();
        return;
    };
    

    const formDateFormatTemplate = (rowData) => {
        return moment(rowData.agreementFrom).format('DD/MM/YYYY');
    };
    const toDateFormatTemplate = (rowData) => {
        return moment(rowData.agreementTo).format('DD/MM/YYYY');
        
    };

    const onNext = (e) => {
        let insertIndex = tenantDetails.findIndex(a => a.state === "New" || a.state === "modified");
        if (insertIndex >= 0) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'There are unsaved records in grid. Please save it before moving it to new tab' });
        }
        // setActiveTab(5);
    }

    const onSelectionChanged = (e) => {
        // let insertIndex = tenantDetails.findIndex(a => a.state === "New" || a.state === "modified");
      //  setLastSelectedTenantId(e.value.tenantId);

        if (lastSelectedTenantId !== e.value.tenantId) {
            let selectedRow = tenantDetails.find(a => a.tenantId === lastSelectedTenantId)
            selectedRow.rowActive = false;
        }

        var data = e.value;
        data.rowActive = true;
        rowClass(data);
        setLastSelectedTenantId(e.value.tenantId);
        // setActiveTab(5);
    }
    return (
        <div className="TenantDetails">
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div style={{ display: 'flex' }}>

                <Dropdown filter filterBy="unitNo" value={flatNo} options={personalDetailsData} onChange={onChangeFlat} id="FlatNo" name="unitNo" ref={register} optionLabel="unitNo" placeholder="Select Flat" />
                <span style={{ marginLeft: '15px' }}>{ownerName}</span>

                <Button style={{ display: 'block', marginLeft: 'auto', marginRight: '0' }} label="Mark Flat as occupied by Owner" disabled={occupiedOwnerBtnDisabled} onClick={updateFlatAsOccupiedByowner} />
            </div>
            <div className="split down">
                <div className="DownHeader">
                    <h3>Tenant Details</h3>
                </div>
                <input type8="text" name="tenantId" ref={register} hidden />

{/*                 <div className="Form-inline">
                    <label className="TenantDetailsLabel">Occupied By:</label>
                    <select className="SelectSmall" name="prefix" ref={register({ required: true })} autoFocus={focusValue} >
                        <option>Tenant</option>
                        <option>SelfOccupied</option>
                    </select>
                </div> */}
                <div className="Form-inline">
                    <label className="TenantDetailsLabel"> Tenant Name<span style={{ color: 'red' }}>*</span>:</label>
                    <select className="SelectSmall" name="prefix" ref={register({ required: true })} autoFocus={focusValue} >
                        <option>Mr</option>
                        <option>Mrs</option>
                    </select>
                    <input type4="text" style={{marginLeft:"10px"}} className="tenantlbl" name="tenantName" ref={register({ required: true })} autoComplete="off" />
                </div>
                <div className="Form-inline">
                    <label className="TenantDetailsLabel">Tenant Address:</label>
                    <input  style={{marginLeft:"10px"}} type7="text" name="address1" ref={register} autoComplete="off" />
                    <div className="TenantSecondAddress" style={{marginLeft:"169px"}}>
                    <input type7="text" name="address2" ref={register} autoComplete="off" />
                    </div>
                </div>
                <div className="Form-inline">
                    <label className="TenantDetailsLabel"> City :</label>
                    <select className="dropdown" name="city" ref={register}>
                        <option>Mumbai</option>
                        <option>Pune</option>
                        <option>Satara</option>
                    </select>

                    <label className="TenantDetailsLabel"> Pincode :</label>
                    <input type6="text"style={{width:"200px"}} name="pincode" ref={register} autoComplete="off" maxLength={6} />

                    <label className="Mobile"> Mobile :</label>
                    <input type6="text" style={{width:"200px"}} name="mobile" ref={register} autoComplete="off"  maxLength={10}/>
                </div>
                <div className="Form-inline">
                    <label className="TenantDetailsLabel">Agreement From :</label>
                    <input type="date" className="Date" name="agreementFrom" ref={register} onChange={onChange} />

                    <label className="TenantDetailsLabel"> Agreement Month's:</label>
                    <input type="text" className="Date" name="agreementMonths" ref={register} onChange={onMonthChange} autoComplete="off" />

                    <label className="TenantDetailsLabel">Agreement To :</label>
                    <input type="date" className="Date" name="agreementTo" ref={register} onChange={onToDateChange} />
                </div>
                <div className="Form-inline TenantDetails" style={{ display: 'flex' }}>
                    <label className="TenantDetailsLabel">Police Verification:</label>
                    <Checkbox checked={isPoliceVerified} name="isPoliceVerified" onChange={e => setPoliceVerification(e.checked)} />

                    <label className="TenantDetailsLabel">Agreement Type :</label>
                    <select className="dropdown" name="agreementType" ref={register}>
                        <option>Registered</option>
                        <option>Notarised</option>

                    </select>

                    <label className="TenantDetailsLabel">Active</label>
                    <Checkbox checked={isActive} name="isActive" onChange={e => setIsActive(e.checked)} />


                </div>
                <div className="invalid-feedback">
                    <div>{errors.prefix?.message}</div>
                    <div> {errors.tenantName?.message}</div>
                </div>
                <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
                    <div className="p-field">
                        <Button label="ADD TENANT" disabled={addDisabled} type="submit " onClick={handleSubmit(onAddTenant)} />
                    </div>
                    <div className="p-field">

                        <Button label="UPDATE" disabled={!addDisabled} type="submit " onClick={handleSubmit(onEditTenant)} />
                    </div>
                    {/* <div className="p-field"> */}

                    <div className="p-field">
                        <Button label="RESET" disabled={!addDisabled} onClick={onReset} />
                    </div>

                    <div className="p-field">
                        <Button label="DELETE" onClick={onDeleteTenant} />
                    </div>
                    {/* </div> */}
                    {/* <div className="p-field ">
                        <Button label="Delete" type="delete" onClick={handleSubmit(onDeletetTenant)} />
                    </div> */}
                </div>                
                </div>


            <div>
                <DataTable value={tenantDetails} className="p-datatable-gridlines p-datatable-sm" rowClassName={rowClass} selection={lastSelectedTenantId} header="Tenant Details" onRowDoubleClick={onDoubleClickEdit} onSelectionChange={onSelectionChanged}>
                    <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                    <Column field="prefix" header="Prefix" ></Column>
                    <Column field="tenantName" header="Tenat Name" ></Column>
                    <Column field="address1" header="Address 1" ></Column>
                    <Column field="address2" header="Address 2" ></Column>
                    <Column field="city" header="City" ></Column>
                    <Column field="pincode" header="Pincode" ></Column>
                    <Column field="mobile" header="Mobile" ></Column>
                    <Column field="agreementFrom"  body={formDateFormatTemplate} header="Agreement From" ></Column>
                    <Column field="agreementMonths"  header="Agreement Month" ></Column>
                    <Column field="agreementTo"  body={toDateFormatTemplate} header="Agreement To" ></Column>
                     <Column field="isPoliceVerifiedFormatted" header="IsPoliceVerified" ></Column>
                    {/* <Column field="policeVerificationFormatted" header="IsPoliceVerified" ></Column> */}
                    <Column field="agreementType" header="Agreement Type" ></Column>
                    <Column field="isActiveFormatted" header="Active" ></Column> 
                </DataTable>
                {/* <div>
                <DataTable value={tenantDetails} selectionMode="single" className="p-datatable-gridlines p-datatable-sm" rowClassName={rowClass} selection={lastSelectedTenantId} header="Tenant Details" onRowDoubleClick={onDoubleClickEdit} >
                    <Column field="prefix" header="Prefix" ></Column>
                    <Column field="wing" header="Wing"></Column>
                    <Column field="flatno" header="Flat No"></Column>
                    <Column field="tenantName" header="Tenat Name" ></Column>
                    <Column field="address1" header="Address 1" ></Column>
                    <Column field="address2" header="Address 2" ></Column>
                    <Column field="city" header="City" ></Column>
                    <Column field="pincode" header="Pincode" ></Column>
                    <Column field="mobile" header="Mobile" ></Column>
                    <Column field="agreementFromDateFormat" header="Agreement From" ></Column>
                    <Column field="agreementMonths" header="Agreement Month" ></Column>
                    <Column field="agreementToDateFormat" header="Agreement To" ></Column>
                </DataTable>
            </div> */}
            </div>
        </div>
    )
}
export default UpdateTenantDetails;