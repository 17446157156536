import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import './GL_MasterAuditView.css';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { SocietyContext } from "../SocietyContext";
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../components/Misc/progressSpinBarControl';

function TransactionAuditView() {

    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();

    const [globalFilter, setGlobalFilter] = useState('');
    const [transactionAuditInfo, setTransactionAuditInfo] = useState();
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        // let d = moment(moment(new Date())).format(' 00:01');
        // setValue('fromDate', d);
        // setFromDate(d);
        // setFromDate(d);
        // console.warn(d);

        // let toD = moment(moment(new Date())).format('DD-MM-YYYY 23:59');
        // setValue('toDate', toD);
        // setToDate(toD);
        // console.warn(toD);        
    }, []);

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {
        setSpinnerVisible(true);
        societyService.getTransactionAuditInfo(fromDate, toDate, societyId).then(data => {
            if(data.length === 0){
                toast.current.show({ severity: 'warn', sticky: true, detail: 'No Data Available On This Date'});
                return;
            }   
            if (data[0].alertMsg !== null) {
                toast.current.show({ severity: 'warn', sticky: true, detail: data[0].alertMsg });

            }
            else {
                setTransactionAuditInfo(data)
            }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', detail: error, sticky: true });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })


        console.warn(transactionAuditInfo);
    }


    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>Transaction Audit </b> */}

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }} value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className="label" >From:</label>
            <input type="date" name="fromDate" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className="label" >To:</label>
            <input type="date" name="toDate" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />

        </div>
    );

    return (
        <div className="GL_MasterAuditView">
            <span style={{fontFamily:'verdena', fontWeight:'bold'}}>Transaction Audit </span> 
            <div>
            <Toast ref={toast} position={'center'} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <DataTable className="p-datatable-sm" responsiveLayout="scroll" scrollable scrollHeight='400px' style={{ overflow: 'hidden' }} header={header}
                globalFilter={globalFilter} value={transactionAuditInfo} emptyMessage="No result found.">
                <Column field="transactionDateFormat" header="Transaction Date" style={{ width: '100px' }} filter></Column>
                <Column field="dueDateFormat" header="Due Date" style={{ width: '120px' }} filter></Column>
                <Column field="chequeDate" header="Cheque Date" style={{ width: '120px' }} filter></Column>
                <Column field="chequeClrDate" header="Cheque Clr Date" style={{ width: '120px' }} filter></Column>
                <Column field="glId" header="Gl Id" style={{ width: '115px' }} filter></Column>
                <Column field="glTitle" header="Gl Title" style={{ width: '120px' }} filter></Column>
                <Column field="wing" header="Wing" style={{ width: '100px' }} filter></Column>
                <Column field="flatNo" header="Flat No" style={{ width: '80px' }} filter></Column>
                <Column field="name" header="Name" style={{ width: '200px' }} filter></Column>
                <Column field="refId" header="Ref Id" style={{ width: '80px' }} filter></Column>
                <Column field="rctNo" header="Rct No" style={{ width: '80px' }} filter></Column>
                <Column field="billNo" header="Bill No" style={{ width: '80px' }} filter></Column>
                <Column field="refNo" header="Ref No" style={{ width: '80px' }} filter></Column>
                <Column field="scrollNo" header="Scroll No" style={{ width: '80px' }} filter></Column>
                <Column field="voucherNo" header="Voucher No" style={{ width: '80px' }} filter></Column>
                <Column field="debitPrinciple" header="Debit Principle" style={{ width: '80px' }} filter></Column>
                <Column field="creditPrinciple" header="Credit Principle" style={{ width: '80px' }} filter></Column>
                <Column field="debitInterest" header="Debit Interest" style={{ width: '80px' }} filter></Column>
                <Column field="creditInterest" header="Credit Interest" style={{ width: '80px' }} filter></Column>
                <Column field="debitDpc" header="Debit Dpc" style={{ width: '80px' }} filter></Column>
                <Column field="creditDpc" header="Credit Dpc" style={{ width: '80px' }} filter></Column>
                <Column field="mode" header="Mode" style={{ width: '80px' }} filter></Column>
                <Column field="bankName" header="Bank Name" style={{ width: '80px' }} filter></Column>
                <Column field="chequeNo" header="Cheque No" style={{ width: '80px' }} filter></Column>
                <Column field="jeNo" header="Je No" style={{ width: '80px' }} filter></Column>
                <Column field="narration" header="Narration" style={{ width: '150px' }} filter></Column>
                <Column field="transactionType" header="Transaction Type" style={{ width: '100px' }} filter></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '100px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>
            </DataTable>
            </div>
        </div>
    );
}
export default TransactionAuditView;