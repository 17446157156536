import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import './Sub_GroupMasterAuditView.css';
import { Button } from 'primereact/button';

function Sub_GroupMasterAuditView() {

    const [globalFilter, setGlobalFilter] = useState('');
    const [SubGroupMasterAuditData, setSubGroupMasterAuditInfo] = useState();
    const societyService = new SocietyService();
    const [fromDate, setFromDate] = useState( localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState( moment(new Date()).format('YYYY-MM-DD'));
    var societyId=parseInt(localStorage.getItem('societyId'));

    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {
        societyService.getSubGroupMasterAuditInfo(fromDate, toDate,societyId).then(data => setSubGroupMasterAuditInfo(data));
        console.warn(SubGroupMasterAuditData);
    }


    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>Sub-Group Master Audit View</b> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }} value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className='label' >From:</label>
            <input type="date" name="date" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className='label' >To:</label>
            <input type="date" name="date" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />

        </div>
    );

    return (

        <div className="Sub_GroupMasterAuditView">
            <span style={{fontFamily:'verdena', fontWeight:'bold'}}>Sub-Group Master Audit View</span> 
            <div>
            <DataTable className="p-datatable-gridlines" responsiveLayout="scroll" scrollable scrollHeight='400px' style={{ overflow: 'hidden' }} header={header}
                globalFilter={globalFilter} value={SubGroupMasterAuditData} emptyMessage="No result found.">
                <Column field="subGroupId" header="Sub Group ID" style={{ width: '70px' }} filter></Column>
                <Column field="groupName" header="Group Name" style={{ width: '120px' }} filter></Column>
                <Column field="subGroupName" header="Sub-Group Name" style={{ width: '120px' }} filter></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '80px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>

            </DataTable>
                            
            </div>
        </div>

    );
}
export default Sub_GroupMasterAuditView;