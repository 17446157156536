import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import './GLReport.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../loader.css';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];

var GLReportDatas = [];
var fDateOnReport;
var tDateOnReport;
var DetailData = [];
var DailyData = [];
var MonthlyData = [];
var QuartelyData = [];
var creditTotal;
var debitTotal;
var openBal;
var GlName;

// class Example extends React.Component {
function GLReport({ props }) {


    const toast = useRef(null);
    const location = useLocation();
    const { errors, register, reset, handleSubmit, setValue } = useForm();

    const societyService = new SocietyService();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [GlTitle, setGlTitle] = useState();
    GlName = GlTitle;
    const [GlId, setGlId] = useState();
    const [GLReportData, setGLReportData] = useState([]);
    GLReportDatas = GLReportData;
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const tableName = sessionStorage.getItem('tblName');
    const [glGridData, setGridData] = useState();
    const [isDetailReport, setIsDetailReport] = useState(true);
    const [isDailyReport, setIsDailyReport] = useState(true);
    const [isMonthlyReport, setISMonthlyReport] = useState(true);
    const [isQuarterlyReport, setIsQuarterlyReport] = useState(true);
    const [openingBalance, setOpeningBalance] = useState();
    openBal = openingBalance;
    const [detailReportData, setDetailData] = useState([]);
    DetailData = detailReportData;
    const [dailyReportData, setDailyData] = useState([]);
    DailyData = dailyReportData;
    const [monthlyReportData, setMonthlyData] = useState([]);
    MonthlyData = monthlyReportData;
    const [quartelyReportData, setQuartelyData] = useState([]);
    QuartelyData = quartelyReportData;
    const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [Generateloading, setGenerateloading] = useState(false);
    const [onView, setOnView] = useState(false);

    fDateOnReport = moment(new Date(fromDate)).format('DD-MM-YYYY');
    tDateOnReport = moment(new Date(toDate)).format('DD-MM-YYYY');

    useEffect(() => {
        setGenerateloading(false);
        setOnView(false);
        societyService.getSocietyDetails(societyId).then(data => {
            SetSocietyDetailsData(data);
        });

    }, []);

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
        setIsGenerateDisabled(false);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
        setIsGenerateDisabled(false);
    }

    const InputGlId = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('glId', onlyNums);
    }

    const onChangeGlId = (e) => {
        setIsGenerateDisabled(false);
        let glId = e.currentTarget.value;
        setGlId(glId);
        societyService
            .getGlTitleOnglId(glId, societyId)
            .then((data) => {

                if (data != '') {
                    setGlTitle(data);
                    console.warn(data);

                }
                else {
                    setGlTitle("");
                    toast.current.show({ severity: 'error', detail: 'GL ID is Wrong' });
                }
            }
            );

    }
   async function GetOpeningBalance(societyId, fDate, GlId) {
        let data = await societyService.getGlOpeningBalance(societyId, fDate, GlId,tableName);
        setOpeningBalance(data);
    }
    
    let fDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
    let tDate = moment(new Date(toDate)).format('YYYY-MM-DD');

    const  onGenerate = async() => {
       
        let undefinedList = [];
        if (GlId === undefined) {
            undefinedList.push({ label: "select Gl-Id" });

        }
        if (fromDate === undefined) {
            undefinedList.push({ label: "select From date" });

        }
        if (toDate === undefined || '') {
            undefinedList.push({ label: "select To date" });

        }
        let message = "Please";
        undefinedList.forEach(a => {

            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            setSpinnerVisible(false);
            toast.current.show({ severity: 'warn', sticky: true, detail: message });

        }
        else {
            setSpinnerVisible(true);
            setGenerateloading(true);
            setIsGenerateDisabled(true);
            setIsDetailReport(false);
            setIsDailyReport(false);
            setISMonthlyReport(false);
            setIsQuarterlyReport(false);

         let items=  await GetOpeningBalance(societyId, fDate,GlId);
            societyService.getGlGridData(societyId, fDate, tDate, GlId,tableName).then(data => {
                data.forEach(d => {

                    d.balance = openBal + d.credit_principle - d.debit_principle;
                    openBal = d.balance;
                })
               // data=   data.sort((item, item2) => item.date - item2.date);
                setGridData(data)
            })

            //detail data
            societyService.GetDetailData(societyId, fDate, tDate, GlId,tableName).then(data => {
                var srNo = 1;
                var bal = 0;


                data.forEach(d => {
                    d.srNo = srNo++;
                    d.balance = bal + d.debit_principle - d.credit_principle;
                    bal = d.balance;

                })
                creditTotal = data && data.reduce((a, v) => a + v.credit_principle, 0)
                debitTotal = data && data.reduce((a, v) => a + v.debit_principle, 0)
               // data=   data.sort((item, item2) => item.date - item2.date);
                setDetailData(data)

            })

            //daily data

            societyService.GetDailyData(societyId, fDate, tDate, GlId,tableName).then(data => {
                var srNo = 1;
                var bal = 0;
                data.forEach(d => {
                    d.srNo = srNo++;
                    d.balance = bal + d.debit_principle - d.credit_principle;
                    bal = d.balance;
                })
                creditTotal = data && data.reduce((a, v) => a + v.credit_principle, 0)
                debitTotal = data && data.reduce((a, v) => a + v.debit_principle, 0)
              //  data=   data.sort((item, item2) => item.date - item2.date);
                setDailyData(data)

            })


            //monthly data
            societyService.GetMonthlyData(societyId, fDate, tDate, GlId,tableName).then(data => {
                var srNo = 1;
                var bal = 0;
                data.forEach(d => {
                    d.srNo = srNo++;
                    d.balance = bal + d.debit_principle - d.credit_principle;
                    bal = d.balance;
                })
                creditTotal = data && data.reduce((a, v) => a + v.credit_principle, 0)
                debitTotal = data && data.reduce((a, v) => a + v.debit_principle, 0)
              //  data=   data.sort((item, item2) => item.date - item2.date);
                setMonthlyData(data)

            })

            //quarterly data
            societyService.GetQuartelyData(societyId, fDate, tDate, GlId,tableName).then(data => {
                var srNo = 1;
                var bal = 0;
                data.forEach(d => {
                    d.srNo = srNo++;
                    d.balance = bal + d.debit_principle - d.credit_principle;
                    bal = d.balance;
                })
                creditTotal = data && data.reduce((a, v) => a + v.credit_principle, 0)
                debitTotal = data && data.reduce((a, v) => a + v.debit_principle, 0)
                data=   data.sort((item, item2) => item.date - item2.date);
                setQuartelyData(data)

            })
            setGenerateloading(false);
            setOnView(true);
            setSpinnerVisible(false);
        }
    }

    const onDetail = () => {
        handlePrint();
    }

    const onDaily = () => {
        handleDailyPrint();
    }

    const onMonthly = () => {
        handleMonthlyPrint();
    }

    const onQuarterly = () => {
        handleQuarterlyPrint();
    }

    const componentRef = useRef();
    const DailyRef = useRef();
    const MonthlyRef = useRef();
    const QuarterlyRef = useRef();

    const handlePrint = useReactToPrint({

        content: () => componentRef.current,

    });

    const handleDailyPrint = useReactToPrint({

        content: () => DailyRef.current,

    });
    const handleMonthlyPrint = useReactToPrint({

        content: () => MonthlyRef.current,

    });
    const handleQuarterlyPrint = useReactToPrint({

        content: () => QuarterlyRef.current,

    });

    return (
        <div className="GLReport">
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />

            <div class="GLReportCard" >
                <div class="ButtonCard" >
                    <div className='p-formgroup-inline'>
                        <div style={{ margin: '5px' }}>
                            <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                            <input type="text" name="glId" ref={register({ required: true })} className="number" onChange={onChangeGlId} onInput={InputGlId} placeholder="Enter Gl-Id" />
                        </div>

                        <div style={{ margin: '5px' }}>

                            <input type="text" className="title" name="glTitle" ref={register({ required: true })} value={GlTitle} />
                        </div>
                        <div style={{ margin: '5px' }}>

                            <label>From:</label>
                            <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                            <input type="date" name="date" className='Date' value={fromDate} onChange={onDateChange} />
                        </div>

                        <div style={{ margin: '5px' }}>
                            <label className="label">To:</label>
                            <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                            <input type="date" name="date" className='Date' value={toDate} onChange={onToDateChange} />
                        </div>
                        <div style={{ margin: '4px' }}>
                        <Button label="GENERATE" icon="pi pi-arrow-circle-down" disabled={isGenerateDisabled} onClick={onGenerate} style={{ marginLeft: '10px', marginTop: '10px', backgroundColor: 'green' }} />
                        </div>
                    </div>
                    <div style={{ margin: '5px' }}>
                        <Button label="DETAIL" disabled={isDetailReport} icon="pi pi-print" style={{ marginLeft: '10px' }} onClick={onDetail} />
                        <Button label="DAILY" disabled={isDailyReport} icon="pi pi-print" onClick={onDaily} style={{ marginLeft: '10px' }} />
                        <Button label="MONTHLY" disabled={isMonthlyReport} icon="pi pi-print" onClick={onMonthly} style={{ marginLeft: '10px' }} />
                        <Button label="QUARTERLY" disabled={isQuarterlyReport} icon="pi pi-print" onClick={onQuarterly} style={{ marginLeft: '10px' }} />
                    </div>
                    <div>
                        <div style={{ color: 'red', padding: '10px' }}>{errors.fromDate?.message}  {errors.toDate?.message}</div>

                    </div>
                </div>
                {
                    Generateloading ? (
                        <div className="spinner">
                            <span>Loading...</span>
                            <div className="half-spinner"></div>
                        </div>
                    )
                        :
                        (
                            <>
                                {onView ? (
                                    <>
                                        <div style={{margin:'5px' }}>
                                            <label ><b>Opening Balance:</b></label>
                                            <input type="text" style={{ height: '25px', width: '150px' }} value={openingBalance} />
                                        </div>
                                        <div className="GLReportGrid">
                                            <DataTable className="p-datatable-gridlines" responsiveLayout="Scroll" sortField="date" scrollable scrollHeight='50vh' 
                                                value={glGridData}  emptyMessage="No result found.">
                                                <Column field="dateFormat" header="Date" sortable="true" style={{width:'100px'}} ></Column>
                                                <Column field="name" header="Name" sortable="true" style={{width:'100px'}}></Column>
                                                <Column field="debit_principle" header="Debit" sortable="true" style={{width:'100px'}}></Column>
                                                <Column field="credit_principle" header="Credit" sortable="true" style={{width:'100px'}}></Column>
                                                <Column field="balance" header="Closing Bal" sortable="true" style={{width:'100px'}}></Column>

                                            </DataTable>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </>
                        )}
                <div hidden >
                    <Report ref={componentRef} />
                    <DailyReport ref={DailyRef} />
                    <MonthlyReport ref={MonthlyRef} />
                    <Quarterly ref={QuarterlyRef} />
                </div>
            </div>
        </div>
    );
}

const landscape = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: landscape;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 297mm;
      height: 210mm;

    }
  }
`;


const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 297mm;

    }
  }
`;

class Report extends React.Component {
    render() {
        return (
            <div>
                <style>{landscape()}</style>
                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        <div className="dateFont">
                            <label>{GlName} GL Detail Report From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>

                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Sr</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Date</th>
                                        <th style={{ width: '400px', textAlign: 'center' }}>Name</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Cheque No</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Debit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Credit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Balance</th>
                                    </tr>
                                </thead>
                                {DetailData.map((Bdata, index) =>
                                    <tbody style={{}}>
                                        {
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{DetailData[index]?.srNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DetailData[index]?.dateFormat}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{DetailData[index]?.name}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DetailData[index]?.chequeNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DetailData[index]?.debit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DetailData[index]?.credit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DetailData[index]?.balance}</td>
                                            </tr>
                                        }
                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                    <th>Total</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{debitTotal}</th>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{creditTotal}</th>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

class DailyReport extends React.Component {
    render() {
        return (

            <div>
                <style>{portrait()}</style>

                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        <div className="dateFont">
                            <label>{GlName} GL Daily Report From : {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>

                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Sr</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Date</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Cheque No</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Debit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Credit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Balance</th>
                                    </tr>
                                </thead>
                                {DailyData.map((Bdata, index) =>
                                    <tbody style={{}}>
                                        {
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{DailyData[index]?.srNo}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{DailyData[index]?.dateFormat}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DailyData[index]?.chequeNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DailyData[index]?.debit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DailyData[index]?.credit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{DailyData[index]?.balance}</td>
                                            </tr>
                                        }
                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                    <th>Total</th>
                                    <td></td>
                                    <td></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{debitTotal}</th>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{creditTotal}</th>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

class MonthlyReport extends React.Component {
    render() {
        return (
            <div>
                <style>{portrait()}</style>
                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        <div className="dateFont">
                            <label>{GlName} GL Monthly Report From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>
                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Sr</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Month</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Cheque No</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Debit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Credit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Balance</th>
                                    </tr>
                                </thead>
                                {MonthlyData.map((Bdata, index) =>
                                    <tbody style={{}}>
                                        {
                                            <tr >
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{MonthlyData[index]?.srNo}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{MonthlyData[index]?.monthYear}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{MonthlyData[index]?.chequeNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{MonthlyData[index]?.debit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{MonthlyData[index]?.credit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{MonthlyData[index]?.balance}</td>

                                            </tr>
                                        }
                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                    <th>Total</th>
                                    <td></td>
                                    <td></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{debitTotal}</th>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{creditTotal}</th>
                                    <td></td>
                                </tr>
                            </table>

                        </div>

                    </div>

                </div>

            </div>



        )
    }

};

class Quarterly extends React.Component {
    render() {
        return (
            <div>
                <style>{portrait()}</style>
                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>

                        <div className="dateFont">
                            <label>{GlName} GL Quarterly Report From: {fDateOnReport} To: {tDateOnReport}</label>
                        </div>
                    </div>


                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '70px', textAlign: 'center' }}>Sr</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Month</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Cheque No</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Debit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Credit</th>
                                        <th style={{ width: '150px', textAlign: 'center' }}>Balance</th>
                                    </tr>

                                </thead>
                                {QuartelyData.map((Bdata, index) =>
                                    <tbody style={{}}>
                                        {
                                            <tr >
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{QuartelyData[index]?.srNo}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{QuartelyData[index]?.monthYear}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{QuartelyData[index]?.chequeNo}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{QuartelyData[index]?.debit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{QuartelyData[index]?.credit_principle}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{QuartelyData[index]?.balance}</td>
                                            </tr>
                                        }
                                    </tbody>
                                )}
                                <tr className='headerColor'>
                                    <th>Total</th>
                                    <td></td>
                                    <td></td>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{debitTotal}</th>
                                    <th style={{ textAlign: 'right', padding: '5px' }}>{creditTotal}</th>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default GLReport;





