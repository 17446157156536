import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import TenantView from './TenantView';
import UpdateTenantDetails from './Masters/UpdateTenantDetails';


function TenantInfo() {
    return (
        <TabView >

            <TabPanel header="TenantDetails">
                
                <UpdateTenantDetails />

                </TabPanel>

            <TabPanel header="Tenant View" >

                <TenantView />

            </TabPanel>
        
        </TabView>
    )
}

export default TenantInfo