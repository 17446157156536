/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import PersonalDatails from '../Masters/PersonalDetails';
import { BrowserRouter, useParams } from 'react-router-dom';
import PropertyDetails from '../Masters/PropertyDetails' ;
import OpeningBalance from '../Masters/OpeningBalance';
import NomineeDetils from '../Masters/NomineeDetails';
import MaintenanceDetails from '../Masters/MaintenanceDetails';
import TenantDetails from '../Masters/TenantDetails';
import VehicleDetails from '../Masters/VehicleDetails';
import React, { useState, useEffect, useContext } from 'react';
import SocietyService from '../../Service/SocietyService';
import Nav from '../../Nav';
import { TabView, TabPanel } from 'primereact/tabview';


function Flat_Master(props) {

  const [activeIndex, setActiveIndex] = useState(0);
  // const id=props.match.params.id; 
  // console.warn("property Details",flatId);
  const [selectedTab, setSelectedTab] = React.useState(0);
  
  const [usedBy, setUsedBy] = useState();
  var societyId = parseInt(localStorage.getItem('societyId'));

  const [personalDetailsData, SetPersonalDetailsData] = useState([]);

  const societyService = new SocietyService();

  const { id } = useParams();
  let mouseAction;
  if(!id)
  {
    mouseAction='disabled';
  }
  // const {societyId} = useContext(SocietyContext);
  const [flatId, setFlatId] = React.useState(id);
    useEffect(() => {
    if(id!=undefined){
      setFlatId(id);
    }
   },[]);

  const handleChange = (event, newValue) => {
    console.warn(newValue);
    setSelectedTab(newValue);
  };
  

  return (
    
    <div >
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      
        <TabPanel header="Personal Details">

          <PersonalDatails setFlatId={setFlatId}  id={flatId} societyId={societyId} setActiveTab={setActiveIndex}  />
          
        </TabPanel>
        <TabPanel header="Property Details" disabled={!flatId} >
          <PropertyDetails setUsedBy={setUsedBy}   id={flatId} setActiveTab={setActiveIndex} />
           </TabPanel>
        <TabPanel header="Opening Balance" disabled={!flatId}>
          <OpeningBalance  id={flatId} setActiveTab={setActiveIndex} societyId={societyId}/>
        </TabPanel>
        <TabPanel header="Nominee Details" disabled={!flatId} >
        <NomineeDetils   id={flatId} usedBy={usedBy} setActiveTab={setActiveIndex} />
      </TabPanel>
      <TabPanel header="Tenant Details" disabled={usedBy === 'Owner' || !flatId}>
        <TenantDetails id={flatId} usedBy={usedBy} setActiveTab={setActiveIndex} />
      </TabPanel>
     
      <TabPanel header="Vehicle Details" disabled={!flatId}>
        <VehicleDetails  id={flatId}  setActiveTab={setActiveIndex} societyId={societyId}/>
      </TabPanel>
      <TabPanel header="Maintenance Details" disabled={!flatId}>
        <MaintenanceDetails   id={flatId}  setActiveTab={setActiveIndex}/>
      </TabPanel>
      
      </TabView>
      {/*   <header className="FlatMasters-header">
        <div >
      <AppBar position="static">
        <Tabs value={selectedTab}  onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Personal Details" />        
          <Tab label="Property Details" />
          <Tab label="Opening Balance" />
          <Tab label="Nominee Details" />
          <Tab label="Tenant Details" />
          <Tab label="Maintenance Details" />
          


        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <PersonalDatails setFlatId={setFlatId} id={id} societyId={societyId}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <PropertyDetails setUsedBy={setUsedBy} flatId={flatId} id={id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <OpeningBalance flatId={flatId} id={id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <NomineeDetils flatId={flatId} id={id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <TenantDetails flatId={flatId} id={id} usedBy={usedBy} />
      </TabPanel>
      <TabPanel value={selectedTab} index={5}>
        <MaintenanceDetails flatId={flatId} id={id} />
      </TabPanel>
      
      

      
    </div>
      </header> */}
      
    </div>

  );
}
/* 
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {
        value === index && (<div>{children}</div>)
      }
    </div>
  )
} */

// PropertyDetails=({flatId})=>{
//   return <h1>id=flatId</h1>
// }

// PersonalDatails=({setFlatId})=>{

// }
export default Flat_Master;

