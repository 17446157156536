import { Button } from 'primereact/button';
import './Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import SocietyService from '../../Service/SocietyService';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import './PaymentReport.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../loader.css';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var paymentRegisterDatas = [];
var fDateOnReport;
var tDateOnReport;
var Total;
// class Example extends React.Component {
function PaymentRegister({ props }) {

    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    const societyService = new SocietyService();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [paymentRegisterData, setPaymentRegisterData] = useState([]);
    const [amount, setAmount] = useState();
    const [printButton, setPrintButton] = useState(true);
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [selectedMode, setSelectedMode] = useState();
    const [Operator, setOperator] = useState();
    const [disabledAmount, setDisabledAmount] = useState(false);
    const [total, setTotal] = useState();
    const [Generateloading, setGenerateloading] = useState(undefined);
    const [onView, setOnView] = useState(undefined);
    const [isPrintDisabled, setIsPrintDisabled] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');

    paymentRegisterDatas = paymentRegisterData;
    fDateOnReport = moment(new Date(fromDate)).format('DD-MM-YYYY');
    tDateOnReport = moment(new Date(toDate)).format('DD-MM-YYYY');
    Total = total;

    useEffect(() => {
        setGenerateloading(false);
        setOnView(false);
        societyService.getSocietyDetails(societyId).then(data => {
            SetSocietyDetailsData(data);
        });
    }, []);

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onChangeMode = (e) => {
        let selectedMode = e.currentTarget.value;
        console.warn(selectedMode);
        setSelectedMode(selectedMode);
        setValue('mode', selectedMode);
        // let selectedGlId = societyBank.find(a => a.glTitle === selectedBank);
        // setSelectedBankGlId(selectedGlId.glId)
    }

    let fDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
    let tDate = moment(new Date(toDate)).format('YYYY-MM-DD');

    const viewBills = (e) => {
        setSpinnerVisible(true);
        let undefinedList = [];
        if (fromDate === undefined) {
            undefinedList.push({ label: "select From date " });

        }
        if (toDate === undefined) {
            undefinedList.push({ label: "select To date " });

        }
        if (selectedMode === undefined) {
            undefinedList.push({ label: "select Mode " });

        }
        let message = "Please";
        undefinedList.forEach(a => {

            message = message + "\n " + a.label + ",";
        })
        if (undefinedList.length > 0) {

            toast.current.show({ severity: 'warn', sticky: true, detail: message });
        }
        else {

            setGenerateloading(true);
            setIsPrintDisabled(false);
            setTimeout(() => {
                societyService.getPaymentRegisterData(societyId, fDate, tDate, selectedMode,tableName).then(data => {
                    const filteredData = data.filter(row => row.debitPrinciple !== 0);
                    setPaymentRegisterData(filteredData);
                    
                    if (filteredData.length === 0) {
                        toast.current.show({ severity: 'info', sticky: true, detail: "No Data" });
                        return;

                    }
                    else {
                        setPrintButton(false);
                        setOnView(true);


                        if (Operator === 'All') {
                            calculateTotal(filteredData);
                            setPaymentRegisterData(filteredData); }
                        else {
                            if (Operator === '>=') {
                                let d = data.filter(a => a.debitPrinciple >= amount);
                                // setSerialNo(totalDues);
                                calculateTotal(d);
                                setPaymentRegisterData(d);

                            }
                            if (Operator === '>') {
                                let d = data.filter(a => a.debitPrinciple > amount);
                                // setSerialNo(totalDues);
                                calculateTotal(d);
                                setPaymentRegisterData(d);
                            }
                            if (Operator === '<=') {
                                let d = data.filter(a => a.debitPrinciple <= amount);
                                calculateTotal(d);
                                setPaymentRegisterData(d);
                            }
                            if (Operator === '<') {
                                let d = data.filter(a => a.debitPrinciple < amount);
                                calculateTotal(d);
                                setPaymentRegisterData(d);
                            }
                            if (Operator === '=') {
                                let d = data.filter(a => a.debitPrinciple === amount);
                                calculateTotal(d);
                                setPaymentRegisterData(d);
                            }
                        }
                    }

                });



                setGenerateloading(false);
            },0);
        }
        setSpinnerVisible(false);
    }

    const calculateTotal = (data) => {
        let totalAmount = 0;
        data.forEach(d => {
            totalAmount = totalAmount + d.debitPrinciple;


        })
        setTotal(totalAmount);
        console.debug(total);
    }

    const onOperatorChange = (e) => {
        let operator = e.currentTarget.value
        setOperator(operator);
        if (operator === 'All') {

            setDisabledAmount(true);
        }
        else {
            setDisabledAmount(false);

        }
    }

    const onAmountChange = (e) => {
        let Amount = e.currentTarget.value
        Amount = parseInt(Amount);
        setAmount(Amount);
    }






    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className="PaymentReport">
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <div class="card" >
                <div className='p-formgroup-inline'>
                    <div style={{ margin: '10px' }}>
                        <label className='Label100'>From:</label>
                        <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                        <input type="date" name="date" className='Date' value={fromDate} onChange={onDateChange} />
                    </div>

                    <div style={{ margin: '10px' }}>
                        <label className='Label100'>To:</label>
                        <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                        <input type="date" name="date" className='Date' value={toDate} onChange={onToDateChange} />
                    </div>

                    <div style={{ margin: '10px' }}>
                        <span style={{ color: 'red', fontSize: '26px' }}>*</span>
                        <select name="mode" className="Mode" ref={register} onChange={onChangeMode}  >
                            <option hidden value="">Select Mode</option>
                            <option>Bank</option>
                            <option>Cash</option>
                            <option>Both</option>
                        </select>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <select className='Operator' onChange={onOperatorChange} value={Operator}>
                            <option hidden value="">Select Operator</option>
                            <option>All</option>
                            <option>&gt;</option>
                            <option>&gt;=</option>
                            <option>&lt;</option>
                            <option>&lt;=</option>
                            <option>=</option>

                        </select>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <input type="text" className='text' disabled={disabledAmount} value={amount} onChange={onAmountChange} placeholder="Enter Amount" />
                    </div>

                    <div style={{ margin: '10px' }}>
                        <label style={{ margin: '100px', fontSize: "30px" }}><b>Payment Register</b></label>
                    </div>

                </div>
                <div className='p-formgroup-inline'>
                    <div style={{ margin: '10px' }}>

                        <Button label="VIEW" onClick={viewBills} />
                    </div>
                    <div style={{ margin: '10px' }}>

                        <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT"  />
                    </div>
                </div>
            </div>





            {Generateloading ? (
                <div className="spinner">
                    <span>Loading...</span>
                    <div className="half-spinner"></div>
                </div>
            ) : (
                <>
                    {onView ? (
                        <>


                            <div className="BankReportData">

                                <DataTable className="p-datatable-gridlines" responsiveLayout="scroll" scrollable scrollHeight='50vh' style={{ overflow: 'hidden' }}
                                    value={paymentRegisterData}  emptyMessage="No result found.">
                                    {/* <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column> */}
                                    <Column field="dateFormat" header="Date" style={{ width: '100px' }} ></Column>
                                    <Column field="scrollNo" header="Vch No" style={{ width: '50px' }} ></Column>
                                    <Column field="glTitle" header="GL Title" style={{ width: '200px' }} ></Column>
                                    <Column field="name" header="Vendor Name" style={{ width: '200px' }} ></Column>
                                    <Column field="mode" header="Mode" style={{ width: '200px' }}  ></Column>
                                    <Column field="chqNo" header="Cheque No" style={{ width: '200px' }}  ></Column>
                                    <Column field="bankName" header="Bank Name" style={{ width: '200px' }}  ></Column>
                                    <Column
    field="debitPrinciple"
    header="Amount"
    style={{ width: '120px' }}
    body={(rowData) => rowData.debitPrinciple.toFixed(2)}
    footer={() => <span>{Total?.toFixed(2)}</span>}
  ></Column>
                                    <Column field="remarks" header="Narration" style={{ width: '200px' }} ></Column>

                                </DataTable>
                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </>
            )}
            <div hidden >
                {/* {isPrintTrue ? ( */}
                <Report ref={componentRef} />

                {/* ) : (
                        <>

                        </>

                    )} */}
            </div>

        </div>
    );

}
const landscape = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: landscape;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 297mm;
      height: 210mm;

    }
  }
`;


class Report extends React.Component {
    render() {



        return (

            <div style={{ height: '100%' }}>
                {/* <style className='pageStyle'></style> */}
                <style>{landscape()}</style>

                <div>
                    <div className='SocietyName'>
                        <div class="font"><text>{societyData[0]?.societyName}</text></div>
                        <div>
                            <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                            <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                        </div>
                        <div>
                            <text>
                                {societyData[0]?.address}
                            </text>
                        </div>
                        <div class="font">
                            <label>Payment Register Report</label>
                        </div>
                        {/* <div class="font">
                            <label>Cash Book For : <text>{BankReportDatas[1]?.bankName}</text></label>
                        </div> */}
                        <div className="dateFont">
                            {/* <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label> */}
                            <label>From:{fDateOnReport} To:{tDateOnReport}</label>
                        </div>
                  


                    <div>
                        <div class="DuesRegularTable">
                            <table>
                                <thead style={{ border: "2px solid" }}>
                                    <tr className='headerColor'>
                                        <th style={{ width: '60px', textAlign: 'center' }}> Date </th>
                                        <th style={{ width: '60px', textAlign: 'center' }}>Vch No</th>
                                        <th style={{ width: '180px', textAlign: 'center' }}> Gl-Title</th>
                                        <th style={{ width: '180px', textAlign: 'center' }}>Vendor Name</th>
                                        <th style={{ width: '60px', textAlign: 'center' }}>Mode</th>
                                        <th style={{ width: '60px', textAlign: 'center' }}>chq.No</th>
                                        <th style={{ width: '200px', textAlign: 'center' }}>Bank Name</th>
                                        <th style={{ width: '80px', textAlign: 'center' }}>Amount</th>
                                        <th style={{ width: '300px', textAlign: 'center' }}>Narration</th>
                                    </tr>



                                </thead>
                                {paymentRegisterDatas.map((Bdata, index) =>

                                    <tbody style={{}}>

                                        {


                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{paymentRegisterDatas[index]?.dateFormat}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{paymentRegisterDatas[index]?.scrollNo}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{paymentRegisterDatas[index]?.glTitle}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{paymentRegisterDatas[index]?.name}</td>
                                                <td style={{ textAlign: 'center', padding: '5px' }}>{paymentRegisterDatas[index]?.mode}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{paymentRegisterDatas[index]?.chqNo}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{paymentRegisterDatas[index]?.bankName}</td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}>{paymentRegisterDatas[index]?.debitPrinciple?.toFixed(2)}</td>
                                                <td style={{ textAlign: 'left', padding: '5px' }}>{paymentRegisterDatas[index]?.remarks}</td>
                                                



                                                {/* <th></th> */}
                                            </tr>










                                            // ))
                                        }


                                    </tbody>

                                )}
                                <tr className='headerColor' style={{ width: '300px', textAlign: 'center' }}>
                                    <th style={{ width: '120px', textAlign: 'center' }}>
                                        Total
                                    </th>
                                    {Total?.toFixed(2)}
                                </tr>

                            </table>

                        </div>

                    </div>
                    </div>
                </div>

            </div>



        )
    }

};

export default PaymentRegister;



