import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './SupplimentaryMemberOpeningBalanceUi.css';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import SocietyService from '../../Service/SocietyService';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import axios from 'axios';
import Config from '../../Config.json';
import authHeader from '../../authHeader';
import { Toast } from 'primereact/toast';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { grey } from '@material-ui/core/colors';

function SupplimentaryMemberOpeningBalance() {
    const toast = useRef(null);
    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [date, setDate] = useState();
    const [transactionDate, setTransactionDate] = useState();
    const [DisableButton, setDisableButton] = useState(true);
    const [memberOpeningBalanceData, setMemberOpeningBalance] = useState(null);
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    const [sumOfPrinciple, setSumOfPrinciple] = useState(0);
    const [sumOfInterest, setSumOfInterest] = useState(0);
    const [sumOfDpc, setSumOfDpc] = useState(0);
    const [sumOfTotal, setSumOfTotal] = useState(0);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblNameSupplimentary');

    const [supplimentaryBillPlans, setSupplimentaryBillPlans] = useState([]);

    const getPlans = (() => {
        societyService.GetSupplimentaryBillPlans(societyId)
            .then(data => {
                //data=data.filter(a=>)
                setSupplimentaryBillPlans(data)
            });

    })
    const columns = [
        { field: 'srNo', header: 'Sr No' },
        { field: 'unitNo', header: 'Flat No' },
        { field: 'name', header: 'Member Name' },
        { field: 'dateFormat', header: 'Transaction Date' },
        { field: 'bal_Type', header: 'Type' },
        { field: 'principle', header: 'Principle' },
        { field: 'interest', header: 'Interest' },
        // { field: 'dpc', header: 'Dpc' },
        { field: 'total', header: 'Total' }
    ];

    useEffect(() => {
        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);
        }
        getPlans();
        // GetMemberData();
    }, []);

    const GetMemberData = (billPlan) => {
        let d = moment(date).format('YYYY-MM-DD');
        societyService.GetSupplimentaryMemberOpeningBalance(societyId, tableName, billPlan).then((data) => {
            let serialNo = 0;
            data.forEach(a => {
                serialNo++;
                a.srNo = serialNo;
                a.unitNo = `${a.wing}-${a.flatNo}`
                calculateTotal(a);
            })
            if (data.length === 0) {
                toast.current.show({ severity: 'warn', detail: "No Data Available on this Date" });
            }
            ;
            setMemberOpeningBalance(data)
            Totals(data);
        });
        console.warn(memberOpeningBalanceData);
    }

    const calculateTotal = (a) => {
        a.total = a.principle + a.interest + a.dpc;
    }

    const Totals = (data) => {
        setSumOfPrinciple(data && data.reduce((a, v) => a + v.principle, 0));
        setSumOfInterest(data && data.reduce((a, v) => a + v.interest, 0));
        setSumOfDpc(data && data.reduce((a, v) => a + v.dpc, 0));
        setSumOfTotal(data && data.reduce((a, v) => a + v.total, 0));
    }

    const dataTableEdit = {
        'data': setMemberOpeningBalance
    };

    const types = [
        { label: 'Debit', value: 'Debit' },
        { label: 'Credit', value: 'Credit' },
    ];

    const inputTextEditor = (productKey, props, field) => {
        if (field != 'bal_Type' && field != 'name' && field != 'unitNo' && field != 'dateFormat' && field != 'total' && field != 'srNo') {
            return <InputNumber type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.value)} mode="currency" currency="INR" locale="en-US" />;
        }
        else if (field == 'bal_Type') {
            return <Dropdown value={props.rowData[field]} options={types} optionLabel="label" optionValue="value"
                onChange={(e) => onEditorValueChange(productKey, props, e.value)} placeholder="Select a type" />;
        }
        else {
            return;
        }
    }

    const onEditorValueChange = (productKey, props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        updatedProducts[props.rowIndex]["status"] = "modified";
        dataTableEdit[`${productKey}`](updatedProducts);
        calculateTotal(updatedProducts[props.rowIndex]);
        Totals(updatedProducts);
    }

    const onEditorCancel = (e) => {
        const { rowIndex: index, field } = e.columnProps;
        let data = [...memberOpeningBalanceData];
        data[index][field] = editingCellRows[index][field];
        delete editingCellRows[index][field];
        setMemberOpeningBalance(data);
    }

    let editingCellRows = {};
    let originalRows = {};

    const onEditorInit = (e) => {
        const { rowIndex: index, field, rowData } = e.columnProps;
        if (!editingCellRows[index]) {
            editingCellRows[index] = { ...rowData };
        }
        editingCellRows[index][field] = memberOpeningBalanceData[index][field];
    }

    const onSubmit = (e) => {
        setSpinnerVisible(true);
        let data = memberOpeningBalanceData;
        data.tableName = tableName;
        if (transactionDate !== undefined) {
            let d = moment(transactionDate).format('YYYY-MM-DD');
            data.forEach(a => {
                a.tDate = d;

            })
        }
        else {
            data.forEach(a => {
                let d = moment(a.dateFormat).format('YYYY-MM-DD');
                a.tDate = d;

            })
        }

        data.forEach(a => {
            if (a.id === 0) {
                a.state = "Insert";
            }
            else {
                a.state = "Update";
            }

            a.billPlanID = selectedBillPlan;
            a.gl_id = 0;
        })

        data.societyId = societyId;
        let changedData = data.filter(a => a.status != undefined && a.status == "modified");
        return axios.put(`${Config.apiUrl}/SupplimentaryBillGeneration/UpdateMemberOpeningBalance?transactionTableName=${tableName}`, changedData, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data != null) {
                    toast.current.show({ sticky: true, severity: 'success', detail: "Data Updated Successfully" });
                }
                else {
                    toast.current.show({ sticky: true, severity: 'error', detail: "Data failed to Update" });
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSpinnerVisible(false);
                GetMemberData(selectedBillPlan);
            });
    }
    const [selectedBillPlan, setSelectedBillPlan] = useState(0);
    const OnChangeBillPlan = ((e) => {
        let plan = e.currentTarget.value;
        setSelectedBillPlan(plan);
        GetMemberData(plan);
    })
    const header = (
        <div className="table-header">
            <div style={{display:'flex'}}>
                <div>
                    <label className='col-fixed' style={{ width: '180px'}}> Supplimentary Plans:</label>
                    <div className="col ">
                        <select name="billPlan" value={selectedBillPlan} style={{ width: '400px', height: '30px',borderRadius:'6px',borderColor:'lightgrey',marginTop:'8px' }} ref={register({ required: true })} onChange={OnChangeBillPlan}>
                            <option hidden value=""> Select Bill Plan</option>
                            {supplimentaryBillPlans.map((item) => (
                                <option value={item.id}>
                                    {item.billName}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                </div>
                
                <div  style={{marginLeft:'300px'}}>
                    <Button label="UPDATE" value="Edit" onClick={handleSubmit(onSubmit)} disabled={DisableButton} />
                </div>
                
            </div>
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={sumOfPrinciple} />
                <Column footer={sumOfInterest} />
                {/* <Column footer={sumOfDpc} /> */}
                <Column footer={sumOfTotal} />
            </Row>
        </ColumnGroup>
    );

    return (
        <form className="SupplimentaryMemberOpeninBalance">
            <div>
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                <Toast ref={toast} position="center" />
                <Card className="BackgroundColor" >
                    <DataTable value={memberOpeningBalanceData} editMode="cell" className="editable-cells-table" header={header} footerColumnGroup={footerGroup} scrollable scrollHeight='300px' style={{ overflow: 'hidden' }}>
                        {
                            columns.map(col => {
                                const { field, header } = col;
                                return <Column key={field} field={field} filter header={header} editor={(props) => inputTextEditor('data', props, field)}
                                    onEditorInit={onEditorInit} onEditorCancel={onEditorCancel} />
                            })
                        }
                    </DataTable>
                </Card>
            </div>
        </form>
    );
}
export default SupplimentaryMemberOpeningBalance;




