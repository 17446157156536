import { Button } from 'primereact/button';
import '../Report/MemberReceiptReport.css';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';       
import { Dropdown } from 'primereact/dropdown';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { List } from '@material-ui/core';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { useParams } from 'react-router-dom';


var memberData;
var societyData = [];
var converter = require('number-to-words');

function SelectedMemberReceipt() { 
  const location = useLocation();
  const toast = useRef(null);
    var societyId = parseInt(localStorage.getItem('societyId'));
    const societyService = new SocietyService();
    const [SocietyDetails, SetSocietyDetailsData] = useState([]);
    societyData = SocietyDetails;
    const [isPrintDisabled, setIsPrintDisabled] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');
    const [data, setMemberReport] = useState([]);
  memberData=data;



    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        societyService.GetMemberReceiptOnRctNo(societyId, location.state.printDetails.rctNo,tableName).then(memberReceiptData => {
        memberReceiptData.forEach(a=>{
          if(a.chqNo === 0)
          {
            a.chqNo= '---';
          }
        })
          setMemberReport(memberReceiptData)
        })


            societyService.getSocietyDetailsOnID(societyId).then(data => {
                SetSocietyDetailsData(data)
            })
    }, []);

    return (
        <div className="card" >
            {/* <Toast ref={toast} position="center" /> */}
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <div style={{ margin: '10px' }}>
                    <Button onClick={handlePrint} disabled={isPrintDisabled} label="PRINT" className="btnlabel" />
                </div>
            <Report ref={componentRef} />
        </div>
    );
}

const  portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class Report extends React.Component {
      render() {
      
          return (
              <div className='memberReceiptReport' style={{ height: '100%' }}>
              <style>{portrait()}</style>
                  {memberData.map((data, index) => 
                     
                      <div className="memberReceiptPageBreak">
                          <div className='SocietyName'>
                              <div className="font"><text>{societyData[0]?.societyName}</text></div>
                              <div>
                                  <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
                                  <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
                              </div>
                              <div>
                                  <text>
                                      {societyData[0]?.address}
                                  </text>
                              </div>
                          </div>
                          <div className="MRBill">
                              RECEIPT
                          </div>
  
  
                          <div style={{margin:'4px'}}>
                              <div className="MRborder">
                                  <div className="p-formgroup-inline">
                                      <div className="p-formgroup-inline">
  
                                          <label className="MRalignleft" >Receipt No
                                          </label>
                                          <text>: {memberData[index].rctNo}</text>
                                      </div>
                                      {/* <div className="p-formgroup-inline"> */}
  
  
                                          <label className="alignRight"> Date: {memberData[index].dateFormat}</label>
                                      {/* </div> */}
                                  </div>
                                  <div className="p-formgroup-inline">
                                      <label className="MRalignleft" >Received with Thanks From </label>
                                      <text>: {memberData[index].name}</text>
  
                                  </div>
                                  <div className="p-formgroup-inline">
                                      <label className="MRalignleft" >Unit No </label>
                                      <text>: {memberData[index].wing}-{memberData[index].flatNo}</text>
                                  </div>
  
                                  {/* <div style={{ height: '100px',margin:'4px' }}> */}
                                      <div className="p-formgroup-inline">
                                          <label className="MRalignleft">Amount ₹ </label>
                                          <text>: {memberData[index].total} </text>
                                      </div>
                                      <div className="p-formgroup-inline">
                                          <label className="MRalignleft">Rupees in words </label>
                                          <text>: "{converter.toWords(Math.round(memberData[index].total).toFixed(2)).toUpperCase()} ONLY"</text>
                                      </div>
                                      <div className="p-formgroup-inline">
                                          <label className="MRalignleft">Mode </label>
                                          <text>: {memberData[index].mode}</text>
                                      </div>
                                      <div className="p-formgroup-inline">
                                          <label className="MRalignleft">By Cheque No </label>
                                          <text>: {memberData[index].chqNo}</text>
                                      </div>
                                      <div className="p-formgroup-inline">
                                          <label className="MRalignleft">Drawn on </label>
                                          <text>: {memberData[index].glTitle}</text>
                                      </div>
                                      <div className="p-formgroup-inline">
                                          <label className="MRalignleft">Narration </label>
                                          <text>: {memberData[index].remarks}</text>
  
                                      </div>
                                      <div style={{ height: '100px' }}>
                                          <text className="alignright"><b>For {societyData[0]?.societyName}</b></text><br />
                                          <text className="alignright" style={{ marginTop: "40px" }}><b>Authorised Signature</b></text>
                                      </div>
                                      <text className="MRalignleft" style={{ marginTop: '30px' }}>This Receipt is Valid Subject to realisation of cheque..</text>
                                  {/* </div> */}
                              </div>
                          </div>
                      </div>
                  )}
              </div>
  
          );  
      } 
  };
  
  export default SelectedMemberReceipt;