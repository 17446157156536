import { useForm } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import './generalReceipt.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import SocietyService from '../../Service/SocietyService';
import { BrowserRouter, useParams } from 'react-router-dom';
import Config from '../../Config.json';
import { SocietyContext } from "../../SocietyContext";
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { usePopup } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import authHeader from "../../authHeader";
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import { useHistory } from 'react-router-dom';

function GeneralReceipt({ props, }) {
    let history = useHistory();
 const { register, handleSubmit, errors, clearErrors, setValue, reset } = useForm();
    const societyService = new SocietyService();
    const { id } = useParams();
    const toast = useRef(null);
    const [date, setDate] = useState([]);
    const [wings, setWings] = useState([]);
    const [wing, setWing] = useState();
    const [Name, setName] = useState([]);
    const [GlTitle, setGlTitle] = useState();
    var societyId = parseInt(localStorage.getItem('societyId'));
    const societyName = localStorage.getItem('societyName');
    const [flatId, setFlatId] = useState(0);
    const [Mode, setMode] = useState([]);
    const [currentDate, setCurrentDate] = useState();
    const isAddMode = !id;
    const [amount, setAmount] = useState();
    const [narrartion, setNarration] = useState();
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [selectedFlatNoName, setSelectedFlatNoName] = useState();
    const [addGLEntry, setGLEntry] = useState();
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [FormatedDate, setFormatedDate] = useState(null);
    var [societyBank, setSocietyBankData] = useState([]);
    const [societyBankNames, setSocietyBankName] = useState([]);
    const [selectedBankGlId, setSelectedBankGlId] = useState([]);
    var [generalReceipt, setGeneralReceipt] = useState([]);
    const [glIdData, setGlIdData] = useState([]);
    const [selectedGlId, setSelectedGlId] = useState();
    const [selectedBank, setSelectedBank] = useState();
    const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [saveButtonLabel, setSaveButtonLabel] = useState('SAVE');
    const [checked, setChecked] = useState(false);
    const tableName = sessionStorage.getItem('tblName');
    const [selectedRadioData, setSelectedRadioData] = useState(null);

    const contentStyle = {
        maxWidth: "600px",
        width: "90%",
        height: "40%",
        marginLeft: "70px",
        marginTop:"-150px"
    };
    const GlcontentStyle = {
        maxWidth: "600px",
    };

    const onSave = (data, e) => {
        data.TransactionTable=tableName;
        return isAddMode
            ? creategeneralReceipt(data)
            : updategeneralReceipt(data);
    }

    const creategeneralReceipt = (data, e) => {
        setSpinnerVisible(true);
        data.transactionType = "GR";
        data.societyId = societyId;
        data.societyName = societyName;

        if(!checked)
        {
            data.flatId = 0;
        }
        else{
            data.flatId=flatId;
        }

        if (data.chqNo === '') {
            data.chqNo = 0;
        }
        data.bankGlId = selectedBankGlId;
        if (data.amount === '0') {
            toast.current.show({ severity: 'warn', detail: 'Enter Correct Amount' });
            return;
        }
        return axios.post(`${Config.apiUrl}/Transaction/InsertgeneralReceipt`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', detail: "Data Saved Successfully",sticky:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg ,sticky:true});
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Network Error',sticky:true });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    }

    const updategeneralReceipt = (data, e) => {
        if(!checked)
        {
            data.flatId = 0;
        }
        else{
            data.flatId=flatId;
        }
        data.societyId = societyId;
        data.societyName = societyName;
        data.bankGlId = selectedBankGlId;
        if (data.chqNo === '') {
            data.chqNo = 0;
        }
        setSpinnerVisible(true);
        return axios.put(`${Config.apiUrl}/Transaction/UpdateGeneralReceipt?id=${id}`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: "Data Updated Successfully",sticky:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg,sticky:true });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Network Error" ,sticky:true});
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    }

   


   // const onReset = (e) => {
       // setValue('glId', '');
       // setValue('glTitle', '');
       // setValue('wing', '');
       // setValue('flatNo', '');
       // setValue('name', '');
       // setValue('amount', '');
       // setValue('mode', '');
       // setValue('chqNo', '');
        //setValue('bankName', '');
       // setValue('remarks', '');
       //// setValue('societyBank', '');
    //}
    const onReset = () => {
        reset();
        window.location.reload();
      }

    const onRemove=()=>{
        window.location.reload();
    }

    useEffect(() => {
        let d = moment(moment(new Date())).format('YYYY-MM-DD');
        setDate(d);
        setValue('date', d);
        console.warn(d);
        const fetchData = async () => {
            const personalDetails = await societyService.getPersonalDetails(societyId,tableName);
            SetPersonalDetailsData(personalDetails);

            const glEntry = await societyService.getGLEntry(societyId, d,tableName);
            setGLEntry(glEntry)

            const glIdData = await societyService.getGlTitle(societyId);
            setGlIdData(glIdData)

            const wings = await societyService.getWing(societyId);
            setWings(wings.map(({ wing }) => ({ label: wing, value: wing })));

            if (!isAddMode) {
                setSaveButtonLabel("UPDATE");
                // get user and set form fields
                societyService.getTransactionDetailsOnID(id,tableName).then(generalReceiptData => {
                    const fields = ['glId', 'glTitle', 'wing', 'flatNo', 'name', 'rctNo', 'creditPrinciple', 'mode', 'chqNo', 'bankName', 'narrartion'];
                    fields.forEach(field => setValue(field, generalReceiptData[0][field]));
                    generalReceipt = generalReceiptData;
                    onModeChangeEditAction(generalReceiptData[0].mode);
                    setFlatId(generalReceiptData[0].flatId);
                    setValue('date', generalReceiptData[0].dateFormat);
                    setValue('glId', generalReceiptData[0].glId);
                    setValue('amount', generalReceiptData[0].creditPrinciple);
                    setValue('remarks', generalReceiptData[0].narration);
                    setWing(generalReceiptData[0].wing);
                    if(generalReceiptData[0].flatId !== 0){
                        setChecked(true);
                    }
                });
            }
            else {
                societyService.getRctNo(societyId,tableName).then(data => {
                    setValue('rctNo', data);
                });
            }

            if (accessLevel >= 2) {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
        }
        fetchData();
    }, []);

    const onDateChange = (e) => {
        let date = e.target.value;
        setDate(date);
        societyService.getGLEntry(societyId, date,tableName).then(data => setGLEntry(data));
        console.warn(addGLEntry);
    }

    const onRowSelect = (event) => {
        let fNo = event.data.flatNo;
        let wing = event.data.wing;
        let name = event.data.name;
        setValue('flatNo', fNo);
        setValue('name', name);
        setValue('wing', wing);
        setWing(wing);
        setFlatId(event.data.id);
    }

    const onRowSelectGlid = (e) => {
        let glid = e.data.glId;
        let glTitle = e.data.glTitle;
        setSelectedGlId(glid);
        setGlTitle(glTitle);
    }

    const onChangeWing = (e) => {
        let wing = e.currentTarget.value;
        setValue('wing', wing);
        setWing(wing);
    }

    const onChangeFlat = (e) => {
        let flatNo = e.currentTarget.value;
        flatNo = parseInt(flatNo);
        societyService
            .getName(flatNo, wing, societyId)
            .then((data) => {
                if (data.length > 0) {
                    setValue('name', data[0].name);
                    setFlatId(data[0].id);
                      }
                else {
                    // toast.current.show({ severity: 'warn', detail: "Wrong Flat Number" });
                    setValue('name', '');
                }
            }
            );
    }

    const onChangeGlId = (e) => {
        let glId = e.currentTarget.value;
        societyService
            .getGlTitleOnglId(glId, societyId)
            .then((data) => {
                if (data != null) {
                    setGlTitle(data);
                    console.warn(GlTitle);
                }
                else {
                    setGlTitle('');
                    toast.current.show({ severity: 'error', detail: "GL ID is Wrong" });
                }
            }
            );
    }

    const onModeChangeEditAction = async (e) => {
        let mode = e;
        setMode(mode);
        if (mode.toUpperCase() === "CASH") {
            mode = "CASH BALANCE";
        }
        else {
            mode = "BANK BALANCE";
        }
        const banks = await societyService.GetSocietyBank(societyId, mode);
        setSocietyBankData(banks);
        societyBank = banks;
        setBankDetails(generalReceipt[0].societyBank);
        // setSocietyBank(data.map(({ societyBankName }) => ({ label: societyBankName.glTitle, value: societyBankName })),
        var bankNames = [];
        banks.forEach(item => {
            bankNames.push({ 'societyBank': item.societyBank });
        })

        setSocietyBankName(
            bankNames.map(({ societyBank }) => ({ label: societyBank, value: societyBank, })),
        )
        setSelectedBank(generalReceipt[0].societyBank);
        setValue('wing', generalReceipt[0].wing);
        onChangeSelectedBank(generalReceipt[0].societyBank);

    }

    const onChangeMode = async (e) => {
        let mode = e.currentTarget.value
        setMode(mode);
        if (mode.toUpperCase() === "CASH") {
            mode = "CASH BALANCE";
            setValue('chqNo', '');
            setValue('bankName', '');
        }
        else {
            mode = "BANK BALANCE";
        }
        const data = await societyService.GetSocietyBank(societyId, mode);
        setSocietyBankData(data);
        var bankNames = [];
        data.forEach(item => {
            bankNames.push({ 'societyBank': item.societyBank });
        })
        setSocietyBankName(
            bankNames.map(({ societyBank }) => ({ label: societyBank, value: societyBank, })),
        )
        setSelectedBank(null);
    }

    const onChangeSelectedBank = (e) => {
        let selectBankLocal;
        if (e.currentTarget !== undefined) {
            selectBankLocal = e.currentTarget.value;
        }
        else {
            selectBankLocal = e;
        }
        setBankDetails(selectBankLocal);
    }

    function setBankDetails(selectBankLocal) {
        let selectedGlId = societyBank.find(a => a.glTitle === selectBankLocal);
        setSelectedBankGlId(selectedGlId.glId)
        setSelectedBank(selectBankLocal);
    }

    const InputGlId = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('glId', onlyNums);
    }

    const InputFlatNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('flatNo', onlyNums);
    }

    const InputAmount = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setValue('amount', onlyNums);
    }

    const InputChequeNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('chqNo', onlyNums);
    }
    const onEdit = (rowData) => {
        if (rowData.transactionType === 'GR') {
          history.push(`/GeneralReceipt/edit/${rowData.id}`);
        }
        if (rowData.transactionType === 'MR') {
          history.push(`/MemberReceipt/edit/${rowData.id}`);
        }
        if (rowData.transactionType === 'MRR') {
          history.push(`/MRReturnEntry/edit/${rowData.id}`);
        }
        if (rowData.transactionType === 'GRR') {
          history.push(`/GRReturnEntry/edit/${rowData.id}`);
        }
        if (rowData.transactionType === 'VE') {
          history.push(`/VoucherEntry/edit/${rowData.id}`);
        }
        if (rowData.transactionType === 'DE') {
          history.push(`/CreditDebitNote/edit/${rowData.id}/${rowData.jeNo}`);
        }
        if (rowData.transactionType === 'CE') {
          history.push(`/CreditDebitNote/edit/${rowData.id}/${rowData.jeNo}`);
        }
        console.warn("edit buuton clicked");
      }
      function compareGlTitils(a, b) {
    
        // converting to uppercase to have case-insensitive comparison
        const name1 = a.glTitle.toUpperCase();
        const name2 = b.glTitle.toUpperCase();
    
        let comparison = 0;
    
        if (name1 > name2) {
          comparison = 1;
        } else if (name1 < name2) {
          comparison = -1;
        }
        return comparison;
      }
    
      const editbutton = (rowData) => {
        return <div>
         <Button icon="pi pi-pencil" className='edit' value="Edit" tooltip="Edit" onClick={() => onEdit(rowData)} />
          
        </div>;
      }
    return (
        
        <div className="GeneralReceipt" style={{ height: '100%', border: "1px solid black", width: "auto", backgroundColor: "Info" }} >
            <h3 style={{ marginLeft: '20px',display:'flex',justifyContent:'center',color:'red'}}>General Receipt</h3>
            <Toast ref={toast} position={'center'} onRemove={onRemove} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div className="p-formgroup-inline">
                <div className="p-field p-grid ">
                    <label className="label200" >Date:</label>
                    <div className="p-col">
                        <input type="Date" name="date" autoFocus={focusValue} className="height" style={{ width: '175px',borderRadius:'6px', borderColor:'lightgrey' }} onChange={onDateChange} ref={register({ required: true })} />
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.date && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '180px', marginTop: '-1px' }}>Please select Date</p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >GL ID:</label>
                    <div className="p-col">
                        <input type="text" name="glId" className="height" style={{ width: '100px',borderRadius:'6px', borderColor:'lightgrey' }} onChange={onChangeGlId} ref={register({ required: true })} onInput={InputGlId} value={selectedGlId} autoComplete="off" />
                    </div>
                    <div>
                        <input type="text" name="glTitle" className="height" style={{ width: '380px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} value={GlTitle} autoComplete="off" />
                        <Popup trigger={<a href="#" style={{ marginLeft: '50px' }}> Search Gl-Id </a>} position="right top" contentStyle={GlcontentStyle}>
                            <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => setSelectedGlidGltitle(e.value)} dataKey="glId" className="p-datatable-sm" scrollable scrollHeight='280px' style={{ overflow: 'hidden' }} width="250px" >
                                <Column field="glId" header="Gl Id" filter></Column>

                                <Column field="glTitle" header="GL Title" filter></Column>
                            </DataTable>
                        </Popup>
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.glId && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '190px', marginTop: '-15px' }}>Please enter GL ID</p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >Wing:</label>
                    <div className=" p-col" >
                        <select disabled={!checked}  name="wing" className="smallTextBox" ref={register} onChange={onChangeWing} >
                            <option hidden value="">Select Wing</option>
                            {wings.map(({ label, value: wings }) => (
                                <option key={wings} value={wings}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="label100" >Flat No:</label>
                    <div className="p-col">
                        <input type="text" disabled={!checked} name="flatNo" className="smallTextBox" ref={register} onChange={onChangeFlat} onInput={InputFlatNo} autoComplete="off" />
                    </div>
                </div>

                <label className="label200" >Enable Flat details:</label>
                <Checkbox inputId="binary" style={{ margin: '5px' }} checked={checked} onChange={e => setChecked(e.checked)} />
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >Name:</label>
                    <div className="p-col">
                        <input type="text" disabled={!checked} readOnly name="name" className="height" style={{ width: '487px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register} autoComplete="off" />
                    </div>
                </div>
                <Popup disabled={!checked} trigger={<a href="#" style={{ marginLeft: '30px' }}>Search Flat No</a>} position="right top" contentStyle={contentStyle}>
                    <DataTable value={personalDetailsData} selectionMode="single" selection={selectedFlatNoName} onRowSelect={onRowSelect} onSelectionChange={e => setSelectedFlatNoName(e.value)} dataKey="id" className="p-datatable-sm" scrollable scrollHeight='350px' style={{ overflow: 'hidden' }} width="250px" >
                        <Column field="wing" header="Wing" filter style={{ width: '150px',borderRadius:'6px', borderColor:'lightgrey' }}></Column>
                        <Column field="flatNo" header="Flat No" filter style={{ width: '150px',borderRadius:'6px', borderColor:'lightgrey' }}></Column>
                        <Column field="name" header="Name" filter></Column>
                    </DataTable>
                </Popup>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.name && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '190px', marginTop: '-15px' }}>Please select Name</p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >Amount:</label>
                    <div className="p-col">
                        <input type="text" name="amount" className="amount1" required ref={register({ required: true })} onInput={InputAmount} autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="label100" >Rct No:</label>
                    <div className="p-col" >
                        <input type="text" name="rctNo" readOnly className="height3" ref={register} />
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.amount && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '190px', marginTop: '-15px' }}>Please enter amount</p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >Mode:</label>
                    <div className="p-col">
                        <select style={{ width: '175px',borderRadius:'6px', borderColor:'lightgrey' }} name="mode" className="height" required ref={register} onChange={onChangeMode}>
                            <option hidden value=''>Select Mode</option>
                            <option>Cash</option>
                            <option>Bank</option>
                        </select>
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="label100" >Chq No:</label>
                    <div className="p-col">
                        <input type="text" name="chqNo" disabled={Mode === 'Cash'} className="chq1" ref={register} onInput={InputChequeNo} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" > Society Bank Name:</label>
                    <div className="p-col-12 p-md-2 ">
                        <select style={{ width: '485px',borderRadius:'6px', borderColor:'lightgrey' }} name="societyBank" className="height" ref={register({ required: true })} onChange={onChangeSelectedBank} value={selectedBank} >
                            <option hidden value="">Select Bank Name</option>
                            {societyBank.map((societyBankName) => (
                                <option key={societyBank.glTitle} value={societyBank.glTitle}>
                                    {societyBankName.glTitle}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.remarks && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '190px', marginTop: '-15px' }}>Please select society Bank name </p>
                )
                }
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >Member Bank Name:</label>
                    <div className="p-col" >
                        <input type="text" name="bankName" disabled={Mode === 'Cash'} className="height2" style={{ width: '485px' }} ref={register} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <label className="label200" >Remarks:</label>
                    <div className="p-col">
                        <input type="text" name="remarks" className="height" style={{ width: '485px',borderRadius:'6px', borderColor:'lightgrey' }} ref={register({ required: true })} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-d-flex p-flex-column p-flex-md-row">
                {errors.remarks && (
                    <p className="errorMsg" style={{ color: 'red', marginLeft: '190px', marginTop: '-15px' }}>There should be Remarks</p>
                )
                }
            </div>

            <div className="p-formgroup-inline" style={{ justifyContent: 'Left', marginLeft: '20rem', width: '60%' }}>
                <div className="p-field">
                    <Button label={saveButtonLabel} type="submit " onClick={handleSubmit(onSave)} disabled={DisableButton} />
                </div>
                <div className="p-field ">
                    <Button label="RESET" onClick={onReset} disabled={DisableButton} />
                </div>
            </div>
            <div className="p-formgroup-inline">
                <div className="p-field">
                    <DataTable value={addGLEntry} paginator rows={5}  scrollable className="p-datatable-sm" width="500px" >
                    <Column body={editbutton} headerStyle={{ width: '5em' }}></Column>
          <Column field="dateFormat" style={{ width: '100px' }} header="Date"  ></Column>
                        <Column field="glId" header="Gl Id"></Column>
                        <Column field="glTitle" header="GL Title" style={{ width: '200px' }} ></Column>
                        <Column field="wing" header="Wing" style={{ width: '50px' }}></Column>
                        <Column field="flatNo" header="Flat No" style={{ width: '70px' }}></Column>
                        <Column field="name" style={{ width: '300px' }} header="Name"></Column>
                        <Column field="creditPrinciple" header="Amount"></Column>
                        <Column field="rctNo" header="Rct No"></Column>
                        <Column field="mode" header="Mode"></Column>
                        <Column field="chqNo" header="Chq No"></Column>
                        <Column field="bankName" header="Bank" style={{ width: '300px' }}></Column>
                        <Column field="remarks" header="Remarks"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default GeneralReceipt;



