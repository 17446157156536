import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from "react-hook-form";
import SocietyService from '../../Service/SocietyService';
import 'primeflex/primeflex.css';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import axios from 'axios';
import Config from '../../Config.json';
import authHeader from '../../authHeader';


//import { Toast } from 'primereact/toast';


function GeneratedBillsView({ props }) {
  const toast = useRef(null);

    const header = (
        <div className="tableheader">
        </div>
    )
    const [generatedbillsData, setGeneratedbillsData] = useState();
    const societyService = new SocietyService();
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const { errors, register, reset, handleSubmit } = useForm();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');


  //const toast = useRef(null);
  
    var societyId = parseInt(localStorage.getItem('societyId'));
    //const tableName = sessionStorage.getItem('tblName');

   
    useEffect(() => {
        societyService
.getGeneratedBillsHistory(societyId,tableName)
          .then((data) =>
          {
          setGeneratedbillsData(data);
          }
          );
      }, []);
      const formatDate = (value) => {
        return value.toISOString().substr(0, 10);
    };
    const dateDueBodyTemplate = (rowData) => {
        return formatDate(new Date(rowData.dueDate));
    };

    const dateBillingBodyTemplate = (rowData) => {
        return formatDate(new Date(rowData.billDate));
    };

    const timeBillingBodyTemplate = (rowData) => {
        return formatDate(new Date(rowData.billGenerationDate));
    };
    
    const onDelete = (event) => {
      if (toast.current) {
        toast.current.show({
          severity: 'warn',
          sticky: true,
          content: (
            <div className="p-flex p-flex-column" style={{ flex: '1' }}>
              <div className="p-text-center">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                <h4>Are you sure?</h4>
                <p>Confirm to Delete</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button type="button" label="Yes" className="p-button-success" onClick={handleSubmit(onYes)} />
                </div>
                <div className="p-col-6">
                  <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        console.error("Toast ref is not initialized.");
      }
    };
  
    const onYes = (event) => {
      setSpinnerVisible(true);
      toast.current.clear();
    
      let index = generatedbillsData.findIndex(a => a.generationId === selectedRadioData.generationId);
      let generationId = selectedRadioData.generationId;
    
      let data = generatedbillsData.filter(b => b.generationId !== selectedRadioData.generationId);
      setGeneratedbillsData(data);
    
      axios.delete(`${Config.apiUrl}/BillGeneration/delete?generationId=${generationId}&transactionTableName=${tableName}`, { headers: authHeader() })
        .then(response => {
          console.log(response);
          if (response.data.success ) {
            toast.current.show({ severity: 'success', detail: response.data.msg });
            window.location.reload();
          } else {
            toast.current.show({ severity: 'error', detail: 'Error in deleting bill details' });
          }
        })
        .catch(error => {
          toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in deleting bills' });
        })
        .finally(() => {
          setSpinnerVisible(false);
        });
    };
      const onNo = () => {
      toast.current.clear();
      return;
    };
  
    return (
        
        <div className="GeneratedBillsView" >
             <Toast ref={toast} />
            <span style={{ fontFamily: 'verdena', fontWeight: 'bold' }}>Generated Bills View</span>
            <div className="p-formgroup-inline">

            <div>
       
        <Button icon="pi pi-trash" label="DELETE" onClick={onDelete} value="Delete" className="btn" 
       style={{ cursor: 'pointer',marginLeft: '100%' }}/>
      </div>
                <DataTable value={generatedbillsData} className="p-datatable-gridlines" 
                    responsiveLayout="scroll" header={header} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}>
                    {/* <Column field="dateFormat" style={{ width: '115px' }} header="Date" filter></Column> */}
                    {/* <Column field="ID" header="ID" style={{ width: '70px' }} filter></Column> */}
                    {/* <Column field="societyId" header="SOCIETY ID" style={{ width: '120px' }} filter></Column> */}
                    <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
                  

                    <Column field="generationId" header="GENERATION ID" style={{ width: '70px' }} filter></Column>
                    <Column field="billDate" body={dateBillingBodyTemplate} header="BILL DATE" style={{ width: '80px' }} filter></Column>
                    <Column field="dueDate" body={dateDueBodyTemplate}  header="DUE DATE" style={{ width: '70px' }} filter></Column>
                    <Column field="billGenerationDate" body={timeBillingBodyTemplate} header="BILL GENERATION DATE" style={{ width: '200px' }} filter></Column>
                  
                    {/* <Column field="Time" header="TIME" style={{ width: '70px' }} filter></Column> */}
                    <Column field="generatedBy" header="GENERATED BY" style={{ width: '70px' }} filter></Column>
                </DataTable>
            </div>
        </div>
    );
}

export default GeneratedBillsView;