import React, { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
 
const clientId = "55236503589-p9rl4c6jeo87okg69d6eitbgcafq5s8p.apps.googleusercontent.com";


function Authtication() {
 
    const [loading, setLoading] = useState('Loading...');
    const [user, setUser] = useState();
  
    const handleLoginSuccess = (response) => {
      console.log("Login Success ", response);
      setLoading();
    }
  
    const handleLoginFailure = error => {
      console.log("Login Failure ", error);
      setLoading();
    }
  
    const handleLogoutSuccess = (response) => {
      console.log("Logout Success ", response);
    }
  
    const handleLogoutFailure = error => {
      console.log("Logout Failure ", error);
    }
  
    const handleRequest = () => {
      setLoading("Loading...");
    }
  
    const handleAutoLoadFinished = () => {
      setLoading();
    }

    return (
      <div>
        <h3>Login with Google</h3>
        {user ? <div>
          
          <GoogleLogout
            clientId={clientId}
            onLogoutSuccess={handleLogoutSuccess}
            onFailure={handleLogoutFailure}
          />
        </div> :
          <GoogleLogin
            clientId={clientId}
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            onRequest={handleRequest}
            onAutoLoadFinished={handleAutoLoadFinished}
            isSignedIn={true}
          />}
      </div>
    );
  }
 
export default Authtication;