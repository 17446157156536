/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { Divider } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import styles from './OpeningBalance.css';
import { useForm } from 'react-hook-form';
import SocietyService from "../../Service/SocietyService";
import axios from 'axios';
import Config from '../../Config.json' 
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import { InputNumber } from 'primereact/inputnumber';
import moment from 'moment';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';



function OpeningBalance({ id, setActiveTab, societyId }) {

    //  const isAddMode = !id;
    const toast = useRef(null);
    const [date, setDate] = useState();
    const [openingBalDate, setOpeningBalDate] = useState();
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [openBalDues, setOpenBalDues] = useState();
    const [openBalInterest, setOpenBalInterest] = useState();
    const [openBalDpc, setOpenBalDpc] = useState();
    const [grandTotal, setGrandTotal] = useState([]);
    const [isAddMode, setIsAddMode] = useState(false);
    const societyService = new SocietyService();
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    // const [DisableButton, setDisableButton] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [resetDisabled, setResetDisabled] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const tableName = sessionStorage.getItem('tblName');


    


    const { errors, register, reset, handleSubmit, setValue } = useForm(
        // {resolver: yupResolver(validationSchema)}
    );

    const onSubmit = (data, e) => {
        data.TransactionTable=tableName;
        setSaveDisabled(true);
        if (isAddMode) {
            createUser(data)
        }
        else {
            updateUser(data);
        }

    }

   



    const createUser = (data, e) => {
        data.flatId = id;
        data.openBalDpc=retriveOpeningBalance[0].openBalDpc;
        data.openBalInterest=retriveOpeningBalance[0].openBalInterest;
        data.openBalDues=retriveOpeningBalance[0].openBalDues;
        data.societyId = societyId;
        if (data.openBalDate === '') {
            data.openBalDate = undefined;
        }
        if (data.openBalDues === '') {
            data.openBalDues = undefined;
        }
        if (data.openBalInterest === '') {
            data.openBalInterest = undefined;
        }
        setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/OpeningBalance/id=2`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                    setNextDisabled(false);
                    setSaveDisabled(false);

                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });

                }

            })
            .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
              })

        
    }

    const updateUser = (data, e) => {
        data.societyId = societyId;
        data.flatId = parseInt(id);
        data.openBalDpc=retriveOpeningBalance[0].openBalDpc;
        data.openBalInterest=retriveOpeningBalance[0].openBalInterest;
        data.openBalDues=retriveOpeningBalance[0].openBalDues;
        data.transactionTable= sessionStorage.getItem('tblName');
        if (data.openBalDate === '') {
            data.openBalDate = undefined;
        }
        if (data.openBalDues === '') {
            data.openBalDues = undefined;
        }
        if (data.openBalInterest === '') {
            data.openBalInterest = undefined;
        }
        
        setSpinnerVisible(true);
        return axios.put(`${Config.apiUrl}/OpeningBalance/update?id=${id}`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data[0].success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', sticky: true, detail: response.data[0].msg });
                    setNextDisabled(false);
                    setSaveDisabled(false);


                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });

                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
              })
    }

    const [retriveOpeningBalance, setRetriveOpeningBalance] = useState({});

    useEffect(() => {
        let d = moment(moment(new Date())).format('YYYY-MM-DD');
        setValue("openBalDate",d);
        //checking opening balance date
    

        //checking access level
        if (accessLevel >= 2) {
        }
        else {
            setResetDisabled(true);
            setSaveDisabled(true);


        }
        // get user and set form fields
        societyService.getOpeningBalanceOnID(id).then(retriveOpeningBalanceIn => {
            if (retriveOpeningBalanceIn.length > 0) {
                //  setGrandTotal( retriveOpeningBalanceIn[0].openBalDues + retriveOpeningBalanceIn[0].openBalInterest);

                const fields = ['openBalDate', 'openBalDues','openBalDpc', 'openBalInterest', 'balType', 'bank', 'branch'];
                fields.forEach(field => setValue(field, retriveOpeningBalanceIn[0][field]));
                setRetriveOpeningBalance(retriveOpeningBalanceIn);
                setOpenBalDpc(retriveOpeningBalanceIn[0].openBalDpc);
                setOpenBalDues(retriveOpeningBalanceIn[0].openBalDues);
                setOpenBalInterest(retriveOpeningBalanceIn[0].openBalInterest);
                setOpeningBalDate(retriveOpeningBalanceIn[0].openBalDateFormat);
                console.warn(openingBalDate)
                let total = retriveOpeningBalanceIn[0]?.openBalDues + retriveOpeningBalanceIn[0]?.openBalInterest + retriveOpeningBalanceIn[0]?.openBalDpc;
                setGrandTotal(total)
            }
            else {
                // createUser(data);
                setIsAddMode(true);
                let obj = { openBalDues: 0, openBalInterest: 0 ,openBalDpc:0};
                let array = [];
                array.push(obj);
                setRetriveOpeningBalance(array);

            }
            setNextDisabled(false);

        });

        
    }, []);

    const onChange = (e) => {
        setOpeningBalDate(e.currentTarget.value);
        setValue('openBalDate', e.currentTarget.value);
        // setSaveDisabled(false);

    }

    const balanceDues = (e) => {

        let dues = e.value
        let Dues = parseFloat(dues);
        retriveOpeningBalance[0].openBalDues = Dues;
        setRetriveOpeningBalance(retriveOpeningBalance);
        calculate_Total();


    }
    const balanceInterest = (e) => {

        let balanceInterest = e.value;
        let BalanceInterest = parseFloat(balanceInterest);
        retriveOpeningBalance[0].openBalInterest = BalanceInterest;
        setRetriveOpeningBalance(retriveOpeningBalance);
        calculate_Total();
    }
    const balanceDpc = (e) => {

        let balanceDpc = e.value;
        let BalanceDpc= parseFloat(balanceDpc);
        retriveOpeningBalance[0].openBalDpc = BalanceDpc;
        setRetriveOpeningBalance(retriveOpeningBalance);
        calculate_Total();
    }

    
    function calculate_Total() {
        let total = retriveOpeningBalance[0].openBalDues + retriveOpeningBalance[0].openBalInterest + retriveOpeningBalance[0].openBalDpc;
        setGrandTotal(total);
        // setValue('total', total);

    }
    const onNext = (e) => {
        // let insertIndex = personalDetails.findIndex(a => a.state === "New" || a.state === "modified");
        // if (insertIndex >= 0) {
        //   // alert("There are unsaved records in grid. Please save it before moving it to new tab")
        //   toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'There are unsaved records in grid. Please save it before moving it to new tab' });
    
        // }
        setActiveTab(3);
      }
    const onReset = () => {
        reset();
        setOpeningBalDate('');
        setGrandTotal('');
    }


    return (
        <div className="OpeningBalance">
            <Toast ref={toast} position="center" />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>

            <div class="container">

                <div class="container__half">
                    <div className="leftHeader">
                        <h3 style={{color:'gray', marginLeft:'200px'}}> Opening Balance Details</h3>
                    </div>
                    <div>
                        {/* <input type8="text" value={flatId} name="flatId" ref={register} hidden />    */}
                        <label className="OpeningBalanceLabel"> Opening Balance Date:</label>
                        <input type="Date" className="OpeningBDate" name="openBalDate" ref={register} value={openingBalDate} onChange={onChange} autoFocus={focusValue}/>
                    </div>
                    <div>
                        <label className="OpeningBalanceLabel" > Balance Type:</label>
                        <select className="PropertySelect" name="balType" ref={register}>
                            <option>Debit</option>
                            <option>Credit</option>
                        </select>
                    </div>
                    <div>

                        <label className="OpeningBalanceLabel" > Opening Balance Dues:</label>
                        {/* <input type="number" className="OpeningBText" name="openBalDues" ref={register}  onChange={BalanceDues}  /> */}
                        <InputNumber className="amount" value={openBalDues}  onChange={balanceDues} mode="currency" currency="INR" locale="en-US" autoComplete="off"  />
                    </div>
                    <div>
                        <label className="OpeningBalanceLabel" > Opening Balance Interest:</label>
                        {/* <input type="number" className="OpeningBText" name="openBalInterest" ref={register} onChange={BalanceInterest} /> */}
                        <InputNumber className="amount" value={openBalInterest} onChange={balanceInterest} mode="currency" currency="INR" locale="en-US" autoComplete="off"  />
                    </div>
                    <div>
                        <label className="OpeningBalanceLabel" > Opening Balance Dpc:</label>
                        {/* <input type="number" className="OpeningBText" name="openBalDpc" ref={register} onChange={BalanceDpc}/> */}
                        <InputNumber className="amount" value={openBalDpc} onValueChange={balanceDpc}  mode="currency" currency="INR" locale="en-US" autoComplete="off"  />
                    </div>
                    <div>
                        <label className="OpeningBalanceLabel" > Grand Total:</label>
                        <InputNumber readOnly className="amount" value={grandTotal}  mode="currency" currency="INR" locale="en-US"  />

                        {/* <input type="text" readOnly className="OpeningBText" name="openBalInterest" value={grandTotal} /> */}
                    </div>
                </div>



                <div class="container__half">
                    <div className="rightHeader">
                        <h3 style={{color:'gray', marginLeft:'250px'}}> Members Bank Details</h3>
                    </div>
                    <div>
                        <label className="OpeningBalanceLabel" > Bank:</label>
                        <input type="text" className="OpeningBText" autoComplete="off"  name="bank" ref={register} />
                    </div>
                    <div>
                        <label className="OpeningBalanceLabel" > Branch:</label>
                        <input type="text" className="OpeningBText" autoComplete="off" name="branch" ref={register} />
                    </div>
                </div>
            </div>
            <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>

                <div className="p-field">

                    <Button disabled={saveDisabled} label="Save" type="submit " onClick={handleSubmit(onSubmit)}  />
                </div>
                <div className="p-field">

                    <Button disabled={nextDisabled} label="Next" onClick={onNext} />
                </div>
                <div className="p-field ">
                    <Button label="Reset" onClick={onReset} disabled={resetDisabled} />
                </div>
            </div>




        </div>
    )
}
export default OpeningBalance;
