import { createContext } from "react";

const SocietyContext = createContext();

/*  const societyService = new SocietyService();

 const SocietyContextProvider=({children})=>{

const [societyId, setSocietyId]= useState();
const [societyName,setSocietyName]=useState();
const [billingDate,setBillingDate]=useState();
const [societyDetails, SetSocietyDetails] = useState([]);
const [userId, setUserId] = useState(21);
const [password, setPassword] = useState();

return (

    <SocietyContext.Provider value ={{
        societyId,
        societyName,
        billingDate,
        societyDetails,
        userId,
        password,
        loadValue: currentValue => {
            setSocietyId(currentValue)
        },
        loadSocietyName: currentValue => {
            setSocietyName(currentValue)
        },
        loadBillingDate: currentValue => {
            setBillingDate(currentValue)
        },
        loadSocietyData: currentValue => {
            SetSocietyDetails(currentValue)
        },
        loadUserId: currentValue => {
            setUserId(currentValue)
        },
        loadPassword: currentValue => {
            setPassword(currentValue)
        },
        
      }}>
        {children}
    </SocietyContext.Provider>
);

}; */

export default SocietyContext;