import { useForm } from 'react-hook-form';
import SocietyService from '../../Service/SocietyService';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { useEffect, useState,useRef } from 'react';
import Config from "../../Config.json";
import axios from 'axios';
import { Card } from '@material-ui/core';
import { Toast } from 'primereact/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './RegisterUser.css';
import { useDebounce } from 'use-debounce';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';







function RegisterUser({ props }) {

    const validationSchema = Yup.object().shape({
        // title: Yup.string()
        //     .required('Title is required'),
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last name is required'),
        userName: Yup.string()
            .required('UserName is required'),
        mobileNo: Yup.string()
            .required('Mobile Number is required'),
        emailId: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    
    });

    const { errors, register, reset, handleSubmit, setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const societyService = new SocietyService();
    const [societyName, setsocietyName] = useState([]);
    const [registerUser, setRegisterUsers] = useState([]);
    const [userName, setUserName] = useState([]);
    const [alertMessage, setAlertMessage] = useState([]);

    const [value]=useDebounce(userName,3000);
    const [Id, setId] = useState(null);
    const toast = useRef(null); 
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    let history = useHistory();
    const isAddMode = !Id;

   

    const onSubmit = (data) => {
      
        return isAddMode
            ? createRegisterUser(data)
            : updateRegisterUser(data);
  
    }
  
    const createRegisterUser = (data, e) => {
        setSpinnerVisible(true);
        if(data.accessLevel==='Admin')
        {
            data.accessLevel=0;
        }
        if(data.accessLevel==='ReadOnly')
        {
            data.accessLevel=1;
        }
        if(data.accessLevel==='ReadWrite')
        {
            data.accessLevel=2;
        }
     return axios.post(`${Config.apiUrl}/Authentication/RegisterUser`,data)
            .then(response => {
                if (response.data != null) {
                    toast.current.show({severity:'success', detail:response.data[0].msg, sticky:true});
                    history.push('/login');
                }
                else {
                    toast.current.show({severity: 'error', detail: response.data[0].msg});

                }
            })
            .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
              })
    }
  
    const updateRegisterUser = (data, e) => {
        setSpinnerVisible(true);
        return axios.put(`${Config.apiUrl}/Authentication/RegisterUser`,{headers:authHeader()}, data)
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data != null) {
                    toast.current.show({severity:'success', detail:response.data[0].msg, sticky:true});
                }
                else {
                    toast.current.show({severity: 'error', detail: response.data[0].msg});
                }
            })
            .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
              })
  
    }

    const onCancel=()=>{
        history.push('/login');
    }

    const toInputLowerCase=(e)=>{
        e.currentTarget.value=("",e.currentTarget.value).toLowerCase();
    }
       
    const InputMobileNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('mobileNo',onlyNums);
        
    }

    const onFindUserName=(e)=>{
        let value=e.currentTarget.value;
        setUserName(value);

        societyService.getRegisterUserDetails().then((data)=>{
            setRegisterUsers(data);
            let UserName=data.find(a=>a.userName=== value);
            console.warn(UserName);
            if(UserName !== undefined)
            {
                setAlertMessage("This Username is already register , please try Another one!");
                return;
            }
            else
            {
                setAlertMessage('');
            }
        })
    }
    useEffect(() => {

        
   

    societyService
    .getsocietyName()
    .then((data) =>
    setsocietyName(
        data.map(({ societyName }) => ({
          label: societyName,
          value: societyName,
        }))
      )
    );
},[]);



  const onlyNumbers = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setValue('mobileNo',onlyNums);
    
  }



    return (
        <form className="signUp">
            <Toast ref={toast} />   
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>        
             <h1>SIGN UP</h1>
            <Card className="signUpCard" style={{backgroundColor:'#c0c0c0', border:'1px solid black'}}>
            <div className="p-formgroup-inline" style={{marginLeft:'20px',marginTop:'10px'}}>
                <div className="p-field p-grid">    
                    <input type="text"  name="firstName" ref={register({ required: true })} placeholder="First Name" />
                </div>

                <div className="p-field p-grid">
                    <input type="text"  name="lastName" ref={register({ required: true })} placeholder="Last Name" />
                </div>
            </div>
            <div className="invalid-feedback">
                <div className="p-formgroup-inline">
                    <div style={{marginLeft:'20px'}}>{errors.firstName?.message}</div> 
                    <div style={{marginLeft:'60px'}}>{errors.lastName?.message}</div>   
                </div>                   
            </div>
                   
            <div className="p-formgroup-inline" style={{marginLeft:'20px'}}>
                <div className="p-field p-grid">
                    <input type="text" style={{width:'365px'}} name="userName" ref={register({ required: true })} onChange={onFindUserName} placeholder="Create Username" />
                </div>
            </div>
            <div className="invalid-feedback">
                    <div>{errors.userName?.message}</div>
                    {alertMessage}                    
            </div>

            <div className="p-formgroup-inline" style={{marginLeft:'20px'}}>
                <div className="p-field p-grid">
                    <input type="text" pattern="[0-9]*" name="mobileNo" onInput={InputMobileNo} ref={register({ required: true })} placeholder="Mobile Number" />
                </div>
            </div>
            <div className="invalid-feedback">
                    <div>{errors.mobileNo?.message}</div>                    
            </div>

            <div className="p-formgroup-inline" style={{marginLeft:'20px'}}>
                <div className="p-field p-grid">
                    <input type="text" style={{width:'365px'}} name="emailId" ref={register({ required: true })} onInput={toInputLowerCase} placeholder="Email-Id" />
                </div>
            </div>
            <div className="invalid-feedback">
                    <div>{errors.emailId?.message}</div>                    
            </div>

            <div className="p-formgroup-inline" style={{marginLeft:'20px'}}>
                <div className="p-field p-grid"> 
                <input type="password"  name="password" ref={register({ required: true })} placeholder="Password"  />
                </div>

                <div className="p-field p-grid"> 
                <input type="password"  name="confirmPassword" ref={register({ required: true })} placeholder="Confirm Password" />
                </div>
                
            </div>
            
            <div className="invalid-feedback">
                <div>{errors.password?.message}</div> 
                <div>{errors.confirmPassword?.message}</div>
            </div>

            <div className="p-formgroup-inline"style={{ justifyContent: "center", margin: "20px" }}>
            <Button label="Submit" type="Submit" style={{ marginRight: '20px' }} onClick={handleSubmit(onSubmit)} />
            <Button label="Cancel" onClick={onCancel}/>
            </div>




        </Card>
        </form>
    )
}
export default RegisterUser;

