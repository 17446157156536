import { Card } from '@material-ui/core';
import { Button } from 'primereact/button';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SocietyService from '../../Service/SocietyService';
import { SlideMenu } from 'primereact/slidemenu';
import { Avatar, withWidth } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { useForm } from "react-hook-form";
import './Profile.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ProfilePopup from './ProfilePopup';
import Config from "../../Config.json";
import authHeader from '../../authHeader';
import LoginPopup from './LoginPopup';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

function Profile(props) {
    let history = useHistory();
    var UserId = localStorage.getItem('userId');
    const [userName, setUserName] = useState();
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [mobileNo, setMobileNo] = useState();
    const societyService = new SocietyService();
    const [isProfileEdit, setProfileEdit] = useState(false);
    const [userData, setData] = useState();
    const [ButtonPopup, setButtonPopup] = useState(false);
    const [AddSocietyPopup, setAddSocietyPopup] = useState(false);


    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();


    useEffect(() => {
        societyService.getUserName(UserId).then(data => {
            setUserName(data[0]?.userName);
            setEmail(data[0]?.email);
            setName(`${data[0]?.firstName} ${data[0]?.lastName}`);
            setMobileNo(data[0]?.mobileNo);

            data.forEach(a => {
                if (a.action === '1') {
                    a.actionName = 'Approved';
                }
                else {
                    a.actionName = 'Pending';
                }
            })
            setData(data);

        }

        );
    }, []);

    const onEdit = (e) => {

        setButtonPopup(true);

    }

    const onCancel = (e) => {
        setProfileEdit(false);

    }

    const onAddSociety = (e) => {
        setAddSocietyPopup(true);
    }

    const handleImageChange = (response) => {

    }
    const actionTemplate = (rowData) => {
        return <span className={`action status-${(rowData.actionName ? rowData.actionName.toLowerCase() : '')}`}>{rowData.actionName}</span>;
    }


    return (
        <form className='Profile'>
            <Card style={{ height: 'auto' }}>
                <Card style={{ margin: '10px', padding: '20px', border: '1px solid black', height: 'auto' }}>
                    <div className="p-formgroup-inline">
                        <Card style={{ margin: '10px', padding: '20px', border: '1px solid black', height: 'auto', width: '300px' }}>
                            <Avatar icon="user" style={{ width: '200px', height: '200px' }} onClick={handleImageChange} />
                            <div className="p-d-flex">
                                <label>User Name - </label>
                                {userName}
                            </div>
                            <div className="p-d-flex" style={{ marginTop: '10px' }}>
                                <label>Email- </label>
                                {email}
                            </div>

                            <div className="p-d-flex" style={{ marginTop: '10px' }}>
                                <label>Name - </label>
                                {name}
                            </div>

                            <div className="p-d-flex" style={{ marginTop: '10px' }}>
                                <label>Mobile No - </label>
                                {mobileNo}
                            </div>
                            <div className="p-d-flex" style={{ marginTop: '20px', justifyContent: 'center' }}>
                                <Button type="button" icon="pi pi-user-edit" label="Edit Profile" style={{ height: '25px' }} onClick={handleSubmit(onEdit)} />
                            </div>
                        </Card>
                        <div>
                            <Card style={{ margin: '10px', padding: '20px', border: '1px solid black', height: '405px', width: '600px' }} >
                                <DataTable className="p-datatable-gridlines" scrollable scrollHeight='500px' value={userData} paginator rows={4}>
                                    <Column field="societyName" header="Society Name" style={{ width: '400px' }}></Column>
                                    <Column field="actionName" header="Action" body={actionTemplate}></Column>
                                </DataTable>
                                <div className="p-d-flex" style={{ marginTop: '20px', justifyContent: 'center' }}>
                                    <Button type="button" icon="pi pi-user-edit" label="Add New Society" style={{ height: '25px' }} onClick={handleSubmit(onAddSociety)} />
                                </div>
                                <LoginPopup
                                    trigger={AddSocietyPopup}
                                    setTrigger={setAddSocietyPopup}
                                    userId={UserId}
                                ></LoginPopup>
                            </Card>
                        </div>
                        {/* <div>
                            <Card style={{ margin: '10px', padding: '20px', border: '1px solid black', height: '405px', width: '300px' }}>
                                <div >
                                    <label>Report</label>
                                </div>

                            </Card>
                        </div> */}
                    </div>
                </Card>
                <ProfilePopup
                    trigger={ButtonPopup}
                    setTrigger={setButtonPopup}
                    userData={userData}
                >
                </ProfilePopup>
            </Card>
        </form>
    );
}
export default Profile;