import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import React, { useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import SocietyService from '../../Service/SocietyService';
import { BrowserRouter, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import Config from '../../Config.json';
import moment, { invalid } from 'moment';
import Popup from "reactjs-popup";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useState from 'react-usestateref';
import { InputText } from 'primereact/inputtext';
import './memberReceipt.css';
import authHeader from '../../authHeader';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { Color } from '@progress/kendo-drawing';

function MemberReceipt({ props }) {
    const [receipt, setReceipt] = useState({ wing: "", flatNo: "", prefix: "", name: "", date: "", dues: "", total: '', rctNo: "", amount: "", mode: "", interest: "", chqNo: "", lateCharges: "", bank: "", remarks: "" });
    const { errors, register, reset, handleSubmit, setValue } = useForm();

    const societyService = new SocietyService();
    const { editRctNo } = useParams();
    const toast = useRef(null);
    const societyName = localStorage.getItem('societyName');
    var societyId = parseInt(localStorage.getItem('societyId'));
    const isAddMode = !editRctNo;
    const [date, setDate] = useState();
    //var [Interest, setInterest] = useState(0);
   // var [Principal, setPrincipal] = useState(0);
    //const [receiptTotal, setReceiptTotal] = useState(0);
    const [Interest, setInterest] = useState(0);
    const [principal, setPrincipal] = useState(0);


    var [Dpc, setDpc] = useState();
    var [total, setTotal] = useState();
    const [wings, setWings] = useState([]);
    const [wing, setWing] = useState();
    const [Name, setName] = useState([]);
    const [flatNo, setFlatNo] = useState();
    const [AutoPopulateData, setAutoPopulateData] = useState();
    const [Mode, setMode] = useState([]);
    const [currentDate, setCurrentDate] = useState();
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [selectedFlatNoName, setSelectedFlatNoName] = useState();
    const [lastDpcAmount, setLastDpcAmount] = useState();
    const [RctNo, setRctNo] = useState([]);
    const [MREntry, setMREntry] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    // const [flatId, setFlatId] = useState(0);
    const [receiptId, setReceiptId] = useState(0);
    const [receiptTotal, setReceiptTotal] = useState(0);
    const [FormatedDate, setFormatedDate] = useState(null);
    const [GlData, setGlData] = useState([]);
    var [societyBank, setSocietyBankData] = useState([]);
    const memberReceiptDate = useRef(null);

    const [societyBankNames, setSocietyBanksName] = useState([]);
    const [selectedBankGlId, setSelectedBankGlId] = useState();
    const [selectedBank, setSelectedBank] = useState();
    var [memberReceipt, setMemberReceipt] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [focusValue, SetFocusValue] = useState(true);
    const [saveButtonLabel, setSaveButtonLabel] = useState('SAVE');
    const [flatId, setFlatId] = useState();
    const tableName = sessionStorage.getItem('tblName');
    const [amount, setAmount] = useState();


    // var flatId;

    const contentStyle = {
        maxWidth: "600px",
        width: "90%",
        height: "41%",
        marginLeft: "100px"
    };

    const onSave = (data, e) => {
        data.TransactionTable=tableName;
        return isAddMode
            ? creatememberReceipt(data)
            : updatememberReceipt(data);
    }

    const creatememberReceipt = (data, e) => {
        data.transactionType = "MR";
        data.societyId = societyId;
        data.societyName = societyName;
        data.flatId = flatId;
        data.glId = selectedBankGlId;
        // if (data.amount === '0') {
        //     alert("Enter Correct Amount");
        //     return;
        // }

        if (data.mode === '') {
            alert("Please Select Proper Mode");
            return;
        }

        if (data.chqNo === '') {
            data.chqNo = 0;
        }

        setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/Transaction/InsertMemberReceipt`, data, { headers: authHeader() })
            .then(response => {
                if (response.data[0].success) {
                    toast.current.show({ severity: 'success', detail: 'Data Saved Successfully', sticky:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg, sticky:true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                SetFocusValue(true);
            })
    }

    const updatememberReceipt = (data, e) => {
        setSpinnerVisible(true);
        // if (total !== (Dpc + Interest + Principal)) {
        //     toast.current.show({ severity: 'warn', sticky: true, detail: "Receipt Total and Interest+Dpc+Principal doesn't match" });
        //     setSpinnerVisible(false);
        //     return;
        // }
        if (data.chqNo === '') {
            data.chqNo = 0;
        }
        data.societyId = societyId;
        data.glId = selectedBankGlId;
        data.flatId = flatId;
        data.societyName = societyName;
        return axios.put(`${Config.apiUrl}/Transaction/UpdateMemberReceipt?id=${receiptId}`, data, { headers: authHeader() })
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Updated Successfully',sticky:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky:true});
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Network error" });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })
    }

    const onRemove=()=>{
        window.location.reload();
    }

    useEffect(() => {
        SetFocusValue(true);
        let d = moment(new Date()).format('YYYY-MM-DD');
        setDate(d);
        setValue('date', d);
        async function getData() {
            const personalDetails = await societyService.getPersonalDetails(societyId,tableName);
            SetPersonalDetailsData(personalDetails);

            const wings = await societyService.getWing(societyId);
            setWings(wings.map(({ wing }) => ({ label: wing, value: wing })));

            const glMasters = await societyService.getGlMasterData(societyId);
            setGlData(glMasters);

            const MRdata = await societyService.getMREntry(societyId,d,tableName);
            MRdata.forEach(item => {
                    if (item.chqNo === 0) {
                        item.chqNo = '---';
                    }
                    if (item.bankName === '') {
                        item.bankName = '---';
                    }
            });
            
            setMREntry(MRdata);



            if (!isAddMode) {
                setSaveButtonLabel("UPDATE");
                let MemberId = parseInt(editRctNo);
                societyService.GetMemberReceiptOnRctNo(societyId, editRctNo,tableName).then(memberReceiptData => {
                    console.warn("member receipt data fetch");
                    const fields = ['dateFormat', 'flatNo', 'wing', 'principal', 'interest', 'mode', 'rctNo', 'dues', 'chqNo', 'name', 'total', 'bankName', 'remarks', 'dpc'];
                    fields.forEach(field => setValue(field, memberReceiptData[0][field]));
                    memberReceipt = memberReceiptData;
                    setValue('date', memberReceiptData[0].dateFormat);
                    onModeChangeEditAction(memberReceiptData[0].mode);
                    setValue('lateChrgs', memberReceiptData[0].dpc.toFixed(2));
                    let int=parseFloat(memberReceipt[0].interest);
                    setInterest(int);
                    setDpc(memberReceiptData[0].dpc);
                    setPrincipal(memberReceiptData[0].principle)
                    setValue('amount', memberReceiptData[0].principle.toFixed(2));
                    setValue('interest', memberReceiptData[0].interest.toFixed(2));
                   setValue('total', memberReceiptData[0].total.toFixed(2));

                    setReceiptId(memberReceiptData[0].id)
                    setReceiptTotal(memberReceiptData[0].total);
                    setWing(memberReceiptData[0].wing);
                    setDate(memberReceiptData[0].dateFormat);
                    setTotal(memberReceiptData[0].total);
                    setFlatId(memberReceiptData[0].flatId);
                    // flatId=memberReceiptData[0].flatId
                }
                )
                    .catch((error) => {
                        toast.current.show({ severity: 'error', detail: error,sticky:true });
                    });
            }
            else {
                const rctNo = await societyService.getRctNo(societyId,tableName);
                setValue('rctNo', rctNo);
            }
        };
        getData();

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);
        }
    }, []); 

    const onDateChange = (e) => {
        let date = e.target.value;
        setDate(date);

        societyService.getMREntry(societyId,date,tableName).then (data=>{;
            data.forEach(item => {
                    if (item.chqNo === 0) {
                        item.chqNo = '---';
                    }
                    if (item.bankName === '') {
                        item.bankName = '---';
                    }
            });
            
            setMREntry(data);
        })
    }
    //const [Interest, setInterest] = useState(0);

    const onInterest = (e) => {
        
            let interestValue = e.currentTarget.value;
            if (interestValue !== '') {
                interestValue = parseFloat(interestValue);
            } else {
                interestValue = 0;
            }
    
            // Calculate principal based on the formula: principal = totalReceipt - interestValue - Dpc
            let principalValue = Math.abs(receiptTotal - interestValue - Dpc);
    
            // Update the form fields and state variables
           
            totalValidate(parseFloat(interestValue),Dpc,parseFloat(principalValue));
            setInterest(interestValue);
            setValue('interest', interestValue.toFixed(2));  
            setValue('amount', principalValue.toFixed(2));  // Automatically updating 'amount'
            setPrincipal(principalValue);
            
        
    };
    
    const onDpc = (e) => {
        
            let dpcValue = e.currentTarget.value;
            if (dpcValue !== '') {
                dpcValue = parseFloat(dpcValue);
            }
            else {
                dpcValue = 0;
            }
            // Calculate principal based on the formula: principal = totalReceipt - interest - dpcValue
            let principalValue = Math.abs(receiptTotal - Interest - dpcValue);
            totalValidate(Interest,parseFloat(dpcValue),principalValue);

            // Update the form fields and state variables
            setDpc(dpcValue);
            setValue('lateChrgs', dpcValue.toFixed(2));
            setValue('amount', principalValue.toFixed(2));  // Automatically updating 'amount'
            setPrincipal(principalValue);

        
    };

const onAmount = (e) => {
    let amountValue = e.currentTarget.value;
    if (amountValue !== '') {
        amountValue = parseFloat(amountValue);
        let interestValue = Math.abs(receiptTotal - Dpc - amountValue);
        totalValidate(parseFloat(interestValue),Dpc,amountValue);

        setValue('amount', amountValue.toFixed(2));
        setValue('interest', interestValue.toFixed(2));  

        setPrincipal(amountValue);
        
    }
};

const totalValidate=(interestValue,dpcValue,principleValue)=>{
    let allTotal=interestValue+dpcValue+principleValue;
    if(allTotal > receiptTotal){
        alert("Amount Is Mismatch!");
        setDisableButton(true);
        return;
    }
    else{
        setDisableButton(false);

    }
}

   

    const onTotal = (e) => {
        let totalReceipt = e.currentTarget.value;
        if (totalReceipt !== '') {
            totalReceipt = parseInt(totalReceipt);
            if (totalReceipt <= 0) {
                alert("Please Enter Correct Amount");
                totalReceipt = '';
            }
        }

        let currentTotal = totalReceipt;
        setValue('total', totalReceipt);
        setReceiptTotal(currentTotal);
        total = totalReceipt;

        // Calculate interest, principal, and update corresponding fields
        if (totalReceipt >= Interest) {
            totalReceipt = totalReceipt - Interest;

            // Update Interest field
            if (totalReceipt >= 0) {
                setValue('interest', Interest);
            }

            // Update DPC field
            if (totalReceipt > 0) {
                if (totalReceipt > Dpc) {
                    totalReceipt = Math.abs(totalReceipt - Dpc);
                    setValue('lateChrgs', parseFloat(Dpc.toFixed(2)));
                } else {
                    totalReceipt = Math.abs(totalReceipt);
                    setValue('lateChrgs', totalReceipt);
                    totalReceipt = Math.abs(currentTotal - Interest - totalReceipt);
                }
            }

            // Update Principal field
            if (totalReceipt > 0) {
                totalReceipt = Math.abs(totalReceipt);
                let totalReceiptValue=totalReceipt.toFixed(2);
                setValue('amount',totalReceiptValue);
                setPrincipal(parseFloat(totalReceiptValue));
            }
        } else {
            // Update Interest field
            setValue('interest', totalReceipt);

            // Handle the case where Interest is greater than totalReceipt
            if (Interest > totalReceipt) {
                setValue('lateChrgs', 0);
                setValue('amount', 0);
            }
        }

    }

    const onChangeWing = (e) => {
        let wing = e.currentTarget.value;
        setValue('wing', wing);
        setValue('flatNo','');
        setValue('name','');
        setWing(wing);
    }

    const onRowSelect = (event) => {
        let fNo = event.data.flatNo;
        let name = event.data.name;
        let wing = event.data.wing;
        setValue('flatNo', fNo);
        setValue('name', name);
        setValue('wing', wing);
        setWing(wing);
        let fId = event.data.id;
        setFlatId(event.data.id);
        if (isAddMode) {
            societyService.getFlatOutstandings(societyId, fId, date,tableName).then((data) => {
                if(data != null && data != undefined){
                setAutoPopulateData(data);
                setValue('dues', data.dues.toFixed(2));
                setValue('interest', data.interest.toFixed(2));
                setInterest(data.interest);
                setValue('amount', data.principal.toFixed(2));
                setPrincipal(data.principal)
                setValue('lateChrgs', data.dpc.toFixed(2));
                setDpc(data.dpc);
                let total = data.interest + data.principal + data.dpc;
                setValue('total', total);
                }
                else{
                    toast.current.show({severity:'info', detail:'No outstanding dues found for flat'});
                }
            }).catch(error=>{
                toast.current.show({severity:'error', detail:'Error in getting outstanding dues for flat'});  
            });
        }
    }

    const onChangeFlat = async (e) => {
        let flatNo = e.currentTarget.value;
        let flatId;
        if (flatNo !== '') {
            flatNo = parseInt(flatNo);
            setFlatNo(flatNo);
        }
        let flatRetrive = personalDetailsData.find(a => a.flatNo === flatNo);
        if (flatRetrive === undefined) {
            // alert("Please Enter Correct Flat No");
            return;
        }

        else {
            const data = await societyService.getName(flatNo, wing, societyId);
            if (data.length > 0) {
                setValue('name', data[0].name);
                setFlatId(data[0].id);
                flatId = data[0].id;
            }
            // else {
            //     setValue('name', '');
            //     setValue('dues', '');
            //     setValue('interest', '');
            //     setValue('amount', '');
            //     setValue('lateChrgs', '');
            //     setValue('total', '');
            // }
        }

        if (isAddMode) {
            societyService.getFlatOutstandings(societyId, flatId, date,tableName).then((data) => {
                setAutoPopulateData(data);
                setValue('dues', data.dues.toFixed(2));
                setValue('interest', data.interest.toFixed(2));
                setInterest(data.interest.toFixed(2));
                setValue('amount', data.principal.toFixed(2));
                setPrincipal(data.principal.toFixed(2))
                setValue('lateChrgs', data.dpc.toFixed(2));
                setDpc(data.dpc);
                let total = data.interest + data.principal + data.dpc;
                setValue('total', total.toFixed(2));
                setTotal(total);
            });
        }
    }


    const onModeChangeEditAction = async (e) => {
        let mode;
        mode = e;
        setMode(mode);
        if (mode.toUpperCase() === "CASH") {
            mode = "CASH BALANCE";
        }
        else {
            mode = "BANK BALANCE";
        }

        const banks = await societyService.GetSocietyBank(societyId, mode);
        setSocietyBankData(banks);
        societyBank = banks;
        var bankNames = [];
        banks.forEach(item => {
            bankNames.push(item.glTitle);
        })
        setSocietyBanksName(
            bankNames.map(glTitle => ({ label: glTitle, value: glTitle, })),
        )
        setSelectedBank(memberReceipt[0].glTitle);
        onChangeSelectedBank(memberReceipt[0].glTitle);
    }

    const onModeChange = async (e) => {
        let mode;
        mode = e.currentTarget.value;
        setMode(mode);
        if (mode.toUpperCase() === "CASH") {
            mode = "CASH BALANCE";
            setValue('chqNo', '');
            setValue('bankName', '');
        }
        else {
            mode = "BANK BALANCE";
        }
        const data = await societyService.GetSocietyBank(societyId, mode);
        setSocietyBankData(data);
        var bankNames = [];
        data.forEach(item => {
            bankNames.push({ 'glTitle': item.glTitle });
        })
        setSocietyBanksName(
            bankNames.map(({ glTitle }) => ({ label: glTitle, value: glTitle, })),
        )
        setSelectedBank(null);
    }

    const onChangeSelectedBank = (e) => {
        let selectBankLocal;
        if (e.currentTarget !== undefined) {
            selectBankLocal = e.currentTarget.value;
        }
        else {
            selectBankLocal = e;
        }
        setValue('glTitle', selectBankLocal);
        let selectedGlId = societyBank.find(a => a.glTitle === selectBankLocal);
        setSelectedBankGlId(selectedGlId.glId);
        setSelectedBank(selectBankLocal);
    }

    const InputFlatNo = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue('flatNo', onlyNums);
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    return (
        <div className="color memberReceipt" style={{ border: "1px solid grey", width: "auto"  }}>
            <h3 style={{display:'flex',justifyContent:'center', color:'Red'}}>Member Receipt
            
            </h3> 
            <Toast ref={toast} position={'center'} onRemove={onRemove} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}  />
            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label>Date:</label>
                    </div>
                    <div className=" p-col" >
                        <input type="Date" name="date" className="height" autoFocus    onChange={onDateChange} ref={register({ required: true })} />
                        {errors.date && (
                            <p className="errorMsg">Please select Date.</p>
                        )}
                    </div>
                </div>
                <div style={{ marginLeft: "120px" }}>
                    <Popup trigger={<a href="#">Search Flat No!</a>} position="right top" contentStyle={contentStyle}>
                        <DataTable value={personalDetailsData} selectionMode="single" selection={selectedFlatNoName} onRowSelect={onRowSelect} onSelectionChange={e => setSelectedFlatNoName(e.value)} dataKey="id" className="p-datatable-sm" scrollable scrollHeight='360px' style={{ overflow: 'hidden' }} width="300px" >
                            <Column field="wing" header="Wing" filter   ></Column>
                            <Column field="flatNo" header="Flat No" filter   ></Column>
                            <Column field="name" header="Name" filter></Column>
                        </DataTable>
                    </Popup>
                </div>
            </div>
            <div className="p-formgroup-inline">
                <div className="p-field p-grid" style={{ marginTop: "5px" }}>
                    <div className="MemerReceiptLabel">
                        <label>Wing:</label>
                    </div>
                    <div className=" p-col">
                        <select   name="wing" className="height" ref={register({ required: true })} onChange={onChangeWing} >
                            <option hidden value="">Select Wing</option>
                            {wings.map(({ label, value: wings }) => (
                                <option>
                                    {label}
                                </option>
                            ))}
                        </select>
                        {errors.wing && (
                            <p className="errorMsg" style={{ color: 'red', marginTop: '1px' }}>Please Select Wing.</p>
                        )}
                    </div>
                </div>

                <div className="p-field p-grid" style={{ marginTop: "5px" }}>
                    <div className="MemerReceiptSmallLabel">
                        <label>Flat No:</label>
                    </div>
                    <div className="p-col">
                        <input type="text" name="flatNo" className="height" ref={register({ required: true })} onChange={onChangeFlat} onInput={InputFlatNo} autoComplete="off" />
                        {errors.flatNo && (
                            <p className="errorMsg" style={{ color: 'red', marginTop: '1px' }}>Please Enter Flat No.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="p-fluid">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label >Name:</label>
                    </div>
                    <div className="p-col-12 p-md-2 ">
                        <input type="text" readOnly name="name" className="Name" ref={register || 'name'} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label >Receipt Total:</label>
                    </div>
                    <div className="p-col" >
                        <input type="text" name="total" className="height" ref={register} onChange={onTotal}    autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <div className="MemerReceiptSmallLabel">
                        <label>Dues:</label>
                    </div>
                    <div className="p-col" >
                        <input type="text" name="dues" readOnly className="height" style={{ backgroundColor: 'yellow', color: 'red' }} ref={register} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label>Interest:</label>
                    </div>
                    <div className="p-col">
                        <input type="text" name="interest" className="height" ref={register} onChange={onInterest}    autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <div className="MemerReceiptSmallLabel">
                        <label>Rct No:</label>
                    </div>
                    <div className="p-col" >
                        <input type="text" readOnly name="rctNo" className="height" ref={register({ required: true })} />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label>Dpc:</label>
                    </div>
                    <div className="p-col">
                        <input type="text" name="lateChrgs" className="height" ref={register} onChange={onDpc}    autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <div className="MemerReceiptSmallLabel">
                        <label>Mode:</label>
                    </div>
                    <div className="p-col">
                        <select  name="mode" className="height" ref={register({ required: true })} onChange={onModeChange} >
                            <option hidden value="">Select Mode</option>
                            <option>Cash</option>
                            <option>Bank</option>
                        </select>
                        {errors.mode && (
                            <p className="errorMsg" style={{ color: 'red', marginTop: '-1px' }}>Please select Mode.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label>Principal:</label>
                    </div>
                    <div className="p-col">
                        <input type="text" name="amount" min="1" className="height" ref={register({ required: true })} onChange={onAmount} autoComplete="off" />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <div className="MemerReceiptSmallLabel">
                        <label>Chq No:</label>
                    </div>
                    <div className="p-col" >
                        <input type="text" name="chqNo" disabled={Mode === 'Cash'} className="height" ref={register} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-fluid">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label > Society Bank Name:</label>
                    </div>
                    <div className="p-col-12 p-md-2 ">
                        <select style={{ width: '422px' ,borderRadius:'6px', borderColor:'lightgrey'  }} name="glTitle" className="height" ref={register({ required: true })} onChange={onChangeSelectedBank} value={selectedBank}  >
                            <option hidden value=""  >Select Bank Name</option>
                            {societyBankNames.map(({ label }) => (
                                <option>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {errors.glTitle && (
                    <p className="errorMsg" style={{ color: 'red', marginTop: '-15px', marginLeft: '180px' }}>Please Select society Bank.</p>
                )}
            </div>

            <div className="p-fluid">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label>Bank Name:</label>
                    </div>
                    <div className="p-col-12 p-md-2 ">
                        <input type="text" name="bankName" disabled={Mode === 'Cash'} className="height" style={{ width: '422px' }} ref={register} autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field p-grid">
                    <div className="MemerReceiptLabel">
                        <label>Remarks:</label>
                    </div>
                    <div className="p-col">
                        <input type="text" name="remarks" className="height" style={{ width: '422px', height: '50px' }} ref={register({ required: true })} autoComplete="off" />
                        {errors.remarks && (
                            <p className="errorMsg" style={{ color: 'red', marginTop: '-1px' }}>Please enter Remark.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="p-formgroup-inline" style={{ backgroundColor: "Info",marginLeft:'18rem', width: '60%' }}>
                <div className="p-field">
                    <Button label={saveButtonLabel} style={{ width: "100px", margin: '5px', marginTop: '10px', height: '30px' }} type="submit " onClick={handleSubmit(onSave)} disabled={DisableButton} />
                    <Button label="CLOSE" style={{ width: "100px", height: '30px',margin:'5px' }} type="submit " disabled={DisableButton} />
                </div>
            </div>

            <div className="p-formgroup-inline">
                <div className="p-field">
                    <DataTable value={MREntry} header={header} className="p-datatable-sm" width="500px" paginator rows={5} globalFilter={globalFilter} emptyMessage="No customers found." >
                        <Column field="dateFormat" header="Date"  ></Column>
                        <Column field="wing" header="Wing"></Column>
                        <Column field="flatNo" header="Flat No" ></Column>
                        <Column field="name" header="Name" style={{width:'200px'}}></Column>
                        <Column field="interest" header="Interest"></Column>
                        <Column field="lateChrgs" header="Dpc"></Column>
                        <Column field="amount" header="Principle"></Column>
                        <Column field="receiptTotal" header="Receipt Total"></Column>
                        <Column field="rctNo" header="Rct No"></Column>
                        <Column field="mode" header="Mode"></Column>
                        <Column field="chqNo" header="Cheque No"></Column>
                        <Column field="bankName" header="Bank Name"></Column>
                        <Column field="remarks" header="Narration"></Column>
                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default MemberReceipt;
