import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './SupplimentaryBillGenerationPlan.css';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import SocietyService from '../../Service/SocietyService';
import moment from 'moment';
import { Popup } from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import axios from 'axios';
import Config from '../../Config.json';
import authHeader from '../../authHeader';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { date } from 'yup/lib/locale';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';

const schema = yup.object().shape({
    billDate: yup.date().required(),
    amount: yup.number().required(),
});
function SupplementaryBillGenerationPlan() {
    const [billDate, setBillDate] = useState("");
    const [dueDate, setDueDate] = useState("");

    const [selectedBillPlan, setSelectedBillPlan] = useState(null);
    const [popupVisible, setpopupVisible] = useState(false);
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [selectedGlidGltitle, setSelectedGlidGltitle] = useState();
    const [GlTitle, setGlTitle] = useState("");
    var societyId = parseInt(localStorage.getItem('societyId'));
    const societyService = new SocietyService();
    const [glIdData, setGlIdData] = useState([]);
    const { register, handleSubmit, errors, clearErrors, setValue, reset } = useForm();
    const [DisableButton, setDisableButton] = useState(false);
    const [selectedGlId, setSelectedGlId] = useState();
    const [flats, setFlats] = useState([]);
    const tableName = sessionStorage.getItem('tblNameSupplimentary');
    var [GenerationId, setMaxGenerationId] = useState();
    const [BillNo, setBillNo] = useState();
    const [uiData, setUiData] = useState();
    var [MaxBillNo, setMaxBillNo] = useState();
    const [interestType, setInterestType] = useState();
    const [amountType, setAmountType] = useState('PerFlat');
    const [noInterestValue, setNointerestValue] = useState(false);
    const [supplimentaryBillPlans, setSupplimentaryBillPlans] = useState(false);
    const [billGenerated, setBillGenerated] = useState(true);

    const getPlans = (() => {
        societyService.GetSupplimentaryBillPlans(societyId)
            .then(data => {
                //data=data.filter(a=>)
                setSupplimentaryBillPlans(data)
            });

    })
    useEffect(() => {
        societyService.GetIsBiilingGls(societyId)
            .then(data => {
                //data=data.filter(a=>)
                setGlIdData(data)
            });

        getPlans();

        // GetFlatsData();

        /*   societyService.GetSupplimentaryBillSetting(societyId).then(response => {
              if (response != undefined) {
                  setInterestType(response.interestType);
                  setValue('interestType', response.interestType);
                  setValue('fixAmount', response.fixAmount);
                  setValue('interestRate', response.rateInterestPA);
              }
          },
              error => {
  
              }
          )
   */
    }, []);


    const onRowSelect = (event) => {
        let fNo = event.data.flatNo;
        let name = event.data.name;
        let wing = event.data.wing;
        setValue('flatNo', fNo);
        setValue('name', name);
        setValue('wing', wing);
    }

    const onRowSelectGlid = (e) => {
        let glid = e.data.glId;
        let glTitle = e.data.glTitle;
        setSelectedGlId(glid);
        setGlTitle(glTitle);
        setValue('glTitle');
        setValue('billName', glTitle + "(Please append Custom name here)");
    }

    const onChangeAmunt = (e) => {

        setValue('amount', e.currentTarget.value);
    }
    const onChangeRemarks = (e) => {

        setValue('remarks', e.currentTarget.value);
    }
    const onChangeBillDate = (e) => {

        setValue('billDate', e.currentTarget.value);
    }
    const onChangeDueDate = (e) => {

        setValue('dueDate', e.currentTarget.value);
    }

    function saveData() {

        setSpinnerVisible(true);
        let billNo = MaxBillNo;

        let billItem = {};

        billItem.societyId = societyId;
        billItem.glid = selectedGlId;
        billItem.billingDate = uiData.billDate;
        billItem.dueDate = uiData.dueDate;
        billItem.BillingCycle = uiData.billingCycle;
        billItem.InterestType = uiData.interestType;
        billItem.InterestRate = uiData.interestRate;
        billItem.AmountType = amountType;
        billItem.Amount = uiData.amount;
        billItem.Remarks = uiData.remark;
        billItem.billName=uiData.billName;
        // billItem.InterestJobRunType= uiData.InterestJobRunType;
        billItem.IsActive = true;


        // billArray.push(billItem);
        return axios.post(`${Config.apiUrl}/SupplimentaryBillGeneration/InsertSupplimentaryBillPlan`, billItem, { headers: authHeader() })
            .then(response => {
                // console.warn(data);
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Updated Successfully', sticky: true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Network error", sticky: true });
            })
            .finally(() => {
                setSpinnerVisible(false);
                resetUI();
                getPlans();
            })
    }

    function cancelData(data) {


    }

    const resetUI = (() => {
        let fields = ['billDate','glTitle','billName', 'dueDate', 'billingCycle', 'interestType', 'interestRate', 'amountType', 'amount', 'remark'];
        fields.forEach(item => {
            setValue(item, "");
        })
    })
    const onSubmitButton = (data, e) => {
        data.glId = selectedGlId;
        // data.billDate=billDate;
        // data.dueDate=dueDate;
        console.warn(data);
        setUiData(data);
        setpopupVisible(true);

    }
    const GlcontentStyle = {
        maxWidth: "600px",
    };

    const onDelete = (event) => {
        toast.current.show({
          severity: 'warn', sticky: true, content: (
            <div className="p-flex p-flex-column" style={{ flex: '1' }}>
              <div className="p-text-center">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                <h4>Are you sure?</h4>
                <p>Confirm to Delete</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button  label="Yes" className="p-button-success" onClick={onYes} />
                </div>
                <div className="p-col-6">
                  <Button  label="No"  className="p-button-secondary" onClick={onNo} />
                </div>
              </div>
            </div>
          )
        });
      }
    
      const onYes = (event) => {
        try {
          toast.current.clear();
          
          let index = supplimentaryBillPlans.findIndex(a => a.id === selectedBillPlan.id);
          let id = selectedBillPlan.id;
        
          setSpinnerVisible(true);
          let url=`${Config.apiUrl}/SupplimentaryBillGeneration/deleteSupplimentaryBillPlan?id=${id}&transactionTableName=${tableName}`;
          axios.post(url, { headers: authHeader() })
            .then(response => {
              if (response.data.success) {
                toast.current.show({ severity: 'success', detail: "Data Deleted Successfully" });
              }
              else {
                toast.current.show({ severity: 'error',sticky:true, detail: response.data.msg });
              }
            })
            .catch(error => {
              toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
              setSpinnerVisible(false);
              getPlans();
            })
        }
        catch (error) {
          console.log(error);
          toast.current.show({ severity: 'error', detail: error });
        }
      }
    
      const onNo = () => {
        toast.current.clear();
        return;
      };
    const header = (
          <div>
             <Button icon="pi pi-check" type='button' className="btnApprove" label="DELETE" onClick={onDelete} disabled={DisableButton} />

        </div>
      );
    return (
        <form>
            <Toast ref={toast} position={'center'} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <ConfirmPopup target={document.getElementById('button')} visible={popupVisible} onHide={() => setpopupVisible(false)} message="Are you sure you want to proceed?"
                icon="pi pi-exclamation-triangle" accept={saveData} reject={cancelData} />
            <div className="SupplimentaryBillGenerationPlan">
                <span style={{ fontFamily: 'verdena', fontWeight: 'bold', padding: '4px', fontSize: '16px', marginBottom: '50px' }}>Supplementary Bill Generation  Plan</span>
                <div style={{ display: 'flex' }}>
                    <div className="SupplimentaryBillGenerationPlan"  >

                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}> GL Title</label>
                            <div className='col'>
                                {/* <input type="text" name="glTitle" ref={register} value={GlTitle} autoComplete="off" /> */}
                                <input type="text" name="glTitle" ref={register({ required: { value: true, message: "You must select GL Titile" } })} value={GlTitle} />

                                {errors?.glTitle && <small className="text-danger">{errors?.glTitle && errors.glTitle.message}
                                </small>
                                }
                            </div>
                            <Popup trigger={<a href="#" className='col-fixed' style={{ width: '200px', marginLeft: '50px' }}> Search Gl-Id </a>} position="right top" contentStyle={GlcontentStyle}>
                                <DataTable value={glIdData} selectionMode="single" selection={selectedGlidGltitle} onRowSelect={onRowSelectGlid} onSelectionChange={e => setSelectedGlidGltitle(e.value)} dataKey="glId" className="p-datatable-sm" scrollable scrollHeight='280px' style={{ overflow: 'hidden' }} width="250px" >
                                    <Column field="glId" header="Gl Id" filter></Column>
                                    <Column field="glTitle" header="GL Title" filter></Column>
                                </DataTable>
                            </Popup>
                        </div>

                        <div className="field grid p-formgroup-inline" >

                            <label className='col-fixed' style={{ width: '130px' }}> From Bill Date:</label>
                            <div className="col ">
                                <input type="Date" name="billDate" onChange={(e) => setBillDate(e.value)} ref={register({ required: { value: true, message: "You must select bill date" } })} />
                                {errors?.billDate && <small className="text-danger">{errors?.billDate && errors.billDate.message}
                                </small>
                                }
                            </div>

                            <label className='col-fixed' style={{ width: '130px', marginLeft: '100px' }}> Bill Plan:</label>
                            <div className="col ">
                                <input type="text" style={{ width: '400px' }} name="billName" ref={register({ required: { value: true, message: "You must enter BillName" } })} />
                                {errors?.billName && <small className="text-danger">{errors?.billName && errors.billName.message}
                                </small>
                                }
                            </div>

                        </div>
                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}>Due Date:</label>
                            <div className="col ">
                                <input type="Date" name="dueDate" onChange={(e) => setDueDate(e.value)} ref={register({ required: { value: true, message: "You must select due date" } })} />
                                {errors?.dueDate && <small className="text-danger">{errors?.dueDate && errors.dueDate.message}
                                </small>
                                }
                            </div>
                        </div>

                        <div className="field grid p-formgroup-inline" style={{ marginTop: '5px' }}>
                            <label className='col-fixed' style={{ width: '130px' }}> Billing Cycle:</label>
                            <div className="col ">
                                <select

                                    name="billingCycle"
                                    ref={register}
                                    style={{ width: '175px', height:'25px',borderRadius:'6px'}}
                                >
                                    <option>Monthly</option>
                                    <option>Quaterly</option>
                                    <option>Half_Yearly</option>
                                    <option>Yearly</option>
                                </select>
                            </div>
                            {/* {!noInterestValue && <> */}

                        </div>
                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}>Interest Type:</label>
                            <div className="col ">
                                <select

                                    name="interestType"
                                    ref={register}
                                    style={{ width: '175px', height:'25px',borderRadius:'6px'}}
                                // onChange={onLatePaymentType}
                                >
                                    <option>None</option>
                                    <option>Simple_Interest</option>
                                    <option>Compound_Interest</option>
                                    <option>Fixed_Amount</option>
                                </select>
                            </div>
                        </div>
                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}>Intesrest Rate:</label>
                            <div className="col ">
                                <input type="text" name="interestRate" ref={register({ required: true })} />
                            </div>
                        </div>

                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}> AmountType</label>
                            <div className='col'>
                                <div className="field-radiobutton" style={{ margin: '2px', padding: '2px' }}>
                                    <RadioButton inputId="city1" name="amountType" value="PerFlat" onChange={(e) => setAmountType(e.value)} checked={amountType === 'PerFlat'} />
                                    <label htmlFor="city1">PerFlat</label>
                                </div>
                                <div className="field-radiobutton" style={{ margin: '2px', padding: '2px' }}>
                                    <RadioButton inputId="city2" name="amountType" value="PerSqft" onChange={(e) => setAmountType(e.value)} checked={amountType === 'PerSqft'} />
                                    <label htmlFor="city2">PerSqft</label>
                                </div>
                                {/*  <div className="field-radiobutton" style={{ margin: '2px', padding: '2px' }}>
                            <RadioButton inputId="city3" name="amountType" value="FixedAmount" onChange={(e) => setAmountType(e.value)} checked={amountType === 'FixedAmount'} />
                            <label htmlFor="city3">FixedAmount</label>
                        </div> */}
                            </div>
                        </div>
                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}>Amount</label>
                            <div className='col'>
                                <input type="number" name="amount" ref={register({ required: { value: true, message: "You must enter Amount" } })} />
                                {errors.amount && <small className="text-danger">{errors?.amount && errors.amount.message}
                                </small>
                                }
                            </div>
                        </div>

                        <div className="field grid p-formgroup-inline">
                            <label className='col-fixed' style={{ width: '130px' }}>Remarks</label>
                            <div className='col'>
                                <input type="text" name="remark" ref={register({ required: true })} onChange={onChangeRemarks} />
                            </div>
                        </div>



                    </div>

                </div>
                <div className="p-formgroup-inline" >
                    <div className="p-field">
                        <Button label="GENERATE"  type="submit" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} onClick={handleSubmit(onSubmitButton)} />
                        <Button label="RESET" style={{ width: "140px", marginLeft: '25px', marginTop: '25px' }} onClick={resetUI}/>
                    </div>
                </div>
            </div>
            <div>
                <DataTable value={supplimentaryBillPlans} className="p-datatable-gridlines" tableStyle={{width:'auto'}} scrollable scrollHeight='500px' header={header} selection={selectedBillPlan} onSelectionChange={e => setSelectedBillPlan(e.value)} >
                    <Column selectionMode="single" ></Column>
                    <Column field="billName" header="PlanName" filter></Column>
                    <Column field="glTitle" header="GlTitle" filter></Column>
                    <Column field="glId" header="GL_ID"  filter></Column>
                    <Column field="billingDate" header="BillingDate" filter></Column>
                    <Column field="dueDate" header="DueDate" filter></Column>
                    <Column field="billingCycle" header="BillingCycle" filter></Column>
                    <Column field="interestType" header="InterestType" filter></Column>
                    <Column field="RateInterestPA" header="Interest Rate" filter></Column>
                    <Column field="amountType" header="AmountType" filter></Column>
                    <Column field="amount" header="Amount" filter></Column>
                    <Column field="remarks" header="Remarks" filter></Column>
                    <Column field="interestJobRunType" header="InterestJobRunType" filter></Column>
                    <Column field="IsActive" header="isActive" filter></Column>

                </DataTable>
            </div>
        </form >

    );
}
export default SupplementaryBillGenerationPlan;