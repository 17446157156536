// import { useState } from "react"
const initialState=0;

const BillPrintData=(Billstate=initialState,action)=>{
    // const [billPrintData,setBillPrintData]=useState([]);
   switch(action.type){
       case "BillPrint" : return initialState;
       default :return Billstate;
   }

}
export default BillPrintData;