import {Link} from 'react-router-dom';
import{BrowserRouter,useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SocietyDetails from './SocietyDetails';
import WingMaster from './WingMaster';
import BillingDetails from './BiilingDetails';
import Nav from '../../Nav';
import TabContext from '@material-ui/lab/TabContext';
import BillFootnote from './BillFootnote';
import { TabView, TabPanel } from 'primereact/tabview';
import SupplementaryBillGeneration from '../Transactions/SupplimentaryBillGeneration';
import SupplimentaryBillSettings from './SupplimentaryBillSettings';

function SocietyMasters() {
  const [activeIndex, setActiveIndex] = useState(0);
    const[selectedTab,setSelectedTab]=React.useState(0);
    const [societyId, setSocietyId] = React.useState(0);
    const [societyName,setSocietyName]=React.useState('');
    const { id } = useParams();
    const [value, setValue] = React.useState(0); 
    const handleChange=(event,newValue) => {
        console.warn(newValue);
        setSelectedTab(newValue);
    };

  return (
    <div>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Society Details">
          <SocietyDetails setSocietyId={setSocietyId} id={id} setActiveTab={setActiveIndex} setSocietyName={setSocietyName}/>
        </TabPanel>
        <TabPanel header="Wing Master">
          <WingMaster societyId={societyId} id={id} setActiveTab={setActiveIndex}/>
        </TabPanel>
        <TabPanel header="Billing Details">
          <BillingDetails societyId={societyId} id={id} setActiveTab={setActiveIndex}/>
        </TabPanel>
        <TabPanel header="BillFootnote">
          <BillFootnote societyId={societyId} id={id} setActiveTab={setActiveIndex} societyName={societyName}/>
        </TabPanel>
        <TabPanel header="Supplementary Bill Details">
          <SupplimentaryBillSettings societyId={societyId} id={id} setActiveTab={setActiveIndex} societyName={societyName}/>
        </TabPanel>
        </TabView>
    </div>
  );
}

export default SocietyMasters;

