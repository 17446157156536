import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from 'Service/SocietyService';
import axios from "axios";
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../authHeader';
//import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import { useForm } from 'react-hook-form';
import { resetIdCounter } from 'react-tabs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './AddRole.css';


var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
var societyId = parseInt(localStorage.getItem('societyId'));

function AddPrivileges() {
   
    const [roleName, setRoleName] = useState('');
    const [description, setDescription] = useState('');
    const [destinationData, setDestinationData] = useState([]);
    const [rolesDetails, setRolesDetails] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const toast = useRef(null);
    const [GmData, setGmData] = useState([]);
  const [id, setId] = useState(null);
  const [PrivilegesDetails, setPrivilegesDetails] = useState([]);
    
  // const [selectedProduct3, setSelectedProduct3] = useState(null);
  const [selectedProducts3, setSelectedProducts3] = useState(null);
  const [Ddata, Deletedata] = useState(null);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const [DisableButton, setDisableButton] = useState(true);
  const [stickyMode, setStickyMode] = useState(true);
  localStorage.setItem('uiName', 'Group Master');
  const [blockedPanel, setBlockedPanel] = useState(false);
 // const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);

    const societyService = new SocietyService();

    useEffect(() => {
        societyService.getprivilegesDetails()
            .then(data => {
                setPrivilegesDetails(data);
            });

    }, []);
    const { errors, register, reset ,handleSubmit } = useForm( );
    const onDelete = (event) => {
      if(selectedRadioData != null)
      {
      toast.current.show({
        severity: 'warn', sticky: true, content: (
          <div className="p-flex p-flex-column" style={{ flex: '1' }}>
            <div className="p-text-center">
              <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
              <h4>Are you sure?</h4>
              <p>Confirm to Delete</p>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
              </div>
              <div className="p-col-6">
                <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
              </div>
            </div>
          </div>
        )
      });
     }
     else{
      toast.current.show({ severity: 'warn',sticky:true, detail: "PLEASE SELECT ROW"});
      return;
     }
    }
  
    const onYes = () => {
      toast.current.clear();
  
      // Assuming that PrivilegesDetails is an array of objects with unique IDs
      selectedRadioData.forEach((d) => {
          let id = d.id;
          let data = PrivilegesDetails.filter((b) => b.id !== id);
          setPrivilegesDetails(data);
          setSpinnerVisible(true);
  
          axios
              .delete(`${Config.apiUrl}/UserPrivileges/deletprivileges?id=${id}`, {
                  headers: authHeader(),
              })
              .then((response) => {
                  console.log(response);
                  if (response.data != null) {
                      toast.current.show({
                          severity: 'success',
                          sticky: true,
                          detail: response.data[0].msg,
                      });
  
                      // Refresh the entire page
                      window.location.reload();
                  } else {
                      toast.current.show({
                          severity: 'error',
                          sticky: true,
                          detail: response.data[0].msg,
                      });
                  }
              })
              .catch((error) => {
                  console.log(error);
              })
              .finally(() => {
                  setSpinnerVisible(false);
              });
      });
  };
  
    const onNo = () => {
      toast.current.clear();
      return;
    };
    const onSubmit = (data, e) => {
      // Add validation to check if fields are not empty
      if (!data.name || !data.description) {
          toast.current.show({
              severity: 'warn',
              sticky: true,
              detail: 'Role Name and Description are required.',
          });
          return;
      }
  
      // Check for duplicate role name
      const isDuplicate = PrivilegesDetails.some(item => item.name.toLowerCase() === data.name.toLowerCase());
  
      if (isDuplicate) {
          toast.current.show({
              severity: 'error',
              sticky: true,
              detail: 'Privileges Name already exists. Please choose a different name.',
          });
          return;
      }
  
      data.societyId = societyId;
      console.log(data);
  
      setSpinnerVisible(true);
  
      axios
          .post(`${Config.apiUrl}/UserPrivileges/addprivileges`, data, {
              headers: authHeader(),
          })
          .then((response) => {
              console.log(response);
              if (response.data[0].success) {
                  toast.current.show({
                      severity: 'success',
                      sticky: true,
                      detail: response.data[0].msg,
                  });
  
                  // Retrieve updated data after successful save
                  societyService.getprivilegesDetails().then((updatedData) => {
                      setPrivilegesDetails(updatedData);
                  });
  
                  reset(); // Reset form after successful submission
  
                  // Refresh the entire page after a short delay (adjust the delay as needed)
                  setTimeout(() => {
                      window.location.reload();
                  }, 1000);
              } else {
                  toast.current.show({
                      severity: 'error',
                      sticky: true,
                      detail: response.data[0].msg,
                  });
              }
          })
          .catch((error) => {
              toast.current.show({
                  severity: 'error',
                  sticky: true,
                  detail: 'ERROR IN SAVING DATA',
              });
          })
          .finally(() => {
              setSpinnerVisible(false);
              SetFocusValue(true);
          });
  };
  
    return  (
        <div className="AddRoleUi">
          <Card className="BackgroundColor" >
          <Toast ref={toast} position="center" />
          
        <div class="container">
    
          <div class="container__half">
            <div className="GroupMaster" >
           <h2>Privileges Details</h2>
              <div style={{marginLeft:'8%',marginTop:'-20px'}}>
                <label>Privileges Name:</label>
                <input type="text" name="name" className='Rolename' ref={register} autoFocus={focusValue} autoComplete="off" />
              </div><div style={{marginLeft:'8%'}}>
                <label>Description:</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="text" name="description" className='Description' ref={register}  autoComplete="off" />
                <div className="invalid-feedback"></div>

                 </div>
                 <div className="button">
             <Button icon="pi pi-save"label="SAVE" type="text"  onClick={handleSubmit(onSubmit)}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <Button icon="pi pi-trash" label="DELETE" value="Delete" tooltip="Delete" className="btn" onClick={onDelete}  />
   
                     </div>

                     <div class="container__half">
        <div className="DataTable">
        <BlockUI blocked={blockedPanel}>
          <DataTable value={PrivilegesDetails} className="p-datatable-gridlines"  selection={selectedRadioData} paginator rows={5} editMode="row"  onSelectionChange={e => setSelectedRadioData(e.value)}    >
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            <Column field="id" header="Id" style={{ width: "70px" }} filter ></Column>

            <Column field="name" header="Privileges Name" style={{ width: "100px" }} filter ></Column>
            <Column field="description" header="Description" style={{ width: "200px" }} filter ></Column>
          </DataTable>
          </BlockUI>
        </div>
      </div>
            </div>
    
    
           
          </div>
    
    
         
        </div>
        </Card>
        </div>
    
    
      )
    }
    
export default AddPrivileges;
