/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { Divider } from '@material-ui/core';
import React, { useState, useEffect, useStateRef, useRef } from 'react';
import styles from './MaintenanceDetails.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SocietyService from "../../Service/SocietyService";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import axios from 'axios';
import Config from '../../Config.json'
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { Dropdown } from 'primereact/dropdown';



function MaintenanceDetails({setActiveTab }) {
    const societyService = new SocietyService();
    // const isAddMode = !id;
    const toast = useRef(null);


    var [billedGLs, setBilledGLs] = useState([]);
    var [maintainanceData, setMaintainanceData] = useState([]);
    const [amount, setAmount] = useState([]);
    const [maintainance, setMaintainanceEntry] = useState([]);
    var [mergedSet, setMergedSet] = useState([]);
    //  var [mergedSet, setMergedSet] = useState([]);
    const [area, setAreaEntry] = useState([]);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [DisableButton, setDisableButton] = useState(true);
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [ownerName,setSelectedOwnerName]=useState()
    const [flatNo,setFlatNo]=useState();
    const [flatId,setFlatId]=useState();
    const { errors, register, reset, handleSubmit, setValue } = useForm(
        // {resolver: yupResolver(validationSchema)}
    );


    useEffect(() => {
        societyService.getFlat(societyId)
            .then(data => {
                data.forEach(a => {
                    a.unitNo = `${a.wing}-${a.flatNo}`;
                })
               
                SetPersonalDetailsData(data)

            }
            );

        

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }

    }, []);



    // 


    const AmountEditor = (productKey, props) => {
        return <InputNumber value={props.rowData['amount']} onValueChange={(e) => onAmountValueChange(productKey, props, e.value)} mode="currency" currency="INR" locale="en-US" />
    }

    const onAmountValueChange = (productKey, props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        let existingIndex = maintainanceData.findIndex(g => g.glId === updatedProducts[props.rowIndex]['glId']);

        if (existingIndex >= 0) {
            updatedProducts[props.rowIndex].state = "modified";
        }
        else {
            updatedProducts[props.rowIndex].state = "New";
        }

        let totalAmt = 0;
        let isChanged = false;
        updatedProducts.forEach(a => {
            try {
                if (a.state === 'New' || a.state === "modified") {
                    isChanged = true;
                }
                if (a.amount != '' && a.amount != undefined) {
                    totalAmt += a.amount;
                }
            } catch { }

        });
        setAmount(totalAmt);
        if (isChanged) {
            setDisableButton(false);
        }
    }

    const onSubmit = (e) => {
        setDisableButton(true);
        try {
            mergedSet.forEach(m => {
                m.flatId = flatId;
                if (m.amount === undefined || m.amount === null) {
                    m.amount = 0;
                }
                if (m.state === "New") {
                    createMaintainance(m)
                }
                else if (m.state === "modified") {
                    updateMaintainance(m);
                }

            });
        }
        catch (error) {

            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: "Error in submit" });
        }
    }

    const createMaintainance = (d) => {
        setSpinnerVisible(true);
        let url = `${Config.apiUrl}/Maintainance/Insert`;
        console.warn(url);
        axios.post(url, d, { headers: authHeader() })
            .then(response => {
                console.log(response);
                if (response.data.success === true) {

                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
                    // window.location.reload();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })

    }

    const updateMaintainance = (m) => {

        setSpinnerVisible(true);
        axios.put(`${Config.apiUrl}/Maintainance/update?id=${flatId}`, m, { headers: authHeader() })
            .then(response => {

                console.log(response)
                if (response.data[0].success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                    window.location.reload();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })
    }

    const onChangeWing = (e) => {
        let selectedFlat = e.value;
        setFlatNo(selectedFlat);
        setSelectedOwnerName(selectedFlat.name);
        setFlatId(selectedFlat.id);

        societyService.getGlMasterIsBilling(societyId).then(data => {
            let tempArray = [];
            data.forEach(d => {
                tempArray = [...tempArray, d];
                // d.amount=0;
            })
            setBilledGLs(tempArray);
            console.log(billedGLs);

            if (selectedFlat.id != undefined) {
                societyService.getMaintainanceDetailsOnID(selectedFlat.id, societyId).then(mainData => {
                    maintainanceData = [...mainData];
                    setMaintainanceData([...maintainanceData]);
                    console.log(maintainanceData);
                    let array = [];
                    tempArray.forEach(a => {
                        let index = maintainanceData.findIndex(b => b.glId === a.glId);
                        if (index >= 0) {
                            let item = {
                                'glId': a.glId, 'glTitle': a.glTitle, 'amount': maintainanceData[index].amount,
                                'state': 'unmodified'
                            };
                            array.push(item);
                        }
                        else {
                            let item = { 'glId': a.glId, 'amount': a.amount, 'glTitle': a.glTitle, 'flatId': a.flatId, 'state': 'unmodified' };
                            array.push(item);
                        }

                    });
                    let tmpAmt = 0;
                    maintainanceData.forEach(a => {
                        tmpAmt += a.amount;
                    })
                    setAmount(tmpAmt);
                    mergedSet = [...array];
                    setMergedSet(mergedSet);

                });

            }
            else {
                let array = [];
                tempArray.forEach(a => {
                    let item = { 'glId': a.glId, 'amount': a.amount, 'glTitle': a.glTitle, 'flatId': a.flatId, 'state': 'unmodified' };
                    array.push(item);
                })
                mergedSet = [...array];
                setMergedSet(mergedSet);
            }
        });
    }


    {
        const footer = (
            <div className="table-footer">
                <label> Grand Total:</label>
                <input type2="number" value={amount} />
                <Button label="SUBMIT" onClick={handleSubmit(onSubmit)} style={{ margin: "5px" }} disabled={DisableButton} />
            </div>
        );
        return (
            <form className="MaintenanceForm">
                <Toast ref={toast} position="center" />
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                <div className="Form-inline">
                    <div >
                        <div>
                            <Dropdown className='Dropdown' filter filterBy="unitNo" value={flatNo} options={personalDetailsData} onChange={onChangeWing} id="FlatNo" name="unitNo" ref={register} optionLabel="unitNo" placeholder="Select Flat" />
                            {ownerName}
                        </div>
                    </div>

                    <div className="MaintenanceDataTable"  >
                        <DataTable value={mergedSet} header="Maintenance Details" footer={footer} className="p-datatable-sm"  >

                            <Column field="glId" header="GL Id"></Column>
                            <Column field="glTitle" header="GL Title" ></Column>

                            <Column field="amount" header="Amount" editor={(props) => AmountEditor('amount', props)}></Column>


                        </DataTable>
                    </div>


                </div>

            </form>
        )
    }
}
export default MaintenanceDetails;