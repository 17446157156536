import { Button } from 'primereact/button';
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var DuesRegular = [];
var selectedDate;
var selectedWing;
var principalSum;
var interestSum;
var dpcSum;
var totalAmountSum;
var view;
var grandTotal;


// class Example extends React.Component {
function DuesRegularBill({ props }) {

  const toast = useRef(null);
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const societyService = new SocietyService();
  const [selectedType, setSelectionType] = useState();
  const [Stype, setType] = useState("Tariff Wise");
  const [regularDues, setRegularDues] = useState([]);
  DuesRegular = regularDues;
  const [amount, setAmount] = useState();
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [DateWithTime, setDateWithTime] = useState();
  const [Operator, setOperator] = useState();
  const [wings, setWings] = useState([]);
  const [Wing, setWing] = useState();
  const [InstallmentData, setInstallmentsdata] = useState([]);
  const [InstallmentsNumber, setInstallmentNumber] = useState(1);
  const [pSum, setPrincipalSum] = useState();
  principalSum = pSum;
  const [interest, setInterestSum] = useState();
  interestSum = interest;
  const [dpc, setDpcSum] = useState();
  dpcSum = dpc;
  const [totalAmount, setTotalAmountSum] = useState();
  totalAmountSum = totalAmount;
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const tableName = sessionStorage.getItem('tblName');
  const [viewButton, setViewButton] = useState(false);
  const [grandtotal, setGrandTotal] = useState(0);
grandTotal = grandtotal;
  view = viewButton;

  selectedDate = date;
  selectedWing = Wing;

  const onSelectionTypeChange = (e) => {
    setSelectionType(e.value);
    setType(e.value.name);
    if (e.value.name === 'Tariff Wise') {
      societyService.getInstallment(societyId).then(data => {
        setInstallmentsdata(data);
      })
    }
  }

  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

    societyService
      .getWing(societyId)
      .then((data) => {
        data.splice(0, 0, { wing: 'All' })
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),

        )
      }, []);


  }, []);

  const selectionType = [
    { name: 'Tariff Wise' },
    { name: 'Amount Wise' },
  ];

  const onAmountChange = (e) => {
    let Amount = e.currentTarget.value
    setAmount(Amount);
  }

  const onDateChange = (e) => {
    let Date = e.currentTarget.value
    setDate(Date);
    // Date = moment(Date).format('YYYY-MM-DD');
    // setDateWithTime(Date);

  }

  const onOperatorChange = (e) => {
    let operator = e.currentTarget.value
    setOperator(operator);
  }
  const onNumberOfInstallments = (e) => {
    let INumber = e.currentTarget.value
    setInstallmentNumber(INumber);
  }

  const onChangeWing = (e) => {
    let wing = e.currentTarget.value;
    setWing(wing);
  }


  const viewBills = (e) => {
    setSpinnerVisible(true);
    let undefinedList = [];
    if (date === undefined) {
      undefinedList.push({ label: "select date" });
    }

    if (selectedType === undefined) {
      undefinedList.push({ label: "select amount type" });

    }
    if (Wing === undefined) {
      undefinedList.push({ label: "select wing" });
    }
    if (Operator === undefined) {
      undefinedList.push({ label: "select operator" });

    }
    if (selectedType?.name === "Tariff Wise") {
      if (InstallmentsNumber === undefined) {
        undefinedList.push({ label: "select Installment number" });
      }
    }
    if (selectedType?.name === "Amount Wise") {
      if (amount === undefined) {
        undefinedList.push({ label: "enter amount" });

      }
    }
    let message = "Please";
    undefinedList.forEach(a => {

      message = message + "\n " + a.label + ",";
    })
    if (undefinedList.length > 0) {

      toast.current.show({ severity: 'warn', sticky: true, detail: message });
      setSpinnerVisible(false);
    }
    else {
      setViewButton(true);
      setIsPrintDisabled(false);

      societyService.getRegularDues(societyId, date, Wing,tableName).then(data => {
        // setRegularDues(data);
        if (Stype === 'Tariff Wise') {
          data.forEach(d => {
            let fId = d.flatId;
            let item = InstallmentData.find(i => i.flatId = fId);
            d.installmentAmount = item.installmentAmount;


          })
          if (Operator === '>=') {
            let totalDues = data.filter(a => a.totalAmount >= a.installmentAmount * InstallmentsNumber);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));


          }
          if (Operator === '>') {
            let totalDues = data.filter(a => a.totalAmount > a.installmentAmount * InstallmentsNumber);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));


          }
          if (Operator === '<=') {
            let totalDues = data.filter(a => a.totalAmount <= a.installmentAmount * InstallmentsNumber);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));

          }
          if (Operator === '<') {
            let totalDues = data.filter(a => a.totalAmount < a.installmentAmount * InstallmentsNumber);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));

          }
          if (Operator === '=') {
            let totalDues = data.filter(a => a.totalAmount == a.installmentAmount * InstallmentsNumber);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));

          }
        }
        else {
          if (Operator === '>=') {
            let totalDues = data.filter(a => a.totalAmount >= amount);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));

          }
          
            if (Operator === '>') {
              let totalDues = data.filter(a => a.totalAmount > amount);
              setSerialNo(totalDues);
              setRegularDues(totalDues);
              totalCalculate(totalDues);
              setGrandTotal(calculateGrandTotal(totalDues));

             }
          
          if (Operator === '<=') {
            let totalDues = data.filter(a => a.totalAmount <= amount);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));


          }
          if (Operator === '<') {
            let totalDues = data.filter(a => a.totalAmount < amount);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));


          }
          if (Operator === '=') {
            let totalDues = data.filter(a => a.totalAmount == amount);
            setSerialNo(totalDues);
            setRegularDues(totalDues);
            totalCalculate(totalDues);
            setGrandTotal(calculateGrandTotal(totalDues));

          }
        }
      }
      ).catch(error=>{
        toast.current.show({ severity: 'warn', sticky: true, detail:"Error in creating Report" });
      
      }).finally(()=>{
        setSpinnerVisible(false);
      })
    }
  }

  const totalCalculate = (data) => {

    setPrincipalSum(data && data.reduce((a, v) => a + v.principle, 0));
    setInterestSum(data && data.reduce((a, v) => a + v.interest, 0));
    setDpcSum(data && data.reduce((a, v) => a + v.dpc, 0));
    setTotalAmountSum(data && data.reduce((a, v) => a + v.totalAmount, 0));
}

const calculateGrandTotal = (data) => {
  return (
    (data && data.reduce((a, v) => a + parseFloat(v.principle), 0)) +
    (data && data.reduce((a, v) => a + parseFloat(v.interest), 0)) +
    (data && data.reduce((a, v) => a + parseFloat(v.dpc), 0)) +
    (data && data.reduce((a, v) => a + parseFloat(v.totalAmount), 0))
  ).toFixed(2);
};

  const setSerialNo = (data) => {
    let srNo = 1;
    data.forEach(d => {
      d.serialNo = srNo;
      srNo++;
    });
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  return (
    <div className='DuesRegularBill'>
    <div class="card" style={{ backgroundColor: 'black', height: '70px' }}>
      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
      <div className='p-formgroup-inline'>
        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <input type="Date" style={{ height: '35px' }} name="Date" value={date} onChange={onDateChange} />
        </div>

        <div style={{ margin: '15px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <Dropdown style={{ height: '34px' }} value={selectedType} options={selectionType} onChange={onSelectionTypeChange} id="selectionType" name="SelectionType" optionLabel="name" placeholder="Select Amount Type" />
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <select style={{ width: '150px', height: '35px' }} name="wing" onChange={onChangeWing} >
            <option hidden value="">Select Wing</option>
            {wings.map(({ label, value: wings }) => (
              <option key={wings} value={wings}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div style={{ margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <select style={{ height: '35px', width: '120px' }} onChange={onOperatorChange} value={Operator}>
            <option hidden value="">Select Operator</option>
            <option>&gt;</option>
            <option>&gt;=</option>
            <option>&lt;</option>
            <option>&lt;=</option>
            <option>=</option>

          </select>
        </div>
        <div style={{ display: Stype === 'Tariff Wise' ? 'none' : 'block', margin: '10px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <input type="text" style={{ height: '35px' }} value={amount} onChange={onAmountChange} placeholder="Enter Amount" />
        </div>
        <div style={{ display: Stype === 'Amount Wise' ? 'none' : 'block', margin: '10px', marginTop:'7px' }}>
          <span style={{ color: 'red', fontSize: '26px' }}>*</span>
          <input type="number" style={{ height: '35px', marginLeft: '10px' }} value={InstallmentsNumber} onChange={onNumberOfInstallments} placeholder="Enter Notice No" />
          {/* <select style={{ height: '35px', width: '140px' }} onChange={onNumberOfInstallments} value={InstallmentsNumber}>
            <option hidden value=""> No of Installments</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select> */}
        </div>

        <div style={{ margin: '10px' }}>
          <Button label="VIEW" onClick={viewBills} />
        </div>
        <div style={{ margin: '10px' }}>
          <Button onClick={handlePrint} label="PRINT" disabled={isPrintDisabled} />
        </div>
      </div>
      <Report ref={componentRef} />
    </div>
    </div>

  );
}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: A4;
    margin: 7mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class Report extends React.Component {
  render() {



    return (

      <div style={{ height: '100%', display: !view ? 'none' : 'block' }}>
        <style>{portrait()}</style>
        {/* {DuesRegular.map((data, index)=> */}
        <div>
        
          <div className='SocietyName'>
            <div class="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>
                {societyData[0]?.address}
              </text>
            </div>
            <div class="font">
              <label>Regular Dues From Members</label>
            </div>
            <div className="dateFont">
              <label>As On Date {moment(selectedDate).format('DD-MM-YYYY')}</label>
            </div>
            <div class="dateFont">
              <label>Wing {selectedWing}</label>
            </div>
          </div>
        

          <div>
            <div class="DuesRegularTable">
              <table>
                <thead>
                  {/* <div className='headerColor'> */}
                  <tr className='headerColor'>
                    <th className='headerColor' style={{ width: '7%', textAlign: 'center' }}>Sr</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Flat No</th>
                    <th style={{ width: '23%', textAlign: 'center' }}>Member Name</th>
                    <th style={{ width: '45%', textAlign: 'center' }} colspan="3">Dues</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Total Amount</th>
                  </tr>
                  {/* </div> */}
                  <tr className='headerColor'>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style={{ textAlign: 'center',width: '15%', padding: '5px' }}>Principal</th>
                    <th style={{ textAlign: 'center',width: '15%', padding: '5px' }}>Interest</th>
                    <th style={{ textAlign: 'center',width: '15%', padding: '5px' }}>DPC</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody >
                  {
                    DuesRegular.map((item) => (
                      <tr key={item}>
                        <td style={{ textAlign: 'center', padding: '5px', width:'5%' }}>{item.serialNo}</td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>{item.wing}-{item.flatNo}</td>
                        <td style={{ textAlign: 'left', padding: '5px' }}>{item.name}</td>
                        <td style={{ textAlign: 'right', padding: '5px' }}>{item.principle.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', padding: '5px' }}>{item.interest.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', padding: '5px' }}>{item.dpc.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', padding: '5px' }}>{item.totalAmount.toFixed(2)}</td>
                      </tr>
                    ))
                  }
                  <tr className='headerColor'>
                    <th style={{ width: '7%', textAlign: 'center',padding: '5px' }}>Total</th>
                    <th style={{ width: '10%', textAlign: 'center',padding: '5px' }}></th>
                    <th style={{ width: '23%', textAlign: 'center',padding: '5px' }}></th>
                    <th style={{ width: '15%',textAlign: 'right', padding: '5px' }}>{principalSum?.toFixed(2)}</th>
                    <th style={{ width: '15%',textAlign: 'right', padding: '5px' }}>{interestSum?.toFixed(2)}</th>
                    <th style={{ width: '15%',textAlign: 'right', padding: '5px' }}>{dpcSum?.toFixed(2)}</th>
                    <th style={{ width: '15%', textAlign: 'right',padding: '5px' }}>{totalAmountSum?.toFixed(2)}</th>
                  </tr>
                  <tr style={{ width: '10%', textAlign: 'center',padding: '5px' }}>Grand Total: {grandTotal}
</tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
        {/* )}  */}
      </div>


    )
  }

};

export default DuesRegularBill;



