import SocietyService from '../Service/SocietyService';


// var SetSocietyDetails=[];
const initialState ="";


const societyData=(state=initialState,action)=>{
    const { type, payload } = action;

    const societyService = new SocietyService();

    //  const [billPrintData,SetSocietyDetails]=useState([]);
   switch(type){
       case "SocietyDetailsData" : 
       return payload;

            // return {...state,SetSocietyDetails:action.payload};
    //    case "getSocietyData" : 
    //         return SetSocietyDetails;
       default :return state;
   }

}
export default societyData;