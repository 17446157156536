import { Button } from 'primereact/button';
// import '../Report/Register.css';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import SocietyService from '../../../Service/SocietyService';
import moment, { invalid } from 'moment';
import { useForm } from 'react-hook-form';
import '../Register/Register.css';






var societyId = parseInt(localStorage.getItem('societyId'));
var societyData = [];
var NominationRegisterData = [];

// class Example extends React.Component {
function NominationRegister({ props }) {

  
 
  const { errors, register, reset, handleSubmit, setValue } = useForm();
  const [SocietyDetails, SetSocietyDetailsData] = useState([]);
  societyData = SocietyDetails;
  const [NominationRegisterReport, setNominationRegisterReportData] = useState([]);
  NominationRegisterData = NominationRegisterReport;
  const societyService = new SocietyService();


  
  useEffect(() => {
    societyService.getSocietyDetailsOnID(societyId).then(data => {
      SetSocietyDetailsData(data);
    });

    societyService.getNominationRegisterData(societyId).then(data => {
      var srNo=1; 
      data.forEach(d=> {
        d.serialNo = srNo++;
      })
      setNominationRegisterReportData(data);
    });

  }, []);


  


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (

    <div class="card" style={{ backgroundColor: 'black', height: '45px' }}>
      <div className='p-formgroup-inline'>
        <Button onClick={handlePrint} label="PRINT" style={{ marginLeft: '10px' }} />
      </div>
      <Report ref={componentRef} />


    </div>

  );
}


class Report extends React.Component {
  render() {



    return (

      <div style={{ height: '100%' }}>
        {/* {DuesRegular.map((data, index)=> */}
        <div>
          <div className='SocietyName'>
            <div class="font"><text>{societyData[0]?.societyName}</text></div>
            <div>
              <label>Registration No:</label><text>{societyData[0]?.registerNo}</text>
              <label> Dated:</label><text>{moment(societyData[0]?.registerDateFormat).format('DD-MM-YYYY')}</text>
            </div>
            <div>
              <text>
                {societyData[0]?.address}
              </text>
            </div>
            <div class="font">
              <label>Register Of Nomination</label>
            </div>
          </div>

          <div>
            <div class="NominationRegisterTable">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%', textAlign: 'center' }}>Sr</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Flat No</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Name of member making nomination</th>
                    <th style={{ width: '15%', textAlign: 'center' }} >Date of Nomination</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Name of nominess</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Date of the Managing Committee Meeting in which the nomination was recorded</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Date of any subsequent revocation of nomination</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>Remarks</th>


                  </tr>
                </thead>
                <tbody>
                  {
                    NominationRegisterData.map((item) => (
                      <tr key={item}>
                        <td>{item.serialNo}</td>
                        <td>{item.wing}-{item.flatNo}</td>
                        <td>{item.name}</td>
                        <td>{item.dateFormat}</td>
                        <td>{item.nomineeName}
                        {item.address}</td>
                        <td>{item.meetingDateFormat}</td>
                        {/* <td>{item.totalAmount}</td> */}
                      </tr>
                    ))
                  }

                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>


    )
  }

};

export default NominationRegister;



