import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './MemberOpeningBalanceUi.css';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import SocietyService from '../../Service/SocietyService';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import axios from 'axios';
import Config from '../../Config.json';
import authHeader from '../../authHeader';
import { Toast } from 'primereact/toast';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

function MemberOpeningBalance() {
    const toast = useRef(null);
    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [date, setDate] = useState();
    const [transactionDate, setTransactionDate] = useState();
    const [DisableButton, setDisableButton] = useState(true);
    const [memberOpeningBalanceData, setMemberOpeningBalance] = useState(null);
    const { errors, register, reset, handleSubmit, setValue } = useForm();
    const [sumOfPrinciple, setSumOfPrinciple] = useState(0);
    const [sumOfInterest, setSumOfInterest] = useState(0);
    const [sumOfDpc, setSumOfDpc] = useState(0);
    const [sumOfTotal, setSumOfTotal] = useState(0);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const tableName = sessionStorage.getItem('tblName');

    const columns = [
        { field: 'srNo', header: 'Sr No' },
        { field: 'unitNo', header: 'Flat No' },
        { field: 'name', header: 'Member Name' },
        { field: 'dateFormat', header: 'Transaction Date' },
        { field: 'bal_Type', header: 'Type' },
        { field: 'principle', header: 'Principle' },
        { field: 'interest', header: 'Interest' },
        { field: 'dpc', header: 'Dpc' },
        { field: 'total', header: 'Total' }
    ];

    useEffect(() => {
        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);
        }
    }, []);

    const GetMemberData = () => {
        let d = moment(date).format('YYYY-MM-DD');
        societyService.GetMemberOpeningBalance(societyId, d,tableName).then((data) => {
            let serialNo = 0;
            data.forEach(a => {
                serialNo++;
                a.srNo = serialNo;
                a.unitNo = `${a.wing}-${a.flatNo}`;
                a.status='unchanged';
                calculateTotal(a);
            })
            if (data.length === 0) {
                toast.current.show({ severity: 'warn', detail: "No Data Available on this Date" });
            }
            ;
            setMemberOpeningBalance(data)
            Totals(data);
        });
   //     console.warn(memberOpeningBalanceData);
    }

    const calculateTotal = (a) => {
        a.total = a.principle + a.interest + a.dpc;
    }

    const Totals = (data) => {
        setSumOfPrinciple(data && data.reduce((a, v) => a + v.principle, 0));
        setSumOfInterest(data && data.reduce((a, v) => a + v.interest, 0));
        setSumOfDpc(data && data.reduce((a, v) => a + v.dpc, 0));
        setSumOfTotal(data && data.reduce((a, v) => a + v.total, 0));
    }

    const dataTableEdit = {
        'data': setMemberOpeningBalance
    };

    const types = [
        { label: 'Debit', value: 'Debit' },
        { label: 'Credit', value: 'Credit' },
    ];

    const inputTextEditor = (productKey, props, field) => {
        if (field != 'bal_Type' && field != 'name' && field != 'unitNo' && field != 'dateFormat' && field != 'total' && field != 'srNo') {
            return <InputNumber minFractionDigits={2} value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.value)} mode="currency" currency="INR" locale="en-US" />;
        }
        else if (field == 'bal_Type') {
            return <Dropdown value={props.rowData[field]} options={types} optionLabel="label" optionValue="value"
                onChange={(e) => onEditorValueChange(productKey, props, e.value)} placeholder="Select a type" />;
        }
        else {
            return;
        }
    }

    const onEditorValueChange = (productKey, props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        updatedProducts[props.rowIndex]['status'] = 'changed';
        dataTableEdit[`${productKey}`](updatedProducts);
        calculateTotal(updatedProducts[props.rowIndex]);
        Totals(updatedProducts);
    }

    const onEditorCancel = (e) => {
        const { rowIndex: index, field } = e.columnProps;
        let data = [...memberOpeningBalanceData];
        data[index][field] = editingCellRows[index][field];
        delete editingCellRows[index][field];
        setMemberOpeningBalance(data);
    }

    let editingCellRows = {};
    let originalRows = {};

    const onEditorInit = (e) => {
        const { rowIndex: index, field, rowData } = e.columnProps;
        if (!editingCellRows[index]) {
            editingCellRows[index] = { ...rowData };
        }
        editingCellRows[index][field] = memberOpeningBalanceData[index][field];
    }

    const onSubmit = (e) => {
        setSpinnerVisible(true);
        let tDateStr = sessionStorage.getItem('fyFromDate');
        let tDate = moment(tDateStr).format('YYYY-MM-DD');
        let data = memberOpeningBalanceData.filter(a=>a.status==='changed');
        data.forEach(a => {
            a.tDate = tDate;
            
        })
       /*  if (transactionDate !== undefined) {
            let d = moment(transactionDate).format('YYYY-MM-DD');
            data.forEach(a => {
                a.tDate = d;
                
            })
        }
        else{
            data.forEach(a => {
                let d = moment(a.dateFormat).format('YYYY-MM-DD');
                a.tDate = d;
                
            })
        } */

        data.forEach(a => {
            a.transactionTable= sessionStorage.getItem('tblName');
            if (a.id === 0) {
                a.state = "Insert";
            }
            else{
                a.state="Update";
            }
            
        })
       
        data.societyId = societyId;
        return axios.put(`${Config.apiUrl}/OpeningBalance/UpdateMemberOpeningBalance`, data, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data != null) {
                    toast.current.show({ sticky:true, severity: 'success', detail: "Data Updated Successfully" });
                }
                else {
                    toast.current.show({ sticky:true, severity: 'error', detail: "Data failed to Update" });
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSpinnerVisible(false);
                GetMemberData();
            });
    }

    /* const rowClassChild = (data) => {

        if(data.status==="changed"){
          return  "Changed";
        }
        
    } */

    const rowClassChild = (data) => {
        return {
            'Changed': data.status != 'unchanged'
        };
    };

    const header = (
        <div className="table-header">
            <div>
                {/* <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon dateFormat="dd-mm-yy"></Calendar> */}
                <Button label="LOAD" style={{ width: "140px", marginLeft: '25px', marginTop: '25px', height: '30px' }} type="submit " disabled={DisableButton} onClick={handleSubmit(GetMemberData)} />
            </div>
            <div>
                {/* <Calendar value={transactionDate} onChange={(e) => setTransactionDate(e.value)} showIcon dateFormat="dd-mm-yy"></Calendar> */}
                <Button label="UPDATE" value="Edit" onClick={handleSubmit(onSubmit)} disabled={DisableButton} />
            </div>
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={sumOfPrinciple} />
                <Column footer={sumOfInterest} />
                <Column footer={sumOfDpc} />
                <Column footer={sumOfTotal} />
            </Row>
        </ColumnGroup>
    );

    return (
        <form className="MemberOpeningBalanceNew">
            <div className="MemberOpeningBalanceNew">
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                <Toast ref={toast} position="center" />
                <Card className="MemberOpeningBalanceNew" >
                    <DataTable value={memberOpeningBalanceData} rowClassName={rowClassChild} editMode="cell" header={header} footerColumnGroup={footerGroup} scrollable scrollHeight='300px' style={{ overflow: 'hidden' }}>
                        {
                            columns.map(col => {
                                const { field, header } = col;
                                return <Column key={field} field={field} filter header={header} editor={(props) => inputTextEditor('data', props, field)}
                                    onEditorInit={onEditorInit} onEditorCancel={onEditorCancel} />
                            })
                        }
                    </DataTable>
                </Card>
            </div>
        </form>
    );
}
export default MemberOpeningBalance;