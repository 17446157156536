import "primeflex/primeflex.css";
import "./BillGeneration.css";
import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card, Hidden } from "@material-ui/core";
import { Dropdown } from 'primereact/dropdown';
import SocietyService from "../../Service/SocietyService";
import { useContext } from "react";
import { SocietyContext } from "../../SocietyContext";
import axios from "axios";
import Config from "../../Config.json";
import { Toolbar } from 'primereact/toolbar';
import { useHistory } from 'react-router-dom';
import { date } from "yup/lib/locale";
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { usePopup } from "react";
import Popup from "reactjs-popup";
import ReactToPrint from "react-to-print";
import Report from '../Report/Report';
import ReportPopup from "../../Popups/ReportPopup";
import { Toast } from 'primereact/toast';
import authHeader from "../../authHeader";
import '../loader.css';
import { setLocale } from "yup";
import EmailPopup from "../../Popups/EmailPopup";
import Enumerable from 'linq';

function BillGeneration({ props }) {
  let history = useHistory();
  const societyId = parseInt(localStorage.getItem('societyId'));
  const societyName = localStorage.getItem('societyName');
  const tableName = sessionStorage.getItem('tblName');
  const societyService = new SocietyService();
  var converter = require('number-to-words');
  var [billedGLs, setBilledGLs] = useState([]);
  const [trueResponseData, setTrueResponse] = useState([]);
  const [isFlatChange, setIsFlatChange] = useState(false);
  const [billData, setBillData] = useState([]);
  const [saveMaintainance, setSaveMaintainance] = useState([]);
  const [wings, setWings] = useState([]);
  const [wingName, setWingName] = useState("");
  const [arrayData, setArrayData] = useState([]);
  const [cMaintainance, setCurrentMaintainance] = useState();
  const [suppMaintenenceData, setSuppMaintenenceData] = useState({});
  const [isSuppBillGenerated, setIsSuppBillGenerated] = useState(false);
  const [CurrInterest, setCurrInterest] = useState(0);
  const [oSPrincipal, setOutStandingPrincipal] = useState(0);
  const [oSInterest, setOutstandingInterest] = useState(0);
  const [oSTotal, setOutStandingTotal] = useState(0);
  const [dpc, setDpc] = useState(0);
  const [oSDpc, setOsDpc] = useState(0);
  const [CurrBillTotal, setCurrBillTotal] = useState(0);
  const [tPayable, setTotalPayable] = useState(0);
  const [flatNo, setFlatNo] = useState();
  var [index, setIndex] = useState(0);
  const [backButton, setBackDisabled] = useState(false);
  const [nextButton, setNextDisabled] = useState(false);
  const [initialForm, setInitialForm] = useState(true);
  const [SaveButton, setSaveDisabled] = useState(false);
  const [billDate, setBillDate] = useState();
  const [billFor, setBillFor] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [billingCycle, setBillingCycle] = useState();
  const [interestType, setInterestType] = useState();
  const [lateInterestType, setLateInterestType] = useState();
  const [NoMaintainance, setNoMaintainance] = useState([]);
  const [SrNo, setSrNo] = useState();
  const [BillNo, setBillNo] = useState();
  var [MaxBillNo, setMaxBillNo] = useState();
  var [GenerationId, setMaxGenerationId] = useState();
  const [ownerName, setOwnerName] = useState();
  const [billForMonth, setBillForMonth] = useState();
  const [area, setArea] = useState();
  const [month, setMonth] = useState([]);
  const [billingTemplate, setBillTemplate] = useState([]);
  const [societyDetailsData, SetSocietyDetailsData] = useState([]);
  const [billFootNote, setBillFootNote] = useState([]);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [value1, setValue1] = useState(0);
  const [MailPopup, setMailPopup] = useState(false);
  const [billGenerated, setBillGenerated] = useState(true);
  const [progressspinner, setProgressSpinner] = useState(false);
  const toast = useRef(null);
  const interval = useRef(null);
  const [Generateloading, setGenerateloading] = useState(undefined);
  const [Saveloading, setSaveloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);
  const [MissingListOfMonth, setMissingListOfMonth] = useState();
  const [loadingLabelText, setLoadingLabelText] = useState('Loading..');

  const { errors, register, reset, handleSubmit, setValue } = useForm(
  );
  const [generatedbillsData, setGeneratedbillsData] = useState();
  var MonthList;
  var Year;
  var Month;
  var FromYear;
  var FromMonth;

  useEffect(() => {
    setGenerateloading(false);
    setcompleted(true);
    setIsFlatChange(false);
    getSocietyData();
    GetBillingDetails();

    societyService
      .getWing(societyId)
      .then((data) =>
        setWings(
          data.map(({ wing }) => ({ label: wing, value: wing })),

        ),
      );

    societyService.getGlMasterIsBilling(societyId).then(data => {
      let tempArray = [];
      data.forEach(d => {
        tempArray = [...tempArray, d];
      })
      setBilledGLs(tempArray);
      console.log(billedGLs);
    });

    societyService.getMaxBillNo(societyId, tableName).then((data) => {
      setMaxBillNo(data);
    });
    //getting bill footNote
    societyService.getBillFootNote(societyId)
      .then(data => setBillFootNote(data));
  }, []);

  var date = new Date();
  const billsfor = (billingCycle) => {
    if (billingCycle === 'Monthly') {
      let Months = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`];
      let options = [];
      for (var pastYear = FromYear; pastYear < Year; pastYear++) {
        Months.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
      }
      Months.slice(0, parseInt(Month + 1)).
        forEach(q => options.push({ label: q + ' ' + Year, value: q + ' ' + Year }));
      setBillFor(options);
    }
    if (billingCycle === 'Quarterly') {
      let quarters = ['Jan-Feb-Mar', 'Apr-May-Jun', 'Jul-Aug-Sep', 'Oct-Nov-Dec'],
        options = [];
      for (var pastYear = FromYear; pastYear <= Year; pastYear++) {
        if (pastYear === FromYear) {
          let monthFloor = Math.floor(FromMonth / 4);
          let tempQuarters = Enumerable.from(quarters).where(a => quarters.indexOf(a) >= monthFloor).toArray();
          tempQuarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
        }
        else if (pastYear === Year) {
          let monthFloor = Math.floor(Month / 4);
          let tempQuarters = Enumerable.from(quarters).where(a => quarters.indexOf(a) <= monthFloor).toArray();
          tempQuarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
        }
        else {
          let monthFloor = FromMonth / 4;
          quarters.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
        }
        setBillFor(options);
      }

      if (billingCycle === 'Half-Yearly') {
        let HalfYearlyMonths = [`Jan-Feb-Mar-Apr-May-Jun`, `Jul-Aug-Sep-Oct-Nov-Dec`];
        let options = [];
        for (var pastYear = FromYear; pastYear < Year; pastYear++) {
          HalfYearlyMonths.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
        }
        HalfYearlyMonths.slice(0, parseInt(Month / 6 + 1)).forEach(q => options.push({ label: q + ' ' + Year, value: q + ' ' + Year }));
        setBillFor(options);
      }

      if (billingCycle === 'Yearly') {
        let YearlyMonths = [`Jan-Feb-Mar-Apr-May-Jun-Jul-Aug-Sep-Oct-Nov-Dec`];
        let options = [];
        for (var pastYear = FromYear; pastYear < Year; pastYear++) {
          YearlyMonths.forEach(q => options.push({ label: q + ' ' + pastYear, value: q + ' ' + pastYear }));
        }
        YearlyMonths.slice(0, parseInt(Month / 11 + 1)).forEach(q => options.push({ label: q + ' ' + Year, value: q + ' ' + Year }));
        setBillFor(options);
      }
    }
  }

  // const isBillGeneratedForMonth = () =>{
  //   societyService.getMissingListOfMonth(billDate).then(data => {
  //     setMissingListOfMonth(data);
  //     if(data.success){
  //       toast.current.show({severity:'warn',sticky: true, detail: data.msg});
  //       return
  //     }
  //     else{
  //       toast.current.show({severity:'warn', detail: data.msg});
  //       history.push("./BillGenerationForIndividualFlat.js");
  //     }

  //   })
  // }

  const onBillForchange = (e) => {
    var Mno;
    Mno = parseInt(Mno);
    var selectedYear = e.currentTarget.value.match(/\d/g);
    selectedYear = selectedYear.join("");
    if (billingCycle === 'Quarterly') {
      if (e.currentTarget.value === `Jan-Feb-Mar ${selectedYear}`) {
        Mno = moment().month("Jan").format("M");
      }
      if (e.currentTarget.value === `Apr-May-Jun ${selectedYear}`) {
        Mno = moment().month("April").format("M");
      }
      if (e.currentTarget.value === `Jul-Aug-Sep ${selectedYear}`) {
        Mno = moment().month("July").format("M");
      }
      if (e.currentTarget.value === `Oct-Nov-Dec ${selectedYear}`) {
        Mno = moment().month("Oct").format("M");
      }
    }

    if (billingCycle === 'Half-Yearly') {
      if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun ${selectedYear}`) {
        Mno = moment().month("Jan").format("M");
      }
      if (e.currentTarget.value === `Jul-Aug-Sep-Oct-Nov-Dec ${selectedYear}`) {
        Mno = moment().month("July").format("M");
      }
    }

    if (billingCycle === 'Yearly') {
      if (e.currentTarget.value === `Jan-Feb-Mar-Apr-May-Jun-Jul-Aug-Sep-Oct-Nov-Dec ${selectedYear}`) {
        Mno = moment().month("Jan").format("M");
      }
    }

    if (billingCycle === 'Monthly') {
      if (e.currentTarget.value === `Jan ${selectedYear}`) {
        Mno = moment().month("Jan").format("M");
      }
      if (e.currentTarget.value === `Feb ${selectedYear}`) {
        Mno = moment().month("Feb").format("M");
      }
      if (e.currentTarget.value === `Mar ${selectedYear}`) {
        Mno = moment().month("Mar").format("M");
      }
      if (e.currentTarget.value === `Apr ${selectedYear}`) {
        Mno = moment().month("April").format("M");
      }
      if (e.currentTarget.value === `May ${selectedYear}`) {
        Mno = moment().month("May").format("M");
      }
      if (e.currentTarget.value === `Jun ${selectedYear}`) {
        Mno = moment().month("June").format("M");
      }
      if (e.currentTarget.value === `Jul ${selectedYear}`) {
        Mno = moment().month("July").format("M");
      }
      if (e.currentTarget.value === `Aug ${selectedYear}`) {
        Mno = moment().month("Aug").format("M");
      }
      if (e.currentTarget.value === `Sep ${selectedYear}`) {
        Mno = moment().month("Sep").format("M");
      }
      if (e.currentTarget.value === `Oct ${selectedYear}`) {
        Mno = moment().month("Oct").format("M");
      }
      if (e.currentTarget.value === `Nov ${selectedYear}`) {
        Mno = moment().month("Nov").format("M");
      }
      if (e.currentTarget.value === `Dec ${selectedYear}`) {
        Mno = moment().month("Dec").format("M");
      }
    }
 
    let firstDay = new Date(selectedYear, Mno - 1, billingTemplate[0].billingDate + 1).toISOString().substr(0, 10);
    societyService.IsBillGenerated(societyId, firstDay, tableName).then((data) => {
      setBillGenerated(data.item1);
   
      //let generatedBillHistoryItem= generatedbillsData.where()
      if (data.item1) {
        let billItem = generatedbillsData.find(a => a.generationId == data.item2);
        let formattedDate = moment(billItem.dueDate).format('DD-MM-YYYY');
        let tempDate = new Date(billItem.dueDate);
        let t =moment(billItem.dueDate).format('YYYY-MM-DD');
        setDueDate( t);
        //setValuetempDate('dueDate', billItem.dueDate);
     //   let tmpBillDate= new Date(billItem.billDate);
       let tBilldate =moment(billItem.billDate).format('YYYY-MM-DD');

      // let tempDate2 = new Date(billItem.billDate);
       setBillDate(tBilldate);
//      let t=  tmpBillDate.toLocaleFormat('%d-%m-%Y'); 
        //setBillDate(t);
        //setValue("billDate", t)
 
        toast.current.show({ severity: 'warn', sticky: true, detail: "Bill is already generated for this month. If any flats missed please generate bill for them using 'Bill Generation for individual flat' menu" });
        return
      }
      else {
        let billTemplate = billingTemplate[0];
       

        if (billTemplate.isDayBased) {
          let dueDay = new Date(firstDay);
          let dueD = moment(firstDay).add(billTemplate.dueDate-1, 'days').format('YYYY-MM-DD');
          setDueDate(dueD);
          let billDate= moment(firstDay).add(billTemplate.billingDate-1, 'days').format('YYYY-MM-DD');
          setBillDate(billDate);

        }
        else {
          let dueDay = new Date(firstDay);
          let dueMonth = moment(firstDay).add(billTemplate.dueDateMonth-1, 'M');
          if (billTemplate.monthStartHalfEnd === 'Month_Half') {
            let dueD = moment(dueMonth).add(14, 'days').format('YYYY-MM-DD');
           // let dueD = moment(firstDay).add(billTemplate.dueDate, 'days').format('YYYY-MM-DD');
            setDueDate(dueD);
          //  setDueDate(dueDate.toISOString().substr(0, 10));

          } else if (billTemplate.monthStartHalfEnd === 'Month_End') {
            let dueDate = moment(dueMonth).add(moment(dueMonth).daysInMonth()-1, 'days').format('YYYY-MM-DD');;
            setDueDate(dueDate);

          } else {
            //let dueDate = moment(dueMonth).add(moment(dueMonth).daysInMonth()-1, 'days').format('YYYY-MM-DD');;
            setDueDate(dueMonth.format('YYYY-MM-DD'));


          }
          let billDate= moment(firstDay).add(billTemplate.billingDate-1, 'days').format('YYYY-MM-DD');
          setBillDate(billDate);

          toast.current.show({ severity: 'warn', detail: "Plz generate bill for this month" });
          // history.push("./BillGenerationForIndividualFlat");
        }
      }
    }
    );

  // dueDay.setDate(dueDay.getDate() + billingTemplate[0].dueDate - 1);

  // setDueDate(dueDay.toISOString().substr(0, 10));
  setBillForMonth(e.currentTarget.value); 
  console.warn(billForMonth);
}

function compare(a, b) {
  if (a.glId < b.glId) {
    return -1;
  }
  if (a.glId > b.glId) {
    return 1;
  }
  return 0;
}

const getSocietyData = () => {
  societyService.getSocietyDetailsOnID(societyId).then(data => {
    SetSocietyDetailsData(data);
    Year = moment(data[0].to).year();
    FromYear = moment(data[0].fyFrom).year();
    Month = moment(data[0].to).month() + 1;
    FromMonth = moment(data[0].fyFrom).month() + 1;
  })
}

const GetBillingDetails = () => {
  societyService.getBillingDetailsOfSociety(societyId).then((billingTemplate) => {
    let date = new Date();
    billsfor(billingTemplate[0].billingCycle);
    setBillingCycle(billingTemplate[0].billingCycle);
    setInterestType(billingTemplate[0].interestType);
    setLateInterestType(billingTemplate[0].latePaymentType);
    setBillTemplate(billingTemplate);
  });

  societyService
    .getGeneratedBillsHistory(societyId)
    .then((data) =>
      setGeneratedbillsData(data)
    );
}

const onGenerate = (data, e) => {


  // setTimeout(() => {
  let billSrNo = 1;
  let indx = 0;
  if (data.billFor === '') {
    toast.current.show("error", "Bill cycle is not set !")
    return;
  }
  setGenerateloading(true);
  setLoadingLabelText('Generating Bills...');

  societyService.getBillData(societyId, data.billDate, tableName).then((data) => {
    setSaveMaintainance(data);
    setGenerateloading(false);
    toast.current.show({ severity: 'success', detail: "Bills Generated Succesfully..!" });
    data.forEach(item => {
      if (item.success) {
        item.billSrNo = billSrNo;
        item.billNo = MaxBillNo;
        item.billDate = billDate;
        item.dueDate = dueDate;
        item.societyId = societyId;
        item.societyName = societyName;
        item.billFor = billForMonth;
        item.narration = `Bill For the month ${billForMonth}`;
        let srNo = 1;
        item.currentTotalFromGLHeads = item.currentTotalFromGLHeads;
        item.currentInterest = item.currentInterest;
        item.dpc = item.dpc;
        let currBillTotal = item?.currentTotalFromGLHeads + item?.currentInterest + item?.dpc;
        item.oustandingsArrears = item.oustandingsArrears;
        item.interestArrears = item.interestArrears;
        item.dpcArrears = item.dpcArrears;
        item.totalArrears = item?.oustandingsArrears + item?.interestArrears + item?.dpcArrears;
        //item.totalArrears = item.totalOutstanding;
        // let totalPayable = currBillTotal + oustandingTotal;
        //trueResponseData.totalAmountPayable = item.totalOutstandings;
        item.totalOutstandings = item.totalOutstandings;
        item.maintainanceInfos.forEach(a => {
          a.serialNo = srNo++;
        })

        billedGLs.forEach(billedItem => {
          let itemRetrieved = item.maintainanceInfos.find(a => a.glId === billedItem.glId);
          if (itemRetrieved != undefined) {
            item.glTitle = billedItem.glTitle;
          }
          else {
            item.maintainanceInfos.push({ glId: billedItem.glId, flatId: item.flatId, flatNo: item.flatNo, area: 0, amount: 0, glTitle: billedItem.glTitle, serialNo: srNo++ });
          }
        })

        trueResponseData.push(item);
        //onChangeFlat(indx);
        setArrayData(trueResponseData[index].maintainanceInfos);
        setSrNo(trueResponseData[index].billSrNo);
        setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
        setOwnerName(trueResponseData[index].name);
        BillNumber(index);
        setArea(trueResponseData[index].area);
        setTrueResponse(trueResponseData);
        var str = "" + item.billNo;
        var pad = "00000";
        var ans = pad.substring(0, pad.length - str.length) + str;
        item.billInvNo = `${"INV"}${ans}`;
        billSrNo++;
        MaxBillNo++;
        indx++;
      }
      else {
        NoMaintainance.push({ FlatNo: item.flatNo, ErrorMsg: item.errormsg })
      }
    });
    if (data.length === 1) {
      setNextDisabled(true);
      setBackDisabled(true);
    }
    setBillData(data);

    setInitialForm(false);
    onChangeFlat(index);
  }
  ).catch(error => {
    console.log(error);
    toast.current.show({ severity: 'failure', detail: "Error in Generating Bill !" });
  }).finally(x => {
    setGenerateloading(false);
  })

};
// }, 1000);


const BillNumber = (index) => {
  var str = "" + trueResponseData[index].billNo;
  var pad = "00000";
  var ans = pad.substring(0, pad.length - str.length) + str;
  setBillNo(`${"INV"}${ans}`);
}

const onNext = (e) => {
  index = index + 1;
  if (trueResponseData.length - 1 === index) {
    setNextDisabled(true);
  }
  else {
    setBackDisabled(false);
  }
  setSrNo(trueResponseData[index].billSrNo);
  setOwnerName(trueResponseData[index].name);
  setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
  BillNumber(index);
  setArrayData(trueResponseData[index].maintainanceInfos);
  onChangeFlat(index);
  setArea(trueResponseData[index].area);
  setIndex(index);
}

const onBack = (e) => {
  index = index - 1;
  if (index === 0) {
    setBackDisabled(true);
  }
  else {
    setNextDisabled(false);
  }
  setSrNo(trueResponseData[index].billSrNo);
  setOwnerName(trueResponseData[index].name);
  setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
  BillNumber(index);
  setArrayData(trueResponseData[index].maintainanceInfos);
  onChangeFlat(index);
  setArea(trueResponseData[index].area);
  setIndex(index);
}

const onFirstBill = (e) => {
  index = 0;
  setBackDisabled(true);
  setNextDisabled(false);
  setOwnerName(trueResponseData[index].name);
  setSrNo(trueResponseData[index].billSrNo);
  setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
  BillNumber(index);
  setArrayData(trueResponseData[index].maintainanceInfos);
  onChangeFlat(index);
  setArea(trueResponseData[index].area);
  setIndex(index);
}

const onLastBill = (e) => {
  index = trueResponseData.length - 1;
  setNextDisabled(true);
  setBackDisabled(false);
  setOwnerName(trueResponseData[index].name);
  setSrNo(trueResponseData[index].billSrNo);
  setFlatNo(`${trueResponseData[index].wing}- ${trueResponseData[index].flatNo}`);
  BillNumber(index);
  setArrayData(trueResponseData[index].maintainanceInfos);
  onChangeFlat(index);
  setArea(trueResponseData[index].area);
  setIndex(index);
}


const onChangeFlat = (index) => {
  setCurrentMaintainance(trueResponseData[index]?.currentTotalFromGLHeads.toFixed(2));
  setCurrInterest(trueResponseData[index]?.currentInterest.toFixed(2));
  setSuppMaintenenceData(trueResponseData[index]?.supplimentaryBillAmount);
  setIsSuppBillGenerated(trueResponseData[index]?.isSupplimentaryBillPresent);
  setDpc(trueResponseData[index]?.dpc.toFixed(2));
  let currBillTotal = (trueResponseData[index]?.currentTotalFromGLHeads + trueResponseData[index]?.currentInterest + trueResponseData[index]?.dpc);
  //let currBillTotal = ((trueResponseData[index]?.currentTotalFromGLHeads.toFixed(2) + trueResponseData[index]?.currentInterest.toFixed(2) + trueResponseData[index]?.dpc).toFixed(2));
  setCurrBillTotal(currBillTotal.toFixed(2));
  setOutStandingPrincipal(trueResponseData[index]?.oustandingsArrears.toFixed(2));
  setOutstandingInterest(trueResponseData[index]?.interestArrears.toFixed(2));
  setOsDpc(trueResponseData[index]?.dpcArrears.toFixed(2));
  //let oustandingTotal = trueResponseData[index]?.oustandingsArrears + trueResponseData[index]?.interestArrears + trueResponseData[index]?.dpcArrears;
  let oustandingTotal = (trueResponseData[index]?.oustandingsArrears + trueResponseData[index]?.interestArrears + trueResponseData[index]?.dpcArrears);
  setOutStandingTotal(oustandingTotal.toFixed(2));
  trueResponseData[index].totalArrears = oustandingTotal;
  let totalPayable = currBillTotal + oustandingTotal;
  setTotalPayable(totalPayable.toFixed(2));
  trueResponseData[index].totalAmountPayable = totalPayable;
  trueResponseData[index].amountInWord = converter.toWords(totalPayable)
}

const onSave = (e) => {
  setSaveloading(true);
  setLoadingLabelText('Saving Bills...');
  // setTimeout(() => {
  axios.post(`${Config.apiUrl}/BillGeneration/SaveMaintenance?societyId=${societyId}&societyName=${societyName}&billingDate=${billDate}&dueDate=${dueDate}&transactionTableName=${tableName}`, trueResponseData, { headers: authHeader() })
    .then(response => {
      console.log(response)
      if (response.data.success) {
        setSaveloading(false);
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg });
        setSaveDisabled(true);
      }
      else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg });
      }
    }).catch(error => {
      console.log(error)
      toast.current.show({ severity: 'error', summary: 'Error', detail: "Error in saving data!" });
      setSaveloading(false);

    })
  // }, 2000);
}


const onPrintBill = () => {
  setButtonPopup(true);
}

const onSendMail = () => {
  setMailPopup(true);
}

const leftContents = (
  <React.Fragment>
    <Button icon="pi pi-plus-circle" disabled className="p-button-danger p-mr-2" />
    <Button icon="pi pi-arrow-up" className="p-button-danger p-mr-2" tooltip="First Bill" onClick={handleSubmit(onFirstBill)} />
    <Button icon="pi pi-arrow-left" className="p-button-danger p-mr-2" disabled={backButton} onClick={handleSubmit(onBack)} />
    <Button icon="pi pi-arrow-right" className="p-button-danger p-mr-2" disabled={nextButton} onClick={handleSubmit(onNext)} />
    <Button icon="pi pi-arrow-down" className="p-button-danger p-mr-2" tooltip="Last Bill" onClick={handleSubmit(onLastBill)} />
    <Button icon="pi pi-print" className="p-button-danger p-mr-2" tooltip="Print Bill" onClick={handleSubmit(onPrintBill)} />
    <Button icon="pi pi-save" className="p-button-danger p-mr-2" tooltip="Save Bill" disabled={SaveButton} onClick={onSave} />
    <Button icon="pi pi-trash" className="p-button-danger p-mr-2" disabled tooltip="Delete Bill" />
    <Button icon="pi pi-info-circle" disabled className="p-button-danger p-mr-2" />
    <Button icon="pi pi-envelope" className="p-button-danger p-mr-2" tooltip="Send Mail" onClick={handleSubmit(onSendMail)} />
  </React.Fragment>
);

return (
  <form onsubmit="return false;" className="BillGeneration">
    <Toast ref={toast} position={"center"} />
    <div className="Form_inline">
      <div>
        {Generateloading ? (
          <div className="spinner">
            <span>{loadingLabelText}</span>
            <div className="half-spinner"></div>
          </div>
        ) : (
          <>
            <div style={{ border: "1px solid black" }}>
              <div classname="initialInfo" style={{ display: initialForm ? "block" : "none" }}>
                <div className="Form_inline">
                  <div className="p-field p-grid ">
                    <label className="p-col-fixed">
                      Bill For:
                    </label>
                    <div className="p-col">
                      <select name="billFor" ref={register} className="TextBoxSize150" onChange={onBillForchange} >
                        <option>Select Month</option>
                        {billFor.map(({ label, value: Months }) => (
                          <option key={Months} value={Months}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="Form_inline">
                  <div className="p-field p-grid ">
                    <label className="p-col-fixed">
                      Bill Date:
                    </label>
                    <div className="p-col">
                      <input
                        type="date"
                        name="billDate"
                        className="DateBox"
                        style={{ height: "30px" }}
                        ref={register}
                        value={billDate}
                       
                      />
                    </div>
                  </div>
                </div>

                <div className="Form_inline">
                  <div className="p-field p-grid ">
                    <label className="p-col-fixed">
                      Due Date:
                    </label>
                    <div className="p-col">
                      <input
                        type="date"
                        name="dueDate"
                        className="DateBox"
                        style={{ height: "30px" }}
                        ref={register}
                        value={dueDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="Form_inline" >
                  <div className="p-field p-grid ">
                    <label className="p-col-fixed" >
                      Billing Cycle:
                    </label>
                    <div className="p-col">
                      <input
                        type="text"
                        name="billingCycle"
                        className="TextBoxSize150"
                        style={{ height: "30px" }}
                        ref={register}
                        value={billingCycle}
                      />
                    </div>
                  </div>
                </div>

                <div className="Form_inline">
                  <div className="p-field p-grid ">
                    <label className="p-col-fixed">
                      Interest Type:
                    </label>
                    <div className="p-col">
                      <input
                        type="text"
                        name="interestType"
                        className="TextBoxSize150"
                        style={{ height: "30px" }}
                        ref={register}
                        value={interestType}
                      />
                    </div>
                  </div>
                </div>

                <div className="Form_inline">
                  <div className="p-field p-grid ">
                    <label className="p-col-fixed">
                      Late Interest Type:
                    </label>
                    <div className="p-col">
                      <input
                        type="text"
                        name="latePaymentType"
                        className="TextBoxSize150"
                        ref={register}
                        value={lateInterestType}
                      />
                    </div>
                  </div>
                </div>

                <div className="p-formgroup-inline" style={{ margin: "20", justifyContent: 'center' }}>
                  <div className="p-field">
                    <Button
                      label="GENERATE"
                      type="submit "
                      disabled={billGenerated}
                      onClick={handleSubmit(onGenerate)}
                    />
                  </div>
                  <div className="p-field ">
                    <Button label="CANCEL" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {Saveloading ? (
          <div className="spinner">
            <span>{loadingLabelText}</span>
            <div className="half-spinner"></div>
          </div>
        ) : (

          <>

            <div style={{
              border: "1px solid black",
              width: 'auto',
              backgroundColor: "Info",
              height: "100%",
              margin: '10px',
            }}>
              <div className="billForm" style={{ display: !initialForm ? "block" : "none" }}>
                <div>
                  <Toolbar left={leftContents} />
                </div>
                <div style={{ border: "1px solid black", padding: '3px'}}>
                  <div className="Form_inline">
                    <label>
                      Flat No:
                    </label>
                    <input
                      type="text"
                      className="TextBoxSize150"
                      name="flatNo"
                      ref={register}
                      value={flatNo}
                    />

                    <label>
                      Name:
                    </label>
                    <input
                      type="text"
                      className="MediumTextBox"
                      name="name"
                      ref={register}
                      value={ownerName}
                    />

                    <label className="Label" >
                      Sr:
                    </label>
                    <input
                      type="text"
                      name="sr"
                      className="TextBoxSize100"
                      ref={register}
                      value={SrNo}
                    />
                  </div>


                  <div className="Form_inline">
                    <label >
                      Bill For:
                    </label>
                    <input
                      type="text"
                      className="TextBoxSize150"
                      name="billFor"
                      ref={register}
                      value={billForMonth}
                    />

                    <label>
                      Bill Date:
                    </label>
                    <input
                      type="Date"
                      className="DateBox"
                      name="billDate"
                      ref={register}
                      value={billDate}
                    />

                    <label>
                      Due Date:
                    </label>
                    <input
                      type="Date"
                      name="dueDate"
                      className="DateBox"
                      ref={register}
                      value={dueDate}
                    />

                  </div>

                  <div className="Form_inline">
                    <label >
                      Bill No:
                    </label>
                    <input
                      type="text"
                      className="TextBoxSize150"
                      name="billNo"
                      ref={register}
                      value={BillNo}
                    />

                    <label >
                      Society:
                    </label>
                    <input
                      type="text"
                      className="MediumTextBox"
                      name="society"
                      ref={register}
                      value={societyName}
                    />

                    <label className="Label" >
                      Area:
                    </label>
                    <input
                      type="text"
                      className="TextBoxSize100"
                      name="area"
                      ref={register}
                      value={area}
                    />
                  </div>

                </div>
                <div className="billTable" style={{ minHeight: "20%", width: "850px" }}>
                  <DataTable value={arrayData} className="p-datatable-sm" scrollable scrollHeight='200px' showGridlines>
                    <Column field="serialNo" header="Sr" style={{ width: "80px" }}></Column>
                    <Column field="glTitle" header="Particulars"></Column>
                    <Column field="amount" header="Amount" style={{ width: "170px", textAlign: 'right' }}></Column>
                  </DataTable>
                </div>
                <div style={{ display: 'flex' }}>
                  {isSuppBillGenerated && <div>
                    <div className="Form_inline">
                      <label className="ValueLabel">Supplimentary Bills: </label>

                    </div>
                    <div className="Form_inline">
                      <label className="ValueLabel"> Principal: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={suppMaintenenceData?.principal} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> Interest: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={suppMaintenenceData?.interest} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> Dpc: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px' }} ref={register} value={suppMaintenenceData?.dpc} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> TOTAL: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow' }} ref={register} value={suppMaintenenceData?.dues} />
                    </div>
                  </div>
                  }
                  <div style={{ justifyContent: 'flex-end', }}  >
                    <div className="Form_inline">
                      <label className="ValueLabel"> TOTAL: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', textAlign: 'right' }} ref={register} value={cMaintainance} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> CURR.INTEREST: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', textAlign: 'right' }} ref={register} value={CurrInterest} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> DPC: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', textAlign: 'right', textAlign: 'right' }} ref={register} value={dpc} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> CURR.BILL TOTAL: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow', textAlign: 'right' }} ref={register} value={CurrBillTotal} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> O/S PRINCIPAL: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', textAlign: 'right' }} ref={register} value={oSPrincipal} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> O/S INTEREST: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', textAlign: 'right' }} ref={register} value={oSInterest} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> O/S DPC: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', textAlign: 'right' }} ref={register} value={oSDpc} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> O/S TOTAL: </label>
                      <input type="text" readOnly style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow', textAlign: 'right' }} ref={register} value={oSTotal} />
                    </div>

                    <div className="Form_inline">
                      <label className="ValueLabel"> TOTAL AMOUNT PAYABLE: </label>
                      <input type="text" readOnly ref={register} value={tPayable} style={{ height: '25px', width: '145px', color: 'red', backgroundColor: 'Yellow', textAlign: 'right' }} />
                    </div>

                  </div>
                </div>
                <div className="NoMaintainance" style={{ minHeight: "20%", width: "800px" }}>
                  <DataTable value={NoMaintainance} className="p-datatable-sm" scrollable scrollHeight='200px' showGridlines>
                    <Column field="FlatNo" header="Flat No" style={{ width: "60px" }}></Column>
                    <Column field="ErrorMsg" header="Remark" style={{ width: "390px", color: "red" }}></Column>
                  </DataTable>
                </div>
              </div>
            </div>

            <div style={{ display: !initialForm ? "block" : "none" }}>
              <div style={{
                height: "auto",
                margin: "10px"
              }}>


              </div>
            </div>
          </>
        )}
      </div>
      <ReportPopup trigger={ButtonPopup} setTrigger={setButtonPopup} data={{ saveMaintainance, societyDetailsData, billFootNote }}>
      </ReportPopup>
      <EmailPopup trigger={MailPopup} setTrigger={setMailPopup} data={{ saveMaintainance, societyDetailsData, billFootNote }}>
      </EmailPopup>
    </div>
  </form>
);
}
export default BillGeneration;
