import './BillFootnote.css';
import { Button } from 'primereact/button';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { useEffect, useState, useContext, useRef } from 'react';
import SocietyService from '../../Service/SocietyService';
import { SocietyContext } from "../../SocietyContext";
import styles from "./SocietyDetails.module.css";
import { useHistory } from "react-router-dom";
import authHeader from '../../authHeader';
import { resetIdCounter } from 'react-tabs';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

function BillFootnote({ societyId, id, societyName, setActiveTab }) {
  const isAddMode = !id;
  let history = useHistory();
  const { register, handleSubmit, errors, clearErrors, setValue, reset } = useForm();
  const toast = useRef(null);
  const societyService = new SocietyService();
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);

  const onSubmit = (data, e) => {
    return isAddMode
      ? createBillFootnote(data)
      : updateBillFootnote(data);
  }

  const createBillFootnote = (data) => {
    data.societyId = societyId;
    data.societyName = societyName;
    setSpinnerVisible(true);
    return axios.post(`${Config.apiUrl}/BillFootNote/InsertBillFootnote`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data[0].msg });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  }

  const updateBillFootnote = (data, e) => {
    setSpinnerVisible(true);
   data.societyId = societyId;
    data.societyName = societyName;
    return axios.put(`${Config.apiUrl}/BillFootNote/UpdateBillFootnote?id=${id}`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data[0].msg });
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  }

  const onReset = (e) => {
    reset();
    window.location.reload();

  }

  useEffect(() => {
    if (!isAddMode) {
      societyService.getBillFootNote(id).then((billfootNote) => {
        const fields = [
          "footNote1",
          "footNote2",
          "footNote3",
          "footNote4",
          "footNote5",
          "footNote6",
          "footNote7",
          "footNote8",
        ];
        fields.forEach((field) => setValue(field, billfootNote[0][field]));
      });
    }

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);
    }
  }, []);


  return (
    <div className={styles.SocietyInfo}>
      <div className={styles.BillFootnote}>
        <Toast ref={toast} position="center" />
        <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote1:</label>
          <input type="text" name="footNote1" className="width" ref={register} autoFocus={focusValue} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote2:</label>
          <input type="text" name="footNote2" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote3:</label>
          <input type="text" name="footNote3" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote4:</label>
          <input type="text" name="footNote4" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote5:</label>
          <input type="text" name="footNote5" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote6:</label>
          <input type="text" name="footNote6" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote7:</label>
          <input type="text" name="footNote7" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: '10px' }}>
          <label className="p-mr-2 p-mb-2">Footnote8:</label>
          <input type="text" name="footNote8" className="width" ref={register} autoComplete="off" />
        </div>

        <div className="p-formgroup-inline" style={{ marginLeft: '100px', marginTop: '30px', justifyContent: 'center' }}>
          <div className="p-field">
            <Button label="SUBMIT" type="submit " onClick={handleSubmit(onSubmit)} disabled={DisableButton} />
            <Button label="RESET" type="cancel" style={{ marginLeft: '10px' }} onClick={onReset} disabled={DisableButton} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BillFootnote;