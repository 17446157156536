import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from '../../Service/SocietyService';
import { useHistory } from 'react-router-dom';
import './SocietyView.css';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import authHeader from '../../authHeader';
import { Color } from '@progress/kendo-drawing';

function SocietyView({ props, match, setEditData }) {
  var societyId = parseInt(localStorage.getItem('societyId'));
  let history = useHistory();
  const { path } = match;
  const societyService = new SocietyService();
  const [id, setId] = useState([]);
  const [societyDetailsData, SetSocietyDetailsData] = useState([]);
  const [selectedRadioData, setSelectedRadioData] = useState(null);
  const toast = useRef(null);
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(false);
  const { errors, register, reset, handleSubmit } = useForm();

  const onDelete = (event) => {
    if (selectedRadioData != null) {
      toast.current.show({
        severity: 'warn', sticky: true, content: (
          <div className="p-flex p-flex-column" style={{ flex: '1' }}>
            <div className="p-text-center">
              <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
              <h4>Are you sure?</h4>
              <p>Confirm to Delete</p>
            </div>
          <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button type="button" label="Yes" className="p-button-success" onClick={onYes} />
              </div>
              <div className="p-col-6">
                <Button type="button" label="No" className="p-button-secondary" onClick={onNo} />
              </div>
            </div>
          </div>
        )
      });
    }
    else {
      toast.current.show({ severity: 'warn', summary: 'alert', detail: "SELECT ROW FIRST" });
    }
  }

  const onYes = (event) => {
    toast.current.clear();
    setEditData = selectedRadioData;
    let index = societyDetailsData.findIndex(a => a.id == selectedRadioData.id);
    let id = selectedRadioData.societyId;
    let data = societyDetailsData.filter(b => b.societyId != selectedRadioData.societyId);
    SetSocietyDetailsData(data);
    axios.delete(`${Config.apiUrl}/SocietyDetails/delete?id=${id}`, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data != null) {
          toast.current.show({ severity: 'success', detail: response.data[0].msg });
          window.location.reload();
        }
        else {
          toast.current.show({ severity: 'error', detail: response.data[0].msg });
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onNo = () => {
    toast.current.clear();
    return;
  };

  const onEdit = () => {
    if (selectedRadioData != null) {
      history.push(`/SocietyMaster/edit/${selectedRadioData.societyId}`);
    }
    else {
      toast.current.show({ severity: 'warn', summary: 'alert', detail: "SELECT ROW FIRST" });
    }
    console.warn("edit buuton clicked");
  }

  const onAddFlat = () => {
    if (selectedRadioData != null) {
      history.push(`/Flat_MasterSocietyLink/${selectedRadioData.societyId}`);
    }
    else {
      toast.current.show({ severity: 'warn', detail: "PLEASE SELECT SOCIETY FIRST" });
    }
  }

  useEffect(() => {
    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);
    }

    societyService.getSocietyDetails(societyId)
      .then(data => {
        SetSocietyDetailsData(data)
      });
      
  
  }, []);

  const header = (
    <div className="table-header">
      <div>
        <label> Society Details </label>
        <Button icon="pi pi-user" value="Add Flat" tooltip="Add Flat" className="AddBtn" onClick={onAddFlat} disabled={DisableButton} />
      </div>
      <div>
        <Button icon="pi pi-pencil" value="Edit" tooltip="Edit" className="Editbtn" onClick={onEdit} disabled={DisableButton} />
        <Button icon="pi pi-trash" value="Delete" tooltip="Delete" className="btn" onClick={onDelete} disabled={DisableButton} />
      </div>
    </div>
  );

  return (
    <div className="SocietyView">
      <Toast ref={toast} position="center" />
      <div className="p-formgroup-inline">
        <DataTable value={societyDetailsData} className="p-datatable-gridlines" scrollable scrollHeight='500px' style={{ overflow: 'hidden', width: '100%' }} header={header} selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}  >
          <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
          <Column field="societyName" style={{ width: '200px' }} header="Society Name" filter></Column>
          <Column field="registerNo" style={{ width: '200px' }} header="Register No" filter ></Column>
          <Column field="registerDateFormat" style={{ width: '100px' }} header="Register Date" filter></Column>
          <Column field="societyCode" style={{ width: '120px' }} header="Society Code" filter ></Column>
          <Column field="address" style={{ width: '200px' }} header="Address" filter></Column>
          <Column field="city" style={{ width: '150px' }} header="City" filter ></Column>
          <Column field="pincode" style={{ width: '100px' }} header="Pin code" filter></Column>
          <Column field="telPhoneNo1" style={{ width: '120px' }} header="Telephone No" filter ></Column>
          <Column field="emailId" style={{ width: '190px' }} header="Email" filter ></Column>
          <Column field="url" style={{ width: '220px' }} header="Url" filter ></Column>
          <Column field="faxNo" style={{ width: '120px' }} header="Fax No" filter></Column>
          <Column field="shops" style={{ width: '70px' }} header="Shops" filter></Column>
          <Column field="totalFlats" style={{ width: '100px' }} header="Total Flats" filter ></Column>
          <Column field="garages" style={{ width: '80px' }} header="Garages" filter></Column>
        </DataTable>
      </div>
    </div>
  );
}
export default SocietyView;