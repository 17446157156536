import { useForm } from 'react-hook-form';
import SocietyService from '../Service/SocietyService';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Card } from '@material-ui/core';
import { Toast } from 'primereact/toast';
import Config from '../Config.json';
import axios from "axios";
import authHeader from '../authHeader';
import ProgressSpinBarControl from '../components/Misc/progressSpinBarControl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../components/Login/RegisterUser.css';




function ForgotPassword() {

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    
    });

    const societyService = new SocietyService();
    const { errors, register, reset, handleSubmit, setValue } = useForm({
        resolver: yupResolver(validationSchema) }
    );
    const toast = useRef(null);
    const [userName, setUserName] = useState();
    const [disabledSubmit,setDisabledSubmit] = useState(true);
    const [otpValue, setOtpValue] = useState(0);
    const [spinnerVisible, setSpinnerVisible] = useState(false);




    let history = useHistory();



    const onSave = (data, e) => {
        setSpinnerVisible(true);
        return axios.put(`${Config.apiUrl}/Authentication/updatePassword?userName=${userName}&password=${data.password}`)
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg });
                }
            }).catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSpinnerVisible(false);
                history.push('/login');
              })
    }


    const onCancel = () => {
        history.push('/login');
    }
    const onUserName = (e) => {
        setUserName(e.currentTarget.value);
    }
    const onGetOtp = (data) => {
        setSpinnerVisible(true);
        axios.post(`${Config.apiUrl}/Authentication/GetOtp?userName=${userName}`)
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', detail: error.message });
            })
            .finally(() => {
                setSpinnerVisible(false);
              })

    }

    const onEnterOtp = (e) => {
        setOtpValue(e.currentTarget.value);
    }
    const onVerifyOtp = () => {
        setSpinnerVisible(true);
        axios.post(`${Config.apiUrl}/Authentication/ValidateOtp?userName=${userName}&otp=${otpValue}`)
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', detail: error.message });
            })
            .finally(() => {
                setSpinnerVisible(false);
                setDisabledSubmit(false);
              })

    }


return (
    <form className="signUp">
        <Toast ref={toast} />
        <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
        <Card className="signUpCard" style={{ marginTop: '50px', backgroundColor: '#c0c0c0', border: '1px solid black' }}>


            <div className="p-formgroup-inline" style={{ marginLeft: '20px' }}>
                <div className="p-field p-grid">
                    <label>User Name:</label>
                </div>


                {/* <div className="p-formgroup-inline" style={{ marginLeft: '20px', marginTop: '-10px' }}> */}
                <div className="p-field p-grid">
                    <input type="text" name="userName" style={{ width: '365px' }}  onChange={onUserName} />
                    <Button className="btn btn-secondary" style={{ fontsize: '12px', paddingleft: '22px', height: '35px' }} type="button" onClick={onGetOtp}>Get OTP</Button>
                </div>
            </div>

            <div className="p-formgroup-inline" style={{ marginLeft: '20px' }}>
                <div className="p-field p-grid">
                    <label>Enter OTP:</label>
                </div>
            </div>
            <div className="p-formgroup-inline" style={{ marginLeft: '20px'}}>
                <div className="p-field p-grid">
                    <input type="text" style={{ width: '365px' }}  onChange={onEnterOtp} />
                    <Button className="btn btn-secondary" style={{ fontsize: '12px', paddingleft: '22px', height: '35px' }} type="button" onClick={onVerifyOtp}>Verify OTP</Button>
                </div>
            </div>

{/* 
            <div className="p-formgroup-inline" style={{ marginLeft: '20px', marginTop: '-5px' }}>
                <div className="p-field p-grid">
                    <label>New Password:</label>
                </div>
            </div>
            <div className="p-formgroup-inline" style={{ marginLeft: '20px', marginTop: '-10px' }}>
                <div className="p-field p-grid">
                    <input type="text" name="password" style={{ width: '365px' }} ref={register({ required: true })} />
                </div>
            </div>

            <div className="p-formgroup-inline" style={{ marginLeft: '20px', marginTop: '-5px' }}>
                <div className="p-field p-grid">
                    <label>Re-enter Password:</label>
                </div>
            </div>
            <div className="p-formgroup-inline" style={{ marginLeft: '20px', marginTop: '-10px' }}>
                <div className="p-field p-grid">
                    <input type="text" name="password" style={{ width: '365px' }} ref={register({ required: true })} />
                </div>
            </div> */}
            <div className="p-formgroup-inline" style={{marginLeft:'20px'}}>
                <div className="p-field p-grid"> 
                <input type="password"  name="password" ref={register({ required: true })} placeholder="New Password"  />
                </div>

                <div className="p-field p-grid"> 
                <input type="password"  name="confirmPassword" ref={register({ required: true })} placeholder="Confirm Password" />
                </div>
                
            </div>
            <div className="invalid-feedback">
                <div>{errors.password?.message}</div> 
                <div>{errors.confirmPassword?.message}</div>
            </div>


            <div className="p-formgroup-inline" style={{ justifyContent: "center", margin: "20px" }}>
                <Button label="Submit" disabled={disabledSubmit} type="Submit" style={{ marginRight: '20px' }} onClick={handleSubmit(onSave)} />
                <Button label="Cancel" onClick={onCancel} />
            </div>




        </Card>
    </form>
);
}
export default ForgotPassword;