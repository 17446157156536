import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { withRouter } from "react-router-dom";
import { SocietyContextProvider } from './SocietyContext';
import store from './store/store';
import { SocietyContext } from './SocietyContext';
import {Provider} from "react-redux";

// store.subscribe(()=>console.log(store.getState()));

ReactDOM.render(
  <React.StrictMode>
    
      {/* <Provider store={store}> */}
        <App />
      {/* </Provider> */}
    {/* </SocietyContextProvider>  */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
