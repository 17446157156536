import React, { useState, useEffect,useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { ProductService } from './ProductService';
import SocietyService from '../../Service/SocietyService';
import { Button } from "primereact/button";
import './UpdateCommunicationDetail.css';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import Config from '../../Config.json'
import authHeader from '../../authHeader';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';

export default function UpdateCommunicationDetail() {
    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [personalDetailsData, SetPersonalDetailsData] = useState([]);
    const [email, setEmail] = useState('');
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    var societyId = parseInt(localStorage.getItem('societyId'));
    const [isUpdateSuccessful,setIsUpdateSuccessful] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        societyService.getFlat(societyId)
            .then(data => {
                data.forEach(a => {
                    a.unitNo = `${a.wing}-${a.flatNo}`;
                })
               
                SetPersonalDetailsData(data)

            }
            );

    }, []);

    const dataTableEdit = {
        'data': SetPersonalDetailsData
    };

    // let editingCellRows = {};

    // const onEditorInit = (e) => {
    //     const { rowIndex: index, field, rowData } = e.columnProps;
    //     if (!editingCellRows[index]) {
    //         editingCellRows[index] = { ...rowData };
    //     }
    //     editingCellRows[index][field] = personalDetailsData[index][field];
    // }

    // const inputTextEditor = (productKey, props, field) => {
    //     if (field != 'srno' && field != 'flatno' && field != 'name') {
    //         if (field === 'whatsapp_no') {
    //             return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;

    //         }
    //         else {
    //             return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
    //         }
    //     }
    //     // else if (field == 'bal_Type') {
    //     //     return <Dropdown value={props.rowData[field]} options={types} optionLabel="label" optionValue="value"
    //     //         onChange={(e) => onEditorValueChange(productKey, props, e.value)} placeholder="Select a type" />;
    //     // }
    //     else {
    //         return;
    //     }
    // }

 
    const numberEditor = (columnName, props) => {
        // return <InputNumber value={props.rowData[props.field]} onValueChange={(e) => onNumberChange(columnName,props, e.value)}  />
        return <InputNumber value={props.rowData[props.field]} useGrouping={false}  onValueChange={(e) => onNumberChange(columnName, props, e.value)}  />

    }

    const onNumberChange = (columnName,props, value) => {
        
        let updatedData = [...props.value];
        const numberAsString = value.toString();
        let length=numberAsString.length;

        if(length === 10 )
        {
            updatedData[props.rowIndex][props.field] = value;
            updatedData[props.rowIndex].state = "modified";
        }
        else{
        //    toast.current.show({ severity: 'error', detail:'Phone number is not correct', });

        }
    }

    const emailEditor = (columnName, props) => {
        // return <InputNumber value={props.rowData[props.field]} onValueChange={(e) => onNumberChange(columnName,props, e.value)}  />
        return <InputText type="email" value={email} onChange={(e) => onEmailChange(columnName, props,e.target.value)}  />
    }

    const onEmailChange = (columnName,props,mailId) => {
        setEmail(mailId);
        let updatedData = [...props.value];
        updatedData[props.rowIndex][props.field] = mailId;
        updatedData[props.rowIndex].state = "modified";
      };

    const onUpdate = ()=>{
        let updatedData=personalDetailsData.filter(a=>a.state ==="modified");
        let filterdData=[];
        updatedData.forEach(a=>
            {                
                let b={'flatId':a.id,'mobile':a.mobile.toString(),'email':a.email};
                filterdData.push(b);
            })
        setSpinnerVisible(true);
        axios.put(`${Config.apiUrl}/PersonalDetails/CommunicationDetails`, filterdData, { headers: authHeader() })
            .then(response => {

                console.log(response)
                if (response.data[0].success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
                    window.location.reload();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
            })
            setIsUpdateSuccessful(true);
    };

    const rowStyle = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width:'300px'
      };

      const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2" style={{ position:"absolute", right:"10px", top:"-15px"}}>  
        <Button  label="UPDATE" onClick={onUpdate} style={{ margin: "5px" }}  />
        {isUpdateSuccessful && <p>Update Successfully!</p>}
    
        </div>
    ); 

    const rowClassChild = (data) => {
        return {
            'Changed': data.status === 'modified'
        };
    };
 
    return (
        <div className="UpdateCommunicationDetail">
            <Toast ref={toast} position={'center'} />

            {/* <div className="card p-fluid"> */}
            <DataTable value={personalDetailsData} rowClassName={rowClassChild} editMode="cell" style={{width:'1200px'}} scrollable scrollHeight='500px' header={header}  tableStyle={{ minWidth: '60rem' }}>
            {/* <DataTable value={personalDetailsData} editMode="cell" style={{width:'1200px'}} scrollable scrollHeight='500px'> */}
            <Column field="serialNo" header="Sr.No" style={{width:'80px'}}></Column>
            <Column field="unitNo" header="Flat No" style={{width:'100px'}} filter></Column>
            <Column field="name" header="Name"  style={rowStyle} filter ></Column>
            <Column field="mobile" header="WhatsApp No"  editor={(props) => numberEditor('mobile', props)} style={{width:'200px'}}></Column>
            <Column field="email" header="Email Id" editor={(props) => emailEditor('email', props)} style={{width:'300px'}}></Column>
            </DataTable>
    
        </div>
    );
}
