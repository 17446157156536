/* eslint-disable no-unused-vars */
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { Divider } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import './TenantDetails.css';
import { useForm } from 'react-hook-form';
import SocietyService from "../../Service/SocietyService";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Config from '../../Config.json' 
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { grey } from '@material-ui/core/colors';


function TenantDetails({ flatId, id, usedBy, setActiveTab }) {

  const validationSchema = Yup.object().shape({
    prefix: Yup.string()
      .required('Prefix is required'),
    tenantName: Yup.string()
      .required('Tenant Name is required'),
  });

  // const isAddMode = !id;
  const toast = useRef(null);

  const [fromDate, setFromDate] = useState([]);
  const [toDate, setToDate] = useState([]);
  var [tenantDetails, setTenantDetails] = useState([]);
  const [addArray, setAddArray] = useState([]);
  const [saveDisabled, SetSaveDisabled] = useState(true);
  const [addDisabled, setAddDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true)
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
  const [DisableButton, setDisableButton] = useState(true);
  const [lastSelectedTenantId,setLastSelectedTenantId]=useState();
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [focusValue, SetFocusValue] = useState(true);
  const [policeVerification, setPoliceVerification] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);


  const societyService = new SocietyService();

  const { errors, register, reset, handleSubmit, setValue } = useForm(
    { resolver: yupResolver(validationSchema) }
  );
  useEffect(() => {
   
    fetchTenantDetails();

    if (accessLevel >= 2) {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);

    }
  }, []);

  // const onSubmit = (data, e) => {
  //   SetSaveDisabled(true);
  //   if (isAddMode) {
  //     createUser(data);
  //   } else {
  //     updateUser(data);
  //   }
  // };

  const fetchTenantDetails=()=>{

     // get user and set form fields
    //  societyService.getTenantDetailsOnID(id).then(retriveTenantDetailsIn => {
    //    setNextDisabled(false);
    //   if (retriveTenantDetailsIn.length > 0) {
    //     const fields = ['prefix', 'tenantId', 'tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo'];
    //     fields.forEach(field => setValue(field, retriveTenantDetailsIn[0][field]));
    //     setTenantDetails(retriveTenantDetailsIn);
    //     retriveTenantDetailsIn[0].rowActive=true;
    //     setLastSelectedTenantId(retriveTenantDetailsIn[0].tenantId)
    //     if (retriveTenantDetailsIn[0].agreementToDateFormat === '0001-01-01') {
    //       setFromDate("");
    //       setValue('agreementMonths', '');

    //     }
    //     else {
    //       // setFromDate(retriveTenantDetailsIn[0].agreementToDateFormat);
    //     }

    //     if (retriveTenantDetailsIn[0].agreementFromDateFormat === '0001-01-01') {
    //       setToDate("");
    //     }
    //     else {
    //       setToDate(retriveTenantDetailsIn[0].agreementFromDateFormat);
    //     }

    //     if (retriveTenantDetailsIn[0].pincode === 0) {
    //       setValue('pincode', '');
    //     }


    //     // setFromDate(retriveTenantDetailsIn[0].agreementToDateFormat);
    //     // setToDate(retriveTenantDetailsIn[0].agreementFromDateFormat);
    //     setAddDisabled(true);
    //   }
    //   SetSaveDisabled(true);
    // });
  }

  const onAddTenant = (data, e) => {
    // setAddArray(addArray.concat(data));
    let existingValue = tenantDetails.findIndex(x => x.tenantName === data.tenantName);
    if (existingValue < 0) {
      data.state = "New";
      setTenantDetails(tenantDetails.concat(data));
      data.isPoliceVerified= policeVerification;
      onsave(data);
      setNextDisabled(false);
    }
    else {
      // alert("Tenant is already added");
      toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Tenant is already added' });
    }
    // if (addArray.length > 0 || id > 0) {
    SetSaveDisabled(false);
  }


  const onEditTenant = (data, e) => {
    console.warn(data);
    // personalDetails.push(data);

    let existingIndex = tenantDetails.findIndex(x => x.tenantName === data.tenantName);
    if (existingIndex >= 0) {
      if (data.state != "New") {

        data.state = "modified";
      }
      tenantDetails[existingIndex] = data;
      tenantDetails = [...tenantDetails];
      setTenantDetails(tenantDetails);     
      data.isPoliceVerified= policeVerification;
      onsave(data);    // reset();
      setNextDisabled(false);

    }
    else {
      //alert("Nominee is already added");
    }
    SetSaveDisabled(false);

  }

  const onsave = (data) => {
    // tenantDetails.forEach(b => {
      setAddDisabled(true);
      data.flatId = id;
      if (data.state === "New") {
        createUser(data);
      }
      else if (data.state === "modified") {
        updateUser(data);
      }

    // });
  }

  const createUser = (data, e) => {
    // addArray.forEach( b=>{
    data.tenantId = "-1";
    if (data.pincode === '') {
      data.pincode = undefined;
    }
    if (data.agreementFrom === '') {
      data.agreementFrom = undefined;
    }
    if (data.agreementMonths === '') {
      data.agreementMonths = undefined;
    }
    if (data.agreementTo === '') {
      data.agreementTo = undefined;
    }
    setSpinnerVisible(true);
    axios.post(`${Config.apiUrl}/TenantDetails/Insert`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data[0].success) {
          // alert(response.data[0].msg );
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg });
          id=id;
          setNextDisabled(false);
          setAddDisabled(false);
          setDisableButton(false);
          fetchTenantDetails();
        }
        else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
        }

      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })
  }

  const updateUser = (data, e) => {
    if (data.pincode === '') {
      data.pincode = undefined;
    }
    if (data.agreementFrom === '') {
      data.agreementFrom = undefined;
    }
    if (data.agreementMonths === '') {
      data.agreementMonths = undefined;
    }
    if (data.agreementTo === '') {
      data.agreementTo = undefined;
    }
    setSpinnerVisible(true);
    axios.put(`${Config.apiUrl}/TenantDetails/update?id=${data.tenantId}`, data, { headers: authHeader() })
      .then(response => {
        console.warn(data);
        console.log(response)
        if (response.data[0].success) {
          //  alert(response.data[0].msg );
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.data[0].msg, life: 6000 });
          id=id;
          fetchTenantDetails();
          setAddDisabled(false);

        }
        else {
          //  alert(response.data[0].msg);
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data[0].msg });
        }
      })
      .catch(error => { 
        toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    })
    .finally(() => {
        setSpinnerVisible(false);
        SetFocusValue(true);
      })

  }

  const [retriveTenantDetails, setRetriveTenantDetais] = useState({});

 


  const rowClass = (data) => {
    
    return {
        'selectedRow': data.rowActive
      
    }
    
}

  const onDoubleClickEdit = (event) => {
    
    if(lastSelectedTenantId !== event.data.tenantId)
    {
      let selectedRow=tenantDetails.find(a=>a.tenantId===lastSelectedTenantId)
      selectedRow.rowActive=false;
    }
  
    var data = event.data;
    data.rowActive=true;
    rowClass(data);
    setLastSelectedTenantId(event.data.tenantId);
    const fields = ['prefix', 'tenantId', 'tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo'];
    fields.forEach(field => setValue(field, data[field]));
    setValue('agreementFrom',data.agreementFrom)
    setValue('agreementTo',data.agreementTo)

    // setFromDate(data.agreementToDateFormat);
    setToDate(data.agreementFrom);

  }
  
  const onChange = (e) => {
     setFromDate(e.currentTarget.value);
    setValue('agreementFrom', e.currentTarget.value);
  }

  const onToDateChange = (e) => {
     setToDate(e.currentTarget.value);
    setValue('agreementTo', e.currentTarget.value);
  }

  const onMonthChange = (e) => {
    let month = e.currentTarget.value;
    let Month = parseInt(month);
    setValue('agreementTo', addMonths(Month));
  }

  function addMonths(Month) {
    var newDate = new Date(fromDate);
    var month = newDate.getMonth();
    var toDate = newDate.setMonth(month + Month);
    var FinalToDate = new Date(toDate).toISOString().slice(0, 10);
    return FinalToDate;
  }

  const onReset = (data) => {
    // reset();
    const fields = ['prefix','tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo'];
    fields.forEach(field => setValue(field, ''));
    setAddDisabled(false);
    // if(tenantDetails.length >0)
    // {
    //   setValue("flatNo",tenantDetails[0].flatNo);
    // }
    // setIsEdit(false);
  }

  const onNext = (e) => {
    let insertIndex = tenantDetails.findIndex(a => a.state === "New" || a.state === "modified");
    if (insertIndex >= 0) {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'There are unsaved records in grid. Please save it before moving it to new tab' });
    }
    setActiveTab(5);
  }


  return (
    // <div className="TenantDetails" style={{ display: usedBy === 'Tenant' ? 'block' : 'none' }}>
          <div className="TenantDetails" >
            <div className="BackgrdColor" >

      <Toast ref={toast} position="center" />
      <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>

      <div className="split down">
        <div className="DownHeader">
          <h3 style={{color:"grey", marginLeft:"5px"}}>Rental/Tenant Details </h3>
        </div>
        <input type8="text" name="tenantId" ref={register} hidden />


        <div className="Form-inline">
          <label className="TenantDetailsLabel"> Tenant Name<span style={{ color: 'red' }}>*</span>:</label>
          <select className="Selec tSmallWidth" name="prefix" ref={register({ required: true })} autoFocus={focusValue} >
            <option>Mr</option>
            <option>Mrs</option>
          </select>

          <input type4="text" className="tenantlbl" name="tenantName" ref={register({ required: true })} autoComplete="off" />
        </div>

        <div className="Form-inline">
          <label className="TenantDetailsLabel">Tenant Address:</label>
          <input type7="text" name="address1" ref={register} autoComplete="off" />
          <div className="TenantSecondAddress" style={{alignItems: "flex-start"}}>
            <input type7="text" name="address2" ref={register} autoComplete="off" />
          </div>
        </div>

        <div className="Form-inline">
          <label className="TenantDetailsLabel"> City :</label>
          <select className="dropdown" name="city" ref={register}>
            <option>Mumbai</option>
            <option>Pune</option>
            <option>Satara</option>
          </select>

          <label className="TenantDetailsLabel"> Pincode :</label>
          <input type6="text" name="pincode" ref={register} autoComplete="off" />

          <label className="TenantDetailsLabel"> Mobile :</label>
          <input type6="text" name="mobile" ref={register} autoComplete="off" />
        </div>

        <div className="Form-inline">
          <label className="TenantDetailsLabel">Agreement From :</label>
          <input type="Date" className="Date" name="agreementFrom" ref={register}  onChange={onToDateChange} />

          <label className="TenantDetailsLabel"> Agreement Month:</label>
          <input type6="text" name="agreementMonths" ref={register} onChange={onMonthChange} autoComplete="off" />

          <label className="TenantDetailsLabel">Agreement To :</label>
          <input type="Date" className="Date" name="agreementTo" ref={register}  onChange={onToDateChange} />
        </div>

        <div className="Form-inline" style={{display:'flex'}}>
        <label className="TenantDetailsLabel">Police Verification:</label>
          <Checkbox className="CheckBox" checked={policeVerification} onChange={e => setPoliceVerification(e.checked)} style={{marginLeft:'10px'}} />
         
        </div>
        <div className="invalid-feedback">
          <div>{errors.prefix?.message}</div>
          <div> {errors.tenantName?.message}</div>
        </div>

        <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
          <div className="p-field">

            <Button label="ADD TENANT" disabled={addDisabled} type="submit " onClick={handleSubmit(onAddTenant)}  />
          </div>
          <div className="p-field">

            <Button label="EDIT TENANT" disabled={!addDisabled} type="submit " onClick={handleSubmit(onEditTenant)} />
          </div>

          {/* <div className="p-field">

            <Button disabled={saveDisabled} label="Save" type="submit " onClick={handleSubmit(onsave)} />
          </div> */}
          <div className="p-field">

            <Button disabled={nextDisabled} label="NEXT" onClick={onNext} />
          </div>

          <div className="p-field ">
            <Button label="RESET" type="reset" onClick={onReset} disabled={DisableButton} />
          </div>

        </div>
        </div>

        <div >
          <DataTable value={tenantDetails} selectionMode="single" className="p-datatable-gridlines p-datatable-sm" rowClassName={rowClass} selection={lastSelectedTenantId} header="Tenant Details"  onRowDoubleClick={onDoubleClickEdit} >
            <Column field="prefix" header="Prefix" ></Column>
            <Column field="tenantName" header="Tenat Name" ></Column>
            <Column field="address1" header="Address 1" ></Column>
            <Column field="address2" header="Address 2" ></Column>
            <Column field="city" header="City" ></Column>
            <Column field="pincode" header="Pincode" ></Column>
            <Column field="mobile" header="Mobile" ></Column>
            <Column field="agreementFrom" header="Agreement From" ></Column>
            <Column field="agreementMonths" header="Agreement Month" ></Column>
            <Column field="agreementTo" header="Agreement To" ></Column>
          
          </DataTable>
        </div>

      </div>

    </div>
  )
}
export default TenantDetails;