/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import logo from './logo.svg';
// import './App.css';
import {Link} from 'react-router-dom';
import React from 'react';
// import {Tabs, Tab} from 'react-bootstrap-tabs';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GroupMasterUi from './components/Masters/GroupMasterUi';
import SubGroupMasterUi from './components/Masters/SubGroupMasterUi';
import GL_Master from './components/Masters/GL_Master';
import{BrowserRouter,useParams } from 'react-router-dom';
import Nav from './Nav';











function GroupMasters() {
    const[selectedTab,setSelectedTab]=React.useState(0);
    // const { id } = useParams();
    

    const handleChange=(event,newValue) => {
        console.warn(newValue);
        setSelectedTab(newValue);
    };


  return (
    <div className="GroupMasters">
      
      <header className="GroupMasters-header">
        <div >
      <AppBar position="static">
        <Tabs value={selectedTab}  onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Group Master" />
        
          <Tab label="Sub-Group Master" />
          <Tab label="GL_Master" />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <GroupMasterUi />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <SubGroupMasterUi />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <GL_Master/>
      </TabPanel>
      

      
    </div>
      </header>
    </div>
  );
}

function TabPanel(props)
{
    const {children,value,index}=props;
    
    return(
        <div>
            {
                value===index &&(<div>{children}</div>)
            }
        </div>
    )
}
export default GroupMasters;

