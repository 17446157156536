
import '../Report/Report.css';
import { SocietyContext } from "../../SocietyContext";
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import SocietyService from '../../Service/SocietyService';
import { useSelector, useDispatch } from "react-redux";
import { SocietyDetails } from '../../actions/index';
// import societyData from '../../reducer/societyData';
import { connect } from 'react-redux';
import moment, { invalid } from 'moment';

var societyData;
var BillData;
var FootNote;
var amountInWord = '';
var totalAmount;
// class Example extends React.Component {
function Example({ props }) {

  const location = useLocation();

  // setBillDetails(location.state.detail);
  BillData = location.state.detail;
  // setSocietyData(location.state.SocietyData);
  societyData = location.state.SocietyData;
  FootNote = location.state.BillFootNote;


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className='DuesReminder'>
      <button onClick={handlePrint}>Print this out!</button>
      <Report ref={componentRef} />

    </div>
  );

}


class Report extends React.Component {
  render() {



    return (

      <div style={{ height: '100%' }}>

        {BillData.map((data, index) =>
          <div>
            <div className='SocietyName'>
              <div class="font"><text>{societyData[0].societyName}</text></div>
              <div>
                <label>Registration No:</label><text>{societyData[0].registerNo}</text>
                <label> Dated:</label><text>{moment(societyData[0].registerDateFormat).format('DD-MM-YYYY')}</text>
              </div>
              <div>
                <text>
                  {societyData[0].address}
                </text>
              </div>
            </div>
            <div className="Bill">
              BILL
            </div>

            {/* <label className="alignleft" style={{width:'40%'}}>Flat No: {location.state.detail[index].flatNo}</label> */}

            <div>
              <div className="border">
                <div>
                  <label className="alignleft" style={{ width: '40%' }}>Flat No: {BillData[index].flatNo}
                  </label>

                  <label className="aligncenter" >Area in Sqft:{BillData[index].area}</label>
                  <label className="alignright"> Bill No: {BillData[index].billInvNo}</label>
                </div>

                <div>
                  <label className="alignleft">Name: {BillData[index].name}</label>
                  <label className="alignright"> Bill Date:{moment(BillData[index].billDate).format('DD-MM-YYYY')}</label>
                </div>



                <div>
                  <label className="alignleft">Bill For:{BillData[index].billFor}</label>
                  <label className="aligncenter"></label>
                  <label className="alignright">Due Date:{moment(BillData[index].dueDate).format('DD-MM-YYYY')}</label>
                </div>
              </div>




              <div class="Table">
                <table>
                  <thead className='headerColor'>
                    <th style={{ width: '10%' }}>Sr</th>
                    <th style={{ width: '75%' }}>Particulars</th>
                    <th style={{ width: '15%' }}>Amount</th>
                  </thead>
                  <tbody>
                    {
                      BillData[index].maintainanceInfos.map((item) => (
                        <tr key={item}>
                          <td>{item.serialNo}</td>
                          <td>{item.glTitle}</td>
                          <td style={{ textAlign: 'right' }}>{Math.round(item.amount).toFixed(2)}</td>
                        </tr>
                      ))
                    }

                  </tbody>
                </table>


                <table style={{ marginLeft: "50%", width: "50%" }} >
                  <tr>
                    <th style={{ width: '70%' }}>Total</th>
                    <td style={{ textAlign: 'right' }}>{Math.round(BillData[index].currentTotalFromGLHeads).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Add: Interest</th>
                    <td style={{ textAlign: 'right' }}>{Math.round(BillData[index].currentInterest).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Add: Dpc </th>
                    <td style={{ textAlign: 'right' }}>{Math.round(BillData[index].dpcCharges).toFixed(2)}</td>
                  </tr>
                </table>


                <table style={{ marginLeft: "50%", width: "50%" }} >
                  <tr>
                    <th style={{ width: '40%' }}>Principal Arrears</th>
                    <td style={{ width: '30%', textAlign: 'right' }}>{Math.round(BillData[index].oustandingsArrears).toFixed(2)}</td>
                    <td rowspan='3' style={{ textAlign: 'right' }}>{Math.round(BillData[index].totalArrears).toFixed(2)}</td>
                  </tr>

                  <tr>
                    <th style={{ width: '40%' }}>Interest Arrears </th>
                    <td style={{ width: '30%', textAlign: 'right' }}>{Math.round(BillData[index].interestArrears).toFixed(2)}</td>
                  </tr>

                  <tr>
                    <th style={{ width: '40%' }}>Dpc Arrears </th>
                    <td style={{ width: '30%', textAlign: 'right' }}>{Math.round(BillData[index].dpcArrears).toFixed(2)}</td>
                  </tr>

                </table>
                {/* total amount Payable table */}
                <table style={{ width: "100%" }} >
                  <tr className='headerColor'>
                    <th style={{ width: '50%', fontSize: '12px' }}>{BillData[index].amountInWord}</th>
                 {/*    {BillData[index]?.supplimentoryBillAmount > 0 && <th style={{ width: '50%', fontSize: '12px' }}>Supplementary Dues:{BillData[index].supplimentoryBillAmount}</th>
                    } */}
                    <th style={{ width: '35%' }}>Total Amount Payable </th>
                    <td style={{ textAlign: 'right' }}><b>{Math.round(BillData[index].totalAmountPayable).toFixed(2)}</b></td>
                    {/* <td style={{textAlign:'right'}}><b>{gettotalamt(index)}</b></td>  */}


                  </tr>
                </table>
              </div>


              <div className="BillFootNote">
                <label><b>E.&.O.E</b></label><br />
                <text>{FootNote[0].footNote1}</text><br />
                <label>{FootNote[0].footNote2}.</label><br />
                <label>{FootNote[0].footNote3}</label><br />
                <label>{FootNote[0].footNote4}</label><br />
                <label>{FootNote[0].footNote5}</label><br />
                <label>{FootNote[0].footNote6}</label><br />
                <label>{FootNote[0].footNote7}</label><br />
                <label>{FootNote[0].footNote8}</label><br />
              </div>

              <div style={{ height: '100px' }}>
                <label className="alignright">For {societyData[0].societyName}</label><br />
                <label className="alignright" style={{ marginTop: "40px" }}>Authorised Signature</label>
              </div>

              <div className="Receipt">
                Attach receipt here
              </div>



            </div>
          </div>
        )};
      </div>


    )
  }

};

export default Example;
