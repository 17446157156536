import { Checkbox } from "@material-ui/core";
import { Button } from "primereact/button";
import React from 'react'
import { useForm } from "react-hook-form";
// import '../Popups/LoginPopup.css';
import '../Login/LoginPopup.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SocietyService from '../../Service/SocietyService';
import { SocietyContext } from "../../SocietyContext";
import { Dropdown } from 'primereact/dropdown';
import axios from "axios";
import Config from "../../Config.json";
import { Toast } from 'primereact/toast';
import authHeader from "../../authHeader";
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";



function LoginPopup(props) {
    // var societyId = parseInt(localStorage.getItem('societyId'));
    let history = useHistory();
    // const toast = useRef(null);
    const societyService = new SocietyService();
    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue, clearErrors } = useForm();
    // const { societyId,societyName} = useContext(SocietyContext);
    var UserId = localStorage.getItem('userId');
    const [societyArray, setSocietyArray] = useState([]);
    var [societiesOfCity,setSocietiesOfCity]=useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [societyCities,setSocietyCities]=useState([]);
    const [societyInfo, setSocietyInfo] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [userData, setUserData] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);




    function compare( a, b ) {
        if ( a.last_nom < b.last_nom ){
          return -1;
        }
        if ( a.last_nom > b.last_nom ){
          return 1;
        }
        return 0;
      }


    useEffect(() => {
        societyService.getAllSocietyDetails()
            .then(data => {
                let tempArray = [];
                data.forEach(society => {
                    let item = { 'id': society.societyId, 'name': society.societyName, 'city': society.city };
                    tempArray.push(item);
                    /* let existingIndex= tempArray.findIndex(a=>a.id=== item.id);
                    if(existingIndex <0){
                    tempArray.push(item);
                    } */
                })
           tempArray.sort(compare);
                setSocietyArray(tempArray);
                
                let cities=[];
                tempArray.forEach(data=>{
                    let item={'city':data.city.toUpperCase()};
                    let duplicate=cities.find(a=>a.city.toUpperCase() === item.city);
                    if(duplicate=== undefined)
                    {
                        cities.push(item);
                    }
                })
                setSocietyCities(cities);
            }
            );

            societyService.getUserName(UserId).then(data => {
                setUserData(data);
            });

        // societyService.getPermissionDetails().then(data => {
        //     let userId=data.find(a=>a.userName===props.userName);
        //     setUserId(userId);
        // });


    }, []);

    const onCityChange = (e) => {
        setSelectedCity(e.value);
        societiesOfCity=societyArray.filter(a=> a.city.toUpperCase() === e.value.city)
        setSocietiesOfCity(societiesOfCity);
        // let index=societyArray.findIndex(a=>a.city===e.value.city);
        // setSocietyName(societyArray[index].name);
    }

    const onSocietyChange = (e) => {
        
        setSocietyInfo(e.value);
        let duplicateSociety=userData.find(a=>a.societyName === e.value.name);
        if(duplicateSociety!=undefined)
        {
            toast.current.show({ severity: 'warn', sticky: true, summary: 'warning', detail: 'Society is Already added to your Account' });
            setSubmitDisabled(true);
            return;


        }
    }

    const onSubmit = (data) => {
        setSpinnerVisible(true);
        
        data.userId = props.userId;
        data.societyId=societyInfo.id;
        if(selectedCity=== undefined|| selectedCity=== null||selectedCity.city.length===0){
            alert("Please select City");
            return;
        }
        if(societyInfo.id===0){
            alert("Please select Society Name");
            return;
        }
        data.city = selectedCity.city;
        data.AccessLevel='ReadOnly';
        data.action='Default';
        data.flatNo= 0;
        // if(data.flatNo===NaN){
        //     data.flatNo=-1;
        // }
        data.societyName = societyInfo.name;
        return axios.post(`${Config.apiUrl}/Permission/InsertDetails`, data)
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data != null) {
                    toast.current.show({ severity: 'success', detail: response.data[0].msg });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg });
                }
            })
            .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                window.location.reload();
              })
    }



    return (props.trigger) ? (

       
        <div className="popup">
            <Toast ref={toast} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <div className="popup-inner">
                <div className="popup__header">
                    {/* <button classname="close-btn">CLOSE</button> */}
                    <h3 style={{marginLeft:'15px'}}>Society Details</h3>
                    {/* {props.children} */}
                </div>
                <div className="popup-content">
                    <div>

                        <div>
                            <div >
                                <div>
                                <Dropdown className="Dropdown" value={selectedCity} options={societyCities} id="city" optionLabel="city" ref={register} onChange={onCityChange} filter showClear filterBy="city" placeholder="Select a City"
                                />
                                </div>
                                <Dropdown className="Dropdown" value={societyInfo} options={societiesOfCity} id="name" optionLabel="name" ref={register} onChange={onSocietyChange} filter showClear filterBy="name" placeholder="Select a Society"
                                />
                                {/* <div>
                                    <input type="text" name="wing" ref={register} placeholder="Wing" />
                                </div>
                                <div>
                                    <input type="text" name="flatNo" ref={register} placeholder="Flat Number" />
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="popup__footer">
                    <Button onClick={handleSubmit(onSubmit)} style={{marginRight:'20px', margin:'5px'}} disabled={submitDisabled}>SUBMIT</Button>
                    <Button onClick={() => props.setTrigger(false)} style={{marginRight:'20px', margin:'5px'}}>CLOSE</Button>
                </div>
            </div>
        </div>
        
    ) : "";
}

export default LoginPopup

