// import React from 'react';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { InputNumber } from 'primereact/inputnumber';
// import { Button } from 'primereact/button';
import { Divider, Radio } from '@material-ui/core';
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SocietyService from '../Service/SocietyService'
import moment from 'moment';
import { useEffect } from 'react';
import SocietyState from 'SocietyState';
import { useRef, useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import Flat from './Transactions/Flat';


function TenantView() {
  const toast = useRef(null);
  const [SocietyGls, setSocietyGls] = useState();
  const [compareGlTitils, setcompareGlTitils] = useState();
  const [tenantData, setTenantData] = useState([]);
  const [allOriginalTenantsData, setAllOriginalTenantsData] = useState([]);

  let service = new SocietyService();

  const fields = ['prefix', 'wing', 'flat_No', 'tenantId', 'tenantName', 'address1', 'address2', 'city', 'pincode', 'mobile', 'agreementFrom', 'agreementMonths', 'agreementTo'];
  var societyId = parseInt(localStorage.getItem('societyId'));
  useEffect(() => {
    let d = moment(moment(new Date())).format('YYYY-MM-DD');
    try {
      service.getTenantDetails(societyId)
        .then((data) => {
          setTenantData(data);
          data.forEach(item => {
            if(item.isPoliceVerified){
              item.policeVerificationFormatted="Done";
            }
            else{
              item.policeVerificationFormatted="Pending";
            }
          });
          setAllOriginalTenantsData([...data]);
          console.warn(data);
        })
        .catch(error => {
          toast.current.show({ severity: 'error', detail: error });
        })
    }
    catch (error) {
      console.log(error);
      toast.current.show({ severity: 'error', detail: error });
    }
  }, []);

const [radioValue, setRadioValue]=useState("All");

  const onAllDataSelected = (e) => {
  
    if (e.value === "All") {
      setTenantData(allOriginalTenantsData);
      setRadioValue("All");
    }
  }
  
  const onExpiredAgreementDataSelected = (e) => {
    if (e.value === "Expired") {
      let data= allOriginalTenantsData.filter(a=>a.agreementToDateFormat !='0001-01-01' && (new Date(Date.parse(a.agreementTo)) < new Date()))
      setTenantData(data);
      setRadioValue("Expired");
    }
  }
  const onAgreementsToExpireIn1MonthDataSelected = (e) => {
    if (e.value === "Expired1Month") {
      //const monthDifference =  ;
      let data= allOriginalTenantsData.filter(a=>a.agreementToDateFormat !='0001-01-01' && moment(a.agreementToDateFormat).diff(new Date, 'months' , true) <=1 && moment(a.agreementToDateFormat).diff(new Date, 'months' , true) >=0)
      setTenantData(data);
      setRadioValue("Expired1Month");
    }
  }


  const onPoliceVerificatinPending = (e) => {
    if (e.value === "policeVerification") {
      let data= allOriginalTenantsData.filter(a=>!a.isPoliceVerified)
      setTenantData(data);
            setRadioValue("PoliceVerificationPending");
    }
  }

  return (
    <div className="TenantView">
      <div className="p-formgroup-inline">
        <div className="p-field p-grid" style={{ marginLeft: "15px", marginInline: "20px" }}>
          <RadioButton style={{ marginLeft: "20px" }} name="value" value="All" checked={radioValue==='All'}  onChange={onAllDataSelected}  />
          <label type="Radiobutton" className="ml-2" style={{ marginLeft: "10px" }}>All</label>
          <RadioButton style={{ marginLeft: "30px" }}  name="value" value="Expired" checked={radioValue==='Expired'} onChange={onExpiredAgreementDataSelected} />
          <label className="ml-2" style={{ marginLeft: "10px" }}>Expired Agreements</label>
          <RadioButton style={{ marginLeft: "30px" }} name="value" value="Expired1Month" checked={radioValue==='Expired1Month'} onChange={onAgreementsToExpireIn1MonthDataSelected}/>
          <label className="ml-2" style={{ marginLeft: "10px" }}>Agreements to expire in 1 month</label>
          <RadioButton style={{ marginLeft: "30px" }} name="value" value="policeVerification" checked={radioValue==='PoliceVerificationPending'}  onChange={onPoliceVerificatinPending}/>
          <label className="ml-2" style={{ marginLeft: "10px" }}>Police verification pending</label>

        </div>
        <div>
          <DataTable value={tenantData} className="p-datatable-gridlines p-datatable-sm" header="Tenant View"  >
            <Column field="prefix" header="Prefix" ></Column>
            <Column field="wing" header="Wing"></Column>
            <Column field="flatNo" header="Flat No"></Column>
            <Column field="ownerName" header="Owner Name" ></Column>
            <Column field="tenantName" header="Tenat Name" ></Column>
            <Column field="address1" header="Address 1" ></Column>
            <Column field="address2" header="Address 2" ></Column>
            <Column field="city" header="City" ></Column>
            <Column field="pincode" header="Pincode" ></Column>
            <Column field="mobile" header="Mobile" ></Column>
            <Column field="agreementFromDateFormat" header="Agreement From" ></Column>
            <Column field="agreementMonths" header="Agreement Month" ></Column>
            <Column field="agreementToDateFormat" header="Agreement To" ></Column>
            <Column field="policeVerificationFormatted" header="IsPoliceVerified" ></Column>
            

          </DataTable>
        </div>
      </div>
    </div>
  )
}
export default TenantView;