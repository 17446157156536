

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SocietyService from 'Service/SocietyService';
import { Dropdown } from 'primereact/dropdown';
import './UserRoles.css';
import axios from "axios";
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import authHeader from '../authHeader';


var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
var roleName = parseInt(sessionStorage.getItem('roleName'));
var societyId = parseInt(localStorage.getItem('societyId'));



function Userprivileges() {
    const [userDetails, setUserDetails] = useState([]);
    const [DisableButton, setDisableButton] = useState(true);
    const [userName, setUserName] = useState('');
    const [roleName, setRoleName] = useState('');
    
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [selectedPrivileges, setSelectedPrivileges] = useState([]);
    const [addDisabled, setAddDisabled] = useState(false);
    const [destinationData, setDestinationData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [PrivilegesDetails, setPrivilegesDetails] = useState([]);
    const [rolesidDetails, setRolesidDetails] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState([]);
    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
    const [SubGmData, setSubGmData] = useState(null);
    const toast = useRef(null);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [rolesDetails,setRolesDetails] = useState([]);
    const [displayRoleName , setdisplayRoleName] = useState([]);
    const societyService = new SocietyService();

    useEffect(() => {
        // societyService.getRegisterUserDetails(societyId)
        //     .then(data => {
        //         data.forEach(a => {
        //             a.userInfo = `${a.userName}-${a.firstName}-${a.lastName}`;
        //         })

        //         setUserDetails(data);
        //     });

            societyService.getRolesDetails()
            .then(data => {
                setRolesDetails(data);
            });
    

        societyService.getprivilegesDetails()
            .then(data => {
                setPrivilegesDetails(data);
            });

        if (accessLevel >= 2) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }

    }, []);

    
    const onChangeUser = (event) => {
        setRoleName(event.target.value);
        let selectedRoleId = event.target.value.id;
    
        societyService.getPrivilegesidDetails(selectedRoleId)
            .then(data => {
                setDestinationData(data);
            });
    };
    
        
    const handleMoveSelectedRows = () => {
        if (selectedRows.length === 0) {
            toast.current.show({
                severity: 'warn',
                detail: 'Please select at least one row.',
            });
            return;
        }
    
        // Check for duplicates in the destinationData based on the name property
        const duplicateNames = selectedRows.filter(row => destinationData.some(destRow => destRow.name === row.name));
    
        if (duplicateNames.length > 0) {
            toast.current.show({
                severity: 'warn',
                detail: 'Duplicate names detected. Please select only unique names.',
            });
            return;
        }
    
        setDestinationData(prevData => [...prevData, ...selectedRows]);
    
        const updatedUserDetails = userDetails.filter((data) => !selectedRows.includes(data));
        setUserDetails(updatedUserDetails);
    
        setSelectedUserRole([...selectedUserRole, ...selectedRows]);
    
        // Update selectedRows for the source DataTable, don't clear it
        // Maintain the existing selections in the source DataTable
        // Use Set to ensure unique values
        setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, ...selectedRows])]);
    
        setIsSaveButtonDisabled(selectedRows.length === 0);
        setIsDeleteButtonDisabled(selectedRows.length === 0);
    };
    
    const onDelete = () => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="p-text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <Button type="button" label="Yes" value={SubGmData} className="p-button-success" onClick={handleSubmit(onYes)} />
                        </div>
                        <div className="p-col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={handleSubmit(onNo)} />
                        </div>
                    </div>
                </div>
            )
        });
    }

    const onYes = () => {
        if (selectedRows === null || !roleName) {
            toast.current.show({ severity: 'warn', sticky: true, detail: "Please Select Row and User First" });
            return;
        }
    
        toast.current.clear();
        setSpinnerVisible(true);
    
        selectedRows.forEach(d => {
            let id = d.id;
            let data = destinationData.filter(b => b.id !== d.id);
            setDestinationData(data);
    
            axios.delete(`${Config.apiUrl}/UserPrivileges/delete1?id=${id}`, { headers: authHeader() })
                .then(response => {
                    console.log(response);
                    if (response.data.success) {
                        toast.current.show({ severity: 'success', sticky: true, detail: response.data.msg });
                    } else {
                        toast.current.show({ severity: 'error', sticky: true, detail: response.data.msg });
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setSpinnerVisible(false);
                });
        });
    };
        const onNo = () => {
        toast.current.clear();
        return;
    };

    const onSave = (data, e) => {
        if (!roleName || !roleName.id || selectedRows.length === 0) {
          toast.current.show({ severity: 'Warn', sticky: true, detail: "Please Select User and Role First" });
          return;
        }
      
        setSpinnerVisible(true);
      
        // Create an array to store promises for each save operation
        const savePromises = [];
      
        // Loop through selected rows
        selectedRows.forEach((selectedRow) => {
          // Assuming you need to filter PrivilegesDetails based on the selected row's accessLevel
          const details = PrivilegesDetails.filter((a) => a.name === selectedRow.accessLevel);
      
          // Check if roleName.id is present before creating newData
          if (roleName.id) {
            const newData = { roleId: roleName.id, privilegesId: selectedRow.id };
      
            // Save each row and store the promise in the array
            savePromises.push(
              axios.post(`${Config.apiUrl}/UserPrivileges/PrivilegesInfo`, newData, { headers: authHeader() })
            );
          }
        });
      
        // Use Promise.all to wait for all save operations to complete
        Promise.all(savePromises)
          .then((responses) => {
            responses.forEach((response, index) => {
              console.log(response);
              if (response.data.success) {
                toast.current.show({ severity: 'success', detail: response.data.msg });
      
                // Remove the successfully saved row from selectedRows
                setSelectedRows((prevSelectedRows) =>
                  prevSelectedRows.filter((row) => row.id !== selectedRows[index].id)
                );
              } else {
                toast.current.show({ severity: 'error', detail: response.data.msg });
              }
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setSpinnerVisible(false);
          });
      };
      
    
    return (
        <div className='UsersView' style={{ marginTop: "10px" }} >
            <div>
                <label style={{ fontSize: "18px", width: "80px", marginLeft: "30px" }}> Select Roles</label>
                <Dropdown options={rolesDetails} optionLabel="roleName" placeholder="Select Role" value={roleName} onChange={onChangeUser}  />
              </div>

            <div style={{ display: 'flex', marginTop: "25px", borderRadius: "6px" }}>
                <DataTable
                    value={PrivilegesDetails}
                    className="p-datatable-gridlines p-datatable-sm"
                    style={{ background: 'linear-gradient(to bottom, #fcfff4 0%,#e9e9ce 100%);' }}
                    scrollable
                    scrollHeight="500px"
                    style={{ overflow: 'hidden' }}
                    selection={selectedRows}
                    onSelectionChange={(e) => setSelectedRows(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="name" header="PrivilegesName"></Column>
                </DataTable>

                <i className="pi pi-angle-double-right" style={{ fontSize: '2rem', color: "grey", marginTop: "60px" }} onClick={handleMoveSelectedRows}></i>

                <DataTable value={destinationData} className="p-datatable-gridlines p-datatable-sm" style={{ background: 'linear-gradient(to bottom, #fcfff4 0%,#e9e9ce 100%);' }} scrollable scrollHeight='500px' style={{ overflow: 'hidden' }} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="name" header="RolePrivileges" > </Column>
                </DataTable>
        

{/* <DataTable value={destinationData} className="p-datatable-gridlines p-datatable-sm" style={{ background: 'linear-gradient(to bottom, #fcfff4 0%,#e9e9ce 100%);' }} scrollable scrollHeight='500px' style={{ overflow: 'hidden' }} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column header="UserPrivileges">

        {(rowData) => (
            <div>
                <div>{rowData.name}</div>
                <div>{rowData.roleName}</div>
            </div>
        )}
    </Column>
</DataTable> */}

// ...


            </div>
            <div className="p-formgroup-inline" style={{ justifyContent: 'center', marginTop: '30px' }}>
            <div className="p-field">
             <Button icon="pi pi-save"label="SAVE" type="text" value={selectedRows} onClick={onSave}  />
                     </div>

<div className="p-field">
                    <Toast ref={toast} />
        <Button icon="pi pi-trash" value="DELETE"  tooltip="Delete" label="Delete" className="btn"  onClick={onDelete}/>  </div>
            </div>
            
        </div>


        
    );
}

export default Userprivileges;




