import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import React, { useState, useEffect, useContext, useRef } from 'react';
import './Permission.css';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import SocietyService from '../../Service/SocietyService';
import axios from 'axios';
import { useForm } from "react-hook-form";
import authHeader from '../../authHeader';
import ProgressSpinBarControl from './progressSpinBarControl';


function Permission({ id }) {

    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
    var [selectedCheckboxData, setselectedCheckboxData] = useState([]);
    const societyService = new SocietyService();
    const isAddMode = !id;
    const toast = useRef(null);
    // const { societyId } = useContext(SocietyContext);
    var societyId = localStorage.getItem('societyId');
    const [actionData, setActionData] = useState([]);
    const [addPermissionDetails, setPermissionDetails] = useState();
    const [selectRole, setSelectRole] = useState();
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);


    const onAction = (data, e) => {
        data.forEach(a => {
            a.action = data.action;
            a.accesslevel = selectRole;
            setSpinnerVisible(true);
            return axios.put(`${Config.apiUrl}/Permission/updateAction`, a, { headers: authHeader() })
                .then(response => {
                    console.warn(data);
                    console.log(response)
                    if (response.data != null) {
                        toast.current.show({ severity: 'success', detail: response.data.msg });
                        window.location.reload();
                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.data.msg });
                    }
                })
                .catch(error => { 
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
                })
                .finally(() => {
                    setSpinnerVisible(false);
                  })
        })

    }

    const approve = (e) => {

        if (selectRole === undefined) {
            toast.current.show({ severity: 'warn', detail: "please selected User Role" });

        }
        else {
            selectedCheckboxData.action = 'Approve';
            onAction(selectedCheckboxData);
        }


    }

    const decline = (e) => {
        selectedCheckboxData.action = 'Decline';
        onAction(selectedCheckboxData);


    }
    const selectedRole = (e) => {
        let selectedRole = e.currentTarget.value;
        setSelectRole(selectedRole);
    }


    useEffect(() => {
        societyService.getPermissionDetails(societyId).then(data => setPermissionDetails(data));
        console.warn(addPermissionDetails);

        if (accessLevel >= 2) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);

        }
    },

        []);



    const header = (
        <div className="table-header">
            <select onChange={selectedRole} style={{ height: '30px', width: '200px', marginRight: '10px' }}>
                <option hidden value="">Select Role </option>
                <option>ReadOnly</option>
                <option>ReadWrite</option>
                <option>Admin</option>
            </select>
            <Button icon="pi pi-check" className="btnApprove" label="APPROVE" onClick={handleSubmit(approve)} disabled={DisableButton} />
            <Button icon="pi pi-times" className="btnDecline" label="DECLINE" onClick={handleSubmit(decline)} disabled={DisableButton} />

        </div>
    )


    return (
        <form>
            <Toast ref={toast} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>

            {
                accessLevel >= 2 && <div className="PermissionTable">
                    <DataTable header={header} value={addPermissionDetails}  className="p-datatable-sm" width="500px" selection={selectedCheckboxData} onSelectionChange={e => setselectedCheckboxData(e.value)}>
                        <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                        <Column field="userName" header="User Name"></Column>
                        <Column field="firstName" header="First Name"></Column>
                        <Column field="lastName" header="Last Name"></Column>
                        <Column field="societyName" header="Society Name"></Column>
                        <Column field="wing" header="Wing" ></Column>
                        <Column field="flatNo" header="Flat No"></Column>
                        <Column field="mobileNo" header="Mobile No"></Column>

                    </DataTable>
                </div>
            }

        </form>

    );
}
export default Permission;