import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import SocietyService from '../Service/SocietyService';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { date } from 'yup/lib/locale';
import { parseDate } from '@material-ui/data-grid';
import { Button } from 'primereact/button';
import './PersonalAuditView.css';

function PersonalAuditView() {

    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
    const [globalFilter, setGlobalFilter] = useState('');
    const [fromDate, setFromDate] = useState(localStorage.getItem('fyFromDate'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [PersonalAuditData, setPersonalAuditInfo] = useState();
    const societyService = new SocietyService();
    var societyId = parseInt(localStorage.getItem('societyId'));

    const onDateChange = (e) => {
        let FromDate = e.currentTarget.value;
        setFromDate(FromDate);
    }

    const onToDateChange = (e) => {
        let ToDate = e.currentTarget.value;
        setToDate(ToDate);
    }

    const onFetch = (e) => {
        societyService.getPersonalAuditInfo(fromDate, toDate,societyId).then(data =>{
                    data.forEach(d=>{
                        if(d.birthDateFormat==='01-01-0001')
                        {
                            d.birthDateFormat='---';
                        }
                        if(d.age=== 0)
                        {
                            d.age='---';
                        }
                        if(d.admitDateFormat==='01-01-0001')
                        {
                            d.admitDateFormat='---';
                        }
                        if(d.admitDateFormat==='01-01-0001')
                        {
                            d.admitDateFormat='---';
                        }
                        if(d.mobile==='')
                        {
                            d.mobile='---';
                        }
                        if(d.address1==='')
                        {
                            d.address1='---';
                        }
                    })
                    setPersonalAuditInfo(data)
                }
             );
        console.warn(PersonalAuditData)
    }

    const header = (
        <div className="table-header" style={{ justifyContent: 'flex-start', marginInline: '10px' }}>
            {/* <b>Personal Audit View</b> */}

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" style={{
            width: '200px', height: '35px', marginRight: 20, borderStyle: 'solid', borderWidth: 1, borderColor: 'green',
            borderRadius: 5, paddingRight:'1rem'
          }}  value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            <label className="label" >From:</label>
            <input type="date" name="date" className="fromDate" value={fromDate} onChange={onDateChange} />

            <label className="label" >To:</label>
            <input type="date" name="date" className="fromDate" value={toDate} onChange={onToDateChange} />

            <Button label="FETCH" type="fetch" className="btnFetch" onClick={onFetch} />
        </div>
    );

    return (
        <div className="personalAuditView">
            <span style={{fontFamily:'verdena', fontWeight:'bold'}}>Personal Audit View </span> 
            <div>
            <DataTable className="p-datatable-gridlines" responsiveLayout="scroll" scrollable scrollHeight='400px' style={{ overflow: 'hidden' }} header={header}
                globalFilter={globalFilter} value={PersonalAuditData} emptyMessage="No result found.">
                <Column field="flatNo" style={{ width: '80px' }} header="Flat Id" filter></Column>
                <Column field="name" style={{ width: '220px' }} header="Owner Name" filter></Column>
                <Column field="gender" style={{ width: '100px' }} header="Gender" filter></Column>
                <Column field="birthDateFormat" style={{ width: '100px' }} header="Birth Date" filter></Column>
                <Column field="age" style={{ width: '80px' }} header="Age" filter></Column>
                <Column field="relation" style={{ width: '100px' }} header="Relation" filter></Column>
                <Column field="occupation" style={{ width: '150px' }} header="Occupation" filter ></Column>
                <Column field="admitDateFormat" style={{ width: '100px' }} header="Date of Admition" filter></Column>
                <Column field="mobile" style={{ width: '120px' }} header="Mobile" filter ></Column>
                <Column field="address1" style={{ width: '250px' }} header="Address" filter ></Column>
                <Column field="city" style={{ width: '150px' }} header="City" filter ></Column>
                <Column field="pincode" style={{ width: '100px' }} header="Pincode" filter ></Column>
                <Column field="modifyBy" header="Modify By" style={{ width: '120px' }} filter></Column>
                <Column field="modifyTime" header="Modify Time" style={{ width: '120px' }} filter></Column>
                <Column field="action" header="Action" style={{ width: '80px' }} filter></Column>
            </DataTable>
            </div>
        </div>

    );
}
export default PersonalAuditView;